import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../../themes/theme'

export const subtaskScreenStyle = (
  theme: Theme
): {
  title: TextStyle
  subtaskBox: ViewStyle
  subtaskDetailBox: ViewStyle
  arrowButton: ViewStyle
} =>
  StyleSheet.create({
    title: {
      color: theme.color.onSurface,
      fontFamily: 'PublicSans_700Bold_Italic',
      fontSize: theme.font.fontSizeMedium,
      maxWidth: '85%'
    },
    subtaskBox: {
      flex: 1,
      flexDirection: 'row',
      alignContent: 'flex-start',
      marginBottom: theme.spacing.verticalSpaceSmall,
      minHeight: 31
    },
    subtaskDetailBox: {
      flex: 1,
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center'
    },
    arrowButton: {
      position: 'absolute',
      right: 0
    }
  })
