import { z } from 'zod'

// Built based off of documentation at https://docs.mx.com/api#connect_postmessage_events
// Note that this is not a complete schema- rather, it is the minimal schema we need to process our post messages.
export const MxPostMessageSchema = z.object({ mx: z.literal(true) }).and(
  z.discriminatedUnion('type', [
    z.object({
      type: z.literal('mx/connect/loaded'),
      metadata: z.object({
        initial_step: z.union([
          z.literal('search'),
          z.literal('disclosure'),
          z.literal('verifyExistingMember'),
          z.literal('enterCreds'),
          z.literal('mfa'),
          z.literal('connected'),
          z.literal('loginError')
        ])
      })
    }),
    z.object({
      type: z.literal('mx/connect/memberConnected')
    }),
    z.object({
      type: z.literal('mx/connect/connected/primaryAction')
    }),
    z.object({
      type: z.literal('mx/connect/selectedInstitution'),
      metadata: z.object({
        name: z.string()
      })
    }),
    z.object({
      type: z.literal('mx/connect/stepChange'),
      metadata: z.object({
        current: z.union([
          z.literal('search'),
          z.literal('disclosure'),
          z.literal('verifyExistingMember'),
          z.literal('enterCreds'),
          z.literal('oauth'),
          z.literal('mfa'),
          z.literal('connecting'),
          z.literal('existingMember'),
          z.literal('timeout'),
          z.literal('connected'),
          z.literal('loginError'),
          z.literal('error')
        ])
      })
    })
  ])
)

export type MxPostMessageSchemaType = z.infer<typeof MxPostMessageSchema>
