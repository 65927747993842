// Get years to financial independence using current net worth, monthly spending, and
// monthly after-tax income.
//
// This logic is the source of truth on our calculation.  For initial notes, please refer to
// https://docs.google.com/document/d/1FxSpa_wthTG3tyniMkLFNv4UBFK-I973AGfPtMcTmWc/edit#heading=h.lgl8g2ooc3ad
//
// After some thought, we decided NOT to move this logic into our backend API.  It should end up
// there eventually, but as of writing it is totally self contained, unused in the backend, and only
// used in one place in the frontend. IT IS CRITICAL THAT THIS LOGIC REMAINS CENTRALIZED!  ESPECIALLY
// IF USE CASES EXPAND OUTSIDE OF THE FRONTEND, THIS LIKELY MEANS WE SHOULD REFACTOR THIS INTO THE
// GOAL ENGINE AND EXPOSE IT THROUGH OUR API!
//
// NOTE: for the most part, important financial constants are defined as input to the goal engine
// in https://github.com/eettaa/emb/blob/dev/src/app/amplify/backend/function/graphqlhandler/ts/run-goal-engine/run-goal-engine-driver.ts
// However, because we have chosen not to write this in the goal engine for now, some important constants
// are defined here.

// We chose a 3.3% safe withdrawal rate as FI target. This matches Vanguard's Case C suggestion for folks in low-cost,
// global investments, accounting for inflation over time with no need for behavioral changes in spending when the
// market is down (https://corporate.vanguard.com/content/dam/corp/research/pdf/Fuel-for-the-F.I.R.E.-Updating-the-4-rule-for-early-retirees-US-ISGFIRE_062021_Online.pdf)
// This does not account for Social Security, which makes these numbers very conservative anyways.
// Note that success is defined in terms of capital depletion (net worth > 0).  A more conservative number may be
// needed for capital preservation (or not- see ERN comment).
// Note that e.g. ERN is more optimistic about this number even in a capital *presrevation* paradigm, see:
// - https://earlyretirementnow.com/2016/12/14/the-ultimate-guide-to-safe-withdrawal-rates-part-2-capital-preservation-vs-capital-depletion/
// - https://earlyretirementnow.com/2017/01/25/the-ultimate-guide-to-safe-withdrawal-rates-part-7-toolbox/
// - https://earlyretirementnow.com/2017/02/01/the-ultimate-guide-to-safe-withdrawal-rates-part-8-technical-appendix/
const kSafeWithdrawalRate = 0.033

// FI calculations require an assumption about real after tax portfolio growth that applies to the portfolio
// both during wealth accumulation and retirement.  This is very similar to the Goal Engine's
// portfolioReturnOfMarginalDollar (defined for v0 in https://github.com/eettaa/emb/blob/dev/src/app/amplify/backend/function/graphqlhandler/ts/run-goal-engine/run-goal-engine-driver.ts#L38-L39)
// except that 1) it is real (post inflation), 2) it is after tax, and 3) it is meant to represent a (geometric!!)
// average return over the member's entire life as they switch portfolio allocations as the near/leave their
// retirement point.
// We chose a value of 5% for this estimation. This is roughly equivalent to a 30-70 stock-bond split with 3% inflation.
// This is a conservative estimate of portfolio appreciation for young folks and probably about an average one for
// members over the course of their saving and retirement time windows.  It's also reasonable to conservatively model
// stock returns for the near- to mid-term future as the market's price-to-earnings ratio has been very high in the
// 2010's, which is historically associated with low expected returns.
const kRealAfterTaxPortfolioGrowthRate = 0.05

export function getYearsToFinancialIndependence (
  currentNetWorth: number,
  monthlySpending: number,
  monthlyAfterTaxIncome: number
): number | null {
  // Monthly spending and after-tax income should be numbers >= 0. If they aren't, return null.
  if (monthlySpending < 0 || monthlyAfterTaxIncome < 0) {
    return null
  }

  const kMonthsPerYear = 12
  const kMonthlySafeWithdrawalRate =
    Math.pow(1 + kSafeWithdrawalRate, 1 / kMonthsPerYear) - 1
  const kRealMonthlyAppreciationRate =
    Math.pow(1 + kRealAfterTaxPortfolioGrowthRate, 1 / kMonthsPerYear) - 1

  // Positive numbers indicate incoming cash, negative numbers indicate running at a deficit
  const monthlyCashFlow = monthlyAfterTaxIncome - monthlySpending

  // If monthlySpending / kMonthlySafeWithdrawalRate is < currentNetWorth, then the member is already
  // at the net worth they need for financial independence, so return 0.
  // Note that decreasing monthly spending also decreases the net worth needed for FI.
  const netWorthNeededForFi = monthlySpending / kMonthlySafeWithdrawalRate

  if (netWorthNeededForFi <= currentNetWorth) {
    return 0
  }

  // Only run this calculation if currentNetWorth is non-negative.  Negative net worth implies debt, which
  // breaks all of our net worth growth assumptions.  Negative net worth grows at an unspecified interest rate
  // which is unrelated to kRealMonthlyAppreciationRate.  It shrinks by an unspecfied paydown rate that *could* be
  // assumed to be monthlyCashFlow, but then this formula is still incorrect because those
  // savings do not generate growth at kRealMonthlyAppreciationRate if they are allocated to paying down debt.
  if (currentNetWorth >= 0) {
    // Calculate years to FI, assuming we are making progress to FI.
    // At this point, we know currentNetWorth is less than netWorthNeededForFi. This if condition therefore
    // guarantees that the top log term is positive (needed for a real answer).
    if (kRealMonthlyAppreciationRate * currentNetWorth + monthlyCashFlow > 0) {
      const topLogFraction =
        (kRealMonthlyAppreciationRate * netWorthNeededForFi + monthlyCashFlow) /
        (kRealMonthlyAppreciationRate * currentNetWorth + monthlyCashFlow)
      const monthsToFinancialIndependence =
        Math.log(topLogFraction) / Math.log(1 + kRealMonthlyAppreciationRate)

      return monthsToFinancialIndependence / kMonthsPerYear
    }
  }

  return null
}
