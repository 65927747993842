import * as WalkthroughGraphQL from 'amplify-client-graphql'

export const taxFilingStatusToDisplayText = new Map<
WalkthroughGraphQL.TaxFilingStatus,
string
>([
  [WalkthroughGraphQL.TaxFilingStatus.SINGLE, 'Single'],
  [WalkthroughGraphQL.TaxFilingStatus.HEAD_OF_HOUSEHOLD, 'Head of household'],
  [
    WalkthroughGraphQL.TaxFilingStatus.MARRIED_FILING_JOINTLY,
    'Married filing jointly'
  ],
  [
    WalkthroughGraphQL.TaxFilingStatus.MARRIED_FILING_SEPARATELY,
    'Married filing separately'
  ],
  [
    WalkthroughGraphQL.TaxFilingStatus.QUALIFYING_WIDOW_ER,
    'Qualifying widow/er'
  ]
])
