import { StyleSheet } from 'react-native'
import { THEME } from '../../../constants'

export const jungleScreenStyle = StyleSheet.create({
  jungleScreen: {
    backgroundColor: THEME.color.background,
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  jungleBackground: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: -3
  },
  modulesContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    // It's unintuitive that this is only paddingLeft, but the individual modules contain right margin
    // which happens to match this value.
    paddingLeft: THEME.spacing.horizontalSpaceMedium,
    paddingTop: THEME.spacing.horizontalSpaceMedium
  },
  module: {
    flex: 1,
    maxWidth: '50%',
    padding: THEME.spacing.horizontalSpaceMedium,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: THEME.color.outline,
    backgroundColor: THEME.color.surfaceOne,
    marginRight: THEME.spacing.horizontalSpaceMedium,
    textAlign: 'center'
  },
  taskContainer: {
    width: '100%',
    paddingHorizontal: THEME.spacing.horizontalSpaceMedium,
    marginTop: THEME.spacing.verticalSpaceSmall,
    zIndex: 1
  },
  task: {
    width: '100%',
    padding: THEME.spacing.horizontalSpaceMedium,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: THEME.color.outline,
    backgroundColor: THEME.color.surfaceOne,
    textAlign: 'left',
    flexDirection: 'row'
  },
  taskTitleContainer: {
    flex: 8,
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  whyThisTaskIconContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  whyThisTaskDropdown: {
    padding: THEME.spacing.horizontalSpaceMedium,
    zIndex: 1
  },
  savingsRateDropdown: {
    width: '100%',
    paddingHorizontal: THEME.spacing.horizontalSpaceMedium,
    zIndex: 1
  },
  dropDownExitPressable: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 0
  }
})
