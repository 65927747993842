import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import { textStyle, viewStyle } from '../../../../themes/global-styles.style'
import RightArrow from '../../../../assets/right-arrow'
import { Card } from '../../common/card/card'
import { accountRowStyle } from './account-row.style'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { AccountData } from '../account-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'

// A single account row, to be displayed in a drawer on the Accounts screen.
export function AccountRow (props: {
  account: AccountData
  navigation: GenericCompositeNavigationProp
  setAccountKeyPairToShow: React.Dispatch<
  React.SetStateAction<WalkthroughGraphQL.AccountKeyPair | null>
  >
}): JSX.Element {
  return (
    <View style={viewStyle.smallTopMargin}>
      <Card>
        <Pressable
          style={accountRowStyle.mainInfoContainer}
          onPress={() => {
            props.setAccountKeyPairToShow(props.account.accountKeyPair)
          }}
        >
          <View style={accountRowStyle.dataRowsContainer}>
            <View style={accountRowStyle.rowContainer}>
              <View style={accountRowStyle.titleContainer}>
                <Text style={[textStyle.regularText, textStyle.boldText]}>
                  {props.account.accountName}
                </Text>
              </View>
              <View style={accountRowStyle.valueContainer}>
                <Text style={[textStyle.regularText, textStyle.boldText]}>
                  {props.account.balance}
                </Text>
              </View>
            </View>
            <View style={accountRowStyle.rowContainer}>
              <View style={accountRowStyle.lastUpdateContainer}>
                <Text style={[textStyle.smallText]}>
                  {props.account.lastUpdateString}
                </Text>
              </View>
            </View>
          </View>
          <View style={accountRowStyle.iconContainer}>
            <RightArrow />
          </View>
        </Pressable>
      </Card>
    </View>
  )
}
