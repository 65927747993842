import * as React from 'react'
import { View, Text } from 'react-native'
import {
  visitFinancialProviderStyle,
  WidgetThemeToMatch
} from './visit-financial-provider.style'

function VisitFinancialProvider (props: {
  financialInstitutionAccountName: string
  widgetThemeToMatch: WidgetThemeToMatch
}): JSX.Element {
  const style = visitFinancialProviderStyle(props.widgetThemeToMatch)
  return (
    <View style={style.contentBox}>
      <Text style={style.content}>
        We think you need to log in to your{' '}
        <Text style={style.contentHighlight}>
          {props.financialInstitutionAccountName}
        </Text>{' '}
        account to resolve an issue. Please log in to your financial
        institution's app or website and resolve any alerts that come up.
      </Text>
    </View>
  )
}

export { VisitFinancialProvider }
