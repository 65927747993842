import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import { THEME } from '../../../../constants'
import {
  buttonStyle,
  textStyle,
  viewStyle
} from '../../../../themes/global-styles.style'
import { finalBannerStyle } from './final-banner.style'

export function FinalBanner (props: {
  setSavingsRateQuestionIndex: React.Dispatch<
  React.SetStateAction<number | null>
  >
}): JSX.Element {
  return (
    <View style={finalBannerStyle.finalBanner}>
      <Text style={[textStyle.regularText, textStyle.boldText]}>
        The fates have spoken.{'\n\n'}Come back tomorrow to spin the wheel again
        and get your daily points 🎱!{'\n\n'}
        The higher your savings rate, the more points you can win! Keep your
        monthly income and spending up to date to keep your savings rate
        accurate.
      </Text>
      <Pressable
        style={[
          buttonStyle(THEME.color.highlight, THEME.color.outline).smallButton,
          viewStyle.smallTopMargin
        ]}
        onPress={() => props.setSavingsRateQuestionIndex(0)}
      >
        <Text style={[textStyle.regularText, textStyle.boldText]}>
          Update savings rate
        </Text>
      </Pressable>
    </View>
  )
}
