import * as React from 'react'
import Svg, {
  SvgProps,
  G,
  Path,
  Circle,
  Defs,
  ClipPath
} from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const Guitar = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 414 896'
    fill='none'
    preserveAspectRatio='xMaxYMax slice'
    {...props}
  >
    <G clipPath='url(#a)'>
      <Path
        d='M262 656.15c0 29.186-23.726 52.85-53 52.85s-53-23.664-53-52.85c0-13.226 4.868-31.316 12.964-47.196 4.916-9.643 10.993-18.411 17.83-24.755C193.635 577.852 201.16 574 209 574c9.352 0 18.255 5.485 26.065 14.119 7.797 8.62 14.386 20.256 19.1 32.247 4.984 12.678 7.835 25.663 7.835 35.784Z'
        fill='#fff'
        stroke='#fff'
        strokeWidth={2}
      />
      <Path
        d='m273.991 619.432 8.174-5.637a9.001 9.001 0 0 1 10.872.496l.387.323a9.05 9.05 0 0 1 3.231 6.542c.168 3.847-2.126 7.406-5.702 8.809l-.817.321-4.383 1.72-3.117 1.224-1.08.424a8.11 8.11 0 0 1-7.565-14.222Z'
        fill='#343751'
        stroke='#fff'
        strokeWidth={2}
      />
      <Path
        d='M270.498 630.282a9.11 9.11 0 0 1 2.924-11.673l8.174-5.637a10.003 10.003 0 0 1 12.08.55l.387.323a10.046 10.046 0 0 1 3.589 7.267c.188 4.269-2.357 8.223-6.335 9.784l-5.2 2.041-4.197 1.647a9.108 9.108 0 0 1-11.422-4.302ZM187.329 653.204c-6.637 3.423-19.76-.769-28.706 3.846-12.379 6.385-13.729 18.556-8.666 30.951a50.153 50.153 0 0 0 1.844 3.989c1.318 2.553 2.794 4.911 4.412 7.011 7.07 9.173 16.86 13.437 28.013 7.684 10.389-5.359 9.499-15.495 19.924-20.872 6.06-3.126 13.776-1.626 18.97-4.305 11.543-5.954 10.29-16.883 4.289-28.518-6.002-11.635-15.218-19.187-27.176-13.019-7.214 3.721-6.266 9.809-12.904 13.233Z'
        fill='#343751'
      />
      <G>
        <Path
          d='m174.992 665.412 11.769 23.524'
          stroke='#fff'
          strokeWidth={2}
          strokeLinecap='round'
        />
      </G>
      <Path
        d='m280.218 621.001-101.155 52.333 4.511 8.494L260 641.283l5-2.653 5-2.652 14.088-7.474-3.87-7.503Z'
        fill='#343751'
      />
      <Path
        d='m260.5 641.001-76.926 40.827-4.511-8.494 101.155-52.333 3.87 7.503L270 635.978c-6.5 17.022 12.598 41.648 33.001 27.522C334 642.039 327.087 681.828 346 681.828c34 0 34-40.827 81-18.328'
        stroke='#fff'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='m273.991 619.432 8.174-5.637a9.001 9.001 0 0 1 10.872.496l.387.323a9.05 9.05 0 0 1 3.231 6.542c.168 3.847-2.126 7.406-5.702 8.809l-.817.321-4.383 1.72-3.117 1.224-1.08.424a8.11 8.11 0 0 1-7.565-14.222Z'
        fill='#343751'
        stroke='#fff'
        strokeWidth={2}
      />
      <Circle
        cx={196.24}
        cy={669.066}
        r={9.391}
        transform='rotate(-27.285 196.24 669.066)'
        stroke='#fff'
        strokeWidth={2}
      />
      <Path
        d='M-2.5 666c-.667 17.167 9.4 56.5 43 68.5 42 15 30.999-47 55.999-47.5 25-.5 19 23.5 37 20 14.4-2.8 18.958-13.5 16.458-19-5.063-12.395-3.713-24.566 8.666-30.951 8.946-4.615 22.069-.423 28.706-3.846 6.637-3.424 5.689-9.512 12.904-13.233 11.957-6.168 21.174 1.385 27.175 13.019 6.002 11.635 7.255 22.564-4.288 28.518-5.195 2.679-12.91 1.179-18.971 4.305-8.681 4.478-10.245 12.949-16.372 18.606l-.277.251'
        stroke='#fff'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M0 0h414v896H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default Guitar
