import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../../themes/theme'

export const goalBarStyle = (
  theme: Theme
): {
  goalBox: ViewStyle
  contentBox: ViewStyle
  goalBar: ViewStyle
} =>
  StyleSheet.create({
    goalBox: {
      width: '100%',
      borderRadius: 20,
      backgroundColor: theme.color.surfaceOne,
      borderColor: theme.color.outline,
      borderWidth: 2
    },
    contentBox: {
      margin: theme.spacing.horizontalSpaceMedium
    },
    goalBar: {
      backgroundColor: theme.color.surfaceTwo,
      width: '100%',
      height: 25,
      borderRadius: 25
    }
  })

export const goalBarProgressStyle = (
  theme: Theme,
  goalProgressPercent: string
): {
  goalProgressBar: ViewStyle
  goalProgressNumber: TextStyle
} =>
  StyleSheet.create({
    goalProgressBar: {
      backgroundColor: theme.color.highlight,
      width: goalProgressPercent,
      height: 25,
      borderRadius: 25,
      alignItems: goalProgressPercent === '0%' ? 'flex-start' : 'flex-end',
      justifyContent: 'center'
    },
    goalProgressNumber: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeMedium,
      fontFamily: 'PublicSans_700Bold',
      marginRight: goalProgressPercent === '0%' ? '0' : '7px',
      marginLeft: goalProgressPercent === '0%' ? '7px' : '0'
    }
  })
