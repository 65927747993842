"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.memberOnboardingData = void 0;
// Queries only member data needed for onboarding
exports.memberOnboardingData = `
  query MemberOnboardingData($owner: String!) {
    getMember(owner: $owner){
      owner
      createdAt
      updatedAt
      onboardingComplete
      state
      zipCode
      birthday
      employmentStatus
      estimatedTaxableIncome
      estimatedAverageMonthlySpending
      taxFilingStatus
      spouseBirthday
      estimatedAverageMonthlyAfterTaxIncome
      lastReceivedSavingsRatePoints
      points
      isAbleToPayBasicExpensesMemberDeclared
      hasOneMonthEmergencyFundMemberDeclared
      promoCode
    }
  }
`;
