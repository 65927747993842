import * as React from "react"
import Svg, { SvgProps, Circle, Path } from "react-native-svg"

const QuestionMarkCircle = (props: SvgProps): JSX.Element => (
  <Svg
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <Circle
      cx={13}
      cy={13}
      r={12}
      fill="#FEDBEB"
      stroke="#202232"
      strokeWidth={2}
    />
    <Path
      d="M9 7.47c.227-.2.473-.39.74-.57a5.54 5.54 0 0 1 .88-.47 5.205 5.205 0 0 1 1.02-.31A5.58 5.58 0 0 1 12.83 6c.58 0 1.107.08 1.58.24.48.16.89.39 1.23.69.34.293.603.65.79 1.07.187.42.28.89.28 1.41 0 .507-.073.947-.22 1.32-.147.367-.33.687-.55.96-.22.273-.463.51-.73.71l-.74.57a5.5 5.5 0 0 0-.61.52 1.015 1.015 0 0 0-.3.57l-.23 1.46h-1.69l-.17-1.63c-.04-.313.003-.587.13-.82.133-.24.307-.457.52-.65.22-.2.463-.39.73-.57.267-.187.517-.387.75-.6.233-.22.427-.463.58-.73.16-.273.24-.597.24-.97 0-.24-.047-.453-.14-.64a1.345 1.345 0 0 0-.37-.49 1.63 1.63 0 0 0-.58-.32c-.22-.073-.46-.11-.72-.11-.38 0-.703.043-.97.13-.26.08-.483.173-.67.28-.18.1-.333.193-.46.28a.656.656 0 0 1-.34.12c-.24 0-.413-.1-.52-.3L9 7.47Zm1.89 11.79a1.6 1.6 0 0 1 .11-.59c.08-.187.187-.347.32-.48s.293-.24.48-.32c.187-.08.387-.12.6-.12.213 0 .41.04.59.12.187.08.347.187.48.32a1.482 1.482 0 0 1 .45 1.07c0 .213-.04.413-.12.6-.08.18-.19.337-.33.47a1.386 1.386 0 0 1-.48.31c-.18.08-.377.12-.59.12-.213 0-.413-.04-.6-.12a1.386 1.386 0 0 1-.48-.31 1.545 1.545 0 0 1-.32-.47 1.627 1.627 0 0 1-.11-.6Z"
      fill="#202232"
    />
  </Svg>
)

export default QuestionMarkCircle
