import * as React from 'react'
import { View, Text } from 'react-native'
import { AccountRow } from './account-row'
import { isNonNullish } from 'global-utils'
import { textStyle } from '../../../../themes/global-styles.style'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { AccountData } from '../account-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'

// A collection of accounts, to be displayed in a single drawer's content section on the Accounts screen.
export function AccountDrawerContent (props: {
  accounts: AccountData[]
  navigation: GenericCompositeNavigationProp
  setAccountKeyPairToShow: React.Dispatch<
  React.SetStateAction<WalkthroughGraphQL.AccountKeyPair | null>
  >
  text?: string
}): JSX.Element {
  return (
    <View>
      {isNonNullish(props.text) ? (
        <View>
          <Text style={textStyle.regularText}>{props.text}</Text>
        </View>
      ) : null}
      {props.accounts.map((account) => (
        <AccountRow
          key={account.accountKeyPair.accountPrimaryKey}
          account={account}
          navigation={props.navigation}
          setAccountKeyPairToShow={props.setAccountKeyPairToShow}
        />
      ))}
    </View>
  )
}
