import {
  AccountWithSnapshotShape,
  validateAccountForHandler
} from 'multi-type-processor'
import { QuestionAndAnswers } from '../../common/questions/question-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish } from 'global-utils'
import { parseISO, differenceInMonths } from 'date-fns'
import { updateAccountBalanceQuestion } from '../../common/questions/content/update-manual-account-balance'

export function manualAccountBalanceRepromptHandler (
  account:
  | (Omit<WalkthroughGraphQL.Account, '__typename'> & {
    __typename: string
  } & AccountWithSnapshotShape)
  | null
): QuestionAndAnswers[][] | null {
  try {
    // This if block allows us to assume that account is nonullable further down
    if (
      !validateAccountForHandler(account, 'manualAccountBalanceRepromptHandler')
    ) {
      return null
    }
  } catch (e) {
    return null
  }
  if (
    account.dataProvider !==
    WalkthroughGraphQL.DataProvider.WALKTHROUGH_MANUALLY_DECLARED
  ) {
    return null
  }
  const lastUpdated = account.snapshots?.items[0]?.lastUpdatedOnProviderSide
  if (!isNonNullish(lastUpdated)) {
    return null
  }
  const lastUpdatedDate = parseISO(lastUpdated)
  const now = new Date(Date.now())
  // If the last updated date is more than a month ago, prompt for update
  if (differenceInMonths(now, lastUpdatedDate) > 0) {
    return [
      [
        updateAccountBalanceQuestion(
          account,
          /* promptingBecauseStaleData= */ true
        )
      ]
    ]
  }
  return null
}
