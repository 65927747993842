import { StyleSheet, ViewStyle } from 'react-native'
import { Theme } from './themes/theme'

export const appStyle = (
  theme: Theme
): {
  innerContainer: ViewStyle
  outerContainer: ViewStyle
} =>
  StyleSheet.create({
    innerContainer: {
      width: '100%',
      maxWidth: 425,
      height: '100%'
    },
    outerContainer: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.color.surfaceTwo,
      alignItems: 'center'
    }
  })
