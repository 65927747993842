import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../../themes/theme'

export const taskStyle = (
  theme: Theme
): {
  mainBox: ViewStyle
  taskBox: ViewStyle
  contentBox: ViewStyle
  subContentBox: ViewStyle
  title: TextStyle
  description: TextStyle
  buttonText: TextStyle
} =>
  StyleSheet.create({
    mainBox: {
      height: '100%',
      width: '100%'
    },
    taskBox: {
      maxHeight: '80%',
      width: '100%',
      borderRadius: 20,
      backgroundColor: theme.color.surfaceOne,
      borderColor: theme.color.outline,
      borderWidth: 2
    },
    contentBox: {
      margin: theme.spacing.horizontalSpaceMedium
    },
    subContentBox: {
      marginBottom: theme.spacing.verticalSpaceSmall
    },
    title: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeMedium,
      fontFamily: 'PublicSans_700Bold',
      paddingBottom: theme.spacing.verticalSpaceSmall
    },
    description: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeMedium,
      fontFamily: 'PublicSans_400Regular'
    },
    buttonText: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeLarge,
      fontFamily: 'PublicSans_700Bold'
    }
  })

export const buttonStyle = (
  theme: Theme,
  isNextButton: boolean
): {
  button: ViewStyle
} =>
  StyleSheet.create({
    button: {
      backgroundColor: isNextButton
        ? theme.color.highlight
        : theme.color.superHighlight,
      width: theme.buttons.smallButtonWidth,
      height: theme.buttons.buttonHeight,
      borderRadius: 20,
      borderColor: theme.color.outline,
      borderWidth: 2,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      marginTop: theme.spacing.verticalSpaceSmall,
      marginBottom: theme.spacing.verticalSpaceSmall
    }
  })
