import * as React from 'react'
import { View } from 'react-native'
import { RouteProp } from '@react-navigation/native'
import { THEME } from '../../../constants'
import { FeedbackButton } from '../feedback/feedback-button'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { onboardingScreenStyle } from './onboarding-screen.style'
import { JungleWithIndex } from '../jungle/jungle-with-index'
import { Questions } from '../common/questions/questions'
import { profileQuestions } from '../common/questions/content/profile-questions'
import { AccountLinkingFlowType } from '../account-linking/account-linking-flow-type'
import { NavigationChainButton } from '../common/buttons/navigation-button'
import { ScrollWithArrow } from '../common/scroll/scroll-with-arrow'
import { BackButton } from '../../common/buttons/back-button'
import {
  gql,
  NormalizedCacheObject,
  ApolloClient,
  useApolloClient
} from '@apollo/client'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { loadToken } from '../../auth/load-token'
import Constants from 'expo-constants'

function OnboardingScreen (props: {
  route: RouteProp<
  { OnboardingScreen?: { onboardingQuestionStepIndex: number } },
  'OnboardingScreen'
  >
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  if (props.route.params === undefined) {
    throw new Error(
      'OnboardingScreen must be provided params despite its route.props.params field technically ' +
        'being optional; please ensure that the relevant callsite is providing them.'
    )
  }

  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>

  if (
    props.route.params.onboardingQuestionStepIndex >= profileQuestions.length
  ) {
    throw new Error(
      'onboardingQuestionStepIndex: ' +
        props.route.params.onboardingQuestionStepIndex.toString() +
        ' out of bounds. questionData length = ' +
        profileQuestions.length.toString()
    )
  }

  // Use custom back button so that the back button color is white on iOS (weirdly defaults to black even
  // when headerTintColor is set to white)
  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (
        <BackButton
          handleOnPress={() => {
            props.navigation.goBack()
          }}
        />
      )
    })
  }, [props.navigation])

  const [scrollToTopTrigger, setScrollToTopTrigger] = React.useState(0)
  React.useEffect(() => {
    // Set a new, different value to trigger a ScrollToTop call
    setScrollToTopTrigger(Math.random())
  }, [props.route.params.onboardingQuestionStepIndex])

  const style = onboardingScreenStyle(THEME)

  return (
    <View style={style.onboardingScreen}>
      <View style={style.questionBox}>
        <ScrollWithArrow triggerScrollToTop={scrollToTopTrigger}>
          <Questions
            questions={
              profileQuestions[props.route.params.onboardingQuestionStepIndex]
            }
            buttonText="Next"
            onSaveState={() => {
              const nextIndex =
                (props.route.params?.onboardingQuestionStepIndex ??
                  profileQuestions.length) + 1
              if (nextIndex >= profileQuestions.length) {
                // Await onboardingComplete mutation before navigation away from the page to ensure that
                // the apolloClient variable is not deallocated (due to unmounting) when we we try to invoke the
                // mutation.
                (async () => {
                  const owner = await loadToken()
                  await apolloClient.mutate<
                  WalkthroughGraphQL.UpdateMemberMutation,
                  WalkthroughGraphQL.UpdateMemberMutationVariables
                  >({
                    mutation: gql(WalkthroughGraphQL.updateMember),
                    variables: {
                      input: {
                        owner: owner,
                        onboardingComplete: true
                      }
                    }
                  })
                  props.navigation.navigate('MxMultiplexerScreen', {
                    connectInstances: [
                      {
                        accountFlowType:
                          AccountLinkingFlowType.LINK_NEW_ACCOUNTS
                      }
                    ],
                    finalNavigation: (
                      navigation: GenericCompositeNavigationProp
                    ) => {
                      navigation.navigate('TabApp', {
                        screen: 'Accounts',
                        params: {
                          screen: 'AccountsScreen',
                          params: { initiallyShowWelcomeBanner: true }
                        }
                      })
                    }
                  })
                })().catch(console.log)
              } else {
                props.navigation.push('OnboardingScreen', {
                  onboardingQuestionStepIndex: nextIndex
                })
              }
            }}
          />
        </ScrollWithArrow>
      </View>
      <View style={style.jungleBackground}>
        {/* Not sure how to think about this jungle- maybe it's okay that semantically different screens
            have separate instances of the jungle. */}
        <JungleWithIndex
          index={props.route.params.onboardingQuestionStepIndex}
        />
      </View>
      <FeedbackButton navigation={props.navigation} />
    </View>
  )
}

export { OnboardingScreen }
