import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../themes/theme'

export const taskIntroStyle = (
  theme: Theme
): {
  taskBox: ViewStyle
  contentBox: ViewStyle
  subContentBox: ViewStyle
  title: TextStyle
  introContent: TextStyle
  button: ViewStyle
  buttonText: TextStyle
  alternativeButton: ViewStyle
  extraPadding: ViewStyle
} =>
  StyleSheet.create({
    taskBox: {
      maxHeight: '80%',
      width: '100%',
      borderRadius: 20,
      backgroundColor: theme.color.surfaceOne,
      borderColor: theme.color.outline,
      borderWidth: 2
    },
    contentBox: {
      margin: theme.spacing.horizontalSpaceMedium
    },
    subContentBox: {
      marginBottom: theme.spacing.verticalSpaceSmall
    },
    title: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeMedium,
      fontFamily: 'PublicSans_700Bold',
      paddingBottom: theme.spacing.verticalSpaceSmall
    },
    introContent: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeLarge,
      fontFamily: 'PublicSans_400Regular',
      marginBottom: theme.spacing.verticalSpaceSmall
    },
    button: {
      backgroundColor: theme.color.highlight,
      width: '100%',
      minHeight: theme.buttons.buttonHeight,
      borderRadius: 40,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      paddingTop: theme.spacing.verticalSpaceExtraExtraSmall,
      paddingBottom: theme.spacing.verticalSpaceExtraExtraSmall,
      marginBottom: theme.spacing.verticalSpaceSmall
    },
    buttonText: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeLarge,
      fontFamily: 'PublicSans_700Bold',
      paddingLeft: theme.spacing.horizontalSpaceMedium,
      paddingRight: theme.spacing.horizontalSpaceMedium,
      textAlign: 'center'
    },
    alternativeButton: {
      backgroundColor: theme.color.superHighlight,
      width: '100%',
      minHeight: theme.buttons.buttonHeight,
      borderRadius: 40,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      paddingTop: theme.spacing.verticalSpaceExtraExtraSmall,
      paddingBottom: theme.spacing.verticalSpaceExtraExtraSmall,
      marginBottom: theme.spacing.verticalSpaceSmall
    },
    extraPadding: {
      paddingBottom: theme.spacing.verticalSpaceSmall
    }
  })
