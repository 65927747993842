import * as React from 'react'
import { Pressable, Text } from 'react-native'
import { THEME } from '../../../constants'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { feedbackButtonStyle } from './feedback-button.style'

function FeedbackButton (props: {
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  const style = feedbackButtonStyle(THEME)

  return (
    <Pressable
      style={style.feedbackButton}
      onPress={() => props.navigation.navigate('FeedbackScreen')}
    >
      <Text style={style.feedbackButtonText}>Give feedback</Text>
    </Pressable>
  )
}

export { FeedbackButton }
