import { StyleSheet } from 'react-native'
import { THEME } from '../../constants'

export const boxWithCarrotStyle = StyleSheet.create({
  carrotView: {
    position: 'absolute',
    paddingTop: 1, // paddingTop is just empirically determined to line up borders based on carrot size...
    paddingLeft: '20%',
    zIndex: 1
  },
  box: {
    marginTop: THEME.spacing.verticalSpaceSmall,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: THEME.color.outline,
    backgroundColor: THEME.color.surfaceOne
  }
})
