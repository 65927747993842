"use strict";
// Queries only member data needed joining the member to external ids
Object.defineProperty(exports, "__esModule", { value: true });
exports.memberExternalIdsByMxUserGuid = exports.memberExternalIds = void 0;
exports.memberExternalIds = `
  query MemberExternalIds($owner: String!) {
    getMember(owner: $owner){
      createdAt
      updatedAt
      owner
      mxUserGuid
      yodleeLoginName
    }
  }
`;
exports.memberExternalIdsByMxUserGuid = `
  query MemberExternalIdsByMxUserGuid(
    $mxUserGuid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByMxUserGuid(
      mxUserGuid: $mxUserGuid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        updatedAt
        owner
        yodleeLoginName
        mxUserGuid
      }
      nextToken
    }
  }
`;
