import * as React from 'react'
import { View, Text, Pressable, ScrollView } from 'react-native'
import { useImmer } from 'use-immer'
import { RouteProp } from '@react-navigation/native'
import {
  ConnectInstanceData,
  ConnectInstanceNavigationData,
  MxConnect,
  ConnectionState
} from './mx-connect-widget'
import { AccountLinkingFlowType } from '../account-linking-flow-type'
import { isNonNullish, exhaustiveSwitchGuard } from 'global-utils'
import { useIsMounted } from '../../../../util/use-is-mounted'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { v4 as uuidv4 } from 'uuid'
import { Card } from '../../common/card/card'
import { THEME } from '../../../../constants'
import RightArrow from '../../../../assets/right-arrow'
import { FlashingExclamationMark } from '../../../../assets/flashing-exclamation-mark'
import { SpinningRefreshCircle } from '../../../../assets/spinning-refresh-circle'
import BigCheckMark from '../../../../assets/big-check-mark'
import {
  textStyle,
  buttonStyle,
  viewStyle
} from '../../../../themes/global-styles.style'
import CloseCircle from '../../../../assets/close-circle'
import { QuestionOverlay } from '../../common/questions/question-overlay'
import { QuestionAndAnswers } from '../../common/questions/question-data'
import { removeMxConnectInstanceQuestion } from '../../common/questions/content/remove-mx-connect-instance'
import { multiplexerStyle } from './mx-multiplexer-screen.style'
import { LogBox } from 'react-native-web-log-box'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// Ignore warnings about passing waitForPromise in the route to a screen because we are not
// using state persistence or deep linking to this screen. See:
// https://reactnavigation.org/docs/troubleshooting/#i-get-the-warning-non-serializable-values-were-found-in-the-navigation-state
// Note that this does not actually stop this from being logged in the web console (but likely works for stopping mobile
// web dev tool logs from showing)
LogBox.ignoreLogs([
  'Non-serializable values were found in the navigation state.'
])
LogBox.install()

interface ConnectInstanceReportedState {
  connectionState: ConnectionState | null
  selectedInstitution: string | null
}

// Helper component for Card with X button. Not intended for reuse, just a helpful refactoring.
// Component chooses its own style/icon/etc based on connection state.
function CardWithClose (props: {
  connectionState: ConnectionState.CONNECTING | ConnectionState.NEEDS_ATTENTION
  selectedInstitution: string | null
  onClose: () => void
  onClickCard: () => void
}): JSX.Element {
  return (
    <View
      style={[viewStyle.smallTopMargin, multiplexerStyle.singleRowContainer]}
    >
      <Pressable onPress={props.onClose} style={viewStyle.mediumRightMargin}>
        <CloseCircle />
      </Pressable>
      <Pressable
        style={multiplexerStyle.cardPressable}
        onPress={props.onClickCard}
      >
        <Card
          borderColor={THEME.color.outline}
          backgroundColor={(() => {
            switch (props.connectionState) {
              case ConnectionState.CONNECTING:
                return THEME.color.rainbow.darkBlue
              case ConnectionState.NEEDS_ATTENTION:
                return THEME.color.superHighlight
              default:
                exhaustiveSwitchGuard(props.connectionState)
            }
          })()}
        >
          <View style={multiplexerStyle.cardContents}>
            <View style={multiplexerStyle.cardContentsBeforeArrow}>
              <View style={multiplexerStyle.cardIconContainer}>
                {(() => {
                  switch (props.connectionState) {
                    case ConnectionState.CONNECTING:
                      return <SpinningRefreshCircle />
                    case ConnectionState.NEEDS_ATTENTION:
                      return <FlashingExclamationMark />
                    default:
                      exhaustiveSwitchGuard(props.connectionState)
                  }
                })()}
              </View>
              <View style={multiplexerStyle.cardTextContainer}>
                <Text style={[textStyle.regularText, textStyle.boldText]}>
                  {props.selectedInstitution ?? 'Pending Connection'}
                </Text>
                <Text style={textStyle.smallText}>
                  {(() => {
                    switch (props.connectionState) {
                      case ConnectionState.CONNECTING:
                        return 'Connecting...'
                      case ConnectionState.NEEDS_ATTENTION:
                        return 'Needs your attention!'
                      default:
                        exhaustiveSwitchGuard(props.connectionState)
                    }
                  })()}
                </Text>
              </View>
            </View>
            <View style={multiplexerStyle.arrowContainer}>
              <RightArrow />
            </View>
          </View>
        </Card>
      </Pressable>
    </View>
  )
}

// The MxMultiplexerScreen allows multiple MX connection to be run at the same time.
// To specify a [list of] connection instance[s] to show initially before showing the multiplexer
// screen (e.g. for refresh alert resolution flows) provide an array of connectInstances, each of which contains all
// the necessary data to render a single MX Connect instance.
// intermediateNextButtonText specifies the text to show on each initial MX Connect instance before navigating
// to the next client-specified connect instance, e.g. "next refresh alert."  If zero or one connectInstances are
// specified, this param does nothing
// lastNextButtonText specifies the text to show on the last of the intial MX Connect instances before navigating to the
// multiplexer page to wait for existing connections.
// finalNavigation is the navigation function to take the user back to the app after all account linking is complete.
export function MxMultiplexerScreen (props: {
  route: RouteProp<
  {
    MxMultiplexerScreen?: {
      connectInstances: ConnectInstanceData[]
      finalNavigation: (navigation: GenericCompositeNavigationProp) => void
      intermediateNextButtonText: string
      lastNextButtonText: string
    }
  },
  'MxMultiplexerScreen'
  >
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  if (props.route.params === undefined) {
    throw new Error(
      'MxMultiplexerScreen must be provided params despite its route.props.params field technically ' +
        'being optional; please ensure that the relevant callsite is providing them.'
    )
  }
  // The key of the one or zero instances that are currently visible.
  const [visibleConnectInstanceId, setVisibleConnectInstanceId] =
    React.useState<string | null>(null)

  const initialChainCompleted = React.useRef(false)

  const [finalButtonEnabled, setFinalButtonEnabled] = React.useState(true)

  const [questionIndex, setQuestionIndex] = React.useState<number | null>(null)
  const [questions, setQuestions] = React.useState<QuestionAndAnswers[][]>([])

  // Core structure maintaining the connections we have open
  const [connectInstanceStates, setConnectInstanceStates] = useImmer(
    new Map<
    string,
    {
      instanceData: ConnectInstanceData
      navigationData: ConnectInstanceNavigationData
      reportedState: ConnectInstanceReportedState
      inInitialNavigationChain: boolean
    }
    >()
  )

  const isMounted = useIsMounted()

  // Adds a new instance to state and returns the key (or null if failure).  Uses the provided instanceData if present,
  // otherwise uses a "link new account" default.
  function addNewConnectInstance (
    navigationData: ConnectInstanceNavigationData,
    inInitialNavigationChain: boolean,
    instanceData?: ConnectInstanceData
  ): string | null {
    if (isMounted()) {
      const key = uuidv4()
      setConnectInstanceStates((draft) => {
        draft.set(key, {
          navigationData,
          instanceData: instanceData ?? {
            accountFlowType: AccountLinkingFlowType.LINK_NEW_ACCOUNTS
          },
          reportedState: {
            connectionState: null,
            selectedInstitution: null
          },
          inInitialNavigationChain
        })
      })
      return key
    }
    return null
  }

  function removeConnectInstance (key: string): void {
    if (isMounted()) {
      setConnectInstanceStates((draft) => {
        draft.delete(key)
      })
      if (visibleConnectInstanceId === key) {
        setVisibleConnectInstanceId(null)
      }
    }
  }

  function setAllNavigationBackToMultiplexScreen (): void {
    const backToMultiplex: ConnectInstanceNavigationData = {
      nextButtonText: 'Back',
      onPressNext: () => {
        setVisibleConnectInstanceId(null)
      }
    }
    if (isMounted()) {
      setConnectInstanceStates((draft) => {
        draft.forEach((data) => {
          data.navigationData = backToMultiplex
        })
      })
      initialChainCompleted.current = true
    }
  }

  function generateStateHandler (
    key: string
  ): (status: ConnectionState) => void {
    return (status) => {
      if (isMounted()) {
        setConnectInstanceStates((draft) => {
          const relevantConnectionStatus = draft.get(key)
          if (isNonNullish(relevantConnectionStatus)) {
            relevantConnectionStatus.reportedState.connectionState = status
          }
        })
      }
      // If connecting or success and this instance is the visible one, move forward
      if (
        (status === ConnectionState.CONNECTING ||
          status === ConnectionState.SUCCESSFULLY_CONNECTED) &&
        visibleConnectInstanceIdRef.current === key
      ) {
        connectInstanceStates.get(key)?.navigationData.onPressNext()
      }
    }
  }

  // Set up user-defined connection instance.
  // Use layout effect so we don't flicker the multiplex screen
  React.useLayoutEffect(() => {
    const connectInstances = props.route.params?.connectInstances
    // Chain is complete if there are no initial instances (undefined or length zero)
    initialChainCompleted.current = (connectInstances?.length ?? 0) === 0
    if (isNonNullish(connectInstances)) {
      // Use reduceRight as a reverse for each that passes the relevant key to each prior instance
      // Side effects register all the instances.
      // One minor limitation of this implementation is that the insertion order into the map is last
      // to first, meaning that the multiplexer screen will order the refresh alerts in reverse order
      // from how the user experienced them.  In practice we don't think this is an issue as there is
      // plenty of other identifying information
      const firstKey = connectInstances.reduceRight<string | null>(
        (keyToNavTo, currentInstance) => {
          const keyOfThisInstance = addNewConnectInstance(
            isNonNullish(keyToNavTo)
              ? {
                  nextButtonText:
                    props.route.params?.intermediateNextButtonText ?? 'Next',
                  onPressNext: () => {
                    if (isMounted()) {
                      setVisibleConnectInstanceId(keyToNavTo)
                    }
                  }
                }
              : {
                  nextButtonText:
                    props.route.params?.lastNextButtonText ?? 'Done',
                  onPressNext: () => {
                    if (isMounted()) {
                      setVisibleConnectInstanceId(null)
                      setAllNavigationBackToMultiplexScreen()
                    }
                  }
                },
            /* inInitialNavigationChain= */ true,
            currentInstance
          )
          return keyOfThisInstance
        },
        null
      )
      setVisibleConnectInstanceId(firstKey)
    }
  }, [props.route.params?.connectInstances])

  // Keep the final button's enabled status updated
  React.useEffect(() => {
    for (const [, state] of [...connectInstanceStates]) {
      if (
        state.reportedState.connectionState ===
          ConnectionState.NEEDS_ATTENTION ||
        state.reportedState.connectionState === ConnectionState.CONNECTING
      ) {
        setFinalButtonEnabled(false)
        return
      }
    }
    setFinalButtonEnabled(true)
  }, [connectInstanceStates])

  // Show header IFF no instance is focused
  React.useLayoutEffect(() => {
    if (visibleConnectInstanceId === null) {
      props.navigation.setOptions({
        headerShown: true
      })
    } else {
      props.navigation.setOptions({
        headerShown: false
      })
    }
  }, [visibleConnectInstanceId])

  // Reaping job.  Remove READY and null state instances as long as they aren't currently visible or
  // in an active initial navigation chain.
  // We copy relevant state into references here so that the reaping job's closure still sees the latest state.
  const visibleConnectInstanceIdRef = React.useRef(visibleConnectInstanceId)
  React.useEffect(() => {
    visibleConnectInstanceIdRef.current = visibleConnectInstanceId
  }, [visibleConnectInstanceId])
  const connectInstanceStatesRef = React.useRef(connectInstanceStates)
  React.useEffect(() => {
    connectInstanceStatesRef.current = connectInstanceStates
  }, [connectInstanceStates])
  React.useEffect(() => {
    const reapingJob = setInterval(() => {
      [...connectInstanceStatesRef.current].forEach(
        ([key, { reportedState, inInitialNavigationChain }]) => {
          if (
            reportedState.connectionState === null ||
            reportedState.connectionState === ConnectionState.READY
          ) {
            if (visibleConnectInstanceIdRef.current !== key) {
              if (initialChainCompleted.current || !inInitialNavigationChain) {
                removeConnectInstance(key)
              }
            }
          }
        }
      )
    }, 30000)
    return () => {
      clearInterval(reapingJob)
    }
  }, [])

  const insets = useSafeAreaInsets()

  // Only show instances that are in NEEDS_ATTENTION, CONNECTING, or SUCCESS state.  Instances in null or READY states
  // are not directly accessible- if the user can navigate to them, great.  If not, they dangle and are periodically
  // reaped.
  return (
    // TODO: this toy multiplexer screen is never accessible to the end user.  Style it correctly and make
    // it accessible.
    <View
      style={[
        multiplexerStyle.background,
        {
          // Padding to handle safe area for the MX multiplexer screen.
          // We don't need to use top insets because the MX multiplexer screen has a react navigation header, but we
          // do need bottom insets because the MX multiplexer screen doesn't have react navigation tabs at the bottom.
          paddingBottom: insets.bottom,
          paddingLeft: insets.left,
          paddingRight: insets.right
        }
      ]}
    >
      <View style={multiplexerStyle.instanceCardsContainer}>
        {/* viewable list linking to instance goes here.  Spread syntax iterates in insertion order (good) */}
        <ScrollView>
          {/* Completed state */}
          {[...connectInstanceStates].map(([key, state]) => {
            if (
              state.reportedState.connectionState ===
              ConnectionState.SUCCESSFULLY_CONNECTED
            ) {
              return (
                <View
                  key={key}
                  style={[
                    viewStyle.smallTopMargin,
                    multiplexerStyle.singleRowContainer
                  ]}
                >
                  <View style={multiplexerStyle.successCardContainer}>
                    <Card
                      borderColor={THEME.color.outline}
                      backgroundColor={THEME.color.highlight}
                    >
                      <View style={multiplexerStyle.cardContents}>
                        <View style={multiplexerStyle.cardIconContainer}>
                          <BigCheckMark />
                        </View>
                        <View style={multiplexerStyle.cardTextContainer}>
                          <Text
                            style={[textStyle.regularText, textStyle.boldText]}
                          >
                            {state.reportedState.selectedInstitution ??
                              'Completed Connection'}
                          </Text>
                          <Text style={textStyle.smallText}>Success!</Text>
                        </View>
                      </View>
                    </Card>
                  </View>
                </View>
              )
            } else {
              return null
            }
          })}

          {/* Connecting and Needs Attention instances */}
          {[...connectInstanceStates].map(([key, state]) => {
            if (
              state.reportedState.connectionState ===
                ConnectionState.CONNECTING ||
              state.reportedState.connectionState ===
                ConnectionState.NEEDS_ATTENTION
            ) {
              return (
                <CardWithClose
                  key={key}
                  connectionState={state.reportedState.connectionState}
                  onClose={() => {
                    setQuestions([
                      [
                        removeMxConnectInstanceQuestion(() => {
                          removeConnectInstance(key)
                        }, state.reportedState.selectedInstitution)
                      ]
                    ])
                    setQuestionIndex(0)
                  }}
                  onClickCard={() => setVisibleConnectInstanceId(key)}
                  selectedInstitution={state.reportedState.selectedInstitution}
                />
              )
            } else {
              return null
            }
          })}
        </ScrollView>
        <View style={multiplexerStyle.footer}>
          <Pressable
            style={[
              buttonStyle(THEME.color.rainbow.darkBlue, THEME.color.outline)
                .smallButton,
              multiplexerStyle.buttonBottomMargin
            ]}
            onPress={() => {
              // We could further optimize this by always pre-loading an 'add account' instance in the background...
              // then the user would never have to wait for the connection to open.
              // However, it could also lead to a staleness issue if that instance is only opened much later (in which
              // case the user would just exit and re-add the next newly preloaded instance)
              const key = addNewConnectInstance(
                {
                  nextButtonText: 'Done',
                  onPressNext: () => {
                    setVisibleConnectInstanceId(null)
                  }
                },
                /* inInitialNavigationChain= */ false
              )
              setVisibleConnectInstanceId(key)
            }}
          >
            <Text style={[textStyle.regularText, textStyle.boldText]}>
              Add another
            </Text>
          </Pressable>
          <Pressable
            onPress={() => {
              if (finalButtonEnabled) {
                props.route.params?.finalNavigation(props.navigation)
              }
            }}
            style={[
              buttonStyle(
                finalButtonEnabled
                  ? THEME.color.highlight
                  : THEME.color.disabled,
                THEME.color.outline
              ).smallButton,
              multiplexerStyle.buttonBottomMargin
            ]}
          >
            <Text style={[textStyle.regularText, textStyle.boldText]}>
              Done linking accounts
            </Text>
          </Pressable>
          {finalButtonEnabled ? null : (
            <Text
              style={[
                textStyle.smallText,
                { paddingHorizontal: THEME.spacing.horizontalSpaceMedium }
              ]}
            >
              All accounts must finish linking before you can continue
            </Text>
          )}
        </View>
      </View>
      {/* list of actual instances goes here.  At most one connection is visible at one time.
            Spread syntax iterates in insertion order (good)  */}
      {[...connectInstanceStates].map(
        ([key, { instanceData, navigationData }]) => {
          return (
            <View
              key={key}
              style={[
                multiplexerStyle.instance,
                {
                  // We originally used display:none here but ran into issues with the nested iframes seemingly
                  // "lazy loading" (see https://moneydesktop.zendesk.com/hc/en-us/requests/1038502) and not
                  // updating/firing post messages until they were re-displayed (which obviously makes the multiplexer
                  // useless).  We'll try zIndex instead and see if the problem persists.
                  zIndex: key === visibleConnectInstanceId ? 1 : -1
                }
              ]}
            >
              <MxConnect
                connectInstance={instanceData}
                navigationData={navigationData}
                connectionStateHandler={generateStateHandler(key)}
                selectedInstitutionHandler={(
                  institutionName: string | null
                ) => {
                  setConnectInstanceStates((draft) => {
                    const relevantConnectionStatus = draft.get(key)
                    if (isNonNullish(relevantConnectionStatus)) {
                      relevantConnectionStatus.reportedState.selectedInstitution =
                        institutionName
                    }
                  })
                }}
              />
            </View>
          )
        }
      )}
      {isNonNullish(questionIndex) &&
      questionIndex >= 0 &&
      questionIndex < questions.length ? (
        <QuestionOverlay
          questionIndex={questionIndex}
          setQuestionIndex={setQuestionIndex}
          questions={questions}
        />
          ) : null}
    </View>
  )
}
