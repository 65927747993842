import * as React from "react"
import RefreshCircle  from './refresh-circle'
import { Animated, Easing } from 'react-native'

export function SpinningRefreshCircle(): JSX.Element {
  const spinValue = React.useRef(new Animated.Value(0)).current
  const degrees = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  })

  React.useEffect(() => {
    Animated.loop(
      Animated.timing(
        spinValue,
        {
         toValue: 1,
         duration: 2000,
         easing: Easing.linear,
         useNativeDriver: false // TODO: update this to true for native apps
        }
      )
     ).start()
  }, [spinValue])

  return (
    <Animated.View
      style={{
        transform: [{rotate: degrees}, { perspective: 1000 }],
        flex: 1
      }}
    >
      <RefreshCircle/>
    </Animated.View>
  )
}
