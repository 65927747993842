import { isNonNullish } from 'global-utils'

// Returns a task title of varying detail based on the provided parameters.
// typename is required and results in a basic title.
// taskStepsCompletedAtLeastOnce, contributionYear and accountName are optional and
// provide increased specificity for some accounts.
// TODO: This logic should arguably be in each specific Task Flow Data handler on the task screen (i.e. the
// callsites of this function).  That pattern makes it harder to access task titles in other contexts (like
// on the jungle screen task box).  Consider what a lean, dedicated "task view" graphql query would look like that would
// allow us to generate task titles outside the TaskScreen.
export function getTaskTitle (
  taskTypename: string,
  taskStepsCompletedAtLeastOnce?: boolean,
  contributionYear?: number,
  accountName?: string
): string {
  const accountNameString = isNonNullish(accountName)
    ? ` — ${accountName}`
    : ''
  const contributionYearString = isNonNullish(contributionYear)
    ? ` (for ${contributionYear.toString()})`
    : ''

  switch (taskTypename) {
    case 'BuildOneMonthEmergencyFundTask':
      return 'Start your emergency fund'
    case 'ContributeTo401kTask':
    case 'ContributeTo401k2022Task': {
      return `${
        taskStepsCompletedAtLeastOnce === true
          ? 'Contribute MOARRRRRR to your 401k'
          : 'Contribute to your 401k'
      }${accountNameString}${contributionYearString}`
    }
    case 'ContributeToIraTask':
    case 'ContributeToIra2022Task': {
      return `Contribute to your IRA${contributionYearString}`
    }
    case 'ExtendEmergencyFundToSixMonthsTask':
      return 'Finish your emergency fund'
    case 'ExtendEmergencyFundToThreeMonthsTask':
      return 'Expand your emergency fund'
    case 'InvestInBrokerageAccountTask':
      return 'Invest in your brokerage account'
    case 'PayCriticalExpensesTask':
      return 'Pay your critical expenses'
    case 'PayOffDebtTask':
      return `Pay off debt${accountNameString}`
    default:
      return ''
  }
}

// Returns the task title as a gerund phrase of varying detail based on the provided parameters.
// (e.g. "starting your emergency fund")
// typename is required and results in a basic title.
// taskStepsCompletedAtLeastOnce, contributionYear and accountName are optional and
// provide increased specificity for some accounts.
export function getTaskTitleAsGerund (
  taskTypename: string,
  taskStepsCompletedAtLeastOnce?: boolean,
  contributionYear?: number,
  accountName?: string
): string {
  const contributionYearString = isNonNullish(contributionYear)
    ? ` for ${contributionYear.toString()}`
    : ''

  switch (taskTypename) {
    case 'BuildOneMonthEmergencyFundTask':
      return 'starting your emergency fund'
    case 'ContributeTo401kTask':
    case 'ContributeTo401k2022Task': {
      return `${
        taskStepsCompletedAtLeastOnce === true
          ? 'contributing even more to your 401k'
          : 'contributing to your 401k'
      }${
        isNonNullish(accountName) ? ` in your ${accountName} account` : ''
      }${contributionYearString}`
    }
    case 'ContributeToIraTask':
    case 'ContributeToIra2022Task': {
      return `contributing to your IRA${contributionYearString}`
    }
    case 'ExtendEmergencyFundToSixMonthsTask':
      return 'finishing your emergency fund'
    case 'ExtendEmergencyFundToThreeMonthsTask':
      return 'expanding your emergency fund'
    case 'InvestInBrokerageAccountTask':
      return 'investing in your brokerage account'
    case 'PayCriticalExpensesTask':
      return 'paying your critical expenses'
    case 'PayOffDebtTask':
      return `paying off your debt${
        isNonNullish(accountName) ? ` on your ${accountName} account` : ''
      }`
    default:
      return ''
  }
}
