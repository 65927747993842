import { Auth } from 'aws-amplify'

// Returns a promise with the username token. We use a promise here as a placeholder for more complicated privacy
// logic surrounding id access- passing a raw id string around is an anti-pattern as it proliferates so easily.
// PLEASE ONLY ACCESS THE RAW USERNAME TOKEN AT THE IMMEDIATE POINT OF CONSUMPTION!
// Intentionally does not catch errors, and lets the caller catch them, so we can also return errors for e.g.
// 'access denied due to privacy'.
async function loadToken (): Promise<string> {
  const session = await Auth.currentSession()
  return session.getAccessToken().decodePayload().username
}

export { loadToken }
