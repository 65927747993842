import * as React from 'react'
import {
  TaskStepsData,
  TaskStepsRenderingStyle
} from '../../tasks/task-flow-data'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { Anchor } from '../../common/links/anchor'
import { NavigationAnchor } from '../../common/links/navigation-anchor'
import { ContentModule } from '../click-through-module-screen'

export const investmentBackground: TaskStepsData = {
  stepRenderingStyle: TaskStepsRenderingStyle.CLICK_THROUGH_MODULE,
  title: 'Intro to Investing',
  description: '',
  subtasks: [
    {
      key: 'INTRO',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          For many of us, finances weren't talked about much as we grew up, and
          some topics like investing might be a little spooky 👻 as a result.
          But not to worry! We'll cover investing basics here and help you get
          comfortable 😌 with letting your money grow for you over time.
        </>
      )
    },
    {
      key: 'SAVE_V_INVEST',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          There's a big difference between "saving" and "investing". Saving is
          the first step where you spend less than you make and have some extra
          cash sitting around. Investing is the next step where you put your
          savings to work. This extra step is essential to building your
          long-term wealth!{'\n\n'}Let's say you have $10,000 saved in a decent
          savings account, yielding 0.5% annually. After 30 years, you'd have
          $11,614 sitting in that account. But if we were to invest that money
          in the stock market, averaging about 10% annually, we'd have $174,494
          instead - a 15x difference!{'\n\n'}
          You might say, "Well, Walkthrough, that's all fine and good, but how
          do I invest?". Don't worry, that's up next!
        </>
      )
    },
    {
      key: 'STOCKS_BONDS',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          We need only two types of investments to get our money working for us:
          stocks and bonds.{'\n\n'}• Stocks, also known as equity, are little
          pieces of companies. When you own them, you own part of a company!
          They historically have grown at about 10% every year, but they are
          more volatile.{'\n\n'}• Bonds are loans you make to different groups,
          like companies or governments. Over time, those groups pay you back
          the money you lent them, plus some interest — to the tune of about 6%
          growth annually.{'\n\n'}
          We combine these two assets together because historically, when one
          goes up, the other goes down a bit. This helps us smooth our growth
          over time. The marriage of stocks and bonds together have been one of
          the main engines of wealth creation throughout history.
        </>
      )
    },
    {
      key: 'PASSIVE',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          Now that we know we need stocks and bonds, how do we go about choosing
          them?{'\n\n'}Some folks who work on Wall Street or read Reddit too
          much think they are quite good at picking what stocks will go up and
          down, but research shows that{' '}
          <Anchor
            text="literal monkeys can do better than they can."
            href="https://www.rock-wealth.co.uk/monkeys-beat-money-managers-at-selling-stocks/"
          />
          {'\n\n'}
          We don't like to get clowned by monkeys, so we recommend investing in
          passive, low-cost index funds. These funds spread your money across
          thousands of companies around the world. The balance between the
          companies follows a well-established rule (called an index) and is not
          actively decided by humans — that's why they're "passive" (and cheap!)
        </>
      )
    },
    {
      key: 'DIVERSE ',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          It's really hard to figure out what makes a given company a "good"
          investment. You have to think about all sorts of things — Profit?
          Competitors? Employee and customer satisfaction? Market trends? Future
          demand? And all this for just one company!{'\n\n'}Index funds solve
          this problem for us through diversification. When we invest our money
          in stocks of thousands of companies (that's what an index fund does,
          remember?), and then further invest across additional assets like
          bonds, we don't need to think about the failure or success of any
          single one. Throughout history, the U.S. and global economies have
          consistently grown, and it makes sense to believe they'll keep doing
          just that.
        </>
      )
    },
    {
      key: 'TIMING ',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          You might wonder about buying into the market at the "wrong time."
          Here's the simple rule:{'\n\n'}• Any time you have extra money to
          invest, invest it now.{'\n\n'}• After you buy, HOLD, don't sell! It
          does not matter if the market goes down or up — that's just short-term
          noise. You're here for the long-term growth.{'\n\n'}This rule gives
          your money the longest possible time to grow in the market and work
          its magic. If you want to read more about why time in the market is
          much more important than timing the market, check out this great{' '}
          <Anchor
            text="article"
            href="https://jlcollinsnh.com/2012/04/19/stocks-part-ii-the-market-always-goes-up/"
          />
          .
        </>
      )
    },
    {
      key: 'DONE ',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          That's it! Those are the basic principles of investing. Hopefully
          you're feeling good about them at this point. If you think there are
          big things missing, or you have lots more questions, feel free to
          reach out to us at{' '}
          <Anchor
            text={'support@walkthrough.co'}
            href={'mailto:support@walkthrough.co'}
          />
          .{'\n\n'}
          Your next step is to figure out the right balance between stocks and
          bonds for you and put your money to work — we'll help you out with
          that when you get there (learn more about portfolio allocation{' '}
          <NavigationAnchor
            text="here"
            handlePress={(nav) =>
              nav.push('ClickThroughModuleScreen', {
                module: ContentModule.PORTFOLIO_ALLOCATION
              })
            }
          />
          )!
        </>
      )
    }
  ],
  button: {
    buttonText: 'Done',
    handleOnPressButton: (props: {
      navigation: GenericCompositeNavigationProp
    }) => {
      props.navigation.goBack()
    }
  }
}
