import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { AnswerType, QuestionAndAnswers } from '../question-data'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import {
  getNumberFromDollarString,
  isWholeDollarString
} from '../../../../../util/input-validators'
import { AccountWithSnapshotShape } from 'multi-type-processor'
import { accountNameWithBalanceFallback } from '../../../../../util/account-parsing'
import { formatISO, format, parseISO } from 'date-fns'
import { isNonNullish } from 'global-utils'

// Switch based on account typename to create a new snapshot with the associated balance.
export async function storeNewAccountSnapshot (
  client: ApolloClient<NormalizedCacheObject>,
  account: Omit<WalkthroughGraphQL.Account, '__typename'> & {
    __typename: string
  } & AccountWithSnapshotShape,
  balance: number
): Promise<void> {
  const snapshotIsAsset =
    account.__typename === 'DebtAccount'
      ? WalkthroughGraphQL.AssetType.LIABILITY
      : WalkthroughGraphQL.AssetType.ASSET

  switch (account.__typename) {
    case 'SavingsAccount': {
      await client.mutate<
      WalkthroughGraphQL.CreateSavingsAccountSnapshotMutation,
      WalkthroughGraphQL.CreateSavingsAccountSnapshotMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createSavingsAccountSnapshot),
        variables: {
          input: {
            accountId: account.accountId,
            balance: balance,
            isAsset: snapshotIsAsset,
            lastUpdatedOnProviderSide: formatISO(Date.now())
          }
        }
      })
      return
    }
    case 'CheckingAccount': {
      await client.mutate<
      WalkthroughGraphQL.CreateCheckingAccountSnapshotMutation,
      WalkthroughGraphQL.CreateCheckingAccountSnapshotMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createCheckingAccountSnapshot),
        variables: {
          input: {
            accountId: account.accountId,
            balance: balance,
            isAsset: snapshotIsAsset,
            lastUpdatedOnProviderSide: formatISO(Date.now())
          }
        }
      })
      return
    }
    case 'DebtAccount': {
      await client.mutate<
      WalkthroughGraphQL.CreateDebtAccountSnapshotMutation,
      WalkthroughGraphQL.CreateDebtAccountSnapshotMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createDebtAccountSnapshot),
        variables: {
          input: {
            accountId: account.accountId,
            balance: balance,
            isAsset: snapshotIsAsset,
            lastUpdatedOnProviderSide: formatISO(Date.now())
          }
        }
      })
      return
    }
    case 'k401Account': {
      await client.mutate<
      WalkthroughGraphQL.CreateK401AccountSnapshotMutation,
      WalkthroughGraphQL.CreateK401AccountSnapshotMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createK401AccountSnapshot),
        variables: {
          input: {
            accountId: account.accountId,
            balance: balance,
            isAsset: snapshotIsAsset,
            lastUpdatedOnProviderSide: formatISO(Date.now())
          }
        }
      })
      return
    }
    case 'TraditionalIraAccount': {
      await client.mutate<
      WalkthroughGraphQL.CreateTraditionalIraAccountSnapshotMutation,
      WalkthroughGraphQL.CreateTraditionalIraAccountSnapshotMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createTraditionalIraAccountSnapshot),
        variables: {
          input: {
            accountId: account.accountId,
            balance: balance,
            isAsset: snapshotIsAsset,
            lastUpdatedOnProviderSide: formatISO(Date.now())
          }
        }
      })
      return
    }
    case 'RothIraAccount': {
      await client.mutate<
      WalkthroughGraphQL.CreateRothIraAccountSnapshotMutation,
      WalkthroughGraphQL.CreateRothIraAccountSnapshotMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createRothIraAccountSnapshot),
        variables: {
          input: {
            accountId: account.accountId,
            balance: balance,
            isAsset: snapshotIsAsset,
            lastUpdatedOnProviderSide: formatISO(Date.now())
          }
        }
      })
      return
    }
    case 'OtherInvestmentAccount': {
      await client.mutate<
      WalkthroughGraphQL.CreateOtherInvestmentAccountSnapshotMutation,
      WalkthroughGraphQL.CreateOtherInvestmentAccountSnapshotMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createOtherInvestmentAccountSnapshot),
        variables: {
          input: {
            accountId: account.accountId,
            balance: balance,
            isAsset: snapshotIsAsset,
            lastUpdatedOnProviderSide: formatISO(Date.now())
          }
        }
      })
      return
    }
    default: {
      throw new Error(
        'In storeNewAccountSnapshot: Unrecognized __typename: ' +
          account.__typename
      )
    }
  }
}

// Question for updating the balance of a manual account. Optional argument promptingBecauseStaleData
// displays more context about why we're asking for this balance.
export const updateAccountBalanceQuestion: (
  account: Omit<WalkthroughGraphQL.Account, '__typename'> & {
    __typename: string
  } & AccountWithSnapshotShape,
  promptingBecauseStaleData?: boolean
) => QuestionAndAnswers = (account, promptingBecauseStaleData = false) => {
  // We do not use getBalanceFromLastSnapshot here because we explicitly always want to show a positive
  // number for the purposes of this question (even for debt accounts).
  const balance = account.snapshots?.items[0]?.balance ?? 0
  let asOfDateString = ''
  const lastUpdated = account.snapshots?.items[0]?.lastUpdatedOnProviderSide
  if (isNonNullish(lastUpdated)) {
    const balanceString = balance.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    asOfDateString =
      '\n\n(It was ' +
      balanceString +
      ' as of ' +
      format(parseISO(lastUpdated), 'MMM d') +
      ')'
  }

  return {
    key: 'ACCOUNT_BALANCE_QUESTION',
    question: {
      text: (
        <>
          {promptingBecauseStaleData
            ? "It's been a while since you've updated your " +
              (accountNameWithBalanceFallback(account) ?? '') +
              " account... what's its current balance?"
            : 'What is the current balance of your ' +
              (accountNameWithBalanceFallback(account) ?? '') +
              ' account?'}
          {asOfDateString}
        </>
      )
    },
    answers: [
      {
        key: 'BALANCE_ANSWER',
        getInitialAnswer: async () => {
          return '$' + balance.toString() // Custom formatting without commas
        },
        storeValue: async (client, value) => {
          if (!isWholeDollarString(value)) {
            console.log(
              'Provided value (' + value + ') is not an amount in dollars > 0.'
            )
          } else {
            await storeNewAccountSnapshot(
              client,
              account,
              getNumberFromDollarString(value)
            )
          }
        },
        validation: {
          isValid: (answer: string) => {
            return isWholeDollarString(answer)
          },
          notValidInfoText: 'Contribution must be a dollar amount > 0.'
        },
        answerType: AnswerType.TEXT,
        textAnswer: {
          placeholderText: '$XXXX'
        }
      }
    ]
  }
}
