"use strict";
/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelSortDirection = exports.TaxFilingStatus = exports.EmploymentStatus = exports.EarmarkType = exports.TransactionAggregationType = exports.DataProviderActionNeededStatus = exports.AssetType = exports.DataProvider = exports.ModelAttributeTypes = void 0;
var ModelAttributeTypes;
(function (ModelAttributeTypes) {
    ModelAttributeTypes["binary"] = "binary";
    ModelAttributeTypes["binarySet"] = "binarySet";
    ModelAttributeTypes["bool"] = "bool";
    ModelAttributeTypes["list"] = "list";
    ModelAttributeTypes["map"] = "map";
    ModelAttributeTypes["number"] = "number";
    ModelAttributeTypes["numberSet"] = "numberSet";
    ModelAttributeTypes["string"] = "string";
    ModelAttributeTypes["stringSet"] = "stringSet";
    ModelAttributeTypes["_null"] = "_null";
})(ModelAttributeTypes = exports.ModelAttributeTypes || (exports.ModelAttributeTypes = {}));
var DataProvider;
(function (DataProvider) {
    DataProvider["YODLEE"] = "YODLEE";
    DataProvider["MX"] = "MX";
    DataProvider["WALKTHROUGH_MANUALLY_DECLARED"] = "WALKTHROUGH_MANUALLY_DECLARED";
})(DataProvider = exports.DataProvider || (exports.DataProvider = {}));
var AssetType;
(function (AssetType) {
    AssetType["ASSET"] = "ASSET";
    AssetType["LIABILITY"] = "LIABILITY";
})(AssetType = exports.AssetType || (exports.AssetType = {}));
var DataProviderActionNeededStatus;
(function (DataProviderActionNeededStatus) {
    DataProviderActionNeededStatus["NO_ACTION_REQUIRED_AFFIRMATIVE_SUCCESS"] = "NO_ACTION_REQUIRED_AFFIRMATIVE_SUCCESS";
    DataProviderActionNeededStatus["RECHECK_WITH_DATA_PROVIDER"] = "RECHECK_WITH_DATA_PROVIDER";
    DataProviderActionNeededStatus["ERROR_TRIGGER_DATA_PROVIDER_FULL_REPULL"] = "ERROR_TRIGGER_DATA_PROVIDER_FULL_REPULL";
    DataProviderActionNeededStatus["REQUEST_USER_GO_TO_FI_SITE"] = "REQUEST_USER_GO_TO_FI_SITE";
    DataProviderActionNeededStatus["REQUEST_USER_PROVIDE_REFRESH_DATA"] = "REQUEST_USER_PROVIDE_REFRESH_DATA";
    DataProviderActionNeededStatus["REQUEST_USER_EDIT_ACCOUNT_LINK"] = "REQUEST_USER_EDIT_ACCOUNT_LINK";
    DataProviderActionNeededStatus["ALERT_USER_CONNECTION_HAS_BEEN_DISABLED"] = "ALERT_USER_CONNECTION_HAS_BEEN_DISABLED";
})(DataProviderActionNeededStatus = exports.DataProviderActionNeededStatus || (exports.DataProviderActionNeededStatus = {}));
var TransactionAggregationType;
(function (TransactionAggregationType) {
    TransactionAggregationType["TRADITIONAL_IRA_PRETAX_CONTRIBUTIONS_2022"] = "TRADITIONAL_IRA_PRETAX_CONTRIBUTIONS_2022";
    TransactionAggregationType["ROTH_IRA_CONTRIBUTIONS_2022"] = "ROTH_IRA_CONTRIBUTIONS_2022";
    TransactionAggregationType["TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2022"] = "TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2022";
    TransactionAggregationType["TRADITIONAL_401K_POST_TAX_CONTRIBUTIONS_2022"] = "TRADITIONAL_401K_POST_TAX_CONTRIBUTIONS_2022";
    TransactionAggregationType["ROTH_401K_CONTRIBUTIONS_2022"] = "ROTH_401K_CONTRIBUTIONS_2022";
    TransactionAggregationType["TRADITIONAL_IRA_PRETAX_CONTRIBUTIONS_2023"] = "TRADITIONAL_IRA_PRETAX_CONTRIBUTIONS_2023";
    TransactionAggregationType["ROTH_IRA_CONTRIBUTIONS_2023"] = "ROTH_IRA_CONTRIBUTIONS_2023";
    TransactionAggregationType["TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2023"] = "TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2023";
    TransactionAggregationType["TRADITIONAL_401K_POST_TAX_CONTRIBUTIONS_2023"] = "TRADITIONAL_401K_POST_TAX_CONTRIBUTIONS_2023";
    TransactionAggregationType["ROTH_401K_CONTRIBUTIONS_2023"] = "ROTH_401K_CONTRIBUTIONS_2023";
})(TransactionAggregationType = exports.TransactionAggregationType || (exports.TransactionAggregationType = {}));
var EarmarkType;
(function (EarmarkType) {
    EarmarkType["CASH_EMERGENCY_FUND"] = "CASH_EMERGENCY_FUND";
    EarmarkType["EXTEND_EMERGENCY_FUND_TO_SIX_MONTHS"] = "EXTEND_EMERGENCY_FUND_TO_SIX_MONTHS";
})(EarmarkType = exports.EarmarkType || (exports.EarmarkType = {}));
var EmploymentStatus;
(function (EmploymentStatus) {
    EmploymentStatus["UNEMPLOYED"] = "UNEMPLOYED";
    EmploymentStatus["EMPLOYED"] = "EMPLOYED";
    EmploymentStatus["SELF_EMPLOYED"] = "SELF_EMPLOYED";
})(EmploymentStatus = exports.EmploymentStatus || (exports.EmploymentStatus = {}));
var TaxFilingStatus;
(function (TaxFilingStatus) {
    TaxFilingStatus["SINGLE"] = "SINGLE";
    TaxFilingStatus["MARRIED_FILING_JOINTLY"] = "MARRIED_FILING_JOINTLY";
    TaxFilingStatus["MARRIED_FILING_SEPARATELY"] = "MARRIED_FILING_SEPARATELY";
    TaxFilingStatus["HEAD_OF_HOUSEHOLD"] = "HEAD_OF_HOUSEHOLD";
    TaxFilingStatus["QUALIFYING_WIDOW_ER"] = "QUALIFYING_WIDOW_ER";
})(TaxFilingStatus = exports.TaxFilingStatus || (exports.TaxFilingStatus = {}));
var ModelSortDirection;
(function (ModelSortDirection) {
    ModelSortDirection["ASC"] = "ASC";
    ModelSortDirection["DESC"] = "DESC";
})(ModelSortDirection = exports.ModelSortDirection || (exports.ModelSortDirection = {}));
