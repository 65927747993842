import {
  validateAccountForHandler,
  InputAccountTypesMemberLatestFinancialViewQuery
} from 'multi-type-processor'
import { AccountQuestionAlertHandlerType } from './get-account-question-alerts'
import { isNonNullish } from 'global-utils'
import { apyQuestion } from '../../common/questions/content/apy-question'
import { interestInactiveQuestion } from '../../common/questions/content/interest-inactive-question'
import { manualAccountBalanceRepromptHandler } from './manual-account-balance-reprompt-handler'

export const debtAccountQuestionAlertsHandler: AccountQuestionAlertHandlerType<
InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
> = (account) => {
  try {
    // This if block allows us to assume that account is nonullable further down
    if (!validateAccountForHandler(account, 'debtAccountHandler')) {
      return null
    }
  } catch (e) {
    return null
  }

  const questionsAndAnswers =
    manualAccountBalanceRepromptHandler(account) ?? []

  // Add a question to get a memberDeclaredInterestAPY if we don't have a interestAPY, interestImprecise, or
  // memberDeclaredInterestAPY already stored
  if (
    isNonNullish(account.snapshots) &&
    account.snapshots?.items?.length > 0 &&
    !isNonNullish(account.snapshots.items[0]?.interestAPY) &&
    !isNonNullish(account.snapshots.items[0]?.interestImprecise) &&
    !isNonNullish(account.memberDeclaredInterestAPY)
  ) {
    questionsAndAnswers.push([apyQuestion(account)])
  }

  // Add a question about whether interest is being charged on the debt, if the member hasn't yet declared it.
  if (!isNonNullish(account.memberDeclaredInterestInactive)) {
    questionsAndAnswers.push([interestInactiveQuestion(account)])
  }
  return questionsAndAnswers
}
