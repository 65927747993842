import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const determineNextTaskStyle = StyleSheet.create({
  taskBox: {
    height: '90%',
    width: '100%',
    borderRadius: 20,
    backgroundColor: THEME.color.surfaceOne,
    borderColor: THEME.color.outline,
    borderWidth: 2
  },
  contentBox: {
    margin: THEME.spacing.horizontalSpaceMedium,
    alignItems: 'center',
    // To make space for the alternative task button, which has an absolute
    // position (it's always at the bottom of the taskBox).
    marginBottom:
      THEME.spacing.horizontalSpaceMedium + THEME.spacing.verticalSpaceSmall * 2
  },
  titleBox: {
    width: '100%',
    paddingBottom: THEME.spacing.verticalSpaceSmall
  },
  subContentBox: {
    width: '100%',
    marginBottom: THEME.spacing.verticalSpaceSmall
  },
  subContentMessage: {
    color: THEME.color.onSurface,
    fontSize: THEME.font.fontSizeMedium,
    fontFamily: 'PublicSans_400Regular'
  },
  errorMessage: {
    color: THEME.color.superHighlight,
    fontSize: THEME.font.fontSizeMedium,
    fontFamily: 'PublicSans_400Regular'
  },
  alternativeButton: {
    position: 'absolute',
    bottom: THEME.spacing.verticalSpaceExtraExtraSmall,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  alternativeButtonText: {
    color: THEME.color.onSurface,
    fontSize: THEME.font.fontSizeSmall,
    fontFamily: 'PublicSans_400Regular',
    textDecorationLine: 'underline'
  }
})
