import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { Text } from 'react-native'
import { FadeElementGroup } from '../../determine-next-task/fade-element-groups'
import YuyuWrenchAndWheel from '../../../../../assets/yuyu/wrench-and-wheel'

export function getHasAnEmergencyFundElementGroup (
  data: WalkthroughGraphQL.GetMemberQuery
): FadeElementGroup {
  const hasOneMonthEmergencyFund =
    data.getMember?.hasOneMonthEmergencyFundMemberDeclared === true ||
    data.getMember?.buildOneMonthEmergencyFundTask?.isComplete === true
  return {
    key: 'EMERGENCY_FUND',
    elements: [
      {
        key: 'EMERGENCY_FUND_LINE',
        element: (
          <Text>
            You {hasOneMonthEmergencyFund ? 'already have' : "don't yet have"} a
            one-month emergency fund!
          </Text>
        ),
        preFadeInDelay: 0
      }
    ],
    image: <YuyuWrenchAndWheel />
  }
}
