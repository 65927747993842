import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { FadeElementGroup } from '../../determine-next-task/fade-element-groups'
import { getCompletedTasksElementGroup } from './completed-tasks'
import { getDebtInterestElementGroup } from './debt-interest'
import { get401kMatchElementGroup } from './401k-match'
import { getTaxFilingStatusIncomeElementGroup } from './tax-filing-and-income'
import { getPrevious401kContributionElementGroup } from './401k-contributions'
import { getAgeBasedContributionLimitsElementGroup } from './contribution-limits'
import { getHasAnEmergencyFundElementGroup } from './emergency-fund'
import { generateRandomInteger } from 'global-utils'
import { getRandomFunElementGroup } from './fun'
import { getRoiOfTasksElementGroup } from './roi'
import { getSelectedTaskElementGroup } from './selected-task'

// Standard fade delays for all the handlers.  0th element should immediately render (it's usually the title).
// The 1th element should have a delay of kFirstElementFadeDelay (add suspense during 'calculation').
// The rest should have a delay of kStandardElementFadeDelay which is pretty quick ('list all the items we found').
// There's a long pause at the end so the user can read the completed screen.
export const kStandardElementFadeDelay = 500
export const kFirstElementFadeDelay = 2000

function getRandomFinancialAnalysisElementGroup (
  data: WalkthroughGraphQL.GetMemberQuery
): FadeElementGroup {
  // The null case is handled in the default case for the switch statement.
  // But this shouldn't return null because 0 and 3 are both integers.
  const random = generateRandomInteger(0, 3)
  switch (random) {
    case 0:
      return getTaxFilingStatusIncomeElementGroup(data)
    case 1:
      return getPrevious401kContributionElementGroup(data)
    case 2:
      return getAgeBasedContributionLimitsElementGroup(data)
    case 3:
      return getHasAnEmergencyFundElementGroup(data)
    // If random is null, return the emergency fund FadeElementGroup.
    default:
      return getHasAnEmergencyFundElementGroup(data)
  }
}

export function getDetermineNextTaskElementGroups (
  data: WalkthroughGraphQL.GetMemberQuery,
  selectedTaskTypename: string,
  selectedTaskPrimaryKey: string
): FadeElementGroup[] {
  const elementGroups: FadeElementGroup[] = []

  elementGroups.push(getCompletedTasksElementGroup(data))
  elementGroups.push(getDebtInterestElementGroup(data))
  elementGroups.push(get401kMatchElementGroup(data))
  elementGroups.push(getRandomFinancialAnalysisElementGroup(data))
  elementGroups.push(getRandomFunElementGroup())
  elementGroups.push(getRoiOfTasksElementGroup())
  elementGroups.push(
    getSelectedTaskElementGroup(
      data,
      selectedTaskTypename,
      selectedTaskPrimaryKey
    )
  )

  return elementGroups
}
