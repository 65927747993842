import * as React from 'react'
import { Text, View } from 'react-native'
import { isNonNullish } from 'global-utils'
import { textStyle } from '../../../../themes/global-styles.style'
import { drawerTitleStyle } from './drawer-title.style'
import SmallCheckMark from '../../../../assets/small-check-mark'

// A single drawer's title. If a value is passed in, it will be rendered right aligned.
export function DrawerTitle (props: {
  title: string
  value?: string
  isComplete?: boolean
}): JSX.Element {
  return (
    <View style={drawerTitleStyle.container}>
      {isNonNullish(props.isComplete) && props.isComplete ? (
        <View style={drawerTitleStyle.completeCheckMark}>
          <SmallCheckMark />
        </View>
      ) : null}
      <View style={drawerTitleStyle.titleView}>
        <Text style={[textStyle.regularText, textStyle.italicBoldText]}>
          {props.title}
        </Text>
      </View>
      {isNonNullish(props.value) ? (
        <View style={drawerTitleStyle.valueView}>
          <Text style={[textStyle.regularText, textStyle.boldText]}>
            {props.value}
          </Text>
        </View>
      ) : null}
    </View>
  )
}
