"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processAllAccountsFromMemberTasksAndAccountsQuery = exports.processAllAccountsFromMemberLatestFinancialViewQuery = exports.processAllAccountsFromGetMemberQuery = exports.getAccountsFromContainer = void 0;
const global_utils_1 = require("global-utils");
// Extracts all accounts from an account-containing object and packages them for processing.
// Null objects in arrays of objects are removed.
// If no Non-null task of a type exists, adds an array with a single null object.  This allows registration of handlers
// that process "account absent" signals.
function getAccountsFromContainer(container) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return {
        savingsAccounts: (0, global_utils_1.filterNullsAndPadEmpty)((_a = container === null || container === void 0 ? void 0 : container.savingsAccounts) === null || _a === void 0 ? void 0 : _a.items),
        checkingAccounts: (0, global_utils_1.filterNullsAndPadEmpty)((_b = container === null || container === void 0 ? void 0 : container.checkingAccounts) === null || _b === void 0 ? void 0 : _b.items),
        debtAccounts: (0, global_utils_1.filterNullsAndPadEmpty)((_c = container === null || container === void 0 ? void 0 : container.debtAccounts) === null || _c === void 0 ? void 0 : _c.items),
        otherInvestmentAccounts: (0, global_utils_1.filterNullsAndPadEmpty)((_d = container === null || container === void 0 ? void 0 : container.otherInvestmentAccounts) === null || _d === void 0 ? void 0 : _d.items),
        traditionalIraAccounts: (0, global_utils_1.filterNullsAndPadEmpty)((_e = container === null || container === void 0 ? void 0 : container.traditionalIraAccounts) === null || _e === void 0 ? void 0 : _e.items),
        rothIraAccounts: (0, global_utils_1.filterNullsAndPadEmpty)((_f = container === null || container === void 0 ? void 0 : container.rothIraAccounts) === null || _f === void 0 ? void 0 : _f.items),
        k401Accounts: (0, global_utils_1.filterNullsAndPadEmpty)((_g = container === null || container === void 0 ? void 0 : container.k401Accounts) === null || _g === void 0 ? void 0 : _g.items),
        uncategorizedAccounts: (0, global_utils_1.filterNullsAndPadEmpty)((_h = container === null || container === void 0 ? void 0 : container.uncategorizedAccounts) === null || _h === void 0 ? void 0 : _h.items)
    };
}
exports.getAccountsFromContainer = getAccountsFromContainer;
const processAllAccountsFromGetMemberQuery = (input, handlerMap) => {
    const ret = {
        savingsAccounts: [],
        checkingAccounts: [],
        debtAccounts: [],
        otherInvestmentAccounts: [],
        traditionalIraAccounts: [],
        rothIraAccounts: [],
        k401Accounts: [],
        uncategorizedAccounts: []
    };
    {
        const processorInputType = 'savingsAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'checkingAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'debtAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'otherInvestmentAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'traditionalIraAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'rothIraAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'k401Accounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'uncategorizedAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    return ret;
};
exports.processAllAccountsFromGetMemberQuery = processAllAccountsFromGetMemberQuery;
const processAllAccountsFromMemberLatestFinancialViewQuery = (input, handlerMap) => {
    const ret = {
        savingsAccounts: [],
        checkingAccounts: [],
        debtAccounts: [],
        otherInvestmentAccounts: [],
        traditionalIraAccounts: [],
        rothIraAccounts: [],
        k401Accounts: [],
        uncategorizedAccounts: []
    };
    {
        const processorInputType = 'savingsAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'checkingAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'debtAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'otherInvestmentAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'traditionalIraAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'rothIraAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'k401Accounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'uncategorizedAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    return ret;
};
exports.processAllAccountsFromMemberLatestFinancialViewQuery = processAllAccountsFromMemberLatestFinancialViewQuery;
const processAllAccountsFromMemberTasksAndAccountsQuery = (input, handlerMap) => {
    const ret = {
        savingsAccounts: [],
        checkingAccounts: [],
        debtAccounts: [],
        otherInvestmentAccounts: [],
        traditionalIraAccounts: [],
        rothIraAccounts: [],
        k401Accounts: [],
        uncategorizedAccounts: []
    };
    {
        const processorInputType = 'savingsAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'checkingAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'debtAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'otherInvestmentAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'traditionalIraAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'rothIraAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'k401Accounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    {
        const processorInputType = 'uncategorizedAccounts';
        const handler = handlerMap[processorInputType];
        ret[processorInputType] = input[processorInputType].map(handler);
    }
    return ret;
};
exports.processAllAccountsFromMemberTasksAndAccountsQuery = processAllAccountsFromMemberTasksAndAccountsQuery;
