import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish, collapseProcessingResultsIntoArray } from 'global-utils'
import { getTaskTitle } from '../get-task-title'
import { Text } from 'react-native'
import {
  FadeElement,
  FadeElementGroup
} from '../../determine-next-task/fade-element-groups'
import {
  getTasksFromContainer,
  processAllFinancialTasksFromGetMemberQuery,
  InputFinancialTaskTypesForGetMemberQuery
} from 'multi-type-processor'
import { accountNameWithBalanceFallback } from '../../../../../util/account-parsing'
import YuyuCheckList from '../../../../../assets/yuyu/check-list'
import {
  kFirstElementFadeDelay,
  kStandardElementFadeDelay
} from './get-determine-next-task-copy'

type CompletedTaskToFadeElementHandler<T> = (
  task: T | null
) => FadeElement | null

function completedTaskElementHandler<
  T extends Omit<WalkthroughGraphQL.FinancialTask, '__typename'> & {
    __typename: string
  }
> (key: string): CompletedTaskToFadeElementHandler<T> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      task.isComplete
    ) {
      return {
        key: key,
        element: <Text>{`✓ ${getTaskTitle(task.__typename)}`}</Text>,
        preFadeInDelay: kStandardElementFadeDelay
      }
    }
    return null
  }
}

function completed401kTaskElementHandler (): CompletedTaskToFadeElementHandler<WalkthroughGraphQL.ContributeTo401kTask> {
  return (task) => {
    const k401Account = task?.k401Account
    if (isNonNullish(task) && task.isComplete && isNonNullish(k401Account)) {
      return {
        key: `CONTRIBUTE_TO_401K:${task.id}`,
        element: (
          <Text>{`✓ ${getTaskTitle(
            task.__typename,
            task.stepsCompletedAtLeastOnce ?? undefined,
            task.contributionYear,
            accountNameWithBalanceFallback(k401Account) ?? undefined
          )}`}</Text>
        ),
        preFadeInDelay: kStandardElementFadeDelay
      }
    }
    return null
  }
}

function completedDebtTaskElementHandler (): CompletedTaskToFadeElementHandler<WalkthroughGraphQL.PayOffDebtTask> {
  return (task) => {
    const debtAccount = task?.debtAccount
    if (isNonNullish(task) && task.isComplete && isNonNullish(debtAccount)) {
      return {
        key: `PAY_OFF_DEBT:${task.id}}`,
        element: (
          <Text>{`✓ ${getTaskTitle(
            task.__typename,
            undefined,
            undefined,
            accountNameWithBalanceFallback(debtAccount) ?? undefined
          )}`}</Text>
        ),
        preFadeInDelay: kStandardElementFadeDelay
      }
    }
    return null
  }
}

function completedIraTaskElementHandler (): CompletedTaskToFadeElementHandler<WalkthroughGraphQL.ContributeToIraTask> {
  return (task) => {
    if (isNonNullish(task) && task.isComplete) {
      return {
        key: `CONTRIBUTE_TO_IRA:${task.id}`,
        element: (
          <Text>{`✓ ${getTaskTitle(
            task.__typename,
            undefined,
            task.contributionYear
          )}`}</Text>
        ),
        preFadeInDelay: kStandardElementFadeDelay
      }
    }
    return null
  }
}

// Gets a list of completed tasks in the form of a FadeElementGroup. These
// completed tasks will fade in one-by-one using the FadeInOutOneElementGroup
// component.
export function getCompletedTasksElementGroup (
  data: WalkthroughGraphQL.GetMemberQuery
): FadeElementGroup {
  const tasks = getTasksFromContainer(data.getMember)
  const handlers = {
    payCriticalExpensesTask: completedTaskElementHandler<
    InputFinancialTaskTypesForGetMemberQuery['payCriticalExpensesTask']
    >('PAY_CRITICAL_EXPENSES'),
    buildOneMonthEmergencyFundTask:
      completedTaskElementHandler<
      InputFinancialTaskTypesForGetMemberQuery['buildOneMonthEmergencyFundTask']
      >('ONE_MONTH_EFUND'),
    extendEmergencyFundToThreeMonthsTask: completedTaskElementHandler<
    InputFinancialTaskTypesForGetMemberQuery['extendEmergencyFundToThreeMonthsTask']
    >('EXTEND_EFUND_THREE_MONTHS'),
    extendEmergencyFundToSixMonthsTask: () => null, // Deregistered,
    investInBrokerageAccountTask: completedTaskElementHandler<
    InputFinancialTaskTypesForGetMemberQuery['investInBrokerageAccountTask']
    >('INVEST_IN_BROKERAGE'),
    payOffDebtTasks: completedDebtTaskElementHandler(),
    contributeTo401k2022Tasks: () => null, // Deregistered
    contributeTo401kTasks: completed401kTaskElementHandler(),
    contributeToIra2022Task: () => null, // Deregistered
    contributeToIraTasks: completedIraTaskElementHandler()
  }

  const completedTaskTitleElements = collapseProcessingResultsIntoArray(
    processAllFinancialTasksFromGetMemberQuery(tasks, handlers)
  ).filter(isNonNullish)

  if (completedTaskTitleElements.length > 0) {
    completedTaskTitleElements[0].preFadeInDelay = kFirstElementFadeDelay
    return {
      key: 'COMPLETED_TASKS',
      elements: [
        {
          key: 'COMPLETED_TASKS_TITLE',
          element: <Text>You’ve done a lot of things already!</Text>,
          preFadeInDelay: 0
        },
        ...completedTaskTitleElements
      ],
      image: <YuyuCheckList />
    }
  }
  return {
    key: 'NO_COMPLETED_TASKS',
    elements: [
      {
        key: 'NO_COMPLETED_TASKS_LINE',
        element: (
          <Text>
            It looks like you're just getting started, so all tasks are
            eligible!
          </Text>
        ),
        preFadeInDelay: 0
      }
    ],
    image: <YuyuCheckList />
  }
}
