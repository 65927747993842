import * as React from "react"
import Svg, { SvgProps, Path, Circle } from "react-native-svg"

const Wheel = (props: SvgProps): JSX.Element => (
  <Svg
    width={242}
    height={242}
    fill="none"
    {...props}
  >
    <Path
      d="M240 121a119.006 119.006 0 0 0-34.854-84.146L121 121h119Z"
      fill="#FF7B93"
    />
    <Path
      d="M205.146 36.854A119 119 0 0 0 121 2v119l84.146-84.146Z"
      fill="#FFBE68"
    />
    <Path d="M121 2a119 119 0 0 0-84.146 34.854L121 121V2Z" fill="#FDFF86" />
    <Path
      d="M36.854 36.854A119 119 0 0 0 2 121h119L36.854 36.854Z"
      fill="#78DE82"
    />
    <Path d="M2 121a119 119 0 0 0 34.854 84.146L121 121H2Z" fill="#22BEAB" />
    <Path
      d="M36.854 205.146A118.999 118.999 0 0 0 121 240V121l-84.146 84.146Z"
      fill="#0EC5FF"
    />
    <Path
      d="M121 240a119 119 0 0 0 84.146-34.854L121 121v119Z"
      fill="#458FFF"
    />
    <Path
      d="M205.146 205.146A119 119 0 0 0 240 121H121l84.146 84.146Z"
      fill="#6A6FF9"
    />
    <Circle cx={121} cy={121} r={120} stroke="#202232" strokeWidth={2} />
    <Circle cx={121} cy={121} r={9} fill="#202232" />
  </Svg>
)

export default Wheel
