import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import { isNonNullish } from 'global-utils'
import { textStyle } from '../../../../themes/global-styles.style'
import { rowWithEditStyle } from './row-with-edit.style'
import Edit from '../../../../assets/edit'

// A row with an edit button that is displayed in a drawer.
export function RowWithEdit (props: {
  title: string
  value: string
  handleOnPressEdit?: () => void
  isSubrow: boolean
  allowMoreSpaceForValue: boolean
}): JSX.Element {
  return (
    <View
      style={
        props.isSubrow
          ? rowWithEditStyle.subrowContainer
          : rowWithEditStyle.container
      }
    >
      <View
        style={[
          props.isSubrow
            ? rowWithEditStyle.subrowTitleContainer
            : rowWithEditStyle.titleContainer,
          props.allowMoreSpaceForValue
            ? rowWithEditStyle.allowLessSpaceForTitle
            : {}
        ]}
      >
        <Text style={textStyle.regularText}>{props.title}</Text>
      </View>
      {isNonNullish(props.value) ? (
        <View
          style={
            props.allowMoreSpaceForValue
              ? rowWithEditStyle.allowMoreSpaceForValue
              : rowWithEditStyle.valueContainer
          }
        >
          <Text style={textStyle.regularText}>{props.value}</Text>
        </View>
      ) : null}
      {isNonNullish(props.handleOnPressEdit) ? (
        <Pressable
          onPress={props.handleOnPressEdit}
          style={rowWithEditStyle.editIconContainer}
        >
          <Edit />
        </Pressable>
      ) : (
        // Keep an empty space if there is no edit button to keep values aligned.
        <View style={rowWithEditStyle.editIconContainer} />
      )}
    </View>
  )
}
