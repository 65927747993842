import { StyleSheet } from 'react-native'
import { THEME } from '../../../constants'

export const offlineStyle = StyleSheet.create({
  jungleContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: -3
  },
  topLevelContainer: {
    backgroundColor: THEME.color.background,
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100'
  },
  textContainer: {
    paddingTop: THEME.spacing.verticalSpaceLarge * 3,
    width: '100%',
    paddingLeft: THEME.spacing.horizontalSpaceMedium,
    paddingRight: THEME.spacing.horizontalSpaceMedium
  }
})
