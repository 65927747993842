import * as React from 'react'
import { Pressable, Text } from 'react-native'
import { THEME } from '../../../constants'
import {
  buttonStyle,
  textStyle,
  viewStyle
} from '../../../themes/global-styles.style'
import { TaskFlowStage } from './task-flow-data'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'

export function TaskListScreenDrawerContent (props: {
  taskTypename: string
  taskId: string
  text: string
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  return (
    <>
      <Text style={textStyle.regularText}>{props.text}</Text>
      <Pressable
        style={[
          buttonStyle(THEME.color.highlight, THEME.color.onSurface).smallButton,
          viewStyle.mediumTopMargin
        ]}
        onPress={() => {
          props.navigation.navigate('TaskScreen', {
            taskKey: {
              taskTypename: props.taskTypename,
              taskId: props.taskId
            },
            flowStage: TaskFlowStage.INTRO,
            recommendedByWalkthrough: false
          })
        }}
      >
        <Text style={[textStyle.regularText, textStyle.boldText]}>
          View this task
        </Text>
      </Pressable>
    </>
  )
}
