import * as React from 'react'
import { View, Text, Pressable, ScrollView } from 'react-native'
import { THEME } from '../../../constants'
import {
  buttonStyle,
  textStyle,
  viewStyle
} from '../../../themes/global-styles.style'
import { Card } from '../common/card/card'
import { RowWithEdit } from '../common/drawers/row-with-edit'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { AccountData } from './account-data'
import { accountDetailsStyle } from './account-details.style'
import { isNonNullish } from 'global-utils'

export function AccountDetails (props: {
  account: AccountData
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  return (
    <ScrollView style={accountDetailsStyle.overlayContainer}>
      <View
        style={[accountDetailsStyle.rowContainer, viewStyle.smallBottomMargin]}
      >
        <View style={accountDetailsStyle.balanceLabel}>
          <Text style={[textStyle.largeText, textStyle.boldText]}>
            Current balance
          </Text>
        </View>
        <View style={accountDetailsStyle.balance}>
          <Text style={[textStyle.largeText, textStyle.boldText]}>
            {props.account.balance}
          </Text>
        </View>
      </View>
      <View style={accountDetailsStyle.rowContainer}>
        <Pressable
          style={[
            buttonStyle(THEME.color.highlight, THEME.color.onSurface)
              .inlineButton,
            viewStyle.mediumRightMargin,
            {
              marginBottom: THEME.spacing.verticalSpaceExtraSmall
            }
          ]}
          onPress={props.account.handleOnPressEditAccountName}
        >
          <Text style={[textStyle.regularText, textStyle.boldText]}>
            Edit account name
          </Text>
        </Pressable>
        {isNonNullish(props.account.handleOnUpdateBalance) ? (
          <Pressable
            style={[
              buttonStyle(THEME.color.highlight, THEME.color.onSurface)
                .inlineButton,
              viewStyle.mediumRightMargin,
              {
                marginBottom: THEME.spacing.verticalSpaceExtraSmall
              }
            ]}
            onPress={props.account.handleOnUpdateBalance}
          >
            <Text style={[textStyle.regularText, textStyle.boldText]}>
              Update balance
            </Text>
          </Pressable>
        ) : null}
        {isNonNullish(props.account.handleOnPressEdit) ? (
          <Pressable
            style={[
              buttonStyle(THEME.color.highlight, THEME.color.onSurface)
                .inlineButton,
              viewStyle.mediumRightMargin,
              {
                marginBottom: THEME.spacing.verticalSpaceExtraSmall
              }
            ]}
            onPress={() => props.account.handleOnPressEdit?.(props.navigation)}
          >
            <Text style={[textStyle.regularText, textStyle.boldText]}>
              Edit
            </Text>
          </Pressable>
        ) : null}
        {isNonNullish(props.account.handleOnPressDelete) ? (
          <Pressable
            style={[
              buttonStyle(THEME.color.superHighlight, THEME.color.onSurface)
                .inlineButton,
              viewStyle.mediumRightMargin,
              {
                marginBottom: THEME.spacing.verticalSpaceExtraSmall
              }
            ]}
            onPress={props.account.handleOnPressDelete}
          >
            <Text style={[textStyle.regularText, textStyle.boldText]}>
              Delete
            </Text>
          </Pressable>
        ) : null}
      </View>
      <View
        style={{
          marginTop: THEME.spacing.verticalSpaceExtraExtraSmall
        }}
      >
        <Card>
          <View style={accountDetailsStyle.rowContainer}>
            <View style={accountDetailsStyle.updateLabel}>
              <Text style={[textStyle.regularText, textStyle.boldText]}>
                Last updated
              </Text>
            </View>
            <View style={accountDetailsStyle.lastUpdate}>
              <Text style={[textStyle.regularText]}>
                {props.account.lastUpdateString}
              </Text>
            </View>
          </View>
          <Text style={[textStyle.regularText, textStyle.italicText]}>
            If your account hasn't updated in a long time, try pressing the
            magic button in the Settings page.
          </Text>
        </Card>
      </View>
      {props.account.transactionAggregations.length > 0 ? (
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <View>
              <Text style={[textStyle.regularText, textStyle.boldText]}>
                Contributions
              </Text>
            </View>
            {props.account.transactionAggregations.map(
              (transactionAggregation) => {
                return (
                  <RowWithEdit
                    key={transactionAggregation.key}
                    title={transactionAggregation.title}
                    value={transactionAggregation.balance}
                    handleOnPressEdit={transactionAggregation.handleOnPressEdit}
                    isSubrow={true}
                    allowMoreSpaceForValue={false}
                  />
                )
              }
            )}
          </Card>
        </View>
      ) : null}
      {props.account.accountSettings.length > 0 ? (
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <View>
              <Text style={[textStyle.regularText, textStyle.boldText]}>
                Settings
              </Text>
            </View>
            {props.account.accountSettings.map((setting) => {
              return (
                <RowWithEdit
                  key={setting.key}
                  title={setting.title}
                  value={setting.value}
                  handleOnPressEdit={setting.handleOnPressEdit}
                  isSubrow={true}
                  allowMoreSpaceForValue={false}
                />
              )
            })}
          </Card>
        </View>
      ) : null}
    </ScrollView>
  )
}
