import { StyleSheet } from 'react-native'

// Shared text styling for content-construction.  Content construction technically is specified in
// TSX but should remain relatively style agnostic.  We specify text styling here as that is more part
// of the content than the abstract styling of the content *page*.

export const contentStyle = StyleSheet.create({
  boldText: {
    fontFamily: 'PublicSans_700Bold'
  },
  underlineText: {
    textDecorationLine: 'underline'
  },
  italicText: {
    fontFamily: 'PublicSans_400Regular_Italic'
  },
  boldItalicText: {
    fontFamily: 'PublicSans_700Bold_Italic'
  }
})
