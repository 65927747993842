export var CountryCode;
(function (CountryCode) {
    CountryCode["UK"] = "UK";
    CountryCode["GB"] = "GB";
    CountryCode["JE"] = "JE";
    CountryCode["GG"] = "GG";
    CountryCode["IM"] = "IM";
    CountryCode["US"] = "US";
    CountryCode["CA"] = "CA";
    CountryCode["IE"] = "IE";
    CountryCode["DE"] = "DE";
    CountryCode["JP"] = "JP";
    CountryCode["FR"] = "FR";
    CountryCode["AU"] = "AU";
    CountryCode["IT"] = "IT";
    CountryCode["CH"] = "CH";
    CountryCode["AT"] = "AT";
    CountryCode["ES"] = "ES";
    CountryCode["NL"] = "NL";
    CountryCode["BE"] = "BE";
    CountryCode["DK"] = "DK";
    CountryCode["SE"] = "SE";
    CountryCode["NO"] = "NO";
    CountryCode["BR"] = "BR";
    CountryCode["PT"] = "PT";
    CountryCode["FI"] = "FI";
    CountryCode["AX"] = "AX";
    CountryCode["KR"] = "KR";
    CountryCode["CN"] = "CN";
    CountryCode["TW"] = "TW";
    CountryCode["SG"] = "SG";
    CountryCode["DZ"] = "DZ";
    CountryCode["AD"] = "AD";
    CountryCode["AR"] = "AR";
    CountryCode["AM"] = "AM";
    CountryCode["AZ"] = "AZ";
    CountryCode["BH"] = "BH";
    CountryCode["BD"] = "BD";
    CountryCode["BB"] = "BB";
    CountryCode["BY"] = "BY";
    CountryCode["BM"] = "BM";
    CountryCode["BA"] = "BA";
    CountryCode["IO"] = "IO";
    CountryCode["BN"] = "BN";
    CountryCode["BG"] = "BG";
    CountryCode["KH"] = "KH";
    CountryCode["CV"] = "CV";
    CountryCode["CL"] = "CL";
    CountryCode["CR"] = "CR";
    CountryCode["HR"] = "HR";
    CountryCode["CY"] = "CY";
    CountryCode["CZ"] = "CZ";
    CountryCode["DO"] = "DO";
    CountryCode["EC"] = "EC";
    CountryCode["EG"] = "EG";
    CountryCode["EE"] = "EE";
    CountryCode["FO"] = "FO";
    CountryCode["GE"] = "GE";
    CountryCode["GR"] = "GR";
    CountryCode["GL"] = "GL";
    CountryCode["GT"] = "GT";
    CountryCode["HT"] = "HT";
    CountryCode["HN"] = "HN";
    CountryCode["HU"] = "HU";
    CountryCode["IS"] = "IS";
    CountryCode["IN"] = "IN";
    CountryCode["ID"] = "ID";
    CountryCode["IL"] = "IL";
    CountryCode["JO"] = "JO";
    CountryCode["KZ"] = "KZ";
    CountryCode["KE"] = "KE";
    CountryCode["KW"] = "KW";
    CountryCode["LA"] = "LA";
    CountryCode["LV"] = "LV";
    CountryCode["LB"] = "LB";
    CountryCode["LI"] = "LI";
    CountryCode["LT"] = "LT";
    CountryCode["LU"] = "LU";
    CountryCode["MK"] = "MK";
    CountryCode["MY"] = "MY";
    CountryCode["MV"] = "MV";
    CountryCode["MT"] = "MT";
    CountryCode["MU"] = "MU";
    CountryCode["MX"] = "MX";
    CountryCode["MD"] = "MD";
    CountryCode["MC"] = "MC";
    CountryCode["MA"] = "MA";
    CountryCode["NP"] = "NP";
    CountryCode["NZ"] = "NZ";
    CountryCode["NI"] = "NI";
    CountryCode["NG"] = "NG";
    CountryCode["OM"] = "OM";
    CountryCode["PA"] = "PA";
    CountryCode["PK"] = "PK";
    CountryCode["PY"] = "PY";
    CountryCode["PH"] = "PH";
    CountryCode["PL"] = "PL";
    CountryCode["PR"] = "PR";
    CountryCode["RO"] = "RO";
    CountryCode["RU"] = "RU";
    CountryCode["SM"] = "SM";
    CountryCode["SA"] = "SA";
    CountryCode["SN"] = "SN";
    CountryCode["SK"] = "SK";
    CountryCode["SI"] = "SI";
    CountryCode["ZA"] = "ZA";
    CountryCode["LK"] = "LK";
    CountryCode["TJ"] = "TJ";
    CountryCode["TH"] = "TH";
    CountryCode["TN"] = "TN";
    CountryCode["TR"] = "TR";
    CountryCode["TM"] = "TM";
    CountryCode["UA"] = "UA";
    CountryCode["UY"] = "UY";
    CountryCode["UZ"] = "UZ";
    CountryCode["VA"] = "VA";
    CountryCode["VE"] = "VE";
    CountryCode["ZM"] = "ZM";
    CountryCode["AS"] = "AS";
    CountryCode["CC"] = "CC";
    CountryCode["CK"] = "CK";
    CountryCode["RS"] = "RS";
    CountryCode["ME"] = "ME";
    CountryCode["CS"] = "CS";
    CountryCode["YU"] = "YU";
    CountryCode["CX"] = "CX";
    CountryCode["ET"] = "ET";
    CountryCode["FK"] = "FK";
    CountryCode["NF"] = "NF";
    CountryCode["FM"] = "FM";
    CountryCode["GF"] = "GF";
    CountryCode["GN"] = "GN";
    CountryCode["GP"] = "GP";
    CountryCode["GS"] = "GS";
    CountryCode["GU"] = "GU";
    CountryCode["GW"] = "GW";
    CountryCode["HM"] = "HM";
    CountryCode["IQ"] = "IQ";
    CountryCode["KG"] = "KG";
    CountryCode["LR"] = "LR";
    CountryCode["LS"] = "LS";
    CountryCode["MG"] = "MG";
    CountryCode["MH"] = "MH";
    CountryCode["MN"] = "MN";
    CountryCode["MP"] = "MP";
    CountryCode["MQ"] = "MQ";
    CountryCode["NC"] = "NC";
    CountryCode["NE"] = "NE";
    CountryCode["VI"] = "VI";
    CountryCode["VN"] = "VN";
    CountryCode["PF"] = "PF";
    CountryCode["PG"] = "PG";
    CountryCode["PM"] = "PM";
    CountryCode["PN"] = "PN";
    CountryCode["PW"] = "PW";
    CountryCode["RE"] = "RE";
    CountryCode["SH"] = "SH";
    CountryCode["SJ"] = "SJ";
    CountryCode["SO"] = "SO";
    CountryCode["SZ"] = "SZ";
    CountryCode["TC"] = "TC";
    CountryCode["WF"] = "WF";
    CountryCode["XK"] = "XK";
    CountryCode["YT"] = "YT";
    CountryCode["INTL"] = "INTL";
})(CountryCode || (CountryCode = {}));
