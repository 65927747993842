import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ExclamationMark = (props: SvgProps): JSX.Element => (
  <Svg
    width={33}
    height={33}
    fill="none"
    {...props}
  >
    <Path
      d="M18.083 16.276c.061-.659.091-1.328.091-2.008V9a1.5 1.5 0 0 0-1.5-1.5h-.29a1.5 1.5 0 0 0-1.5 1.5v5.268c0 .68.031 1.35.092 2.008.036.391.079.796.127 1.216a1.433 1.433 0 0 0 1.427 1.265c.723 0 1.342-.538 1.426-1.265.049-.42.091-.825.127-1.216Zm-3.44 6.254-.004.006-.002.007c-.092.26-.137.532-.137.813 0 .286.044.562.137.824l.004.01.003.01c.1.247.235.47.409.663l.008.01c.182.192.397.343.64.45.25.119.52.177.799.177.276 0 .54-.06.79-.177.243-.107.456-.259.633-.455.182-.194.323-.418.423-.668l.002-.006c.103-.266.152-.547.152-.838 0-.286-.05-.563-.152-.826-.1-.258-.24-.489-.425-.686a1.993 1.993 0 0 0-.626-.463 1.838 1.838 0 0 0-.797-.18c-.282 0-.553.06-.807.18-.24.114-.45.267-.632.459l-.008.009a2.18 2.18 0 0 0-.41.68Z"
      fill="#fff"
      stroke="#fff"
    />
  </Svg>
)

export default ExclamationMark
