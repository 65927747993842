import * as React from 'react'

// Same as useEffect but only runs when dependencies are *updated*, not on initial render.
// useEffect always runs on initial render, which can cause (among other things) double state initialization
// if you have a pattern where you useState() and then useEffect() to update that state when props change.
// Shamelessly taken from https://stackoverflow.com/questions/53179075/with-useeffect-how-can-i-skip-applying-an-effect-upon-the-initial-render
export function useDidUpdateEffect (
  fn: (() => void) | (() => () => void),
  dependencies: any[]
): void {
  const didMountRef = React.useRef(false)
  React.useEffect(() => {
    if (didMountRef.current) {
      return fn()
    }
    didMountRef.current = true
  }, dependencies)
}
