import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const welcomeBannerStyle = StyleSheet.create({
  welcomeBanner: {
    width: '100%',
    padding: THEME.spacing.horizontalSpaceMedium,
    backgroundColor: THEME.color.rainbow.darkBlue,
    borderColor: THEME.color.outline,
    borderBottomWidth: 2,
    borderTopWidth: 2
  }
})
