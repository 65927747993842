import * as React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { THEME } from '../../../constants'
import { SettingsScreen } from './settings-screen'

// We can remove the stack navigator here. This should be done at the same time as removing the accounts
// stack navigator so the header UI is consistent across the two.
const SettingsStack = createNativeStackNavigator()

function SettingsStackScreen (): JSX.Element {
  const settingsHeaderColor =
    THEME.color.gradientC.colors.length > 0
      ? THEME.color.gradientC.colors[0]
      : THEME.color.surfaceOne

  return (
    <SettingsStack.Navigator>
      <SettingsStack.Screen
        name="SettingsScreen"
        component={SettingsScreen}
        initialParams={{
          theme: THEME,
          colorGradient: THEME.color.gradientC,
          title: 'Settings',
          drawersKey: 'settings'
        }}
        options={{
          headerTitle: 'Settings',
          headerTitleStyle: {
            fontSize: THEME.font.fontSizeLarge,
            fontFamily: 'PublicSans_700Bold'
          },
          headerShadowVisible: false,
          headerTintColor: THEME.color.onSurface,
          headerStyle: {
            backgroundColor: settingsHeaderColor
          }
        }}
      />
    </SettingsStack.Navigator>
  )
}

export { SettingsStackScreen }
