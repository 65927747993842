import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import { determineNextTaskStyle } from './determine-next-task.style'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import {
  gql,
  useLazyQuery,
  useApolloClient,
  NormalizedCacheObject,
  ApolloClient
} from '@apollo/client'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { useIsMounted } from '../../../../util/use-is-mounted'
import { useGetIsFocused } from '../../../../util/use-get-is-focused'
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { isNonNullish } from 'global-utils'
import { TaskFlowStage } from '../task-flow-data'
import { initializeMissingTasksForMember } from '../../../../api-calls/member'
import { loadToken } from '../../../auth/load-token'
import {
  FadeElementGroup,
  FadeInOutElementGroups
} from './fade-element-groups'
import {
  buttonStyle,
  textStyle,
  viewStyle
} from '../../../../themes/global-styles.style'
import { getDetermineNextTaskElementGroups } from '../content-construction/determine-next-task/get-determine-next-task-copy'
import { ScrollWithArrow } from '../../common/scroll/scroll-with-arrow'
import { THEME } from '../../../../constants'

export function DetermineNextTask (props: {
  navigation: GenericCompositeNavigationProp
  waitForPromise?: Promise<void>
}): JSX.Element {
  React.useLayoutEffect(() => {
    props.navigation.setOptions({ headerTitle: '' })
  })

  // Polling for this query is handled centrally by the app in order to not register
  // repeated polling requests.
  const [getMember, { data: memberData }] = useLazyQuery<
  WalkthroughGraphQL.GetMemberQuery,
  WalkthroughGraphQL.GetMemberQueryVariables
  >(gql(WalkthroughGraphQL.getMember))

  // Never cache RunGoalEngine results... we'd never want to accidentally use stale results.
  const [callRunGoalEngine, { error, data }] =
    useLazyQuery<WalkthroughGraphQL.RunGoalEngineForMemberQuery>(
      gql(WalkthroughGraphQL.runGoalEngineForMember),
      {
        fetchPolicy: 'no-cache'
      }
    )
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>

  const isMounted = useIsMounted()

  // Wait for animations to complete before redirecting.
  const [animationsComplete, setAnimationsComplete] = React.useState(false)

  // The text groups to animate on the screen.
  const [fadeElementGroups, setFadeElementGroups] = React.useState<
  FadeElementGroup[]
  >([])

  const [displayErrorMessage, setDisplayErrorMessage] = React.useState(false)

  React.useEffect(() => {
    setDisplayErrorMessage(error !== undefined)
  }, [error])

  // Reset the error message if we unfocus the page. This avoids a flicker when we come back to the page
  // after addressing errors.
  const isFocused = useIsFocused()
  React.useEffect(() => {
    if (!isFocused) {
      setDisplayErrorMessage(false)
    }
  }, [isFocused])

  // Call the goal engine and reset the animations complete state whenever we refocus on this element.
  useFocusEffect(
    React.useCallback(() => {
      // Reset the animations complete state whenever we refocus on this element.
      setAnimationsComplete(false);
      // Only callRunGoalEngine after waitForPromise resolves (if provided). This allows screens
      // navigating to DetermineNextTask to demand that their logic (like updating task completion
      // statuses in the DB) completes before we re-run the goal engine.
      (async (): Promise<void> => {
        try {
          if (isNonNullish(props.waitForPromise)) {
            await props.waitForPromise
          }
        } finally {
          try {
            await initializeMissingTasksForMember(client)
          } finally {
            await callRunGoalEngine()
          }
        }
      })().catch(console.log)
    }, [])
  )

  React.useEffect(() => {
    // There are known race condititions with async code in useEffect (like what if the
    // component unmounts before the function returns?), but this is still the recommended
    // approach. See https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function getOwnerAndQuery (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getMember({ variables: { owner: owner } })
      }
    }
    getOwnerAndQuery().catch(console.log)
  }, [])

  // If the screen is focused and we have data, parse the result and redirect there.
  // We do not use the useFocusEffect hook here because we do not want to trigger this
  // immediately upon refocus. If we did, the setMinimumDisplayTimeReached(false) call
  // in the above useFocusEffect function would be registered but not have taken action
  // yet, so we would immediately renavigate.  For the same reason, we do not retrigger
  // this hook when the isFocused value changes.
  const getIsFocused = useGetIsFocused()
  React.useEffect(() => {
    const taskTypename = data?.runGoalEngineForMember?.taskTypename
    const taskId = data?.runGoalEngineForMember?.taskPrimaryKey
    if (getIsFocused()) {
      if (animationsComplete) {
        if (isNonNullish(taskTypename) && isNonNullish(taskId)) {
          props.navigation.navigate('TaskScreen', {
            flowStage: TaskFlowStage.INTRO,
            taskKey: {
              taskTypename: taskTypename,
              taskId: taskId
            },
            recommendedByWalkthrough: true
          })
        } else if (isNonNullish(data?.runGoalEngineForMember)) {
          // Data is non-null, there is no error, but we get no task back (partial
          // data is disabled:
          // https://www.apollographql.com/docs/react/data/error-handling/#partial-data-with-resolver-errors)
          // Just go back to the TaskListScreen in this case (?)
          props.navigation.navigate('TaskListScreen')
        }
      } else if (
        isNonNullish(memberData) &&
        isNonNullish(taskTypename) &&
        isNonNullish(taskId)
      ) {
        // TODO: Split animations into two parts: (1) before selected task is returned from the
        // goal engine (2) after selected task is returned from the goal engine.
        // We currently set all FadeElementGroups after the goal engine returns the selected task.
        // This can take a bit of time, so we might eventually want to run the animations that
        // don't need the selected task first, and then run the selected task animations afterwards.
        // We tried doing this by updating the FadeElementGroups, but even though the element was
        // updating, the component wouldn't re-render. We can potentially create a separate state
        // variable to hold the FadeElementGroups for the selected task, and run that animation
        // after the the first animation finishes.
        setFadeElementGroups(
          getDetermineNextTaskElementGroups(memberData, taskTypename, taskId)
        )
      }
    }
  }, [data, memberData, animationsComplete])

  const [scrollToTopTrigger, setScrollToTopTrigger] = React.useState(0)

  React.useEffect(() => {
    // Set a new, different value to trigger a ScrollToTop call
    setScrollToTopTrigger(Math.random())
  }, [data, memberData])

  return (
    <View style={determineNextTaskStyle.taskBox}>
      <ScrollWithArrow triggerScrollToTop={scrollToTopTrigger}>
        <View style={determineNextTaskStyle.contentBox}>
          <View style={determineNextTaskStyle.titleBox}>
            <Text style={[textStyle.extraLargeText, textStyle.boldText]}>
              Determining your next task
            </Text>
          </View>
          <View style={determineNextTaskStyle.subContentBox}>
            {displayErrorMessage ? (
              <Text style={determineNextTaskStyle.errorMessage}>
                Something confusing happened! Please make sure your info in the
                Settings and Accounts tabs is up to date, then come back here
                and we'll try again!
              </Text>
            ) : (
              <FadeInOutElementGroups
                elementGroups={fadeElementGroups}
                onAnimationsComplete={() => {
                  setAnimationsComplete(true)
                }}
              />
            )}
          </View>
        </View>
      </ScrollWithArrow>
      <Pressable
        onPress={() => props.navigation.navigate('TaskListScreen')}
        style={[
          // Button shape, but fades into background unless there is text scrolling
          // behind it.
          buttonStyle(THEME.color.surfaceOne, THEME.color.surfaceOne)
            .smallButton,
          viewStyle.mediumTopMargin,
          determineNextTaskStyle.alternativeButton
        ]}
      >
        <Text style={determineNextTaskStyle.alternativeButtonText}>
          see all possible tasks instead
        </Text>
      </Pressable>
    </View>
  )
}
