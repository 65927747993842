import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg'

const EggStart = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 414 896'
    fill='none'
    preserveAspectRatio='xMaxYMax slice'
    {...props}
  >
    <G clipPath='url(#a)'>
      <Path
        d='M237 715.932c-8.581 5.728-18.899 9.068-30 9.068-29.823 0-54-24.109-54-53.85 0-13.424 4.926-31.67 13.073-47.65C175.976 604.076 190.638 588 207 588c19.512 0 36.607 22.862 46.096 47 5.014 12.756 7.904 25.868 7.904 36.15a53.522 53.522 0 0 1-5.088 22.85c8.696 9.167 24.088 18.5 36.588 18.5 13.829 0 24.5-18.5 34-18.5 16 0 12.5 51.857 26 51 13.5-.857 21.5-113.5 34.5-113.5s-3.5 76 29 67'
        stroke='#fff'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M0 0h414v896H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default EggStart
