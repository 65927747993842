import * as React from 'react'
import { Text, View } from 'react-native'
import { THEME } from '../../../../constants'
import { goalBarProgressStyle, goalBarStyle } from './goal-bar.style'

// goalProgressPercent is assumed to be a number between 1 and 100.
function GoalBar (props: { goalProgressPercent: number }): JSX.Element {
  const goalProgressPercentString = props.goalProgressPercent.toString() + '%'
  const style = goalBarStyle(THEME)

  return (
    <View style={style.goalBox}>
      <View style={style.contentBox}>
        <View style={style.goalBar}>
          <View
            style={
              goalBarProgressStyle(THEME, goalProgressPercentString)
                .goalProgressBar
            }
          >
            <Text
              style={
                goalBarProgressStyle(THEME, goalProgressPercentString)
                  .goalProgressNumber
              }
            >
              {props.goalProgressPercent}%
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export { GoalBar }
