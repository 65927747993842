import {
  TaskFlowData,
  TaskFlowStage,
  TaskStepsRenderingStyle
} from '../task-flow-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { TaskFlowDataConverter } from './get-task-flow-data'
import { gql } from '@apollo/client'
import { extractBooleanFromJsonMap } from 'global-utils'
import { NavigationAnchor } from '../../common/links/navigation-anchor'
import { getTaskTitle } from './get-task-title'

const intro =
  "You're starting to hit your stride 😎🏃🏾‍♀️. Your next mission, should you choose to accept it: " +
  'expand your emergency fund to three months of expenses. If you lose your job or end up in the hospital, ' +
  'this will give you some time to figure things out.\n\nShield yourself from the forces of chaos:'

const steps =
  "You've already built up a one-month emergency fund, but it's actually better to have a little more " +
  'than that. Three months of cash is about the right balance between having it when you need it and investing to ' +
  'let your money work for you.'

const referToPreviousKey = 'PREVIOUS'
const referToOneMonth: (taskOwner: string) => React.ReactNode = (taskOwner) => {
  return (
    <>
      This task is nearly identical to building your one-month emergency fund.
      If you haven't done that task yet, go straight there instead (
      <NavigationAnchor
        handlePress={(navigation) => {
          navigation.navigate('TaskScreen', {
            taskKey: {
              taskTypename: 'BuildOneMonthEmergencyFundTask',
              taskId: taskOwner
            },
            flowStage: TaskFlowStage.INTRO,
            recommendedByWalkthrough: false
          })
        }}
        text={'Build an initial emergency fund task'}
      />
      ).
    </>
  )
}

const whenToSpendKey = 'WHEN'
const whenToSpend =
  'A three-month emergency fund gives you more cushion if things go wrong. Here are a couple ' +
  'examples of when you might think about using it:\n\nYou lose your job and need to cover two months of rent.\n\nYour ' +
  "child breaks their leg and needs to have surgery.\n\nThere's a pandemic.\n\nSome of these things are pretty " +
  'pricey... one month of expenses might not cut it.'

const reuseAccountKey = 'REUSE'
const reuseAccount: (taskOwner: string) => React.ReactNode = (taskOwner) => {
  return (
    <>
      The wheel was invented about 6,000 years ago. Back then, it was a round,
      solid piece of wood.{'\n\n'}After about 2,000 years, people started
      building them with spokes 🤯.{'\n\n'}Just reuse your existing emergency
      fund from the{' '}
      <NavigationAnchor
        handlePress={(navigation) => {
          navigation.navigate('TaskScreen', {
            taskKey: {
              taskTypename: 'BuildOneMonthEmergencyFundTask',
              taskId: taskOwner
            },
            flowStage: TaskFlowStage.INTRO,
            recommendedByWalkthrough: false
          })
        }}
        text={'Build an initial emergency fund task'}
      />
      . Don't overthink it.
    </>
  )
}

// TODO: dyanmic content!
const fundItKey = 'FUND_IT'
const fundIt =
  "We're trying to grow your fund to a total of three times your monthly expenses.\n\nIf you already " +
  "have the extra money, transfer it into your fund. Yay!\n\nIf you don't have the money yet, start transferring as " +
  'much as you can every month into your ' +
  "fund — maybe $50 or $100?\n\nIf you set up auto-transfers from your standard account, you'll do the right " +
  "thing without ever having to think about it again (well, don't forget to turn them off when you're all done 😉)."

const restEasyKey = 'REST_EASY'
const restEasy =
  'Saving is hard, but the security is worth it. Take a moment to be proud of yourself for being so ' +
  "gosh-darn responsible.\n\nRemember, it's best to forget about this fund so you don't accidentally spend it. If " +
  'you do need to cover an emergency, work on rebuilding your safety net as soon as you can.'

export const extendEmergencyFundToThreeMonthsTaskToFlowData: TaskFlowDataConverter<
WalkthroughGraphQL.ExtendEmergencyFundToThreeMonthsTask
> =
  (
    taskTypename,
    taskId,
    absoluteDollarsToAllocateToTask,
    currentlyAllocatedDollars,
    client,
    recommendedByWalkthrough
  ) =>
    (task) => {
      if (task === null) {
        return null
      }
      if (task.__typename !== taskTypename) {
        return null
      }
      if (task.owner !== taskId) {
        return null
      }

      // Note that we refer to the JS object (not the JSON string!) when writing this back to the DB. This means that each
      // time the object passes through the FE it is revalidated for JSON parseability (although we still lack enforcement
      // that it conforms to a {[key: string]: boolean} type)
      const subtaskCompletionState: any = JSON.parse(
        task.subtaskCompletion ?? '{}'
      )

      // Generate a storage function to associate with a subtask.
      // We define it as an arrow function so it is interpreted within the control flow (needed for the compiler to infer
      // that task is not null). See https://github.com/microsoft/TypeScript/issues/32339 and 32300 for more.
      const storeCompletionGenerator = (key: string) => {
        return (complete: boolean) => {
          client
            .mutate<
          WalkthroughGraphQL.UpdateExtendEmergencyFundToThreeMonthsTaskMutation,
          WalkthroughGraphQL.UpdateExtendEmergencyFundToThreeMonthsTaskMutationVariables
          >({
            mutation: gql(
              WalkthroughGraphQL.updateExtendEmergencyFundToThreeMonthsTask
            ),
            variables: {
              input: {
                owner: task.owner,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            },
            optimisticResponse: {
              updateExtendEmergencyFundToThreeMonthsTask: {
                ...task,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            }
          })
            .catch(console.log)
        }
      }

      const flowData: TaskFlowData = {
        steps: {
          stepRenderingStyle: TaskStepsRenderingStyle.CHECKLIST,
          title: getTaskTitle(task.__typename),
          description: steps,
          subtasks: [
            {
              key: referToPreviousKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                referToPreviousKey
              ),
              storeCompletionState: storeCompletionGenerator(referToPreviousKey),
              title: 'Do not pass go. Do not collect $200',
              description: referToOneMonth(task.owner)
            },
            {
              key: whenToSpendKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                whenToSpendKey
              ),
              storeCompletionState: storeCompletionGenerator(whenToSpendKey),
              title: 'Why expand your emergency fund?',
              description: whenToSpend
            },
            {
              key: reuseAccountKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                reuseAccountKey
              ),
              storeCompletionState: storeCompletionGenerator(reuseAccountKey),
              title: 'Where to put your fund',
              description: reuseAccount(task.owner)
            },
            {
              key: fundItKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                fundItKey
              ),
              storeCompletionState: storeCompletionGenerator(fundItKey),
              title: 'Add the 💵',
              description: fundIt
            },
            {
              key: restEasyKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                restEasyKey
              ),
              storeCompletionState: storeCompletionGenerator(restEasyKey),
              title: 'Rest easy',
              description: restEasy
            }
          ],
          button: {
            buttonText: 'Next',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              const response = client.mutate<
              WalkthroughGraphQL.UpdateExtendEmergencyFundToThreeMonthsTaskMutation,
              WalkthroughGraphQL.UpdateExtendEmergencyFundToThreeMonthsTaskMutationVariables
              >({
                mutation: gql(
                  WalkthroughGraphQL.updateExtendEmergencyFundToThreeMonthsTask
                ),
                variables: {
                  input: {
                    owner: task.owner,
                    isComplete: true
                  }
                }
              })
              props.navigation.navigate('TaskScreen', {
                flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL,
                waitForPromise: response
              })
            }
          }
        },
        introData: {
          title: getTaskTitle(task.__typename),
          introContent: intro,
          button: {
            buttonText: 'Expand your emergency fund',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskScreen', {
                taskKey: {
                  taskTypename: task.__typename,
                  taskId: task.owner
                },
                flowStage: TaskFlowStage.STEPS,
                absoluteDollarsToAllocateToTask: absoluteDollarsToAllocateToTask,
                recommendedByWalkthrough: recommendedByWalkthrough
              })
            }
          },
          alternativeButton: {
            buttonText: 'Change tasks',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskListScreen')
            }
          }
        }
      }
      return flowData
    }
