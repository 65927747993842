import * as React from 'react'
import { Pressable, ScrollView } from 'react-native'
import DownArrowCircle from '../../../../assets/down-arrow-circle'
import { THEME } from '../../../../constants'
import { scrollStyle } from './scroll-with-arrow.style'

export function ScrollWithArrow (props: {
  triggerScrollToTop: number; // A meaningless number that will trigger a ScrollToTop call when changed
  triggerScrollToBottom: number; // A meaningless number that will trigger a ScrollToTop call when changed
  children: React.ReactNode
}): JSX.Element {
  const [scrollContentHeight, setScrollContentHeight] = React.useState(0)
  const [scrollWindowHeight, setScrollWindowHeight] = React.useState(0)
  const [scrollHasOccurred, setScrollHasOccurred] = React.useState(false)
  const [shouldShowScrollIcon, setShouldShowScrollIcon] = React.useState(false)
  const scrollRef = React.useRef<ScrollView>(null)

  React.useEffect(() => {
    if (scrollHasOccurred) {
      // Stop showing the scroll icon once the member has scrolled manually or pressed the scroll icon
      // to scroll to the bottom. Now they know that the content is scrollable so we don't need to show
      // them a hint anymore.
      setShouldShowScrollIcon(false)
    } else {
      // Only show the scroll icon if the content that can be scrolled is bigger than the view it's in. Add
      // a small buffer at the bottom so that we don't render the scroll button if we can already see the
      // margin at the bottom.
      // We check that scrollWindowHeight > 0 to fix a Firefox rendering issue where the scroll icon flashes.
      // We don't show the icon until we have the actual height of the scroll window.
      setShouldShowScrollIcon(
        scrollWindowHeight > 0 &&
          scrollContentHeight >
            scrollWindowHeight + THEME.spacing.verticalSpaceMedium
      )
    }
  }, [scrollContentHeight, scrollWindowHeight, scrollHasOccurred])

  React.useEffect(() => {
    scrollRef?.current?.scrollTo({ y: 0, animated: false })
  }, [props.triggerScrollToTop])

  React.useEffect(() => {
    scrollRef?.current?.scrollToEnd({ animated: true })
  }, [props.triggerScrollToBottom])

  const style = scrollStyle(THEME)

  return (
    <>
      <ScrollView
        ref={scrollRef}
        onContentSizeChange={(_width, height) => {
          setScrollContentHeight(height)
        }}
        onLayout={(event) => {
          setScrollWindowHeight(event.nativeEvent.layout.height)
        }}
        onScroll={() => setScrollHasOccurred(true)}
        onScrollBeginDrag={() => setScrollHasOccurred(true)}
        scrollEventThrottle={0}
      >
        {props.children}
      </ScrollView>
      {shouldShowScrollIcon ? (
        <Pressable
          style={style.scrollIcon}
          onPress={() => {
            scrollRef?.current?.scrollToEnd()
            setScrollHasOccurred(true)
          }}
        >
          <DownArrowCircle />
        </Pressable>
      ) : null}
    </>
  )
}

ScrollWithArrow.defaultProps = {
  triggerScrollToTop: 0,
  triggerScrollToBottom: 0
}
