import * as React from 'react'
import {
  View,
  Text,
  TextInput,
  Pressable,
  Animated,
  Easing
} from 'react-native'
import { THEME } from '../../constants'
import {
  textStyle,
  viewStyle,
  buttonStyle
} from '../../themes/global-styles.style'
import JungleRight from '../../assets/jungle/jungle-right'
import ExclamationMark from '../../assets/exclamation-mark'
import Close from '../../assets/close'
import { SpinningRefreshCircle } from '../../assets/spinning-refresh-circle'
import Flag from '../../assets/flag'
import { isNonNullish } from 'global-utils'
import { QuestionOverlay } from './common/questions/question-overlay'
import { QuestionAndAnswers } from './common/questions/question-data'
import { AiAdvisorFeedback } from './common/questions/content/ai-advisor-feedback'
import { ScrollWithArrow } from './common/scroll/scroll-with-arrow'
import { gql, useLazyQuery } from '@apollo/client'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { style } from './ai-advisor-screen.style'
import { NavigationAnchor } from './common/links/navigation-anchor'
import { ContentModule } from './click-through-module/click-through-module-screen'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export function AiAdvisorScreen (): JSX.Element {
  const startingTextInputHeight = 40
  const [textInputText, setTextInputText] = React.useState('')
  const [textInputHeight, setTextInputHeight] = React.useState(
    startingTextInputHeight
  )
  const inputRef = React.useRef<TextInput>(null)
  // Whether the screen is in input mode or "display answer" mode
  const [inInputMode, setInInputMode] = React.useState(true)
  const [shouldDisplayThankYou, setShouldDisplayThankYou] =
    React.useState(false)
  const [scrollToBottomTrigger, setScrollToBottomTrigger] = React.useState(0)

  const thankYouOpacity = React.useRef(new Animated.Value(1)).current

  // Never cache RunGoalEngine results... we'd never want to accidentally use stale results.
  const [callQueryAiAdvisor, { loading, data }] = useLazyQuery<
  WalkthroughGraphQL.QueryAiAdvisorQuery,
  WalkthroughGraphQL.QueryAiAdvisorQueryVariables
  >(gql(WalkthroughGraphQL.queryAiAdvisor), {
    fetchPolicy: 'no-cache'
  })

  const [questionIndex, setQuestionIndex] = React.useState<number | null>(null)
  const [questions, setQuestions] = React.useState<QuestionAndAnswers[][]>([])

  const shouldDisplayResponse =
    !inInputMode && isNonNullish(data?.queryAiAdvisor?.response) && !loading

  // Call focus on the text input but via callback.  For some reason, calling focus directly
  // in resetForNewQuestion causes the keyboard to not appear.
  React.useEffect(() => {
    if (inInputMode) {
      inputRef.current?.focus()
    }
  }, [inInputMode])

  function resetForNewQuestion (): void {
    // Reset the TextInput Component
    inputRef.current?.clear()
    // Reset TextInput state
    setTextInputText('')
    setTextInputHeight(startingTextInputHeight)
    // Reset other components
    setShouldDisplayThankYou(false)
    setInInputMode(true)
  }

  const insets = useSafeAreaInsets()

  return (
    <View style={style.screenContainer}>
      <View
        style={[
          style.bannerContainer,
          {
            // Padding to handle safe area for the banner.
            // We don't need to use insets.bottom because the banner is at the top of the screen, and this screen has
            // tab navigation on the bottom.
            // Include normal top padding for the banner, with THEME.spacing.verticalSpaceSmall.
            paddingTop: THEME.spacing.verticalSpaceSmall + insets.top,
            paddingLeft: insets.left,
            paddingRight: insets.right
          }
        ]}
      >
        <View style={style.bannerIconContainer}>
          <ExclamationMark />
        </View>
        <View style={style.bannerTextContainer}>
          <Text style={[textStyle.regularText]}>
            This is a preview of our AI-generated content and{' '}
            <Text style={textStyle.boldText}>
              IS NOT OFFICIAL FINANCIAL ADVICE!
            </Text>{' '}
            For official advice, please use the rest of the app.
          </Text>
        </View>
      </View>
      <ScrollWithArrow triggerScrollToBottom={scrollToBottomTrigger}>
        <View style={style.aiQueryAndResponseScrollContainer}>
          <View style={style.aiQueryContainer}>
            <TextInput
              ref={inputRef}
              onChangeText={setTextInputText}
              style={[
                textStyle.regularText,
                {
                  // @ts-expect-error
                  outline: 'none', // This works but is not considered a valid field per the TS type.
                  flex: 1,
                  height: textInputHeight
                }
              ]}
              autoFocus={true}
              multiline
              onContentSizeChange={({
                nativeEvent: {
                  contentSize: { height }
                }
              }) => {
                setTextInputHeight(height)
              }}
              disabled={!inInputMode}
              placeholderTextColor={THEME.color.lowLight}
              placeholder={'What questions do you have about personal finance?'}
              onKeyPress={({ nativeEvent: { key } }) => {
                if (key === 'Enter') {
                  setInInputMode(false)
                  void callQueryAiAdvisor({
                    variables: {
                      question: textInputText
                    }
                  })
                }
              }}
            ></TextInput>
            <Pressable
              onPress={() => {
                resetForNewQuestion()
              }}
              style={viewStyle.mediumLeftMargin}
            >
              <Close />
            </Pressable>
          </View>
          {loading ? (
            <View style={style.loadingContainer}>
              <SpinningRefreshCircle />
            </View>
          ) : null}
          {shouldDisplayResponse ? (
            <View style={style.reponseContainer}>
              <View>
                <Text style={[textStyle.regularText]}>
                  {data?.queryAiAdvisor?.response}
                </Text>
              </View>
              <View style={style.flagContainer}>
                <Pressable
                  onPress={() => {
                    const recordId = data?.queryAiAdvisor?.recordId
                    if (!isNonNullish(recordId)) {
                      console.log(
                        'ERROR! Record ID is nullish but question was opened.'
                      )
                    } else {
                      setQuestions([[AiAdvisorFeedback(recordId)]])
                      setQuestionIndex(0)
                    }
                  }}
                >
                  <Flag />
                </Pressable>
              </View>
            </View>
          ) : null}
          {shouldDisplayThankYou ? (
            <Animated.View
              style={[
                style.thankYouContainer,
                {
                  opacity: thankYouOpacity
                }
              ]}
            >
              <Text style={[textStyle.regularText]}>
                Thank you for your feedback! We'll take a look soon.
              </Text>
            </Animated.View>
          ) : null}
          {inInputMode ? (
            <View style={style.privacyContainer}>
              <Text style={textStyle.regularText}>
                <Text style={textStyle.boldText}>
                  Don't include identifying information in your question!
                </Text>{' '}
                Click{' '}
                <NavigationAnchor
                  text={'here'}
                  handlePress={(nav) =>
                    nav.navigate('ClickThroughModuleScreen', {
                      module: ContentModule.AI_ADVISOR_PRIVACY
                    })
                  }
                />{' '}
                for the full privacy story.
              </Text>
            </View>
          ) : null}
          {shouldDisplayResponse ? (
            <Pressable
              style={[
                buttonStyle(THEME.color.highlight, THEME.color.outline)
                  .smallButton,
                viewStyle.smallTopMargin,
                {
                  alignSelf: 'center'
                }
              ]}
              onPress={() => {
                resetForNewQuestion()
              }}
            >
              <Text style={[textStyle.largeText, textStyle.boldText]}>
                Ask another
              </Text>
            </Pressable>
          ) : null}
        </View>
      </ScrollWithArrow>
      {isNonNullish(questionIndex) &&
      questionIndex >= 0 &&
      questionIndex < questions.length ? (
        <QuestionOverlay
          questionIndex={questionIndex}
          setQuestionIndex={setQuestionIndex}
          questions={questions}
          onFinalSaveState={() => {
            thankYouOpacity.setValue(1)
            setShouldDisplayThankYou(true)
            setScrollToBottomTrigger(Math.random())
            Animated.sequence([
              Animated.delay(3000),
              Animated.timing(thankYouOpacity, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: false,
                easing: Easing.linear
              })
            ]).start(() => {
              setShouldDisplayThankYou(false)
            })
          }}
        />
          ) : null}
      <View
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          bottom: 0,
          zIndex: -3
        }}
      >
        <JungleRight />
      </View>
    </View>
  )
}
