// This file is currently in sync with landing/themes/default-theme.ts. If updating this file, consider keeping
// it in sync with landing/themes/default-theme.ts or potentially refactoring into a shared util.
import { SpacingTheme, ShadowTheme, FontTheme, ButtonTheme } from './theme'

const DEFAULT_SPACING_THEME: SpacingTheme = {
  horizontalSpaceSmall: 7,
  horizontalSpaceMedium: 15,
  verticalSpaceExtraExtraSmall: 5,
  verticalSpaceExtraSmall: 10,
  verticalSpaceSmall: 15,
  verticalSpaceMedium: 22,
  verticalSpaceLarge: 30
}

const DEFAULT_SHADOW_THEME: ShadowTheme = {
  shadowOpacity: 0.3,
  shadowRadius: 4,
  upperDropShadowYOffset: -3,
  shadowColor: '#000000'
}

const DEFAULT_FONT_THEME: FontTheme = {
  fontSizeSmall: 12,
  fontSizeMedium: 16,
  fontSizeLarge: 18,
  fontSizeExtraLarge: 22
}

const DEFAULT_BUTTON_THEME: ButtonTheme = {
  smallButtonWidth: 220,
  buttonHeight: 40
}

export {
  DEFAULT_SPACING_THEME,
  DEFAULT_SHADOW_THEME,
  DEFAULT_FONT_THEME,
  DEFAULT_BUTTON_THEME
}
