import * as React from 'react'
import { Pressable, View, Text } from 'react-native'
import ArrowCircleLarge from '../../../assets/arrow-circle-large'
import RefreshCircleWithBackground from '../../../assets/refresh-circle-with-background'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { AccountRefreshAlertData } from './alert-processing/get-account-refresh-alerts'
import { bannersStyle } from './banners.style'
import { accountNameWithBalanceFallback } from '../../../util/account-parsing'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish } from 'global-utils'
import { ConnectInstanceData } from '../account-linking/mx/mx-connect-widget'

export function Banners (props: {
  initiallyShowWelcomeBanner: boolean
  accountRefreshAlerts: AccountRefreshAlertData[]
  setAccountRefreshAlerts: React.Dispatch<
  React.SetStateAction<AccountRefreshAlertData[]>
  >
  showAccountQuestionsAlertBanner: boolean
  setAccountQuestionIndex: React.Dispatch<React.SetStateAction<number | null>>
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  return (
    <>
      {props.initiallyShowWelcomeBanner ? (
        <View style={bannersStyle.welcomeBanner}>
          <Text style={bannersStyle.warningText}>
            Welcome!!! Please hang out here and wait for your accounts to finish
            importing.{'\n\n'}If it's taking more than a minute, try pressing
            the magic button in the Settings page ⭐{'\n\n'}Once they're
            imported, click on the checked circle tab to get your first task!!
          </Text>
        </View>
      ) : null}
      {props.accountRefreshAlerts.length > 0 ||
      props.initiallyShowWelcomeBanner ? (
        <View style={bannersStyle.border} />
          ) : null}
      {props.accountRefreshAlerts.length > 0 ? (
        <Pressable
          style={bannersStyle.authRefreshWarningBox}
          onPress={() => {
            const connectInstances: ConnectInstanceData[] =
              props.accountRefreshAlerts
                .map((refreshAlert) => {
                  if (
                    refreshAlert.account.dataProvider ===
                    WalkthroughGraphQL.DataProvider.MX
                  ) {
                    return {
                      accountFlowType: refreshAlert.accountLinkingFlowType,
                      accountInfo: {
                        dataProviderIdForFinancialInstitutionUser:
                          refreshAlert.account
                            .dataProviderIdForFinancialInstitutionUser,
                        financialInstitutionAccountName:
                          accountNameWithBalanceFallback(
                            refreshAlert.account
                          ) ?? undefined
                      }
                    }
                  } else {
                    // Don't handle non-MX alerts
                    return null
                  }
                })
                .filter(isNonNullish)
            props.navigation.navigate('MxMultiplexerScreen', {
              connectInstances,
              lastNextButtonText: 'Done',
              intermediateNextButtonText: 'Fix next alert',
              finalNavigation: (navigation: GenericCompositeNavigationProp) => {
                props.setAccountRefreshAlerts([])
                navigation.navigate('TabApp', {
                  screen: 'Accounts',
                  params: {
                    screen: 'AccountsScreen',
                    params: {
                      initiallyShowWelcomeBanner:
                        props.initiallyShowWelcomeBanner
                    }
                  }
                })
              }
            })
          }}
        >
          <Text style={bannersStyle.warningText}>
            !! Can't refresh some accounts. Click here to update login info.
          </Text>
          <View>
            <RefreshCircleWithBackground />
          </View>
        </Pressable>
      ) : null}
      {
        // Arbitrarily choose that this (second) border object be the one that is not rendered if the middle element
        // (refreshes) is absent.
        props.accountRefreshAlerts.length > 0 ||
        (props.showAccountQuestionsAlertBanner &&
          !props.initiallyShowWelcomeBanner) ? (
          <View style={bannersStyle.border} />
            ) : null
      }
      {props.showAccountQuestionsAlertBanner ? (
        <Pressable
          style={bannersStyle.accountAlertWarningBox}
          onPress={() => {
            props.setAccountQuestionIndex(0)
          }}
        >
          <Text style={bannersStyle.warningText}>
            !! We need more info about some accounts to give you accurate
            advice. Click here to fix.
          </Text>
          <View>
            <ArrowCircleLarge />
          </View>
        </Pressable>
      ) : null}
    </>
  )
}
