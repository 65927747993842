// This file is currently in sync with landing/themes/night-theme.ts. If updating this file, consider keeping
// it in sync with landing/themes/night-theme.ts or potentially refactoring into a shared util.
import { ColorTheme, Gradient, Rainbow, Theme } from './theme'
import {
  DEFAULT_BUTTON_THEME,
  DEFAULT_FONT_THEME,
  DEFAULT_SHADOW_THEME,
  DEFAULT_SPACING_THEME
} from './default-theme'

const NIGHT_GRADIENT_A: Gradient = {
  colors: [
    '#22beab',
    '#00b2aa',
    '#00a6a7',
    '#009aa4',
    '#008e9e',
    '#008297',
    '#00778f',
    '#066b86',
    '#175f7c',
    '#1f5470',
    '#244964',
    '#263f58',
    '#26354b',
    '#242b3f'
  ]
}

const NIGHT_GRADIENT_B: Gradient = {
  colors: [
    '#24c592',
    '#00b997',
    '#00ae9a',
    '#00a19c',
    '#00959b',
    '#008898',
    '#007c92',
    '#006f8b',
    '#006381',
    '#005776',
    '#174b69',
    '#21405c',
    '#24354d',
    '#242b3f'
  ]
}

const NIGHT_GRADIENT_C: Gradient = {
  colors: [
    '#2da7cb',
    '#309cc1',
    '#3392b7',
    '#3587ad',
    '#367da2',
    '#367397',
    '#36698c',
    '#356081',
    '#345676',
    '#324d6b',
    '#2f4460',
    '#2c3b55',
    '#28334a',
    '#242b3f'
  ]
}

const NIGHT_RAINBOW: Rainbow = {
  red: '#FF7B93',
  orange: '#FFBE68',
  yellow: '#FDFF86',
  lightGreen: '#78DE82',
  darkGreen: '#22BEAB',
  lightBlue: '#0EC5FF',
  blue: '#458FFF',
  darkBlue: '#3B79D7',
  purple: '#6A6FF9'
}

const NIGHT_COLOR_THEME: ColorTheme = {
  background: '#343751',
  surfaceOne: '#27293D',
  surfaceTwo: '#202232',
  onSurface: '#FFFFFF',
  highlight: '#22BEAB',
  highlightPressed: '#008D98',
  superHighlight: '#FF7991',
  lowLight: '#D9D9D9',
  disabled: '#A5A5A5',
  outline: '#202232',
  overlay: '#575a7abf',
  whiteOverlay: '#FFFFFF1A',
  gradientA: NIGHT_GRADIENT_A,
  gradientB: NIGHT_GRADIENT_B,
  gradientC: NIGHT_GRADIENT_C,
  rainbow: NIGHT_RAINBOW
}

export const NIGHT_THEME: Theme = {
  color: NIGHT_COLOR_THEME,
  spacing: DEFAULT_SPACING_THEME,
  shadow: DEFAULT_SHADOW_THEME,
  font: DEFAULT_FONT_THEME,
  buttons: DEFAULT_BUTTON_THEME
}
