import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const questionOverlayStyle = StyleSheet.create({
  overlay: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    position: 'absolute',
    backgroundColor: THEME.color.overlay
  },
  overlayContainer: {
    width: '100%',
    height: '100%',
    zIndex: 2,
    position: 'absolute'
  },
  questionContainer: {
    zIndex: 3,
    margin: THEME.spacing.horizontalSpaceMedium,
    // height is auto by default, so just set maxHeight
    maxHeight: '90%' // Progress bar and question box sum to 90% of the screen at most
  },
  questionBox: {
    flex: 1, // Take up as much space as possible in the questionContainer (after absolute-sized GoalBar takes its space)
    marginTop: THEME.spacing.horizontalSpaceMedium,
    borderRadius: 20,
    backgroundColor: THEME.color.surfaceOne,
    borderColor: THEME.color.outline,
    borderWidth: 2
  }
})
