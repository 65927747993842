import * as React from 'react'
import {
  TaskStepsRenderingStyle,
  TaskFlowData,
  TaskFlowStage
} from '../task-flow-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { TaskFlowDataConverter } from './get-task-flow-data'
import { gql } from '@apollo/client'
import { extractBooleanFromJsonMap, isNonNullish } from 'global-utils'
import {
  InputAccountTypesMemberLatestFinancialViewQuery,
  getBalanceFromLastSnapshot
} from 'multi-type-processor'
import {
  getDebtInterestString,
  getInterestBeingCharged
} from '../../../../util/account-parsing'
import { YesOrNo } from '../../common/questions/display-values/yes-or-no'
import { getTaskTitle } from './get-task-title'

const intro: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
) => React.ReactNode = (account) => {
  return (
    <>
      Paying off debt on your {account.accountName ?? ''} account is the same as
      investing, except for one thing: it's a guaranteed{' '}
      {getDebtInterestString(account) ?? ''} return.{'\n\n'}For credit cards and
      other high interest debt, the return is often better than the stock market
      📈!
      {'\n\n'}This is gonna feel gooooooood.
    </>
  )
}

const steps: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
) => React.ReactNode = (account) => {
  return (
    <>
      Paying off debt gives you peace of mind and is almost always a sound
      financial decision. The {getDebtInterestString(account) ?? ''} return you
      get on paying off your debt on your {account.accountName ?? ''} account is
      guaranteed and risk-free. If your {account.accountName ?? ''} account is a
      credit card or another form of high interest debt, paying it down probably
      beats investing in the market.{'\n\n'}
      Remaining debt:{' '}
      {getBalanceFromLastSnapshot(
        account,
        WalkthroughGraphQL.AssetType.LIABILITY
      )?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) ?? 'unknown'}
    </>
  )
}

// TODO: link to compound interest module?
const backgroundKey = 'BACKGROUND'
const background =
  'Debt works just like investments, but in reverse. Instead of compound interest working for you, ' +
  'it eats away at your hard-earned dollars. Just having $500 on a credit card costs you around $10 every month ' +
  'for nothing!\n\nThe good news is that if you have debt, you also have the chance to pay it off, which means a ' +
  "GUARANTEED return matching your interest rate!  For a credit card, that's often 20+ percent — WAY better than the " +
  "stock market! For a mortgage, it might be a lot lower, so we're strategic in what debt we suggest you pay off early."

const exceptionsKey = 'EXCEPTIONS'
const exceptions: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
) => React.ReactNode = (account) => {
  return (
    <>
      If you are not being charged interest, it probably doesn't make sense to
      pay off your debt early. We think you{' '}
      {getInterestBeingCharged(account) === YesOrNo.YES ? 'ARE' : 'ARE NOT'}{' '}
      being charged interest, but you should double-check. (You can edit whether
      you are being charged interest on your debt account on the accounts page.)
      A couple of reasons you might not be charged interest are:{'\n\n'}• You
      always pay your whole credit card bill every month. When you do this, you
      live in the credit card "grace period" and never pay interest 🎉 But if
      you miss even one payment, all bets are off{'\n\n'}• Your credit card has
      a 0% *introductory* rate. In that case, you need to pay down your debt
      before your rate ends. It's okay to pay it down now, or you can choose a
      different task (but don't forget to pay the bill in full before your rate
      expires... that's how they get you!).{'\n\n'}• This is a student loan and
      the government suspended your interest charges during COVID (and they're
      still suspended).{'\n\n'}Double-check that you are being charged interest
      on this debt, then proceed to the next step.
    </>
  )
}

const executionKey = 'EXECUTE'
const execution =
  "Funnel every extra dollar you have into this debt. Don't wait for your monthly bill to show " +
  "up — send money every time there's a little extra in your account.\n\nIf you like using autopay, set it up so it " +
  'pays more than ' +
  'the minimum amount every month — set it as high as you can tolerate.\n\nEverything about paying off this debt is ' +
  "good. The return on investment is good. You'll feel better. You'll smile more. It will even make you " +
  "funnier.\n\nYou got this. Don't stop."

export const payOffDebtTaskToFlowData: TaskFlowDataConverter<
WalkthroughGraphQL.PayOffDebtTask
> =
  (
    taskTypename,
    taskId,
    absoluteDollarsToAllocateToTask,
    currentlyAllocatedDollars,
    client,
    recommendedByWalkthrough
  ) =>
    (task) => {
      if (task === null) {
        return null
      }
      if (task.__typename !== taskTypename) {
        return null
      }
      if (task.id !== taskId) {
        return null
      }
      if (!isNonNullish(task.debtAccount)) {
        return null
      }

      // Note that we refer to the JS object (not the JSON string!) when writing this back to the DB. This means that each
      // time the object passes through the FE it is revalidated for JSON parseability (although we still lack enforcement
      // that it conforms to a {[key: string]: boolean} type)
      const subtaskCompletionState: any = JSON.parse(
        task.subtaskCompletion ?? '{}'
      )

      // Generate a storage function to associate with a subtask.
      // We define it as an arrow function so it is interpreted within the control flow (needed for the compiler to infer
      // that task is not null). See https://github.com/microsoft/TypeScript/issues/32339 and 32300 for more.
      const storeCompletionGenerator = (key: string) => {
        return (complete: boolean) => {
          client
            .mutate<
          WalkthroughGraphQL.UpdatePayOffDebtTaskMutation,
          WalkthroughGraphQL.UpdatePayOffDebtTaskMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updatePayOffDebtTask),
            variables: {
              input: {
                id: task.id,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            },
            optimisticResponse: {
              updatePayOffDebtTask: {
                ...task,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            }
          })
            .catch(console.log)
        }
      }

      const flowData: TaskFlowData = {
        steps: {
          stepRenderingStyle: TaskStepsRenderingStyle.CHECKLIST,
          title: getTaskTitle(task.__typename),
          description: steps(task.debtAccount),
          subtasks: [
            {
              key: backgroundKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                backgroundKey
              ),
              storeCompletionState: storeCompletionGenerator(backgroundKey),
              title: 'Learn about debt',
              description: background
            },
            {
              key: exceptionsKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                exceptionsKey
              ),
              storeCompletionState: storeCompletionGenerator(exceptionsKey),
              title: 'Are you special?',
              description: exceptions(task.debtAccount)
            },
            {
              key: executionKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                executionKey
              ),
              storeCompletionState: storeCompletionGenerator(executionKey),
              title: 'Crush. your. debt.',
              description: execution
            }
          ],
          button: {
            buttonText: 'Next',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              const response = client.mutate<
              WalkthroughGraphQL.UpdatePayOffDebtTaskMutation,
              WalkthroughGraphQL.UpdatePayOffDebtTaskMutationVariables
              >({
                mutation: gql(WalkthroughGraphQL.updatePayOffDebtTask),
                variables: {
                  input: {
                    id: task.id,
                    isComplete: true
                  }
                }
              })
              props.navigation.navigate('TaskScreen', {
                flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL,
                waitForPromise: response
              })
            }
          }
        },
        introData: {
          title: getTaskTitle(task.__typename),
          introContent: intro(task.debtAccount),
          button: {
            buttonText: 'Pay it down',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskScreen', {
                taskKey: {
                  taskTypename: task.__typename,
                  taskId: task.id
                },
                flowStage: TaskFlowStage.STEPS,
                absoluteDollarsToAllocateToTask: absoluteDollarsToAllocateToTask,
                recommendedByWalkthrough: recommendedByWalkthrough
              })
            }
          },
          alternativeButton: {
            buttonText: 'Change tasks',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskListScreen')
            }
          }
        }
      }
      return flowData
    }
