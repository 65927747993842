import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const ChatBubble = (props: SvgProps) => (

<Svg
width={27}
height={25}
fill="none"
{...props}
>
<Path
d="M19.212 1H7.788C4.05 1 1 4.068 1 7.875v4.5c0 3.807 3.05 6.875 6.788 6.875.722 0
1.38.41 1.7 1.055l1.434 2.9a1 1 0 0 0 1.763.057l1.448-2.511a3 3 0 0 1 2.6-1.501h2.479c3.738
0 6.788-3.068 6.788-6.875v-4.5C26 4.068 22.95 1 19.212 1Z"
stroke={props.color}
strokeWidth={2}
/>
</Svg>
)
export default ChatBubble
