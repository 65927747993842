import { StyleSheet } from 'react-native'
import { THEME } from '../../../constants'

export const editProfileSettingsStyle = StyleSheet.create({
  overlayContainer: {
    backgroundColor: THEME.color.overlay,
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  questionBox: {
    marginTop: THEME.spacing.verticalSpaceMedium,
    maxHeight: '80%',
    width: '90%',
    marginLeft: THEME.spacing.horizontalSpaceMedium,
    marginRight: THEME.spacing.horizontalSpaceMedium,
    borderRadius: 20,
    backgroundColor: THEME.color.surfaceOne,
    borderColor: THEME.color.outline,
    borderWidth: 2,
    zIndex: 2,
    position: 'absolute',
    alignSelf: 'center'
  }
})
