import * as React from 'react'
import { Text } from 'react-native'
import {
  TaskFlowStage,
  TaskFlowData,
  TaskStepsRenderingStyle
} from '../task-flow-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { TaskFlowDataConverter } from './get-task-flow-data'
import { gql } from '@apollo/client'
import { extractBooleanFromJsonMap } from 'global-utils'
import { Anchor } from '../../common/links/anchor'
import { NavigationAnchor } from '../../common/links/navigation-anchor'
import { ContentModule } from '../../click-through-module/click-through-module-screen'
import { contentStyle } from './content.style'
import { getTaskTitle } from './get-task-title'

// Note: somewhat extreme math example assumes 10% compounding, rebalance 50% of portfolio every 2 years:
// Amortized return is: ((1000*1.1*1.1 - 0.5*(1000*1.1*1.1-1000)*0.15)/1000)^0.5 = 1.093
const intro =
  "Let's keep it going!\n\nIRAs compound your money without capital gains taxes. $6,000 in an IRA grows to $40,400 over " +
  '20 years. But in a brokerage account, it only grows to $35,400 (because taxes) 😭😭😭.\n\nWhat would you do ' +
  'with a free five grand? Time to lock in those gains:'

const steps =
  'An IRA, or Individual Retirement Account, is a tax-advantaged account similar to 401ks, 403bs, ' +
  'TSPs, etc. It effectively lets your investments to grow without capital gains taxes. This deal is so sweet ' +
  'that you can only put in a certain amount of money each year, you gotta act fast!'

// TODO: add glossary/links to capital gains taxes?
const learnKey = 'LEARN'
const learn =
  'IRAs offer two main benefits:\n\n1) You effectively never pay capital gains taxes — if you buy stock for $100 and ' +
  'sell  it for $150, you\'d normally pay "capital gains" taxes on that $50 growth. But not in an IRA!\n\n2) An option to defer ' +
  "income tax into the future. We'll discuss this in the Roth v. Traditional section next.\n\nIn exchange for these " +
  "advantages, money you put into an IRA must stay there until you are 59.5 years old. We think that's a great " +
  'deal — it forces you to let compound interest grow your money over time. And there are some exceptions to the ' +
  '59.5 rule as well.'

const rothTradKey = 'ROTH_TRAD'
const rothTrad =
  'You can choose to put money into a Traditional or a Roth IRA. With a Roth IRA, you put in money like a ' +
  "normal bank account. When you withdraw it after you're 59.5, there are no taxes.\n\nWith a Traditional IRA, you " +
  'contribute "pre-tax" dollars. This means you don\'t pay ' +
  "income taxes on that money now. BUT, you'll have to pay income tax on ALL the money (principal AND growth), " +
  'when it comes out. Still no capital gains tax, though.\n\nMathematically, these two options are the same unless your income tax now is different than ' +
  "it will be in the future. If you're sure you'll have less income in the future, or if you think the government " +
  "will lower everyone's taxes, you should defer taxes with a Traditional IRA. But in general, we recommend Roth " +
  "IRAs — there's no tax bill at the end, and it's pretty easy to get your money out early."

const taxYearKey = 'WHICH_TAX_YEAR'
const taxYear = (contributionYear: number): string => {
  return (
    "You get to choose which tax year your IRA contributions go to. If you haven't filed your taxes for " +
    'last year yet, you can still contribute to your IRA for that tax year! What a way to sneak in under the ' +
    'deadline. Also, that means you can put in even more money for this tax year.\n\nThis specific task is about ' +
    'contributing for tax year ' +
    contributionYear.toString() +
    '. If you see a question asking this on your ' +
    "bank's website when you start contributing money, you should make sure to designate that year."
  )
}

// TODO: Make a simplified spreadsheet or something explaining current year contribution limits for the
// (roth, trad) x (single, joint tax filing) combinations. In July 2022 we received feedback that existing links here
// is overwhelming/hard to use.
const incomeLimitsKey = 'INCOME_LIMITS'
const incomeLimits = (
  shouldDisplayYearMismatchCaveat: boolean
): JSX.Element => {
  return (
    <>
      {shouldDisplayYearMismatchCaveat ? (
        <Text style={contentStyle.italicText}>
          Note: we're assuming your tax filing status and income were the same
          last year as this year — if those changed, PLEASE double check using
          the resources below!{'\n\n'}
        </Text>
      ) : null}
      If you make a lot of money, sometimes you aren't allowed to contribute as
      much to an IRA. We recommend this task because we think you are eligible
      for at least some contribution, but it's worth confirming your limit.
      {'\n\n'}If you want to go with a Traditional IRA, make sure your income is
      below the levels{' '}
      <Anchor
        text="here"
        href="https://www.investopedia.com/retirement/ira-contribution-limits/#toc-traditional-ira-deduction-limits"
      />
      .{'\n\n'}If you want to go with a Roth (our usual recommendation), compare
      against the numbers{' '}
      <Anchor
        text="here"
        href="https://www.investopedia.com/retirement/ira-contribution-limits/#toc-roth-ira-income-limits"
      />
      {/* TODO(COPY_UPDATE): We don't yet include the personalized max yearly contribution here because we'd have to calculate the personalized max contribution based on income. */}
      .{'\n\n'}Either way, remember how much money you are allowed to
      contribute! We'll need that in a minute.{'\n\n'}If you're too rich for
      these limits and also a super smartypants and say "what about IRA backdoor
      conversions?" — congratulations, go do it yourself. You're too cool for us
      (for now).
    </>
  )
}

const chooseAccountKey = 'CHOOSE_ACCOUNT'
const chooseAccount = (
  <>
    Choose an IRA you already have or open one. If you like the bank you already
    use, you can open an IRA with them (maybe check they don't charge you fees
    though).{'\n\n'}Otherwise, we recommend opening your IRA with{' '}
    <Anchor
      text="M1 Finance"
      href="https://help.m1.com/hc/en-us/articles/231358188-Open-an-IRA-account"
    />{' '}
    (
    <NavigationAnchor
      text="no kickbacks"
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.NO_KICKBACKS
        })
      }
    />
    !). Their accounts are free and have zero transaction fees, and we can give
    you a portfolio allocation to mirror with a single click.{'\n\n'}After your
    IRA is ready, move on to the next step.
  </>
)

const investmentPlanKey = 'INVESTMENT_PLAN'
const investmentPlan = (
  <>
    Your IRA savings should be invested. If you don't invest, your money will be
    sitting as cash, being eaten away by inflation and missing out on compound
    returns 🧛.{'\n\n'}But, before you do that (or anything else with your
    money!!), you should understand what it means.{' '}
    <NavigationAnchor
      text={"Here's"}
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.INVESTMENT_BACKGROUND
        })
      }
    />{' '}
    the basic investing info you need to know.{'\n\n'}
    You also have to decide what investments you are going to buy. We like
    passive, low-cost index funds (basically buying a little bit of the whole
    economy). They aren't fancy, aren't confusing, and get the job done. To make
    your plan,{' '}
    <NavigationAnchor
      text="click here"
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.PORTFOLIO_ALLOCATION
        })
      }
    />
    {'.\n\n'}
    Once you've decided on your investment plan, move on to the next step.
  </>
)

// For both trad/roth, you can withdraw excess contributions penalty free before the tax filing deadline, so we aren't
// adding tons of warnings about accidentally doing that.
const contributeKey = 'CONTRIBUTE'
// TODO(COPY_UPDATE): We don't yet include the personalized max yearly contribution here because we'd have to
// calculate the personalized max contribution based on income.
const contribute =
  "You're trying to max out your IRA contribution, so your goal is the number " +
  'you found in the income limitations step. \n\nIf you already ' +
  "have the extra money, transfer it into your fund. Yay!\n\nIf you don't have the money yet, start transferring as " +
  'much as you can every month into your ' +
  'fund — maybe $50 or $100?\n\nEvery time money goes in, invest it according to your plan. If your IRA ' +
  'charges transaction fees, maybe only invest every time you have $500 built up.\n\nIf you set up ' +
  "auto-transfers from your day-to-day account, you'll do the right thing without ever having to think about it " +
  "again (well, don't forget to turn them off when you're all done 😉)."

// TODO: Delete this handler in favor of the year-generic handler.
export const contributeToIra2022TaskToFlowData: TaskFlowDataConverter<
WalkthroughGraphQL.ContributeToIra2022Task
> =
  (
    taskTypename,
    taskId,
    absoluteDollarsToAllocateToTask,
    currentlyAllocatedDollars,
    client,
    recommendedByWalkthrough
  ) =>
    (task) => {
      if (task === null) {
        return null
      }
      if (task.__typename !== taskTypename) {
        return null
      }
      if (task.owner !== taskId) {
        return null
      }

      // Note that we refer to the JS object (not the JSON string!) when writing this back to the DB. This means that each
      // time the object passes through the FE it is revalidated for JSON parseability (although we still lack enforcement
      // that it conforms to a {[key: string]: boolean} type)
      const subtaskCompletionState: any = JSON.parse(
        task.subtaskCompletion ?? '{}'
      )

      // Generate a storage function to associate with a subtask.
      // We define it as an arrow function so it is interpreted within the control flow (needed for the compiler to infer
      // that task is not null). See https://github.com/microsoft/TypeScript/issues/32339 and 32300 for more.
      const storeCompletionGenerator = (key: string) => {
        return (complete: boolean) => {
          client
            .mutate<
          WalkthroughGraphQL.UpdateContributeToIra2022TaskMutation,
          WalkthroughGraphQL.UpdateContributeToIra2022TaskMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updateContributeToIra2022Task),
            variables: {
              input: {
                owner: task.owner,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            },
            optimisticResponse: {
              updateContributeToIra2022Task: {
                ...task,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            }
          })
            .catch(console.log)
        }
      }
      const shouldDisplayYearMismatchCaveat = new Date().getFullYear() !== 2022
      const taxFilingYearSubtask = {
        key: taxYearKey,
        isComplete: extractBooleanFromJsonMap(subtaskCompletionState, taxYearKey),
        storeCompletionState: storeCompletionGenerator(taxYearKey),
        title: 'Travel through time',
        description: taxYear(2022)
      }
      const flowData: TaskFlowData = {
        steps: {
          stepRenderingStyle: TaskStepsRenderingStyle.CHECKLIST,
          title: getTaskTitle(task.__typename, undefined, 2022),
          description: steps,
          subtasks: [
            {
              key: learnKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                learnKey
              ),
              storeCompletionState: storeCompletionGenerator(learnKey),
              title: 'You gotta max those gains 💪💪💪',
              description: learn
            },
            {
              key: rothTradKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                rothTradKey
              ),
              storeCompletionState: storeCompletionGenerator(rothTradKey),
              title: 'Choose: Roth or Traditional',
              description: rothTrad
            },
            ...(shouldDisplayYearMismatchCaveat ? [taxFilingYearSubtask] : []),
            {
              key: incomeLimitsKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                incomeLimitsKey
              ),
              storeCompletionState: storeCompletionGenerator(incomeLimitsKey),
              title: 'Are you too rich, tho?',
              description: incomeLimits(shouldDisplayYearMismatchCaveat)
            },
            {
              key: chooseAccountKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                chooseAccountKey
              ),
              storeCompletionState: storeCompletionGenerator(chooseAccountKey),
              title: 'Designate your account',
              description: chooseAccount
            },
            {
              key: investmentPlanKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                investmentPlanKey
              ),
              storeCompletionState: storeCompletionGenerator(investmentPlanKey),
              title: 'Plan to invest',
              description: investmentPlan
            },
            {
              key: contributeKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                contributeKey
              ),
              storeCompletionState: storeCompletionGenerator(contributeKey),
              title: 'Start contributing',
              description: contribute
            }
          ],
          button: {
            buttonText: 'Next',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              const response = client.mutate<
              WalkthroughGraphQL.UpdateContributeToIra2022TaskMutation,
              WalkthroughGraphQL.UpdateContributeToIra2022TaskMutationVariables
              >({
                mutation: gql(WalkthroughGraphQL.updateContributeToIra2022Task),
                variables: {
                  input: {
                    owner: task.owner,
                    isComplete: true
                  }
                }
              })
              props.navigation.navigate('TaskScreen', {
                flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL,
                waitForPromise: response
              })
            }
          }
        },
        introData: {
          title: getTaskTitle(task.__typename, undefined, 2022),
          introContent: intro,
          button: {
            buttonText: 'Max your IRA',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskScreen', {
                taskKey: {
                  taskTypename: task.__typename,
                  taskId: task.owner
                },
                flowStage: TaskFlowStage.STEPS,
                absoluteDollarsToAllocateToTask: absoluteDollarsToAllocateToTask,
                recommendedByWalkthrough: recommendedByWalkthrough
              })
            }
          },
          alternativeButton: {
            buttonText: 'Change tasks',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskListScreen')
            }
          }
        }
      }
      return flowData
    }

export const contributeToIraTaskToFlowData: TaskFlowDataConverter<
WalkthroughGraphQL.ContributeToIraTask
> =
  (
    taskTypename,
    taskId,
    absoluteDollarsToAllocateToTask,
    currentlyAllocatedDollars,
    client,
    recommendedByWalkthrough
  ) =>
    (task) => {
      if (task === null) {
        return null
      }
      if (task.__typename !== taskTypename) {
        return null
      }
      if (task.id !== taskId) {
        return null
      }

      // Note that we refer to the JS object (not the JSON string!) when writing this back to the DB. This means that each
      // time the object passes through the FE it is revalidated for JSON parseability (although we still lack enforcement
      // that it conforms to a {[key: string]: boolean} type)
      const subtaskCompletionState: any = JSON.parse(
        task.subtaskCompletion ?? '{}'
      )

      // Generate a storage function to associate with a subtask.
      // We define it as an arrow function so it is interpreted within the control flow (needed for the compiler to infer
      // that task is not null). See https://github.com/microsoft/TypeScript/issues/32339 and 32300 for more.
      const storeCompletionGenerator = (key: string) => {
        return (complete: boolean) => {
          client
            .mutate<
          WalkthroughGraphQL.UpdateContributeToIraTaskMutation,
          WalkthroughGraphQL.UpdateContributeToIraTaskMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updateContributeToIraTask),
            variables: {
              input: {
                id: task.id,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            },
            optimisticResponse: {
              updateContributeToIraTask: {
                ...task,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            }
          })
            .catch(console.log)
        }
      }

      const shouldDisplayYearMismatchCaveat =
      task.contributionYear !== new Date().getFullYear()
      const taxFilingYearSubtask = {
        key: taxYearKey,
        isComplete: extractBooleanFromJsonMap(subtaskCompletionState, taxYearKey),
        storeCompletionState: storeCompletionGenerator(taxYearKey),
        title: 'Travel through time',
        description: taxYear(task.contributionYear)
      }
      const flowData: TaskFlowData = {
        steps: {
          stepRenderingStyle: TaskStepsRenderingStyle.CHECKLIST,
          title: getTaskTitle(task.__typename, undefined, task.contributionYear),
          description: steps,
          subtasks: [
            {
              key: learnKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                learnKey
              ),
              storeCompletionState: storeCompletionGenerator(learnKey),
              title: 'You gotta max those gains 💪💪💪',
              description: learn
            },
            {
              key: rothTradKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                rothTradKey
              ),
              storeCompletionState: storeCompletionGenerator(rothTradKey),
              title: 'Choose: Roth or Traditional',
              description: rothTrad
            },
            ...(shouldDisplayYearMismatchCaveat ? [taxFilingYearSubtask] : []),
            {
              key: incomeLimitsKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                incomeLimitsKey
              ),
              storeCompletionState: storeCompletionGenerator(incomeLimitsKey),
              title: 'Are you too rich, tho?',
              description: incomeLimits(shouldDisplayYearMismatchCaveat)
            },
            {
              key: chooseAccountKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                chooseAccountKey
              ),
              storeCompletionState: storeCompletionGenerator(chooseAccountKey),
              title: 'Designate your account',
              description: chooseAccount
            },
            {
              key: investmentPlanKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                investmentPlanKey
              ),
              storeCompletionState: storeCompletionGenerator(investmentPlanKey),
              title: 'Plan to invest',
              description: investmentPlan
            },
            {
              key: contributeKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                contributeKey
              ),
              storeCompletionState: storeCompletionGenerator(contributeKey),
              title: 'Start contributing',
              description: contribute
            }
          ],
          button: {
            buttonText: 'Next',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              const response = client.mutate<
              WalkthroughGraphQL.UpdateContributeToIraTaskMutation,
              WalkthroughGraphQL.UpdateContributeToIraTaskMutationVariables
              >({
                mutation: gql(WalkthroughGraphQL.updateContributeToIraTask),
                variables: {
                  input: {
                    id: task.id,
                    isComplete: true
                  }
                }
              })
              props.navigation.navigate('TaskScreen', {
                flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL,
                waitForPromise: response
              })
            }
          }
        },
        introData: {
          title: getTaskTitle(task.__typename, undefined, task.contributionYear),
          introContent: intro,
          button: {
            buttonText: 'Max your IRA',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskScreen', {
                taskKey: {
                  taskTypename: task.__typename,
                  taskId: task.id
                },
                flowStage: TaskFlowStage.STEPS,
                absoluteDollarsToAllocateToTask: absoluteDollarsToAllocateToTask,
                recommendedByWalkthrough: recommendedByWalkthrough
              })
            }
          },
          alternativeButton: {
            buttonText: 'Change tasks',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskListScreen')
            }
          }
        }
      }
      return flowData
    }
