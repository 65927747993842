import { WhyThisTaskHandler } from './get-why-this-task'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { Text } from 'react-native'
import { isNonNullish } from 'global-utils'
import { getDebtInterestString } from '../../../../../util/account-parsing'
import { getTasksAsSentenceFragment } from './get-tasks-as-sentence-fragments'

export function payOffDebtWhyThisTaskHandler (
  data: WalkthroughGraphQL.GetMemberQuery,
  taskTypename: string,
  taskPrimaryKey: string,
  contributionYear: number,
  minDebtInterestRate: number
): WhyThisTaskHandler<WalkthroughGraphQL.PayOffDebtTask> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      task.__typename === taskTypename &&
      task.id === taskPrimaryKey
    ) {
      let debtAccount = task.debtAccount
      if (!isNonNullish(debtAccount)) {
        debtAccount = data.getMember?.debtAccounts?.items?.find(
          (account) => account?.accountId === task.debtAccountId
        )
      }
      if (isNonNullish(debtAccount)) {
        const alternativeTasksSentenceFragment = getTasksAsSentenceFragment(
          data,
          new Set([
            'contributeTo401kTasks',
            'contributeToIraTasks',
            'investInBrokerageAccountTask'
          ]),
          /* includeIfComplete = */ false,
          /* includeIfIncomplete = */ true,
          contributionYear,
          minDebtInterestRate,
          'or'
        )
        return (
          <Text>
            You're guaranteed a return of {getDebtInterestString(debtAccount)},
            which we think is a better option than the riskier return you would
            get by{' '}
            {alternativeTasksSentenceFragment ?? (
              <Text>investing your money somewhere else</Text>
            )}
            .
          </Text>
        )
      }
    }
    return null
  }
}
