import * as React from 'react'
import { Pressable, View } from 'react-native'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { accountsScreenStyle } from './accounts-screen.style'
import { THEME } from '../../../constants'
import AddCircle from '../../../assets/add-circle'
import { AccountLinkingFlowType } from '../account-linking/account-linking-flow-type'
import { gql, useLazyQuery } from '@apollo/client'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { useIsMounted } from '../../../util/use-is-mounted'
import { loadToken } from '../../auth/load-token'
import { QuestionAndAnswers } from '../common/questions/question-data'
import { getAccountQuestionAlerts } from './alert-processing/get-account-question-alerts'
import { isNonNullish } from 'global-utils'
import {
  AccountRefreshAlertData,
  getAccountRefreshAlerts
} from './alert-processing/get-account-refresh-alerts'
import { getAccountLatestSnapshotHash } from './alert-processing/get-account-latest-snapshot-hash'
import { RouteProp } from '@react-navigation/native'
import { Drawers, DrawerWithNodes } from '../common/drawers/drawers'
import { BackButton } from '../../common/buttons/back-button'
import { Banners } from './banners'
import { DrawerTitle } from '../common/drawers/drawer-title'
import { AccountCollectionData, AccountData } from './account-data'
import { AccountDetails } from './account-details'
import { AccountDrawerContent } from './drawers/account-drawer-content'
import { getAccountCollections } from './drawers/get-account-collections'
import { QuestionOverlay } from '../common/questions/question-overlay'

export interface AccountQuestionHooks {
  setAccountQuestionIndex: React.Dispatch<React.SetStateAction<number | null>>
  setAccountQuestions: React.Dispatch<
  React.SetStateAction<QuestionAndAnswers[][]>
  >
}

function buildDrawersFromCollections (
  accountCollections: AccountCollectionData[],
  navigation: GenericCompositeNavigationProp,
  setAccountKeyPairToShow: React.Dispatch<
  React.SetStateAction<WalkthroughGraphQL.AccountKeyPair | null>
  >
): DrawerWithNodes[] {
  return accountCollections.map((collection) => ({
    key: collection.key,
    title: DrawerTitle({ title: collection.title, value: collection.balance }),
    content: AccountDrawerContent({
      accounts: collection.accounts,
      navigation: navigation,
      setAccountKeyPairToShow: setAccountKeyPairToShow,
      text: collection.text
    })
  }))
}

function getAccountWithAccountKeyPair (
  accountKeyPair: WalkthroughGraphQL.AccountKeyPair | null,
  accountCollections: AccountCollectionData[]
): AccountData | null {
  if (accountKeyPair === null) {
    return null
  }

  for (const collection of accountCollections) {
    const account = collection.accounts.find(
      (account) =>
        account.accountKeyPair.accountPrimaryKey ===
          accountKeyPair.accountPrimaryKey &&
        account.accountKeyPair.accountTypename ===
          accountKeyPair.accountTypename
    )
    if (isNonNullish(account)) {
      return account
    }
  }
  return null
}

function AccountsScreen (props: {
  route: RouteProp<
  { AccountsScreen?: { initiallyShowWelcomeBanner: boolean } },
  'AccountsScreen'
  >
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  // Whether or not to show the "we have account questions" banner at the top.  Note this isn't exactly
  // correlated with accountQuestions.length !== 0 because for user-intiated editing we don't want the banner
  // to show.
  const [showAccountQuestionsAlertBanner, setShowAccountQuestionsAlertBanner] =
    React.useState<boolean>(false)
  // The index of the account question to show. If it's set to null, no account alert will be shown.
  // The account details to show. If null, the main accounts page will be shown instead.
  const [accountKeyPairToShow, setAccountKeyPairToShow] =
    React.useState<WalkthroughGraphQL.AccountKeyPair | null>(null)

  // The index of the account alert to show, if it's set to null, no account alert will be shown.
  const [accountQuestionIndex, setAccountQuestionIndex] = React.useState<
  number | null
  >(null)
  const [accountQuestions, setAccountQuestions] = React.useState<
  QuestionAndAnswers[][]
  >([])

  const [accountRefreshAlerts, setAccountRefreshAlerts] = React.useState<
  AccountRefreshAlertData[]
  >([])
  const [
    accountRefreshLatestSnapshotHash,
    setAccountRefreshLatestSnapshotHash
  ] = React.useState('')

  // As of Feb 2023, we do not support a way for members to retrigger a data-provider -> financial institution
  // pull.  We only support manual BE <-> data provider pulls via the account sync button in
  // settings.
  // Polling for this query is handled centrally by the app in order to not register repeated
  // polling requests.
  const [getFinancialView, { data }] = useLazyQuery<
  WalkthroughGraphQL.MemberLatestFinancialViewQuery,
  WalkthroughGraphQL.MemberLatestFinancialViewQueryVariables
  >(gql(WalkthroughGraphQL.memberLatestFinancialView))

  const isMounted = useIsMounted()

  React.useEffect(() => {
    // There are known race condititions with async code in useEffect (like what if the component unmounts before the
    // function returns?), but this is still the recommended approach. See
    // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function getOwnerAndQuery (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getFinancialView({ variables: { owner: owner } })
      }
    }
    getOwnerAndQuery().catch(console.log)
  }, [])

  // Only update the accountAlerts when the MemberLatestFinancialViewQuery changes and we aren't in the middle of a
  // resolution flow.
  React.useEffect(() => {
    if (accountQuestionIndex === null) {
      const questions = getAccountQuestionAlerts(data)
      setShowAccountQuestionsAlertBanner(questions.length !== 0)
      setAccountQuestions(questions)
    }
  }, [data, accountQuestionIndex])

  // Update accountRefreshLatestSnapshotHash each time the MemberLatestFinancialViewQuery changes. This hash is used
  // to determine if there are new snapshots, and accountRefreshAlerts should be updated.
  React.useEffect(() => {
    getAccountLatestSnapshotHash(data)
      .then((hash) => setAccountRefreshLatestSnapshotHash(hash))
      .catch(console.log)
  }, [data])

  // Only update accountRefreshAlerts when there are changes in the latest account snapshot createdAt times. This could
  // be new snapshots for the current accounts, new accounts added, or accounts deleted.
  React.useEffect(() => {
    setAccountRefreshAlerts(getAccountRefreshAlerts(data))
  }, [accountRefreshLatestSnapshotHash])

  const accountCollections: AccountCollectionData[] = getAccountCollections(
    props.route.params?.initiallyShowWelcomeBanner ?? false,
    {
      setAccountQuestionIndex: setAccountQuestionIndex,
      setAccountQuestions: setAccountQuestions
    },
    data
  )
  const accountDrawers = buildDrawersFromCollections(
    accountCollections,
    props.navigation,
    setAccountKeyPairToShow
  )
  const accountDetailsToShow = getAccountWithAccountKeyPair(
    accountKeyPairToShow,
    accountCollections
  )

  React.useLayoutEffect(() => {
    if (
      isNonNullish(accountKeyPairToShow) &&
      isNonNullish(accountDetailsToShow)
    ) {
      props.navigation.setOptions({
        headerTitle: accountDetailsToShow.accountName,
        headerLeft: () => (
          <BackButton
            handleOnPress={() => {
              setAccountKeyPairToShow(null)
            }}
          />
        )
      })
    } else {
      props.navigation.setOptions({
        headerTitle: 'Accounts',
        headerLeft: () => null
      })
    }
  }, [accountKeyPairToShow, accountDetailsToShow])

  return (
    <View style={accountsScreenStyle.accountsScreen}>
      <Banners
        initiallyShowWelcomeBanner={
          props.route.params?.initiallyShowWelcomeBanner ?? false
        }
        accountRefreshAlerts={accountRefreshAlerts}
        setAccountRefreshAlerts={setAccountRefreshAlerts}
        showAccountQuestionsAlertBanner={showAccountQuestionsAlertBanner}
        setAccountQuestionIndex={setAccountQuestionIndex}
        navigation={props.navigation}
      />
      <Drawers drawers={accountDrawers} colorGradient={THEME.color.gradientB} />
      <View style={accountsScreenStyle.iconBox}>
        <Pressable
          style={accountsScreenStyle.icon}
          onPress={() =>
            props.navigation.navigate('MxMultiplexerScreen', {
              connectInstances: [
                {
                  accountFlowType: AccountLinkingFlowType.LINK_NEW_ACCOUNTS
                }
              ],
              finalNavigation: (navigation: GenericCompositeNavigationProp) => {
                navigation.navigate('TabApp', {
                  screen: 'Accounts',
                  params: {
                    screen: 'AccountsScreen',
                    params: {
                      initiallyShowWelcomeBanner:
                        props.route.params?.initiallyShowWelcomeBanner
                    }
                  }
                })
              }
            })
          }
        >
          <AddCircle />
        </Pressable>
      </View>
      {/* The account details page is rendered over the accounts list/banners page to preserve what
      is rendered underneath. */}
      {isNonNullish(accountDetailsToShow) ? (
        <AccountDetails
          account={accountDetailsToShow}
          navigation={props.navigation}
        />
      ) : null}
      {/* The question overylay can be rendered over both the accounts list/banners page and the
      account details page.  */}
      {isNonNullish(accountQuestionIndex) &&
      accountQuestionIndex >= 0 &&
      accountQuestionIndex < accountQuestions.length ? (
        <QuestionOverlay
          questionIndex={accountQuestionIndex}
          setQuestionIndex={setAccountQuestionIndex}
          questions={accountQuestions}
        />
          ) : null}
      <View />
    </View>
  )
}

export { AccountsScreen }
