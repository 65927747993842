import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'
import { textStyle } from '../../../../themes/global-styles.style'

export const mxConnectWidgetStyle = StyleSheet.create({
  topLevelView: {
    // Empirically this is the color of the MX connect widget background
    backgroundColor: '#1f2329',
    justifyContent: 'flex-end',
    flex: 1
  },
  footer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: THEME.spacing.verticalSpaceSmall
  },
  manualButton: {
    backgroundColor: THEME.color.rainbow.darkBlue,
    width: THEME.buttons.smallButtonWidth,
    height: THEME.buttons.buttonHeight,
    borderRadius: 20,
    borderColor: THEME.color.outline,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: THEME.spacing.verticalSpaceExtraSmall
  },
  nextButton: {
    backgroundColor: THEME.color.highlight,
    width: THEME.buttons.smallButtonWidth,
    height: THEME.buttons.buttonHeight,
    borderRadius: 20,
    borderColor: THEME.color.outline,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: THEME.spacing.verticalSpaceExtraSmall
  },
  footerButtonText: {
    color: THEME.color.onSurface,
    fontSize: THEME.font.fontSizeLarge,
    fontFamily: 'PublicSans_700Bold'
  },
  privacyLink: {
    ...textStyle.smallText,
    color: THEME.color.highlight,
    textDecorationLine: 'underline',
    marginBottom: THEME.spacing.verticalSpaceExtraSmall
  },
  usdDisclosure: {
    ...textStyle.smallText,
    color: THEME.color.highlight,
    paddingHorizontal: THEME.spacing.horizontalSpaceMedium
  }
})
