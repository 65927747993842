import * as React from 'react'
import { appStyle } from './tab-app.style'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import SettingsIcon from '../../assets/settings-icon'
import JungleIcon from '../../assets/jungle-icon'
import AccountsIcon from '../../assets/accounts-icon'
import { TaskStackScreen } from './tasks/task-stack-screen'
import { SettingsStackScreen } from './settings/settings-stack-screen'
import { AccountsStackScreen } from './accounts/accounts-stack-screen'
import { THEME } from '../../constants'
import TaskIcon from '../../assets/task-icon'
import { JungleScreen } from './jungle/jungle-screen'
import { AiAdvisorScreen } from './ai-advisor-screen'
import ChatBubble from '../../assets/chat-bubble'

const Tab = createBottomTabNavigator()

function TabApp (): JSX.Element {
  const style = appStyle(THEME)

  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarShowLabel: false,
        tabBarStyle: style.navigationBar
      }}
      initialRouteName="Jungle"
    >
      <Tab.Screen
        name="Jungle"
        options={{
          tabBarIcon: ({ focused }) =>
            focused ? (
              <JungleIcon color={THEME.color.highlight} />
            ) : (
              <JungleIcon color={THEME.color.lowLight} />
            )
        }}
        component={JungleScreen}
      />
      <Tab.Screen
        name="Tasks"
        options={{
          tabBarIcon: ({ focused }) =>
            focused ? (
              <TaskIcon color={THEME.color.highlight} />
            ) : (
              <TaskIcon color={THEME.color.lowLight} />
            )
        }}
      >
        {() => <TaskStackScreen />}
      </Tab.Screen>
      <Tab.Screen
        name="Accounts"
        options={{
          tabBarIcon: ({ focused }) =>
            focused ? (
              <AccountsIcon color={THEME.color.highlight} />
            ) : (
              <AccountsIcon color={THEME.color.lowLight} />
            )
        }}
      >
        {() => <AccountsStackScreen />}
      </Tab.Screen>
      <Tab.Screen
        name="AiAdvisor"
        options={{
          tabBarIcon: ({ focused }) =>
            focused ? (
              <ChatBubble color={THEME.color.highlight} />
            ) : (
              <ChatBubble color={THEME.color.lowLight} />
            )
        }}
      >
        {() => <AiAdvisorScreen />}
      </Tab.Screen>
      <Tab.Screen
        name="Settings"
        options={{
          tabBarIcon: ({ focused }) =>
            focused ? (
              <SettingsIcon color={THEME.color.highlight} />
            ) : (
              <SettingsIcon color={THEME.color.lowLight} />
            )
        }}
      >
        {() => <SettingsStackScreen />}
      </Tab.Screen>
    </Tab.Navigator>
  )
}

export { TabApp }
