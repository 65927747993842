import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import * as React from 'react'

enum AnswerType {
  UNDEFINED,
  TEXT,
  SELECTION
}

// A question with some number of answer fields for that particular question.
interface QuestionAndAnswers {
  // A ReactNative key to link data to a specific component across renders.
  // Must be unique across instantiated QuestionAndAnswers objects. See
  // ReactNative key documentation for more.
  key: string
  question: Question
  answers: Answer[]
  // Optionally, a condition that determines whether we show this Q&A. If not
  // present, will be shown by default. At least one Q&A in a step should always
  // render (AKA not have a condition.)
  condition?: Condition
}

interface Question {
  text: React.ReactNode
}

interface Answer {
  // A key to identify this answer.  Used by ReactNative list rendering and also question matching.
  // Key must be unique among all Answers in the relevant QuestionStep
  key: string
  getInitialAnswer: (
    client: ApolloClient<NormalizedCacheObject>
  ) => Promise<string>
  storeValue: (
    client: ApolloClient<NormalizedCacheObject>,
    value: string
  ) => Promise<void>
  // Info used to validate this answer for streamlined member experience.
  // IMPORTANT: real validation needs to occur in backend/API flow. This validation
  // is NOT intended to implement security or thorough backend validation.
  validation: Validation
  // answerType must match the corresponding text/selectionAnswer field.
  // text/selectionAnswer fields that are filled in that don't match the answerType
  // will be ignored.
  answerType: AnswerType
  textAnswer?: TextAnswer
  selectionAnswer?: SelectionAnswer
}

// Simple text input answer field.
interface TextAnswer {
  placeholderText: string
}

// Answer field with a list of possible options to select from.
interface SelectionAnswer {
  items: Array<{ displayText: string, enumValue: string }>
}

// A condition for whether to show the given question & answer fields.
interface Condition {
  conditionType: ConditionType
  matchAnswerCondition?: MatchAnswerCondition
}

// Types of conditions for showing questions.
enum ConditionType {
  UNDEFINED,
  // Some previous answer has been answered in a particular way. Match against
  // the specified answer.
  MATCH_ANSWER
}

// Some previous answer has been answered in a particular way. Match against
// the specified answer. Searches previous answers to find the matching
// <databaseFieldKeyToMatch, answerToMatch> pair.
interface MatchAnswerCondition {
  answerKeyToMatch: string
  answersToMatch: string[]
}

// Validation for the given answer field.
interface Validation {
  // Returns true if the give answer is valid, false otherwise.
  isValid: (answer: string) => boolean
  // Helpful text to show to the user if the answer is not valid.
  notValidInfoText: string
}

export {
  AnswerType,
  QuestionAndAnswers,
  Question,
  Answer,
  TextAnswer,
  SelectionAnswer,
  Condition,
  ConditionType,
  MatchAnswerCondition,
  Validation
}
