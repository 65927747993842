// Get the member's monthly spending, given their after tax income and savings rate.
// Returns null for invalid argument input (savings rate out of range, negative income)
export function getMonthlySpending (
  monthlyAfterTaxIncome: number,
  savingsRate: number
): number | null {
  // monthlyAfterTaxIncome should be a number >= 0, and savingsRate should be a number
  // between 0 and 1, inclusive. Return null if these conditions are not met.
  if (savingsRate >= 0 && savingsRate <= 1 && monthlyAfterTaxIncome >= 0) {
    return monthlyAfterTaxIncome - monthlyAfterTaxIncome * savingsRate
  }
  return null
}
