import * as React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { View } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import authenticatedApp from './screens/authenticated/app-stack'
import {
  ALLOWED_APP_SCREEN_STATES,
  APP_SCREEN_STATE_KEY,
  THEME
} from './constants'
import { appStyle } from './app.style'
import { Amplify } from 'aws-amplify'
import awsconfig from '../backend-src/aws-exports'
import { ApolloProvider } from '@apollo/client'
import { APOLLO_CLIENT_DO_NOT_USE_OUTSIDE_TOP_LEVEL_APP } from './api-calls/apollo-client'
import { enableMapSet } from 'immer'
import { WelcomeScreen } from './screens/unauthenticated/welcome/welcome-screen'
import {
  useFonts,
  PublicSans_400Regular,
  PublicSans_700Bold,
  PublicSans_400Regular_Italic,
  PublicSans_700Bold_Italic
} from '@expo-google-fonts/public-sans'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import * as ServiceWorkerRegistration from './service-worker/service-worker-registration'

// Storing navigation screen state in asynchronous, unencrypted, persistent, key-value storage
// system for React Native: https://github.com/react-native-async-storage/async-storage
// IMPORTANT: DO NOT store personally identifiable info in AsyncStorage.
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Loading } from './screens/common/loading/loading'
import { OfflineScreen } from './screens/common/offline/offline'
import { useNetInfo } from '@react-native-community/netinfo'
import Plausible from 'plausible-tracker'

Amplify.configure({
  ...awsconfig,
  // If you want to force authorization using ID tokens instead, comment in the following code:
  // API: {
  //   graphql_headers: async () => ({
  //     Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
  //   })
  // },

  // Added to correct for weird exception in console, see https://github.com/aws-amplify/amplify-js/issues/5918
  Analytics: {
    disabled: true
  }
})

const apolloClient = APOLLO_CLIENT_DO_NOT_USE_OUTSIDE_TOP_LEVEL_APP()

// For Immer
enableMapSet()

// We use plausible for very high level app analytics, mostly via utm_* query params.
// Plausible is highly privacy focused (good!) but doesn't give us longitudinal data that we would
// expect to get from signed in users.  Therefore, we use plausible only for high level analytics that
// apply to all visitors, regardless of signed in status.
const { enableAutoPageviews } = Plausible({
  domain: 'app.walkthrough.co',
  // Change trackLocalhost to true for local testing.
  trackLocalhost: false
})

enableAutoPageviews()

const AppStack = createNativeStackNavigator()

export function WrapToMobileDimensions (props: {
  children: React.ReactNode
}): JSX.Element {
  const style = appStyle(THEME)
  return (
    <View style={style.outerContainer}>
      <View style={style.innerContainer}>{props.children}</View>
    </View>
  )
}

export default function App (): JSX.Element {
  const [isReady, setIsReady] = React.useState(false)
  const [appScreenState, setAppScreenState] = React.useState('')

  React.useEffect(() => {
    async function restoreNavigationScreenState (): Promise<void> {
      try {
        const savedNavigationScreenState = await AsyncStorage.getItem(
          APP_SCREEN_STATE_KEY
        )
        if (
          savedNavigationScreenState !== undefined &&
          savedNavigationScreenState !== null
        ) {
          setAppScreenState(savedNavigationScreenState)
        }
      } finally {
        setIsReady(true)
      }
    }

    if (!isReady) {
      restoreNavigationScreenState().catch((error) => {
        console.log(error)
      })
    }
  }, [])

  function getInitialRouteName (): string {
    return ALLOWED_APP_SCREEN_STATES.has(appScreenState)
      ? appScreenState
      : 'Welcome'
  }

  const [fontsLoaded] = useFonts({
    PublicSans_400Regular,
    PublicSans_700Bold,
    PublicSans_400Regular_Italic,
    PublicSans_700Bold_Italic
  })

  // Note that the web implementation of this heavily depends on the Network Information API which is an experimental
  // technology and thus it's not supported in every browser. If this API is not available the library will safely
  // fallback to the old onLine property and return basic connection information.
  // See https://github.com/react-native-netinfo/react-native-netinfo#readme for more
  const netInfo = useNetInfo()

  // Only show the 'offline' page if we are sure that the app is offline (unknown defaults to true)
  // Note that fonts are cached by our service worker, which means they should load super-fast even in the offline
  // case.  If local font loading from cache proves to be slow enough to be noticeable (i.e. fonts on offline screen
  // show as system default for a minute before updating), we can consider a text-less offline screen (at least while)
  // we wait for fonts to load.
  if (netInfo.isConnected === false || netInfo.isInternetReachable === false) {
    return (
      <SafeAreaProvider>
        <WrapToMobileDimensions>
          <OfflineScreen />
        </WrapToMobileDimensions>
      </SafeAreaProvider>
    )
  }

  if (!isReady || !fontsLoaded) {
    return (
      <SafeAreaProvider>
        <Loading />
      </SafeAreaProvider>
    )
  }

  return (
    <SafeAreaProvider>
      <ApolloProvider client={apolloClient}>
        <WrapToMobileDimensions>
          {/* TODO: we can use deep linking to make browser back buttons work correctly:
          https://reactnavigation.org/docs/deep-linking */}
          {/* Disabling documentTitle so that 'walkthrough' is the website name on browser tabs. */}
          <NavigationContainer
            documentTitle={{
              enabled: false
            }}
          >
            <AppStack.Navigator initialRouteName={getInitialRouteName()}>
              {/* Screens for unauthenticated users.
                  Note: React Navigation's preferred auth pattern is using protected routes (see
                  https://reactnavigation.org/docs/auth-flow), but the Amplify withAuthenticator() HOC should correctly
                  handle our case here, because if the user is signed out and they somehow coerce a navigation event to
                  a signed-in screen, they will just get the amplify-generated signin prompt.  We may want to switch
                  patterns if we move to a custom signin flow in the future.
                  In addition to access to the UI components, there is also a question about whether the authenticated
                  frames remain mounted/are preserving signed in state after signout, even if withAuthenticator blocks
                  them with a signin screen.  Empirical testing of the TabApp component shows that it mounts only after
                  successful withAuthenticator signin and unmounts when Auth.signout() is called.  Therefore we believe
                  that withAuthenticator correctly cleans up this signed-in state as well.
                  ApolloClient cache state must be manually cleared- we do this as part of our signout flow. */}
              <AppStack.Group>
                <AppStack.Screen
                  name="Welcome"
                  component={WelcomeScreen}
                  initialParams={{
                    welcomeId: 0
                  }}
                  options={{
                    headerTitle: '',
                    headerTitleStyle: {
                      fontSize: THEME.font.fontSizeLarge,
                      fontFamily: 'PublicSans_700Bold'
                    },
                    headerShadowVisible: false,
                    headerTintColor: THEME.color.onSurface,
                    headerStyle: {
                      backgroundColor: THEME.color.background
                    }
                  }}
                />
              </AppStack.Group>
              {/* Screens for authenticated users */}
              <AppStack.Group>
                <AppStack.Screen
                  name="AuthenticatedApp"
                  component={authenticatedApp}
                  options={{
                    headerShown: false
                  }}
                />
              </AppStack.Group>
            </AppStack.Navigator>
          </NavigationContainer>
        </WrapToMobileDimensions>
      </ApolloProvider>
    </SafeAreaProvider>
  )
}

// See https://github.com/eettaa/emb/blob/dev/src/app/frontend/service-worker/README.md for deeper discussion of our
// service worker.
ServiceWorkerRegistration.register()
