import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg'

const JungleRight = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 414 728'
    fill='none'
    preserveAspectRatio='xMaxYMax slice'
    {...props}
  >
    <G clipPath='url(#a)'>
      <Path
        d='m415 406.5-429 84.792V764h429V406.5Z'
        fill='#27293D'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M107.341 578.45c-8.944 2.063-12.828-1.657-12.714-3.066.356-1.243 5.16-5.854 12.751-4.13 7.896 1.793 9.853-10.74 9.852-14.675-.002-12.177-12.685-25.915-35.784-23.019-18.095 2.269-41.688 20.342-50.386 50.711 14.818 24.998 40.363 37.792 63.347 36.305 12.315-.797 19.7-4.158 25.5-16.332 5.136-10.778-1.418-28.366-12.566-25.794Z'
        fill='#008D98'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M94.625 575.384c-15.26-.912-49.336-.411-63.567 8.887M94.625 575.384c-8.678 9.429-33.54 24.406-63.567 8.887'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M94.624 575.384c-13.404-14.793-49.18-22.562-63.224 8.697M94.626 575.384c2.181 25.702-11.888 41.907-63.3 8.963'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M94.703 575.118c32.977-52.527-50.1-43.176-62.956 8.773'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M94.36 575.308c.299-33.352-44.983-36.81-63.3 8.963M94.014 575.497c34.449 53.393-31.26 45.525-62.422 8.927'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M.081 482.067c-8.475-7.089-22.933-4.947-28.794-2.269 1.26 11.324 9.897 21.34 15.262 23.748 11.068 4.968 20.104-.226 28.713 2.89-7.084-6.967-5.242-16.056-15.18-24.369Z'
        fill='#009B9B'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M-28.68 480.1c14.594 27.192 27.48 18.791 43.629 26.22M-28.696 479.949c31.371-2.929 25.464 8.78 43.481 26.237M-6.5 118.5c145 51 328 10.5 428-78.5'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M69.008 136.5c0 28.915-1 112 18.817 103.99 19.315-7.808 7.183-53.99 7.183-101.49'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M88.998 138.5c0 34.853-3.055 127.348 19.127 127.348 19.031 0 19.375-67.592 19.375-124.848'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M114.471 140.5c-.001 39.5-4.527 167 14.502 167 14.498 0 17.499-108.744 17.499-166M329.001 98.5c0 39.5-3.03 126 15.999 126 14.498 0 18-86.244 18-143.5'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M348.267 692.795c-14.327-38.261-32.539-79.206-20.958-80.126 12.967-1.03 29.685 50.795 33.266 93.098-2.277-44.355-11.227-102.365 1.203-98.87 11.453 3.221 13.577 50.929 7.442 90.805 9.277-41.088 19.38-89.833 30.379-84.487 10.264 4.99-1.848 45.823-18.159 81.192 19.121-34.373 40.424-69.732 47.264-60.289 6.601 9.113-14.737 39.994-37.983 66.743 25.836-26.303 54.49-52.973 60.182-43.308 4.487 7.616-13.101 24.536-35.396 40.856 21.807-11.47 42.243-19.135 48.951-12.316 8.215 8.351-20.621 21.69-52.297 32.109 27.779-5.965 54.91-9.174 58.087-1.042 5.404 13.829-75.576 16.664-109.414 14.685-31.238-4.257-99.764-9.544-99.654-24.02.09-11.938 46.613 1.047 80.395 15.12-39.509-17.667-92.593-39.485-74.991-46.965 10.666-4.532 29.9 3.587 48.846 16.041-28.041-23.145-53.05-43.649-40.031-43.55 15.901.12 49.767 30.682 70.817 59.208-24.514-37.423-59.121-81.525-45.69-81.424 11.667.088 33.496 33.335 47.741 66.54Z'
        fill='#78DE82'
      />
      <Path
        d='M360.834 731.845c-9.439-41.723-49.371-117.917-33.525-119.176 15.845-1.259 37.291 76.404 33.525 119.176Zm0 0c2.329-42.496-14.171-129.198.944-124.948 15.116 4.251 13.982 85.995-.944 124.948Zm0 0c11.49-39.841 24.359-125.632 38.765-118.63 14.406 7.003-15.27 84.625-38.765 118.63Zm0 0c20.648-36.899 58.33-110.898 67.87-97.727 9.539 13.17-39.272 71.803-67.87 97.727Zm0 0c26.6-27.375 81.912-88.142 90.069-74.292 8.158 13.849-56.671 58.458-90.069 74.292Zm0 0c26.242-17.899 90.642-58.949 103.624-45.752 12.983 13.197-66.564 38.851-103.624 45.752Zm0 0c29.335-9.545 104.01-28.514 109.414-14.685 5.404 13.829-75.576 16.664-109.414 14.685Zm0 0c-6.879-38.831-43.785-105.465-60.308-105.59-16.524-.125 39.66 66.655 60.308 105.59Zm0 0c-13.818-31.702-64.781-83.218-85.435-83.374-20.655-.156 54.405 51.542 85.435 83.374Zm0 0c-20.461-25.72-73.527-64.67-94.25-55.865-20.722 8.806 56.52 37.483 94.25 55.865Zm0 0c-31.147-16.321-99.545-38.497-99.654-24.02-.11 14.476 68.416 19.763 99.654 24.02Z'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M145.037 583.502c6.106 5.929 5.162 10.825 4.008 11.445-1.148.348-7.156-1.075-9.631-7.854-2.576-7.053-13.322-2.273-16.387-.292-9.483 6.127-13.803 22.916.071 39.451 10.87 12.953 36.815 22.238 64.845 13.737 12.017-24.117 9.132-50.45-3.588-67.604-6.816-9.191-13.148-13.252-25.548-11.647-10.978 1.422-21.381 15.374-13.77 22.764Z'
        fill='#00B797'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M149.043 594.947c6.965 12.344 24.497 38.634 38.898 45.042'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M149.043 594.947c11.709 2.016 35.881 13.847 38.898 45.042M149.042 594.947c-4.78 17.881 7.165 49.655 38.577 44.87'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M149.043 594.947c18.923-14.628 38.622-11.821 38.823 44.795M148.799 595.021c-57.501.737-8.427 60.742 38.503 44.624'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M149.117 595.193c-26.127 16.544-6.043 53.554 38.824 44.796M149.438 595.366c24.259-53.691 51.184 1.448 38.353 44.129'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M117.845 612.819c-3.391 4.875-6.869 4.779-7.427 4.054-.369-.75-.069-5.048 4.318-7.519 4.565-2.571.057-9.429-1.653-11.315-5.291-5.836-17.34-6.911-27.154 4.512-7.688 8.949-11.145 27.863-2.121 46.2 17.964 5.545 35.768.58 46.139-10.119 5.558-5.733 7.637-10.552 5.129-18.908-2.221-7.398-13.004-12.981-17.231-6.905Z'
        fill='#53D28B'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M110.418 616.873c-7.711 6.193-23.828 21.238-26.61 31.878M110.418 616.873c-.063 8.29-5.474 26.272-26.61 31.878'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M110.418 616.873c-12.853-1.267-33.378 10.552-26.529 31.638M110.42 616.873c12.212 11.373 12.508 25.254-26.45 31.799'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M110.341 616.713c-7.014-39.507-42.774 1.07-26.367 31.558'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M110.256 616.953c-14.347-16.117-37.555 1.898-26.449 31.798M110.175 617.193c39.711 10.628 4.794 35.404-26.044 31.399'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M262.808 361.709c.136-4.236 7.378-9.792 17.412-8.932 3.76 3.171 4.247 18.404-2.725 18.18-3-.097-2.778-2.358-2.434-4.198-2.398 1.356-3.912 1.845-5.386 1.081-1.097-.568-1.636-2.381-1.14-4.514-2.594.812-5.838 1.818-5.727-1.617ZM275.76 331.253c4.118 1.283 7.813 9.221 4.546 17.871-4.016 2.461-19.06-1.287-17.156-7.4.819-2.631 2.981-1.814 4.701-1.004-.749-2.496-.862-3.971.242-5.067.822-.815 2.728-.796 4.699.229-.169-2.521-.371-5.669 2.968-4.629Z'
        fill='#24C592'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M290.614 368.532c-4.294-.395-9.565-7.387-8.177-16.528 3.414-3.245 18.909-2.721 18.323 3.655-.252 2.744-2.537 2.396-4.387 1.963 1.253 2.285 1.672 3.704.82 5.006-.633.969-2.502 1.348-4.642.757.691 2.43 1.546 5.467-1.937 5.147ZM299.308 406.064c.136-4.236 7.378-9.792 17.412-8.933 3.76 3.172 4.247 18.404-2.725 18.18-3-.096-2.778-2.357-2.434-4.197-2.398 1.356-3.912 1.844-5.386 1.081-1.097-.569-1.636-2.381-1.14-4.514-2.594.812-5.838 1.818-5.727-1.617ZM312.26 375.608c4.118 1.282 7.813 9.221 4.546 17.871-4.016 2.46-19.06-1.287-17.156-7.4.819-2.631 2.981-1.814 4.701-1.005-.749-2.495-.862-3.97.242-5.066.822-.816 2.728-.796 4.699.229-.169-2.521-.371-5.669 2.968-4.629Z'
        fill='#24C592'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M327.11 412.886c-4.294-.394-9.565-7.386-8.177-16.528 3.414-3.244 18.909-2.721 18.324 3.655-.252 2.744-2.538 2.397-4.388 1.964 1.253 2.284 1.672 3.703.82 5.005-.633.97-2.501 1.348-4.642.757.691 2.43 1.546 5.467-1.937 5.147ZM289.095 454.286c1.131-4.084 9.48-7.775 19.028-4.573 2.906 3.968-.213 18.886-6.936 17.024-2.893-.801-2.144-2.947-1.375-4.653-2.65.752-4.237.87-5.489-.22-.932-.811-1.029-2.7-.043-4.656-2.713.178-6.102.39-5.185-2.922ZM308.865 427.744c3.699 2.218 5.417 10.804.202 18.439-4.483 1.444-18.218-5.746-14.926-11.237 1.416-2.364 3.325-1.06 4.805.132-.139-2.602.099-4.062 1.43-4.866.992-.599 2.839-.13 4.512 1.331.431-2.49.977-5.597 3.977-3.799Z'
        fill='#24C592'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M408.199 369.137c2.309 8.185-2.102 31.946-35.058 59.668'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M314.508 467.475c-4.081-1.397-7.554-9.435-4.048-17.991 4.083-2.348 19.017 1.816 16.944 7.874-.892 2.607-3.031 1.73-4.727.873.679 2.516.751 3.993-.383 5.058-.844.792-2.749.72-4.69-.36.098 2.525.213 5.678-3.096 4.546ZM372.306 383.304c1.554-3.943 10.244-6.737 19.403-2.551 2.474 4.252-2.195 18.76-8.684 16.203-2.793-1.101-1.823-3.156-.88-4.772-2.714.469-4.305.42-5.436-.795-.841-.905-.739-2.793.447-4.634-2.717-.109-6.11-.253-4.85-3.451ZM394.754 358.984c3.446 2.594 4.253 11.313-1.734 18.359-4.61.965-17.515-7.627-13.665-12.743 1.657-2.201 3.418-.705 4.765.637.135-2.603.525-4.03 1.934-4.69 1.048-.491 2.836.169 4.347 1.797.689-2.43 1.558-5.463 4.353-3.36Z'
        fill='#24C592'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M396.195 399.087c-3.912-1.817-6.522-10.175-2.138-18.316 4.307-1.906 18.722 3.802 16.024 9.609-1.161 2.499-3.196 1.403-4.792.372.411 2.573.328 4.05-.912 4.99-.923.7-2.81.427-4.627-.85-.167 2.521-.383 5.669-3.555 4.195ZM360.053 446.913c-1.366-4.012 3.454-11.765 13.146-14.497 4.637 1.642 10.462 15.725 3.859 17.973-2.842.968-3.432-1.227-3.758-3.07-1.766 2.114-3.011 3.105-4.66 2.91-1.226-.145-2.37-1.651-2.657-3.822-2.142 1.674-4.823 3.759-5.93.506ZM361.436 413.846c4.305-.252 10.561 5.875 10.554 15.121-2.892 3.718-18.291 5.514-18.664-.877-.161-2.751 2.151-2.749 4.045-2.597-1.581-2.072-2.206-3.412-1.559-4.827.482-1.053 2.273-1.707 4.478-1.442-1.047-2.299-2.346-5.174 1.146-5.378Z'
        fill='#24C592'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M388.479 443.495c-4.158 1.145-11.555-3.54-13.478-12.584 2.051-4.24 16.736-9.212 18.436-3.039.731 2.657-1.53 3.137-3.415 3.385 1.979 1.695 2.871 2.876 2.533 4.395-.251 1.13-1.866 2.143-4.078 2.344 1.504 2.031 3.374 4.571.002 5.499ZM314.007 357.609c-.834-4.155 4.949-11.218 14.914-12.671 4.385 2.229 8.336 16.948 1.497 18.322-2.943.591-3.243-1.662-3.328-3.531-2.025 1.867-3.388 2.688-4.997 2.281-1.198-.303-2.136-1.944-2.14-4.135-2.341 1.383-5.269 3.103-5.946-.266ZM319.667 325c4.302.309 9.711 7.194 8.505 16.362-3.349 3.312-18.851 3.097-18.393-3.289.198-2.748 2.489-2.447 4.348-2.051-1.299-2.259-1.745-3.669-.92-4.988.614-.982 2.474-1.398 4.627-.849-.74-2.416-1.656-5.435 1.833-5.185Z'
        fill='#24C592'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M342.64 357.904c-4.271.597-10.999-5.008-11.734-14.225 2.583-3.938 17.789-6.965 18.674-.624.381 2.729-1.923 2.913-3.824 2.914 1.742 1.938 2.474 3.224 1.942 4.686-.396 1.088-2.128 1.883-4.348 1.796 1.228 2.209 2.754 4.97-.71 5.453ZM311.844 283.14c1.276-4.042 9.751-7.434 19.179-3.894 2.763 4.069-.885 18.867-7.537 16.767-2.863-.904-2.038-3.021-1.209-4.699-2.675.657-4.265.718-5.478-.416-.902-.844-.932-2.734.123-4.654-2.718.081-6.112.173-5.078-3.104ZM332.547 257.317c3.618 2.348 5.03 10.99-.453 18.435-4.532 1.283-18.003-6.391-14.518-11.761 1.5-2.312 3.361-.941 4.798.303-.047-2.605.243-4.056 1.603-4.812 1.011-.563 2.841-.029 4.461 1.49.519-2.473 1.175-5.558 4.109-3.655Z'
        fill='#24C592'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M336.774 297.224c-4.029-1.541-7.214-9.698-3.406-18.124 4.164-2.201 18.94 2.492 16.653 8.472-.984 2.574-3.09 1.621-4.755.704.59 2.539.609 4.018-.563 5.042-.872.762-2.773.621-4.674-.527.009 2.527.011 5.682-3.255 4.433ZM376.088 299.097c1.731-3.869 10.539-6.268 19.499-1.671 2.279 4.359-3.041 18.641-9.408 15.793-2.741-1.226-1.679-3.235-.663-4.807-2.733.346-4.32.225-5.394-1.04-.8-.942-.613-2.823.655-4.609-2.709-.232-6.092-.529-4.689-3.666ZM399.611 275.816c3.325 2.747 3.738 11.494-2.562 18.262-4.649.755-17.152-8.412-13.075-13.348 1.755-2.124 3.447-.549 4.731.852.253-2.594.707-4.002 2.144-4.598 1.07-.443 2.827.297 4.262 1.992.798-2.397 1.804-5.387 4.5-3.16Z'
        fill='#24C592'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M399.239 315.944c-3.825-1.992-6.054-10.46-1.307-18.394 4.389-1.71 18.531 4.645 15.574 10.324-1.273 2.444-3.256 1.256-4.805.155.295 2.589.145 4.061-1.137 4.943-.953.657-2.826.3-4.583-1.058-.281 2.511-.64 5.645-3.742 4.03Z'
        fill='#24C592'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M439.891 169.5c3 29.318-.909 95.649-40.553 126.431'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M434.131 232.181c-25.634-3.388-81.677.887-100.772 45.088M418.551 276.235c11.884 20.951 23.701 70.683-24.107 101.995'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M355.793 250.395c6.638 18.014 8.095 66.452-24.442 90.54'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M352.601 308.4c-16.267-8.507-51.682.739-70.621 42.306M421.159 347.799c-28.729-2.64-89.801 3.301-101.101 46.339'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M346.637 361.666c14.995 9.955 6.151 50.428-35.045 85.081'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M0 0h414v728H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default JungleRight
