import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { THEME } from '../../../constants'

export enum WidgetThemeToMatch {
  MX_CONNECT,
  YODLEE_FASTLINK
}

function getColorFromWidgetTheme (
  widgetThemeToMatch: WidgetThemeToMatch
): string {
  switch (widgetThemeToMatch) {
    case WidgetThemeToMatch.MX_CONNECT:
      return THEME.color.onSurface
    case WidgetThemeToMatch.YODLEE_FASTLINK:
      return THEME.color.background
  }
}

function getTextSizeFromWidgetTheme (
  widgetThemeToMatch: WidgetThemeToMatch
): number {
  switch (widgetThemeToMatch) {
    case WidgetThemeToMatch.MX_CONNECT:
      return THEME.font.fontSizeExtraLarge
    case WidgetThemeToMatch.YODLEE_FASTLINK:
      return THEME.font.fontSizeLarge
  }
}

function getPaddingTopFromWidgetTheme (
  widgetThemeToMatch: WidgetThemeToMatch
): string {
  switch (widgetThemeToMatch) {
    case WidgetThemeToMatch.MX_CONNECT:
      return '40%'
    case WidgetThemeToMatch.YODLEE_FASTLINK:
      return '20%'
  }
}

export const visitFinancialProviderStyle = (
  widgetThemeToMatch: WidgetThemeToMatch
): {
  contentBox: ViewStyle
  content: TextStyle
  contentHighlight: TextStyle
} => {
  return StyleSheet.create({
    contentBox: {
      padding: THEME.spacing.horizontalSpaceMedium,
      paddingTop: getPaddingTopFromWidgetTheme(widgetThemeToMatch),
      flex: 1
    },
    content: {
      color: getColorFromWidgetTheme(widgetThemeToMatch),
      fontSize: getTextSizeFromWidgetTheme(widgetThemeToMatch),
      fontFamily: 'PublicSans_700Bold'
    },
    contentHighlight: {
      color: THEME.color.highlight,
      fontSize: getTextSizeFromWidgetTheme(widgetThemeToMatch),
      fontFamily: 'PublicSans_700Bold'
    }
  })
}
