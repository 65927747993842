import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { loadToken } from '../screens/auth/load-token'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { formatISO } from 'date-fns'
import { Auth } from 'aws-amplify'

// Calls API to create the member with the given token info if the member does not already exist.  Either way,
// backfills email and updates last login.
export async function createAndUpdateMember (
  apolloClient: ApolloClient<NormalizedCacheObject>
): Promise<void> {
  const token = await loadToken()
  const email = (await Auth.currentAuthenticatedUser()).attributes.email
  try {
    await apolloClient.mutate<
    WalkthroughGraphQL.CreateMemberMutation,
    WalkthroughGraphQL.CreateMemberMutationVariables
    >({
      mutation: gql(WalkthroughGraphQL.createMember),
      variables: {
        input: {
          owner: token,
          email, // Backfill email
          lastLogin: formatISO(Date.now()) // Record new login
        }
      }
    })
  } catch (e) {
    try {
      await apolloClient.mutate<
      WalkthroughGraphQL.UpdateMemberMutation,
      WalkthroughGraphQL.UpdateMemberMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.updateMember),
        variables: {
          input: {
            owner: token,
            email, // Backfill email
            lastLogin: formatISO(Date.now()) // Record new login
          }
        }
      })
    } catch (e) {
      console.log('Create and update both failed.  Error: ', e)
      console.log('This means that member.updatedAt was not updated.')
    }
  }
}

export async function initializeMissingTasksForMember (
  apolloClient: ApolloClient<NormalizedCacheObject>
): Promise<boolean | null | undefined> {
  const response =
    await apolloClient.mutate<WalkthroughGraphQL.InitializeMissingTasksForMemberMutation>(
      {
        mutation: gql(WalkthroughGraphQL.initializeMissingTasksForMember)
      }
    )
  // initializeMissingTasksForMember alters backend state in complicated ways that are not returned by the
  // query.  Because they are not returned, certain cache fields (like tasks and lists of tasks) will become stale when
  // we call this query.  ApolloClient provides custom update functions to handle this, but it can get quite
  // complicated.  As a short term solution, we just totally reset the cache and refetch every query.  This obviously is
  // not ideal from a performance perspective, but we think it is probably okay as a first approximation, especially as
  // we do not anticipate invoking this operation often.
  apolloClient.resetStore().catch(() => {})
  return response.data?.initializeMissingTasksForMember
}
