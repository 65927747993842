import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

function Edit (props: SvgProps): JSX.Element {
  return (
    <Svg
      width={16}
      height={15}
      fill='none'
      {...props}
    >
      <Path
        d='M14.613.989a1.399 1.399 0 00-1.978 0L4.52 9.105l1.978 1.978 8.116-8.116a1.398 1.398 0 000-1.978zM5.082 12.497L3.105 10.52l-.798.797c-.546.546-1.319 3.297-1.319 3.297s2.75-.773 3.297-1.319l.797-.798z'
        fill='#fff'
      />
    </Svg>
  )
}

export default Edit
