import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg'

const ExponentialGrowth = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 414 896'
    fill='none'
    preserveAspectRatio='xMaxYMax slice'
    {...props}
  >
    <G clipPath='url(#a)'>
      <Path
        d='M-15 699c24 1 43 8 59-12 10.638-13.298 52-66 19-60-37.387 6.798 51.058 162.924 9 110-34.5-43.413 77-78 44-88-21.055-6.38-18 58 33 58 165 0 252-115 280-281'
        stroke='#fff'
        strokeWidth={2}
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M0 0h414v896H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default ExponentialGrowth
