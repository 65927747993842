import { StyleSheet, ViewStyle } from 'react-native'
import { Theme } from '../../themes/theme'

export const appStyle = (
  theme: Theme
): {
  navigationBar: ViewStyle
} =>
  StyleSheet.create({
    navigationBar: {
      backgroundColor: theme.color.surfaceTwo,
      shadowColor: theme.shadow.shadowColor,
      shadowOffset: { width: 0, height: theme.shadow.upperDropShadowYOffset },
      shadowOpacity: theme.shadow.shadowOpacity,
      shadowRadius: theme.shadow.shadowRadius,
      borderTopWidth: 0
    }
  })
