import { StyleSheet, ViewStyle } from 'react-native'
import { Theme } from '../../../themes/theme'

export const backButtonStyle = (
  theme: Theme
): {
  backBox: ViewStyle
} =>
  StyleSheet.create({
    backBox: {
      paddingHorizontal: theme.spacing.horizontalSpaceMedium
    }
  })
