import * as WalkthroughGraphQL from 'amplify-client-graphql'
import {
  getTasksFromContainer,
  processAllFinancialTasksFromGetMemberQuery
} from 'multi-type-processor'
import {
  isNonNullish,
  collapseProcessingResultsIntoArray,
  getUSWestCoastDate
} from 'global-utils'
import { payCriticalExpensesWhyThisTaskHandler } from './pay-critical-expenses'
import { buildEmergencyFundWhyThisTaskHandler } from './build-emergency-fund'
import { extendEmergencyFundToThreeMonthsWhyThisTaskHandler } from './extend-emergency-fund-to-three-months'
import { investInBrokerageAccountWhyThisTaskHandler } from './invest-in-brokerage-account'
import { payOffDebtWhyThisTaskHandler } from './pay-off-debt'
import { contributeTo401kWhyThisTaskHandler } from './contribute-to-401k'
import { contributeToIraWhyThisTaskHandler } from './contribute-to-ira'

export type WhyThisTaskHandler<T> = (task: T | null) => JSX.Element | null

// Returns an unstyled Text component explaining why the user should do this task.
// Note that this logic will always do its best to justify the task, even if the task
// was not recommended by Walkthrough.  Only use this as justification for tasks that are
// already blessed.
// TODO: change this/associated handlers to return a string instead of a JSX.Element
// narrower typing.
export function getWhyThisTask (
  data: WalkthroughGraphQL.GetMemberQuery,
  taskTypename: string,
  taskPrimaryKey: string
): JSX.Element | null {
  const currentWestCoastYear = getUSWestCoastDate(
    new Date(Date.now())
  ).getUTCFullYear()
  // We chose a value of of 6% because that is our assumed stability premium breakeven rate
  // for market-level investments That is, we don't want to surface debts below this interest
  // rate because we would never recommend them from the goal engine (although this is an assumed
  // implementation detail of the goal engine and could change!) The source of truth for this
  // number is the relation between stabilityPremium and portfolioReturnOfMarginalDollar in
  // https://github.com/eettaa/emb/blob/dev/src/app/amplify/backend/function/graphqlhandler/ts/run-goal-engine/run-goal-engine-driver.ts
  const minDebtInterestRate = 0.06

  const tasks = getTasksFromContainer(data.getMember)

  const handlers = {
    payCriticalExpensesTask:
      payCriticalExpensesWhyThisTaskHandler(taskTypename),
    buildOneMonthEmergencyFundTask:
      buildEmergencyFundWhyThisTaskHandler(taskTypename),
    extendEmergencyFundToThreeMonthsTask:
      extendEmergencyFundToThreeMonthsWhyThisTaskHandler(
        data,
        taskTypename,
        currentWestCoastYear,
        minDebtInterestRate
      ),
    extendEmergencyFundToSixMonthsTask: () => null, // Deregistered,
    investInBrokerageAccountTask: investInBrokerageAccountWhyThisTaskHandler(
      data,
      taskTypename,
      currentWestCoastYear,
      minDebtInterestRate
    ),
    payOffDebtTasks: payOffDebtWhyThisTaskHandler(
      data,
      taskTypename,
      taskPrimaryKey,
      currentWestCoastYear,
      minDebtInterestRate
    ),
    contributeTo401k2022Tasks: () => null, // Deregistered
    contributeTo401kTasks: contributeTo401kWhyThisTaskHandler(
      data,
      taskTypename,
      taskPrimaryKey,
      currentWestCoastYear,
      minDebtInterestRate
    ),
    contributeToIra2022Task: () => null, // Deregistered
    contributeToIraTasks: contributeToIraWhyThisTaskHandler(
      data,
      taskTypename,
      taskPrimaryKey,
      currentWestCoastYear,
      minDebtInterestRate
    )
  }

  const whyThisTaskElements = collapseProcessingResultsIntoArray(
    processAllFinancialTasksFromGetMemberQuery(tasks, handlers)
  ).filter(isNonNullish)

  return whyThisTaskElements.length === 1 ? whyThisTaskElements[0] : null
}
