import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg'

const JungleLeft0 = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 414 728'
    fill='none'
    preserveAspectRatio='xMaxYMax slice'
    {...props}
  >
    <G clipPath='url(#a)' stroke='#202232' strokeWidth={2}>
      <Path d='m419 586-429 84.792V861h429V586Z' fill='#27293D' />
      <Path
        d='M380 634.046C380 659.977 358.958 681 333 681s-47-21.023-47-46.954c0-11.757 4.321-27.849 11.511-41.979 4.367-8.58 9.762-16.377 15.827-22.014C319.407 564.412 326.069 561 333 561c8.267 0 16.153 4.858 23.086 12.537 6.919 7.663 12.771 18.013 16.957 28.682C377.47 613.501 380 625.05 380 634.046Z'
        fill='#fff'
      />
      <Path
        d='M380 634.046C380 659.977 358.958 681 333 681s-47-21.023-47-46.954c0-11.757 4.321-27.849 11.511-41.979 4.367-8.58 9.762-16.377 15.827-22.014C319.407 564.412 326.069 561 333 561c8.267 0 16.153 4.858 23.086 12.537 6.919 7.663 12.771 18.013 16.957 28.682C377.47 613.501 380 625.05 380 634.046Z'
        fill='#fff'
      />
      <Path d='M364.545 228.719c3.07 27.867 2.226 84.281-11.897 84.281-14.122 0-14.683-56.5-13.148-85.5' />
      <Path d='M359.5 229c-3.07 47.086-.271 148 13.851 148 14.122 0 14.583-98 13.048-147M283.631 223.088C286.922 259.041 285.688 392 263.881 392c-18.271 0-18.926-134.881-14.812-174M99 184c0 28.915.998 68.611-9 69.5-20.751 1.845-10.103-36.818-10.103-76.618' />
      <Path d='M83 178.5c6.917 40.14 4 96.945-9.5 98-24.383 1.905-16.946-62.69-15-108.5' />
      <Path d='M421 230.289C325.889 231.65 107.133 214.097-7 133' />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M0 0h414v728H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default JungleLeft0
