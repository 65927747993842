import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import { THEME } from '../../../constants'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { clickThroughModuleStyle } from './click-through-module.style'
import { GoalBar } from '../common/goal-bar/goal-bar'
import { TaskFlowStage, TaskKey, TaskStepsData } from '../tasks/task-flow-data'
import { ScrollWithArrow } from '../common/scroll/scroll-with-arrow'
import { BackButton } from '../../common/buttons/back-button'

function ClickThroughModule (props: {
  taskSteps: TaskStepsData
  navigation: GenericCompositeNavigationProp
  taskKeyForBackButton?: TaskKey
}): JSX.Element {
  const [moduleIndex, setModuleIndex] = React.useState(0)
  const numSubtasks = props.taskSteps.subtasks.length
  const subtask = props.taskSteps.subtasks[moduleIndex]
  const contentProgressPercent = Math.round(
    ((moduleIndex + 1) / numSubtasks) * 100
  )

  const [scrollToTopTrigger, setScrollToTopTrigger] = React.useState(0)

  React.useLayoutEffect(() => {
    // If moduleIndex is <= 0, we're on the first screen and the back button on this page
    // takes the member back to the intro screen.
    if (moduleIndex <= 0) {
      props.navigation.setOptions({
        headerTitle: props.taskSteps.title,
        headerLeft: () => (
          <BackButton
            handleOnPress={() => {
              if (props.taskKeyForBackButton !== undefined) {
                props.navigation.navigate('TaskScreen', {
                  taskKey: props.taskKeyForBackButton,
                  flowStage: TaskFlowStage.INTRO
                })
              } else {
                props.navigation.goBack()
              }
            }}
          />
        )
      })
    } else {
      // If moduleIndex is greater than 0, we're somewhere in the click through flow. The back button on this page
      // takes the member back to the previous click through page.
      props.navigation.setOptions({
        headerLeft: () => (
          <BackButton
            handleOnPress={() => {
              setModuleIndex(moduleIndex - 1)
            }}
          />
        )
      })
    }
  }, [props.navigation, props.taskKeyForBackButton, moduleIndex])

  React.useEffect(() => {
    // Set a new, different value to trigger a ScrollToTop call
    setScrollToTopTrigger(Math.random())
  }, [moduleIndex])

  const style = clickThroughModuleStyle(THEME)

  return (
    <View style={style.mainBox}>
      <GoalBar goalProgressPercent={contentProgressPercent} />
      <View style={style.extraPadding} />
      <View style={style.taskBox}>
        <ScrollWithArrow triggerScrollToTop={scrollToTopTrigger}>
          <View style={style.contentBox}>
            <Text style={style.description}>{subtask.description}</Text>
          </View>
        </ScrollWithArrow>
      </View>
      <Pressable
        onPress={() => {
          moduleIndex + 1 < numSubtasks
            ? setModuleIndex(moduleIndex + 1)
            : props.taskSteps.button.handleOnPressButton({
              navigation: props.navigation
            })
        }}
      >
        <View style={style.button}>
          <Text style={style.buttonText}>
            {moduleIndex + 1 < numSubtasks
              ? 'Next'
              : props.taskSteps.button.buttonText}
          </Text>
        </View>
      </Pressable>
    </View>
  )
}

export { ClickThroughModule }
