import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish, collapseProcessingResultsIntoArray } from 'global-utils'
import { getTaskTitleAsGerund } from '../get-task-title'
import { Text } from 'react-native'
import {
  getTasksFromContainer,
  processAllFinancialTasksFromGetMemberQuery,
  InputFinancialTaskTypesForGetMemberQuery
} from 'multi-type-processor'
import {
  accountNameWithBalanceFallback,
  getDebtInterest
} from '../../../../../util/account-parsing'

type SentenceFragmentTaskHandler<T> = (task: T | null) => string | null

function sentenceFragmentTaskHandler<
  T extends Omit<WalkthroughGraphQL.FinancialTask, '__typename'> & {
    __typename: string
  }
> (
  includeIfComplete: boolean,
  includeIfIncomplete: boolean
): SentenceFragmentTaskHandler<T> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      ((includeIfComplete && task.isComplete) ||
        (includeIfIncomplete && !task.isComplete))
    ) {
      return getTaskTitleAsGerund(task.__typename)
    }
    return null
  }
}

function sentenceFragment401kTaskHandler (
  includeIfComplete: boolean,
  includeIfIncomplete: boolean,
  contributionYear: number
): SentenceFragmentTaskHandler<WalkthroughGraphQL.ContributeTo401kTask> {
  return (task) => {
    const k401Account = task?.k401Account
    if (
      isNonNullish(task) &&
      isNonNullish(k401Account) &&
      task.contributionYear === contributionYear &&
      ((includeIfComplete && task.isComplete) ||
        (includeIfIncomplete && !task.isComplete))
    ) {
      // Purposefully exclude taskStepsCompletedAtLeastOnce and accountName to keep the 401k string shorter & simpler.
      return getTaskTitleAsGerund(
        task.__typename,
        /* taskStepsCompletedAtLeastOnce = */ undefined,
        task.contributionYear
      )
    }
    return null
  }
}

function sentenceFragmentDebtTaskHandler (
  includeIfComplete: boolean,
  includeIfIncomplete: boolean,
  minDebtInterestRate: number
): SentenceFragmentTaskHandler<WalkthroughGraphQL.PayOffDebtTask> {
  return (task) => {
    const debtAccount = task?.debtAccount
    if (
      isNonNullish(task) &&
      isNonNullish(debtAccount) &&
      ((includeIfComplete && task.isComplete) ||
        (includeIfIncomplete && !task.isComplete))
    ) {
      const debtInterest = getDebtInterest(debtAccount)
      if (isNonNullish(debtInterest) && debtInterest >= minDebtInterestRate) {
        return getTaskTitleAsGerund(
          task.__typename,
          undefined,
          undefined,
          accountNameWithBalanceFallback(debtAccount) ?? undefined
        )
      }
    }
    return null
  }
}

function sentenceFragmentIraTaskHandler (
  includeIfComplete: boolean,
  includeIfIncomplete: boolean,
  contributionYear: number
): SentenceFragmentTaskHandler<WalkthroughGraphQL.ContributeToIraTask> {
  return (task) => {
    if (
      isNonNullish(task) &&
      task.contributionYear === contributionYear &&
      ((includeIfComplete && task.isComplete) ||
        (includeIfIncomplete && !task.isComplete))
    ) {
      return getTaskTitleAsGerund(
        task.__typename,
        undefined,
        task.contributionYear
      )
    }
    return null
  }
}

// Gets a list of tasks in the form of a sentence fragment. Only includes tasks that
// are included in allowedTaskTypes and are complete or incomplete depending on parameters.
// Use the conjunction parameter to specify whether the tasks should be joined by 'and' or 'or'.
// For example:
// - allowedTaskTypes is ['payCriticalExpensesTask', 'payOffDebtTasks'],
// - includeIfComplete is true
// - includeIfIncomplete is false
// - contributionYear is 2023 (not applicable for this example)
// - minDebtInterestRate is 0.07 (7%)
// - conjunction is 'and',
// The member has completed their payCriticalExpensesTask but only one of their two payOffDebtTasks,
// both of which have a debt interest rate > 7%. The pay off debt task with AccountNameA is complete,
// and the pay off debt task with AccountNameB is incomplete.
// getTasksAsSentenceFragment will return the sentence fragment:
// "paying your critical expenses and paying off debt on your AccountNameA account"
export function getTasksAsSentenceFragment (
  data: WalkthroughGraphQL.GetMemberQuery,
  // A set of task types to include in the sentence fragment. You can find the task type strings
  // in emb/src/app/frontend/node_modules/amplify-client-graphql/js/codegen/API.d.ts -> GetMemberQuery.
  // as the field name of the task in the getMember query response. For example, the field name of the
  // PayCriticalExpensesTask in the getMember query is 'payCriticalExpensesTask', and that is the string
  // to pass in the allowedTaskTypes set here.
  allowedTaskTypes: Set<string>,
  includeIfComplete: boolean,
  includeIfIncomplete: boolean,
  contributionYear: number,
  minDebtInterestRate: number,
  conjunction: 'and' | 'or'
): JSX.Element | null {
  const tasks = getTasksFromContainer(data.getMember)
  const handlers = {
    payCriticalExpensesTask: allowedTaskTypes.has('payCriticalExpensesTask')
      ? sentenceFragmentTaskHandler<
      InputFinancialTaskTypesForGetMemberQuery['payCriticalExpensesTask']
      >(includeIfComplete, includeIfIncomplete)
      : () => null,
    buildOneMonthEmergencyFundTask: allowedTaskTypes.has(
      'buildOneMonthEmergencyFundTask'
    )
      ? sentenceFragmentTaskHandler<
      InputFinancialTaskTypesForGetMemberQuery['buildOneMonthEmergencyFundTask']
      >(includeIfComplete, includeIfIncomplete)
      : () => null,
    extendEmergencyFundToThreeMonthsTask: allowedTaskTypes.has(
      'extendEmergencyFundToThreeMonthsTask'
    )
      ? sentenceFragmentTaskHandler<
      InputFinancialTaskTypesForGetMemberQuery['extendEmergencyFundToThreeMonthsTask']
      >(includeIfComplete, includeIfIncomplete)
      : () => null,
    extendEmergencyFundToSixMonthsTask: () => null, // Deregistered,
    // If investInBrokerageAccountTask is in allowedTaskTypes, always include it.
    // Don't consider includeIfComplete and includeIfIncomplete for brokerage accounts, because
    // this task can never be completed — it just keeps getting recommended.
    investInBrokerageAccountTask: allowedTaskTypes.has(
      'investInBrokerageAccountTask'
    )
      ? sentenceFragmentTaskHandler<
      InputFinancialTaskTypesForGetMemberQuery['investInBrokerageAccountTask']
      >(true, true)
      : () => null,
    payOffDebtTasks: allowedTaskTypes.has('payOffDebtTasks')
      ? sentenceFragmentDebtTaskHandler(
        includeIfComplete,
        includeIfIncomplete,
        minDebtInterestRate
      )
      : () => null,
    contributeTo401k2022Tasks: () => null, // Deregistered
    contributeTo401kTasks: allowedTaskTypes.has('contributeTo401kTasks')
      ? sentenceFragment401kTaskHandler(
        includeIfComplete,
        includeIfIncomplete,
        contributionYear
      )
      : () => null,
    contributeToIra2022Task: () => null, // Deregistered
    contributeToIraTasks: allowedTaskTypes.has('contributeToIraTasks')
      ? sentenceFragmentIraTaskHandler(
        includeIfComplete,
        includeIfIncomplete,
        contributionYear
      )
      : () => null
  }

  // Get a map of all the task types to task sentence fragments and reduce task types with
  // multiple tasks into a single string.
  const taskMap = processAllFinancialTasksFromGetMemberQuery(tasks, handlers)
  if (taskMap.contributeTo401kTasks.entries.length > 1) {
    taskMap.contributeTo401kTasks = [
      getTaskTitleAsGerund('contributeTo401kTasks', undefined, contributionYear)
    ]
  }
  if (taskMap.contributeToIraTasks.entries.length > 1) {
    taskMap.contributeToIraTasks = [
      getTaskTitleAsGerund('contributeToIraTasks', undefined, contributionYear)
    ]
  }
  if (taskMap.payOffDebtTasks.entries.length > 1) {
    taskMap.payOffDebtTasks = [getTaskTitleAsGerund('payOffDebtTasks')]
  }

  const taskSentenceFragments =
    collapseProcessingResultsIntoArray(taskMap).filter(isNonNullish)

  // Concatenate task strings into a single string, with:
  // > 2 tasks: each task separated by a comma, with an ', and' before the last task (uses an oxford comma)
  // 2 tasks: the two tasks separated by 'and'
  // 1 task: the task
  // 0 tasks: an empty string
  const combinedTasks = taskSentenceFragments.reduce(
    (previousValue, task, index) => {
      if (index === 0) {
        return task
      }
      if (
        index === taskSentenceFragments.length - 1 &&
        taskSentenceFragments.length === 2
      ) {
        return `${previousValue} ${conjunction} ${task}`
      }
      if (index === taskSentenceFragments.length - 1) {
        return `${previousValue}, ${conjunction} ${task}`
      }
      return `${previousValue}, ${task}`
    },
    ''
  )

  return combinedTasks.length > 0 ? <Text>{combinedTasks}</Text> : null
}
