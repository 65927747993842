import * as React from 'react'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { THEME } from '../../../constants'
import {
  gql,
  useLazyQuery,
  useApolloClient,
  ApolloClient,
  NormalizedCacheObject
} from '@apollo/client'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { getTaskDrawers } from './get-task-drawers'
import { loadToken } from '../../auth/load-token'
import { useIsMounted } from '../../../util/use-is-mounted'
import { useFocusEffect } from '@react-navigation/native'
import { initializeMissingTasksForMember } from '../../../api-calls/member'
import { BackButton } from '../../common/buttons/back-button'
import { Pressable, View, Text } from 'react-native'
import { TaskFlowStage } from './task-flow-data'
import { taskListScreenStyle } from './task-list-screen.style'
import { Drawers, DrawerWithNodes } from '../common/drawers/drawers'

export function TaskListScreen (props: {
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  const [getMember, { loading, error, data }] = useLazyQuery<
  WalkthroughGraphQL.GetMemberQuery,
  WalkthroughGraphQL.GetMemberQueryVariables
  >(gql(WalkthroughGraphQL.getMember))

  const isMounted = useIsMounted()

  React.useEffect(() => {
    // There are known race condititions with async code in useEffect (like what if the component unmounts before the
    // function returns?), but this is still the recommended approach. See
    // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function getOwnerAndQuery (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getMember({ variables: { owner: owner } })
      }
    }
    getOwnerAndQuery().catch(console.log)
  }, [])

  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>
  useFocusEffect(
    React.useCallback(() => {
      // This call is quite expensive as we currently require a full cache refresh afterwards (it does not return all
      // the values it mutates- see https://github.com/eettaa/emb/blob/dev/src/app/frontend/api-calls/member.ts#L24).
      // However, this ensures that any time we re-visit the task-list-screen page we are always looking at fresh data.
      // Future optimization could remove much of the current expense; for now we just resign ourselves to this
      // inefficienty- it is likely still a better user experience than either looking at stale tasks or having to
      // navigate to an advanced 'refresh' button in the settings page.
      initializeMissingTasksForMember(apolloClient).catch(console.log)
    }, [])
  )

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      headerLeft: () => (
        <BackButton
          handleOnPress={() => {
            props.navigation.goBack()
          }}
        />
      )
    })
  }, [props.navigation])

  let drawersData: DrawerWithNodes[] = []
  if (!loading && error === undefined) {
    drawersData = getTaskDrawers(props.navigation, data)
  }

  const style = taskListScreenStyle(THEME)

  return (
    <View style={style.container}>
      <Drawers colorGradient={THEME.color.gradientA} drawers={drawersData} />
      <Pressable
        style={style.button}
        onPress={() => {
          props.navigation.navigate('TaskScreen', {
            flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL
          })
        }}
      >
        <Text style={style.buttonText}>Recommend a task for me!</Text>
      </Pressable>
    </View>
  )
}
