import * as React from "react"
import Svg, { SvgProps, Path, Ellipse } from "react-native-svg"

const YuyuCreditCards = (props: SvgProps): JSX.Element => (
  <Svg
    width={115}
    height={119}
    fill="none"
    {...props}
  >
    <Path
      d="M76 88h-.894c-5.067.001-9.847.002-14.21-.332-4.62-.353-8.696-1.078-12.078-2.533-3.359-1.445-6.032-3.61-7.883-6.876C39.074 74.973 38 70.492 38 64.347c0-12.26 4.27-21.436 11.09-27.557 6.838-6.135 16.332-9.283 26.91-9.283s20.072 3.148 26.909 9.283C109.73 42.911 114 52.088 114 64.347c0 6.145-1.073 10.626-2.935 13.912-1.851 3.267-4.524 5.431-7.883 6.876-3.382 1.455-7.457 2.18-12.078 2.533-4.363.334-9.143.333-14.21.332H76Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={63.382} cy={69.507} rx={2.294} ry={2.293} fill="#505050" />
    <Ellipse cx={89.765} cy={69.507} rx={2.294} ry={2.293} fill="#505050" />
    <Path
      d="M72.559 74.093c.934.865 3.62 2.077 6.882 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M81.162 36.827c-5.506 5.045-10.706 2.102-12.618 0C63.497 32.699 69.882 12.556 73.706 3c4.78 9.173 12.962 28.781 7.456 33.827ZM92.002 35.555c-2.632 1.21-4.203-.492-4.66-1.495-1.374-2.123 3.373-8.805 5.919-11.88.677 3.954 1.373 12.164-1.259 13.375ZM57.748 35.555c2.632 1.21 4.203-.492 4.66-1.495 1.374-2.123-3.373-8.805-5.919-11.88-.677 3.954-1.373 12.164 1.259 13.375Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M61.926 96.878a4 4 0 0 0 1.7-5.395l-22.392-42.99a4 4 0 0 0-5.395-1.7l-17.39 9.056a4 4 0 0 0-1.7 5.396l22.393 42.99a3.999 3.999 0 0 0 5.395 1.7l17.389-9.057Z"
      fill="#FF7272"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M53.174 88.085a4 4 0 0 0 1.7-5.396l-2.22-4.26a4 4 0 0 0-5.395-1.7l-11.48 5.98a4 4 0 0 0-1.7 5.396l2.219 4.26a4 4 0 0 0 5.395 1.7l11.48-5.98Z"
      fill="#BFB5FF"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M49.143 83.383a1.59 1.59 0 0 0 2.822-1.47l-.342-.656a1.59 1.59 0 1 0-2.822 1.47l.342.656Z"
      fill="#FDFF86"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="m37.926 89.643 6.564-3.419M38.951 91.613l8.534-4.445"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M54.541 90.71a4 4 0 0 0 1.7-5.395l-2.219-4.26a4 4 0 0 0-5.395-1.7l-11.481 5.98a4 4 0 0 0-1.7 5.395l2.219 4.26a4 4 0 0 0 5.395 1.7l11.481-5.98Z"
      fill="#75B7E7"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M50.51 86.008a1.59 1.59 0 1 0 2.822-1.47l-.342-.656a1.59 1.59 0 1 0-2.821 1.47l.342.656Z"
      fill="#FDFF86"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="m39.293 92.27 6.565-3.42M40.318 94.238l8.534-4.445"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M55.908 93.336a4 4 0 0 0 1.7-5.395l-2.219-4.26a4 4 0 0 0-5.395-1.7l-11.481 5.98a4 4 0 0 0-1.7 5.395l2.219 4.26a4 4 0 0 0 5.395 1.7l11.481-5.98Z"
      fill="#91E5E5"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M51.878 88.634a1.59 1.59 0 0 0 2.821-1.47l-.342-.656a1.59 1.59 0 1 0-2.821 1.47l.342.656Z"
      fill="#FDFF86"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="m40.66 94.895 6.565-3.42M41.685 96.864l8.534-4.445"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M40.522 63.796a4 4 0 0 0 1.7-5.395l-2.22-4.26a4 4 0 0 0-5.395-1.7l-11.48 5.98a4 4 0 0 0-1.7 5.395l2.218 4.26a4 4 0 0 0 5.396 1.7l11.48-5.98Z"
      fill="#FFB672"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M36.491 59.094a1.59 1.59 0 1 0 2.821-1.47l-.342-.656a1.59 1.59 0 0 0-2.82 1.47l.341.656Z"
      fill="#FDFF86"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="m25.273 65.355 6.565-3.42M26.299 67.324l8.534-4.445"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M41.89 66.422a4 4 0 0 0 1.7-5.396l-2.218-4.26a4 4 0 0 0-5.396-1.7l-11.48 5.98a4 4 0 0 0-1.7 5.396l2.218 4.26a4 4 0 0 0 5.396 1.7l11.48-5.98Z"
      fill="#C3EC81"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M37.86 61.72a1.59 1.59 0 0 0 2.822-1.47l-.343-.656a1.59 1.59 0 1 0-2.82 1.47l.341.656Z"
      fill="#FDFF86"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="m26.643 67.98 6.564-3.419M27.668 69.95l8.534-4.445"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M43.258 69.048a4 4 0 0 0 1.7-5.396l-2.22-4.26a4 4 0 0 0-5.395-1.7l-11.48 5.98a4 4 0 0 0-1.7 5.396l2.219 4.26a4 4 0 0 0 5.395 1.7l11.48-5.98Z"
      fill="#FFAFEA"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M39.227 64.346a1.59 1.59 0 1 0 2.822-1.47l-.342-.656a1.59 1.59 0 1 0-2.822 1.47l.342.656Z"
      fill="#FDFF86"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="m28.01 70.606 6.564-3.419M29.035 72.576l8.534-4.445"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M61.926 96.878a4 4 0 0 0 1.7-5.395l-.168-.322a4 4 0 0 0-5.395-1.7L40.674 98.52a4 4 0 0 0-1.7 5.395l.168.321a3.999 3.999 0 0 0 5.395 1.7l17.389-9.057ZM49.277 72.59a4 4 0 0 0 1.7-5.396l-.167-.321a4 4 0 0 0-5.396-1.7L28.026 74.23a4 4 0 0 0-1.7 5.396l.167.32a4 4 0 0 0 5.396 1.7l17.388-9.056ZM38.461 109.755a4 4 0 0 0 .599-4.55L15.706 60.368a4 4 0 0 0-3.974-2.13l-4.339.465c-2.801.3-4.423 3.326-3.121 5.825l24.772 47.561c1.282 2.462 4.622 2.901 6.497.854l2.92-3.188Z"
      fill="#FF7272"
      stroke="#505050"
      strokeWidth={2}
    />
  </Svg>
)

export default YuyuCreditCards
