import * as React from 'react'
import Svg, { SvgProps, Circle, Rect } from 'react-native-svg'

const AddCircle = (props: SvgProps): JSX.Element => (
  <Svg
    width={36}
    height={36}
    fill='none'
    {...props}
  >
    <Circle cx={18} cy={18} r={18} fill='#24C592' />
    <Rect x={16.398} y={10} width={3.2} height={16} rx={1.6} fill='#fff' />
    <Rect x={16.398} y={10} width={3.2} height={16} rx={1.6} fill='#fff' />
    <Rect x={16.398} y={10} width={3.2} height={16} rx={1.6} fill='#fff' />
    <Rect
      x={26}
      y={16.4}
      width={3.2}
      height={16}
      rx={1.6}
      transform='rotate(90 26 16.4)'
      fill='#fff'
    />
    <Rect
      x={26}
      y={16.4}
      width={3.2}
      height={16}
      rx={1.6}
      transform='rotate(90 26 16.4)'
      fill='#fff'
    />
    <Rect
      x={26}
      y={16.4}
      width={3.2}
      height={16}
      rx={1.6}
      transform='rotate(90 26 16.4)'
      fill='#fff'
    />
  </Svg>
)

export default AddCircle
