import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

const DownArrowCircle = (props: SvgProps): JSX.Element => (
  <Svg
    width={31}
    height={31}
    fill='none'
    {...props}
  >
    <Circle
      cx={15.5}
      cy={15.5}
      r={15.5}
      transform='rotate(90 15.5 15.5)'
      fill='#fff'
      fillOpacity={0.5}
    />
    <Path
      d='M17.12 7.44a1 1 0 1 0-2 0h2Zm-1.708 16.827a1 1 0 0 0 1.414 0l6.364-6.364a1 1 0 0 0-1.414-1.414l-5.657 5.657-5.657-5.657a1 1 0 0 0-1.414 1.414l6.364 6.364ZM15.119 7.44v16.12h2V7.44h-2Z'
      fill='#fff'
    />
  </Svg>
)

export default DownArrowCircle
