import * as React from 'react'
import { View, Text, Pressable } from 'react-native'
import { WebView, WebViewMessageEvent } from 'react-native-webview'
import { mxConnectWidgetStyle } from './mx-connect-widget.style'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { useIsMounted } from '../../../../util/use-is-mounted'
import {
  gql,
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient
} from '@apollo/client'
import { isNonNullish, exhaustiveSwitchGuard } from 'global-utils'
import { AccountLinkingFlowType } from '../account-linking-flow-type'
import { VisitFinancialProvider } from '../visit-financial-provider'
import { WidgetThemeToMatch } from '../visit-financial-provider.style'
import { NavigationAnchor } from '../../common/links/navigation-anchor'
import { ContentModule } from '../../click-through-module/click-through-module-screen'
import {
  MxPostMessageSchema,
  MxPostMessageSchemaType
} from './mx-post-message-schema'
import * as uuid from 'uuid'
import { QuestionOverlay } from '../../common/questions/question-overlay'
import { QuestionAndAnswers } from '../../common/questions/question-data'
import { newAccountQuestions } from '../../common/questions/content/new-account-questions'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// External interface for the state of the widget.
export enum ConnectionState {
  READY, // Widget is loaded
  CONNECTING, // Widget is "Connecting" (spinning wheel, could take a long time)
  NEEDS_ATTENTION, // Widget needs end-user input to proceed
  SUCCESSFULLY_CONNECTED // Widget successfully connected accounts
}

// Generic style for the elements of our nested iframe
const styleTag =
  'style="width: 100%; height: 100%; border-width: 0px; overflow: hidden; margin: 0px"'

// NOTE THAT THIS IS AN ANTI-PATTERN: WE DO NOT WANT TO PROGRAMMATICALLY GENERATE CODE FOR THE BROWSER AS IT
// MAY EXPOSE US TO XSS ATTACKS.  WE ATTEMPT TO MITIGATE THIS BY DISALLOWING LITERAL DOUBLE QUOTES FROM THE URL (THIS
// IS AN ILLEGAL URL CHARACTER ANYWAYS).
function getSourceDoc (widgetUrl: string): string {
  if (widgetUrl.includes('"')) {
    throw new Error(
      'Not loading the relevant widget url because it contains an unexpected " literal character... is ' +
        'this an XSS attack?'
    )
  }
  return `
    <html ${styleTag}>
      <body ${styleTag}>
        <iframe src="${widgetUrl}" ${styleTag}>
        </iframe>
      </body>
    </html
  `
}

// String manipulation to generate a postmessage proxy with an instanceId field added.
// Because the proxy is the only place that sees the real origin, we have to do origin validation here.
// We allow post messages from both integration and prod MX environments. The frontend has no way
// of knowing/need to know whether it is being run in a prod or integration mode for MX.  From a security
// perspective, this seems okay; the failure mode would be that a malicious actor can masquerade as the
// integration origin in a prod instance (the masquerading is already a problem), and the post message
// processing we anticipate only affects App frontend rendering (navigation etc)... annoying at worst.
// NOTE THAT THIS IS AN ANTI-PATTERN: WE DO NOT WANT TO PROGRAMMATICALLY GENERATE CODE FOR THE BROWSER AS IT
// MAY EXPOSE US TO XSS ATTACKS. WE ATTEMPT TO MITIGATE THIS BY REQUIRING THAT THE INSTANCE ID IS A VALID UUID.
const eventListenerInjection = (instanceId: string): string => {
  if (uuid.validate(instanceId)) {
    return `
      window.addEventListener(
        'message',
        (event) => {
          if (event.origin === 'https://int-widgets.moneydesktop.com' || event.origin === 'https://widgets.moneydesktop.com') {
            window.parent.postMessage({ instanceId: '${instanceId}', mxData: event.data }, '*')
          }
        },
        true)
    `
  }
  return `
    console.log('not injecting postmessage proxy because instanceId was not a validated uuid')
  `
}

function shouldProvideDataProviderIdForFinancialInstitutionUser (
  flowType: AccountLinkingFlowType | undefined
): boolean {
  if (flowType === undefined) {
    return false
  }
  switch (flowType) {
    case AccountLinkingFlowType.EDIT_ACCOUNT:
      console.log(
        'Unexpected flow type detected in MxConnectWidgetScreen: ' +
          AccountLinkingFlowType[flowType]
      )
      return true
    case AccountLinkingFlowType.REFRESH_ACCOUNT:
      return true
    case AccountLinkingFlowType.LINK_NEW_ACCOUNTS:
    case AccountLinkingFlowType.VISIT_FINANCIAL_PROVIDER:
      return false
  }
}

interface AccountInfoForMxConnect {
  dataProviderIdForFinancialInstitutionUser?: string; // Needed for EDIT_ACCOUNT and REFRESH_ACCOUNT flow types
  financialInstitutionAccountName?: string; // Needed for VISIT_FINANCIAL_PROVIDER
}

// This structure holds the data needed to load an MX Connect instance
export interface ConnectInstanceData {
  accountFlowType: AccountLinkingFlowType
  accountInfo?: AccountInfoForMxConnect
}

// Navigation data for each Connect Instance
export interface ConnectInstanceNavigationData {
  nextButtonText: string
  onPressNext: () => void
}

// Loads and renders a single MX Connect instance.
export function MxConnect (props: {
  connectInstance: ConnectInstanceData
  navigationData: ConnectInstanceNavigationData
  // Component will call this when institutions are selected/deselected
  selectedInstitutionHandler: (institution: string | null) => void
  // Component will call this when connection state changes.  See ConnectionState
  // definition for value semantics.
  connectionStateHandler: (state: ConnectionState) => void
}): JSX.Element {
  if (
    shouldProvideDataProviderIdForFinancialInstitutionUser(
      props.connectInstance.accountFlowType
    ) &&
    !isNonNullish(
      props.connectInstance.accountInfo
        ?.dataProviderIdForFinancialInstitutionUser
    )
  ) {
    throw new Error(
      'accountInfo.dataProviderIdForFinancialInstitutionUser must be provided for flow type: ' +
        AccountLinkingFlowType[props.connectInstance.accountFlowType]
    )
  }
  if (
    props.connectInstance.accountFlowType ===
      AccountLinkingFlowType.VISIT_FINANCIAL_PROVIDER &&
    !isNonNullish(
      props.connectInstance.accountInfo?.financialInstitutionAccountName
    )
  ) {
    throw new Error(
      'accountInfo.financialInstitutionAccountName must be provided for flow type: ' +
        AccountLinkingFlowType[props.connectInstance.accountFlowType]
    )
  }

  const [instanceId] = React.useState(uuid.v4())
  const [widgetUrl, setWidgetUrl] = React.useState('')
  const [shouldDisplayNavButtons, setShouldDisplayNavButtons] =
    React.useState(false)
  const [buttonTimerId, setButtonTimerId] = React.useState<NodeJS.Timeout>()
  const [questionIndex, setQuestionIndex] = React.useState<number | null>(null)
  const [questions, setQuestions] = React.useState<QuestionAndAnswers[][]>([])
  const [shouldDisplayManualButton, setShouldDisplayManualButton] =
    React.useState(false)
  const [connectionState, setConnectionState] =
    React.useState<ConnectionState | null>(null)
  const [selectedInstitution, setSelectedInstitution] = React.useState<
  string | null
  >(null)

  const isMounted = useIsMounted()
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>

  // Reset screen to show no widget when the params change (as in the case of a chain of MX Widgets).
  React.useLayoutEffect(() => {
    setWidgetUrl('')
    setShouldDisplayNavButtons(false)
    clearTimeout(buttonTimerId)
  }, [props.connectInstance])

  // Reload a new MX Connect Widget if the connection instance prop changes.
  React.useEffect(() => {
    (async function (): Promise<void> {
      // Backstop: Show the next button if the url takes longer than 5s to load
      const timerId = setTimeout(() => {
        if (isMounted()) {
          setShouldDisplayNavButtons(true)
        }
      }, 5000)
      if (isMounted()) {
        setButtonTimerId(timerId)
      }
      // Get an MX Connect URL for all flows except VISIT_FINANCIAL_PROVIDER
      if (
        props.connectInstance.accountFlowType !==
        AccountLinkingFlowType.VISIT_FINANCIAL_PROVIDER
      ) {
        const urlResponse = await apolloClient.query<
        WalkthroughGraphQL.GetMxConnectWidgetUrlQuery,
        WalkthroughGraphQL.GetMxConnectWidgetUrlQueryVariables
        >({
          query: gql(WalkthroughGraphQL.getMxConnectWidgetUrl),
          variables: {
            dataProviderIdForFinancialInstitutionUser:
              shouldProvideDataProviderIdForFinancialInstitutionUser(
                props.connectInstance.accountFlowType
              )
                ? props.connectInstance.accountInfo
                  ?.dataProviderIdForFinancialInstitutionUser
                : undefined
          },
          fetchPolicy: 'no-cache'
        })
        if (isMounted()) {
          if (isNonNullish(urlResponse.data.getMxConnectWidgetUrl)) {
            setWidgetUrl(urlResponse.data.getMxConnectWidgetUrl)
          } else {
            console.log('MX Connect Url Request came back empty')
          }
        }
      }
    })()
      .catch(console.log)
      .finally(() => {
        if (isMounted()) {
          // Display the next button if the query has returned, no matter what (assuming
          // it is mounted)
          setShouldDisplayNavButtons(true)
        }
      })
  }, [props.connectInstance])

  // Handle state updates for VISIT_FINANCIAL_PROVIDER
  React.useEffect(() => {
    if (
      props.connectInstance.accountFlowType ===
      AccountLinkingFlowType.VISIT_FINANCIAL_PROVIDER
    ) {
      setSelectedInstitution(
        props.connectInstance.accountInfo?.financialInstitutionAccountName ??
          null
      )
      setConnectionState(ConnectionState.NEEDS_ATTENTION)
    } else {
      setSelectedInstitution(null)
    }
  }, [props.connectInstance])

  // Report changes in connectionState to the parent component
  React.useEffect(() => {
    if (isNonNullish(connectionState)) {
      props.connectionStateHandler(connectionState)
    }
  }, [connectionState])

  // Display manual button if no connection state reported or we're in connection state READY
  // Note that component nesting means that this button is AND'ed with shouldDisplayNavButtons, but
  // we don't need to represent that here.
  React.useEffect(() => {
    setShouldDisplayManualButton(connectionState === ConnectionState.READY)
  }, [connectionState])

  // Report changes in selectedInstitution to the parent component
  React.useEffect(() => {
    props.selectedInstitutionHandler(selectedInstitution)
  }, [selectedInstitution])

  // Returns a valid instance of MxPostMessageSchemaType or throws an error
  // Warning: This function does not protect against XSS attacks... treat freeform (string) validated input
  // skeptically!!
  function extractAndValidatePostMessage (
    event: WebViewMessageEvent
  ): MxPostMessageSchemaType {
    if ((event.nativeEvent as any).origin !== window.origin) {
      throw new Error(
        'In extractAndValidatePostMessage, encountered a post message with a different origin. ' +
          'We will ignore it as we only care about events that we proxy (and thus have the same origin).'
      )
    }
    if ((event.nativeEvent.data as any).instanceId !== instanceId) {
      throw new Error(
        'In extractAndValidatePostMessage, encountered post message for a different MX instance'
      )
    }
    return MxPostMessageSchema.parse((event.nativeEvent.data as any).mxData)
  }

  const insets = useSafeAreaInsets()
  const style = mxConnectWidgetStyle
  return (
    <View
      style={[
        style.topLevelView,
        {
          // Padding to handle safe area for the MX connect widget, which takes up the whole screen.
          paddingTop: insets.top,
          paddingBottom: insets.bottom,
          paddingLeft: insets.left,
          paddingRight: insets.right
        }
      ]}
    >
      {props.connectInstance.accountFlowType ===
      AccountLinkingFlowType.VISIT_FINANCIAL_PROVIDER ? (
        <VisitFinancialProvider
          financialInstitutionAccountName={
            props.connectInstance.accountInfo
              ?.financialInstitutionAccountName ?? '(unnamed account)'
          }
          widgetThemeToMatch={WidgetThemeToMatch.MX_CONNECT}
        />
          ) : widgetUrl === '' ? null : (
        <WebView
          source={{
            // We specify html here so we can wrap the widget in *another* iframe and proxy all the post messages.
            // This allows us to append instanceId's to each message for better differentiation between multiple connect
            // instances.
            html: getSourceDoc(widgetUrl)
          }}
          injectedJavaScript={eventListenerInjection(instanceId)}
          onMessage={(event: WebViewMessageEvent) => {
            try {
              // DO NOT ADD CODE BEFORE THIS! We must immediately validate the post message.  If you add code before this,
              // you may be introducing a security vulnerability!
              const validatedMessage = extractAndValidatePostMessage(event)
              // Now that the message is validated, we can add handling logic.
              const messageType = validatedMessage.type
              switch (messageType) {
                case 'mx/connect/connected/primaryAction': {
                  setConnectionState(ConnectionState.READY)
                  break
                }
                case 'mx/connect/loaded': {
                  switch (validatedMessage.metadata.initial_step) {
                    case 'search': {
                      setConnectionState(ConnectionState.READY)
                      setSelectedInstitution(null)
                      break
                    }
                    case 'connected': {
                      setConnectionState(
                        ConnectionState.SUCCESSFULLY_CONNECTED
                      )
                      break
                    }
                    // All loaded states except for search and connected need attention
                    default: {
                      setConnectionState(ConnectionState.NEEDS_ATTENTION)
                    }
                  }
                  break
                }
                case 'mx/connect/stepChange': {
                  switch (validatedMessage.metadata.current) {
                    case 'connected': {
                      setConnectionState(
                        ConnectionState.SUCCESSFULLY_CONNECTED
                      )
                      break
                    }
                    case 'search': {
                      setSelectedInstitution(null)
                      setConnectionState(ConnectionState.READY)
                      break
                    }
                    case 'enterCreds':
                    case 'oauth':
                    case 'mfa':
                    case 'existingMember':
                    case 'loginError':
                    case 'error': {
                      setConnectionState(ConnectionState.NEEDS_ATTENTION)
                      break
                    }
                    case 'connecting':
                    case 'timeout': {
                      setConnectionState(ConnectionState.CONNECTING)
                      break
                    }
                    default:
                      break // We ignore step changes by default
                  }
                  break
                }
                case 'mx/connect/memberConnected': {
                  setConnectionState(ConnectionState.SUCCESSFULLY_CONNECTED)
                  break
                }
                case 'mx/connect/selectedInstitution': {
                  setSelectedInstitution(validatedMessage.metadata.name)
                  break
                }
                default:
                  exhaustiveSwitchGuard(messageType)
              }
            } catch {
              // Don't print anything for invalid messages for now.
            }
          }}
        />
          )}
      {shouldDisplayNavButtons ? (
        <View style={style.footer}>
          {shouldDisplayManualButton ? (
            <Pressable
              style={style.manualButton}
              onPress={() => {
                setQuestions(newAccountQuestions(setSelectedInstitution))
                setQuestionIndex(0)
              }}
            >
              <Text style={style.footerButtonText}>Add manually</Text>
            </Pressable>
          ) : null}
          <Pressable
            style={style.nextButton}
            onPress={() => {
              // Manually report success for VISIT_FINANCIAL_PROVIDER flows.
              if (
                props.connectInstance.accountFlowType ===
                AccountLinkingFlowType.VISIT_FINANCIAL_PROVIDER
              ) {
                setConnectionState(ConnectionState.SUCCESSFULLY_CONNECTED)
              }
              props.navigationData.onPressNext()
            }}
          >
            <Text style={style.footerButtonText}>
              {props.navigationData.nextButtonText}
            </Text>
          </Pressable>
          <NavigationAnchor
            style={[style.privacyLink]}
            text={'Privacy'}
            handlePress={(nav) =>
              nav.navigate('ClickThroughModuleScreen', {
                module: ContentModule.MX_PRIVACY
              })
            }
          />
          <Text style={style.usdDisclosure}>
            Note: We don't handle foreign currency accounts yet — just USD.
          </Text>
        </View>
      ) : null}
      {isNonNullish(questionIndex) &&
      questionIndex >= 0 &&
      questionIndex < questions.length ? (
        <QuestionOverlay
          questionIndex={questionIndex}
          setQuestionIndex={setQuestionIndex}
          questions={questions}
          onFinalSaveState={() => {
            setConnectionState(ConnectionState.SUCCESSFULLY_CONNECTED)
            props.navigationData.onPressNext()
          }}
        />
          ) : null}
    </View>
  )
}
