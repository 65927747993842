import * as React from "react"
import ExclamationMark  from './exclamation-mark'
import { Animated, Easing } from 'react-native'

export function FlashingExclamationMark(): JSX.Element {
  const opacity = React.useRef(new Animated.Value(0)).current

  React.useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(
          opacity,
          {
            toValue: 1,
            duration: 500,
            easing: Easing.inOut(Easing.poly(3)),
            useNativeDriver: false // TODO: update this to true for native apps
          }
        ),
        Animated.timing(
          opacity,
          {
           toValue: 0,
           duration: 500,
           easing: Easing.out(Easing.inOut(Easing.poly(3))),
           useNativeDriver: false // TODO: update this to true for native apps
          }
        )
      ])
     ).start()
  }, [opacity])

  return (
    <Animated.View
      style={{opacity}}
    >
      <ExclamationMark/>
    </Animated.View>
  )
}
