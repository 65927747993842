import * as React from "react"
import Svg, { SvgProps, Path, Ellipse, Rect, Circle } from "react-native-svg"

const YuyuCalculator = (props: SvgProps): JSX.Element => (
  <Svg
    width={105}
    height={105}
    fill="none"
    {...props}
  >
    <Path
      d="M65.008 87.878h-.895c-5.068.001-9.849.002-14.213-.331-4.621-.353-8.698-1.077-12.08-2.53-3.36-1.443-6.034-3.604-7.885-6.866C28.073 74.87 27 70.396 27 64.26c0-12.241 4.27-21.404 11.092-27.516 6.839-6.127 16.335-9.27 26.916-9.27 10.58 0 20.077 3.143 26.915 9.27 6.823 6.112 11.093 15.275 11.093 27.516 0 6.136-1.074 10.61-2.936 13.89-1.85 3.263-4.525 5.424-7.884 6.867-3.383 1.453-7.46 2.177-12.08 2.53-4.365.333-9.146.332-14.214.331h-.894Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={52.387} cy={69.412} rx={2.295} ry={2.29} fill="#505050" />
    <Ellipse cx={78.776} cy={69.412} rx={2.295} ry={2.29} fill="#505050" />
    <Path
      d="M61.566 73.992c.935.864 3.62 2.074 6.884 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M70.17 36.779c-5.506 5.038-10.708 2.099-12.62 0C52.502 32.656 58.89 12.542 62.713 3c4.78 9.16 12.965 28.74 7.458 33.779ZM81.013 35.509c-2.632 1.209-4.204-.492-4.66-1.493-1.375-2.12 3.373-8.793 5.919-11.864.677 3.949 1.373 12.148-1.259 13.357ZM46.753 35.509c2.632 1.209 4.203-.492 4.66-1.493 1.375-2.12-3.373-8.793-5.92-11.864-.677 3.949-1.373 12.148 1.26 13.357Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Rect
      x={1.812}
      y={63.992}
      width={33.718}
      height={45.624}
      rx={9}
      transform="rotate(-30.532 1.812 63.992)"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
    />
    <Circle
      cx={37.288}
      cy={67.52}
      r={2.451}
      transform="rotate(-30.532 37.288 67.52)"
      fill="#FF7272"
    />
    <Circle
      cx={40.49}
      cy={72.949}
      r={2.451}
      transform="rotate(-30.532 40.49 72.95)"
      fill="#FF7272"
    />
    <Circle
      cx={43.693}
      cy={78.378}
      r={2.451}
      transform="rotate(-30.532 43.693 78.378)"
      fill="#FF7272"
    />
    <Circle
      cx={46.894}
      cy={83.808}
      r={2.451}
      transform="rotate(-30.532 46.894 83.808)"
      fill="#FF7272"
    />
    <Circle
      cx={30.652}
      cy={71.434}
      r={2.451}
      transform="rotate(-30.532 30.652 71.434)"
      fill="#8E8E8E"
    />
    <Circle
      cx={33.854}
      cy={76.863}
      r={2.451}
      transform="rotate(-30.532 33.854 76.863)"
      fill="#8E8E8E"
    />
    <Circle
      cx={37.056}
      cy={82.292}
      r={2.451}
      transform="rotate(-30.532 37.056 82.292)"
      fill="#8E8E8E"
    />
    <Circle
      cx={40.258}
      cy={87.721}
      r={2.451}
      transform="rotate(-30.532 40.258 87.721)"
      fill="#8E8E8E"
    />
    <Circle
      cx={24.016}
      cy={75.347}
      r={2.451}
      transform="rotate(-30.532 24.016 75.347)"
      fill="#8E8E8E"
    />
    <Circle
      cx={27.219}
      cy={80.777}
      r={2.451}
      transform="rotate(-30.532 27.219 80.777)"
      fill="#8E8E8E"
    />
    <Circle
      cx={30.421}
      cy={86.206}
      r={2.451}
      transform="rotate(-30.532 30.421 86.206)"
      fill="#8E8E8E"
    />
    <Circle
      cx={33.623}
      cy={91.635}
      r={2.451}
      transform="rotate(-30.532 33.623 91.635)"
      fill="#8E8E8E"
    />
    <Circle
      cx={17.381}
      cy={79.261}
      r={2.451}
      transform="rotate(-30.532 17.381 79.261)"
      fill="#8E8E8E"
    />
    <Circle
      cx={34.085}
      cy={62.091}
      r={2.451}
      transform="rotate(-30.532 34.085 62.09)"
      fill="#FF7272"
    />
    <Circle
      cx={27.45}
      cy={66.004}
      r={2.451}
      transform="rotate(-30.532 27.45 66.004)"
      fill="#505050"
    />
    <Circle
      cx={20.815}
      cy={69.918}
      r={2.451}
      transform="rotate(-30.532 20.815 69.918)"
      fill="#505050"
    />
    <Circle
      cx={14.178}
      cy={73.832}
      r={2.451}
      transform="rotate(-30.532 14.178 73.832)"
      fill="#505050"
    />
    <Circle
      cx={20.582}
      cy={84.691}
      r={2.451}
      transform="rotate(-30.532 20.582 84.69)"
      fill="#8E8E8E"
    />
    <Circle
      cx={23.784}
      cy={90.12}
      r={2.451}
      transform="rotate(-30.532 23.784 90.12)"
      fill="#8E8E8E"
    />
    <Circle
      cx={26.988}
      cy={95.549}
      r={2.451}
      transform="rotate(-30.532 26.988 95.549)"
      fill="#8E8E8E"
    />
    <Rect
      x={5.594}
      y={65.479}
      width={28.714}
      height={7.004}
      rx={3.502}
      transform="rotate(-30.532 5.594 65.48)"
      fill="#505050"
    />
  </Svg>
)

export default YuyuCalculator
