import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

function AccountsIcon (props: SvgProps): JSX.Element {
  return (
    <Svg
      width={14}
      height={24}
      fill='none'
      {...props}
    >
      <Path
        d='M12.48 4.058v-.12l-.096-.076c-1.303-1.03-2.797-1.62-4.475-1.77V.25H6.117v1.844c-1.652.153-2.946.685-3.848 1.625h0C1.348 4.687.88 5.797.88 7.042c0 1.222.466 2.288 1.384 3.185.91.89 2.258 1.609 4.022 2.17l1.289.456h0l.008.003c1.351.43 2.317.93 2.923 1.486h.001c.586.53.884 1.232.884 2.132 0 .848-.336 1.588-1.03 2.23h0c-.677.634-1.573.942-2.73 1.072a8.502 8.502 0 01-1.246 0C4.198 19.6 3 18.794 1.156 17.33L.75 17.01v2.194l.081.075c1.46 1.34 3.226 2.108 5.286 2.306v2.165h1.792v-2.167c1.7-.181 3.021-.766 3.932-1.78.931-1.038 1.409-2.177 1.409-3.413 0-1.333-.471-2.473-1.409-3.406-.93-.925-2.308-1.636-4.108-2.146l-1.287-.428h0l-.01-.003c-1.272-.369-2.2-.848-2.806-1.425-.599-.57-.891-1.241-.891-2.025 0-.733.31-1.41.967-2.038h0c.644-.617 1.54-.875 2.688-1 .298-.033 1.022-.019 1.244 0 1.845.158 2.972.757 4.435 1.93l.407.325V4.058z'
        fill={props.color}
        stroke={props.color}
        strokeWidth={0.5}
      />
    </Svg>
  )
}

export default AccountsIcon
