import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../../../constants'

export const pointsStyle = StyleSheet.create({
  // IMPORTANT: The width and height of the points view need to be 100% so that the animations
  // that occur with the points have the same coordinate system as the games in the jungle screen.
  pointsView: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    // Render points at the same zIndex as the spinning wheel game. Points are above the spinning
    // wheel game in the code, so they don't need to be at a lower zIndex than the spinning wheel
    // game, just at the same zIndex.
    zIndex: -1
  },
  pointsPressable: {
    width: '100%',
    height: '100%',
    alignContent: 'center',
    justifyContent: 'center'
  },
  pointsCircle: {
    position: 'absolute',
    bottom: 20,
    textAlign: 'center',
    alignSelf: 'center',
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: THEME.color.rainbow.darkBlue,
    borderColor: THEME.color.outline,
    borderWidth: 2,
    alignContent: 'center',
    justifyContent: 'center'
  },
  pointsInfoMessage: {
    position: 'absolute',
    bottom: 110,
    textAlign: 'center',
    alignSelf: 'center',
    padding: THEME.spacing.horizontalSpaceMedium,
    borderRadius: 10,
    maxWidth: 300,
    backgroundColor: THEME.color.rainbow.darkBlue,
    borderColor: THEME.color.outline,
    borderWidth: 2,
    alignContent: 'center',
    justifyContent: 'center'
  }
})

export const pointsToAddStyle = (
  pointsToAddStartingXCoordinateFromLeftOfContainer: number,
  pointsToAddStartingYCoordinateFromTopOfContainer: number
): {
  pointsToAdd: ViewStyle
} =>
  StyleSheet.create({
    pointsToAdd: {
      position: 'absolute',
      top: pointsToAddStartingYCoordinateFromTopOfContainer,
      left: pointsToAddStartingXCoordinateFromLeftOfContainer,
      zIndex: -10,
      textAlign: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      width: 80,
      height: 80,
      borderRadius: 40,
      borderColor: THEME.color.outline,
      borderWidth: 2
    }
  })
