import { accountNameWithBalanceFallback } from '../../../../../util/account-parsing'
import { AnswerType, QuestionAndAnswers } from '../question-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import {
  isNonNullish,
  stringInStringEnum,
  collapseProcessingResultsIntoArray
} from 'global-utils'
import { YesOrNo, yesOrNoToDisplayText } from '../display-values/yes-or-no'
import { purgeMxFinancialInstitutionUser } from '../../../../../api-calls/accounts'
import {
  getAccountsFromContainer,
  processAllAccountsFromMemberTasksAndAccountsQuery
} from 'multi-type-processor'
import { Anchor } from '../../links/anchor'

export const kDeleteMxFinancialInsitutionUserQuestionKey = 'DELETE_MX_QUESTION'

export const deleteMxFinancialInsitutionUserQuestion: (
  dataProviderIdForFinancialInstitutionUser: string,
  memberQuery:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
) => QuestionAndAnswers = (
  dataProviderIdForFinancialInstitutionUser,
  memberQuery
) => {
  const accountMap = getAccountsFromContainer(memberQuery?.getMember)

  // Find the relevant MX accounts with the given dataProviderIdForFinancialInstitutionUser
  // Expect the input type to be some Account specialization.  We override the type field to
  // allow it to propagate downstream (needed for deleting the account).
  function filterForMxAndDataProviderIdForFinancialInstitutionUser<
    T extends Omit<WalkthroughGraphQL.Account, '__typename'>
  > (account: T | null): string | null {
    if (
      account?.dataProvider === WalkthroughGraphQL.DataProvider.MX &&
      account.dataProviderIdForFinancialInstitutionUser ===
        dataProviderIdForFinancialInstitutionUser
    ) {
      return accountNameWithBalanceFallback(account)
    }
    return null
  }

  const accountHandlerMap = {
    savingsAccounts: filterForMxAndDataProviderIdForFinancialInstitutionUser,
    checkingAccounts: filterForMxAndDataProviderIdForFinancialInstitutionUser,
    debtAccounts: filterForMxAndDataProviderIdForFinancialInstitutionUser,
    otherInvestmentAccounts:
      filterForMxAndDataProviderIdForFinancialInstitutionUser,
    traditionalIraAccounts:
      filterForMxAndDataProviderIdForFinancialInstitutionUser,
    rothIraAccounts: filterForMxAndDataProviderIdForFinancialInstitutionUser,
    k401Accounts: filterForMxAndDataProviderIdForFinancialInstitutionUser,
    uncategorizedAccounts:
      filterForMxAndDataProviderIdForFinancialInstitutionUser
  }

  const accountNamesToSurface = collapseProcessingResultsIntoArray(
    processAllAccountsFromMemberTasksAndAccountsQuery(
      accountMap,
      accountHandlerMap
    )
  ).filter(isNonNullish)

  return {
    key: kDeleteMxFinancialInsitutionUserQuestionKey,
    question: {
      text:
        accountNamesToSurface.length > 0 ? (
          <>
            In order to fully delete this bank account from Walkthrough and our
            data providers, you must delete all your accounts associated with
            your financial institution login, which include:{'\n\n'}
            {accountNamesToSurface.join('\n')}
            {'\n\n'}
            Do you want to fully delete all of these accounts now?
            {'\n\n'}(Select 'No' or click outside this question to exit without
            deleting).
          </>
        ) : (
          <>
            Somehow you're trying to delete an account that doesn't exist — now
            you've really done it!{'\n\n'}
            Seeing as you were trying to delete the account anyway... yay?
            {'\n\n'}
            If you want to double check, please come back later and try to
            delete the account again. If that still doesn't work, please{' '}
            <Anchor text="contact us" href="mailto:support@walkthrough.co" />.
          </>
        )
    },
    answers:
      accountNamesToSurface.length > 0
        ? [
            {
              key: 'ONLY_ANSWER',
              answerType: AnswerType.SELECTION,
              getInitialAnswer: async () => {
                return YesOrNo.NO
              }, // No by default
              validation: {
                isValid: (answer: string) =>
                  stringInStringEnum(YesOrNo, answer),
                notValidInfoText: 'Answer must be Yes or No.'
              },
              selectionAnswer: {
                items: Array.from(yesOrNoToDisplayText).map(([key, value]) => {
                  return { enumValue: key, value, displayText: value }
                })
              },
              storeValue: async (client, value) => {
                if (!stringInStringEnum(YesOrNo, value)) {
                  console.log(
                    "Provided value ('" + value + "') is not Yes or No."
                  )
                  return
                }
                if (value === YesOrNo.YES) {
                  await purgeMxFinancialInstitutionUser(
                    client,
                    dataProviderIdForFinancialInstitutionUser
                  )
                }
              }
            }
          ]
        : []
  }
}
