import {
  InputAccountTypesMemberLatestFinancialViewQuery,
  AccountWithSnapshotShape,
  getBalanceFromLastSnapshot
} from 'multi-type-processor'
import { isNonNullish } from 'global-utils'
import { YesOrNo } from '../screens/authenticated/common/questions/display-values/yes-or-no'
import * as WalkthroughGraphQL from 'amplify-client-graphql'

// **************************************************************************************************************
// IMPORTANT: If you update this logic, make sure it stays in sync with the logic in the backends's run-goal-engine
// convert-debt-account-from-schema.ts, which parses the interest APY that we use for the goal engine.
// **************************************************************************************************************
// This is explicitly not "APY" as it may be some more imprecise form of interest.  Either way, it's the 'right'
// interest to surface to the end user. Returned as a decimal (0.08 = 8% interest)
export function getDebtInterest (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
): number | null {
  let interest: number | null = null
  const snapshot = account.snapshots?.items[0]

  if (isNonNullish(account.memberDeclaredInterestAPY)) {
    interest = account.memberDeclaredInterestAPY
  } else if (isNonNullish(snapshot)) {
    if (isNonNullish(snapshot.interestAPY)) {
      interest = snapshot.interestAPY
    } else if (isNonNullish(snapshot.interestImprecise)) {
      interest = snapshot.interestImprecise
    }
  }
  return interest
}

// Same as getDebtInterest but returns the interest as a string in  "[X]*X.XX%" form (e.g. "108.54%" or "0.04%")
export function getDebtInterestString (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
): string | null {
  const interest = getDebtInterest(account)
  return isNonNullish(interest)
    ? interest.toLocaleString('en-US', {
      style: 'percent',
      minimumFractionDigits: 2
    })
    : null
}

// **************************************************************************************************************
// IMPORTANT: If you update this logic, make sure it stays in sync with the logic in the backends's run-goal-engine
// convert-debt-account-from-schema.ts, which parses the interest interest inactive that we use for the goal engine.
// **************************************************************************************************************
// Matches the phrasing of interestInactive in the schema. Calculates that value whether the
// the interest on a debt account is inactive depending on memberDeclaredInterestInactive & interestInactive.
export function getInterestInactive (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
): boolean | null {
  let interestInactive: boolean | undefined
  const snapshot = account.snapshots?.items[0]
  if (isNonNullish(account.memberDeclaredInterestInactive)) {
    interestInactive = account.memberDeclaredInterestInactive
  } else if (
    isNonNullish(snapshot) &&
    isNonNullish(snapshot.interestInactive)
  ) {
    interestInactive = snapshot.interestInactive
  }
  if (isNonNullish(interestInactive)) {
    return interestInactive
  }
  return null
}

// Gets a YesOrNo enum value for a human readable string that denotes whether interest is being
// charged on a account (so it returns the opposite of getInterestInactive, which matches the schema
// interestInactive phrasing).
export function getInterestBeingCharged (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
): YesOrNo | null {
  const interestInactive = getInterestInactive(account)
  if (isNonNullish(interestInactive)) {
    return interestInactive ? YesOrNo.NO : YesOrNo.YES
  }
  return null
}

// Returns a normalized human-readable string describing the account, based off of account name and balance). If no
// information is available, returns null instead.
export function accountNameWithBalanceFallback (
  account: Omit<WalkthroughGraphQL.Account, '__typename'> &
  AccountWithSnapshotShape
): string | null {
  const balance = getBalanceFromLastSnapshot(
    account,
    WalkthroughGraphQL.AssetType.ASSET
  )
  let balanceString: string | undefined
  if (isNonNullish(balance)) {
    balanceString =
      'unnamed (' +
      balance.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) +
      ')'
  }
  if (isNonNullish(account.accountName)) {
    return account.accountName
  } else if (isNonNullish(balanceString)) {
    return balanceString
  } else {
    return null
  }
}

// A match of 0% up to $0 is considered true iff includeZeroMatch is true
export function hasEmployerMatch (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts'],
  includeZeroMatch: boolean
): boolean {
  if (
    isNonNullish(account.employerMatchRules) &&
    account.employerMatchRules.length > 0
  ) {
    const firstMatchRule = account.employerMatchRules[0]
    if (isNonNullish(firstMatchRule)) {
      if (includeZeroMatch) {
        return (
          isNonNullish(firstMatchRule.fractionMatch) &&
          isNonNullish(firstMatchRule.maxEmployerMatch)
        )
      }
      return (
        isNonNullish(firstMatchRule.fractionMatch) &&
        firstMatchRule.fractionMatch > 0 &&
        isNonNullish(firstMatchRule.maxEmployerMatch) &&
        firstMatchRule.maxEmployerMatch > 0
      )
    }
  }
  return false
}

function employerMatchString (
  matchRule?: WalkthroughGraphQL.EmployerMatchRule | null
): string {
  return (
    `${
      matchRule?.fractionMatch?.toLocaleString('en-US', {
        style: 'percent',
        minimumFractionDigits: 0
      }) ?? ''
    }` +
    ' of your contributions up to a ' +
    `${
      matchRule?.maxEmployerMatch?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }) ?? ''
    }` +
    ' total match'
  )
}

export function getEmployerMatchString (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
): string {
  if (!isNonNullish(account.employerMatchRules)) {
    return ''
  }
  let matchRulesString = ''
  if (account.employerMatchRules.length > 0) {
    matchRulesString = employerMatchString(account.employerMatchRules[0])
  }
  for (let i = 1; i < account.employerMatchRules.length; i++) {
    matchRulesString = matchRulesString
      .concat(' and ')
      .concat(employerMatchString(account.employerMatchRules[i]))
  }
  return matchRulesString
}

function employerMatchShortString (
  matchRule?: WalkthroughGraphQL.EmployerMatchRule | null
): string {
  return (
    `${
      matchRule?.fractionMatch?.toLocaleString('en-US', {
        style: 'percent',
        minimumFractionDigits: 0
      }) ?? ''
    }` +
    ' up to ' +
    `${
      matchRule?.maxEmployerMatch?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }) ?? ''
    }`
  )
}

export function getEmployerMatchShortString (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
): string {
  if (!isNonNullish(account.employerMatchRules)) {
    return ''
  }
  let matchRulesString = ''
  if (account.employerMatchRules.length > 0) {
    matchRulesString = employerMatchShortString(account.employerMatchRules[0])
  }
  for (let i = 1; i < account.employerMatchRules.length; i++) {
    matchRulesString = matchRulesString
      .concat(',\n')
      .concat(employerMatchShortString(account.employerMatchRules[i]))
  }
  return matchRulesString
}

export function transactionAggregationTypeToDisplayText (
  aggregationType: WalkthroughGraphQL.TransactionAggregationType
): string {
  switch (aggregationType) {
    case WalkthroughGraphQL.TransactionAggregationType
      .ROTH_401K_CONTRIBUTIONS_2022:
      return '2022 Roth After-Tax 401k contributions'
    case WalkthroughGraphQL.TransactionAggregationType
      .ROTH_IRA_CONTRIBUTIONS_2022:
      return '2022 Roth IRA contributions'
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_401K_POST_TAX_CONTRIBUTIONS_2022:
      return '2022 After-Tax 401k contributions'
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2022:
      return '2022 Traditional Pre-Tax 401k contributions'
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_IRA_PRETAX_CONTRIBUTIONS_2022:
      return '2022 Traditional Pre-Tax IRA contributions'
    case WalkthroughGraphQL.TransactionAggregationType
      .ROTH_401K_CONTRIBUTIONS_2023:
      return '2023 Roth After-Tax 401k contributions'
    case WalkthroughGraphQL.TransactionAggregationType
      .ROTH_IRA_CONTRIBUTIONS_2023:
      return '2023 Roth IRA contributions'
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_401K_POST_TAX_CONTRIBUTIONS_2023:
      return '2023 After-Tax 401k contributions'
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2023:
      return '2023 Traditional Pre-Tax 401k contributions'
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_IRA_PRETAX_CONTRIBUTIONS_2023:
      return '2023 Traditional Pre-Tax IRA contributions'
  }
}

export function getPretax401kTransactionAggregationTypeByYear (
  contributionYear: number
): WalkthroughGraphQL.TransactionAggregationType {
  switch (contributionYear) {
    case 2022:
      return WalkthroughGraphQL.TransactionAggregationType
        .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2022
    case 2023:
      return WalkthroughGraphQL.TransactionAggregationType
        .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2023
    default:
      throw new Error(
        'In getPretax401kTransactionAggregationTypeByYear, encountered unsupported year: ' +
          contributionYear.toString()
      )
  }
}

export function getRoth401kTransactionAggregationTypeByYear (
  contributionYear: number
): WalkthroughGraphQL.TransactionAggregationType {
  switch (contributionYear) {
    case 2022:
      return WalkthroughGraphQL.TransactionAggregationType
        .ROTH_401K_CONTRIBUTIONS_2022
    case 2023:
      return WalkthroughGraphQL.TransactionAggregationType
        .ROTH_401K_CONTRIBUTIONS_2023
    default:
      throw new Error(
        'In getRoth401kTransactionAggregationTypeByYear, encountered unsupported year: ' +
          contributionYear.toString()
      )
  }
}
