import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

const TaskIcon = (props: SvgProps): JSX.Element => (
  <Svg
    width={26}
    height={26}
    fill='none'
    {...props}
  >
    <Circle cx={13} cy={13} r={12} stroke={props.color} strokeWidth={2} />
    <Path
      d='m7.549 14.09 3.469 3.523 7.434-10.065'
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap='round'
    />
  </Svg>
)

export default TaskIcon
