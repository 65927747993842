import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg'

const JungleMiddle = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 414 728'
    fill='none'
    preserveAspectRatio='xMaxYMax slice'
    {...props}
  >
    <G clipPath='url(#a)'>
      <Path
        d='m415 505-429 84.792V780h429V505Z'
        fill='#27293D'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M414.081 499.567c-8.475-7.089-22.933-4.947-28.794-2.269 1.26 11.324 9.897 21.34 15.262 23.748 11.068 4.968 20.104-.226 28.713 2.89-7.084-6.967-5.242-16.056-15.181-24.369Z'
        fill='#009B9B'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M385.32 497.6c14.594 27.192 27.479 18.791 43.629 26.22M385.304 497.449c31.371-2.929 25.464 8.78 43.481 26.237'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M375.073 448.126c-12.493 29.005-33.685 99.024-22.154 143.635'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M388.686 432.19c-7.858 2.033-12.907 11.645-13.891 16.322 7.198 4.13 16.845 3.263 20.598 1.079 7.744-4.506 8.636-12.177 14.22-16.005-7.18 1.25-11.712-3.779-20.927-1.396Z'
        fill='#009B9B'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M374.986 448.623c22.329 2.966 23.017-8.401 34.422-14.899M374.892 448.568c11.963-20.114 16.26-11.511 34.368-14.802'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M363.406 497.824c6.939 4.207 17.226 1.039 21.199-1.562-2.151-8.089-9.527-14.405-13.692-15.557-8.596-2.376-14.605 2.406-21.208 1.101 5.914 4.274 5.563 11.085 13.701 16.018Z'
        fill='#009B9B'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M384.547 496.047c-13.581-18.135-22.042-10.593-34.602-14.191M384.578 496.154c-22.507 5.628-19.484-3.541-34.496-14.22'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M340.612 510.652c-11.823 4.776-17.75 20.758-18.342 28.233 12.032 4.942 26.873 1.611 32.276-2.556 11.148-8.598 10.995-20.709 18.918-27.808-10.924 3.42-18.988-3.469-32.852 2.131Z'
        fill='#00A99B'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M322.429 538.951c14.579-33.732 22.996-21.239 50.516-30.077'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M315.324 553.661c5.619-2.927 7.839-11.129 7.801-14.862-6.15-1.879-13.321.476-15.802 2.797-5.118 4.79-4.51 10.785-8.104 14.679 5.236-2.212 9.516.819 16.105-2.614Z'
        fill='#00A99B'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M323.044 538.774c-6.549 5.473-20.45 16.59-23.667 17.277'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M322.959 538.749c-17.43 1.665-16.048 10.437-23.609 17.385M323.044 538.774c-5.704 17.409-10.404 11.617-23.585 17.302'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M384.751 496.402c-12.498 21.101-36.362 69.715-31.835 95.359'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M321.941 538.984c10.241 8.024 30.638 30.64 30.978 52.777'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M384.964 575.204c-3.18 7.895-8.07 8.872-9.08 8.038-.756-.931-1.711-7.031 3.63-11.886 5.555-5.051-2.933-13.192-5.925-15.281-9.258-6.462-26.435-4.114-36.496 14.982-7.882 14.96-6.668 42.49 11.799 65.225 26.87 2.007 50.157-10.62 61.229-28.881 5.932-9.785 7.298-17.183 1.123-28.055-5.467-9.626-22.316-13.982-26.28-4.142Z'
        fill='#78DE82'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M375.883 583.242c-8.795 11.115-26.505 37.285-26.992 53.04M375.883 583.242c2.56 11.602.746 38.453-26.992 53.04'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M375.885 583.242c-18.361 2.335-43.26 25.407-26.954 52.678M375.886 583.242c20.696 11.987 25.545 31.285-26.792 52.877'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M375.723 583.042c-22.423-52.955-59.419 15.163-26.754 52.516'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M375.684 583.404c-25.194-17.933-51.863 14.653-26.792 52.878M375.647 583.766c58.876 2.159 18.011 47.932-26.354 52.191'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M96.755 416.471c-18.971-16.351-39.87 4.893-43.173 21.573-27.607-12.34-47.721-12.337-46.114 8.426 1.781 23.007 35.759-8.609 38.866 6.416 3.78 18.273-35.806-2.539-32.253 16.217 7.753 40.931 32.064-13.239 40.768 3.336 9.691 18.455-35.317 6.945-27.307 26.154 9.87 23.665 28.735-13.405 35.925-2.143 6.85 10.727-23.671 6.111-16.69 19.327 9.359 17.72 19.632-7.308 23.65-1.922 10.91 14.622-11.986 5.928-5.68 10.844 12.182 9.497 22.976 16 29.078-5.507 2.912-10.264-7.52 4.874-12.077-10.039s26.027 13.06 22.481-5.663c-3.098-16.355-25.077 1.221-26.29-14.443-1.212-15.665 32.629 13.242 34.721-14.772 1.682-22.522-43.638 11.151-37.615-12.174 3.452-13.368 37.924 11.466 32.523-14.708-7-33.929-27.032 5.934-38.154-3.356-14.168-11.836 46.313-11.214 27.341-27.566Z'
        fill='#00B797'
      />
      <Path
        d='M53.582 438.044c3.304-16.68 24.202-37.924 43.173-21.573 18.972 16.352-41.51 15.73-27.341 27.566 11.121 9.29 31.154-30.573 38.154 3.356 5.401 26.174-29.07 1.34-32.523 14.708-6.023 23.325 39.297-10.348 37.615 12.174-2.092 28.014-35.933-.893-34.72 14.772 1.212 15.664 23.191-1.912 26.289 14.443 3.546 18.723-27.038-9.25-22.481 5.663 4.557 14.913 14.989-.225 12.077 10.039-6.102 21.507-16.896 15.004-29.077 5.507-6.307-4.916 16.59 3.778 5.68-10.844-4.019-5.386-14.292 19.642-23.651 1.922-6.981-13.216 23.54-8.6 16.69-19.327-7.19-11.262-26.055 25.808-35.925 2.143-8.01-19.209 36.998-7.699 27.307-26.154-8.704-16.575-33.015 37.595-40.768-3.336-3.553-18.756 36.032 2.056 32.253-16.217-3.107-15.025-37.085 16.591-38.866-6.416-1.607-20.763 18.507-20.766 46.114-8.426Zm0 0c-3.01-24.225-40.546-44.248-64.831-19.396-54.297 55.564 46.642 119.206 38.791 199.814M50.717 228.928c-5.188 27.894-3.762 84.363 20.103 84.363 23.864 0 24.642-58.056 22.048-87.084'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M72.115 227.908c-5.188 36.058-3.242 169.405 31.128 169.405 28.797 0 29.83-135.275 23.345-174.508M295.194 190.149c0 28.914-6.377 82.907 25.29 85.723 20.752 1.845 13.619-59.19 13.619-98.99'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M313.351 184.366c-6.917 40.14-14.656 120.897 9.727 122.801 24.383 1.905 26.588-89.238 24.642-135.047'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M-7 230.289C88.111 231.65 306.867 214.097 421 133'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M341.238 174.501c1.297 24.039 0 66.627 18.806 62.591 14.915-3.2 14.916-59.189 14.916-77.218'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M173.429 589.327c-13.874-8.163-34.226-1.773-42.054 3.401 4.456 15.87 19.245 28.172 27.542 30.372 17.119 4.538 28.94-4.957 42.081-2.495-11.84-8.309-11.3-21.705-27.569-31.278Z'
        fill='#009B9B'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M131.433 592.939c20.518 6.702 63.019 21.61 68.875 27.632'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M131.433 592.939c44.558-11.412 38.766 6.66 68.816 27.42'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M105.193 524.907c-11.26 44.275-25.51 149.085 1.512 210.093'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M121.117 499.026c-10.859 4.713-15.901 19.594-16.23 26.496 11.342 4.235 25.052.774 29.956-3.209 10.118-8.216 9.617-19.369 16.779-26.119-10.064 3.439-17.771-2.694-30.505 2.832Z'
        fill='#007E93'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M105.036 525.579c12.875-9.301 40.154-28.119 46.265-28.99'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M105.036 525.579c12.566-31.462 20.768-20.176 46.114-29.047'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M99.944 598.522c10.987 4.405 25.084-2.489 30.208-7.116-4.987-11.048-17.095-18.362-23.371-19.046-12.948-1.41-20.5 6.801-30.327 6.461 9.524 4.735 10.605 14.536 23.49 19.701Z'
        fill='#009B9B'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M130.019 591.112c-23.811-22.75-34.255-10.036-53.207-12.276M130.084 591.259c-31.148 13.219-28.924-.562-53.06-12.342'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M70.065 622.084c-15.937 9.541-20.762 33.716-19.874 44.52 18.502 4.279 39.129-3.895 45.95-11.088 14.073-14.841 11.032-32.089 20.804-44.047-14.957 7.398-28.191-.574-46.88 10.615Z'
        fill='#008D98'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M50.684 666.718c50.981-8.115 45.083-33.262 65.901-54.815M50.436 666.661c13.167-51.5 28.215-35.612 65.844-54.57'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M118.443 534.022c-2.362-4.482-8.859-6.146-11.807-6.06-1.429 4.982.494 10.723 2.349 12.686 3.828 4.051 8.558 3.47 11.665 6.307-1.794-4.184.562-7.677-2.207-12.933Z'
        fill='#007E93'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M106.6 528.095c1.468 14.014 8.384 12.77 13.939 18.755M106.619 528.029c13.799 4.332 9.266 8.205 13.873 18.736'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M43.616 689.293c7.422-5.473 8.711-17.69 7.788-23.009-9.307-1.263-19.1 3.75-22.136 7.635-6.266 8.016-3.992 16.431-8.268 22.817 7.035-4.365 13.913-1.025 22.616-7.443Z'
        fill='#008D98'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M51.28 666.267c-8.168 9.32-25.625 28.39-30.105 30.113'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M51.157 666.25c-24.749 6.394-20.712 18.595-29.998 30.254M51.28 666.267c-4.17 26.16-12.297 18.977-29.98 30.13'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M98.715 512.706c-3.725-3.43-10.403-2.818-13.151-1.747.32 5.174 4.051 9.937 6.456 11.164 4.962 2.531 9.223.398 13.1 2.027-3.09-3.34-2.038-7.422-6.405-11.444Z'
        fill='#007E93'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M85.573 511.097c6.071 12.711 12.17 9.217 19.406 12.992M85.567 511.028c14.451-.55 11.475 4.621 19.338 12.997'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M90.143 565.221c13.213-9.199 15.949-30.388 14.54-39.676-16.251-2.597-33.6 5.699-39.07 12.324-11.286 13.67-7.633 28.401-15.371 39.322 12.492-7.287 24.407-1.183 39.901-11.97Z'
        fill='#007E93'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M104.248 525.475c-43.596 10.054-37.003 31.445-53.722 51.32M104.468 525.51c-8.327 45.311-22.28 32.471-53.688 51.104'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M130.399 591.571C117.292 624.567 94.202 699.446 106.705 735'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M49.734 666.821c16.637 9.09 51.322 36.665 56.968 68.179'
        stroke='#202232'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <Path
        d='M-15.023 388.816c10.59-14.614 28.77-3.186 33.854 7.962 17.31-13.235 31.415-16.571 33.734-1.745 2.57 16.428-26.503-.102-26.188 10.949.383 13.441 24.686-7.723 25.308 6.019 1.357 29.988-24.681-3.962-28.034 9.105-3.732 14.549 25.918-.991 23.489 13.807-2.993 18.232-22.374-4.631-25.547 4.46-3.022 8.659 17.613.357 14.912 10.782-3.622 13.979-14.98-1.866-16.904 2.578-5.223 12.063 9.389 2.167 5.783 6.661-6.965 8.681-13.455 15.033-21.303.964-3.746-6.713 6.082 2.17 6.802-9.043.72-11.214-16.083 13.477-16.704-.24-.542-11.983 17.787-3.306 16.038-14.491-1.75-11.185-20.682 14.701-26.799-4.595-4.917-15.514 32.45.576 24.356-14.78-4.64-8.801-24.69 14.334-25.247-4.916-.722-24.953 19.941-.325 26.198-8.685 7.97-10.651-34.337-.177-23.748-14.792Z'
        fill='#00A99B'
      />
      <Path
        d='M18.831 396.778c-5.084-11.148-23.264-22.576-33.854-7.962-10.589 14.615 31.718 4.141 23.748 14.792-6.257 8.36-26.92-16.268-26.198 8.685.557 19.25 20.608-3.885 25.247 4.916 8.094 15.356-29.273-.734-24.355 14.78 6.116 19.296 25.048-6.59 26.798 4.595 1.75 11.185-16.58 2.508-16.038 14.491.62 13.717 17.424-10.974 16.704.24-.72 11.213-10.548 2.33-6.802 9.043 7.848 14.069 14.338 7.717 21.303-.964 3.606-4.494-11.006 5.402-5.782-6.661 1.923-4.444 13.28 11.401 16.903-2.578 2.701-10.425-17.934-2.123-14.912-10.782 3.173-9.091 22.554 13.772 25.547-4.46 2.429-14.798-27.221.742-23.489-13.807 3.353-13.067 29.39 20.883 28.034-9.105-.622-13.742-24.925 7.422-25.308-6.019-.315-11.051 28.758 5.479 26.188-10.949-2.319-14.826-16.424-11.49-33.734 1.745Zm0 0c-1.91-17.486 21.088-37.756 42.241-24.361C108.368 402.368 19.602 487.5 42 579'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M132.837 630.504c-13.896 1.448-31.303 7.638-26.309 13.136 5.077 5.591 21.611-.431 39.171-12.448-12.485 8.769-35.062 22.103-30.216 27.44 3.493 3.846 11.625-1.528 19.04-10.169.452-.528.902-1.067 1.348-1.617-5.238 8.51-9.245 16.74-4.932 19.968 4.498 3.368 9.043-5.447 11.882-16.378-.745 8.509.182 16.17 7.019 16.67 12.477.912 1.23-24.015-4.141-35.914 7.922 13.663 15.058 38.243 22.718 31.286 5.49-4.986-5.432-17.601-15.394-25.882 11.812 7.683 28.543 14.862 28.745 8.09.25-8.39-25.651-11.619-36.069-13.494 4.536-8.703 6.636-13.688 10.687-15.219 3.427.556 6.229.219 6.406-2.081.319-4.139-4.552-.971-6.406 2.081-4.051 1.531-6.151 6.516-10.687 15.219.741-6.494 1.537-20.188-1.206-23.018.743-.64.904-6.104-1.432-5.448-4.729 1.328.889 3.482 1.432 5.448 2.743 2.83 1.947 16.524 1.206 23.018-10.241-7.477-35.848-15.417-39.763-7.871-2.909 5.605 12.994 6.57 26.901 7.183Z'
        fill='#FEDBEB'
      />
      <Path
        d='M145.699 631.192c-10.383-4.022-46.054 4.869-39.171 12.448 5.077 5.591 21.611-.431 39.171-12.448Zm0 0c-12.485 8.769-35.062 22.103-30.216 27.44 5.529 6.088 22.689-10.931 30.216-27.44Zm0 0c-6.343 11.352-21.876 30.295-14.76 35.622 7.115 5.328 14.347-19.83 14.76-35.622Zm0 0c-1.133 10.249-8.335 35.002 4.141 35.914 12.477.912 1.23-24.015-4.141-35.914Zm0 0c-10.241-7.477-35.848-15.417-39.763-7.871-3.916 7.546 26.259 6.683 39.763 7.871Zm0 0c10.418 1.875 36.319 5.104 36.069 13.494-.251 8.389-25.86-4.628-36.069-13.494Zm0 0c10.433 6.547 29.647 24.993 22.718 31.286-7.66 6.957-14.796-17.623-22.718-31.286Zm0 0c.741-6.494 1.537-20.188-1.206-23.018m1.206 23.018c4.536-8.703 6.636-13.688 10.687-15.219m-10.687 15.219c-23.376 33.002-43.217 79.824-43.512 110.086m42.306-133.104c.743-.64.904-6.104-1.432-5.448-4.729 1.328.889 3.482 1.432 5.448Zm11.893 7.799c1.854-3.052 6.725-6.22 6.406-2.081-.177 2.3-2.979 2.637-6.406 2.081Z'
        stroke='#202232'
        strokeWidth={2}
      />
      <Path
        d='M17.588 511.504c13.897 1.448 31.303 7.638 26.31 13.136-5.078 5.591-21.611-.431-39.171-12.448 12.485 8.769 35.062 22.103 30.215 27.44-3.569 3.93-11.986-1.77-19.529-10.746a76.097 76.097 0 0 1-.858-1.04c5.238 8.51 9.245 16.74 4.932 19.968-4.498 3.368-9.043-5.447-11.882-16.378.745 8.509-.182 16.17-7.02 16.67-12.476.912-1.229-24.015 4.142-35.914-7.922 13.663-15.058 38.243-22.718 31.286-5.49-4.986 5.431-17.601 15.393-25.882-11.81 7.683-28.542 14.862-28.744 8.09-.25-8.39 25.651-11.619 36.069-13.494C.19 503.489-1.91 498.504-5.96 496.973c-3.427.556-6.23.219-6.407-2.081-.318-4.139 4.553-.971 6.407 2.081 4.051 1.531 6.151 6.516 10.687 15.219-.741-6.494-1.538-20.188 1.206-23.018-.743-.64-.905-6.104 1.431-5.448 4.73 1.328-.888 3.482-1.431 5.448-2.744 2.83-1.947 16.524-1.206 23.018 10.24-7.477 35.848-15.417 39.763-7.871 2.909 5.605-12.995 6.57-26.902 7.183Z'
        fill='#FEDBEB'
      />
      <Path
        d='M4.727 512.192c10.383-4.022 46.054 4.869 39.17 12.448-5.077 5.591-21.61-.431-39.17-12.448Zm0 0c12.485 8.769 35.062 22.103 30.215 27.44-5.528 6.088-22.688-10.931-30.215-27.44Zm0 0c6.343 11.352 21.876 30.295 14.76 35.622-7.115 5.328-14.347-19.83-14.76-35.622Zm0 0c1.133 10.249 8.335 35.002-4.142 35.914-12.476.912-1.229-24.015 4.142-35.914Zm0 0c10.24-7.477 35.848-15.417 39.763-7.871 3.916 7.546-26.26 6.683-39.763 7.871Zm0 0c-10.418 1.875-36.32 5.104-36.07 13.494.251 8.389 25.86-4.628 36.07-13.494Zm0 0c-10.433 6.547-29.647 24.993-22.718 31.286 7.66 6.957 14.796-17.623 22.718-31.286Zm0 0c-.741-6.494-1.538-20.188 1.206-23.018m-1.206 23.018C.19 503.489-1.91 498.504-5.96 496.973m10.687 15.219C28.103 545.194 34.942 571.5 29.5 617.5M5.933 489.174c-.743-.64-.905-6.104 1.431-5.448 4.73 1.328-.888 3.482-1.431 5.448Zm-11.893 7.799c-1.854-3.052-6.725-6.22-6.407-2.081.178 2.3 2.98 2.637 6.407 2.081Z'
        stroke='#202232'
        strokeWidth={2}
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M0 0h414v728H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default JungleMiddle
