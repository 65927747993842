// A full page view of ClickThroughModule, with navigation wrapping.
// This screen is intended for use with educational modules that may be linkable from anywhere in the app.
// For tasks that are meant to be rendered as clickthrough modules, we still intend to use the task screen.
import * as React from 'react'
import { View } from 'react-native'
import { RouteProp } from '@react-navigation/native'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { ClickThroughModule } from './click-through-module'
import { clickThroughModuleScreenStyle } from './click-through-module-screen-style'
import { THEME } from '../../../constants'
import { investmentBackground } from './content/investment-background'
import { portfolioAllocation } from './content/portfolio-allocation'
import { noKickbacks } from './content/no-kickbacks'
import { mxPrivacy } from './content/mx-privacy'
import { TaskStepsData } from '../tasks/task-flow-data'
import JungleRight from '../../../assets/jungle/jungle-right'
import { savingsRate } from './content/savings-rate'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { useLazyQuery, gql } from '@apollo/client'
import { useIsMounted } from '../../../util/use-is-mounted'
import { loadToken } from '../../auth/load-token'
import { exhaustiveSwitchGuard } from 'global-utils'
import { aiAdvisorPrivacy } from './content/ai-advisor-privacy'

export enum ContentModule {
  INVESTMENT_BACKGROUND,
  PORTFOLIO_ALLOCATION,
  NO_KICKBACKS,
  SAVINGS_RATE,
  MX_PRIVACY,
  AI_ADVISOR_PRIVACY
}

function getModule (
  module: ContentModule,
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
): TaskStepsData {
  switch (module) {
    case ContentModule.INVESTMENT_BACKGROUND:
      return investmentBackground
    case ContentModule.PORTFOLIO_ALLOCATION:
      return portfolioAllocation
    case ContentModule.NO_KICKBACKS:
      return noKickbacks
    case ContentModule.SAVINGS_RATE:
      return savingsRate(memberQuery)
    case ContentModule.MX_PRIVACY:
      return mxPrivacy
    case ContentModule.AI_ADVISOR_PRIVACY:
      return aiAdvisorPrivacy
    default:
      exhaustiveSwitchGuard(module)
  }
}

// Note that when navigating from one ClickThroughModuleScreen to another, you must navigation.push() in order to have
// the child content's back button correctly work.  This is one of the rare times where we do recommend .push()'ing and
// it makes conceptual sense- we genuinely are trying to navigate to a new instance of the ClickThroughModuleScreen,
// just as if we were trying to navigate to a new website in a browser, and we would want a completely separate copy of
// any element state.
export function ClickThroughModuleScreen (props: {
  route: RouteProp<
  { ClickThroughModuleScreen?: { module: ContentModule } },
  'ClickThroughModuleScreen'
  >
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  if (props.route.params === undefined) {
    throw new Error(
      'ClickThroughModuleScreen must be provided params despite its route.props.params field technically ' +
        'being optional; please ensure that the relevant callsite is providing them.'
    )
  }
  // Polling for this query is handled centrally by the app in order to not register repeated
  // polling requests.
  const [getFinancialView, { data }] = useLazyQuery<
  WalkthroughGraphQL.MemberLatestFinancialViewQuery,
  WalkthroughGraphQL.MemberLatestFinancialViewQueryVariables
  >(gql(WalkthroughGraphQL.memberLatestFinancialView))

  const isMounted = useIsMounted()

  React.useEffect(() => {
    // There are known race condititions with async code in useEffect (like what if the component unmounts before the
    // function returns?), but this is still the recommended approach. See
    // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function getOwnerAndQuery (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getFinancialView({ variables: { owner: owner } })
      }
    }
    // Only run the query if we are building the savings rate module.
    if (props.route.params?.module === ContentModule.SAVINGS_RATE) {
      getOwnerAndQuery().catch(console.log)
    }
  }, [])

  const content = getModule(props.route.params.module, data)
  const style = clickThroughModuleScreenStyle(THEME)
  return (
    <View style={style.background}>
      <ClickThroughModule taskSteps={content} navigation={props.navigation} />
      <View style={style.jungleBackground}>
        {/* Not sure how to think about this jungle- maybe it's okay that semantically different screens
        have separate instances of the jungle. */}
        {/* This jungle is slightly more zoomed in than the one on the task page because there is now tab footer
        restricting the View size. */}
        <JungleRight />
      </View>
    </View>
  )
}
