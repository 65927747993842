"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.memberLatestFinancialView = void 0;
// Queries member information relevant to financial decisions, financial tasks, and all financial accounts each with the
// most recent snapshot.
// For tasks which link to accounts, only the account ID is provided. The full account (and snapshots) are provided via
// member.<accountType>.snapshots.
exports.memberLatestFinancialView = `
  query MemberLatestFinancialView($owner: String!) {
    getMember(owner: $owner){
      owner
      createdAt
      updatedAt
      birthday
      spouseBirthday
      employmentStatus
      estimatedTaxableIncome
      estimatedAverageMonthlySpending
      taxFilingStatus
      estimatedAverageMonthlyAfterTaxIncome
      lastReceivedSavingsRatePoints
      points
      isAbleToPayBasicExpensesMemberDeclared
      hasOneMonthEmergencyFundMemberDeclared
      activeTaskAbsoluteDollarsToAllocate
      activeTaskWasRecommendedByWalkthrough
      activeTask {
        taskTypename
        taskPrimaryKey
      }
      payCriticalExpensesTask {
        owner
        createdAt
        updatedAt
        isComplete
      }
      buildOneMonthEmergencyFundTask {
        owner
        createdAt
        updatedAt
        isComplete
      }
      extendEmergencyFundToThreeMonthsTask {
        owner
        createdAt
        updatedAt
        isComplete
      }
      extendEmergencyFundToSixMonthsTask {
        owner
        createdAt
        updatedAt
        isComplete
      }
      investInBrokerageAccountTask {
        owner
        createdAt
        updatedAt
        isComplete
        stepsCompletedAtLeastOnce
      }
      payOffDebtTasks {
        items {
          id
          owner
          isComplete
          createdAt
          updatedAt
          debtAccountId
        }
      }
      contributeTo401k2022Tasks {
        items {
          id
          owner
          isComplete
          createdAt
          updatedAt
          k401AccountId
        }
      }
      contributeTo401kTasks {
        items {
          id
          owner
          isComplete
          contributionYear
          createdAt
          updatedAt
          k401AccountId
          stepsCompletedAtLeastOnce
        }
      }
      contributeToIra2022Task {
        owner
        createdAt
        updatedAt
        isComplete
      }
      contributeToIraTasks {
        items {
          id
          owner
          isComplete
          contributionYear
          createdAt
          updatedAt
        }
      }

      savingsAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          earmarks {
            type
            amount
          }
          snapshots(limit: 1, sortDirection: DESC) {
            items {
              id
              createdAt
              updatedAt
              balance
              isAsset
              accountId
              dataProviderActionNeededStatus
              lastUpdatedOnProviderSide
            }
          }
        }
      }
      checkingAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          earmarks {
            type
            amount
          }
          snapshots(limit: 1, sortDirection: DESC) {
            items {
              id
              createdAt
              updatedAt
              balance
              isAsset
              accountId
              dataProviderActionNeededStatus
              lastUpdatedOnProviderSide
            }
          }

        }
      }
      debtAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          memberDeclaredInterestAPY
          memberDeclaredInterestInactive
          snapshots(limit: 1, sortDirection: DESC) {
            items {
              id
              createdAt
              updatedAt
              balance
              isAsset
              accountId
              dataProviderActionNeededStatus
              interestAPY
              interestImprecise
              interestInactive
              lastUpdatedOnProviderSide
            }
          }

        }
      }
      k401Accounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          owner
          createdAt
          updatedAt
          accountId
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          employerMatchRules {
            fractionMatch
            maxEmployerMatch
          }
          isEligibleForRoth401KContributions,
          isEligibleForPreTaxTraditional401KContributions
          isEligibleForAfterTaxTraditional401KContributions
          transactionAggregations {
            type
            amount
          }
          snapshots(limit: 1, sortDirection: DESC) {
            items {
              id
              createdAt
              updatedAt
              balance
              isAsset
              accountId
              dataProviderActionNeededStatus
              lastUpdatedOnProviderSide
            }
          }

        }
      }
      traditionalIraAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          transactionAggregations {
            type
            amount
          }
          snapshots(limit: 1, sortDirection: DESC) {
            items {
              id
              createdAt
              updatedAt
              balance
              isAsset
              accountId
              dataProviderActionNeededStatus
              lastUpdatedOnProviderSide
            }
          }
        }
      }
      rothIraAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          earmarks {
            type
            amount
          }
          transactionAggregations {
            type
            amount
          }
          snapshots(limit: 1, sortDirection: DESC) {
            items {
              id
              createdAt
              updatedAt
              balance
              isAsset
              accountId
              dataProviderActionNeededStatus
              lastUpdatedOnProviderSide
            }
          }
        }
      }
      otherInvestmentAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          earmarks {
            type
            amount
          }
          snapshots(limit: 1, sortDirection: DESC) {
            items {
              id
              createdAt
              updatedAt
              balance
              isAsset
              accountId
              dataProviderActionNeededStatus
              lastUpdatedOnProviderSide
            }
          }
        }
      }
      uncategorizedAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots(limit: 1, sortDirection: DESC) {
            items {
              id
              createdAt
              updatedAt
              balance
              isAsset
              accountId
              dataProviderActionNeededStatus
              lastUpdatedOnProviderSide
            }
          }
        }
      }
    }
  }
`;
