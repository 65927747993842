// This file translates member tasks into a Drawer[] shape for rendering.
import {
  getTasksFromContainer,
  processAllFinancialTasksFromGetMemberQuery,
  InputFinancialTaskTypesForGetMemberQuery,
  getBalanceFromLastSnapshot
} from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { isNonNullish, collapseProcessingResultsIntoArray } from 'global-utils'
import { accountNameWithBalanceFallback } from '../../../util/account-parsing'
import { DrawerWithNodes } from '../common/drawers/drawers'
import { DrawerTitle } from '../common/drawers/drawer-title'
import { TaskListScreenDrawerContent } from './task-list-screen-drawer-content'
import { getTaskTitle } from './content-construction/get-task-title'

type DrawerConverter<T> = (task: T | null) => DrawerWithNodes | null

export function getTaskDrawers (
  navigation: GenericCompositeNavigationProp,
  memberQuery?: WalkthroughGraphQL.GetMemberQuery | null | undefined
): DrawerWithNodes[] {
  const tasks = getTasksFromContainer(memberQuery?.getMember)
  const taskToDrawerHandlers = {
    payCriticalExpensesTask: payCriticalExpensesTaskToDrawer(navigation),
    buildOneMonthEmergencyFundTask: buildEmergencyFundTaskToDrawer(navigation),
    extendEmergencyFundToThreeMonthsTask:
      extendEmergencyFundToThreeMonthsTaskToDrawer(navigation),
    // The 6 month task is being de-registered for v0; see code change for decision pointers.
    extendEmergencyFundToSixMonthsTask: () => null, // extendEmergencyFundToSixMonthsTaskToDrawer,
    investInBrokerageAccountTask:
      investInBrokerageAccountTaskToDrawer(navigation),
    payOffDebtTasks: payOffDebtTaskToDrawer(navigation),
    contributeTo401k2022Tasks: () => null, // Deregistered
    contributeTo401kTasks: contributeTo401kTaskToDrawer(navigation),
    contributeToIra2022Task: () => null, // Deregistered
    contributeToIraTasks: contributeToIraTaskToDrawer(navigation)
  }

  return collapseProcessingResultsIntoArray(
    processAllFinancialTasksFromGetMemberQuery(tasks, taskToDrawerHandlers)
  ).filter(isNonNullish)
}

function payCriticalExpensesTaskToDrawer (
  navigation: GenericCompositeNavigationProp
): DrawerConverter<
  InputFinancialTaskTypesForGetMemberQuery['payCriticalExpensesTask']
  > {
  return (task) => {
    if (task === null) {
      return null
    }
    return {
      key: [task.__typename, task.owner].join(':'),
      title: DrawerTitle({
        title: getTaskTitle(task.__typename),
        isComplete: task.isComplete
      }),
      content: TaskListScreenDrawerContent({
        taskTypename: task.__typename,
        // Note that this task uses owner as its primary key because there is only one task per user.
        taskId: task.owner,
        text: 'Double check you are paying all basic expenses. Are you sure you know what they all are?',
        navigation
      })
    }
  }
}

function buildEmergencyFundTaskToDrawer (
  navigation: GenericCompositeNavigationProp
): DrawerConverter<
  InputFinancialTaskTypesForGetMemberQuery['buildOneMonthEmergencyFundTask']
  > {
  return (task) => {
    if (task === null) {
      return null
    }
    return {
      key: [task.__typename, task.owner].join(':'),
      title: DrawerTitle({
        title: getTaskTitle(task.__typename),
        isComplete: task.isComplete
      }),
      content: TaskListScreenDrawerContent({
        taskTypename: task.__typename,
        // Note that this task uses owner as its primary key because there is only one task per user.
        taskId: task.owner,
        text:
          'Why do you need an emergency fund anyway? Nothing ever bad happens, right?\n\nStart or designate ' +
          'your emergency fund.',
        navigation
      })
    }
  }
}

function extendEmergencyFundToThreeMonthsTaskToDrawer (
  navigation: GenericCompositeNavigationProp
): DrawerConverter<
  InputFinancialTaskTypesForGetMemberQuery['extendEmergencyFundToThreeMonthsTask']
  > {
  return (task) => {
    if (task === null) {
      return null
    }
    return {
      key: [task.__typename, task.owner].join(':'),
      title: DrawerTitle({
        title: getTaskTitle(task.__typename),
        isComplete: task.isComplete
      }),
      content: TaskListScreenDrawerContent({
        taskTypename: task.__typename,
        // Note that this task uses owner as its primary key because there is only one task per user.
        taskId: task.owner,
        text: 'Grow your emergency fund to three months of expenses.',
        navigation
      })
    }
  }
}

function investInBrokerageAccountTaskToDrawer (
  navigation: GenericCompositeNavigationProp
): DrawerConverter<
  InputFinancialTaskTypesForGetMemberQuery['investInBrokerageAccountTask']
  > {
  return (task) => {
    if (task === null) {
      return null
    }
    return {
      key: [task.__typename, task.owner].join(':'),
      title: DrawerTitle({
        title: getTaskTitle(task.__typename),
        isComplete: task.isComplete
      }),
      content: TaskListScreenDrawerContent({
        taskTypename: task.__typename,
        // Note that this task uses owner as its primary key because there is only one task per user.
        taskId: task.owner,
        text: 'Increase your savings rate and invest for your future.',
        navigation
      })
    }
  }
}

function contributeToIraTaskToDrawer (
  navigation: GenericCompositeNavigationProp
): DrawerConverter<
  InputFinancialTaskTypesForGetMemberQuery['contributeToIraTasks']
  > {
  return (task) => {
    if (task === null) {
      return null
    }
    return {
      key: [task.__typename, task.id].join(':'),
      title: DrawerTitle({
        title: getTaskTitle(task.__typename, undefined, task.contributionYear),
        isComplete: task.isComplete
      }),
      content: TaskListScreenDrawerContent({
        taskTypename: task.__typename,
        taskId: task.id,
        text: "It's raining taxes! Contribute to your IRA ☂",
        navigation
      })
    }
  }
}

function payOffDebtTaskToDrawer (
  navigation: GenericCompositeNavigationProp
): DrawerConverter<
  InputFinancialTaskTypesForGetMemberQuery['payOffDebtTasks']
  > {
  return (task) => {
    if (task === null) {
      return null
    }
    if (!isNonNullish(task.debtAccount)) {
      // TODO: We could theoretically delete these tasks with dangling
      // account references as we encounter them, or we could set up an
      // offline GC job to deal with them.  They're harmless but do clutter
      // the database.
      return null
    }
    const accountName = task.debtAccount.accountName
    const balance = getBalanceFromLastSnapshot(
      task.debtAccount,
      WalkthroughGraphQL.AssetType.LIABILITY
    )
    if (!isNonNullish(accountName) && !isNonNullish(balance)) {
      return null
    }
    return {
      key: [task.__typename, task.id].join(':'),
      title: DrawerTitle({
        title: getTaskTitle(
          task.__typename,
          undefined,
          undefined,
          accountNameWithBalanceFallback(task.debtAccount) ?? undefined
        ),
        isComplete: task.isComplete
      }),
      content: TaskListScreenDrawerContent({
        taskTypename: task.__typename,
        // Note that this task uses id as its primary key because there is one task per account
        // (potentially many tasks per user).
        taskId: task.id,
        text: 'Guaranteed, risk-free returns? A lil peace of mind? Yes pleeeease.',
        navigation
      })
    }
  }
}

function contributeTo401kTaskToDrawer (
  navigation: GenericCompositeNavigationProp
): DrawerConverter<
  InputFinancialTaskTypesForGetMemberQuery['contributeTo401kTasks']
  > {
  return (task) => {
    if (task === null) {
      return null
    }
    if (!isNonNullish(task.k401Account)) {
      // TODO: We could theoretically delete these tasks with dangling
      // account references as we encounter them, or we could set up an
      // offline GC job to deal with them.  They're harmless but do clutter
      // the database.
      return null
    }
    const accountName = task.k401Account.accountName
    const balance = getBalanceFromLastSnapshot(
      task.k401Account,
      WalkthroughGraphQL.AssetType.LIABILITY
    )
    if (!isNonNullish(accountName) && !isNonNullish(balance)) {
      return null
    }
    return {
      key: [task.__typename, task.id].join(':'),
      title: DrawerTitle({
        title: getTaskTitle(
          task.__typename,
          task.stepsCompletedAtLeastOnce ?? undefined,
          task.contributionYear,
          accountNameWithBalanceFallback(task.k401Account) ?? undefined
        ),
        isComplete: task.isComplete
      }),
      content: TaskListScreenDrawerContent({
        taskTypename: task.__typename,
        // Note that this task uses id as its primary key because there is one task per account
        // (potentially many tasks per user).
        taskId: task.id,
        text: "Free money and less taxes. What's not to like?",
        navigation
      })
    }
  }
}
