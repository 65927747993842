"use strict";
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateDebtAccountSnapshot = exports.createDebtAccountSnapshot = exports.deleteDebtAccount = exports.updateDebtAccount = exports.createDebtAccount = exports.deleteCheckingAccountSnapshot = exports.updateCheckingAccountSnapshot = exports.createCheckingAccountSnapshot = exports.deleteCheckingAccount = exports.updateCheckingAccount = exports.createCheckingAccount = exports.deleteSavingsAccountSnapshot = exports.updateSavingsAccountSnapshot = exports.createSavingsAccountSnapshot = exports.deleteSavingsAccount = exports.updateSavingsAccount = exports.createSavingsAccount = exports.deleteContributeToIraTask = exports.updateContributeToIraTask = exports.createContributeToIraTask = exports.deleteContributeToIra2022Task = exports.updateContributeToIra2022Task = exports.createContributeToIra2022Task = exports.deleteContributeTo401kTask = exports.updateContributeTo401kTask = exports.createContributeTo401kTask = exports.deleteContributeTo401k2022Task = exports.updateContributeTo401k2022Task = exports.createContributeTo401k2022Task = exports.deletePayOffDebtTask = exports.updatePayOffDebtTask = exports.createPayOffDebtTask = exports.deleteInvestInBrokerageAccountTask = exports.updateInvestInBrokerageAccountTask = exports.createInvestInBrokerageAccountTask = exports.deleteExtendEmergencyFundToSixMonthsTask = exports.updateExtendEmergencyFundToSixMonthsTask = exports.createExtendEmergencyFundToSixMonthsTask = exports.deleteExtendEmergencyFundToThreeMonthsTask = exports.updateExtendEmergencyFundToThreeMonthsTask = exports.createExtendEmergencyFundToThreeMonthsTask = exports.deleteBuildOneMonthEmergencyFundTask = exports.updateBuildOneMonthEmergencyFundTask = exports.createBuildOneMonthEmergencyFundTask = exports.deletePayCriticalExpensesTask = exports.updatePayCriticalExpensesTask = exports.createPayCriticalExpensesTask = exports.updateAiAdvisorRecord = exports.createAiAdvisorRecord = exports.createFeedbackEntry = void 0;
exports.purgeMxFinancialInstitutionUser = exports.purgeWalkthroughManualAccount = exports.purgeYodleeAccount = exports.adminImportAccountsFromMx = exports.importAccountsFromMx = exports.initializeMissingTasksForMember = exports.deleteMember = exports.updateMember = exports.createMember = exports.deleteUncategorizedAccountSnapshot = exports.updateUncategorizedAccountSnapshot = exports.createUncategorizedAccountSnapshot = exports.deleteUncategorizedAccount = exports.updateUncategorizedAccount = exports.createUncategorizedAccount = exports.deleteOtherInvestmentAccountSnapshot = exports.updateOtherInvestmentAccountSnapshot = exports.createOtherInvestmentAccountSnapshot = exports.deleteOtherInvestmentAccount = exports.updateOtherInvestmentAccount = exports.createOtherInvestmentAccount = exports.deleteRothIraAccountSnapshot = exports.updateRothIraAccountSnapshot = exports.createRothIraAccountSnapshot = exports.deleteRothIraAccount = exports.updateRothIraAccount = exports.createRothIraAccount = exports.deleteTraditionalIraAccountSnapshot = exports.updateTraditionalIraAccountSnapshot = exports.createTraditionalIraAccountSnapshot = exports.deleteTraditionalIraAccount = exports.updateTraditionalIraAccount = exports.createTraditionalIraAccount = exports.deleteK401AccountSnapshot = exports.updateK401AccountSnapshot = exports.createK401AccountSnapshot = exports.deleteK401Account = exports.updateK401Account = exports.createK401Account = exports.deleteDebtAccountSnapshot = void 0;
exports.createFeedbackEntry = `
  mutation CreateFeedbackEntry(
    $input: CreateFeedbackEntryInput!
    $condition: ModelFeedbackEntryConditionInput
  ) {
    createFeedbackEntry(input: $input, condition: $condition) {
      id
      email
      name
      phone
      feedback
      createdAt
      updatedAt
    }
  }
`;
exports.createAiAdvisorRecord = `
  mutation CreateAiAdvisorRecord(
    $input: CreateAiAdvisorRecordInput!
    $condition: ModelAiAdvisorRecordConditionInput
  ) {
    createAiAdvisorRecord(input: $input, condition: $condition) {
      id
      owner
      aiGeneratedResponse
      input
      response
      userFeedback
      createdAt
      updatedAt
    }
  }
`;
exports.updateAiAdvisorRecord = `
  mutation UpdateAiAdvisorRecord(
    $input: UpdateAiAdvisorRecordInput!
    $condition: ModelAiAdvisorRecordConditionInput
  ) {
    updateAiAdvisorRecord(input: $input, condition: $condition) {
      id
      owner
      aiGeneratedResponse
      input
      response
      userFeedback
      createdAt
      updatedAt
    }
  }
`;
exports.createPayCriticalExpensesTask = `
  mutation CreatePayCriticalExpensesTask(
    $input: CreatePayCriticalExpensesTaskInput!
    $condition: ModelPayCriticalExpensesTaskConditionInput
  ) {
    createPayCriticalExpensesTask(input: $input, condition: $condition) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.updatePayCriticalExpensesTask = `
  mutation UpdatePayCriticalExpensesTask(
    $input: UpdatePayCriticalExpensesTaskInput!
    $condition: ModelPayCriticalExpensesTaskConditionInput
  ) {
    updatePayCriticalExpensesTask(input: $input, condition: $condition) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.deletePayCriticalExpensesTask = `
  mutation DeletePayCriticalExpensesTask(
    $input: DeletePayCriticalExpensesTaskInput!
    $condition: ModelPayCriticalExpensesTaskConditionInput
  ) {
    deletePayCriticalExpensesTask(input: $input, condition: $condition) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.createBuildOneMonthEmergencyFundTask = `
  mutation CreateBuildOneMonthEmergencyFundTask(
    $input: CreateBuildOneMonthEmergencyFundTaskInput!
    $condition: ModelBuildOneMonthEmergencyFundTaskConditionInput
  ) {
    createBuildOneMonthEmergencyFundTask(input: $input, condition: $condition) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.updateBuildOneMonthEmergencyFundTask = `
  mutation UpdateBuildOneMonthEmergencyFundTask(
    $input: UpdateBuildOneMonthEmergencyFundTaskInput!
    $condition: ModelBuildOneMonthEmergencyFundTaskConditionInput
  ) {
    updateBuildOneMonthEmergencyFundTask(input: $input, condition: $condition) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.deleteBuildOneMonthEmergencyFundTask = `
  mutation DeleteBuildOneMonthEmergencyFundTask(
    $input: DeleteBuildOneMonthEmergencyFundTaskInput!
    $condition: ModelBuildOneMonthEmergencyFundTaskConditionInput
  ) {
    deleteBuildOneMonthEmergencyFundTask(input: $input, condition: $condition) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.createExtendEmergencyFundToThreeMonthsTask = `
  mutation CreateExtendEmergencyFundToThreeMonthsTask(
    $input: CreateExtendEmergencyFundToThreeMonthsTaskInput!
    $condition: ModelExtendEmergencyFundToThreeMonthsTaskConditionInput
  ) {
    createExtendEmergencyFundToThreeMonthsTask(
      input: $input
      condition: $condition
    ) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.updateExtendEmergencyFundToThreeMonthsTask = `
  mutation UpdateExtendEmergencyFundToThreeMonthsTask(
    $input: UpdateExtendEmergencyFundToThreeMonthsTaskInput!
    $condition: ModelExtendEmergencyFundToThreeMonthsTaskConditionInput
  ) {
    updateExtendEmergencyFundToThreeMonthsTask(
      input: $input
      condition: $condition
    ) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.deleteExtendEmergencyFundToThreeMonthsTask = `
  mutation DeleteExtendEmergencyFundToThreeMonthsTask(
    $input: DeleteExtendEmergencyFundToThreeMonthsTaskInput!
    $condition: ModelExtendEmergencyFundToThreeMonthsTaskConditionInput
  ) {
    deleteExtendEmergencyFundToThreeMonthsTask(
      input: $input
      condition: $condition
    ) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.createExtendEmergencyFundToSixMonthsTask = `
  mutation CreateExtendEmergencyFundToSixMonthsTask(
    $input: CreateExtendEmergencyFundToSixMonthsTaskInput!
    $condition: ModelExtendEmergencyFundToSixMonthsTaskConditionInput
  ) {
    createExtendEmergencyFundToSixMonthsTask(
      input: $input
      condition: $condition
    ) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.updateExtendEmergencyFundToSixMonthsTask = `
  mutation UpdateExtendEmergencyFundToSixMonthsTask(
    $input: UpdateExtendEmergencyFundToSixMonthsTaskInput!
    $condition: ModelExtendEmergencyFundToSixMonthsTaskConditionInput
  ) {
    updateExtendEmergencyFundToSixMonthsTask(
      input: $input
      condition: $condition
    ) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.deleteExtendEmergencyFundToSixMonthsTask = `
  mutation DeleteExtendEmergencyFundToSixMonthsTask(
    $input: DeleteExtendEmergencyFundToSixMonthsTaskInput!
    $condition: ModelExtendEmergencyFundToSixMonthsTaskConditionInput
  ) {
    deleteExtendEmergencyFundToSixMonthsTask(
      input: $input
      condition: $condition
    ) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.createInvestInBrokerageAccountTask = `
  mutation CreateInvestInBrokerageAccountTask(
    $input: CreateInvestInBrokerageAccountTaskInput!
    $condition: ModelInvestInBrokerageAccountTaskConditionInput
  ) {
    createInvestInBrokerageAccountTask(input: $input, condition: $condition) {
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.updateInvestInBrokerageAccountTask = `
  mutation UpdateInvestInBrokerageAccountTask(
    $input: UpdateInvestInBrokerageAccountTaskInput!
    $condition: ModelInvestInBrokerageAccountTaskConditionInput
  ) {
    updateInvestInBrokerageAccountTask(input: $input, condition: $condition) {
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.deleteInvestInBrokerageAccountTask = `
  mutation DeleteInvestInBrokerageAccountTask(
    $input: DeleteInvestInBrokerageAccountTaskInput!
    $condition: ModelInvestInBrokerageAccountTaskConditionInput
  ) {
    deleteInvestInBrokerageAccountTask(input: $input, condition: $condition) {
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.createPayOffDebtTask = `
  mutation CreatePayOffDebtTask(
    $input: CreatePayOffDebtTaskInput!
    $condition: ModelPayOffDebtTaskConditionInput
  ) {
    createPayOffDebtTask(input: $input, condition: $condition) {
      id
      owner
      isComplete
      subtaskCompletion
      debtAccountId
      debtAccount {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            interestAPY
            interestImprecise
            interestInactive
            id
            updatedAt
          }
          nextToken
        }
        owner
        memberDeclaredInterestAPY
        memberDeclaredInterestInactive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.updatePayOffDebtTask = `
  mutation UpdatePayOffDebtTask(
    $input: UpdatePayOffDebtTaskInput!
    $condition: ModelPayOffDebtTaskConditionInput
  ) {
    updatePayOffDebtTask(input: $input, condition: $condition) {
      id
      owner
      isComplete
      subtaskCompletion
      debtAccountId
      debtAccount {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            interestAPY
            interestImprecise
            interestInactive
            id
            updatedAt
          }
          nextToken
        }
        owner
        memberDeclaredInterestAPY
        memberDeclaredInterestInactive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.deletePayOffDebtTask = `
  mutation DeletePayOffDebtTask(
    $input: DeletePayOffDebtTaskInput!
    $condition: ModelPayOffDebtTaskConditionInput
  ) {
    deletePayOffDebtTask(input: $input, condition: $condition) {
      id
      owner
      isComplete
      subtaskCompletion
      debtAccountId
      debtAccount {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            interestAPY
            interestImprecise
            interestInactive
            id
            updatedAt
          }
          nextToken
        }
        owner
        memberDeclaredInterestAPY
        memberDeclaredInterestInactive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.createContributeTo401k2022Task = `
  mutation CreateContributeTo401k2022Task(
    $input: CreateContributeTo401k2022TaskInput!
    $condition: ModelContributeTo401k2022TaskConditionInput
  ) {
    createContributeTo401k2022Task(input: $input, condition: $condition) {
      id
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      k401AccountId
      k401Account {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.updateContributeTo401k2022Task = `
  mutation UpdateContributeTo401k2022Task(
    $input: UpdateContributeTo401k2022TaskInput!
    $condition: ModelContributeTo401k2022TaskConditionInput
  ) {
    updateContributeTo401k2022Task(input: $input, condition: $condition) {
      id
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      k401AccountId
      k401Account {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.deleteContributeTo401k2022Task = `
  mutation DeleteContributeTo401k2022Task(
    $input: DeleteContributeTo401k2022TaskInput!
    $condition: ModelContributeTo401k2022TaskConditionInput
  ) {
    deleteContributeTo401k2022Task(input: $input, condition: $condition) {
      id
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      k401AccountId
      k401Account {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.createContributeTo401kTask = `
  mutation CreateContributeTo401kTask(
    $input: CreateContributeTo401kTaskInput!
    $condition: ModelContributeTo401kTaskConditionInput
  ) {
    createContributeTo401kTask(input: $input, condition: $condition) {
      id
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      contributionYear
      k401AccountId
      k401Account {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.updateContributeTo401kTask = `
  mutation UpdateContributeTo401kTask(
    $input: UpdateContributeTo401kTaskInput!
    $condition: ModelContributeTo401kTaskConditionInput
  ) {
    updateContributeTo401kTask(input: $input, condition: $condition) {
      id
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      contributionYear
      k401AccountId
      k401Account {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.deleteContributeTo401kTask = `
  mutation DeleteContributeTo401kTask(
    $input: DeleteContributeTo401kTaskInput!
    $condition: ModelContributeTo401kTaskConditionInput
  ) {
    deleteContributeTo401kTask(input: $input, condition: $condition) {
      id
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      contributionYear
      k401AccountId
      k401Account {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.createContributeToIra2022Task = `
  mutation CreateContributeToIra2022Task(
    $input: CreateContributeToIra2022TaskInput!
    $condition: ModelContributeToIra2022TaskConditionInput
  ) {
    createContributeToIra2022Task(input: $input, condition: $condition) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.updateContributeToIra2022Task = `
  mutation UpdateContributeToIra2022Task(
    $input: UpdateContributeToIra2022TaskInput!
    $condition: ModelContributeToIra2022TaskConditionInput
  ) {
    updateContributeToIra2022Task(input: $input, condition: $condition) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.deleteContributeToIra2022Task = `
  mutation DeleteContributeToIra2022Task(
    $input: DeleteContributeToIra2022TaskInput!
    $condition: ModelContributeToIra2022TaskConditionInput
  ) {
    deleteContributeToIra2022Task(input: $input, condition: $condition) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.createContributeToIraTask = `
  mutation CreateContributeToIraTask(
    $input: CreateContributeToIraTaskInput!
    $condition: ModelContributeToIraTaskConditionInput
  ) {
    createContributeToIraTask(input: $input, condition: $condition) {
      id
      owner
      isComplete
      subtaskCompletion
      contributionYear
      createdAt
      updatedAt
    }
  }
`;
exports.updateContributeToIraTask = `
  mutation UpdateContributeToIraTask(
    $input: UpdateContributeToIraTaskInput!
    $condition: ModelContributeToIraTaskConditionInput
  ) {
    updateContributeToIraTask(input: $input, condition: $condition) {
      id
      owner
      isComplete
      subtaskCompletion
      contributionYear
      createdAt
      updatedAt
    }
  }
`;
exports.deleteContributeToIraTask = `
  mutation DeleteContributeToIraTask(
    $input: DeleteContributeToIraTaskInput!
    $condition: ModelContributeToIraTaskConditionInput
  ) {
    deleteContributeToIraTask(input: $input, condition: $condition) {
      id
      owner
      isComplete
      subtaskCompletion
      contributionYear
      createdAt
      updatedAt
    }
  }
`;
exports.createSavingsAccount = `
  mutation CreateSavingsAccount(
    $input: CreateSavingsAccountInput!
    $condition: ModelSavingsAccountConditionInput
  ) {
    createSavingsAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.updateSavingsAccount = `
  mutation UpdateSavingsAccount(
    $input: UpdateSavingsAccountInput!
    $condition: ModelSavingsAccountConditionInput
  ) {
    updateSavingsAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.deleteSavingsAccount = `
  mutation DeleteSavingsAccount(
    $input: DeleteSavingsAccountInput!
    $condition: ModelSavingsAccountConditionInput
  ) {
    deleteSavingsAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.createSavingsAccountSnapshot = `
  mutation CreateSavingsAccountSnapshot(
    $input: CreateSavingsAccountSnapshotInput!
    $condition: ModelSavingsAccountSnapshotConditionInput
  ) {
    createSavingsAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.updateSavingsAccountSnapshot = `
  mutation UpdateSavingsAccountSnapshot(
    $input: UpdateSavingsAccountSnapshotInput!
    $condition: ModelSavingsAccountSnapshotConditionInput
  ) {
    updateSavingsAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.deleteSavingsAccountSnapshot = `
  mutation DeleteSavingsAccountSnapshot(
    $input: DeleteSavingsAccountSnapshotInput!
    $condition: ModelSavingsAccountSnapshotConditionInput
  ) {
    deleteSavingsAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.createCheckingAccount = `
  mutation CreateCheckingAccount(
    $input: CreateCheckingAccountInput!
    $condition: ModelCheckingAccountConditionInput
  ) {
    createCheckingAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.updateCheckingAccount = `
  mutation UpdateCheckingAccount(
    $input: UpdateCheckingAccountInput!
    $condition: ModelCheckingAccountConditionInput
  ) {
    updateCheckingAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.deleteCheckingAccount = `
  mutation DeleteCheckingAccount(
    $input: DeleteCheckingAccountInput!
    $condition: ModelCheckingAccountConditionInput
  ) {
    deleteCheckingAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.createCheckingAccountSnapshot = `
  mutation CreateCheckingAccountSnapshot(
    $input: CreateCheckingAccountSnapshotInput!
    $condition: ModelCheckingAccountSnapshotConditionInput
  ) {
    createCheckingAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.updateCheckingAccountSnapshot = `
  mutation UpdateCheckingAccountSnapshot(
    $input: UpdateCheckingAccountSnapshotInput!
    $condition: ModelCheckingAccountSnapshotConditionInput
  ) {
    updateCheckingAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.deleteCheckingAccountSnapshot = `
  mutation DeleteCheckingAccountSnapshot(
    $input: DeleteCheckingAccountSnapshotInput!
    $condition: ModelCheckingAccountSnapshotConditionInput
  ) {
    deleteCheckingAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.createDebtAccount = `
  mutation CreateDebtAccount(
    $input: CreateDebtAccountInput!
    $condition: ModelDebtAccountConditionInput
  ) {
    createDebtAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          interestAPY
          interestImprecise
          interestInactive
          id
          updatedAt
        }
        nextToken
      }
      owner
      memberDeclaredInterestAPY
      memberDeclaredInterestInactive
      createdAt
      updatedAt
    }
  }
`;
exports.updateDebtAccount = `
  mutation UpdateDebtAccount(
    $input: UpdateDebtAccountInput!
    $condition: ModelDebtAccountConditionInput
  ) {
    updateDebtAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          interestAPY
          interestImprecise
          interestInactive
          id
          updatedAt
        }
        nextToken
      }
      owner
      memberDeclaredInterestAPY
      memberDeclaredInterestInactive
      createdAt
      updatedAt
    }
  }
`;
exports.deleteDebtAccount = `
  mutation DeleteDebtAccount(
    $input: DeleteDebtAccountInput!
    $condition: ModelDebtAccountConditionInput
  ) {
    deleteDebtAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          interestAPY
          interestImprecise
          interestInactive
          id
          updatedAt
        }
        nextToken
      }
      owner
      memberDeclaredInterestAPY
      memberDeclaredInterestInactive
      createdAt
      updatedAt
    }
  }
`;
exports.createDebtAccountSnapshot = `
  mutation CreateDebtAccountSnapshot(
    $input: CreateDebtAccountSnapshotInput!
    $condition: ModelDebtAccountSnapshotConditionInput
  ) {
    createDebtAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      interestAPY
      interestImprecise
      interestInactive
      id
      updatedAt
    }
  }
`;
exports.updateDebtAccountSnapshot = `
  mutation UpdateDebtAccountSnapshot(
    $input: UpdateDebtAccountSnapshotInput!
    $condition: ModelDebtAccountSnapshotConditionInput
  ) {
    updateDebtAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      interestAPY
      interestImprecise
      interestInactive
      id
      updatedAt
    }
  }
`;
exports.deleteDebtAccountSnapshot = `
  mutation DeleteDebtAccountSnapshot(
    $input: DeleteDebtAccountSnapshotInput!
    $condition: ModelDebtAccountSnapshotConditionInput
  ) {
    deleteDebtAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      interestAPY
      interestImprecise
      interestInactive
      id
      updatedAt
    }
  }
`;
exports.createK401Account = `
  mutation CreateK401Account(
    $input: CreateK401AccountInput!
    $condition: ModelK401AccountConditionInput
  ) {
    createK401Account(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      employerMatchRules {
        fractionMatch
        maxEmployerMatch
      }
      isEligibleForRoth401KContributions
      isEligibleForPreTaxTraditional401KContributions
      isEligibleForAfterTaxTraditional401KContributions
      createdAt
      updatedAt
    }
  }
`;
exports.updateK401Account = `
  mutation UpdateK401Account(
    $input: UpdateK401AccountInput!
    $condition: ModelK401AccountConditionInput
  ) {
    updateK401Account(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      employerMatchRules {
        fractionMatch
        maxEmployerMatch
      }
      isEligibleForRoth401KContributions
      isEligibleForPreTaxTraditional401KContributions
      isEligibleForAfterTaxTraditional401KContributions
      createdAt
      updatedAt
    }
  }
`;
exports.deleteK401Account = `
  mutation DeleteK401Account(
    $input: DeleteK401AccountInput!
    $condition: ModelK401AccountConditionInput
  ) {
    deleteK401Account(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      employerMatchRules {
        fractionMatch
        maxEmployerMatch
      }
      isEligibleForRoth401KContributions
      isEligibleForPreTaxTraditional401KContributions
      isEligibleForAfterTaxTraditional401KContributions
      createdAt
      updatedAt
    }
  }
`;
exports.createK401AccountSnapshot = `
  mutation CreateK401AccountSnapshot(
    $input: CreateK401AccountSnapshotInput!
    $condition: ModelK401AccountSnapshotConditionInput
  ) {
    createK401AccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.updateK401AccountSnapshot = `
  mutation UpdateK401AccountSnapshot(
    $input: UpdateK401AccountSnapshotInput!
    $condition: ModelK401AccountSnapshotConditionInput
  ) {
    updateK401AccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.deleteK401AccountSnapshot = `
  mutation DeleteK401AccountSnapshot(
    $input: DeleteK401AccountSnapshotInput!
    $condition: ModelK401AccountSnapshotConditionInput
  ) {
    deleteK401AccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.createTraditionalIraAccount = `
  mutation CreateTraditionalIraAccount(
    $input: CreateTraditionalIraAccountInput!
    $condition: ModelTraditionalIraAccountConditionInput
  ) {
    createTraditionalIraAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.updateTraditionalIraAccount = `
  mutation UpdateTraditionalIraAccount(
    $input: UpdateTraditionalIraAccountInput!
    $condition: ModelTraditionalIraAccountConditionInput
  ) {
    updateTraditionalIraAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.deleteTraditionalIraAccount = `
  mutation DeleteTraditionalIraAccount(
    $input: DeleteTraditionalIraAccountInput!
    $condition: ModelTraditionalIraAccountConditionInput
  ) {
    deleteTraditionalIraAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.createTraditionalIraAccountSnapshot = `
  mutation CreateTraditionalIraAccountSnapshot(
    $input: CreateTraditionalIraAccountSnapshotInput!
    $condition: ModelTraditionalIraAccountSnapshotConditionInput
  ) {
    createTraditionalIraAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.updateTraditionalIraAccountSnapshot = `
  mutation UpdateTraditionalIraAccountSnapshot(
    $input: UpdateTraditionalIraAccountSnapshotInput!
    $condition: ModelTraditionalIraAccountSnapshotConditionInput
  ) {
    updateTraditionalIraAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.deleteTraditionalIraAccountSnapshot = `
  mutation DeleteTraditionalIraAccountSnapshot(
    $input: DeleteTraditionalIraAccountSnapshotInput!
    $condition: ModelTraditionalIraAccountSnapshotConditionInput
  ) {
    deleteTraditionalIraAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.createRothIraAccount = `
  mutation CreateRothIraAccount(
    $input: CreateRothIraAccountInput!
    $condition: ModelRothIraAccountConditionInput
  ) {
    createRothIraAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.updateRothIraAccount = `
  mutation UpdateRothIraAccount(
    $input: UpdateRothIraAccountInput!
    $condition: ModelRothIraAccountConditionInput
  ) {
    updateRothIraAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.deleteRothIraAccount = `
  mutation DeleteRothIraAccount(
    $input: DeleteRothIraAccountInput!
    $condition: ModelRothIraAccountConditionInput
  ) {
    deleteRothIraAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.createRothIraAccountSnapshot = `
  mutation CreateRothIraAccountSnapshot(
    $input: CreateRothIraAccountSnapshotInput!
    $condition: ModelRothIraAccountSnapshotConditionInput
  ) {
    createRothIraAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.updateRothIraAccountSnapshot = `
  mutation UpdateRothIraAccountSnapshot(
    $input: UpdateRothIraAccountSnapshotInput!
    $condition: ModelRothIraAccountSnapshotConditionInput
  ) {
    updateRothIraAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.deleteRothIraAccountSnapshot = `
  mutation DeleteRothIraAccountSnapshot(
    $input: DeleteRothIraAccountSnapshotInput!
    $condition: ModelRothIraAccountSnapshotConditionInput
  ) {
    deleteRothIraAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.createOtherInvestmentAccount = `
  mutation CreateOtherInvestmentAccount(
    $input: CreateOtherInvestmentAccountInput!
    $condition: ModelOtherInvestmentAccountConditionInput
  ) {
    createOtherInvestmentAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      dataProviderAccountType
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.updateOtherInvestmentAccount = `
  mutation UpdateOtherInvestmentAccount(
    $input: UpdateOtherInvestmentAccountInput!
    $condition: ModelOtherInvestmentAccountConditionInput
  ) {
    updateOtherInvestmentAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      dataProviderAccountType
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.deleteOtherInvestmentAccount = `
  mutation DeleteOtherInvestmentAccount(
    $input: DeleteOtherInvestmentAccountInput!
    $condition: ModelOtherInvestmentAccountConditionInput
  ) {
    deleteOtherInvestmentAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      dataProviderAccountType
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.createOtherInvestmentAccountSnapshot = `
  mutation CreateOtherInvestmentAccountSnapshot(
    $input: CreateOtherInvestmentAccountSnapshotInput!
    $condition: ModelOtherInvestmentAccountSnapshotConditionInput
  ) {
    createOtherInvestmentAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.updateOtherInvestmentAccountSnapshot = `
  mutation UpdateOtherInvestmentAccountSnapshot(
    $input: UpdateOtherInvestmentAccountSnapshotInput!
    $condition: ModelOtherInvestmentAccountSnapshotConditionInput
  ) {
    updateOtherInvestmentAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.deleteOtherInvestmentAccountSnapshot = `
  mutation DeleteOtherInvestmentAccountSnapshot(
    $input: DeleteOtherInvestmentAccountSnapshotInput!
    $condition: ModelOtherInvestmentAccountSnapshotConditionInput
  ) {
    deleteOtherInvestmentAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.createUncategorizedAccount = `
  mutation CreateUncategorizedAccount(
    $input: CreateUncategorizedAccountInput!
    $condition: ModelUncategorizedAccountConditionInput
  ) {
    createUncategorizedAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      dataProviderAccountType
      createdAt
      updatedAt
    }
  }
`;
exports.updateUncategorizedAccount = `
  mutation UpdateUncategorizedAccount(
    $input: UpdateUncategorizedAccountInput!
    $condition: ModelUncategorizedAccountConditionInput
  ) {
    updateUncategorizedAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      dataProviderAccountType
      createdAt
      updatedAt
    }
  }
`;
exports.deleteUncategorizedAccount = `
  mutation DeleteUncategorizedAccount(
    $input: DeleteUncategorizedAccountInput!
    $condition: ModelUncategorizedAccountConditionInput
  ) {
    deleteUncategorizedAccount(input: $input, condition: $condition) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      dataProviderAccountType
      createdAt
      updatedAt
    }
  }
`;
exports.createUncategorizedAccountSnapshot = `
  mutation CreateUncategorizedAccountSnapshot(
    $input: CreateUncategorizedAccountSnapshotInput!
    $condition: ModelUncategorizedAccountSnapshotConditionInput
  ) {
    createUncategorizedAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.updateUncategorizedAccountSnapshot = `
  mutation UpdateUncategorizedAccountSnapshot(
    $input: UpdateUncategorizedAccountSnapshotInput!
    $condition: ModelUncategorizedAccountSnapshotConditionInput
  ) {
    updateUncategorizedAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.deleteUncategorizedAccountSnapshot = `
  mutation DeleteUncategorizedAccountSnapshot(
    $input: DeleteUncategorizedAccountSnapshotInput!
    $condition: ModelUncategorizedAccountSnapshotConditionInput
  ) {
    deleteUncategorizedAccountSnapshot(input: $input, condition: $condition) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.createMember = `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      owner
      yodleeLoginName
      mxUserGuid
      payCriticalExpensesTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      buildOneMonthEmergencyFundTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      extendEmergencyFundToThreeMonthsTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      extendEmergencyFundToSixMonthsTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      investInBrokerageAccountTask {
        owner
        isComplete
        stepsCompletedAtLeastOnce
        subtaskCompletion
        createdAt
        updatedAt
      }
      payOffDebtTasks {
        items {
          id
          owner
          isComplete
          subtaskCompletion
          debtAccountId
          debtAccount {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                interestAPY
                interestImprecise
                interestInactive
                id
                updatedAt
              }
              nextToken
            }
            owner
            memberDeclaredInterestAPY
            memberDeclaredInterestInactive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeTo401k2022Tasks {
        items {
          id
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          k401AccountId
          k401Account {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeTo401kTasks {
        items {
          id
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          contributionYear
          k401AccountId
          k401Account {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeToIra2022Task {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      contributeToIraTasks {
        items {
          id
          owner
          isComplete
          subtaskCompletion
          contributionYear
          createdAt
          updatedAt
        }
        nextToken
      }
      activeTask {
        taskTypename
        taskPrimaryKey
      }
      activeTaskAbsoluteDollarsToAllocate
      activeTaskWasRecommendedByWalkthrough
      savingsAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      checkingAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      debtAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              interestAPY
              interestImprecise
              interestInactive
              id
              updatedAt
            }
            nextToken
          }
          owner
          memberDeclaredInterestAPY
          memberDeclaredInterestInactive
          createdAt
          updatedAt
        }
        nextToken
      }
      k401Accounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          employerMatchRules {
            fractionMatch
            maxEmployerMatch
          }
          isEligibleForRoth401KContributions
          isEligibleForPreTaxTraditional401KContributions
          isEligibleForAfterTaxTraditional401KContributions
          createdAt
          updatedAt
        }
        nextToken
      }
      traditionalIraAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rothIraAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      otherInvestmentAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          dataProviderAccountType
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      uncategorizedAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          dataProviderAccountType
          createdAt
          updatedAt
        }
        nextToken
      }
      onboardingComplete
      email
      phone
      state
      zipCode
      birthday
      spouseBirthday
      employmentStatus
      estimatedTaxableIncome
      estimatedAverageMonthlySpending
      taxFilingStatus
      estimatedAverageMonthlyAfterTaxIncome
      lastReceivedSavingsRatePoints
      points
      isAbleToPayBasicExpensesMemberDeclared
      hasOneMonthEmergencyFundMemberDeclared
      promoCode
      lastLogin
      createdAt
      updatedAt
    }
  }
`;
exports.updateMember = `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      owner
      yodleeLoginName
      mxUserGuid
      payCriticalExpensesTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      buildOneMonthEmergencyFundTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      extendEmergencyFundToThreeMonthsTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      extendEmergencyFundToSixMonthsTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      investInBrokerageAccountTask {
        owner
        isComplete
        stepsCompletedAtLeastOnce
        subtaskCompletion
        createdAt
        updatedAt
      }
      payOffDebtTasks {
        items {
          id
          owner
          isComplete
          subtaskCompletion
          debtAccountId
          debtAccount {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                interestAPY
                interestImprecise
                interestInactive
                id
                updatedAt
              }
              nextToken
            }
            owner
            memberDeclaredInterestAPY
            memberDeclaredInterestInactive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeTo401k2022Tasks {
        items {
          id
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          k401AccountId
          k401Account {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeTo401kTasks {
        items {
          id
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          contributionYear
          k401AccountId
          k401Account {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeToIra2022Task {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      contributeToIraTasks {
        items {
          id
          owner
          isComplete
          subtaskCompletion
          contributionYear
          createdAt
          updatedAt
        }
        nextToken
      }
      activeTask {
        taskTypename
        taskPrimaryKey
      }
      activeTaskAbsoluteDollarsToAllocate
      activeTaskWasRecommendedByWalkthrough
      savingsAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      checkingAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      debtAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              interestAPY
              interestImprecise
              interestInactive
              id
              updatedAt
            }
            nextToken
          }
          owner
          memberDeclaredInterestAPY
          memberDeclaredInterestInactive
          createdAt
          updatedAt
        }
        nextToken
      }
      k401Accounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          employerMatchRules {
            fractionMatch
            maxEmployerMatch
          }
          isEligibleForRoth401KContributions
          isEligibleForPreTaxTraditional401KContributions
          isEligibleForAfterTaxTraditional401KContributions
          createdAt
          updatedAt
        }
        nextToken
      }
      traditionalIraAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rothIraAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      otherInvestmentAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          dataProviderAccountType
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      uncategorizedAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          dataProviderAccountType
          createdAt
          updatedAt
        }
        nextToken
      }
      onboardingComplete
      email
      phone
      state
      zipCode
      birthday
      spouseBirthday
      employmentStatus
      estimatedTaxableIncome
      estimatedAverageMonthlySpending
      taxFilingStatus
      estimatedAverageMonthlyAfterTaxIncome
      lastReceivedSavingsRatePoints
      points
      isAbleToPayBasicExpensesMemberDeclared
      hasOneMonthEmergencyFundMemberDeclared
      promoCode
      lastLogin
      createdAt
      updatedAt
    }
  }
`;
exports.deleteMember = `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      owner
      yodleeLoginName
      mxUserGuid
      payCriticalExpensesTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      buildOneMonthEmergencyFundTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      extendEmergencyFundToThreeMonthsTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      extendEmergencyFundToSixMonthsTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      investInBrokerageAccountTask {
        owner
        isComplete
        stepsCompletedAtLeastOnce
        subtaskCompletion
        createdAt
        updatedAt
      }
      payOffDebtTasks {
        items {
          id
          owner
          isComplete
          subtaskCompletion
          debtAccountId
          debtAccount {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                interestAPY
                interestImprecise
                interestInactive
                id
                updatedAt
              }
              nextToken
            }
            owner
            memberDeclaredInterestAPY
            memberDeclaredInterestInactive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeTo401k2022Tasks {
        items {
          id
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          k401AccountId
          k401Account {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeTo401kTasks {
        items {
          id
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          contributionYear
          k401AccountId
          k401Account {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeToIra2022Task {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      contributeToIraTasks {
        items {
          id
          owner
          isComplete
          subtaskCompletion
          contributionYear
          createdAt
          updatedAt
        }
        nextToken
      }
      activeTask {
        taskTypename
        taskPrimaryKey
      }
      activeTaskAbsoluteDollarsToAllocate
      activeTaskWasRecommendedByWalkthrough
      savingsAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      checkingAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      debtAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              interestAPY
              interestImprecise
              interestInactive
              id
              updatedAt
            }
            nextToken
          }
          owner
          memberDeclaredInterestAPY
          memberDeclaredInterestInactive
          createdAt
          updatedAt
        }
        nextToken
      }
      k401Accounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          employerMatchRules {
            fractionMatch
            maxEmployerMatch
          }
          isEligibleForRoth401KContributions
          isEligibleForPreTaxTraditional401KContributions
          isEligibleForAfterTaxTraditional401KContributions
          createdAt
          updatedAt
        }
        nextToken
      }
      traditionalIraAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rothIraAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      otherInvestmentAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          dataProviderAccountType
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      uncategorizedAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          dataProviderAccountType
          createdAt
          updatedAt
        }
        nextToken
      }
      onboardingComplete
      email
      phone
      state
      zipCode
      birthday
      spouseBirthday
      employmentStatus
      estimatedTaxableIncome
      estimatedAverageMonthlySpending
      taxFilingStatus
      estimatedAverageMonthlyAfterTaxIncome
      lastReceivedSavingsRatePoints
      points
      isAbleToPayBasicExpensesMemberDeclared
      hasOneMonthEmergencyFundMemberDeclared
      promoCode
      lastLogin
      createdAt
      updatedAt
    }
  }
`;
exports.initializeMissingTasksForMember = `
  mutation InitializeMissingTasksForMember {
    initializeMissingTasksForMember
  }
`;
exports.importAccountsFromMx = `
  mutation ImportAccountsFromMx {
    importAccountsFromMx
  }
`;
exports.adminImportAccountsFromMx = `
  mutation AdminImportAccountsFromMx($mxUserGuid: String!) {
    adminImportAccountsFromMx(mxUserGuid: $mxUserGuid)
  }
`;
exports.purgeYodleeAccount = `
  mutation PurgeYodleeAccount($accountKey: AccountKeyPair!) {
    purgeYodleeAccount(accountKey: $accountKey)
  }
`;
exports.purgeWalkthroughManualAccount = `
  mutation PurgeWalkthroughManualAccount($accountKey: AccountKeyPair!) {
    purgeWalkthroughManualAccount(accountKey: $accountKey)
  }
`;
exports.purgeMxFinancialInstitutionUser = `
  mutation PurgeMxFinancialInstitutionUser(
    $dataProviderIdForFinancialInstitutionUser: String!
  ) {
    purgeMxFinancialInstitutionUser(
      dataProviderIdForFinancialInstitutionUser: $dataProviderIdForFinancialInstitutionUser
    )
  }
`;
