export enum AccountType {
  SAVINGS = 'SAVINGS',
  CHECKING = 'CHECKING',
  DEBT = 'DEBT',
  K401_ACCOUNT = 'K401_ACCOUNT',
  TRADITIONAL_IRA = 'TRADITIONAL_IRA',
  ROTH_IRA = 'ROTH_IRA',
  OTHER_INVESTMENT = 'OTHER_INVESTMENT'
}

export const accountTypeToDisplayValues = new Map<AccountType, string>([
  [AccountType.SAVINGS, 'Savings Account'],
  [AccountType.CHECKING, 'Checking Account'],
  [AccountType.DEBT, 'Debt (Credit Card, Loan, etc.)'],
  [AccountType.K401_ACCOUNT, '401(k)'],
  [AccountType.TRADITIONAL_IRA, 'Traditional IRA'],
  [AccountType.ROTH_IRA, 'Roth IRA'],
  [AccountType.OTHER_INVESTMENT, 'Brokerage / Other Investment Account']
])
