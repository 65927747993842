"use strict";
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
Object.defineProperty(exports, "__esModule", { value: true });
exports.getK401AccountSnapshot = exports.k401AccountsByOwner = exports.k401AccountsByDataProviderAccountId = exports.listK401Accounts = exports.getK401Account = exports.debtAccountSnapshotsByAccount = exports.listDebtAccountSnapshots = exports.getDebtAccountSnapshot = exports.debtAccountsByOwner = exports.debtAccountsByDataProviderAccountId = exports.listDebtAccounts = exports.getDebtAccount = exports.checkingAccountSnapshotsByAccount = exports.listCheckingAccountSnapshots = exports.getCheckingAccountSnapshot = exports.checkingAccountsByOwner = exports.checkingAccountsByDataProviderAccountId = exports.listCheckingAccounts = exports.getCheckingAccount = exports.savingsAccountSnapshotsByAccount = exports.listSavingsAccountSnapshots = exports.getSavingsAccountSnapshot = exports.savingsAccountsByOwner = exports.savingsAccountsByDataProviderAccountId = exports.listSavingsAccounts = exports.getSavingsAccount = exports.contributeToIraTasksByOwner = exports.listContributeToIraTasks = exports.getContributeToIraTask = exports.listContributeToIra2022Tasks = exports.getContributeToIra2022Task = exports.contributeTo401kTasksByOwner = exports.listContributeTo401kTasks = exports.getContributeTo401kTask = exports.contributeTo401k2022TasksByOwner = exports.listContributeTo401k2022Tasks = exports.getContributeTo401k2022Task = exports.payOffDebtTaskByOwner = exports.listPayOffDebtTasks = exports.getPayOffDebtTask = exports.listInvestInBrokerageAccountTasks = exports.getInvestInBrokerageAccountTask = exports.listExtendEmergencyFundToSixMonthsTasks = exports.getExtendEmergencyFundToSixMonthsTask = exports.listExtendEmergencyFundToThreeMonthsTasks = exports.getExtendEmergencyFundToThreeMonthsTask = exports.listBuildOneMonthEmergencyFundTasks = exports.getBuildOneMonthEmergencyFundTask = exports.listPayCriticalExpensesTasks = exports.getPayCriticalExpensesTask = void 0;
exports.queryAiAdvisor = exports.dollarsToAddToAccountForTask = exports.runGoalEngineForMember = exports.getMxConnectWidgetUrl = exports.memberByMxUserGuid = exports.memberByYodleeLoginName = exports.listMembers = exports.getMember = exports.uncategorizedAccountSnapshotsByAccount = exports.listUncategorizedAccountSnapshots = exports.getUncategorizedAccountSnapshot = exports.uncategorizedAccountsByOwner = exports.uncategorizedAccountsByDataProviderAccountId = exports.listUncategorizedAccounts = exports.getUncategorizedAccount = exports.otherInvestmentAccountSnapshotsByAccount = exports.listOtherInvestmentAccountSnapshots = exports.getOtherInvestmentAccountSnapshot = exports.otherInvestmentAccountsByOwner = exports.otherInvestmentAccountsByDataProviderAccountId = exports.listOtherInvestmentAccounts = exports.getOtherInvestmentAccount = exports.rothIraAccountSnapshotsByAccount = exports.listRothIraAccountSnapshots = exports.getRothIraAccountSnapshot = exports.rothIraAccountsByOwner = exports.rothIraAccountsByDataProviderAccountId = exports.listRothIraAccounts = exports.getRothIraAccount = exports.traditionalIraAccountSnapshotsByAccount = exports.listTraditionalIraAccountSnapshots = exports.getTraditionalIraAccountSnapshot = exports.traditionalIraAccountsByOwner = exports.traditionalIraAccountsByDataProviderAccountId = exports.listTraditionalIraAccounts = exports.getTraditionalIraAccount = exports.k401AccountSnapshotsByAccount = exports.listK401AccountSnapshots = void 0;
exports.getPayCriticalExpensesTask = `
  query GetPayCriticalExpensesTask($owner: String!) {
    getPayCriticalExpensesTask(owner: $owner) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.listPayCriticalExpensesTasks = `
  query ListPayCriticalExpensesTasks(
    $owner: String
    $filter: ModelPayCriticalExpensesTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPayCriticalExpensesTasks(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getBuildOneMonthEmergencyFundTask = `
  query GetBuildOneMonthEmergencyFundTask($owner: String!) {
    getBuildOneMonthEmergencyFundTask(owner: $owner) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.listBuildOneMonthEmergencyFundTasks = `
  query ListBuildOneMonthEmergencyFundTasks(
    $owner: String
    $filter: ModelBuildOneMonthEmergencyFundTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBuildOneMonthEmergencyFundTasks(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getExtendEmergencyFundToThreeMonthsTask = `
  query GetExtendEmergencyFundToThreeMonthsTask($owner: String!) {
    getExtendEmergencyFundToThreeMonthsTask(owner: $owner) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.listExtendEmergencyFundToThreeMonthsTasks = `
  query ListExtendEmergencyFundToThreeMonthsTasks(
    $owner: String
    $filter: ModelExtendEmergencyFundToThreeMonthsTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExtendEmergencyFundToThreeMonthsTasks(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getExtendEmergencyFundToSixMonthsTask = `
  query GetExtendEmergencyFundToSixMonthsTask($owner: String!) {
    getExtendEmergencyFundToSixMonthsTask(owner: $owner) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.listExtendEmergencyFundToSixMonthsTasks = `
  query ListExtendEmergencyFundToSixMonthsTasks(
    $owner: String
    $filter: ModelExtendEmergencyFundToSixMonthsTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExtendEmergencyFundToSixMonthsTasks(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getInvestInBrokerageAccountTask = `
  query GetInvestInBrokerageAccountTask($owner: String!) {
    getInvestInBrokerageAccountTask(owner: $owner) {
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.listInvestInBrokerageAccountTasks = `
  query ListInvestInBrokerageAccountTasks(
    $owner: String
    $filter: ModelInvestInBrokerageAccountTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvestInBrokerageAccountTasks(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        isComplete
        stepsCompletedAtLeastOnce
        subtaskCompletion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getPayOffDebtTask = `
  query GetPayOffDebtTask($id: ID!) {
    getPayOffDebtTask(id: $id) {
      id
      owner
      isComplete
      subtaskCompletion
      debtAccountId
      debtAccount {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            interestAPY
            interestImprecise
            interestInactive
            id
            updatedAt
          }
          nextToken
        }
        owner
        memberDeclaredInterestAPY
        memberDeclaredInterestInactive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.listPayOffDebtTasks = `
  query ListPayOffDebtTasks(
    $id: ID
    $filter: ModelPayOffDebtTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPayOffDebtTasks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        isComplete
        subtaskCompletion
        debtAccountId
        debtAccount {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              interestAPY
              interestImprecise
              interestInactive
              id
              updatedAt
            }
            nextToken
          }
          owner
          memberDeclaredInterestAPY
          memberDeclaredInterestInactive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.payOffDebtTaskByOwner = `
  query PayOffDebtTaskByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPayOffDebtTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payOffDebtTaskByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        isComplete
        subtaskCompletion
        debtAccountId
        debtAccount {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              interestAPY
              interestImprecise
              interestInactive
              id
              updatedAt
            }
            nextToken
          }
          owner
          memberDeclaredInterestAPY
          memberDeclaredInterestInactive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getContributeTo401k2022Task = `
  query GetContributeTo401k2022Task($id: ID!) {
    getContributeTo401k2022Task(id: $id) {
      id
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      k401AccountId
      k401Account {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.listContributeTo401k2022Tasks = `
  query ListContributeTo401k2022Tasks(
    $id: ID
    $filter: ModelContributeTo401k2022TaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContributeTo401k2022Tasks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        isComplete
        stepsCompletedAtLeastOnce
        subtaskCompletion
        k401AccountId
        k401Account {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          employerMatchRules {
            fractionMatch
            maxEmployerMatch
          }
          isEligibleForRoth401KContributions
          isEligibleForPreTaxTraditional401KContributions
          isEligibleForAfterTaxTraditional401KContributions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.contributeTo401k2022TasksByOwner = `
  query ContributeTo401k2022TasksByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelContributeTo401k2022TaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contributeTo401k2022TasksByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        isComplete
        stepsCompletedAtLeastOnce
        subtaskCompletion
        k401AccountId
        k401Account {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          employerMatchRules {
            fractionMatch
            maxEmployerMatch
          }
          isEligibleForRoth401KContributions
          isEligibleForPreTaxTraditional401KContributions
          isEligibleForAfterTaxTraditional401KContributions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getContributeTo401kTask = `
  query GetContributeTo401kTask($id: ID!) {
    getContributeTo401kTask(id: $id) {
      id
      owner
      isComplete
      stepsCompletedAtLeastOnce
      subtaskCompletion
      contributionYear
      k401AccountId
      k401Account {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
exports.listContributeTo401kTasks = `
  query ListContributeTo401kTasks(
    $id: ID
    $filter: ModelContributeTo401kTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContributeTo401kTasks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        isComplete
        stepsCompletedAtLeastOnce
        subtaskCompletion
        contributionYear
        k401AccountId
        k401Account {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          employerMatchRules {
            fractionMatch
            maxEmployerMatch
          }
          isEligibleForRoth401KContributions
          isEligibleForPreTaxTraditional401KContributions
          isEligibleForAfterTaxTraditional401KContributions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.contributeTo401kTasksByOwner = `
  query ContributeTo401kTasksByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelContributeTo401kTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contributeTo401kTasksByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        isComplete
        stepsCompletedAtLeastOnce
        subtaskCompletion
        contributionYear
        k401AccountId
        k401Account {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          employerMatchRules {
            fractionMatch
            maxEmployerMatch
          }
          isEligibleForRoth401KContributions
          isEligibleForPreTaxTraditional401KContributions
          isEligibleForAfterTaxTraditional401KContributions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getContributeToIra2022Task = `
  query GetContributeToIra2022Task($owner: String!) {
    getContributeToIra2022Task(owner: $owner) {
      owner
      isComplete
      subtaskCompletion
      createdAt
      updatedAt
    }
  }
`;
exports.listContributeToIra2022Tasks = `
  query ListContributeToIra2022Tasks(
    $owner: String
    $filter: ModelContributeToIra2022TaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContributeToIra2022Tasks(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getContributeToIraTask = `
  query GetContributeToIraTask($id: ID!) {
    getContributeToIraTask(id: $id) {
      id
      owner
      isComplete
      subtaskCompletion
      contributionYear
      createdAt
      updatedAt
    }
  }
`;
exports.listContributeToIraTasks = `
  query ListContributeToIraTasks(
    $id: ID
    $filter: ModelContributeToIraTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContributeToIraTasks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        isComplete
        subtaskCompletion
        contributionYear
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.contributeToIraTasksByOwner = `
  query ContributeToIraTasksByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelContributeToIraTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contributeToIraTasksByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        isComplete
        subtaskCompletion
        contributionYear
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getSavingsAccount = `
  query GetSavingsAccount($accountId: ID!) {
    getSavingsAccount(accountId: $accountId) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.listSavingsAccounts = `
  query ListSavingsAccounts(
    $accountId: ID
    $filter: ModelSavingsAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSavingsAccounts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.savingsAccountsByDataProviderAccountId = `
  query SavingsAccountsByDataProviderAccountId(
    $dataProviderAccountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSavingsAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    savingsAccountsByDataProviderAccountId(
      dataProviderAccountId: $dataProviderAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.savingsAccountsByOwner = `
  query SavingsAccountsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSavingsAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    savingsAccountsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getSavingsAccountSnapshot = `
  query GetSavingsAccountSnapshot($id: ID!) {
    getSavingsAccountSnapshot(id: $id) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.listSavingsAccountSnapshots = `
  query ListSavingsAccountSnapshots(
    $filter: ModelSavingsAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavingsAccountSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.savingsAccountSnapshotsByAccount = `
  query SavingsAccountSnapshotsByAccount(
    $accountId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSavingsAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    savingsAccountSnapshotsByAccount(
      accountId: $accountId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getCheckingAccount = `
  query GetCheckingAccount($accountId: ID!) {
    getCheckingAccount(accountId: $accountId) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.listCheckingAccounts = `
  query ListCheckingAccounts(
    $accountId: ID
    $filter: ModelCheckingAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCheckingAccounts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.checkingAccountsByDataProviderAccountId = `
  query CheckingAccountsByDataProviderAccountId(
    $dataProviderAccountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckingAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkingAccountsByDataProviderAccountId(
      dataProviderAccountId: $dataProviderAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.checkingAccountsByOwner = `
  query CheckingAccountsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckingAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkingAccountsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getCheckingAccountSnapshot = `
  query GetCheckingAccountSnapshot($id: ID!) {
    getCheckingAccountSnapshot(id: $id) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.listCheckingAccountSnapshots = `
  query ListCheckingAccountSnapshots(
    $filter: ModelCheckingAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckingAccountSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.checkingAccountSnapshotsByAccount = `
  query CheckingAccountSnapshotsByAccount(
    $accountId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCheckingAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkingAccountSnapshotsByAccount(
      accountId: $accountId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getDebtAccount = `
  query GetDebtAccount($accountId: ID!) {
    getDebtAccount(accountId: $accountId) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          interestAPY
          interestImprecise
          interestInactive
          id
          updatedAt
        }
        nextToken
      }
      owner
      memberDeclaredInterestAPY
      memberDeclaredInterestInactive
      createdAt
      updatedAt
    }
  }
`;
exports.listDebtAccounts = `
  query ListDebtAccounts(
    $accountId: ID
    $filter: ModelDebtAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDebtAccounts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            interestAPY
            interestImprecise
            interestInactive
            id
            updatedAt
          }
          nextToken
        }
        owner
        memberDeclaredInterestAPY
        memberDeclaredInterestInactive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.debtAccountsByDataProviderAccountId = `
  query DebtAccountsByDataProviderAccountId(
    $dataProviderAccountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDebtAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    debtAccountsByDataProviderAccountId(
      dataProviderAccountId: $dataProviderAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            interestAPY
            interestImprecise
            interestInactive
            id
            updatedAt
          }
          nextToken
        }
        owner
        memberDeclaredInterestAPY
        memberDeclaredInterestInactive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.debtAccountsByOwner = `
  query DebtAccountsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDebtAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    debtAccountsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            interestAPY
            interestImprecise
            interestInactive
            id
            updatedAt
          }
          nextToken
        }
        owner
        memberDeclaredInterestAPY
        memberDeclaredInterestInactive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getDebtAccountSnapshot = `
  query GetDebtAccountSnapshot($id: ID!) {
    getDebtAccountSnapshot(id: $id) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      interestAPY
      interestImprecise
      interestInactive
      id
      updatedAt
    }
  }
`;
exports.listDebtAccountSnapshots = `
  query ListDebtAccountSnapshots(
    $filter: ModelDebtAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDebtAccountSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        interestAPY
        interestImprecise
        interestInactive
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.debtAccountSnapshotsByAccount = `
  query DebtAccountSnapshotsByAccount(
    $accountId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDebtAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    debtAccountSnapshotsByAccount(
      accountId: $accountId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        interestAPY
        interestImprecise
        interestInactive
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getK401Account = `
  query GetK401Account($accountId: ID!) {
    getK401Account(accountId: $accountId) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      employerMatchRules {
        fractionMatch
        maxEmployerMatch
      }
      isEligibleForRoth401KContributions
      isEligibleForPreTaxTraditional401KContributions
      isEligibleForAfterTaxTraditional401KContributions
      createdAt
      updatedAt
    }
  }
`;
exports.listK401Accounts = `
  query ListK401Accounts(
    $accountId: ID
    $filter: ModelK401AccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listK401Accounts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.k401AccountsByDataProviderAccountId = `
  query K401AccountsByDataProviderAccountId(
    $dataProviderAccountId: ID!
    $sortDirection: ModelSortDirection
    $filter: Modelk401AccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    k401AccountsByDataProviderAccountId(
      dataProviderAccountId: $dataProviderAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.k401AccountsByOwner = `
  query K401AccountsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: Modelk401AccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    k401AccountsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        employerMatchRules {
          fractionMatch
          maxEmployerMatch
        }
        isEligibleForRoth401KContributions
        isEligibleForPreTaxTraditional401KContributions
        isEligibleForAfterTaxTraditional401KContributions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getK401AccountSnapshot = `
  query GetK401AccountSnapshot($id: ID!) {
    getK401AccountSnapshot(id: $id) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.listK401AccountSnapshots = `
  query ListK401AccountSnapshots(
    $filter: ModelK401AccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listK401AccountSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.k401AccountSnapshotsByAccount = `
  query K401AccountSnapshotsByAccount(
    $accountId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: Modelk401AccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    k401AccountSnapshotsByAccount(
      accountId: $accountId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getTraditionalIraAccount = `
  query GetTraditionalIraAccount($accountId: ID!) {
    getTraditionalIraAccount(accountId: $accountId) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.listTraditionalIraAccounts = `
  query ListTraditionalIraAccounts(
    $accountId: ID
    $filter: ModelTraditionalIraAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTraditionalIraAccounts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.traditionalIraAccountsByDataProviderAccountId = `
  query TraditionalIraAccountsByDataProviderAccountId(
    $dataProviderAccountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTraditionalIraAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traditionalIraAccountsByDataProviderAccountId(
      dataProviderAccountId: $dataProviderAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.traditionalIraAccountsByOwner = `
  query TraditionalIraAccountsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTraditionalIraAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traditionalIraAccountsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getTraditionalIraAccountSnapshot = `
  query GetTraditionalIraAccountSnapshot($id: ID!) {
    getTraditionalIraAccountSnapshot(id: $id) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.listTraditionalIraAccountSnapshots = `
  query ListTraditionalIraAccountSnapshots(
    $filter: ModelTraditionalIraAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTraditionalIraAccountSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.traditionalIraAccountSnapshotsByAccount = `
  query TraditionalIraAccountSnapshotsByAccount(
    $accountId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTraditionalIraAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traditionalIraAccountSnapshotsByAccount(
      accountId: $accountId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getRothIraAccount = `
  query GetRothIraAccount($accountId: ID!) {
    getRothIraAccount(accountId: $accountId) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      transactionAggregations {
        type
        amount
      }
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.listRothIraAccounts = `
  query ListRothIraAccounts(
    $accountId: ID
    $filter: ModelRothIraAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRothIraAccounts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.rothIraAccountsByDataProviderAccountId = `
  query RothIraAccountsByDataProviderAccountId(
    $dataProviderAccountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRothIraAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rothIraAccountsByDataProviderAccountId(
      dataProviderAccountId: $dataProviderAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.rothIraAccountsByOwner = `
  query RothIraAccountsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRothIraAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rothIraAccountsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        transactionAggregations {
          type
          amount
        }
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getRothIraAccountSnapshot = `
  query GetRothIraAccountSnapshot($id: ID!) {
    getRothIraAccountSnapshot(id: $id) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.listRothIraAccountSnapshots = `
  query ListRothIraAccountSnapshots(
    $filter: ModelRothIraAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRothIraAccountSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.rothIraAccountSnapshotsByAccount = `
  query RothIraAccountSnapshotsByAccount(
    $accountId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRothIraAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rothIraAccountSnapshotsByAccount(
      accountId: $accountId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getOtherInvestmentAccount = `
  query GetOtherInvestmentAccount($accountId: ID!) {
    getOtherInvestmentAccount(accountId: $accountId) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      dataProviderAccountType
      earmarks {
        type
        amount
      }
      createdAt
      updatedAt
    }
  }
`;
exports.listOtherInvestmentAccounts = `
  query ListOtherInvestmentAccounts(
    $accountId: ID
    $filter: ModelOtherInvestmentAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOtherInvestmentAccounts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        dataProviderAccountType
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.otherInvestmentAccountsByDataProviderAccountId = `
  query OtherInvestmentAccountsByDataProviderAccountId(
    $dataProviderAccountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOtherInvestmentAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    otherInvestmentAccountsByDataProviderAccountId(
      dataProviderAccountId: $dataProviderAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        dataProviderAccountType
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.otherInvestmentAccountsByOwner = `
  query OtherInvestmentAccountsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOtherInvestmentAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    otherInvestmentAccountsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        dataProviderAccountType
        earmarks {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getOtherInvestmentAccountSnapshot = `
  query GetOtherInvestmentAccountSnapshot($id: ID!) {
    getOtherInvestmentAccountSnapshot(id: $id) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.listOtherInvestmentAccountSnapshots = `
  query ListOtherInvestmentAccountSnapshots(
    $filter: ModelOtherInvestmentAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOtherInvestmentAccountSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.otherInvestmentAccountSnapshotsByAccount = `
  query OtherInvestmentAccountSnapshotsByAccount(
    $accountId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOtherInvestmentAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    otherInvestmentAccountSnapshotsByAccount(
      accountId: $accountId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getUncategorizedAccount = `
  query GetUncategorizedAccount($accountId: ID!) {
    getUncategorizedAccount(accountId: $accountId) {
      accountId
      accountName
      accountNameFromDataProvider
      dataProviderAccountId
      dataProviderIdForFinancialInstitutionUser
      dataProvider
      manuallyDeclared
      yodleeRawAccountFields {
        container
      }
      snapshots {
        items {
          accountId
          isAsset
          balance
          createdAt
          lastUpdatedOnProviderSide
          dataProviderActionNeededStatus
          owner
          id
          updatedAt
        }
        nextToken
      }
      owner
      dataProviderAccountType
      createdAt
      updatedAt
    }
  }
`;
exports.listUncategorizedAccounts = `
  query ListUncategorizedAccounts(
    $accountId: ID
    $filter: ModelUncategorizedAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUncategorizedAccounts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        dataProviderAccountType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.uncategorizedAccountsByDataProviderAccountId = `
  query UncategorizedAccountsByDataProviderAccountId(
    $dataProviderAccountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUncategorizedAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uncategorizedAccountsByDataProviderAccountId(
      dataProviderAccountId: $dataProviderAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        dataProviderAccountType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.uncategorizedAccountsByOwner = `
  query UncategorizedAccountsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUncategorizedAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uncategorizedAccountsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        accountName
        accountNameFromDataProvider
        dataProviderAccountId
        dataProviderIdForFinancialInstitutionUser
        dataProvider
        manuallyDeclared
        yodleeRawAccountFields {
          container
        }
        snapshots {
          items {
            accountId
            isAsset
            balance
            createdAt
            lastUpdatedOnProviderSide
            dataProviderActionNeededStatus
            owner
            id
            updatedAt
          }
          nextToken
        }
        owner
        dataProviderAccountType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getUncategorizedAccountSnapshot = `
  query GetUncategorizedAccountSnapshot($id: ID!) {
    getUncategorizedAccountSnapshot(id: $id) {
      accountId
      isAsset
      balance
      createdAt
      lastUpdatedOnProviderSide
      dataProviderActionNeededStatus
      owner
      id
      updatedAt
    }
  }
`;
exports.listUncategorizedAccountSnapshots = `
  query ListUncategorizedAccountSnapshots(
    $filter: ModelUncategorizedAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUncategorizedAccountSnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.uncategorizedAccountSnapshotsByAccount = `
  query UncategorizedAccountSnapshotsByAccount(
    $accountId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUncategorizedAccountSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uncategorizedAccountSnapshotsByAccount(
      accountId: $accountId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        isAsset
        balance
        createdAt
        lastUpdatedOnProviderSide
        dataProviderActionNeededStatus
        owner
        id
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getMember = `
  query GetMember($owner: String!) {
    getMember(owner: $owner) {
      owner
      yodleeLoginName
      mxUserGuid
      payCriticalExpensesTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      buildOneMonthEmergencyFundTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      extendEmergencyFundToThreeMonthsTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      extendEmergencyFundToSixMonthsTask {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      investInBrokerageAccountTask {
        owner
        isComplete
        stepsCompletedAtLeastOnce
        subtaskCompletion
        createdAt
        updatedAt
      }
      payOffDebtTasks {
        items {
          id
          owner
          isComplete
          subtaskCompletion
          debtAccountId
          debtAccount {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                interestAPY
                interestImprecise
                interestInactive
                id
                updatedAt
              }
              nextToken
            }
            owner
            memberDeclaredInterestAPY
            memberDeclaredInterestInactive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeTo401k2022Tasks {
        items {
          id
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          k401AccountId
          k401Account {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeTo401kTasks {
        items {
          id
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          contributionYear
          k401AccountId
          k401Account {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contributeToIra2022Task {
        owner
        isComplete
        subtaskCompletion
        createdAt
        updatedAt
      }
      contributeToIraTasks {
        items {
          id
          owner
          isComplete
          subtaskCompletion
          contributionYear
          createdAt
          updatedAt
        }
        nextToken
      }
      activeTask {
        taskTypename
        taskPrimaryKey
      }
      activeTaskAbsoluteDollarsToAllocate
      activeTaskWasRecommendedByWalkthrough
      savingsAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      checkingAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      debtAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              interestAPY
              interestImprecise
              interestInactive
              id
              updatedAt
            }
            nextToken
          }
          owner
          memberDeclaredInterestAPY
          memberDeclaredInterestInactive
          createdAt
          updatedAt
        }
        nextToken
      }
      k401Accounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          employerMatchRules {
            fractionMatch
            maxEmployerMatch
          }
          isEligibleForRoth401KContributions
          isEligibleForPreTaxTraditional401KContributions
          isEligibleForAfterTaxTraditional401KContributions
          createdAt
          updatedAt
        }
        nextToken
      }
      traditionalIraAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rothIraAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          transactionAggregations {
            type
            amount
          }
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      otherInvestmentAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          dataProviderAccountType
          earmarks {
            type
            amount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      uncategorizedAccounts {
        items {
          accountId
          accountName
          accountNameFromDataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
          dataProvider
          manuallyDeclared
          yodleeRawAccountFields {
            container
          }
          snapshots {
            items {
              accountId
              isAsset
              balance
              createdAt
              lastUpdatedOnProviderSide
              dataProviderActionNeededStatus
              owner
              id
              updatedAt
            }
            nextToken
          }
          owner
          dataProviderAccountType
          createdAt
          updatedAt
        }
        nextToken
      }
      onboardingComplete
      email
      phone
      state
      zipCode
      birthday
      spouseBirthday
      employmentStatus
      estimatedTaxableIncome
      estimatedAverageMonthlySpending
      taxFilingStatus
      estimatedAverageMonthlyAfterTaxIncome
      lastReceivedSavingsRatePoints
      points
      isAbleToPayBasicExpensesMemberDeclared
      hasOneMonthEmergencyFundMemberDeclared
      promoCode
      lastLogin
      createdAt
      updatedAt
    }
  }
`;
exports.listMembers = `
  query ListMembers(
    $owner: String
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMembers(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        yodleeLoginName
        mxUserGuid
        payCriticalExpensesTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        buildOneMonthEmergencyFundTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        extendEmergencyFundToThreeMonthsTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        extendEmergencyFundToSixMonthsTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        investInBrokerageAccountTask {
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          createdAt
          updatedAt
        }
        payOffDebtTasks {
          items {
            id
            owner
            isComplete
            subtaskCompletion
            debtAccountId
            debtAccount {
              accountId
              accountName
              accountNameFromDataProvider
              dataProviderAccountId
              dataProviderIdForFinancialInstitutionUser
              dataProvider
              manuallyDeclared
              yodleeRawAccountFields {
                container
              }
              snapshots {
                nextToken
              }
              owner
              memberDeclaredInterestAPY
              memberDeclaredInterestInactive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        contributeTo401k2022Tasks {
          items {
            id
            owner
            isComplete
            stepsCompletedAtLeastOnce
            subtaskCompletion
            k401AccountId
            k401Account {
              accountId
              accountName
              accountNameFromDataProvider
              dataProviderAccountId
              dataProviderIdForFinancialInstitutionUser
              dataProvider
              manuallyDeclared
              yodleeRawAccountFields {
                container
              }
              snapshots {
                nextToken
              }
              owner
              transactionAggregations {
                type
                amount
              }
              employerMatchRules {
                fractionMatch
                maxEmployerMatch
              }
              isEligibleForRoth401KContributions
              isEligibleForPreTaxTraditional401KContributions
              isEligibleForAfterTaxTraditional401KContributions
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        contributeTo401kTasks {
          items {
            id
            owner
            isComplete
            stepsCompletedAtLeastOnce
            subtaskCompletion
            contributionYear
            k401AccountId
            k401Account {
              accountId
              accountName
              accountNameFromDataProvider
              dataProviderAccountId
              dataProviderIdForFinancialInstitutionUser
              dataProvider
              manuallyDeclared
              yodleeRawAccountFields {
                container
              }
              snapshots {
                nextToken
              }
              owner
              transactionAggregations {
                type
                amount
              }
              employerMatchRules {
                fractionMatch
                maxEmployerMatch
              }
              isEligibleForRoth401KContributions
              isEligibleForPreTaxTraditional401KContributions
              isEligibleForAfterTaxTraditional401KContributions
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        contributeToIra2022Task {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        contributeToIraTasks {
          items {
            id
            owner
            isComplete
            subtaskCompletion
            contributionYear
            createdAt
            updatedAt
          }
          nextToken
        }
        activeTask {
          taskTypename
          taskPrimaryKey
        }
        activeTaskAbsoluteDollarsToAllocate
        activeTaskWasRecommendedByWalkthrough
        savingsAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        checkingAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        debtAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                interestAPY
                interestImprecise
                interestInactive
                id
                updatedAt
              }
              nextToken
            }
            owner
            memberDeclaredInterestAPY
            memberDeclaredInterestInactive
            createdAt
            updatedAt
          }
          nextToken
        }
        k401Accounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          nextToken
        }
        traditionalIraAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rothIraAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        otherInvestmentAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            dataProviderAccountType
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        uncategorizedAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            dataProviderAccountType
            createdAt
            updatedAt
          }
          nextToken
        }
        onboardingComplete
        email
        phone
        state
        zipCode
        birthday
        spouseBirthday
        employmentStatus
        estimatedTaxableIncome
        estimatedAverageMonthlySpending
        taxFilingStatus
        estimatedAverageMonthlyAfterTaxIncome
        lastReceivedSavingsRatePoints
        points
        isAbleToPayBasicExpensesMemberDeclared
        hasOneMonthEmergencyFundMemberDeclared
        promoCode
        lastLogin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.memberByYodleeLoginName = `
  query MemberByYodleeLoginName(
    $yodleeLoginName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByYodleeLoginName(
      yodleeLoginName: $yodleeLoginName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        yodleeLoginName
        mxUserGuid
        payCriticalExpensesTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        buildOneMonthEmergencyFundTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        extendEmergencyFundToThreeMonthsTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        extendEmergencyFundToSixMonthsTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        investInBrokerageAccountTask {
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          createdAt
          updatedAt
        }
        payOffDebtTasks {
          items {
            id
            owner
            isComplete
            subtaskCompletion
            debtAccountId
            debtAccount {
              accountId
              accountName
              accountNameFromDataProvider
              dataProviderAccountId
              dataProviderIdForFinancialInstitutionUser
              dataProvider
              manuallyDeclared
              yodleeRawAccountFields {
                container
              }
              snapshots {
                nextToken
              }
              owner
              memberDeclaredInterestAPY
              memberDeclaredInterestInactive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        contributeTo401k2022Tasks {
          items {
            id
            owner
            isComplete
            stepsCompletedAtLeastOnce
            subtaskCompletion
            k401AccountId
            k401Account {
              accountId
              accountName
              accountNameFromDataProvider
              dataProviderAccountId
              dataProviderIdForFinancialInstitutionUser
              dataProvider
              manuallyDeclared
              yodleeRawAccountFields {
                container
              }
              snapshots {
                nextToken
              }
              owner
              transactionAggregations {
                type
                amount
              }
              employerMatchRules {
                fractionMatch
                maxEmployerMatch
              }
              isEligibleForRoth401KContributions
              isEligibleForPreTaxTraditional401KContributions
              isEligibleForAfterTaxTraditional401KContributions
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        contributeTo401kTasks {
          items {
            id
            owner
            isComplete
            stepsCompletedAtLeastOnce
            subtaskCompletion
            contributionYear
            k401AccountId
            k401Account {
              accountId
              accountName
              accountNameFromDataProvider
              dataProviderAccountId
              dataProviderIdForFinancialInstitutionUser
              dataProvider
              manuallyDeclared
              yodleeRawAccountFields {
                container
              }
              snapshots {
                nextToken
              }
              owner
              transactionAggregations {
                type
                amount
              }
              employerMatchRules {
                fractionMatch
                maxEmployerMatch
              }
              isEligibleForRoth401KContributions
              isEligibleForPreTaxTraditional401KContributions
              isEligibleForAfterTaxTraditional401KContributions
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        contributeToIra2022Task {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        contributeToIraTasks {
          items {
            id
            owner
            isComplete
            subtaskCompletion
            contributionYear
            createdAt
            updatedAt
          }
          nextToken
        }
        activeTask {
          taskTypename
          taskPrimaryKey
        }
        activeTaskAbsoluteDollarsToAllocate
        activeTaskWasRecommendedByWalkthrough
        savingsAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        checkingAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        debtAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                interestAPY
                interestImprecise
                interestInactive
                id
                updatedAt
              }
              nextToken
            }
            owner
            memberDeclaredInterestAPY
            memberDeclaredInterestInactive
            createdAt
            updatedAt
          }
          nextToken
        }
        k401Accounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          nextToken
        }
        traditionalIraAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rothIraAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        otherInvestmentAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            dataProviderAccountType
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        uncategorizedAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            dataProviderAccountType
            createdAt
            updatedAt
          }
          nextToken
        }
        onboardingComplete
        email
        phone
        state
        zipCode
        birthday
        spouseBirthday
        employmentStatus
        estimatedTaxableIncome
        estimatedAverageMonthlySpending
        taxFilingStatus
        estimatedAverageMonthlyAfterTaxIncome
        lastReceivedSavingsRatePoints
        points
        isAbleToPayBasicExpensesMemberDeclared
        hasOneMonthEmergencyFundMemberDeclared
        promoCode
        lastLogin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.memberByMxUserGuid = `
  query MemberByMxUserGuid(
    $mxUserGuid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByMxUserGuid(
      mxUserGuid: $mxUserGuid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        yodleeLoginName
        mxUserGuid
        payCriticalExpensesTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        buildOneMonthEmergencyFundTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        extendEmergencyFundToThreeMonthsTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        extendEmergencyFundToSixMonthsTask {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        investInBrokerageAccountTask {
          owner
          isComplete
          stepsCompletedAtLeastOnce
          subtaskCompletion
          createdAt
          updatedAt
        }
        payOffDebtTasks {
          items {
            id
            owner
            isComplete
            subtaskCompletion
            debtAccountId
            debtAccount {
              accountId
              accountName
              accountNameFromDataProvider
              dataProviderAccountId
              dataProviderIdForFinancialInstitutionUser
              dataProvider
              manuallyDeclared
              yodleeRawAccountFields {
                container
              }
              snapshots {
                nextToken
              }
              owner
              memberDeclaredInterestAPY
              memberDeclaredInterestInactive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        contributeTo401k2022Tasks {
          items {
            id
            owner
            isComplete
            stepsCompletedAtLeastOnce
            subtaskCompletion
            k401AccountId
            k401Account {
              accountId
              accountName
              accountNameFromDataProvider
              dataProviderAccountId
              dataProviderIdForFinancialInstitutionUser
              dataProvider
              manuallyDeclared
              yodleeRawAccountFields {
                container
              }
              snapshots {
                nextToken
              }
              owner
              transactionAggregations {
                type
                amount
              }
              employerMatchRules {
                fractionMatch
                maxEmployerMatch
              }
              isEligibleForRoth401KContributions
              isEligibleForPreTaxTraditional401KContributions
              isEligibleForAfterTaxTraditional401KContributions
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        contributeTo401kTasks {
          items {
            id
            owner
            isComplete
            stepsCompletedAtLeastOnce
            subtaskCompletion
            contributionYear
            k401AccountId
            k401Account {
              accountId
              accountName
              accountNameFromDataProvider
              dataProviderAccountId
              dataProviderIdForFinancialInstitutionUser
              dataProvider
              manuallyDeclared
              yodleeRawAccountFields {
                container
              }
              snapshots {
                nextToken
              }
              owner
              transactionAggregations {
                type
                amount
              }
              employerMatchRules {
                fractionMatch
                maxEmployerMatch
              }
              isEligibleForRoth401KContributions
              isEligibleForPreTaxTraditional401KContributions
              isEligibleForAfterTaxTraditional401KContributions
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        contributeToIra2022Task {
          owner
          isComplete
          subtaskCompletion
          createdAt
          updatedAt
        }
        contributeToIraTasks {
          items {
            id
            owner
            isComplete
            subtaskCompletion
            contributionYear
            createdAt
            updatedAt
          }
          nextToken
        }
        activeTask {
          taskTypename
          taskPrimaryKey
        }
        activeTaskAbsoluteDollarsToAllocate
        activeTaskWasRecommendedByWalkthrough
        savingsAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        checkingAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        debtAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                interestAPY
                interestImprecise
                interestInactive
                id
                updatedAt
              }
              nextToken
            }
            owner
            memberDeclaredInterestAPY
            memberDeclaredInterestInactive
            createdAt
            updatedAt
          }
          nextToken
        }
        k401Accounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            employerMatchRules {
              fractionMatch
              maxEmployerMatch
            }
            isEligibleForRoth401KContributions
            isEligibleForPreTaxTraditional401KContributions
            isEligibleForAfterTaxTraditional401KContributions
            createdAt
            updatedAt
          }
          nextToken
        }
        traditionalIraAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rothIraAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            transactionAggregations {
              type
              amount
            }
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        otherInvestmentAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            dataProviderAccountType
            earmarks {
              type
              amount
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        uncategorizedAccounts {
          items {
            accountId
            accountName
            accountNameFromDataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
            dataProvider
            manuallyDeclared
            yodleeRawAccountFields {
              container
            }
            snapshots {
              items {
                accountId
                isAsset
                balance
                createdAt
                lastUpdatedOnProviderSide
                dataProviderActionNeededStatus
                owner
                id
                updatedAt
              }
              nextToken
            }
            owner
            dataProviderAccountType
            createdAt
            updatedAt
          }
          nextToken
        }
        onboardingComplete
        email
        phone
        state
        zipCode
        birthday
        spouseBirthday
        employmentStatus
        estimatedTaxableIncome
        estimatedAverageMonthlySpending
        taxFilingStatus
        estimatedAverageMonthlyAfterTaxIncome
        lastReceivedSavingsRatePoints
        points
        isAbleToPayBasicExpensesMemberDeclared
        hasOneMonthEmergencyFundMemberDeclared
        promoCode
        lastLogin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
exports.getMxConnectWidgetUrl = `
  query GetMxConnectWidgetUrl($dataProviderIdForFinancialInstitutionUser: ID) {
    getMxConnectWidgetUrl(
      dataProviderIdForFinancialInstitutionUser: $dataProviderIdForFinancialInstitutionUser
    )
  }
`;
exports.runGoalEngineForMember = `
  query RunGoalEngineForMember {
    runGoalEngineForMember {
      taskTypename
      taskPrimaryKey
    }
  }
`;
exports.dollarsToAddToAccountForTask = `
  query DollarsToAddToAccountForTask($taskKey: TaskKeyPairInput!) {
    dollarsToAddToAccountForTask(taskKey: $taskKey)
  }
`;
exports.queryAiAdvisor = `
  query QueryAiAdvisor($question: String!) {
    queryAiAdvisor(question: $question) {
      recordId
      response
    }
  }
`;
