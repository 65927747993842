import * as React from 'react'
import { NavigationButton } from '../common/buttons/navigation-button'
import { QuestionAndAnswers } from '../common/questions/question-data'

export enum TaskFlowStage {
  UNDEFINED,
  STEPS,
  INTRO,
  DETERMINE_NEXT_GOAL
}

// Once in the task steps portion of the flow, how should the steps be rendered?
export enum TaskStepsRenderingStyle {
  CHECKLIST,
  CLICK_THROUGH_MODULE
}

export interface SubtaskData {
  // key for React lists.  Keys must be unique among sibling in Subtasks rendered from lists.
  key: string
  isComplete: boolean
  // Store the provided completion state in the backend.  Relevant client objects etc. must be passed in
  // via function closure.
  storeCompletionState: (complete: boolean) => void
  title: string
  description?: React.ReactNode
  questions?: QuestionAndAnswers[]
}

export interface TaskStepsData {
  title: string
  description: React.ReactNode
  stepRenderingStyle: TaskStepsRenderingStyle
  subtasks: SubtaskData[]
  button: NavigationButton
}

export interface TaskIntroData {
  title: string
  introContent: React.ReactNode
  button?: NavigationButton
  alternativeButton?: NavigationButton
}

// The interface for rendering a Task.
// Notably, this object contains rendering information like navigation buttons that are not necessary for a data struct
// containing task copy only.
export interface TaskFlowData {
  steps: TaskStepsData
  introData?: TaskIntroData
}

// TODO: consider changing this to WalkthroughGraphQL.TaskKeyPairInput, which is the same structural
// type but explicitly linked to how we represent tasks in the backend.  Alternately don't do this
// and justify why we want to decopule API task key representation from FE task key representation.
export interface TaskKey {
  taskTypename: string
  taskId: string
}
