import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const drawerTitleStyle = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row'
  },
  titleView: {
    flex: 2,
    alignContent: 'flex-start'
  },
  valueView: {
    flex: 1,
    alignContent: 'flex-end',
    textAlign: 'right'
  },
  completeCheckMark: {
    paddingRight: THEME.spacing.horizontalSpaceMedium,
    alignSelf: 'center'
  }
})
