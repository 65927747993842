// This file translates member accounts into a Drawer[] shape for rendering.
import {
  AccountWithSnapshotShape,
  getBalanceFromLastSnapshot,
  validateAccountForHandler,
  getAccountsFromContainer,
  processAllAccountsFromMemberLatestFinancialViewQuery,
  InputAccountTypesMemberLatestFinancialViewQuery
} from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish } from 'global-utils'
import { AccountCollectionData, AccountData } from '../account-data'
import { AccountQuestionHooks } from '../accounts-screen'
import { debtAccountBalanceRowHandler } from './debt-account-balance-row-handler'
import { k401AccountBalanceRowHandler } from './k401-account-balance-row-handler'
import { deleteSingleAccountQuestion } from '../../common/questions/content/delete-single-account'
import { deleteMxFinancialInsitutionUserQuestion } from '../../common/questions/content/delete-mx-financial-institution-user'
import { editAccountNameQuestion } from '../../common/questions/content/edit-account-name'
import { formatDistanceToNow } from 'date-fns'
import { updateAccountBalanceQuestion } from '../../common/questions/content/update-manual-account-balance'

export type AccountRowConverter<T> = (account: T | null) => {
  account: AccountData
  balance: number
} | null

function extractBalance<T> (
  r: NonNullable<ReturnType<AccountRowConverter<T>>>
): number {
  return r.balance
}

function extractRow<T> (
  r: NonNullable<ReturnType<AccountRowConverter<T>>>
): AccountData {
  return r.account
}

function sumReducer (partial: number, next: number): number {
  return partial + next
}

export function getAccountCollections (
  shouldShowWelcomeBanner: boolean,
  accountQuestionHooks: AccountQuestionHooks,
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
): AccountCollectionData[] {
  const queryIsEmpty = !isNonNullish(memberQuery?.getMember)
  const accounts = getAccountsFromContainer(memberQuery?.getMember)
  const accountToAccountRowHandlers = {
    savingsAccounts: accountBalanceRow<
    InputAccountTypesMemberLatestFinancialViewQuery['savingsAccounts']
    >(accountQuestionHooks, shouldShowWelcomeBanner, memberQuery),
    checkingAccounts: accountBalanceRow<
    InputAccountTypesMemberLatestFinancialViewQuery['checkingAccounts']
    >(accountQuestionHooks, shouldShowWelcomeBanner, memberQuery),
    debtAccounts: debtAccountBalanceRowHandler(
      accountQuestionHooks,
      shouldShowWelcomeBanner,
      memberQuery
    ),
    otherInvestmentAccounts: accountBalanceRow<
    InputAccountTypesMemberLatestFinancialViewQuery['otherInvestmentAccounts']
    >(accountQuestionHooks, shouldShowWelcomeBanner, memberQuery),
    traditionalIraAccounts: accountBalanceRow<
    InputAccountTypesMemberLatestFinancialViewQuery['traditionalIraAccounts']
    >(accountQuestionHooks, shouldShowWelcomeBanner, memberQuery),
    rothIraAccounts: accountBalanceRow<
    InputAccountTypesMemberLatestFinancialViewQuery['rothIraAccounts']
    >(accountQuestionHooks, shouldShowWelcomeBanner, memberQuery),
    k401Accounts: k401AccountBalanceRowHandler(
      accountQuestionHooks,
      shouldShowWelcomeBanner,
      memberQuery
    ),
    uncategorizedAccounts: accountBalanceRow<
    InputAccountTypesMemberLatestFinancialViewQuery['uncategorizedAccounts']
    >(accountQuestionHooks, shouldShowWelcomeBanner, memberQuery)
  }

  const accountRows = processAllAccountsFromMemberLatestFinancialViewQuery(
    accounts,
    accountToAccountRowHandlers
  )
  const accountCollectionsToReturn: AccountCollectionData[] = []

  const cashResults = [
    ...accountRows.savingsAccounts.filter(isNonNullish),
    ...accountRows.checkingAccounts.filter(isNonNullish)
  ]
  accountCollectionsToReturn.push({
    key: 'Cash',
    title: 'Cash',
    balance: queryIsEmpty
      ? undefined
      : cashResults
        .map(extractBalance)
        .reduce(sumReducer, 0)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }),
    accounts: cashResults.map(extractRow)
  })

  const taxAdvantagedResults = [
    ...accountRows.k401Accounts.filter(isNonNullish),
    ...accountRows.rothIraAccounts.filter(isNonNullish),
    ...accountRows.traditionalIraAccounts.filter(isNonNullish)
  ]
  accountCollectionsToReturn.push({
    key: 'TaxAdvantaged',
    title: 'Tax-advantaged',
    balance: queryIsEmpty
      ? undefined
      : taxAdvantagedResults
        .map(extractBalance)
        .reduce(sumReducer, 0)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }),
    accounts: taxAdvantagedResults.map(extractRow)
  })

  const debtResults = accountRows.debtAccounts.filter(isNonNullish)
  accountCollectionsToReturn.push({
    key: 'Debt',
    title: 'Debt',
    balance: queryIsEmpty
      ? undefined
      : debtResults
        .map(extractBalance)
        .reduce(sumReducer, 0)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }),
    accounts: debtResults.map(extractRow)
  })

  const otherInvestmentResults =
    accountRows.otherInvestmentAccounts.filter(isNonNullish)
  accountCollectionsToReturn.push({
    key: 'Investments',
    title: 'Investments',
    balance: queryIsEmpty
      ? undefined
      : otherInvestmentResults
        .map(extractBalance)
        .reduce(sumReducer, 0)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }),
    accounts: otherInvestmentResults.map(extractRow)
  })

  const uncategorizedResults =
    accountRows.uncategorizedAccounts.filter(isNonNullish)
  // Unlike other account types, we don't show uncategorized accounts if they are empty.  Uncategorized accounts are
  // about handling edge cases, not a standard part of your financial picture.
  if (uncategorizedResults.length !== 0) {
    accountCollectionsToReturn.push({
      key: 'Uncategorized',
      title: 'Uncategorized',
      balance: queryIsEmpty
        ? undefined
        : uncategorizedResults
          .map(extractBalance)
          .reduce(sumReducer, 0)
          .toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }),
      accounts: uncategorizedResults.map(extractRow),
      text:
        'WARNING! Uncategorized accounts are not considered as part of our financial advice.\n\nIf you have ' +
        "uncategorized accounts, please 1) tell us about them in Feedback and 2) double check that they don't " +
        'change your eligibility for recommended goals.\n\nExample- your IRA contribution limits change if you ' +
        "have an active 401k account, but if that account is uncategorized, we won't be able to handle that for you"
    })
  }

  return accountCollectionsToReturn
}

export function accountBalanceRow<
  T extends Omit<WalkthroughGraphQL.Account, '__typename'> &
  AccountWithSnapshotShape & { __typename: string }
> (
  accountQuestionHooks: AccountQuestionHooks,
  shouldShowWelcomeBanner: boolean,
  memberQuery:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
): AccountRowConverter<T> {
  return (account) => {
    try {
      // This if block allows us to assume that account is nonullable further down
      if (!validateAccountForHandler(account, 'accountDrawersGenericHandler')) {
        return null
      }
    } catch (e) {
      return null
    }
    if (
      !isNonNullish(account.accountName) &&
      !isNonNullish(
        getBalanceFromLastSnapshot(account, WalkthroughGraphQL.AssetType.ASSET)
      )
    ) {
      return null
    }

    // Assemble edit/delete/update hooks depending on data provider
    let deleteFn: AccountData['handleOnPressDelete'] = null
    let updateBalanceFn: AccountData['handleOnUpdateBalance'] = null
    if (account.dataProvider === WalkthroughGraphQL.DataProvider.YODLEE) {
      deleteFn = () => {
        accountQuestionHooks.setAccountQuestions([
          [deleteSingleAccountQuestion(account)]
        ])
        accountQuestionHooks.setAccountQuestionIndex(0)
      }
    } else if (account.dataProvider === WalkthroughGraphQL.DataProvider.MX) {
      deleteFn = () => {
        accountQuestionHooks.setAccountQuestions([
          [
            deleteMxFinancialInsitutionUserQuestion(
              account.dataProviderIdForFinancialInstitutionUser,
              memberQuery
            )
          ]
        ])
        accountQuestionHooks.setAccountQuestionIndex(0)
      }
    } else if (
      account.dataProvider ===
      WalkthroughGraphQL.DataProvider.WALKTHROUGH_MANUALLY_DECLARED
    ) {
      deleteFn = () => {
        accountQuestionHooks.setAccountQuestions([
          [deleteSingleAccountQuestion(account)]
        ])
        accountQuestionHooks.setAccountQuestionIndex(0)
      }
      updateBalanceFn = () => {
        accountQuestionHooks.setAccountQuestions([
          [updateAccountBalanceQuestion(account)]
        ])
        accountQuestionHooks.setAccountQuestionIndex(0)
      }
    }
    const accountKeyPair = {
      accountTypename: account.__typename,
      accountPrimaryKey: account.accountId
    }
    const lastUpdatedDbValue =
      account.snapshots?.items[0]?.lastUpdatedOnProviderSide
    let lastUpdatedFormattedValue = ''
    if (isNonNullish(lastUpdatedDbValue)) {
      lastUpdatedFormattedValue = formatDistanceToNow(
        new Date(lastUpdatedDbValue),
        { addSuffix: true }
      )
    }
    const accountDetails: AccountData = {
      accountKeyPair,
      accountName: account.accountName ?? '(unnamed account)',
      lastUpdateString: lastUpdatedFormattedValue,
      handleOnPressEditAccountName: () => {
        accountQuestionHooks.setAccountQuestions([
          [editAccountNameQuestion(accountKeyPair, memberQuery)]
        ])
        accountQuestionHooks.setAccountQuestionIndex(0)
      },
      balance: (
        getBalanceFromLastSnapshot(
          account,
          WalkthroughGraphQL.AssetType.ASSET
        ) ?? 0
      ).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }),
      handleOnPressEdit: null,
      handleOnPressDelete: deleteFn,
      handleOnUpdateBalance: updateBalanceFn,
      // Individual account type handlers should handle filling in transaction aggregations as they are not
      // generic to all accounts.  We could consider defining this field to be TransactionAggregationData[] | undefined
      // and not mentioning it here in this generic code, which would also help clarify the data definition story
      // (i.e. where is this field actually set?).  We do NOT want to add data here and then mutate it in individual
      // handlers- it would be very hard to inspect/reason about the logic.
      transactionAggregations: [],
      // Individual account type handlers should handle filling in account settings as they are not
      // generic to all accounts. We could consider defining this field to be AccountSettingData[] | undefined
      // and not mentioning it here in this generic code, which would also help clarify the data definition story
      // (i.e. where is this field actually set?). We do NOT want to add data here and then mutate it in individual
      // handlers- it would be very hard to inspect/reason about the logic.
      accountSettings: []
    }
    return {
      account: accountDetails,
      balance:
        getBalanceFromLastSnapshot(
          account,
          WalkthroughGraphQL.AssetType.ASSET
        ) ?? 0
    }
  }
}
