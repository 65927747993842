import {
  validateAccountForHandler,
  InputAccountTypesMemberLatestFinancialViewQuery
} from 'multi-type-processor'
import { QuestionAndAnswers } from '../../common/questions/question-data'
import { AccountQuestionAlertHandlerType } from './get-account-question-alerts'
import { isNonNullish } from 'global-utils'
import { afterTaxTraditional401kEligibilityQuestion } from '../../common/questions/content/after-tax-traditional-401k-eligibility'
import { preTaxTraditional401kEligibilityQuestion } from '../../common/questions/content/pre-tax-traditional-401k-eligibility'
import { roth401kEligibilityQuestion } from '../../common/questions/content/roth-401k-eligibility'
import { employerMatch401kQuestion } from '../../common/questions/content/employer-match-401k'
import { manualAccountBalanceRepromptHandler } from './manual-account-balance-reprompt-handler'

export const k401AccountQuestionAlertsHandler: AccountQuestionAlertHandlerType<
InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
> = (account) => {
  try {
    // This if block allows us to assume that account is nonullable further down
    if (!validateAccountForHandler(account, 'k401AccountHandler')) {
      return null
    }
  } catch (e) {
    return null
  }

  const questionsAndAnswers =
    manualAccountBalanceRepromptHandler(account) ?? []

  const eligibilityQuestions: QuestionAndAnswers[] = []
  if (!isNonNullish(account.isEligibleForPreTaxTraditional401KContributions)) {
    eligibilityQuestions.push(
      preTaxTraditional401kEligibilityQuestion(account)
    )
  }
  if (!isNonNullish(account.isEligibleForRoth401KContributions)) {
    eligibilityQuestions.push(roth401kEligibilityQuestion(account))
  }
  if (
    !isNonNullish(account.isEligibleForAfterTaxTraditional401KContributions)
  ) {
    eligibilityQuestions.push(
      afterTaxTraditional401kEligibilityQuestion(account)
    )
  }
  if (eligibilityQuestions.length > 0) {
    questionsAndAnswers.push(eligibilityQuestions)
  }

  // This logic is WAI but slightly unintuitive- account.isEligible* here is the value at the beginning of question
  // generation (that is when this logic is run).  Therefore, for a new account, we will always consider the
  // match question ineligible.  Only after the first round of questions on the AccountsScreen are complete will the
  // next round of questions be generated.  At that point, the match questions will be eligilbe/included as
  // appropriate.
  const isEligibleForMatchQuestion =
    (isNonNullish(account.isEligibleForPreTaxTraditional401KContributions) &&
      account.isEligibleForPreTaxTraditional401KContributions) ||
    (isNonNullish(account.isEligibleForRoth401KContributions) &&
      account.isEligibleForRoth401KContributions) ||
    (isNonNullish(account.isEligibleForAfterTaxTraditional401KContributions) &&
      account.isEligibleForAfterTaxTraditional401KContributions)

  if (
    isEligibleForMatchQuestion &&
    (!isNonNullish(account.employerMatchRules) ||
      account.employerMatchRules?.length <= 0)
  ) {
    questionsAndAnswers.push(employerMatch401kQuestion(account))
  }

  return questionsAndAnswers
}
