import {
  getAccountsFromContainer,
  processAllAccountsFromMemberLatestFinancialViewQuery,
  AccountWithSnapshotShape,
  getBalanceFromLastSnapshot,
  validateAccountForHandler,
  InputAccountTypesMemberLatestFinancialViewQuery
} from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish, collapseProcessingResultsIntoArray } from 'global-utils'

export type BalanceHandlerType<T> = (account: T | null) => number | null

// Get the member's net worth by summing the value of each of their accounts.
// This is a generally accepted form of net worth, although it is worth noting that it doesn't
// factor in some nuances like tax liability.  Ex: if all your savings are in a pre-tax 401k then
// your usable net worth is much lower because it will be taxed upon withdrawal.  Another case
// could be that you have large unrealized capital gains that would be taxed if you liquidated.
export function getNetWorth (
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
): number {
  const accounts = getAccountsFromContainer(memberQuery?.getMember)

  const accountToBalanceHandlers = {
    savingsAccounts: balanceHandler<
    InputAccountTypesMemberLatestFinancialViewQuery['savingsAccounts']
    >(WalkthroughGraphQL.AssetType.ASSET),
    checkingAccounts: balanceHandler<
    InputAccountTypesMemberLatestFinancialViewQuery['checkingAccounts']
    >(WalkthroughGraphQL.AssetType.ASSET),
    debtAccounts: balanceHandler<
    InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
    >(WalkthroughGraphQL.AssetType.LIABILITY),
    otherInvestmentAccounts: balanceHandler<
    InputAccountTypesMemberLatestFinancialViewQuery['otherInvestmentAccounts']
    >(WalkthroughGraphQL.AssetType.ASSET),
    traditionalIraAccounts: balanceHandler<
    InputAccountTypesMemberLatestFinancialViewQuery['traditionalIraAccounts']
    >(WalkthroughGraphQL.AssetType.ASSET),
    rothIraAccounts: balanceHandler<
    InputAccountTypesMemberLatestFinancialViewQuery['rothIraAccounts']
    >(WalkthroughGraphQL.AssetType.ASSET),
    k401Accounts: balanceHandler<
    InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
    >(WalkthroughGraphQL.AssetType.ASSET),
    uncategorizedAccounts: balanceHandler<
    InputAccountTypesMemberLatestFinancialViewQuery['uncategorizedAccounts']
    >(WalkthroughGraphQL.AssetType.ASSET)
  }

  const balances = processAllAccountsFromMemberLatestFinancialViewQuery(
    accounts,
    accountToBalanceHandlers
  )
  return collapseProcessingResultsIntoArray(balances)
    .filter(isNonNullish)
    .flat(1)
    .reduce((a, b) => a + b, 0)
}

function balanceHandler<
  T extends Omit<WalkthroughGraphQL.Account, '__typename'> &
  AccountWithSnapshotShape
> (defaultAssetType: WalkthroughGraphQL.AssetType): BalanceHandlerType<T> {
  return (account) => {
    try {
      // This if block allows us to assume that account is nonullable further down
      if (!validateAccountForHandler(account, 'netWorthGenericHandler')) {
        return null
      }
    } catch (e) {
      return null
    }
    const balance = getBalanceFromLastSnapshot(account, defaultAssetType)
    return isNonNullish(balance) ? balance : null
  }
}
