import { useCallback, useEffect, useRef } from 'react'

// A hook to track whether the component is mounted.  Useful for long-running asynchronous state updates.
// Shamelessly borrowed from https://stackoverflow.com/questions/58979309/checking-if-a-component-is-unmounted-using-react-hooks
export function useIsMounted (): () => boolean {
  const isMountedRef = useRef(true)
  const isMounted = useCallback(() => isMountedRef.current, [])

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  return isMounted
}
