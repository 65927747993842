import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../themes/theme'

export const taskListScreenStyle = (
  theme: Theme
): {
  container: ViewStyle
  button: ViewStyle
  buttonText: TextStyle
} =>
  StyleSheet.create({
    container: {
      width: '100%',
      height: '100%'
    },
    button: {
      backgroundColor: theme.color.superHighlight,
      minWidth: theme.buttons.smallButtonWidth,
      height: theme.buttons.buttonHeight,
      borderRadius: 20,
      borderColor: theme.color.outline,
      borderWidth: 2,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      position: 'absolute',
      bottom: theme.spacing.verticalSpaceLarge
    },
    buttonText: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeLarge,
      fontFamily: 'PublicSans_700Bold',
      paddingLeft: theme.spacing.horizontalSpaceMedium,
      paddingRight: theme.spacing.horizontalSpaceMedium
    }
  })
