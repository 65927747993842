import * as React from 'react'
import { View } from 'react-native'
import { taskScreenStyle } from './task-screen.style'
import { TaskSteps } from './task-steps/task-steps'
import { TaskIntro } from './task-intro'
import { RouteProp, useFocusEffect } from '@react-navigation/native'
import { TaskFlowStage, TaskFlowData, TaskKey } from './task-flow-data'
import { THEME } from '../../../constants'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import {
  gql,
  useLazyQuery,
  useMutation,
  useApolloClient,
  ApolloClient,
  NormalizedCacheObject
} from '@apollo/client'
import { loadToken } from '../../auth/load-token'
import { useIsMounted } from '../../../util/use-is-mounted'
import { useGetIsFocused } from '../../../util/use-get-is-focused'

import { isNonNullish } from 'global-utils'
import { DetermineNextTask } from './determine-next-task/determine-next-task'
import JungleMiddle from '../../../assets/jungle/jungle-middle'
import { getTaskFlowData } from './content-construction/get-task-flow-data'
import { LogBox } from 'react-native-web-log-box'
import { getCurrentAllocationForTask } from 'multi-type-processor'

// Ignore warnings about passing waitForPromise in the route to a screen because we are not
// using state persistence or deep linking to this screen. See:
// https://reactnavigation.org/docs/troubleshooting/#i-get-the-warning-non-serializable-values-were-found-in-the-navigation-state
// Note that this does not actually stop this from being logged in the web console (but likely works for stopping mobile
// web dev tool logs from showing)
LogBox.ignoreLogs([
  'Non-serializable values were found in the navigation state.'
])
LogBox.install()

function TaskScreen (props: {
  route: RouteProp<
  {
    TaskScreen?: {
      taskKey?: TaskKey
      // The absolute dollars to allocate to this task. NOTE that null and undefined are very different!
      // Null indicates that this prop *has* a value (of null).  Undefined indicates that there is no
      // value for this prop (and we should maybe try to find one).
      absoluteDollarsToAllocateToTask?: number | null | undefined
      flowStage: TaskFlowStage
      waitForPromise?: Promise<void>
      recommendedByWalkthrough?: boolean
    }
  },
  'TaskScreen'
  >
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  const isMounted = useIsMounted()
  const getIsFocused = useGetIsFocused()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>

  const [authOwner, setAuthOwner] = React.useState<string | undefined>()
  // TODO: We could narrow this query to either conditionally load a single task from the member object
  // or always load the "active task" object.  This depends on what the A bit tbd based on what the "select the next
  // task + run goal engine" flow looks like.  For example- is it possible to go to the TaskScreen for a task and NOT
  // have that task be the "active" task?  That might get complicated.
  // The downsides to loading the whole member are that 1) this query will cause a rerender when extraneous member
  // fields are updated and 2) we don't explictly declare a narrow set of data dependencies for this component.
  const [getMember, { data }] = useLazyQuery<
  WalkthroughGraphQL.GetMemberQuery,
  WalkthroughGraphQL.GetMemberQueryVariables
  >(gql(WalkthroughGraphQL.getMember))

  React.useEffect(() => {
    // There are known race condititions with async code in useEffect (like what if the component unmounts before the
    // function returns?), but this is still the recommended approach. See
    // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function getOwnerAndQuery (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getMember({ variables: { owner: owner } })
        setAuthOwner(owner)
      }
    }
    getOwnerAndQuery().catch(console.log)
  }, [])

  // If there is no explicit task in the route params and we aren't determining the next task, reroute to the stored
  // active task, it is exists.
  // Run this as an effect hook as navigation is not permitted mid-render.
  // Run this as a focus effect so that we do not trigger navigation if we've already navigated away from this screen.
  useFocusEffect(
    React.useCallback(() => {
      const dataActiveTask = data?.getMember?.activeTask
      if (
        props.route.params?.flowStage !== TaskFlowStage.DETERMINE_NEXT_GOAL &&
        props.route.params?.taskKey === undefined
      ) {
        // If data has an active task, redirect there.
        if (isNonNullish(dataActiveTask)) {
          props.navigation.navigate('TaskScreen', {
            taskKey: {
              taskTypename: dataActiveTask.taskTypename,
              taskId: dataActiveTask.taskPrimaryKey
            },
            // Returns undefined unless activeTaskAbsoluteDollarsToAllocate is actually stored as null
            dollarsToAllocateToTask:
              data?.getMember?.activeTaskAbsoluteDollarsToAllocate,
            flowStage: TaskFlowStage.STEPS,
            recommendedByWalkthrough:
              data?.getMember?.activeTaskWasRecommendedByWalkthrough ??
              undefined
          })
        } else if (isNonNullish(data)) {
          // If data is defined but lacks an active task, try to determine one
          props.navigation.navigate('TaskScreen', {
            flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL
          })
        } else if (data === null) {
          // If data is null (not undefined), redirect to the TaskListScreen, which will fire a new query and present the
          // member with more options.
          props.navigation.navigate('TaskListScreen')
        }
      }
    }, [data, props.route.params?.flowStage])
  )

  // If there is a specified task but not a relevant dollars amount in props,
  // read it from the db if possible, else query for it.
  // If the goal engine just recommended this task, requery for relevant dollars no
  // matter what.
  useFocusEffect(
    React.useCallback(() => {
      // Initial constants/helper values
      const dataActiveTask = data?.getMember?.activeTask
      const dollarsFromDatabase =
        data?.getMember?.activeTaskAbsoluteDollarsToAllocate
      const taskKey = props.route.params?.taskKey
      async function queryDollarsAndRedirect (): Promise<void> {
        const taskKey = props.route.params?.taskKey
        if (taskKey !== undefined) {
          const response = await client.query<
          WalkthroughGraphQL.DollarsToAddToAccountForTaskQuery,
          WalkthroughGraphQL.DollarsToAddToAccountForTaskQueryVariables
          >({
            query: gql(WalkthroughGraphQL.dollarsToAddToAccountForTask),
            variables: {
              taskKey: {
                taskPrimaryKey: taskKey.taskId,
                taskTypename: taskKey.taskTypename
              }
            }
          })
          const dollarsToAdd = response.data.dollarsToAddToAccountForTask
          if (getIsFocused() && isMounted()) {
            if (dollarsToAdd === null) {
              props.navigation.navigate('TaskScreen', {
                ...props.route.params,
                absoluteDollarsToAllocateToTask: null
              })
            } else if (dollarsToAdd !== undefined) {
              const currentlyAllocated =
                getCurrentAllocationForTask(data, {
                  taskPrimaryKey: taskKey.taskId,
                  taskTypename: taskKey.taskTypename
                }) ?? 0
              const total = currentlyAllocated + dollarsToAdd
              props.navigation.navigate('TaskScreen', {
                ...props.route.params,
                absoluteDollarsToAllocateToTask: total
              })
            }
          }
        }
      }
      const hasTaskAndDataButNoDollars =
        props.route.params?.absoluteDollarsToAllocateToTask === undefined &&
        // TODO: this should probably be checking taskToRender so that we don't accidentally query/trigger
        // a redirect if the task is in an error state.
        taskKey !== undefined &&
        data !== undefined // data is only undefined before the first db call.

      // Real logic of the hook
      if (
        hasTaskAndDataButNoDollars &&
        props.route.params?.flowStage !== TaskFlowStage.DETERMINE_NEXT_GOAL
      ) {
        const taskIsActiveTaskAndDbHasDollars =
          taskKey !== undefined && // have to repeat this so the compiler can correctly typecheck.
          dataActiveTask?.taskTypename === taskKey.taskTypename &&
          dataActiveTask?.taskPrimaryKey === taskKey.taskId &&
          dollarsFromDatabase !== undefined
        // "... and either we're not on the intro page or we are on the intro page but the task wasn't
        // explicitly recommended by Walkthrough"... this works because the goal engine only ever takes
        // us to the intro page with recommendedByWalkthrough = true
        const didntGetHereFromGoalEngine =
          props.route.params?.flowStage !== TaskFlowStage.INTRO ||
          props.route.params?.recommendedByWalkthrough !== true
        if (taskIsActiveTaskAndDbHasDollars && didntGetHereFromGoalEngine) {
          // Case 1: the task in props is the active task, and we weren't just re-recommended
          // this goal.
          // In this case we can redirect to the stored value from the database
          // Note that this is NOT redundant/covered by standard active task lookup/navigation
          // because we want to refer to the DB value if the user manually goes to the
          // task list screen and then returns to the previous task.  If this screen is mounted
          // under the task list screen in the navigation stack this code will be redundant, but
          // it's better not to rely on that ordering.
          props.navigation.navigate('TaskScreen', {
            ...props.route.params,
            absoluteDollarsToAllocateToTask: dollarsFromDatabase
          })
        } else {
          // Case 2: the task in props is NOT the active task, or it is but we don't have a stored value,
          // Or it is the active task but we were just re-recommended the goal.
          // In this case we should requery for dollars.
          queryDollarsAndRedirect().catch(console.log)
        }
      }
    }, [
      data,
      props.route.params?.taskKey,
      props.route.params?.absoluteDollarsToAllocateToTask
    ])
  )

  // Update the task to render any time data (or task key) changes, regardless of whether the
  // screen is focused.  If the screen is focused, we are trying to render a task, and that task
  // is undefined after we attempt to build it (i.e. there is a problem with that task — maybe the account is missing?),
  // redirect to the goal engine.
  // We could maybe split this into two hooks, a useEffect for the task and useFocusEffect for the navigation,
  // but correct behavior would then rely on correct hook ordering (first try to build the task, then redirect if
  // we tried and failed) and would have to communicate using state variables, which seems messy.
  const [taskToRender, setTaskToRender] = React.useState<
  TaskFlowData | undefined
  >()

  React.useLayoutEffect(() => {
    const taskToRenderKey = props.route.params?.taskKey
    if (
      isNonNullish(taskToRenderKey) &&
      // data is only undefined before the first db call.
      data !== undefined
    ) {
      const task = getTaskFlowData(
        data,
        taskToRenderKey.taskTypename,
        taskToRenderKey.taskId,
        props.route.params?.absoluteDollarsToAllocateToTask,
        getCurrentAllocationForTask(data, {
          taskPrimaryKey: taskToRenderKey.taskId,
          taskTypename: taskToRenderKey.taskTypename
        }),
        client,
        props.route.params?.recommendedByWalkthrough
      )
      setTaskToRender(task)
      // Error case where we are told to render a task but get nothing back from getTaskFlowData
      if (task === undefined && getIsFocused()) {
        props.navigation.navigate('TaskScreen', {
          flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL
        })
      }
    }
  }, [
    props.route.params?.taskKey,
    props.route.params?.absoluteDollarsToAllocateToTask,
    data
  ])

  // If TaskToRender is undefined, remove any header. This helps with the case where there used to
  // be a valid task but it becomes invalid.  In this case, having no header is better than having
  // a blank screen that says "your task" or something.  Sub-elements (TaskSteps, TaskIntro, etc)
  // can always override this by adding their own hook (which should run after this one.)
  React.useLayoutEffect(() => {
    if (taskToRender === undefined) {
      props.navigation.setOptions({
        headerTitle: ''
      })
    }
  }, [taskToRender])

  const [mutateFunction] = useMutation<
  WalkthroughGraphQL.UpdateMemberMutation,
  WalkthroughGraphQL.UpdateMemberMutationVariables
  >(gql(WalkthroughGraphQL.updateMember))

  // When we get to the state of "We have a specified task in the params, and we are on the steps page,"
  // consider this the active task and store it.
  React.useEffect(() => {
    if (authOwner !== undefined) {
      if (props.route.params?.flowStage === TaskFlowStage.STEPS) {
        if (
          props.route.params?.taskKey?.taskId !== undefined &&
          props.route.params?.taskKey.taskTypename !== undefined &&
          props.route.params?.absoluteDollarsToAllocateToTask !== undefined
        ) {
          mutateFunction({
            variables: {
              input: {
                owner: authOwner,
                activeTask: {
                  taskPrimaryKey: props.route.params.taskKey.taskId,
                  taskTypename: props.route.params.taskKey.taskTypename
                },
                activeTaskAbsoluteDollarsToAllocate:
                  props.route.params.absoluteDollarsToAllocateToTask,
                activeTaskWasRecommendedByWalkthrough:
                  props.route.params.recommendedByWalkthrough
              }
            }
          }).catch(console.log)
        }
      }
    }
  }, [
    props.route.params?.flowStage,
    props.route.params?.taskKey,
    props.route.params?.absoluteDollarsToAllocateToTask,
    authOwner
  ])

  const style = taskScreenStyle(THEME)
  return (
    <View style={style.tasksScreen}>
      {props.route.params?.flowStage === TaskFlowStage.DETERMINE_NEXT_GOAL ? (
        <DetermineNextTask
          navigation={props.navigation}
          waitForPromise={props.route.params.waitForPromise}
        />
      ) : props.route.params?.flowStage === TaskFlowStage.STEPS &&
        taskToRender !== undefined ? (
        <TaskSteps
          taskSteps={taskToRender.steps}
          navigation={props.navigation}
          taskKeyForBackButton={props.route.params.taskKey}
        />
          ) : props.route.params?.flowStage === TaskFlowStage.INTRO &&
        taskToRender?.introData !== undefined ? (
        <TaskIntro
          taskIntro={taskToRender.introData}
          navigation={props.navigation}
          recommendedByWalkthrough={props.route.params.recommendedByWalkthrough}
        />
              ) : null}
      <View style={style.jungleBackground}>
        <JungleMiddle />
      </View>
    </View>
  )
}

export { TaskScreen }
