import * as React from 'react'
import JungleLeft from '../../../assets/jungle/jungle-left'
import JungleLeft0 from '../../../assets/jungle/jungle-left-0'
import JungleLeft1 from '../../../assets/jungle/jungle-left-1'
import JungleLeft2 from '../../../assets/jungle/jungle-left-2'
import JungleLeft3 from '../../../assets/jungle/jungle-left-3'
import JungleLeft4 from '../../../assets/jungle/jungle-left-4'
import JungleLeft5 from '../../../assets/jungle/jungle-left-5'
import JungleLeft6 from '../../../assets/jungle/jungle-left-6'
import JungleLeft7 from '../../../assets/jungle/jungle-left-7'

function JungleWithIndex (props: { index: number }): JSX.Element {
  function getJungleBackground (index: number): JSX.Element {
    switch (index) {
      case 0:
        return <JungleLeft0 />
      case 1:
        return <JungleLeft1 />
      case 2:
        return <JungleLeft2 />
      case 3:
        return <JungleLeft3 />
      case 4:
        return <JungleLeft4 />
      case 5:
        return <JungleLeft5 />
      case 6:
        return <JungleLeft6 />
      case 7:
        return <JungleLeft7 />
      default:
        return <JungleLeft />
    }
  }

  return getJungleBackground(props.index)
}

export { JungleWithIndex }
