import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish } from 'global-utils'
import { Text } from 'react-native'
import { FadeElementGroup } from '../../determine-next-task/fade-element-groups'
import { differenceInDays, format, isBefore, parseISO } from 'date-fns'
import YuyuBirthdayHat from '../../../../../assets/yuyu/birthday-hat'
import {
  kFirstElementFadeDelay,
  kStandardElementFadeDelay
} from './get-determine-next-task-copy'

export function getAgeBasedContributionLimitsElementGroup (
  data: WalkthroughGraphQL.GetMemberQuery
): FadeElementGroup {
  const birthday = data.getMember?.birthday
  let daysUntilNextBirthday = 0

  if (isNonNullish(birthday)) {
    const nextbirthdayDate = parseISO(birthday)
    const now = new Date(Date.now())
    nextbirthdayDate.setFullYear(now.getFullYear())
    if (isBefore(nextbirthdayDate, now)) {
      nextbirthdayDate.setFullYear(now.getFullYear() + 1)
    }
    daysUntilNextBirthday = differenceInDays(nextbirthdayDate, now)
  }

  return {
    key: 'AGE_CONTRIBUTION_LIMITS',
    elements: [
      {
        key: 'AGE_CONTRIBUTION_LIMITS_BIRTHDAY',
        element: (
          <Text>
            Checking contribution limits using your{' '}
            {isNonNullish(birthday)
              ? format(parseISO(birthday), 'LL/dd/yyyy')
              : ''}{' '}
            birthday…
          </Text>
        ),
        preFadeInDelay: 0
      },
      {
        key: 'AGE_CONTRIBUTION_LIMITS_GLAD',
        element: <Text>We're glad you were born, by the way :)</Text>,
        preFadeInDelay: kFirstElementFadeDelay
      },
      {
        key: 'AGE_CONTRIBUTION_LIMITS_CAKE',
        element: (
          <Text>
            Only{' '}
            {daysUntilNextBirthday > 0
              ? daysUntilNextBirthday
              : 'some mysterious number of'}{' '}
            day{daysUntilNextBirthday === 1 ? '' : 's'} until we forget to send
            you a birthday cake!
          </Text>
        ),
        preFadeInDelay: kStandardElementFadeDelay * 2
      }
    ],
    image: <YuyuBirthdayHat />
  }
}
