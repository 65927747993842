import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const BigCheckMark = (props: SvgProps): JSX.Element => (
<Svg
width={33}
height={33}
fill="none"
{...props}
>
<Path
d="m10 18.8 4.136 4.2L23 11"
stroke="#fff"
strokeWidth={3}
strokeLinecap="round"
/>
</Svg>
)
export default BigCheckMark
