import * as React from 'react'
import { View, Text, TextInput, Pressable } from 'react-native'
import DownArrow from '../../../../assets/down-arrow'
import { THEME } from '../../../../constants'
import { QuestionAndAnswers, ConditionType, AnswerType } from './question-data'
import {
  questionsStyle,
  validationStyle,
  nextButtonStyle
} from './questions.style'
import { Picker } from '@react-native-picker/picker'
import {
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient
} from '@apollo/client'
import { useImmer } from 'use-immer'
import { useDidUpdateEffect } from '../../../../util/use-did-update-effect'
import { useIsMounted } from '../../../../util/use-is-mounted'

function Questions (props: {
  questions: QuestionAndAnswers[]
  buttonText: string
  onSaveState?: () => void
}): JSX.Element {
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const isMounted = useIsMounted()

  // Central state-tracking object of the component
  const [questionState, setQuestionState] = useImmer(initQuestionState())

  // Reset state when the prop updates
  // TODO: consider moving this to useLayoutEffect or custom useDidUpdateLayoutEffect so we don't
  // have temporary invalid flicker on when props.questions changes before questionState updates
  useDidUpdateEffect(() => {
    setQuestionState((draft) => {
      return initQuestionState()
    })
  }, [props.questions])

  // Register all asynchronous question state fetching functions.  This should be done on mount and also any time the
  // questions prop updates
  React.useEffect(() => {
    for (const question of props.questions) {
      for (const answer of question.answers) {
        // Register each data fetcher.
        answer
          .getInitialAnswer(apolloClient)
          .then((response: string) => {
            if (isMounted()) {
              onChangeAnswer(question.key, answer.key, response)
            }
          })
          .catch(console.log)
      }
    }
  }, [props.questions])

  // Helper init function
  function initQuestionState (): Map<string, Map<string, string>> {
    const state = new Map<string, Map<string, string>>()
    for (const question of props.questions) {
      for (const answer of question.answers) {
        const answerMap = state.get(question.key)
        if (answerMap === undefined) {
          state.set(question.key, new Map<string, string>([[answer.key, '']]))
        } else {
          answerMap.set(answer.key, '')
        }
      }
    }
    return state
  }

  // Helper update function
  function onChangeAnswer (
    questionKey: string,
    answerKey: string,
    response: string
  ): void {
    setQuestionState((draft) => {
      draft.get(questionKey)?.set(answerKey, response)
    })
  }

  // Consolidated final button state
  const [buttonDisabled, setButtonDisabled] = React.useState(
    buttonShouldBeDisabled()
  )
  // Recalculate whether a button is disabled every time we update questionState.  Because questionState is React/Immer
  // state, the right way to do this is via useEffect (not via synchronous processing after an update)
  useDidUpdateEffect(() => {
    setButtonDisabled(buttonShouldBeDisabled())
  }, [questionState])

  function buttonShouldBeDisabled (): boolean {
    for (const question of props.questions) {
      if (shouldShowQuestion(question)) {
        for (const answer of question.answers) {
          const currentAnswer = questionState
            .get(question.key)
            ?.get(answer.key)
          if (
            currentAnswer === undefined ||
            !answer.validation.isValid(currentAnswer)
          ) {
            return true
          }
        }
      }
    }
    return false
  }

  function saveFields (): void {
    props.questions.forEach((question) => {
      question.answers.forEach((answer) => {
        // Only store answered questions. When there are conditional questions on a page
        // that the member doesn't need to answer because the conditional questions don't apply
        // to them, we shouldn't try to store those empty answers.
        // Answer values are stored asynchronously for speed.  This does mean that multiple answers
        // mutating the same data may face a race conditions which must be coordinated by the questions
        // objects themselves.  See 401k match rules as a good example.
        const text = questionState.get(question.key)?.get(answer.key)
        if (text !== undefined && text !== '' && shouldShowQuestion(question)) {
          answer.storeValue(apolloClient, text).catch(console.log)
        }
      })
    })
    if (props.onSaveState !== undefined) {
      props.onSaveState()
    }
  }

  // Checks whether the current state for this page has an answer that matches the given
  // key and set of possible matching answers.
  function questionStateHasAnswerMatch (
    answerKeyToMatch: string,
    answersToMatch: string[]
  ): boolean {
    for (const question of props.questions) {
      const answerWithMatchingKey = question.answers.find((answer) => {
        return answer.key === answerKeyToMatch
      })
      if (answerWithMatchingKey !== undefined) {
        const currentAnswer = questionState
          .get(question.key)
          ?.get(answerWithMatchingKey.key)
        return answersToMatch.includes(currentAnswer ?? '')
      }
    }
    return false
  }

  // Whether to show the given question, based on its condition. If there is no
  // given condition, the question will be rendered, otherwise, we'll check the
  // condition to determine whether the question should be rendered.
  function shouldShowQuestion (question: QuestionAndAnswers): boolean {
    if (question.condition !== undefined) {
      switch (question.condition.conditionType) {
        case ConditionType.MATCH_ANSWER:
          if (question.condition.matchAnswerCondition === undefined) {
            throw new Error(
              'MATCH_ANSWER condition type but matchAnswerCondition is undefined.'
            )
          }
          return questionStateHasAnswerMatch(
            question.condition.matchAnswerCondition.answerKeyToMatch,
            question.condition.matchAnswerCondition.answersToMatch
          )
        case ConditionType.UNDEFINED:
        default:
          break
      }
    }
    return true
  }

  const style = questionsStyle(THEME)

  return (
    <View style={style.contentBox}>
      {props.questions.map((question) =>
        shouldShowQuestion(question) ? (
          <View key={question.key} style={style.questionAndAnswers}>
            <Text style={style.question}>{question.question.text}</Text>
            {question.answers.map((answer) => (
              <View key={answer.key}>
                {answer.answerType === AnswerType.TEXT &&
                answer.textAnswer !== undefined ? (
                  <TextInput
                    style={style.shortInput}
                    onChangeText={(text) =>
                      onChangeAnswer(question.key, answer.key, text)
                    }
                    value={
                      questionState.get(question.key)?.get(answer.key) ?? ''
                    }
                    placeholder={answer.textAnswer?.placeholderText}
                    placeholderTextColor={THEME.color.lowLight}
                  />
                    ) : answer.answerType === AnswerType.SELECTION &&
                  answer.selectionAnswer !== undefined ? (
                  <View style={style.shortInput}>
                    <Picker
                      style={style.picker}
                      selectedValue={questionState
                        .get(question.key)
                        ?.get(answer.key)}
                      onValueChange={(itemValue) =>
                        onChangeAnswer(question.key, answer.key, itemValue)
                      }
                    >
                      <Picker.Item
                        key="0"
                        label="Choose an option..."
                        value=""
                        color="#000"
                      />
                      {answer.selectionAnswer?.items.map(
                        ({ displayText, enumValue }, index) => (
                          <Picker.Item
                            key={(index + 1).toString()}
                            label={displayText}
                            value={enumValue}
                            color="#000"
                          />
                        )
                      )}
                    </Picker>
                    <View
                      style={style.pickerDownArrowView}
                      pointerEvents="none"
                    >
                      <DownArrow color={THEME.color.onSurface} />
                    </View>
                  </View>
                        ) : null}
                <View
                  style={
                    validationStyle(
                      THEME,
                      questionState.get(question.key)?.get(answer.key) === '' ||
                        answer.validation.isValid(
                          questionState.get(question.key)?.get(answer.key) ?? ''
                        )
                    ).notValidInfoView
                  }
                >
                  <Text style={style.notValidInfoText}>
                    {answer.validation.notValidInfoText}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        ) // If the question shouldn't be shown, we render nothing. Make sure each step includes at least
        // one question that always renders. (Or update this code to navigate forward if no questions
        // match conditions to render.)
          : null
      )}
      <Pressable
        onPress={saveFields}
        style={nextButtonStyle(THEME, buttonDisabled).button}
        disabled={buttonDisabled}
      >
        <Text style={style.buttonText}>{props.buttonText}</Text>
      </Pressable>
    </View>
  )
}

export { Questions }
