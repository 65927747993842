import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import ArrowCircleFilled from '../../../../assets/arrow-circle-filled'
import CheckedCircle from '../../../../assets/checked-circle'
import { THEME } from '../../../../constants'
import { subtaskScreenStyle } from './subtask.style'
import { SubtaskData } from '../task-flow-data'

function Subtask (props: {
  subtask: SubtaskData
  onOpenDetails: () => void
}): JSX.Element {
  const style = subtaskScreenStyle(THEME)

  return (
    <View style={style.subtaskBox}>
      <Pressable
        style={style.subtaskDetailBox}
        onPress={() => {
          props.onOpenDetails()
        }}
      >
        <Text style={style.title}>{props.subtask.title}</Text>
      </Pressable>
      <Pressable
        onPress={() => {
          if (props.subtask.isComplete) {
            if (props.subtask.storeCompletionState !== undefined) {
              props.subtask.storeCompletionState(false)
            }
          } else {
            props.onOpenDetails()
          }
        }}
      >
        <View style={style.arrowButton}>
          {props.subtask.isComplete ? <CheckedCircle /> : <ArrowCircleFilled />}
        </View>
      </Pressable>
    </View>
  )
}

export { Subtask }
