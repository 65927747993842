import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

const RefreshCircleWithBackground = (props: SvgProps): JSX.Element => (
  <Svg
    width={36}
    height={36}
    fill='none'
    {...props}
  >
    <Circle cx={18} cy={18} r={18} fill='#fff' />
    <Path
      d='M22.571 18A4.571 4.571 0 1 0 18 22.571V26a8 8 0 1 1 8-8h1.5l-3.214 3.5L21 18h1.571Z'
      fill='#FF7991'
    />
  </Svg>
)

export default RefreshCircleWithBackground
