import { StyleSheet, ViewStyle } from 'react-native'
import { Theme } from '../../../../themes/theme'

export const scrollStyle = (
  theme: Theme
): {
  scrollIcon: ViewStyle
} =>
  StyleSheet.create({
    scrollIcon: {
      position: 'absolute',
      bottom: theme.spacing.verticalSpaceSmall,
      alignSelf: 'center'
    }
  })
