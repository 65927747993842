import * as React from 'react'
import {
  Animated,
  Easing,
  Pressable,
  Text,
  View,
  ViewStyle
} from 'react-native'
import { isNonNullish } from 'global-utils'
import SpinArrow from '../../../../assets/spin-arrow'
import Wheel from '../../../../assets/wheel'
import { THEME } from '../../../../constants'
import { textStyle } from '../../../../themes/global-styles.style'
import { Coordinates } from '../../../../util/coordinates'
import { PointsToAddAnimation } from '../points'
import { spinningWheelStyle } from './spinning-wheel.style'

interface WheelSlice {
  key: string
  points: number
  color: string
  style: ViewStyle
}

function getRandomIntInclusive (min: number, max: number): number {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function getRotationDegrees (
  numSlices: number,
  sliceIndexToPointTo: number
): number {
  const kDegreesInCircle = 360
  const degreesPerSlice = kDegreesInCircle / numSlices
  const startDegrees = degreesPerSlice / 2
  return (
    // Add three extra full spins for fun/to make it look more realistic.
    sliceIndexToPointTo * degreesPerSlice + startDegrees + kDegreesInCircle * 3
  )
}

// Get an array of points mapped to colors. The numbers in the wheel follow a
// uniform distribution spanning from 0 to 2x savingsRate.
function getSlices (maxPoints: number): WheelSlice[] {
  return [
    {
      key: 'SLICE_0',
      points: getRandomIntInclusive(0, maxPoints),
      color: THEME.color.rainbow.orange,
      style: spinningWheelStyle.numberZero
    },
    {
      key: 'SLICE_1',
      // Intentionally make one slice always have 0 points.
      points: 0,
      color: THEME.color.rainbow.red,
      style: spinningWheelStyle.numberOne
    },
    {
      key: 'SLICE_2',
      points: getRandomIntInclusive(0, maxPoints),
      color: THEME.color.rainbow.purple,
      style: spinningWheelStyle.numberTwo
    },
    {
      key: 'SLICE_3',
      points: getRandomIntInclusive(0, maxPoints),
      color: THEME.color.rainbow.blue,
      style: spinningWheelStyle.numberThree
    },
    {
      key: 'SLICE_4',
      points: getRandomIntInclusive(0, maxPoints),
      color: THEME.color.rainbow.lightBlue,
      style: spinningWheelStyle.numberFour
    },
    {
      key: 'SLICE_5',
      // Intentionally make one slice always have points equal to the maxPoints.
      points: maxPoints,
      color: THEME.color.rainbow.darkGreen,
      style: spinningWheelStyle.numberFive
    },
    {
      key: 'SLICE_6',
      points: getRandomIntInclusive(0, maxPoints),
      color: THEME.color.rainbow.lightGreen,
      style: spinningWheelStyle.numberSix
    },
    {
      key: 'SLICE_7',
      points: getRandomIntInclusive(0, maxPoints),
      color: THEME.color.rainbow.yellow,
      style: spinningWheelStyle.numberSeven
    }
  ]
}

export function SpinningWheel (props: {
  seedNumber: number
  setPointsToAddAnimation: React.Dispatch<
  React.SetStateAction<PointsToAddAnimation | null>
  >
  setSpinningWheelAnimationComplete: React.Dispatch<
  React.SetStateAction<boolean>
  >
}): JSX.Element {
  // Determines whether to start the spinner & following animations.
  const [startSpin, setStartSpin] = React.useState(false)

  // Store the winning number index and wheel numbers so re-rendering (for example when
  // the points update), doesn't cause the wheel to change it's configuration.
  const [winningNumberIndex, setWinningNumberIndex] = React.useState<
  number | null
  >(null)
  const [wheelSlices, setWheelSlices] = React.useState<WheelSlice[] | null>(
    null
  )

  // We use the bottom of the spinner wheel as the starting coordinates for points to add.
  // We save this value here since we need to use onLayout to get the coordinates on mount &
  // layout changes.
  const [startingCoordinatesPointsToAdd, setStartingCoordinatesPointsToAdd] =
    React.useState<Coordinates | null>(null)

  React.useEffect(() => {
    setWheelSlices(getSlices(props.seedNumber))
  }, [props.seedNumber])

  // Wait until the wheel slices array is set before trying to get the winning index
  // (otherwise it will always be 0).
  React.useEffect(() => {
    if (isNonNullish(wheelSlices)) {
      setWinningNumberIndex(getRandomIntInclusive(0, wheelSlices.length - 1))
    }
  }, [wheelSlices])

  const winningNumber =
    isNonNullish(winningNumberIndex) && isNonNullish(wheelSlices)
      ? wheelSlices[winningNumberIndex].points
      : null
  const winningColor =
    isNonNullish(winningNumberIndex) && isNonNullish(wheelSlices)
      ? wheelSlices[winningNumberIndex].color
      : null
  const winningNumberRotationDegrees =
    isNonNullish(winningNumberIndex) && isNonNullish(wheelSlices)
      ? getRotationDegrees(wheelSlices.length, winningNumberIndex)
      : null

  const arrowRotation = React.useRef(new Animated.Value(0)).current
  const winningNumberfadeOut = React.useRef(new Animated.Value(1)).current

  React.useEffect(() => {
    if (startSpin && isNonNullish(winningNumber)) {
      Animated.sequence([
        Animated.timing(arrowRotation, {
          toValue: 1,
          duration: 3000,
          easing: Easing.ease,
          // NOTE: using useNativeDriver: false for now, since we don't have native apps running.
          // When we do run these on native, we can use useNativeDriver: false, and install the correct
          // RCTAnimation module via Xcode.
          // See https://github.com/facebook/react-native/issues/11094#issuecomment-263240420
          // The warning that was showing up was: "react-native-logs.fx.ts:22 Animated: `useNativeDriver`
          // is not supported because the native animated module is missing. Falling back to JS-based
          // animation. To resolve this, add `RCTAnimation` module to this app, or remove `useNativeDriver`.
          // Make sure to run `pod install` first. Read more about autolinking:
          // https://github.com/react-native-community/cli/blob/master/docs/autolinking.md"
          useNativeDriver: false
        }),
        Animated.timing(winningNumberfadeOut, {
          toValue: 0,
          duration: 1000,
          easing: Easing.ease,
          useNativeDriver: false
        })
      ]).start(() => {
        // This callback runs after the previous animations have finished.
        if (
          isNonNullish(winningNumber) &&
          isNonNullish(winningColor) &&
          isNonNullish(startingCoordinatesPointsToAdd)
        ) {
          props.setPointsToAddAnimation({
            points: winningNumber,
            color: winningColor,
            startingCoordinates: startingCoordinatesPointsToAdd
          })
        }
        props.setSpinningWheelAnimationComplete(true)
      })
    }
  }, [startSpin])

  const rotationDegrees = arrowRotation.interpolate({
    inputRange: [0, 1],
    outputRange: [
      '0deg',
      (isNonNullish(winningNumberRotationDegrees)
        ? winningNumberRotationDegrees.toString()
        : '0') + 'deg'
    ]
  })

  const wheelNumberTextStyle = [
    textStyle.extraLargeText,
    textStyle.boldText,
    textStyle.outlineColorText
  ]

  return (
    <>
      {isNonNullish(winningNumberIndex) && isNonNullish(wheelSlices) ? (
        <View
          style={spinningWheelStyle.spinningWheel}
          // We need to use onLayout to get the bottom of the wheel Y coordinate instead of measure()
          // because measure() doesn't get the value quickly enough. Moreover, we need to use onLayout
          // on the whole savings rate game container element because the y coordinate given is relative
          // to the parent container.)
          onLayout={(event) => {
            setStartingCoordinatesPointsToAdd({
              // X coordinate is 1/3 of the wheel width from the left side of the spinner wheel.
              // Y coordinate is 1/3 of the wheel width from the top of the spinner wheel.
              // This keeps the points to add in the center-ish of the wheel (depends on width of
              // points to add, which this element does not control)
              x:
                event.nativeEvent.layout.x + event.nativeEvent.layout.width / 3,
              y:
                event.nativeEvent.layout.y + event.nativeEvent.layout.height / 3
            })
          }}
        >
          <View style={spinningWheelStyle.spinningWheelComponents}>
            <Wheel />
          </View>
          {wheelSlices.map((slice, index) => (
            <Animated.View
              key={slice.key}
              style={[
                slice.style,
                {
                  opacity:
                    winningNumberIndex === index ? winningNumberfadeOut : 1
                }
              ]}
            >
              <Text style={wheelNumberTextStyle}>
                {wheelSlices[index].points}
              </Text>
            </Animated.View>
          ))}
          <Animated.View
            style={[
              spinningWheelStyle.spinningWheelComponents,
              {
                transform: [
                  { rotate: rotationDegrees },
                  // Without perspective this Animation will not render on Android.
                  // See https://reactnative.dev/docs/animations#bear-in-mind
                  { perspective: 1000 }
                ]
              }
            ]}
          >
            {/* NOTE: this arrow has a box around it that is the same width & height as the wheel, which
                moves the transform origin to the bottom of the arrow/center of the box (instead of the center
                of the arrow). React native doesn't have a transform-origin property, but we could use this
                react-native-anchor-point library if we start getting into more complicated animations.
                https://github.com/sueLan/react-native-anchor-point */}
            <SpinArrow />
          </Animated.View>
          {!startSpin ? (
            <Pressable
              style={spinningWheelStyle.startSpinButton}
              onPress={() => setStartSpin(true)}
            >
              <Text style={[textStyle.largeText, textStyle.boldText]}>
                Spin
              </Text>
            </Pressable>
          ) : null}
        </View>
      ) : null}
    </>
  )
}
