import * as React from "react"
import Svg, { SvgProps, Circle, Path, Ellipse, Rect } from "react-native-svg"

const YuyuWrenchAndWheel = (props: SvgProps): JSX.Element => (
  <Svg
    width={144}
    height={110}
    fill="none"
    {...props}
  >
    <Circle
      cx={98}
      cy={46}
      r={45}
      fill="#202232"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M127.9 46a29.783 29.783 0 0 1-7.367 19.654C115.052 71.933 106.989 75.9 98 75.9c-8.43 0-16.045-3.488-21.48-9.1A29.8 29.8 0 0 1 68.1 46a29.8 29.8 0 0 1 8.42-20.8c5.435-5.612 13.05-9.1 21.48-9.1 8.429 0 16.044 3.488 21.479 9.1A29.799 29.799 0 0 1 127.9 46Z"
      fill="#C8F5FF"
    />
    <Path
      d="M75.802 24.504a31.117 31.117 0 0 1 1.718-1.644A30.778 30.778 0 0 1 98 15.1a30.777 30.777 0 0 1 20.48 7.76 30.753 30.753 0 0 1 1.717 1.644h.001l-.719.696.719-.695h.001v.001h.001v.001h.001v.001h.001c.55.569 1.078 1.158 1.583 1.767A30.761 30.761 0 0 1 128.9 46c0 .8-.031 1.592-.09 2.376a30.747 30.747 0 0 1-7.506 17.916h-.001l-.017.02h-.001l-.752-.657a30.272 30.272 0 0 1-1.579 1.674A29.8 29.8 0 0 1 98 75.9a29.777 29.777 0 0 1-19.816-7.51 30.023 30.023 0 0 1-1.664-1.59l-.718-42.296Zm0 0-.004.004m.004-.004-.005.005m0 0a31.078 31.078 0 0 0-1.583 1.766A30.765 30.765 0 0 0 67.1 46a30.765 30.765 0 0 0 7.114 19.725l.77-.638.813-40.578ZM94.7 38.21 83 26.88a24.156 24.156 0 0 1 11.7-4.96v16.29ZM73.92 42.7a24.142 24.142 0 0 1 4.486-11.079L89.849 42.7H73.921Zm15.927 6.6-11.44 11.079A24.142 24.142 0 0 1 73.92 49.3h15.927ZM94.7 70.078A24.158 24.158 0 0 1 83 65.12l11.7-11.33v16.29Zm6.6-16.82 12.674 11.054a24.182 24.182 0 0 1-12.674 5.766v-16.82Zm20.778-3.958a24.136 24.136 0 0 1-3.763 10.04L106.803 49.3h15.275Zm-15.927-6.6 11.441-11.079a24.15 24.15 0 0 1 4.486 11.079h-15.927Zm-4.851-4.49V21.923A24.158 24.158 0 0 1 113 26.88l-11.7 11.33Z"
      fill="#202232"
      stroke="#505050"
      strokeWidth={2}
    />
    <Circle cx={97.999} cy={46} r={3.833} fill="#505050" />
    <Path
      d="M39 93.493h-.894c-5.067.001-9.847.003-14.21-.331-4.62-.354-8.696-1.079-12.078-2.534-3.359-1.445-6.032-3.609-7.883-6.876C2.073 80.466 1 75.985 1 69.84 1 57.58 5.27 48.405 12.09 42.284 18.929 36.148 28.423 33 39 33s20.072 3.148 26.91 9.284C72.73 48.404 77 57.58 77 69.84c0 6.145-1.073 10.626-2.935 13.912-1.85 3.267-4.524 5.431-7.883 6.876-3.382 1.455-7.458 2.18-12.078 2.534-4.363.334-9.143.332-14.21.331H39Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={26.382} cy={74.507} rx={2.294} ry={2.293} fill="#505050" />
    <Ellipse cx={52.765} cy={74.507} rx={2.294} ry={2.293} fill="#505050" />
    <Path
      d="M35.559 79.093C38.5 79 39 79 42.44 79.093"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M44.162 41.827c-5.506 5.045-10.706 2.102-12.618 0C26.497 37.699 32.882 17.556 36.706 8c4.78 9.173 12.962 28.781 7.456 33.827ZM55.002 40.555c-2.632 1.21-4.203-.492-4.66-1.495-1.374-2.123 3.373-8.805 5.919-11.88.677 3.954 1.373 12.164-1.259 13.375ZM20.748 40.555c2.632 1.21 4.203-.492 4.66-1.495 1.374-2.123-3.373-8.805-5.919-11.88-.677 3.954-1.373 12.164 1.259 13.375Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Rect
      x={1.414}
      y={-0.04}
      width={5.915}
      height={26.143}
      rx={2.958}
      transform="scale(1 -1) rotate(-43.391 -97.674 -101.996)"
      fill="#8E8E8E"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M59.844 76.73c.038-4.35 5.008-7.218 7.175-7.59l1.917 1.813-4.53 4.793c-.59.624-.09 2.187.55 2.791.639.604 1.818 1.032 2.645.23 1.973-1.915 4.53-4.793 4.53-4.793l1.918 1.812c-.48 1.361-1.512 6.892-5.846 7.636-1.818.312-3.908-.519-5.186-1.727-1.278-1.208-3.195-2.43-3.173-4.965Z"
      fill="#8E8E8E"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.795 105.125a6.596 6.596 0 1 0 9.063-9.588 6.596 6.596 0 0 0-9.063 9.588Zm2.416-2.557a3.077 3.077 0 1 0 4.23-4.471 3.077 3.077 0 0 0-4.23 4.471Z"
      fill="#8E8E8E"
    />
    <Path
      d="M48.393 104.176a5.596 5.596 0 0 1-7.91.222l-1.375 1.453a7.596 7.596 0 0 0 10.738-.301l-1.453-1.374Zm-.222-7.91a5.596 5.596 0 0 1 .222 7.91l1.453 1.374a7.596 7.596 0 0 0-.301-10.738l-1.374 1.453Zm-7.911.221a5.596 5.596 0 0 1 7.91-.222l1.375-1.453a7.596 7.596 0 0 0-10.738.301l1.453 1.374Zm.222 7.911a5.596 5.596 0 0 1-.222-7.91l-1.453-1.375a7.595 7.595 0 0 0 .301 10.738l1.374-1.453Zm5.354-2.639a2.079 2.079 0 0 1-2.938.083l-1.374 1.453a4.078 4.078 0 0 0 5.765-.162l-1.453-1.374Zm-.083-2.938c.834.789.871 2.104.083 2.938l1.453 1.374a4.078 4.078 0 0 0-.162-5.765l-1.374 1.454Zm-2.937.083a2.078 2.078 0 0 1 2.937-.082l1.374-1.454a4.078 4.078 0 0 0-5.765.162l1.454 1.374Zm.082 2.938a2.078 2.078 0 0 1-.082-2.938l-1.454-1.374a4.078 4.078 0 0 0 .162 5.765l1.374-1.453Z"
      fill="#505050"
    />
  </Svg>
)

export default YuyuWrenchAndWheel
