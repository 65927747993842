import { StyleSheet } from 'react-native'
import { THEME } from '../../constants'

export const style = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: THEME.color.background
  },
  bannerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingBottom: THEME.spacing.verticalSpaceSmall,
    backgroundColor: THEME.color.rainbow.purple
  },
  bannerIconContainer: {
    height: '100%',
    justifyContent: 'center',
    paddingLeft: THEME.spacing.horizontalSpaceMedium
  },
  bannerTextContainer: {
    flex: 1,
    flexWrap: 'wrap',
    marginLeft: THEME.spacing.horizontalSpaceSmall,
    marginRight: THEME.spacing.horizontalSpaceMedium
  },
  aiQueryAndResponseScrollContainer: {
    width: '100%',
    paddingHorizontal: THEME.spacing.horizontalSpaceMedium,
    marginTop: THEME.spacing.verticalSpaceSmall,
    marginBottom: THEME.spacing.verticalSpaceLarge
  },
  aiQueryContainer: {
    width: '100%',
    borderRadius: 10,
    borderWidth: 2,
    borderColor: THEME.color.outline,
    backgroundColor: THEME.color.surfaceOne,
    padding: THEME.spacing.horizontalSpaceMedium,
    flexDirection: 'row'
  },
  loadingContainer: {
    marginTop: THEME.spacing.verticalSpaceMedium,
    backgroundColor: THEME.color.rainbow.darkBlue,
    borderWidth: 2,
    borderColor: THEME.color.outline,
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  reponseContainer: {
    width: '100%',
    borderRadius: 10,
    borderWidth: 2,
    borderColor: THEME.color.outline,
    backgroundColor: THEME.color.rainbow.darkBlue,
    padding: THEME.spacing.horizontalSpaceMedium,
    marginTop: THEME.spacing.verticalSpaceSmall
  },
  flagContainer: {
    alignItems: 'flex-end',
    width: '100%',
    marginTop: THEME.spacing.verticalSpaceSmall
  },
  thankYouContainer: {
    width: '100%',
    borderRadius: 10,
    borderWidth: 2,
    borderColor: THEME.color.outline,
    backgroundColor: THEME.color.rainbow.purple,
    padding: THEME.spacing.horizontalSpaceMedium,
    marginTop: THEME.spacing.verticalSpaceMedium,
    justifyContent: 'center'
  },
  privacyContainer: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: THEME.color.overlay,
    padding: THEME.spacing.horizontalSpaceMedium,
    marginTop: THEME.spacing.verticalSpaceSmall
  }
})
