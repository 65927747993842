import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish } from 'global-utils'

// Get the member's current savings rate, using their estimated monthly income and spending.
// TODO: known issue with SR definition w.r.t. 401k **match** (it isn't explicitly included), but this makes the estimate overly
// conservative (i.e. people can actually retire even sooner) and it is clearer to explain. Rhetorically we're happy,
// and the member should be pleasantly surprised with reality. Note that (savings / (savings + expenses)) is likely
// overly optimistic because people will likely add in their 401k savings/match even if it's pre-tax. If your 401k +
// match is your primary savings vehicle, this could seriously alter the calculation.
// TODO: known issue with SR definition w.r.t. 401k **contributions.** These are generally not included in the
// estimatedAverageMonthlyAfterTaxIncome field (see the user-facing question for specifics) but should be factored
// into savings after some tax correction factor (currently hard to calculate).  We could add back in average
// contributions as declared via transaction aggregations.
// https://www.choosefi.com/how-to-calculate-your-savings-rate/ is a good article here,  method 4 is technically best,
// 3 is okay but it gets a little wonky if you have a lot of pretax savings (rate nears 1?), esp bad if you later
// have 'high' tax rate.
// See https://docs.google.com/document/d/1FxSpa_wthTG3tyniMkLFNv4UBFK-I973AGfPtMcTmWc/edit#heading=h.61tx9gro8kxa for
// more discussion of savings rate algorithms.
// NOTE: This function could use WalkthroughGraphQL.MemberOnboardingDataQuery instead of
// WalkthroughGraphQL.MemberLatestFinancialViewQuery, but the places it's being used currently
// already use WalkthroughGraphQL.MemberLatestFinancialViewQuery, to get the member's net worth,
// so it makes sense to reuse that query for savings rate.
export function getSavingsRate (
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
): number | null {
  const monthlyAfterTaxIncome =
    memberQuery?.getMember?.estimatedAverageMonthlyAfterTaxIncome
  const monthlySpending =
    memberQuery?.getMember?.estimatedAverageMonthlySpending

  // monthlyAfterTaxIncome and monthlySpending should both be numbers >= 0.
  // If monthlyAfterTaxIncome is 0, we get -Infinity savingsRate, which is what we want to show,
  // so we allow division by 0. We don't allow 0/0 = NaN, because NaN is not easy to interpret as
  // a savings rate.
  if (
    isNonNullish(monthlyAfterTaxIncome) &&
    isNonNullish(monthlySpending) &&
    monthlyAfterTaxIncome >= 0 &&
    monthlySpending >= 0 &&
    !(monthlyAfterTaxIncome === 0 && monthlySpending === 0)
  ) {
    return (monthlyAfterTaxIncome - monthlySpending) / monthlyAfterTaxIncome
  }
  return null
}
