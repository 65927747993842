import * as React from "react"
import Svg, { SvgProps, Path, Ellipse, Rect, Mask } from "react-native-svg"

const YuyuSnack = (props: SvgProps): JSX.Element => (
  <Svg
    width={126}
    height={125}
    fill="none"
    {...props}
  >
    <Path
      d="M39 88.493h-.894c-5.067.001-9.847.003-14.21-.331-4.62-.354-8.696-1.079-12.078-2.534-3.359-1.445-6.032-3.609-7.883-6.876C2.073 75.466 1 70.985 1 64.84 1 52.58 5.27 43.405 12.09 37.284 18.929 31.148 28.423 28 39 28s20.072 3.148 26.91 9.284C72.73 43.404 77 52.58 77 64.84c0 6.145-1.073 10.626-2.935 13.912-1.85 3.267-4.524 5.431-7.883 6.876-3.382 1.455-7.458 2.18-12.078 2.534-4.363.334-9.143.332-14.21.331H39Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={26.382} cy={69.507} rx={2.294} ry={2.293} fill="#505050" />
    <Path
      d="M55.059 69.507c0 1.266-1.027 0-2.294 0-1.267 0-2.294 1.266-2.294 0a2.294 2.294 0 0 1 4.588 0Z"
      fill="#505050"
    />
    <Path
      d="M35.559 74.093c.934.865 3.62 2.077 6.882 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M44.162 36.827c-5.506 5.045-10.706 2.102-12.618 0C26.497 32.699 32.882 12.556 36.706 3c4.78 9.173 12.962 28.781 7.456 33.827ZM55.002 35.555c-2.632 1.21-4.203-.492-4.66-1.495-1.374-2.123 3.373-8.805 5.919-11.88.677 3.954 1.373 12.164-1.259 13.375ZM20.748 35.555c2.632 1.21 4.203-.492 4.66-1.495 1.374-2.123-3.373-8.805-5.919-11.88-.677 3.954-1.373 12.164 1.259 13.375Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Rect
      x={64.287}
      y={122.996}
      width={42}
      height={65}
      rx={9}
      transform="rotate(-112.697 64.287 122.996)"
      fill="#FF7272"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={65.744}
      y={118.705}
      width={40.603}
      height={62.038}
      rx={9}
      transform="rotate(-112.697 65.744 118.705)"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-1.308}
      y={-0.537}
      width={13}
      height={34}
      rx={6.5}
      transform="scale(-1 1) rotate(-67.303 1.422 118.252)"
      fill="#FFAFEA"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-1.308}
      y={-0.537}
      width={11}
      height={11}
      rx={5.5}
      transform="scale(-1 1) rotate(-67.303 15.944 107.502)"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={0.537}
      y={-1.308}
      width={16}
      height={33}
      rx={8}
      transform="scale(1 -1) rotate(22.697 314.631 110.602)"
      fill="#91E5E5"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={0.537}
      y={-1.308}
      width={15}
      height={17}
      rx={7.5}
      transform="scale(1 -1) rotate(22.697 305.13 157.938)"
      fill="#91E5E5"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={0.537}
      y={-1.308}
      width={15}
      height={17}
      rx={7.5}
      transform="scale(1 -1) rotate(22.697 295.63 205.274)"
      fill="#91E5E5"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M62.742 87.47c1.487 1.704 4.228 1.319 4.296 1.765l.025-.032c1.824-2.417 3.52-4.664 2.146-5.863-.553-.481-.91.38-1.82.761-.606.254-.93-1.035-2.023-.578-.91.38-.958 1.113-.578 2.023.38.91-4.095-.425-2.046 1.924Z"
      fill="#C3EC81"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Mask id="a" fill="#fff">
      <Path d="M75.56 106.692c.216 1.392-1.533 2.123-2.373.993-2.995-4.033-7.16-9.791-7.93-11.633-1.227-2.933-.522-6.02 1.574-6.898 2.097-.877 4.79.79 6.017 3.723.77 1.841 1.945 8.851 2.713 13.815Z" />
    </Mask>
    <Path
      d="M75.56 106.692c.216 1.392-1.533 2.123-2.373.993-2.995-4.033-7.16-9.791-7.93-11.633-1.227-2.933-.522-6.02 1.574-6.898 2.097-.877 4.79.79 6.017 3.723.77 1.841 1.945 8.851 2.713 13.815Z"
      fill="#FFB672"
    />
    <Path
      d="m72.848 92.877-1.845.772 1.845-.772Zm2.713 13.815-1.977.306 1.977-.306Zm-2.374.993-1.605 1.193 1.606-1.193Zm-2.184-14.036c.12.288.307.922.538 1.917.22.954.458 2.127.698 3.415.48 2.573.962 5.543 1.345 8.017l3.953-.611c-.385-2.49-.874-5.507-1.366-8.14a87.635 87.635 0 0 0-.734-3.584c-.23-.993-.48-1.925-.744-2.558l-3.69 1.544Zm-3.4-2.65c.298-.124.811-.144 1.51.289.696.43 1.42 1.235 1.89 2.36l3.69-1.543c-.755-1.807-1.992-3.302-3.475-4.219-1.478-.914-3.36-1.329-5.159-.577L67.603 91Zm-.502 4.282c-.47-1.126-.536-2.206-.354-3.004.183-.801.558-1.153.856-1.278l-1.544-3.69c-1.798.752-2.824 2.383-3.211 4.078-.388 1.7-.193 3.63.563 5.437l3.69-1.543Zm-3.69 1.543c.265.633.753 1.465 1.298 2.326.568.896 1.272 1.94 2.037 3.039a280.064 280.064 0 0 0 4.836 6.689l3.211-2.385a275.31 275.31 0 0 1-4.764-6.588 83.589 83.589 0 0 1-1.94-2.895c-.546-.863-.867-1.441-.988-1.73l-3.69 1.544Zm10.173 10.174c-.104-.671.804-1.051 1.21-.505l-3.212 2.385c2.084 2.805 6.49.962 5.955-2.491l-3.953.611Z"
      fill="#505050"
      mask="url(#a)"
    />
    <Path
      d="M59.705 88.74c1.487 1.704 4.228 1.319 4.296 1.765l.025-.032c1.824-2.417 3.52-4.665 2.146-5.863-.553-.481-.91.38-1.82.761-.606.254-.93-1.035-2.023-.578-.91.38-.959 1.113-.578 2.022.38.91-4.095-.424-2.046 1.925Z"
      fill="#C3EC81"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Mask id="b" fill="#fff">
      <Path d="M72.524 107.962c.215 1.392-1.534 2.123-2.374.993-2.995-4.033-7.16-9.792-7.93-11.633-1.227-2.933-.522-6.021 1.574-6.898 2.097-.877 4.79.79 6.017 3.723.77 1.841 1.945 8.851 2.713 13.815Z" />
    </Mask>
    <Path
      d="M72.524 107.962c.215 1.392-1.534 2.123-2.374.993-2.995-4.033-7.16-9.792-7.93-11.633-1.227-2.933-.522-6.021 1.574-6.898 2.097-.877 4.79.79 6.017 3.723.77 1.841 1.945 8.851 2.713 13.815Z"
      fill="#FFB672"
    />
    <Path
      d="m69.811 94.147-1.845.772 1.845-.772Zm2.713 13.815-1.977.306 1.977-.306Zm-2.374.993-1.605 1.192 1.605-1.192Zm-2.184-14.036c.12.288.307.922.538 1.917.22.954.458 2.127.698 3.414.48 2.574.962 5.544 1.345 8.018l3.953-.611c-.385-2.49-.874-5.507-1.366-8.14a87.647 87.647 0 0 0-.734-3.584c-.23-.993-.48-1.925-.744-2.558l-3.69 1.544Zm-3.4-2.65c.298-.125.811-.144 1.51.289.696.43 1.42 1.235 1.89 2.36l3.69-1.543c-.756-1.807-1.993-3.302-3.475-4.219-1.478-.915-3.36-1.329-5.159-.577l1.544 3.69Zm-.502 4.282c-.47-1.126-.536-2.206-.354-3.004.183-.801.557-1.153.856-1.278l-1.544-3.69c-1.798.752-2.825 2.383-3.211 4.078-.388 1.7-.193 3.63.563 5.437l3.69-1.543Zm-3.69 1.543c.265.633.753 1.465 1.298 2.325a87.063 87.063 0 0 0 2.037 3.04 277.96 277.96 0 0 0 4.836 6.688l3.211-2.385a275.614 275.614 0 0 1-4.764-6.587 84.416 84.416 0 0 1-1.94-2.895c-.546-.863-.867-1.441-.988-1.73l-3.69 1.544Zm10.173 10.174c-.104-.671.804-1.051 1.209-.506l-3.211 2.385c2.084 2.806 6.49.963 5.955-2.49l-3.953.611Z"
      fill="#505050"
      mask="url(#b)"
    />
    <Path
      d="M91.8 101.478a.948.948 0 0 1-.183.194 3.212 3.212 0 0 1-.821.481c-.335.14-.663.224-.919.247a.922.922 0 0 1-.267-.006c-1.507-1.153-3.277-2.547-4.734-3.804a30.554 30.554 0 0 1-1.861-1.72c-.522-.537-.75-.858-.798-.974-.23-.549-.129-1.357.572-2.313.687-.938 1.86-1.866 3.377-2.5 1.516-.635 3-.818 4.151-.649 1.173.173 1.819.669 2.048 1.217.049.116.118.504.133 1.252.014.694-.018 1.564-.082 2.533-.128 1.92-.378 4.16-.615 6.042Z"
      fill="#FF7272"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-1.308}
      y={-0.537}
      width={9}
      height={9}
      rx={4.5}
      transform="scale(-1 1) rotate(-67.303 9.687 109.5)"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-1.308}
      y={-0.537}
      width={10}
      height={10}
      rx={5}
      transform="scale(-1 1) rotate(-67.303 3.175 116.002)"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-1.308}
      y={-0.537}
      width={5}
      height={5}
      rx={2.5}
      transform="scale(-1 1) rotate(-67.303 8.675 124.264)"
      fill="#75B7E7"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-1.308}
      y={-0.537}
      width={5}
      height={5}
      rx={2.5}
      transform="scale(-1 1) rotate(-67.303 12.431 121.763)"
      fill="#75B7E7"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-1.308}
      y={-0.537}
      width={5}
      height={5}
      rx={2.5}
      transform="scale(-1 1) rotate(-67.303 12.677 127.02)"
      fill="#75B7E7"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-1.308}
      y={-0.537}
      width={5}
      height={5}
      rx={2.5}
      transform="scale(-1 1) rotate(-67.303 15.428 129.524)"
      fill="#75B7E7"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-1.308}
      y={-0.537}
      width={5}
      height={5}
      rx={2.5}
      transform="scale(-1 1) rotate(-67.303 15.682 125.02)"
      fill="#75B7E7"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-1.308}
      y={-0.537}
      width={5}
      height={5}
      rx={2.5}
      transform="scale(-1 1) rotate(-67.303 8.922 129.52)"
      fill="#75B7E7"
      stroke="#505050"
      strokeWidth={2}
    />
  </Svg>
)

export default YuyuSnack
