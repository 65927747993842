import {
  TaskFlowData,
  TaskFlowStage,
  TaskStepsRenderingStyle
} from '../task-flow-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { TaskFlowDataConverter } from './get-task-flow-data'
import { gql } from '@apollo/client'
import { extractBooleanFromJsonMap } from 'global-utils'
import { Anchor } from '../../common/links/anchor'
import { NavigationAnchor } from '../../common/links/navigation-anchor'
import { ContentModule } from '../../click-through-module/click-through-module-screen'
import { getTaskTitle } from './get-task-title'

const intro =
  'Emergencies happen. They can be rough.\n\nHaving an emergency fund makes it much easier to handle them. ' +
  "This cash makes sure that an emergency (unexpected car crash?) doesn't immediately spiral into something worse " +
  '(like losing your job or missing rent).\n\nBuild your personal safety net:'

// TODO: add dynamic amount?
const steps =
  'Your initial emergency fund should be one month of living expenses or $1,000, whichever is more, and ' +
  "should be kept in a savings account. It's important to have that much on hand just in case an emergency pops up."

const whenToSpendKey = 'WHEN_TO_SPEND'
const whenToSpend =
  "If all goes well, you'll build up your one-month emergency fund and never have to use it. " +
  "It's meant to cover REALLY big emergencies — things like keeping you and your family HOUSED and ALIVE. Here's a " +
  'little quiz (with answers, because we like you):\n\nUnexpected injury? — EMERGENCY\n\n40% off at Lululemon 🛍️? — ' +
  "NOT EMERGENCY\n\nPaying rent after losing your job? — EMERGENCY\n\nCar Maintenance? — MAYBE, if it's actually " +
  'unexpected and stops you from getting to work. Cars break down, though, so replacing break pads probably ' +
  "shouldn't come as a surprise."

const chooseAccountKey = 'CHOOSE_ACCOUNT'
const chooseAccount = (
  <>
    It's harder to accidentally spend your emergency fund when you don't see it
    every day. A couple good ways to protect your fund from yourself are:
    {'\n\n'}• Set up a separate "bucket" or "vault" in your account (if your
    bank has this feature) to hold your emergency fund.{'\n\n'}• Choose an
    account you don't normally use.
    {'\n\n'}• Open a new dedicated account.{'\n\n'}
    Choose one of these approaches for your fund, then move on to the next step.
    {'\n\n'}If you decide to open a new checkings or savings account, we
    recommend opening it with{' '}
    <Anchor
      text="Ally Bank"
      href="https://www.ally.com/bank/online-savings-account/"
    />{' '}
    (
    <NavigationAnchor
      text="no kickbacks"
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.NO_KICKBACKS
        })
      }
    />
    !). They are free, make it easy to bucket your money, and never charge
    overdraft fees.
  </>
)

const fundItKey = 'FUND_IT'
const fundIt =
  'If you already have one month of expenses (or $1000, whichever is greater), transfer it into your ' +
  "fund. Yay!\n\nIf you don't have the money yet, start transferring as much as you can every month into your " +
  "fund — maybe 50 or 100 dollars?\n\nIf you set up auto-transfers from your standard account, you'll do the right " +
  "thing without ever having to think about it again (well, don't forget to turn them off when you're all done 😉)."

const forgetAboutItKey = 'FORGET_ABOUT_IT'
const forgetAboutIt =
  "Yay! You should feel great that you're saving money to protect yourself. 38% of Americans " +
  "can't cover a $500 emergency, but you can!\n\nOkay, celebration over — time to forget " +
  "about this fund so you don't accidentally spend it. On to the next task!\n\nRemember that if you ever draw down " +
  'this fund, you need to replenish it as soon as the emergency is dealt with.'

export const buildEmergencyFundTaskToFlowData: TaskFlowDataConverter<
WalkthroughGraphQL.BuildOneMonthEmergencyFundTask
> =
  (
    taskTypename,
    taskId,
    absoluteDollarsToAllocateToTask,
    currentlyAllocatedDollars,
    client,
    recommendedByWalkthrough
  ) =>
    (task) => {
      if (task === null) {
        return null
      }
      if (task.__typename !== taskTypename) {
        return null
      }
      if (task.owner !== taskId) {
        return null
      }

      // Note that we refer to the JS object (not the JSON string!) when writing this back to the DB. This means that each
      // time the object passes through the FE it is revalidated for JSON parseability (although we still lack enforcement
      // that it conforms to a {[key: string]: boolean} type)
      const subtaskCompletionState: any = JSON.parse(
        task.subtaskCompletion ?? '{}'
      )

      // Generate a storage function to associate with a subtask.
      // We define it as an arrow function so it is interpreted within the control flow (needed for the compiler to infer
      // that task is not null). See https://github.com/microsoft/TypeScript/issues/32339 and 32300 for more.
      const storeCompletionGenerator = (key: string) => {
        return (complete: boolean) => {
          client
            .mutate<
          WalkthroughGraphQL.UpdateBuildOneMonthEmergencyFundTaskMutation,
          WalkthroughGraphQL.UpdateBuildOneMonthEmergencyFundTaskMutationVariables
          >({
            mutation: gql(
              WalkthroughGraphQL.updateBuildOneMonthEmergencyFundTask
            ),
            variables: {
              input: {
                owner: task.owner,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            },
            optimisticResponse: {
              updateBuildOneMonthEmergencyFundTask: {
                ...task,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            }
          })
            .catch(console.log)
        }
      }

      const flowData: TaskFlowData = {
        steps: {
          stepRenderingStyle: TaskStepsRenderingStyle.CHECKLIST,
          title: getTaskTitle(task.__typename),
          description: steps,
          subtasks: [
            {
              key: whenToSpendKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                whenToSpendKey
              ),
              storeCompletionState: storeCompletionGenerator(whenToSpendKey),
              title: 'What is an emergency fund for?',
              description: whenToSpend
            },
            {
              key: chooseAccountKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                chooseAccountKey
              ),
              storeCompletionState: storeCompletionGenerator(chooseAccountKey),
              title: 'Designate an emergency fund account',
              description: chooseAccount
            },
            {
              key: fundItKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                fundItKey
              ),
              storeCompletionState: storeCompletionGenerator(fundItKey),
              title: 'Fund da fund',
              description: fundIt
            },
            // TODO: earmark selection?
            {
              key: forgetAboutItKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                forgetAboutItKey
              ),
              storeCompletionState: storeCompletionGenerator(forgetAboutItKey),
              title: 'Celebrate! Then forget it',
              description: forgetAboutIt
            }
          ],
          button: {
            buttonText: 'Next',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              const response = client.mutate<
              WalkthroughGraphQL.UpdateBuildOneMonthEmergencyFundTaskMutation,
              WalkthroughGraphQL.UpdateBuildOneMonthEmergencyFundTaskMutationVariables
              >({
                mutation: gql(
                  WalkthroughGraphQL.updateBuildOneMonthEmergencyFundTask
                ),
                variables: {
                  input: {
                    owner: task.owner,
                    isComplete: true
                  }
                }
              })
              props.navigation.navigate('TaskScreen', {
                flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL,
                waitForPromise: response
              })
            }
          }
        },
        introData: {
          title: getTaskTitle(task.__typename),
          introContent: intro,
          button: {
            buttonText: 'Start your emergency fund',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskScreen', {
                taskKey: {
                  taskTypename: task.__typename,
                  taskId: task.owner
                },
                flowStage: TaskFlowStage.STEPS,
                absoluteDollarsToAllocateToTask: absoluteDollarsToAllocateToTask,
                recommendedByWalkthrough: recommendedByWalkthrough
              })
            }
          },
          alternativeButton: {
            buttonText: 'Change tasks',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskListScreen')
            }
          }
        }
      }
      return flowData
    }
