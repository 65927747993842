// Returns true if the given string is an integer, false otherwise.
export function stringParsesToUnsignedInteger (maybeInteger: string): boolean {
  // Regex that matchs the beginning of the string, 1+ digits, and the end of the string
  const integerRegex = /^\d+$/
  return integerRegex.test(maybeInteger)
}

export function stringParsesToUnsignedFloat (maybeFloat: string): boolean {
  const floatRegex = /^([0-9]*[.])?[0-9]+$/
  return floatRegex.test(maybeFloat)
}

// Whether the given string can be parsed as a number representing a percent between
// 0% and 100% (inclusive). Allows for a single % character at the end of the string.
export function isPercentString (maybePercent: string): boolean {
  // Remove % if it's the last character in the value string.
  const answerToValidate = maybePercent.replace(/[%]$/g, '')
  return (
    stringParsesToUnsignedFloat(answerToValidate) &&
    Number(answerToValidate) >= 0 &&
    Number(answerToValidate) <= 100
  )
}

// Extracts the fractional representation of the given percent string. percent MUST BE
// validated as a percent string using isPercentString before calling this function.
export function getFractionFromPercentString (percent: string): number {
  return parseFloat(percent.replace(/[%]$/g, '')) / 100
}

// Whether the given string can be parsed as a whole number representing a dollar amount.
// Required format is XXXX with an optional prefixed dollar sign.
// Parsed dollars must be nonnegative.
export function isWholeDollarString (maybeDollars: string): boolean {
  const answerToValidate = maybeDollars.replace(/^[$]/g, '')
  return (
    stringParsesToUnsignedInteger(answerToValidate) &&
    Number(answerToValidate) >= 0
  )
}

// Extracts the number representation of the given dollars string. dollars MUST BE
// validated as a dollar string using isDollarString before calling this function.
export function getNumberFromDollarString (dollars: string): number {
  return parseFloat(dollars.replace(/^[$]/g, ''))
}

export function stringIsWordCharsOrSpacesAndLessThan31Chars (
  s: string
): boolean {
  return /^[ \w]{1,30}$/.test(s)
}

export function stringIsCapitalLettersOrNumbersAndLessThan31Chars (
  s: string
): boolean {
  return /^[A-Z0-9]{1,30}$/.test(s)
}
