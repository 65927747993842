"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.contributeToIraTaskCurrentAllocationHandler = void 0;
const WalkthroughGraphQL = __importStar(require("amplify-client-graphql"));
const get_current_allocation_for_task_1 = require("./get-current-allocation-for-task");
// Note that this handler has nothing to do with the goal engine.  It is dealing entirely with app concepts.
// It is up to the app <-> goal engine interface to ensure that representations and semantics between the two
// packages (e.g. what does "allocated" mean and whether/how it is represented with TransactionAggregations).
function getRelevantIraTransactionAggregationTypesFromContributionYear(contributionYear) {
    switch (contributionYear) {
        case 2022:
            return new Set([
                WalkthroughGraphQL.TransactionAggregationType
                    .ROTH_IRA_CONTRIBUTIONS_2022,
                WalkthroughGraphQL.TransactionAggregationType
                    .TRADITIONAL_IRA_PRETAX_CONTRIBUTIONS_2022
            ]);
        case 2023:
            return new Set([
                WalkthroughGraphQL.TransactionAggregationType
                    .ROTH_IRA_CONTRIBUTIONS_2023,
                WalkthroughGraphQL.TransactionAggregationType
                    .TRADITIONAL_IRA_PRETAX_CONTRIBUTIONS_2023
            ]);
        default:
            throw new Error('In getRelevantIraTransactionAggregationTypesFromContributionYear, encountered unconfigured contribution year: ' +
                contributionYear.toString());
    }
}
const contributeToIraTaskCurrentAllocationHandler = (taskKey, memberQuery) => (task) => {
    if (task === null) {
        return null;
    }
    if (task.__typename !== taskKey.taskTypename) {
        return null;
    }
    if (task.id !== taskKey.taskPrimaryKey) {
        return null;
    }
    const relevantAggregationTypes = getRelevantIraTransactionAggregationTypesFromContributionYear(task.contributionYear);
    let totalAllocated = 0;
    relevantAggregationTypes.forEach((t) => {
        totalAllocated += (0, get_current_allocation_for_task_1.sumAllTransactionAggregationsOfType)(memberQuery, t);
    });
    return totalAllocated;
};
exports.contributeToIraTaskCurrentAllocationHandler = contributeToIraTaskCurrentAllocationHandler;
