import { useIsFocused } from '@react-navigation/native'
import { useCallback, useEffect, useRef } from 'react'

// Similar to React-Navigation's useIsFocused() hook, except returns a reference-stable getter
// that then returns the isFocused boolean.  useIsFocused() returns a raw boolean, which makes it unsuitable
// for delayed uses in useEffect (see e.g. https://dmitripavlutin.com/react-hooks-stale-closures/)
// Here's an example:
//
// const isFocused = useIsFocused()
// const getIsFocused = useGetIsFocused()
// useEffect(() => {
//   setTimeout(() => {
//     console.log('5s after triggering, isFocused is: ' + isFocused.toString())
//     console.log('5s after triggering, getIsFocused evaluates to: ' + getIsFocused().toString())
//   }, 5000)
// }, [isFocused, getIsFocused])
//
// Imagine that the component is focused and then unfocused after one second.  Two things happen:
// 1) Because isFocused changes, the function will be reevaluated when the component loses focus, which
//    may not have been desired. Because getIsFocused() is reference-stable, it will never cause the hook to
//    be retriggered (and in fact can probably be safely excluded from the dependencies).
// 2) Because useEffect creates its closure at call time, the first logging line will always log "true" even
//    if the component is not focused at the time the console.log() line runs.  getIsFocused() will return the
//    value of isFocused at execution time, which is probably what you wanted to know.
export function useGetIsFocused (): () => boolean {
  const isFocused = useIsFocused()
  const isFocusedRef = useRef(isFocused)
  // Because we provide empty dependencies, the getIsFocused instance will never be updated i.e.
  // it is reference-stable.
  const getIsFocused = useCallback(() => isFocusedRef.current, [])

  useEffect(() => {
    isFocusedRef.current = isFocused
    return () => {
      isFocusedRef.current = false
    }
  }, [isFocused])

  return getIsFocused
}
