import { Auth } from 'aws-amplify'
import awsconfig from '../../backend-src/aws-exports'
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import {
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  ApolloClient,
  NormalizedCacheObject
} from '@apollo/client'

// This is exported initialization logic that is meant to be provided to ApolloProvider.  DO NOT REUSE THIS FUNCTION
// AS A WAY TO ACCESS the Apollo Client in other files within the app! Instead, use the useApolloClient() hook.
//
// IMPORTANT: this function also configures the ApolloClient cache, our primary FE datastore layer.
// This caching has default assumptions that the 'id' field uniquely identifies an instance of a type.  If this is not
// the case, you must notify the cache by registering the type here.
// Similarly, if your mutation does not return all the data it mutates (e.g. you command the backend to perhaps update
// many different things without returning them, like initializing missing tasks), you must notify the cache to
// appropriately rerun relevant queries.  See https://www.apollographql.com/docs/react/data/mutations/
export function APOLLO_CLIENT_DO_NOT_USE_OUTSIDE_TOP_LEVEL_APP (): ApolloClient<NormalizedCacheObject> {
  const url = awsconfig.aws_appsync_graphqlEndpoint
  const region = awsconfig.aws_appsync_region
  if (
    awsconfig.aws_appsync_authenticationType !==
    AUTH_TYPE.AMAZON_COGNITO_USER_POOLS
  ) {
    // We'd like to pass this value in to authOptions dynamically, but it leads to type errors (see
    // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/474).
    // This workaround allows us to hardocode it while still matching our expectations.
    throw new Error(
      'Frontend expects cognito-based authentication, but received: ' +
        awsconfig.aws_appsync_authenticationType
    )
  }
  const authOptions: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    // apiKey: awsconfig.aws_appsync_apiKey,
    // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
    jwtToken: async () =>
      (await Auth.currentSession()).getAccessToken().getJwtToken()
    // credentials: async () => credentials, // Required when you use IAM-based auth.
  }

  const httpLink = createHttpLink({ uri: url })

  const link = ApolloLink.from([
    createAuthLink({
      url: url,
      region: region,
      auth: authOptions
    }),
    createSubscriptionHandshakeLink(
      {
        url: url,
        region: region,
        auth: authOptions
      },
      httpLink
    )
  ])

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Member: {
          keyFields: ['owner']
        },
        PayCriticalExpensesTask: {
          keyFields: ['owner']
        },
        BuildOneMonthEmergencyFundTask: {
          keyFields: ['owner']
        },
        ExtendEmergencyFundToThreeMonthsTask: {
          keyFields: ['owner']
        },
        ExtendEmergencyFundToSixMonthsTask: {
          keyFields: ['owner']
        },
        InvestInBrokerageAccountTask: {
          keyFields: ['owner']
        },
        ContributeToIra2022Task: {
          keyFields: ['owner']
        },
        // contributeTo401k2022Tasks unneeded because id is the primary key
        // payOffDebt unneeded because id is the primary key
        ActiveTask: {
          keyFields: ['owner']
        },
        SavingsAccount: {
          keyFields: ['accountId']
        },
        CheckingAccount: {
          keyFields: ['accountId']
        },
        DebtAccount: {
          keyFields: ['accountId']
        },
        k401Account: {
          keyFields: ['accountId']
        },
        TraditionalIraAccount: {
          keyFields: ['accountId']
        },
        RothIraAccount: {
          keyFields: ['accountId']
        },
        OtherInvestmentAccount: {
          keyFields: ['accountId']
        },
        // Amplify generates intermediate types for list and @hasMany connections.  These types lack id's for cache
        // normalization, but we don't really need to store them as separate normalized objects from their containing
        // parents anyways, as they never need to be shared across different objects.  We define standard merge
        // behavior (including overwritting the items array every time a newer result comes back
        // for the same key args)) for each such connection to address the warnings the ApolloClient otherwise logs.
        Modelk401AccountSnapshotConnection: {
          merge: true
        },
        ModelSavingsAccountSnapshotConnection: {
          merge: true
        },
        ModelCheckingAccountSnapshotConnection: {
          merge: true
        },
        ModelTraditionalIraAccountSnapshotConnection: {
          merge: true
        },
        ModelRothIraAccountSnapshotConnection: {
          merge: true
        },
        ModelOtherInvestmentAccountSnapshotConnection: {
          merge: true
        },
        ModelUncategorizedAccountSnapshotConnection: {
          merge: true
        },
        ModelDebtAccountSnapshotConnection: {
          merge: true
        },
        ModelPayOffDebtTaskConnection: {
          merge: true
        },
        ModelContributeTo401k2022TaskConnection: {
          merge: true
        },
        ModelContributeTo401kTaskConnection: {
          merge: true
        },
        ModelSavingsAccountConnection: {
          merge: true
        },
        ModelCheckingAccountConnection: {
          merge: true
        },
        ModelDebtAccountConnection: {
          merge: true
        },
        Modelk401AccountConnection: {
          merge: true
        },
        ModelTraditionalIraAccountConnection: {
          merge: true
        },
        ModelRothIraAccountConnection: {
          merge: true
        },
        ModelOtherInvestmentAccountConnection: {
          merge: true
        },
        ModelUncategorizedAccountConnection: {
          merge: true
        },
        ModelMemberConnection: {
          merge: true
        },
        ModelPayCriticalExpensesTaskConnection: {
          merge: true
        },
        ModelBuildOneMonthEmergencyFundTaskConnection: {
          merge: true
        },
        ModelExtendEmergencyFundToThreeMonthsTaskConnection: {
          merge: true
        },
        ModelExtendEmergencyFundToSixMonthsTaskConnection: {
          merge: true
        },
        ModelInvestInBrokerageAccountTaskConnection: {
          merge: true
        },
        ModelContributeToIra2022TaskConnection: {
          merge: true
        },
        ModelContributeToIraTaskConnection: {
          merge: true
        },
        ModelActiveTaskConnection: {
          merge: true
        },
        // Amplify codegen capitalizes model types for the models generated by List* queries but apparently not for
        // the models generated by @hasMany annotations.  Our 'k401' model type therefore results in nearly identical
        // 'k401' and 'K401' models, which are both harmless/specific to their use cases.
        ModelK401AccountConnection: {
          merge: true
        },
        ModelK401AccountSnapshotConnection: {
          merge: true
        }
      }
    })
  })
  return apolloClient
}
