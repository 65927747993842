import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const cardStyle = StyleSheet.create({
  card: {
    borderRadius: 10,
    backgroundColor: THEME.color.whiteOverlay,
    padding: THEME.spacing.horizontalSpaceMedium
  }
})
