// This file checks member accounts and builds appropriate account alerts in a QuestionAndAnswers[][] shape.
import {
  getAccountsFromContainer,
  processAllAccountsFromMemberLatestFinancialViewQuery
} from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { QuestionAndAnswers } from '../../common/questions/question-data'
import { debtAccountQuestionAlertsHandler } from './debt-account-question-alerts-handler'
import { k401AccountQuestionAlertsHandler } from './401k-account-question-alerts-handler'
import { isNonNullish, collapseProcessingResultsIntoArray } from 'global-utils'
import { manualAccountBalanceRepromptHandler } from './manual-account-balance-reprompt-handler'

export type AccountQuestionAlertHandlerType<T> = (
  account: T | null
) => QuestionAndAnswers[][] | null

export function getAccountQuestionAlerts (
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
): QuestionAndAnswers[][] {
  const accounts = getAccountsFromContainer(memberQuery?.getMember)

  // WARNING: The account provided to these handlers is the account when this function runs, NOT the state
  // of the account when the question renders.  If, for example, we hang on to this list of questions for a while,
  // the questions may no longer reflect the latest account information.
  // Similarly, if a question updates the account, those updates will not be reflected in the account used to generate
  // subsequent questions.
  //
  // THIS MEANS THAT ALL QUESTIONS REGISTERED HERE SHOULD BE INDEPENDENT. THEY SHOULD NOT UPDATE FIELDS THAT OTHER
  // QUESTIONS RELY UPON, AS THOSE FIELDS WILL NOT BE UP TO DATE WHEN THE SUBSEQUENT QUESTION IS SHOWN.
  //
  // If we really needed each question to have fully up-to-date information, we could instead return a list of (data) => QuestionAndAnswers[]
  // and plumb the latest data to each question as it was rendered, or we could requery the account within the
  // question itself (see https://github.com/eettaa/emb/pull/813/ for examples) and hope for/engineer a cache hit.  Both
  // options seem like overkill for now- let's just require the questions not rely on overlapping data :).
  const accountToAlertQuestionHandlers = {
    savingsAccounts: manualAccountBalanceRepromptHandler,
    checkingAccounts: manualAccountBalanceRepromptHandler,
    debtAccounts: debtAccountQuestionAlertsHandler,
    otherInvestmentAccounts: manualAccountBalanceRepromptHandler,
    traditionalIraAccounts: manualAccountBalanceRepromptHandler,
    rothIraAccounts: manualAccountBalanceRepromptHandler,
    k401Accounts: k401AccountQuestionAlertsHandler,
    // TODO: eventually ask questions about uncategorized accounts in order to categorize them (once we have
    // recategorization pipelines built)
    uncategorizedAccounts: manualAccountBalanceRepromptHandler
  }

  const alertQuestions = processAllAccountsFromMemberLatestFinancialViewQuery(
    accounts,
    accountToAlertQuestionHandlers
  )
  return collapseProcessingResultsIntoArray(alertQuestions)
    .filter(isNonNullish)
    .flat(1)
}
