import * as React from "react"
import Svg, { SvgProps, Rect, Path, Ellipse } from "react-native-svg"

const YuyuBank = (props: SvgProps): JSX.Element => (
  <Svg
    width={140}
    height={129}
    fill="none"
    {...props}
  >
    <Rect
      x={5.956}
      y={85.413}
      width={83.636}
      height={97.079}
      rx={9}
      transform="rotate(-90 5.956 85.413)"
      fill="#D9D9D9"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={13.963}
      y={85.594}
      width={61.288}
      height={11.381}
      rx={5.69}
      transform="rotate(-90 13.963 85.594)"
      fill="#D9D9D9"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={33.588}
      y={85.594}
      width={61.288}
      height={11.381}
      rx={5.69}
      transform="rotate(-90 33.588 85.594)"
      fill="#D9D9D9"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={63.026}
      y={85.594}
      width={61.288}
      height={11.381}
      rx={5.69}
      transform="rotate(-90 63.026 85.594)"
      fill="#D9D9D9"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={82.65}
      y={85.594}
      width={61.288}
      height={11.381}
      rx={5.69}
      transform="rotate(-90 82.65 85.594)"
      fill="#D9D9D9"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={1.475}
      y={17.146}
      width={105.045}
      height={11.381}
      rx={5.69}
      fill="#D9D9D9"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={1.475}
      y={80.505}
      width={105.045}
      height={11.381}
      rx={5.69}
      fill="#D9D9D9"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={48.774}
      y={76.451}
      width={23.392}
      height={10.447}
      rx={5.224}
      transform="rotate(-90 48.774 76.451)"
      fill="#D3A286"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M53.515 22.45c-.291-2.077.633-2.886.951-2.832.276.106 1.23 1.29.686 2.984-.566 1.76 2.247 2.456 3.143 2.534 2.77.242 6.15-2.37 5.951-7.684-.155-4.163-3.798-9.892-10.535-12.477-5.984 2.874-9.405 8.432-9.525 13.692-.064 2.818.554 4.566 3.209 6.128 2.35 1.384 6.483.243 6.12-2.345Z"
      fill="#53D28B"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M100.565 127.384h-.894c-5.068.001-9.849.003-14.213-.331-4.622-.353-8.698-1.077-12.081-2.53-3.36-1.443-6.034-3.604-7.885-6.866-1.861-3.281-2.935-7.755-2.935-13.891 0-12.24 4.27-21.404 11.093-27.516 6.838-6.126 16.335-9.27 26.915-9.27 10.581 0 20.077 3.144 26.916 9.27 6.822 6.112 11.092 15.275 11.092 27.516 0 6.136-1.073 10.61-2.935 13.891-1.851 3.262-4.525 5.423-7.885 6.866-3.382 1.453-7.459 2.177-12.08 2.53-4.365.334-9.146.332-14.213.331h-.895Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={87.945} cy={108.918} rx={2.295} ry={2.29} fill="#505050" />
    <Ellipse cx={114.334} cy={108.918} rx={2.295} ry={2.29} fill="#505050" />
    <Path
      d="M97.124 113.499c.935.864 3.62 2.073 6.884 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M105.728 76.285c-5.507 5.038-10.708 2.1-12.62 0-5.048-4.122 1.338-24.237 5.163-33.779 4.78 9.16 12.964 28.74 7.457 33.779ZM116.571 75.015c-2.633 1.21-4.204-.492-4.661-1.493-1.374-2.12 3.374-8.792 5.92-11.864.677 3.95 1.373 12.148-1.259 13.357ZM82.31 75.015c2.632 1.21 4.204-.492 4.66-1.493 1.375-2.12-3.373-8.792-5.919-11.864-.677 3.95-1.373 12.148 1.26 13.357Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
)

export default YuyuBank
