import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Carrot = (props: SvgProps): JSX.Element => (
  <Svg
    width={21}
    height={19}
    fill="none"
    {...props}
  >
    <Path
      d="m.913 15.267-.084.149H3.354l.029-.05 6.653-11.808a.9.9 0 0 1 1.568 0l6.653 11.807.029.051H20.81l-.084-.149-7.206-12.789c-1.186-2.104-4.216-2.104-5.402 0L.913 15.267Z"
      fill="#202232"
      stroke="#202232"
      strokeWidth={0.2}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m19.219 16.866-.874-1.55-6.653-11.807a1 1 0 0 0-1.742 0L3.297 15.316l-.874 1.55a1 1 0 0 0 .871 1.491h15.054a1 1 0 0 0 .87-1.49Z"
      fill="#26293C"
    />
  </Svg>
)

export default Carrot