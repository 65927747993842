import * as React from 'react'
import { View, Text } from 'react-native'
import OfflineJungle from '../../../assets/jungle/offline-jungle'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { textStyle } from '../../../themes/global-styles.style'
import { offlineStyle } from './offline.style'

export function OfflineScreen (): JSX.Element {
  const insets = useSafeAreaInsets()
  return (
    <View
      style={{
        ...offlineStyle.topLevelContainer,
        paddingTop: insets.top,
        paddingLeft: insets.left,
        paddingRight: insets.right,
        paddingBottom: insets.bottom
      }}
    >
      <View style={offlineStyle.textContainer}>
        <Text style={[textStyle.largeText, textStyle.boldText]}>
          It looks like you're offline...{'\n\n'}Good for you! (Unless you want
          to use Walkthrough)
        </Text>
      </View>
      <View style={offlineStyle.jungleContainer}>
        <OfflineJungle />
      </View>
    </View>
  )
}
