import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const SmallCheckMark = (props: SvgProps): JSX.Element => (
  <Svg
    width={15}
    height={15}
    fill='none'
    {...props}
  >
    <Path
      d='M1 8.8 5.136 13 14 1'
      stroke='#fff'
      strokeWidth={2}
      strokeLinecap='round'
    />
  </Svg>
)

export default SmallCheckMark
