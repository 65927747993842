import {
  AnswerType,
  ConditionType,
  QuestionAndAnswers
} from '../question-data'
import iso3166 from 'iso-3166-2'
import { postcodeValidator } from 'postcode-validator'
import { getUsaStates } from '../../../../../api-calls/usa-state'
import {
  getNumberFromDollarString,
  isWholeDollarString
} from '../../../../../util/input-validators'
import { isValid, parse, format, formatISO, parseISO } from 'date-fns'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { stringInStringEnum, isNonNullish } from 'global-utils'
import { gql } from '@apollo/client'
import { loadToken } from '../../../../auth/load-token'
import { YesOrNo, yesOrNoToDisplayText } from '../display-values/yes-or-no'
import { mxPrivacy } from '../../../click-through-module/content/mx-privacy'
import { taxFilingStatusToDisplayText } from '../../../../../util/profile-parsing'
import { promoCodeExists, promoCodeValue } from './promo-code'

// IMPORTANT:
// Validation in these Q&As is for streamlined member experience.
// Real validation needs to occur in backend/API flow. This validation
// is NOT intended to implement security or thorough backend validation.

const welcome: QuestionAndAnswers = {
  key: 'WELCOME',
  question: {
    text: 'Yay! Welcome to alllllll the questions! Actually, only seven(ish), which could be a lot or a little depending on your mood 🔮'
  },
  answers: []
}

export const stateAndZipQuestion: QuestionAndAnswers = {
  key: 'STATE_AND_ZIP_QUESTION',
  question: {
    text: "What's your state & zip code?\n\nThis info will be used to figure out estimates on taxes and such. Funnnnnnn stuff."
  },
  answers: [
    {
      key: 'USA_STATE_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        return response.data.getMember?.state ?? ''
      },
      storeValue: async (client, value) => {
        if (iso3166.subdivision('USA', value) === null) {
          console.log(
            "Provided value ('" +
              value +
              "') is not USA ISO 3166-2 subdivision. Frontend should be validating string before it gets here."
          )
          return
        }
        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              state: value
            }
          }
        })
      },
      validation: {
        // Validation here does not inherently protect against XSS attacks because an attacker
        // could simply call the backend directly (via cURL or something).  This field should
        // be treated skeptically in the backend.
        isValid: (answer: string) => {
          return iso3166.subdivision('USA', answer) !== null
        },
        notValidInfoText: 'State must be one of the available options.'
      },
      answerType: AnswerType.SELECTION,
      selectionAnswer: {
        items: getUsaStates().map((state) => {
          return { displayText: state, enumValue: state }
        })
      }
    },
    {
      key: 'ZIPCODE_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        return response.data.getMember?.zipCode ?? ''
      },
      storeValue: async (client, value) => {
        if (!postcodeValidator(value, 'US')) {
          console.log(
            "Provided value ('" +
              value +
              "') is not a valid US 5-digit or 9-digit zipcode. Frontend should be validating string before it gets here."
          )
          return
        }
        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              zipCode: value
            }
          }
        })
      },
      validation: {
        // Validation here does not inherently protect against XSS attacks because an attacker
        // could simply call the backend directly (via cURL or something).  This field should
        // be treated skeptically in the backend.
        isValid: (answer: string) => {
          return postcodeValidator(answer, 'US')
        },
        notValidInfoText: 'Must be a valid 5-digit or 9-digit US zipcode.'
      },
      answerType: AnswerType.TEXT,
      textAnswer: {
        placeholderText: 'zipcode'
      }
    }
  ]
}

export const birthdayQuestion: QuestionAndAnswers = {
  key: 'BIRTHDAY_QUESTION',
  question: {
    text: "When's your birthday?\n\nThere is a teeny tiny chance we send you a cake! But mostly we need to know so we can figure out what retirement account contributions you're eligible for, etc."
  },
  answers: [
    {
      key: 'BIRTHDAY_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        const dateString = response.data.getMember?.birthday
        if (dateString !== undefined && dateString !== null) {
          // parseISO interprets an input date as 0h in the current timezone
          // format prints the date in the current timezone, so there is no disagreement.
          return format(parseISO(dateString), 'LL/dd/yyyy')
        }
        return ''
      },
      storeValue: async (client, value) => {
        // parse interprets dates in the local timezone.
        // formatISO prints the date in the current timezone (e.g. '*T00:00:00-08:00'), so there is no disagreement
        const birthday = parse(
          value,
          'LL/dd/yyyy',
          /* referenceDate= */ new Date()
        )
        if (!isValid(birthday)) {
          console.log(
            "Provided value ('" +
              value +
              "') is not a nicely formatted date. Frontend should be validating string before it gets here."
          )
          return
        }
        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              birthday: formatISO(birthday, { representation: 'date' })
            }
          }
        })
      },
      validation: {
        isValid: (answer: string) => {
          return (
            answer.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/) !== null &&
            isValid(parse(answer, 'LL/dd/yyyy', new Date()))
          )
        },
        notValidInfoText:
          'Birthday must be a real date in the mm/dd/yyyy format.'
      },
      answerType: AnswerType.TEXT,
      textAnswer: {
        placeholderText: 'mm/dd/yyyy'
      }
    }
  ]
}

const employmentStatusToDisplayText = new Map<
WalkthroughGraphQL.EmploymentStatus,
string
>([
  [WalkthroughGraphQL.EmploymentStatus.UNEMPLOYED, 'Unemployed'],
  [WalkthroughGraphQL.EmploymentStatus.EMPLOYED, 'Employed'],
  [WalkthroughGraphQL.EmploymentStatus.SELF_EMPLOYED, 'Self employed']
])

export const employmentStatusQuestion: QuestionAndAnswers = {
  key: 'EMPLOYMENT_STATUS_QUESTION',
  question: {
    text: "What's your employment status?"
  },
  answers: [
    {
      key: 'EMPLOYMENT_STATUS_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        return response.data.getMember?.employmentStatus ?? ''
      },
      storeValue: async (client, value) => {
        if (!stringInStringEnum(WalkthroughGraphQL.EmploymentStatus, value)) {
          console.log(
            "Provided value ('" +
              value +
              "') is not valid. Frontend should be validating string before it gets here."
          )
          return
        }
        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              employmentStatus: value
            }
          }
        })
      },
      validation: {
        isValid: (answer: string) =>
          stringInStringEnum(WalkthroughGraphQL.EmploymentStatus, answer),
        notValidInfoText:
          'Employment status must be one of the available options.'
      },
      answerType: AnswerType.SELECTION,
      selectionAnswer: {
        items: Array.from(employmentStatusToDisplayText).map(([key, value]) => {
          return { enumValue: key, value, displayText: value }
        })
      }
    }
  ]
}

export const yearlyIncomeQuestion: QuestionAndAnswers = {
  key: 'TAXABLE_INCOME_QUESTION',
  question: {
    text: "What's your estimated taxable income for this year (in US dollars)?"
  },
  answers: [
    {
      key: 'TAXABLE_INCOME_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        return (
          response.data.getMember?.estimatedTaxableIncome?.toString() ?? ''
        )
      },
      storeValue: async (client, value) => {
        if (!isWholeDollarString(value)) {
          console.log(
            "Provided value ('" +
              value +
              "') is not an amount in dollars > 0. Frontend should be validating string before it gets here."
          )
          return
        }
        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              estimatedTaxableIncome: getNumberFromDollarString(value)
            }
          }
        })
      },
      validation: {
        isValid: (answer: string) => {
          return isWholeDollarString(answer)
        },
        notValidInfoText: 'Taxable income must be a whole dollar amount > 0.'
      },
      answerType: AnswerType.TEXT,
      textAnswer: {
        placeholderText: '$XXXX'
      }
    }
  ]
}

export const monthlyTakeHomeIncomeQuestion: QuestionAndAnswers = {
  key: 'MONTHLY_TAKE_HOME_QUESTION',
  question: {
    text: `What is your average monthly take-home income?\n\nWhat we're really looking for is how much money you make each month after taxes, not including investment growth.  Some ways you can figure that out are:
    \n• Tell us how much money actually shows up in your bank account every month from your jobs. Don't include any money withheld from your paycheck for taxes, 401k contributions, etc. Just the total number that hits your bank account!
    \n• If you know how much money you make and how much in total income/payroll taxes you'll pay this year (federal, state, and local), you could subtract them from your total non-investment income, then divide by 12. Hint: Maybe it's about the same as last year?`
  },
  answers: [
    {
      key: 'MONTHLY_TAKE_HOME_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        return (
          response.data.getMember?.estimatedAverageMonthlyAfterTaxIncome?.toString() ??
          ''
        )
      },
      storeValue: async (client, value) => {
        if (!isWholeDollarString(value)) {
          console.log(
            "Provided value ('" +
              value +
              "') is not an amount in dollars > 0. Frontend should be validating string before it gets here."
          )
          return
        }
        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              estimatedAverageMonthlyAfterTaxIncome:
                getNumberFromDollarString(value)
            }
          }
        })
      },
      answerType: AnswerType.TEXT,
      validation: {
        isValid: (answer: string) => {
          return isWholeDollarString(answer)
        },
        notValidInfoText:
          'Monthly after-tax income must be a whole dollar amount > 0.'
      },
      textAnswer: {
        placeholderText: '$XXXX'
      }
    }
  ]
}

export const monthlySpendingQuestion: QuestionAndAnswers = {
  key: 'MONTHLY_SPENDING_QUESTION',
  question: {
    text:
      'Add up all your monthly expenses: rent, groceries, subscriptions, etc. You should also probably add in all ' +
      "your debt payments.\n\nIf you use a credit card for day to day purchases, don't add that expected credit card " +
      'payment — instead, please add up all the stuff you bought with it in the month (these are the same if you ' +
      'always pay your credit card in full).'
  },
  answers: [
    {
      key: 'MONTHLY_SPENDING_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        return (
          response.data.getMember?.estimatedAverageMonthlySpending?.toString() ??
          ''
        )
      },
      storeValue: async (client, value) => {
        if (!isWholeDollarString(value)) {
          console.log(
            "Provided value ('" +
              value +
              "') is not an amount in dollars > 0. Frontend should be validating string before it gets here."
          )
          return
        }
        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              estimatedAverageMonthlySpending: getNumberFromDollarString(value)
            }
          }
        })
      },
      answerType: AnswerType.TEXT,
      validation: {
        isValid: (answer: string) => {
          return isWholeDollarString(answer)
        },
        notValidInfoText: 'Monthly expense must be a whole dollar amount > 0.'
      },
      textAnswer: {
        placeholderText: '$XXXX'
      }
    }
  ]
}

export const taxFilingStatusQuestion: QuestionAndAnswers = {
  key: 'TAX_FILING_STATUS_QUESTION',
  question: {
    text: "What's your tax filing status for this year?"
  },
  answers: [
    {
      key: 'TAX_FILING_STATUS_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        return response.data.getMember?.taxFilingStatus ?? ''
      },
      storeValue: async (client, value) => {
        if (!stringInStringEnum(WalkthroughGraphQL.TaxFilingStatus, value)) {
          console.log(
            "Provided value ('" +
              value +
              "') is not valid. Frontend should be validating string before it gets here."
          )
          return
        }

        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              taxFilingStatus: value
            }
          }
        })
      },
      validation: {
        isValid: (answer: string) =>
          stringInStringEnum(WalkthroughGraphQL.TaxFilingStatus, answer),
        notValidInfoText:
          'Tax filing status must be one of the available options'
      },
      answerType: AnswerType.SELECTION,
      selectionAnswer: {
        items: Array.from(taxFilingStatusToDisplayText).map(([key, value]) => {
          return { enumValue: key, value, displayText: value }
        })
      }
    }
  ]
}

export const spouseBirthdayQuestion: QuestionAndAnswers = {
  key: 'SPOUSE_BIRTHDAY_QUESTION',
  question: {
    text: "When's your spouse's birthday?\n\nWe're more likely to send cakes to your better half :) But mostly we need to know for retirement account contribution limits."
  },
  answers: [
    {
      key: 'SPOUSE_BIRTHDAY_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        const dateString = response.data.getMember?.spouseBirthday
        if (dateString !== undefined && dateString !== null) {
          // parseISO interprets an input date as 0h in the current timezone
          // format prints the date in the current timezone, so there is no disagreement.
          return format(parseISO(dateString), 'LL/dd/yyyy')
        }
        return ''
      },
      storeValue: async (client, value) => {
        // parse interprets dates in the local timezone.
        // formatISO prints the date in the current timezone (e.g. '*T00:00:00-08:00'), so there is no disagreement
        const birthday = parse(
          value,
          'LL/dd/yyyy',
          /* referenceDate= */ new Date()
        )
        if (!isValid(birthday)) {
          console.log(
            "Provided value ('" +
              value +
              "') is not a nicely formatted date. Frontend should be validating string before it gets here."
          )
          return
        }
        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              spouseBirthday: formatISO(birthday, { representation: 'date' })
            }
          }
        })
      },
      validation: {
        isValid: (answer: string) => {
          return (
            answer.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/) !== null &&
            isValid(parse(answer, 'LL/dd/yyyy', new Date()))
          )
        },
        notValidInfoText:
          'Birthday must be a real date in the mm/dd/yyyy format.'
      },
      answerType: AnswerType.TEXT,
      textAnswer: {
        placeholderText: 'mm/dd/yyyy'
      }
    }
  ],
  condition: {
    conditionType: ConditionType.MATCH_ANSWER,
    matchAnswerCondition: {
      answerKeyToMatch: 'TAX_FILING_STATUS_ANSWER',
      answersToMatch: [
        WalkthroughGraphQL.TaxFilingStatus.MARRIED_FILING_JOINTLY
      ]
    }
  }
}

export const ableToPayBasicExpensesQuestion: QuestionAndAnswers = {
  key: 'BASIC_EXPENSES',
  question: {
    text: 'Are you able to pay your critical expenses?\n\nThis includes housing, utilities, food, transportation, minimum debt payments, health insurance, car insurance (if you own a car), and life insurance (if you have dependents).'
  },
  answers: [
    {
      key: 'BASIC_EXPENSES_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        const isAbleToPayBasicExpenses: boolean | undefined | null =
          response?.data?.getMember?.isAbleToPayBasicExpensesMemberDeclared
        if (isNonNullish(isAbleToPayBasicExpenses)) {
          return isAbleToPayBasicExpenses ? YesOrNo.YES : YesOrNo.NO
        }
        return ''
      },
      storeValue: async (client, value) => {
        if (!stringInStringEnum(YesOrNo, value)) {
          console.log("Provided value ('" + value + "') is not yes or no.")
          return
        }
        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              isAbleToPayBasicExpensesMemberDeclared: value === YesOrNo.YES
            }
          }
        })
      },
      validation: {
        isValid: (answer: string) => stringInStringEnum(YesOrNo, answer),
        notValidInfoText: 'Answer must be Yes or No.'
      },
      answerType: AnswerType.SELECTION,
      selectionAnswer: {
        items: Array.from(yesOrNoToDisplayText).map(([key, value]) => {
          return { enumValue: key, value, displayText: value }
        })
      }
    }
  ]
}

export const hasOneMonthEmergencyFundQuestion: QuestionAndAnswers = {
  key: 'ONE_MONTH_EMERGENCY_FUND',
  question: {
    text: "Do you have a emergency fund that's able to cover one month of living expenses (or $1,000 — whichever is more)?"
  },
  answers: [
    {
      key: 'ONE_MONTH_EMERGENCY_FUND_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        const hasOneMonthEmergencyFund: boolean | undefined | null =
          response?.data?.getMember?.hasOneMonthEmergencyFundMemberDeclared
        if (isNonNullish(hasOneMonthEmergencyFund)) {
          return hasOneMonthEmergencyFund ? YesOrNo.YES : YesOrNo.NO
        }
        return ''
      },
      storeValue: async (client, value) => {
        if (!stringInStringEnum(YesOrNo, value)) {
          console.log("Provided value ('" + value + "') is not yes or no.")
          return
        }
        await client.mutate<
        WalkthroughGraphQL.UpdateMemberMutation,
        WalkthroughGraphQL.UpdateMemberMutationVariables
        >({
          mutation: gql(WalkthroughGraphQL.updateMember),
          variables: {
            input: {
              owner: await loadToken(),
              hasOneMonthEmergencyFundMemberDeclared: value === YesOrNo.YES
            }
          }
        })
      },
      validation: {
        isValid: (answer: string) => stringInStringEnum(YesOrNo, answer),
        notValidInfoText: 'Answer must be Yes or No.'
      },
      answerType: AnswerType.SELECTION,
      selectionAnswer: {
        items: Array.from(yesOrNoToDisplayText).map(([key, value]) => {
          return { enumValue: key, value, displayText: value }
        })
      }
    }
  ]
}

export const thankYou: QuestionAndAnswers = {
  key: 'THANK_YOU',
  question: {
    text: (
      <>Yay thanks for answering allllll the questions! We like you already.</>
    )
  },
  answers: []
}

// Translate the MX Privacy content module into question format so it can fit into onboarding.
export const mxPrivacySequenceAsQuestions: QuestionAndAnswers[][] =
  mxPrivacy.subtasks.map((subtask): QuestionAndAnswers[] => {
    return [
      {
        key: 'MX_PRIVACY_SEQUENCE_' + subtask.key,
        question: {
          text: subtask.description
        },
        answers: []
      }
    ]
  })

export const profileQuestions: QuestionAndAnswers[][] =
  (function (): QuestionAndAnswers[][] {
    const ret = [
      [welcome],
      [stateAndZipQuestion],
      [birthdayQuestion],
      [employmentStatusQuestion],
      [yearlyIncomeQuestion],
      [monthlyTakeHomeIncomeQuestion],
      [monthlySpendingQuestion],
      [ableToPayBasicExpensesQuestion],
      [hasOneMonthEmergencyFundQuestion],
      [taxFilingStatusQuestion, spouseBirthdayQuestion],
      [promoCodeExists, promoCodeValue],
      [thankYou]
    ]

    ret.push(...mxPrivacySequenceAsQuestions)
    return ret
  })()
