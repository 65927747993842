"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.contributeTo401kTaskCurrentAllocationHandler = void 0;
const WalkthroughGraphQL = __importStar(require("amplify-client-graphql"));
const global_utils_1 = require("global-utils");
// Note that this handler has nothing to do with the goal engine.  It is dealing entirely with app concepts.
// It is up to the app <-> goal engine interface to ensure that representations and semantics between the two
// packages (e.g. what does "allocated" mean and whether/how it is represented with TransactionAggregations).
function getRelevant401kTransactionAggregationTypesFromContributionYear(contributionYear) {
    switch (contributionYear) {
        case 2022:
            return new Set([
                WalkthroughGraphQL.TransactionAggregationType
                    .ROTH_401K_CONTRIBUTIONS_2022,
                WalkthroughGraphQL.TransactionAggregationType
                    .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2022
            ]);
        case 2023:
            return new Set([
                WalkthroughGraphQL.TransactionAggregationType
                    .ROTH_401K_CONTRIBUTIONS_2023,
                WalkthroughGraphQL.TransactionAggregationType
                    .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2023
            ]);
        // TODO: implement 2023 logic once earmarks are introduced into schema.
        default:
            throw new Error('In getRelevant401kTransactionAggregationTypesFromContributionYear, encountered unconfigured contribution year: ' +
                contributionYear.toString());
    }
}
const contributeTo401kTaskCurrentAllocationHandler = (taskKey) => (task) => {
    var _a, _b, _c;
    if (task === null) {
        return null;
    }
    if (task.__typename !== taskKey.taskTypename) {
        return null;
    }
    if (task.id !== taskKey.taskPrimaryKey) {
        return null;
    }
    const relevantTransactionAggregationTypes = getRelevant401kTransactionAggregationTypesFromContributionYear(task.contributionYear);
    return (
    // 401k tasks are account-specific, so we do not sum *all* transaction aggregations
    // just the ones associated with this account.
    (_c = (_b = (_a = task.k401Account) === null || _a === void 0 ? void 0 : _a.transactionAggregations) === null || _b === void 0 ? void 0 : _b.filter(global_utils_1.isNonNullish).filter((t) => relevantTransactionAggregationTypes.has(t.type)).map((t) => { var _a; return (_a = t.amount) !== null && _a !== void 0 ? _a : 0; }).reduce((sum, next) => sum + next, 0)) !== null && _c !== void 0 ? _c : 0);
};
exports.contributeTo401kTaskCurrentAllocationHandler = contributeTo401kTaskCurrentAllocationHandler;
