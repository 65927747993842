// import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { AnswerType, QuestionAndAnswers } from '../question-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { gql } from '@apollo/client'
// import { isNonNullish } from 'global-utils'

export const AiAdvisorFeedback: (recordId: string) => QuestionAndAnswers = (
  recordId
) => {
  return {
    key: 'WHY_FLAG',
    question: {
      text: (
        <>
          Thanks for flagging this! We're working on improving our AI Advisor.
          {'\n\n'}
          Why did you flag this? What would a better answer mention or leave
          out?
        </>
      )
    },
    answers: [
      {
        key: 'WHY_FLAG_ANSWER',
        getInitialAnswer: async () => '',
        storeValue: async (client, value) => {
          // Don't bother awaiting for this as it is inconsequential to the UI.
          void client.mutate<
          WalkthroughGraphQL.ProvideAiAdvisorFeedbackMutation,
          WalkthroughGraphQL.ProvideAiAdvisorFeedbackMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.provideAiAdvisorFeedback),
            variables: {
              input: {
                id: recordId,
                userFeedback: value
              }
            }
          })
        },
        validation: {
          isValid: () => true, // No validation for freeform input.
          notValidInfoText: 'Input not valid (unexpected error)'
        },
        answerType: AnswerType.TEXT,
        textAnswer: {
          placeholderText: '(answer here)'
        }
      }
    ]
  }
}
