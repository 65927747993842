import { AnswerType, QuestionAndAnswers } from '../question-data'
import { stringInStringEnum, isNonNullish } from 'global-utils'
import { YesOrNo, yesOrNoToDisplayText } from '../display-values/yes-or-no'

export const kRemoveMxConnectInstance = 'REMOVE_MX_CONNECT_INSTANCE'

export const removeMxConnectInstanceQuestion: (
  onDelete: () => void,
  institutionName: string | null
) => QuestionAndAnswers = (onDelete, institutionName) => {
  return {
    key: kRemoveMxConnectInstance,
    question: {
      text: (
        <>
          Are you sure you want to stop{' '}
          {isNonNullish(institutionName)
            ? 'the connection to ' + institutionName
            : 'this connection'}
          ? Those accounts won't be imported.
          {'\n\n'}We know linking can take a while, but it's generally a
          one-time deal. Stick around!
          {'\n\n'}(Select 'Yes' to remove. Select 'No' or click outside this
          question to exit without deleting).
        </>
      )
    },
    answers: [
      {
        key: 'ONLY_ANSWER',
        answerType: AnswerType.SELECTION,
        getInitialAnswer: async () => {
          return YesOrNo.NO
        }, // No by default
        validation: {
          isValid: (answer: string) => stringInStringEnum(YesOrNo, answer),
          notValidInfoText: 'Answer must be Yes or No.'
        },
        selectionAnswer: {
          items: Array.from(yesOrNoToDisplayText).map(([key, value]) => {
            return { enumValue: key, value, displayText: value }
          })
        },
        storeValue: async (client, value) => {
          if (!stringInStringEnum(YesOrNo, value)) {
            console.log("Provided value ('" + value + "') is not Yes or No.")
            return
          }
          if (value === YesOrNo.YES) {
            onDelete()
          }
        }
      }
    ]
  }
}
