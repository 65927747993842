import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

function JungleIcon (props: SvgProps): JSX.Element {
  return (
    <Svg
      width={20}
      height={24}
      fill='none'
      {...props}
    >
      <Path
        d='M19 14.566C19 19.17 15.026 23 10 23s-9-3.83-9-8.434c0-2.38 1.045-5.844 2.784-8.719.864-1.427 1.87-2.659 2.949-3.523C7.81 1.46 8.913 1 10 1c1.088 0 2.189.46 3.267 1.324 1.08.864 2.085 2.096 2.948 3.523 1.74 2.875 2.785 6.34 2.785 8.72z'
        stroke={props.color}
        strokeWidth={2}
      />
    </Svg>
  )
}

export default JungleIcon
