import * as React from 'react'
import { Text } from 'react-native'
import { FadeElementGroup } from '../../determine-next-task/fade-element-groups'
import { generateRandomInteger } from 'global-utils'
import YuyuCartwheel from '../../../../../assets/yuyu/cartwheel'
import YuyuFriends from '../../../../../assets/yuyu/friends'
import YuyuSnack from '../../../../../assets/yuyu/snack'
import { kFirstElementFadeDelay } from './get-determine-next-task-copy'

export function getRandomFunElementGroup (): FadeElementGroup {
  const cartwheel: FadeElementGroup = {
    key: 'CARTWHEEL',
    elements: [
      {
        key: 'STRESSFUL_LINE',
        element: <Text>Man, sometimes finances are stressful!</Text>,
        preFadeInDelay: 0
      },
      {
        key: 'CARTWHEEL_LINE',
        element: <Text>Let's stop and do a cartwheel!</Text>,
        preFadeInDelay: kFirstElementFadeDelay
      }
    ],
    image: <YuyuCartwheel />
  }
  const tellYourFriends: FadeElementGroup = {
    key: 'FRIENDS',
    elements: [
      {
        key: 'RECOMMEND_FRIENDS_LINE',
        element: <Text>We recommend...</Text>,
        preFadeInDelay: 0
      },
      {
        key: 'FRIENDS_LINE',
        element: (
          <Text>
            you tell your friends about us!{'\n\n'}Just kidding (sort of).
          </Text>
        ),
        preFadeInDelay: kFirstElementFadeDelay
      }
    ],
    image: <YuyuFriends />
  }
  const snack: FadeElementGroup = {
    key: 'SNACK',
    elements: [
      {
        key: 'LOTS_LINE',
        element: <Text>Well this is lots.</Text>,
        preFadeInDelay: 0
      },
      {
        key: 'SNACK_LINE',
        element: <Text>Time for a snack!</Text>,
        preFadeInDelay: kFirstElementFadeDelay
      }
    ],
    image: <YuyuSnack />
  }

  // The null case is handled in the default case for the switch statement.
  // But this shouldn't return null because 0 and 2 are both integers.
  const random = generateRandomInteger(0, 2)
  switch (random) {
    case 0:
      return cartwheel
    case 1:
      return tellYourFriends
    case 2:
      return snack
    // If random is null, return snack.
    default:
      return snack
  }
}
