// Must be named signUpConfig for amplify to parse it correctly.
// See documentation at https://docs.amplify.aws/ui/auth/authenticator/q/framework/react-native/#signup
export const signUpConfig = {
  header: 'Create a new account',
  hideAllDefaults: true,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: 'Email',
      placeholder: 'email',
      key: 'email',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Password',
      placeholder: 'password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password'
    },
    {
      label: 'Name',
      placeholder: 'name',
      key: 'name',
      required: true,
      displayOrder: 3,
      type: 'string'
    },
    {
      label: 'PhoneNumber',
      placeholder: 'phone number',
      key: 'phone_number',
      required: false,
      displayOrder: 4,
      type: 'string'
    }
  ]
}
