import * as React from "react"
import Svg, { SvgProps, Path, Ellipse } from "react-native-svg"

const YuyuBirthdayHat = (props: SvgProps): JSX.Element => (
  <Svg
    width={91}
    height={133}
    fill="none"
    {...props}
  >
    <Path
      d="M68.923 23.794C67.367 25.45 64 26.882 64 26.882s3.361 1.844 4.923 4.015C70.485 33.067 72 38 72 38s1.415-4.23 3.077-6.485C76.644 29.388 80 26.882 80 26.882s-3.361-1.776-4.923-3.706C73.515 21.247 72 17 72 17s-1.084 4.675-3.077 6.794ZM83.077 10.206C82.104 11.23 80 12.118 80 12.118s2.1 1.141 3.077 2.485C84.053 15.947 85 19 85 19s.885-2.618 1.923-4.015C87.903 13.67 90 12.118 90 12.118s-2.1-1.1-3.077-2.294C85.947 8.629 85 6 85 6s-.677 2.894-1.923 4.206ZM62.462 4.235C61.682 5.023 60 5.705 60 5.705s1.68.879 2.462 1.913C63.242 8.65 64 11 64 11s.708-2.014 1.538-3.088C66.323 6.899 68 5.706 68 5.706s-1.68-.846-2.462-1.765C64.758 3.022 64 1 64 1s-.542 2.226-1.538 3.235ZM3.462 81.235C2.683 82.023 1 82.705 1 82.705s1.68.879 2.462 1.913C4.242 85.65 5 88 5 88s.708-2.014 1.538-3.088C7.322 83.899 9 82.706 9 82.706s-1.68-.846-2.462-1.765C5.758 80.022 5 78 5 78s-.542 2.226-1.538 3.235ZM9.077 65.206C8.104 66.23 6 67.118 6 67.118s2.1 1.141 3.077 2.485C10.053 70.947 11 74 11 74s.885-2.618 1.923-4.015C13.903 68.67 16 67.118 16 67.118s-2.1-1.1-3.077-2.294C11.947 63.628 11 61 11 61s-.677 2.894-1.923 4.206Z"
      fill="#FFF972"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M48 131.493h-.894c-5.067.001-9.847.002-14.21-.331-4.62-.354-8.696-1.079-12.078-2.534-3.359-1.445-6.032-3.609-7.883-6.876C11.073 118.466 10 113.985 10 107.84c0-12.259 4.27-21.435 11.09-27.556C27.929 74.148 37.423 71 48 71s20.072 3.148 26.91 9.284C81.73 86.404 86 95.58 86 107.84c0 6.145-1.073 10.626-2.935 13.912-1.85 3.267-4.524 5.431-7.883 6.876-3.382 1.455-7.457 2.18-12.078 2.534-4.363.333-9.143.332-14.21.331H48Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={35.382} cy={112.506} rx={2.294} ry={2.293} fill="#505050" />
    <Ellipse cx={61.765} cy={112.506} rx={2.294} ry={2.293} fill="#505050" />
    <Path
      d="M44.559 117.093c.934.865 3.62 2.077 6.882 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M53.162 79.827c-5.506 5.045-10.706 2.102-12.618 0-5.047-4.128 1.338-24.271 5.162-33.827 4.78 9.173 12.962 28.781 7.456 33.827ZM64.002 78.555c-2.632 1.21-4.203-.493-4.66-1.496-1.374-2.122 3.373-8.804 5.919-11.88.677 3.954 1.373 12.165-1.259 13.376ZM29.748 78.555c2.632 1.21 4.203-.493 4.66-1.496 1.374-2.122-3.373-8.804-5.919-11.88-.677 3.954-1.373 12.165 1.259 13.376Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M49.858 89.145c-18.073.842-25.15-3.833-30.859-7.145l27.925-55.787L76 81.5c-4.412 3.784-10.239 6.903-26.142 7.645Z"
      fill="#75B7E7"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M52.5 37.5 37 46.298 30 60l27.5-13-5-9.5Z"
      fill="#FF86B1"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M60 51.5 27 66.798 19.5 81.5l46.5-19-6-11Z"
      fill="#FFF972"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M68 67 26.5 85.5s6.095 2.317 10 3c3.905.683 10 .5 10 .5L74 78l-6-11Z"
      fill="#BFB5FF"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M46.425 26.37c-4.333-1.666-12.1-5.9-8.5-9.5 3.6-3.6 7.167 4.834 8.5 9.5ZM47.224 25.926c-1.61-4.354-3.56-12.982 1.52-12.656 5.081.326 1.104 8.573-1.52 12.656Z"
      fill="#FFF972"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M47 25.975c2.91-3.617 9.314-9.719 11.642-5.191C60.97 25.312 51.85 26.13 47 25.974Z"
      fill="#FFF972"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default YuyuBirthdayHat
