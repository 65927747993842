import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish } from 'global-utils'
import { getEmployerMatchString } from '../../../../../util/account-parsing'
import { Text } from 'react-native'
import {
  FadeElement,
  FadeElementGroup
} from '../../determine-next-task/fade-element-groups'
import YuyuBagOfLeaves from '../../../../../assets/yuyu/bag-of-leaves'
import {
  kFirstElementFadeDelay,
  kStandardElementFadeDelay
} from './get-determine-next-task-copy'

export function get401kMatchElementGroup (
  data: WalkthroughGraphQL.GetMemberQuery
): FadeElementGroup {
  const k401MatchElements: FadeElement[] = []
  const contributeTo401kTasks = data.getMember?.contributeTo401kTasks
  const k401Accounts = data.getMember?.k401Accounts

  if (isNonNullish(contributeTo401kTasks)) {
    for (const task of contributeTo401kTasks.items) {
      if (isNonNullish(task) && !task.isComplete) {
        const k401Account = k401Accounts?.items.find((account) => {
          return account?.accountId === task.k401AccountId
        })
        if (isNonNullish(k401Account)) {
          k401MatchElements.push({
            key: `401K_MATCH:${task.contributionYear}${k401Account.accountId}`,
            element: (
              <Text>{`${getEmployerMatchString(k401Account)} on your ${
                k401Account.accountName ?? 'unknown'
              } 401(k) account`}</Text>
            ),
            preFadeInDelay: kStandardElementFadeDelay
          })
        }
      }
    }
  }

  if (k401MatchElements.length === 0) {
    k401MatchElements.push({
      key: 'NO_401K_MATCH',
      element: (
        <Text>
          According to your account data, you don't have a 401(k) employer
          match.
        </Text>
      ),
      preFadeInDelay: kFirstElementFadeDelay
    })
  } else {
    k401MatchElements[0].preFadeInDelay = kFirstElementFadeDelay
  }

  return {
    key: '401K_MATCH_RULES',
    elements: [
      {
        key: '401K_MATCH_RULES_TITLE',
        element: (
          <Text>And the potential matches on your 401(k) accounts...</Text>
        ),
        preFadeInDelay: 0
      },
      ...k401MatchElements
    ],
    image: <YuyuBagOfLeaves />
  }
}
