import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import { THEME } from '../../../constants'
import { ScrollWithArrow } from '../common/scroll/scroll-with-arrow'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { TaskIntroData } from './task-flow-data'
import { taskIntroStyle } from './task-intro.style'

export function TaskIntro (props: {
  taskIntro: TaskIntroData
  navigation: GenericCompositeNavigationProp
  recommendedByWalkthrough?: boolean
}): JSX.Element {
  // Don't show a back button on the intro screen.
  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      headerLeft: undefined,
      headerTitle:
        props.recommendedByWalkthrough === true ? 'Recommended task' : '',
      headerTitleAlign: 'center'
    })
  }, [props.recommendedByWalkthrough])

  const [scrollToTopTrigger, setScrollToTopTrigger] = React.useState(0)

  React.useEffect(() => {
    // Set a new, different value to trigger a ScrollToTop call
    setScrollToTopTrigger(Math.random())
  }, [props.taskIntro])

  const style = taskIntroStyle(THEME)
  return (
    <View style={style.taskBox}>
      <ScrollWithArrow triggerScrollToTop={scrollToTopTrigger}>
        <View style={style.contentBox}>
          <View style={style.subContentBox}>
            <Text style={style.title}>{props.taskIntro.title}</Text>
            <Text style={style.introContent}>
              {props.taskIntro.introContent}
            </Text>
          </View>
          {props.taskIntro.button !== undefined ? (
            <Pressable
              onPress={() => {
                props.taskIntro.button?.handleOnPressButton({
                  navigation: props.navigation
                })
              }}
            >
              <View style={style.button}>
                <Text style={style.buttonText}>
                  {props.taskIntro.button.buttonText}
                </Text>
              </View>
            </Pressable>
          ) : null}
          {props.taskIntro.alternativeButton !== undefined ? (
            <Pressable
              onPress={() =>
                props.taskIntro.alternativeButton?.handleOnPressButton({
                  navigation: props.navigation
                })
              }
            >
              <View style={style.alternativeButton}>
                <Text style={style.buttonText}>
                  {props.taskIntro.alternativeButton.buttonText}
                </Text>
              </View>
            </Pressable>
          ) : null}
        </View>
      </ScrollWithArrow>
    </View>
  )
}
