import { InputAccountTypesMemberLatestFinancialViewQuery } from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { AnswerType, QuestionAndAnswers } from '../question-data'
import { gql } from '@apollo/client'
import {
  getNumberFromDollarString,
  isWholeDollarString
} from '../../../../../util/input-validators'
import {
  accountNameWithBalanceFallback,
  getPretax401kTransactionAggregationTypeByYear,
  getRoth401kTransactionAggregationTypeByYear
} from '../../../../../util/account-parsing'
import { isNonNullish } from 'global-utils'

export const k401ContributionQuestions: (
  // We limit the account input here to MemberLatestFinancialViewQuery so it is compatible
  // across the TasksScreen flow (using GetMember) and the AccountsScreen flow (using narrower
  // MemberLatestFinancialViewQuery)
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts'],
  contributionYear: number
) => QuestionAndAnswers[] = (account, contributionYear) => {
  // Use promises to handle answers from multiple questions and store them all at once.
  let preTaxResolve: (
    txnAggregations: WalkthroughGraphQL.TransactionAggregation[]
  ) => void
  let preTaxReject: (reason: Error) => void
  const pretaxTxnAggregations = new Promise<
  WalkthroughGraphQL.TransactionAggregation[]
  >((resolve, reject) => {
    preTaxResolve = resolve
    preTaxReject = reject
  })
  return [
    {
      key: 'PRE_TAX_401K_CONTRIBUTION_QUESTION',
      question: {
        text: (
          <>
            Based on the withholding you've set up, how much pre-tax money will
            you contribute to your TRADITIONAL{' '}
            {accountNameWithBalanceFallback(account)} 401k in {contributionYear}
            ? Don't count your employer match—only count the money you
            contribute!
          </>
        )
      },
      answers: [
        {
          key: 'PRETAX_ANSWER',
          getInitialAnswer: async () => {
            if (!isNonNullish(account.transactionAggregations)) {
              return ''
            }
            const relevantAggregations = (account.transactionAggregations ?? [])
              .filter(isNonNullish)
              .filter(
                (t) =>
                  t.type ===
                  getPretax401kTransactionAggregationTypeByYear(
                    contributionYear
                  )
              )
            if (relevantAggregations.length === 0) {
              return ''
            }
            return relevantAggregations
              .map((t) => t.amount ?? 0)
              .reduce((sum, value) => sum + value)
              .toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })
              .replace(/,/g, '')
          },
          storeValue: async (client, value) => {
            if (!isWholeDollarString(value)) {
              preTaxReject(
                new Error(
                  "Provided value ('" +
                    value +
                    "') is not an amount in dollars > 0. Frontend should be validating string before it gets here."
                )
              )
              return
            }
            preTaxResolve([
              ...(account.transactionAggregations ?? [])
                .filter(isNonNullish)
                .filter(
                  (t) =>
                    t.type !==
                    getPretax401kTransactionAggregationTypeByYear(
                      contributionYear
                    )
                ),
              {
                __typename: 'TransactionAggregation',
                type: getPretax401kTransactionAggregationTypeByYear(
                  contributionYear
                ),
                amount: getNumberFromDollarString(value)
              }
            ])
          },
          validation: {
            isValid: (answer: string) => {
              return isWholeDollarString(answer)
            },
            notValidInfoText: 'Contribution must be a dollar amount > 0.'
          },
          answerType: AnswerType.TEXT,
          textAnswer: {
            placeholderText: '$XXXX'
          }
        }
      ]
    },
    {
      key: 'ROTH_401K_CONTRIBUTION_QUESTION',
      question: {
        text: (
          <>
            Based on the withholding you've set up, how much money will you
            contribute to your ROTH {accountNameWithBalanceFallback(account)}{' '}
            401k in {contributionYear}? Don't count your employer match—only
            count the money you contribute!
          </>
        )
      },
      answers: [
        {
          key: 'ROTH_ANSWER',
          getInitialAnswer: async () => {
            if (!isNonNullish(account.transactionAggregations)) {
              return ''
            }
            const relevantAggregations = account.transactionAggregations
              .filter(isNonNullish)
              .filter(
                (t) =>
                  t.type ===
                  getRoth401kTransactionAggregationTypeByYear(contributionYear)
              )
            if (relevantAggregations.length === 0) {
              return ''
            }
            return relevantAggregations
              .map((t) => t.amount ?? 0)
              .reduce((sum, value) => sum + value)
              .toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })
              .replace(/,/g, '')
          },
          storeValue: async (client, value) => {
            if (!isWholeDollarString(value)) {
              console.log(
                "Provided value ('" +
                  value +
                  "') is not an amount in dollars > 0. Frontend should be validating string before it gets here."
              )
              return
            }
            const txnAggregationFromPretax = await pretaxTxnAggregations
            await client.mutate<
            WalkthroughGraphQL.UpdateK401AccountMutation,
            WalkthroughGraphQL.UpdateK401AccountMutationVariables
            >({
              mutation: gql(WalkthroughGraphQL.updateK401Account),
              variables: {
                input: {
                  accountId: account.accountId,
                  // Rewrite all the unedited transaction aggregations, and add a new one that was just edited.
                  // Because TransactionAggregation has __typename but TransactionAggregationInput does not, we
                  // explicitly (shallow) copy only the type and amount fields
                  transactionAggregations: [
                    ...txnAggregationFromPretax
                      .filter(
                        (t) =>
                          t.type !==
                          getRoth401kTransactionAggregationTypeByYear(
                            contributionYear
                          )
                      )
                      .map(({ type, amount }) => {
                        return { type, amount }
                      }),
                    {
                      type: getRoth401kTransactionAggregationTypeByYear(
                        contributionYear
                      ),
                      amount: getNumberFromDollarString(value)
                    }
                  ]
                }
              }
            })
          },
          validation: {
            isValid: (answer: string) => {
              return isWholeDollarString(answer)
            },
            notValidInfoText: 'Contribution must be a dollar amount > 0.'
          },
          answerType: AnswerType.TEXT,
          textAnswer: {
            placeholderText: '$XXXX'
          }
        }
      ]
    }
  ]
}
