import * as React from "react"
import Svg, { SvgProps, Path, Ellipse } from "react-native-svg"

const YuyuFriends = (props: SvgProps): JSX.Element => (
  <Svg
    width={168}
    height={98}
    fill="none"
    {...props}
  >
    <Path
      d="M142 59h-.592c-3.382 0-6.557.001-9.453-.219-3.065-.233-5.74-.71-7.947-1.653-2.183-.934-3.904-2.324-5.096-4.416-1.203-2.11-1.912-5.016-1.912-9.052 0-8.04 2.813-14.025 7.281-18.011 4.484-4 10.73-6.069 17.719-6.069 6.989 0 13.235 2.068 17.719 6.069 4.468 3.986 7.281 9.971 7.281 18.01 0 4.037-.709 6.943-1.912 9.053-1.192 2.092-2.913 3.482-5.096 4.416-2.207.943-4.882 1.42-7.947 1.653-2.896.22-6.071.22-9.453.219H142Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={133.588} cy={47.08} rx={1.529} ry={1.52} fill="#505050" />
    <Ellipse cx={151.177} cy={47.08} rx={1.529} ry={1.52} fill="#505050" />
    <Path
      d="M139.706 50.12c.623.573 2.413 1.376 4.588 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M145.441 25.42c-3.671 3.344-7.137 1.393-8.412 0-3.365-2.736.892-16.087 3.441-22.42 3.187 6.08 8.641 19.076 4.971 22.42ZM152.668 24.577c-1.754.803-2.802-.326-3.106-.991-.917-1.407 2.248-5.836 3.945-7.874.451 2.62.916 8.063-.839 8.865ZM129.833 24.577c1.754.803 2.802-.326 3.106-.991.917-1.407-2.248-5.836-3.945-7.874-.452 2.62-.916 8.063.839 8.865Z"
      fill="#BFB5FF"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M129 80h-.592c-3.382 0-6.557.001-9.453-.219-3.065-.233-5.74-.71-7.947-1.653-2.183-.934-3.904-2.324-5.096-4.416-1.203-2.11-1.912-5.016-1.912-9.052 0-8.04 2.813-14.025 7.281-18.011 4.484-4 10.73-6.069 17.719-6.069 6.989 0 13.235 2.068 17.719 6.069 4.468 3.986 7.281 9.971 7.281 18.01 0 4.037-.709 6.943-1.912 9.053-1.192 2.092-2.913 3.482-5.096 4.416-2.207.943-4.882 1.42-7.947 1.653-2.896.22-6.071.22-9.453.219H129Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={120.588} cy={68.08} rx={1.529} ry={1.52} fill="#505050" />
    <Ellipse cx={138.177} cy={68.08} rx={1.529} ry={1.52} fill="#505050" />
    <Path
      d="M126.706 71.12c.623.573 2.413 1.376 4.588 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M132.441 46.42c-3.671 3.344-7.137 1.393-8.412 0-3.365-2.736.892-16.087 3.441-22.42 3.187 6.08 8.641 19.076 4.971 22.42ZM139.668 45.577c-1.754.803-2.802-.326-3.106-.991-.917-1.407 2.248-5.836 3.945-7.874.451 2.62.916 8.063-.839 8.865ZM116.833 45.577c1.754.803 2.802-.326 3.106-.991.917-1.407-2.248-5.836-3.945-7.874-.452 2.62-.916 8.063.839 8.865Z"
      fill="#FFAFEA"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M26 65h-.592c-3.382 0-6.557.001-9.453-.219-3.065-.233-5.74-.71-7.947-1.653-2.183-.934-3.904-2.324-5.096-4.416C1.71 56.602 1 53.696 1 49.66c0-8.04 2.813-14.025 7.28-18.011 4.485-4 10.73-6.069 17.72-6.069 6.99 0 13.235 2.068 17.72 6.069C48.186 35.635 51 41.62 51 49.659c0 4.037-.71 6.943-1.912 9.053-1.192 2.092-2.913 3.482-5.096 4.416-2.206.943-4.882 1.42-7.947 1.653-2.896.22-6.071.22-9.453.219H26Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={17.588} cy={53.08} rx={1.529} ry={1.52} fill="#505050" />
    <Ellipse cx={35.177} cy={53.08} rx={1.529} ry={1.52} fill="#505050" />
    <Path
      d="M23.706 56.12c.623.573 2.413 1.376 4.588 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M29.44 31.42c-3.67 3.344-7.136 1.393-8.41 0-3.366-2.736.891-16.087 3.44-22.42 3.187 6.08 8.641 19.076 4.97 22.42ZM36.668 30.577c-1.755.803-2.802-.326-3.106-.991-.917-1.407 2.248-5.836 3.945-7.874.452 2.62.916 8.063-.839 8.865ZM13.833 30.577c1.754.803 2.802-.326 3.106-.991.916-1.407-2.248-5.836-3.945-7.874-.451 2.62-.915 8.063.839 8.865Z"
      fill="#CBFFA1"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M43 83h-.592c-3.382 0-6.557.001-9.453-.219-3.065-.233-5.74-.71-7.946-1.653-2.184-.934-3.905-2.324-5.097-4.416C18.71 74.602 18 71.696 18 67.66c0-8.04 2.813-14.025 7.28-18.011 4.485-4 10.73-6.069 17.72-6.069 6.99 0 13.235 2.068 17.72 6.069C65.186 53.635 68 59.62 68 67.659c0 4.037-.71 6.943-1.912 9.053-1.192 2.092-2.913 3.482-5.096 4.416-2.206.943-4.882 1.42-7.947 1.653-2.896.22-6.071.22-9.453.219H43Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={34.588} cy={71.08} rx={1.529} ry={1.52} fill="#505050" />
    <Ellipse cx={52.177} cy={71.08} rx={1.529} ry={1.52} fill="#505050" />
    <Path
      d="M40.706 74.12c.623.573 2.413 1.376 4.588 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M46.44 49.42c-3.67 3.344-7.136 1.393-8.41 0-3.366-2.736.891-16.087 3.44-22.42 3.187 6.08 8.641 19.076 4.97 22.42ZM53.668 48.577c-1.755.803-2.802-.326-3.106-.991-.917-1.407 2.248-5.836 3.945-7.874.452 2.62.916 8.063-.839 8.865ZM30.833 48.577c1.754.803 2.802-.326 3.106-.991.916-1.407-2.248-5.836-3.945-7.874-.451 2.62-.916 8.063.839 8.865Z"
      fill="#7DDBFB"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M85 94h-.894c-5.067.001-9.847.002-14.21-.332-4.62-.353-8.696-1.078-12.078-2.533-3.359-1.445-6.032-3.61-7.883-6.876C48.074 80.973 47 76.492 47 70.347c0-12.26 4.27-21.436 11.09-27.557 6.838-6.135 16.332-9.283 26.91-9.283s20.072 3.148 26.909 9.283C118.73 48.911 123 58.088 123 70.347c0 6.145-1.073 10.626-2.935 13.912-1.851 3.267-4.524 5.431-7.883 6.876-3.382 1.455-7.458 2.18-12.078 2.533-4.363.334-9.143.333-14.21.332H85Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={72.382} cy={75.507} rx={2.294} ry={2.293} fill="#505050" />
    <Ellipse cx={98.765} cy={75.507} rx={2.294} ry={2.293} fill="#505050" />
    <Path
      d="M81.559 80.093c.934.865 3.62 2.077 6.882 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M90.162 42.827c-5.506 5.045-10.706 2.102-12.618 0C72.497 38.699 78.882 18.556 82.706 9c4.78 9.173 12.962 28.781 7.456 33.827ZM101.002 41.555c-2.632 1.21-4.203-.492-4.66-1.495-1.374-2.123 3.373-8.805 5.919-11.88.677 3.953 1.373 12.164-1.259 13.375ZM66.749 41.555c2.632 1.21 4.203-.492 4.66-1.495 1.374-2.123-3.373-8.805-5.919-11.88-.677 3.953-1.373 12.164 1.259 13.375Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M105.253 79.521c-.08 3.736 3.168 12.919 5.359 17.112 4.217-.764 11.494-4.49 15.209-8.186 4.631-4.605 5.988-9.464-.025-12.073-4.809-2.087-7.295 1.503-7.937 3.56.162-2.395.185-6.925-3.454-8.504-5.875-2.549-9.045 3.08-9.152 8.091Z"
      fill="#FF7272"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default YuyuFriends
