import * as React from 'react'
import { Pressable, View } from 'react-native'
import { Questions } from '../common/questions/questions'
import {
  ableToPayBasicExpensesQuestion,
  birthdayQuestion,
  employmentStatusQuestion,
  hasOneMonthEmergencyFundQuestion,
  monthlySpendingQuestion,
  monthlyTakeHomeIncomeQuestion,
  spouseBirthdayQuestion,
  stateAndZipQuestion,
  taxFilingStatusQuestion,
  yearlyIncomeQuestion
} from '../common/questions/content/profile-questions'
import { editProfileSettingsStyle } from './edit-profile-settings.style'
import { QuestionAndAnswers } from '../common/questions/question-data'
import { ScrollWithArrow } from '../common/scroll/scroll-with-arrow'
import {
  promoCodeExists,
  promoCodeValue
} from '../common/questions/content/promo-code'

function getQuestionAndAnswersWithConditionUndefined (
  questionAndAnswers: QuestionAndAnswers
): QuestionAndAnswers {
  return {
    ...questionAndAnswers,
    condition: undefined
  }
}

// This enum is specific to the edit profile settings flow. It's used to show the
// corresponding question when someone clicks the edit button next to an answer.
// This enum is NOT meant to be used for the keys in the QuestionAndAnswers interface.
export enum EditProfileSettingsQuestionKey {
  STATE_AND_ZIP_QUESTION,
  BIRTHDAY_QUESTION,
  EMPLOYMENT_STATUS_QUESTION,
  TAXABLE_INCOME_QUESTION,
  MONTHLY_TAKE_HOME_QUESTION,
  MONTHLY_SPENDING_QUESTION,
  BASIC_EXPENSES_QUESTION,
  ONE_MONTH_EMERGENCY_FUND_QUESTION,
  TAX_FILING_STATUS_QUESTION,
  SPOUSE_BIRTHDAY_QUESTION,
  PROMO_CODE_QUESTION
}

function getQuestionsToEdit (
  profileQuestionKey: EditProfileSettingsQuestionKey
): QuestionAndAnswers[] {
  switch (profileQuestionKey) {
    case EditProfileSettingsQuestionKey.STATE_AND_ZIP_QUESTION:
      return [stateAndZipQuestion]
    case EditProfileSettingsQuestionKey.BIRTHDAY_QUESTION:
      return [birthdayQuestion]
    case EditProfileSettingsQuestionKey.EMPLOYMENT_STATUS_QUESTION:
      return [employmentStatusQuestion]
    case EditProfileSettingsQuestionKey.TAXABLE_INCOME_QUESTION:
      return [yearlyIncomeQuestion]
    case EditProfileSettingsQuestionKey.MONTHLY_TAKE_HOME_QUESTION:
      return [monthlyTakeHomeIncomeQuestion]
    case EditProfileSettingsQuestionKey.MONTHLY_SPENDING_QUESTION:
      return [monthlySpendingQuestion]
    case EditProfileSettingsQuestionKey.BASIC_EXPENSES_QUESTION:
      return [ableToPayBasicExpensesQuestion]
    case EditProfileSettingsQuestionKey.ONE_MONTH_EMERGENCY_FUND_QUESTION:
      return [hasOneMonthEmergencyFundQuestion]
    case EditProfileSettingsQuestionKey.TAX_FILING_STATUS_QUESTION:
      return [taxFilingStatusQuestion, spouseBirthdayQuestion]
    case EditProfileSettingsQuestionKey.SPOUSE_BIRTHDAY_QUESTION:
      return [
        getQuestionAndAnswersWithConditionUndefined(spouseBirthdayQuestion)
      ]
    case EditProfileSettingsQuestionKey.PROMO_CODE_QUESTION:
      return [promoCodeExists, promoCodeValue]
  }
}

export function EditProfileSettings (props: {
  profileQuestionKey: EditProfileSettingsQuestionKey
  setProfileQuestionKeyToEdit: React.Dispatch<
  React.SetStateAction<EditProfileSettingsQuestionKey | null>
  >
}): JSX.Element {
  const questionAndAnswersToEdit = getQuestionsToEdit(props.profileQuestionKey)
  if (questionAndAnswersToEdit.length === 0) {
    throw new Error(
      'profileQuestionKey: ' +
        props.profileQuestionKey.toString() +
        ' did not return a QuestionAndAnswers[] with any entries.'
    )
  }

  return (
    <>
      <Pressable
        onPress={() => props.setProfileQuestionKeyToEdit(null)}
        style={editProfileSettingsStyle.overlayContainer}
      />
      <View style={editProfileSettingsStyle.questionBox}>
        <ScrollWithArrow>
          <Questions
            questions={questionAndAnswersToEdit}
            buttonText="Save"
            onSaveState={() => props.setProfileQuestionKeyToEdit(null)}
          />
        </ScrollWithArrow>
      </View>
    </>
  )
}
