import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { AnswerType, QuestionAndAnswers } from '../question-data'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import {
  getAccountsFromContainer,
  processAllAccountsFromMemberTasksAndAccountsQuery
} from 'multi-type-processor'
import { isNonNullish, collapseProcessingResultsIntoArray } from 'global-utils'
import { stringIsWordCharsOrSpacesAndLessThan31Chars } from '../../../../../util/input-validators'

async function updateAccountNameByAccountKeyPair (
  client: ApolloClient<NormalizedCacheObject>,
  accountKeyPair: WalkthroughGraphQL.AccountKeyPair,
  newAccountName: string
): Promise<void> {
  switch (accountKeyPair.accountTypename) {
    case 'SavingsAccount': {
      await client.mutate<
      WalkthroughGraphQL.UpdateSavingsAccountMutation,
      WalkthroughGraphQL.UpdateSavingsAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.updateSavingsAccount),
        variables: {
          input: {
            accountId: accountKeyPair.accountPrimaryKey,
            accountName: newAccountName
          }
        }
      })
      return
    }
    case 'CheckingAccount': {
      await client.mutate<
      WalkthroughGraphQL.UpdateCheckingAccountMutation,
      WalkthroughGraphQL.UpdateCheckingAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.updateCheckingAccount),
        variables: {
          input: {
            accountId: accountKeyPair.accountPrimaryKey,
            accountName: newAccountName
          }
        }
      })
      return
    }
    case 'DebtAccount': {
      await client.mutate<
      WalkthroughGraphQL.UpdateDebtAccountMutation,
      WalkthroughGraphQL.UpdateDebtAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.updateDebtAccount),
        variables: {
          input: {
            accountId: accountKeyPair.accountPrimaryKey,
            accountName: newAccountName
          }
        }
      })
      return
    }
    case 'k401Account': {
      await client.mutate<
      WalkthroughGraphQL.UpdateK401AccountMutation,
      WalkthroughGraphQL.UpdateK401AccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.updateK401Account),
        variables: {
          input: {
            accountId: accountKeyPair.accountPrimaryKey,
            accountName: newAccountName
          }
        }
      })
      return
    }
    case 'TraditionalIraAccount': {
      await client.mutate<
      WalkthroughGraphQL.UpdateTraditionalIraAccountMutation,
      WalkthroughGraphQL.UpdateTraditionalIraAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.updateTraditionalIraAccount),
        variables: {
          input: {
            accountId: accountKeyPair.accountPrimaryKey,
            accountName: newAccountName
          }
        }
      })
      return
    }
    case 'RothIraAccount': {
      await client.mutate<
      WalkthroughGraphQL.UpdateRothIraAccountMutation,
      WalkthroughGraphQL.UpdateRothIraAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.updateRothIraAccount),
        variables: {
          input: {
            accountId: accountKeyPair.accountPrimaryKey,
            accountName: newAccountName
          }
        }
      })
      return
    }
    case 'OtherInvestmentAccount': {
      await client.mutate<
      WalkthroughGraphQL.UpdateOtherInvestmentAccountMutation,
      WalkthroughGraphQL.UpdateOtherInvestmentAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.updateOtherInvestmentAccount),
        variables: {
          input: {
            accountId: accountKeyPair.accountPrimaryKey,
            accountName: newAccountName
          }
        }
      })
      return
    }
    case 'UncategorizedAccount': {
      await client.mutate<
      WalkthroughGraphQL.UpdateUncategorizedAccountMutation,
      WalkthroughGraphQL.UpdateUncategorizedAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.updateUncategorizedAccount),
        variables: {
          input: {
            accountId: accountKeyPair.accountPrimaryKey,
            accountName: newAccountName
          }
        }
      })
      return
    }
    default: {
      throw new Error(
        'In updateAccountNameByAccountKeyPair: Unrecognized accountKey.typename: ' +
          accountKeyPair.accountTypename
      )
    }
  }
}

export const editAccountNameQuestion: (
  accountKeyPair: WalkthroughGraphQL.AccountKeyPair,
  memberQuery:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
) => QuestionAndAnswers = (accountKeyPair, memberQuery) => {
  const accountMap = getAccountsFromContainer(memberQuery?.getMember)

  function findAccountName<
    T extends Omit<WalkthroughGraphQL.Account, '__typename'> & {
      __typename: string
    }
  > (
    account: T | null
  ): {
      currentName: string | null | undefined
      accountNameFromDataProvider: string | null | undefined
    } | null {
    if (
      account?.accountId === accountKeyPair.accountPrimaryKey &&
      account.__typename === accountKeyPair.accountTypename
    ) {
      return {
        currentName: account.accountName,
        accountNameFromDataProvider: account.accountNameFromDataProvider
      }
    }
    return null
  }

  const accountHandlerMap = {
    savingsAccounts: findAccountName,
    checkingAccounts: findAccountName,
    debtAccounts: findAccountName,
    otherInvestmentAccounts: findAccountName,
    traditionalIraAccounts: findAccountName,
    rothIraAccounts: findAccountName,
    k401Accounts: findAccountName,
    uncategorizedAccounts: findAccountName
  }

  const accountNamesToSurface = collapseProcessingResultsIntoArray(
    processAllAccountsFromMemberTasksAndAccountsQuery(
      accountMap,
      accountHandlerMap
    )
  ).filter(isNonNullish)

  const currentName = accountNamesToSurface[0].currentName
  const accountNameFromDataProvider =
    accountNamesToSurface[0].accountNameFromDataProvider

  if (accountNamesToSurface.length === 1) {
    return {
      key: 'EDIT_ACCOUNT_NAME_QUESTION',
      question: {
        text: (
          <>
            What would you like to call this account?
            {isNonNullish(accountNameFromDataProvider)
              ? '\n\nThe name we see from your account data is "' +
                accountNameFromDataProvider +
                '"'
              : null}
          </>
        )
      },
      answers: [
        {
          key: 'NAME_ANSWER',
          getInitialAnswer: async () =>
            await Promise.resolve(currentName ?? '(unnamed)'),
          storeValue: async (client, value) => {
            if (stringIsWordCharsOrSpacesAndLessThan31Chars(value.trim())) {
              await updateAccountNameByAccountKeyPair(
                client,
                accountKeyPair,
                value.trim()
              )
            } else {
              console.log(
                "Provided value ('" +
                  value +
                  "') is not alphanumeric or short enough"
              )
            }
          },
          validation: {
            isValid: (answer: string) => {
              // Validation here does not inherently protect against XSS attacks because an attacker
              // could simply call the backend directly (via cURL or something).  This field should
              // be treated skeptically in the backend.
              return stringIsWordCharsOrSpacesAndLessThan31Chars(answer.trim())
            },
            notValidInfoText:
              'Account name must be alphanumeric and not super long.'
          },
          answerType: AnswerType.TEXT,
          textAnswer: {
            placeholderText: 'Steve the bank account'
          }
        }
      ]
    }
  } else {
    console.log(
      'In editAccountNameQuestion, did not find exactly one indicated account.  Accounts found: '
    )
    console.log(accountNamesToSurface)
    return {
      key: 'EDIT_ACCOUNT_NAME_ERROR',
      answers: [],
      question: {
        text: (
          <>
            This account is straaange... we can't seem to find it in our
            records. Please report which account this is in Feedback and we'll
            figure out what's going on.
          </>
        )
      }
    }
  }
}
