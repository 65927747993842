import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

const CheckedCircle = (props: SvgProps): JSX.Element => (
  <Svg
    width={31}
    height={31}
    fill='none'
    {...props}
  >
    <Circle cx={15.5} cy={15.5} r={14.5} stroke='#fff' strokeWidth={2} />
    <Path
      d='m9 17.8 4.136 4.2L22 10'
      stroke='#fff'
      strokeWidth={2}
      strokeLinecap='round'
    />
  </Svg>
)

export default CheckedCircle
