import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'

export async function purgeYodleeAccount (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  account: Omit<WalkthroughGraphQL.Account, '__typename'> & {
    __typename: string
  }
): Promise<boolean | null | undefined> {
  const response = await apolloClient.mutate<
  WalkthroughGraphQL.PurgeYodleeAccountMutation,
  WalkthroughGraphQL.PurgeYodleeAccountMutationVariables
  >({
    mutation: gql(WalkthroughGraphQL.purgeYodleeAccount),
    variables: {
      accountKey: {
        accountPrimaryKey: account.accountId,
        accountTypename: account.__typename
      }
    }
  })
  // purgeYodleeAccount alters backend state in complicated ways that are not returned by the
  // query.  Because they are not returned, certain cache fields (like accounts and tasks linking to accounts
  // will become stale when we call this query.  ApolloClient provides custom update functions to handle this, but it
  // can get quite complicated.  As a short term solution, we just totally reset the cache and refetch every query.
  // This obviously is not ideal from a performance perspective, but we think it is probably okay as a first
  // approximation, especially as we do not anticipate invoking this operation often.
  apolloClient.resetStore().catch(() => {})
  return response.data?.purgeYodleeAccount
}

export async function purgeWalkthroughManualAccount (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  account: Omit<WalkthroughGraphQL.Account, '__typename'> & {
    __typename: string
  }
): Promise<boolean | null | undefined> {
  const response = await apolloClient.mutate<
  WalkthroughGraphQL.PurgeWalkthroughManualAccountMutation,
  WalkthroughGraphQL.PurgeWalkthroughManualAccountMutationVariables
  >({
    mutation: gql(WalkthroughGraphQL.purgeWalkthroughManualAccount),
    variables: {
      accountKey: {
        accountPrimaryKey: account.accountId,
        accountTypename: account.__typename
      }
    }
  })
  // purgeWalkthroughManualAccount alters backend state in complicated ways that are not returned by the
  // query.  Because they are not returned, certain cache fields (like accounts and tasks linking to accounts
  // will become stale when we call this query.  ApolloClient provides custom update functions to handle this, but it
  // can get quite complicated.  As a short term solution, we just totally reset the cache and refetch every query.
  // This obviously is not ideal from a performance perspective, but we think it is probably okay as a first
  // approximation, especially as we do not anticipate invoking this operation often.
  apolloClient.resetStore().catch(() => {})
  return response.data?.purgeWalkthroughManualAccount
}

export async function purgeMxFinancialInstitutionUser (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  dataProviderIdForFinancialInstitutionUser: string
): Promise<boolean | null | undefined> {
  const response = await apolloClient.mutate<
  WalkthroughGraphQL.PurgeMxFinancialInstitutionUserMutation,
  WalkthroughGraphQL.PurgeMxFinancialInstitutionUserMutationVariables
  >({
    mutation: gql(WalkthroughGraphQL.purgeMxFinancialInstitutionUser),
    variables: {
      dataProviderIdForFinancialInstitutionUser
    }
  })
  // purgeMxFinancialInstitutionUser alters backend state in complicated ways that are not returned by the
  // query.  Because they are not returned, certain cache fields (like accounts and tasks linking to accounts
  // will become stale when we call this query.  ApolloClient provides custom update functions to handle this, but it
  // can get quite complicated.  As a short term solution, we just totally reset the cache and refetch every query.
  // This obviously is not ideal from a performance perspective, but we think it is probably okay as a first
  // approximation, especially as we do not anticipate invoking this operation often.
  apolloClient.resetStore().catch(() => {})
  return response.data?.purgeMxFinancialInstitutionUser
}
