import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../themes/theme'

export const feedbackScreenStyle = (
  theme: Theme
): {
  text: TextStyle
} =>
  StyleSheet.create({
    text: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeLarge,
      fontFamily: 'PublicSans_700Bold'
    }
  })

export const feedbackGradientStyle = (
  theme: Theme,
  index: number
): {
  feedbackScreen: ViewStyle
  shortInput: TextStyle
  longInput: TextStyle
} =>
  StyleSheet.create({
    feedbackScreen: {
      backgroundColor:
        theme.color.gradientA.colors.length > index
          ? theme.color.gradientA.colors[index]
          : theme.color.surfaceOne,
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing.horizontalSpaceMedium
    },
    shortInput: {
      backgroundColor:
        theme.color.gradientA.colors.length > index
          ? theme.color.gradientA.colors[index]
          : theme.color.surfaceTwo,
      width: '100%',
      height: 40,
      borderRadius: 20,
      marginBottom: theme.spacing.verticalSpaceSmall,
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeMedium,
      fontFamily: 'PublicSans_700Bold',
      paddingLeft: theme.spacing.horizontalSpaceMedium,
      paddingRight: theme.spacing.horizontalSpaceMedium
    },
    longInput: {
      backgroundColor:
        theme.color.gradientA.colors.length > index
          ? theme.color.gradientA.colors[index]
          : theme.color.surfaceTwo,
      width: '100%',
      height: 200,
      borderRadius: 20,
      marginBottom: theme.spacing.verticalSpaceSmall,
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeMedium,
      fontFamily: 'PublicSans_700Bold',
      paddingLeft: theme.spacing.horizontalSpaceMedium,
      paddingRight: theme.spacing.horizontalSpaceMedium,
      paddingTop: theme.spacing.verticalSpaceSmall,
      paddingBottom: theme.spacing.verticalSpaceSmall
    }
  })

export const submitButtonStyle = (
  theme: Theme,
  index: number,
  buttonDisabled: boolean
): {
  button: ViewStyle
} =>
  StyleSheet.create({
    button: {
      backgroundColor: buttonDisabled
        ? theme.color.disabled
        : theme.color.highlight,
      width: theme.buttons.smallButtonWidth,
      height: theme.buttons.buttonHeight,
      borderRadius: 20,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      marginBottom: theme.spacing.verticalSpaceSmall
    }
  })

export const feedbackThankYouStyle = (
  theme: Theme,
  showThankYou: boolean
): {
  thankYou: ViewStyle
} =>
  StyleSheet.create({
    thankYou: {
      display: showThankYou ? 'flex' : 'none',
      width: '100%',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      marginTop: theme.spacing.verticalSpaceSmall,
      marginBottom: theme.spacing.verticalSpaceSmall
    }
  })
