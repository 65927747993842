import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const SettingsIcon = (props: SvgProps): JSX.Element => (
  <Svg
    width={25}
    height={25}
    fill='none'
    {...props}
  >
    <Path
      d='M15 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM15 22.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM22.5 15a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5ZM2.5 15a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5ZM21.339 7.197a2.5 2.5 0 1 1-3.536-3.536 2.5 2.5 0 0 1 3.536 3.536ZM7.197 21.339a2.5 2.5 0 1 1-3.536-3.536 2.5 2.5 0 0 1 3.536 3.536ZM17.803 21.338a2.5 2.5 0 1 1 3.536-3.536 2.5 2.5 0 0 1-3.536 3.536ZM3.661 7.197a2.5 2.5 0 1 1 3.536-3.535A2.5 2.5 0 0 1 3.66 7.197Z'
      fill={props.color}
    />
  </Svg>
)

export default SettingsIcon
