import * as React from 'react'
import { TaskStepsList } from './task-steps-list'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import {
  TaskKey,
  TaskStepsData,
  TaskStepsRenderingStyle
} from '../task-flow-data'
import { ClickThroughModule } from '../../click-through-module/click-through-module'

// A simple wrapper class that determines how to render task steps- either as a checklist or as a clickthrough module.
export function TaskSteps (props: {
  taskSteps: TaskStepsData
  taskKeyForBackButton?: TaskKey
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  return props.taskSteps.stepRenderingStyle ===
    TaskStepsRenderingStyle.CHECKLIST ? (
    <TaskStepsList taskSteps={props.taskSteps} navigation={props.navigation} />
      ) : props.taskSteps.stepRenderingStyle ===
    TaskStepsRenderingStyle.CLICK_THROUGH_MODULE ? (
    <ClickThroughModule
      taskSteps={props.taskSteps}
      navigation={props.navigation}
      taskKeyForBackButton={props.taskKeyForBackButton}
    />
          ) : (
    <></>
          )
}
