import * as React from 'react'
import {
  TaskStepsData,
  TaskStepsRenderingStyle
} from '../../tasks/task-flow-data'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { Anchor } from '../../common/links/anchor'
import { NavigationAnchor } from '../../common/links/navigation-anchor'
import { ContentModule } from '../click-through-module-screen'

export const portfolioAllocation: TaskStepsData = {
  stepRenderingStyle: TaskStepsRenderingStyle.CLICK_THROUGH_MODULE,
  title: 'Portfolio Allocation',
  description: '',
  subtasks: [
    {
      key: 'INTRO',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          Hopefully we've convinced you that investing isn't as confusing as
          many folks make it out to be (if you want to review, click{' '}
          <NavigationAnchor
            text="here"
            handlePress={(nav) =>
              nav.push('ClickThroughModuleScreen', {
                module: ContentModule.INVESTMENT_BACKGROUND
              })
            }
          />
          ).{'\n\n'}This module will help you identify the appropriate
          "portfolio allocation" of stock and bond index funds you should buy
          and help you through the buying process. All you need are a few
          investments and your money will be invested into the stocks and bonds
          of thousands 🤯 of companies all over the world. As the world wins,
          you win 🥳.
        </>
      )
    },
    {
      // TODO(COPY_UPDATE): Incorporate FI timeline + expected age at retirement
      // into this copy.
      key: 'AGE',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          We need to answer two basic questions to determine your portfolio
          allocation. The first one is tricky...{'\n\n'}
          How old are you? 😛{'\n\n'}You obviously know how old you are, but
          what we're really getting at here is "How much longer will you be
          working?". The younger you are, the more time you have to make money,
          invest it for growth, and ride out any drops in the market, so your
          money should be more in stocks. You have to start protecting yourself
          from sudden market drops as you come to the end of your career, so
          we'll move your money more into bonds over time.{'\n\n'}If you aren't
          totally sure about when you are going to retire, age 65 is a decent
          guess to start with. If that seems off and you don't have a better
          guess, send us a note at{' '}
          <Anchor
            text={'support@walkthrough.co'}
            href={'mailto:support@walkthrough.co'}
          />
          .{'\n\n'}
          The next question is...
        </>
      )
    },
    {
      key: 'RISK',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          Question Two: What's your risk tolerance?{'\n\n'}There are three main
          levels of risk to consider here: moderate, risk-averse, and
          risk-tolerant. Most folks should fall in the moderate bucket as it's a
          good balance of risk and reward. However, if you are still feeling a
          little tentative about investing, you can consider the risk-averse
          allocation and give up some returns for some stability. On the other
          side, if you're looking for a little more bang for your buck, you can
          follow the risk-tolerant profile and historically end up with a good
          bit more money in exchange for some more risk.{'\n\n'}The story for
          any of them is the same — keep investing your money as you have it,
          let it sit for years, and enjoy how much it will have grown for you
          down the line!
        </>
      )
    },
    {
      key: 'CAVEAT',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          One question you might be asking about now is, "Walkthrough, I get how
          to invest for long-term wealth, but what about my other goals? Do I
          invest for a down payment on a future home or a vacation a few years
          from now in the same way? What about any additional cushion to my
          emergency fund?"{'\n\n'}These are excellent questions and are topics
          we will be covering soon — we'll let you know as soon as the content
          is available! If you want to prioritize these goals, reach out to us
          at{' '}
          <Anchor
            text={'support@walkthrough.co'}
            href={'mailto:support@walkthrough.co'}
          />{' '}
          and we can get you some information in advance 😉.
        </>
      )
    },
    {
      key: 'SPREADSHEET',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          Finally, we've gotten to the fun part! Let's determine an appropriate
          stock/bond split for you and learn about what index funds we should
          buy to spread our wealth across thousands of companies and governments
          across the world. This{' '}
          <Anchor
            text="spreadsheet"
            href="https://docs.google.com/spreadsheets/d/1IOx4YhmW_Bo-1u58w7usFGP2tbha28egwkn4pnZkt14/edit#gid=0"
          />{' '}
          has the sauce - check it out and keep going for an example!
        </>
      )
    },
    {
      // TODO: example of manual dynamic rebalancing?
      key: 'EXAMPLE',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          Here's an easy example of using the{' '}
          <Anchor
            text="spreadsheet"
            href="https://docs.google.com/spreadsheets/d/1IOx4YhmW_Bo-1u58w7usFGP2tbha28egwkn4pnZkt14/edit#gid=0"
          />
          .{'\n\n'}
          Let's say we're 30 years old with a balanced appetite for risk. We
          fall in the age “40 or Younger” bucket with a “Moderate” risk profile,
          so the historical research backing the spreadsheet says that we should
          invest our wealth in a 90% stocks and 10% bonds portfolio. We also see
          that the relevant funds to buy are Vanguard's Total World Stock ETF
          for stocks, labeled "VT", and their accompanying Total World Bond ETF,
          labeled "BNDW".{'\n\n'}Imagine we have $1,000 to invest in our Roth
          IRA. That means would purchase $900 of VT and $100 of BNDW right now.
          As we have more money to put into this account, we'd invest to
          preserve this 90-10 split until we age out to a different bucket!
        </>
      )
    },
    {
      key: 'M1',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          Even if you have an IRA or brokerage account with other providers like
          Vanguard or Fidelity, we recommend automating your investments through{' '}
          <Anchor
            text="M1 Finance"
            href="https://help.m1.com/hc/en-us/articles/220988208-Account-types-that-M1-supports"
          />{' '}
          (
          <NavigationAnchor
            text="no kickbacks"
            handlePress={(nav) =>
              nav.push('ClickThroughModuleScreen', {
                module: ContentModule.NO_KICKBACKS
              })
            }
          />
          !).{'\n\n'}M1 makes it really easy to adopt investment portfolios we
          recommend through their Pie system, like{' '}
          <Anchor
            text="the 90-10 investment"
            href="https://m1.finance/saxgKStD3tN_"
          />{' '}
          in our example, and they make sure that extra money is automatically
          invested to maintain the target allocations you've set through{' '}
          <Anchor
            text="dynamic rebalancing"
            href="https://help.m1.com/hc/en-us/articles/4404766862739-Rebalancing-#h_01FC8VVBDJCZ69HE40QK817GWK"
          />
          . Better yet, there are no transaction fees and you can set up
          auto-investments from your checking account. It makes things as easy
          and cheap as possible!{'\n\n'}If you want to keep your existing
          providers, we totally get it — just know that you might have to be a
          little more hands-on to make sure you are consistently contributing to
          your portfolio investing with your target allocation in mind.
        </>
      )
    },
    {
      key: 'EXECUTE',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          We recommend writing down your investment plan right now. Something
          like, "90% in stocks by buying VT, 10% in bonds by buying BNDW,
          purchased through my XYZ account at ABC bank." Take this with you back
          to your financial task and invest your money accordingly!{'\n\n'}
          Always let us know if you have any questions by writing{' '}
          <Anchor
            text={'support@walkthrough.co'}
            href={'mailto:support@walkthrough.co'}
          />
          , especially if your plan is a big change from what you've currently
          been doing. We promise to get back to you quickly 😊.
        </>
      )
    }
  ],
  button: {
    buttonText: 'Done',
    handleOnPressButton: (props: {
      navigation: GenericCompositeNavigationProp
    }) => {
      props.navigation.goBack()
    }
  }
}
