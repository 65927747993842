import * as React from "react"
import Svg, { SvgProps, Path, Ellipse } from "react-native-svg"

const YuyuBalloons = (props: SvgProps): JSX.Element => (
  <Svg
    width={127}
    height={151}
    fill="none"
    {...props}
  >
    <Path
      d="M94.749 75.598c-.338-2.728-3.36-4.204-3.125-4.7l-.047.016c-3.481 1.193-6.718 2.302-6.149 4.443.229.86 1.175.236 2.35.471.782.157.232 1.676 1.643 1.96 1.175.235 1.723-.47 1.959-1.644.235-1.175 3.834 3.213 3.369-.546Z"
      fill="#91E5E5"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M75.78 34.169c-1.294 6.448-.398 15.032 2.403 22.316 2.818 7.33 7.421 13 13.286 14.177 5.865 1.176 12.299-2.28 17.726-7.955 5.394-5.64 9.531-13.215 10.824-19.664 2.573-12.824-5.299-25.141-17.476-27.584-12.177-2.442-24.19 5.886-26.763 18.71Z"
      fill="#91E5E5"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M89.597 77.82c-.21 2.4 1.295 6.75-7.097 12.18-8.501 5.5-16.036 11-14 22.5 1.42 8.021 6.537 20.343 2.644 25.536"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M69.378 75.16c-1.201-2.472-4.538-2.893-4.476-3.438l-.04.03c-2.908 2.254-5.613 4.35-4.382 6.192.495.74 1.188-.157 2.376-.313.791-.105.761 1.51 2.189 1.322 1.188-.156 1.478-1 1.322-2.188-.157-1.188 4.667 1.801 3.011-1.606Z"
      fill="#75B7E7"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M38.036 42.085c.86 6.52 4.482 14.354 9.487 20.342 5.037 6.026 11.225 9.904 17.156 9.121 5.93-.782 10.901-6.132 14.203-13.257 3.281-7.081 4.748-15.586 3.888-22.106-1.71-12.968-13.142-22.08-25.454-20.455-12.313 1.624-20.99 13.388-19.28 26.355Z"
      fill="#75B7E7"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M66.331 77.978c.577 2.34 8.162 6.27 4.022 15.367-3.122 6.856-2.64 10.893 2.127 19.528 3.935 7.131.67 18.99-1.335 25.163"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M69.674 69.683c1.217-2.67 4.77-3.207 4.689-3.788l.042.032c3.165 2.33 6.107 4.496 4.841 6.495-.508.804-1.272-.136-2.544-.272-.848-.09-.773 1.633-2.302 1.47-1.273-.137-1.605-1.03-1.469-2.302.136-1.272-4.936 2.045-3.257-1.635Z"
      fill="#FF7272"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M102.335 33.557c-.749 6.998-4.416 15.47-9.61 22.002-5.223 6.57-11.749 10.896-18.136 10.213-6.388-.684-11.85-6.292-15.566-13.82-3.693-7.483-5.486-16.538-4.737-23.536C55.776 14.492 67.772 4.434 81 5.849c13.228 1.416 22.825 13.784 21.335 27.708Z"
      fill="#FF7272"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M73.001 72.612c-.554 2.514-.568 6.78 4.998 15.888 5.501 9-2.994 13.492-7.856 22.836-4.015 7.717-1.344 20.587.96 27.124"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M39 149.493h-.894c-5.067.001-9.847.002-14.21-.332-4.62-.353-8.696-1.078-12.078-2.533-3.359-1.445-6.032-3.609-7.883-6.876C2.073 136.466 1 131.985 1 125.84c0-12.259 4.27-21.436 11.09-27.557C18.929 92.148 28.423 89 39 89s20.072 3.148 26.91 9.283C72.73 104.404 77 113.581 77 125.84c0 6.145-1.073 10.626-2.935 13.912-1.85 3.267-4.524 5.431-7.883 6.876-3.382 1.455-7.458 2.18-12.078 2.533-4.363.334-9.143.333-14.21.332H39Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={26.382} cy={130.506} rx={2.294} ry={2.293} fill="#505050" />
    <Ellipse cx={52.765} cy={130.506} rx={2.294} ry={2.293} fill="#505050" />
    <Path
      d="M35.559 135.093c.934.865 3.62 2.077 6.882 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M44.162 97.826c-5.506 5.046-10.706 2.103-12.618 0-5.047-4.128 1.338-24.27 5.162-33.826 4.78 9.173 12.962 28.781 7.456 33.826ZM55.002 96.555c-2.632 1.21-4.203-.493-4.66-1.496-1.374-2.122 3.373-8.804 5.919-11.88.677 3.954 1.373 12.165-1.259 13.376ZM20.748 96.555c2.632 1.21 4.203-.493 4.66-1.496 1.374-2.122-3.373-8.804-5.919-11.88-.677 3.954-1.373 12.165 1.259 13.376Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
)

export default YuyuBalloons
