import * as React from "react"
import Svg, { SvgProps, Path, Ellipse } from "react-native-svg"

const YuyuCartwheel = (props: SvgProps): JSX.Element => (
  <Svg
    width={274}
    height={116}
    fill="none"
    {...props}
  >
    <Path
      d="M235 115h-.894c-5.067.001-9.847.002-14.21-.332-4.62-.353-8.696-1.078-12.078-2.533-3.359-1.445-6.032-3.609-7.883-6.876-1.862-3.286-2.935-7.767-2.935-13.912 0-12.26 4.27-21.436 11.091-27.557 6.837-6.135 16.331-9.283 26.909-9.283s20.072 3.148 26.909 9.283C268.73 69.911 273 79.088 273 91.347c0 6.145-1.073 10.626-2.935 13.912-1.851 3.267-4.524 5.431-7.883 6.876-3.382 1.455-7.458 2.18-12.078 2.533-4.363.334-9.143.333-14.21.332H235Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={222.382} cy={96.507} rx={2.294} ry={2.293} fill="#505050" />
    <Ellipse cx={248.765} cy={96.507} rx={2.294} ry={2.293} fill="#505050" />
    <Path
      d="M231.559 101.093c.934.865 3.619 2.077 6.882 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M240.162 63.827c-5.506 5.045-10.706 2.102-12.618 0-5.047-4.128 1.338-24.271 5.162-33.827 4.779 9.173 12.962 28.781 7.456 33.827ZM251.002 62.555c-2.632 1.21-4.203-.492-4.66-1.495-1.374-2.123 3.373-8.805 5.919-11.88.677 3.953 1.373 12.164-1.259 13.375ZM216.748 62.555c2.632 1.21 4.203-.492 4.66-1.495 1.374-2.123-3.373-8.805-5.919-11.88-.677 3.953-1.373 12.164 1.259 13.375Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="m28.284 87.681-.632-.632c-3.584-3.582-6.964-6.961-9.814-10.283-3.017-3.517-5.386-6.911-6.749-10.331-1.353-3.397-1.713-6.818-.712-10.437 1.008-3.64 3.417-7.567 7.763-11.912 8.668-8.669 18.176-12.138 27.327-11.643 9.173.496 18.113 4.983 25.593 12.463 7.48 7.48 11.966 16.42 12.463 25.592.495 9.152-2.975 18.66-11.643 27.328-4.346 4.345-8.273 6.755-11.913 7.762-3.619 1.001-7.04.641-10.436-.712-3.42-1.362-6.815-3.732-10.332-6.749-3.321-2.85-6.7-6.23-10.282-9.813l-.633-.633Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse
      cx={32.438}
      cy={65.682}
      rx={2.294}
      ry={2.293}
      transform="rotate(45 32.438 65.682)"
      fill="#505050"
    />
    <Ellipse
      cx={51.094}
      cy={84.338}
      rx={2.294}
      ry={2.293}
      transform="rotate(45 51.094 84.338)"
      fill="#505050"
    />
    <Path
      d="M35.684 75.414c.049 1.273 1.09 4.028 4.866 4.867"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M68.118 55.146c-7.46-.326-9.056-6.084-8.922-8.922-.65-6.488 18.109-16.216 27.57-20.27-3.108 9.867-11.187 29.518-18.648 29.192ZM76.683 61.912c-2.717-1.005-2.624-3.32-2.237-4.352.529-2.473 8.61-3.84 12.585-4.216-2.317 3.275-7.631 9.573-10.348 8.568ZM52.462 37.691c1.004 2.717 3.32 2.624 4.352 2.237 2.472-.529 3.84-8.61 4.215-12.585-3.274 2.317-9.572 7.631-8.568 10.348Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="m129.457 22.187.827-.34c4.687-1.925 9.108-3.742 13.271-5.091 4.408-1.428 8.454-2.306 12.135-2.245 3.655.061 6.951 1.048 9.904 3.367 2.97 2.332 5.665 6.07 8 11.754 4.657 11.34 4.193 21.45.208 29.704-3.994 8.272-11.58 14.791-21.365 18.81-9.785 4.018-19.764 4.712-28.419 1.634-8.635-3.07-16.07-9.938-20.727-21.278-2.335-5.684-3.044-10.237-2.57-13.984.471-3.725 2.122-6.743 4.68-9.355 2.575-2.63 6.07-4.85 10.21-6.931 3.909-1.967 8.331-3.782 13.019-5.705l.827-.34Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse
      cx={148.153}
      cy={34.501}
      rx={2.294}
      ry={2.293}
      transform="rotate(157.674 148.153 34.5)"
      fill="#505050"
    />
    <Ellipse
      cx={123.749}
      cy={44.523}
      rx={2.294}
      ry={2.293}
      transform="rotate(157.674 123.749 44.523)"
      fill="#505050"
    />
    <Path
      d="M137.922 33.744c-1.193-.446-4.137-.546-6.367 2.614"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M144.121 71.485c3.176-6.759 9.105-6.011 11.672-4.793 6.237 1.901 7.982 22.96 8.075 33.252-7.906-6.67-22.924-21.7-19.747-28.459ZM134.576 76.78c1.974-2.12 4.075-1.142 4.878-.388 2.078 1.442.225 9.426-.962 13.238-2.128-3.4-5.891-10.731-3.916-12.85ZM166.262 63.767c-2.894-.12-3.701 2.052-3.742 3.153-.465 2.486 6.465 6.864 9.988 8.742-.876-3.915-3.351-11.775-6.246-11.895Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
)

export default YuyuCartwheel
