import * as React from "react"
import Svg, { SvgProps, Circle, Path } from "react-native-svg"

const CloseCircle = (props: SvgProps) => (
<Svg
width={37}
height={37}
fill="none"
{...props}
>
<Circle
cx={18.5}
cy={18.5}
r={17.5}
fill="#FF7991"
stroke="#202232"
strokeWidth={2}
/>
<Path
d="M24 13 13 24M13 13l11 11"
stroke="#fff"
strokeWidth={3}
strokeLinecap="round"
/>
</Svg>
)
export default CloseCircle
