import * as React from 'react'
import { Pressable, ScrollView, View } from 'react-native'
import { Gradient } from '../../../../themes/theme'
import { useImmer } from 'use-immer'
import { WritableDraft } from 'immer/dist/types/types-external'
import { useDidUpdateEffect } from '../../../../util/use-did-update-effect'
import {
  drawersStyle,
  drawerStyle,
  expandableDrawerStyle
} from './drawers.style'

export interface DrawerWithNodes {
  // The unique key for this drawer for React.
  key: string
  // The title for this drawer. This will be displayed when the drawer is both closed & open.
  title: React.ReactNode
  // The content for this drawer. This will only be displayed when the drawer open.
  content: React.ReactNode
}

// A reusable screen with stacked expandable drawers. Each drawer contains a customizable title
// and content.
export function Drawers (props: {
  colorGradient: Gradient
  drawers: DrawerWithNodes[]
}): JSX.Element {
  function createDrawersState (
    drawers: DrawerWithNodes[]
  ): Map<string, boolean> {
    const drawersState = new Map<string, boolean>()
    for (const drawer of drawers) {
      drawersState.set(drawer.key, false)
    }
    return drawersState
  }

  // Updates the drawers state, using the new value of 'drawers'. If a drawer key exists in in the
  // previously stored drawers in 'draft', we use the draft's drawer's isOpen value to maintain
  // state of currently opened drawers. Note that we only use the new rowStates in 'drawers', and
  // don't merge the previously stored rowStates in draft.
  function updateDrawersState (
    drawers: DrawerWithNodes[],
    draft: Map<string, WritableDraft<boolean>>
  ): Map<string, boolean> {
    const updatedDrawersState = new Map<string, boolean>()
    for (const drawer of drawers) {
      updatedDrawersState.set(
        drawer.key,
        draft.get(drawer.key)?.valueOf() ?? false
      )
    }
    return updatedDrawersState
  }

  const [drawersState, setDrawersState] = useImmer(
    createDrawersState(props.drawers)
  )

  // If drawersData is updated, fully overwrite drawers state.
  // This has the effect of closing drawers if new drawers are added, which seems fine for now.
  // We could theoretically write a much more complicated merging function (and require drawer key persistence from our
  // clients) if we really wanted to solve this.
  useDidUpdateEffect(
    () =>
      setDrawersState((draft) => {
        return updateDrawersState(props.drawers, draft)
      }),
    [props.drawers]
  )

  function onUpdateDrawerIsOpen (drawerKey: string): void {
    setDrawersState((draft) => {
      const drawerIsOpen = draft.get(drawerKey)
      if (drawerIsOpen !== undefined) {
        draft.set(drawerKey, !drawerIsOpen)
      }
    })
  }

  function getDrawerColor (index: number, colorGradient: Gradient): string {
    if (colorGradient.colors.length === 0) {
      throw new Error('Gradient does not have any colors.')
    }
    if (colorGradient.colors.length === 1) {
      return colorGradient.colors[0]
    }
    // If there is more than one color in the gradient, start with the second color in the gradient so the
    // title can be the first color.
    const colorIndex = index + 1
    // Snake back and forth through the gradient.  The last color in the gradient is the first 'else' execution
    if (Math.floor(colorIndex / (colorGradient.colors.length - 1)) % 2 === 0) {
      return colorGradient.colors[colorIndex % colorGradient.colors.length]
    } else {
      return colorGradient.colors[
        colorGradient.colors.length -
          1 -
          (colorIndex % (colorGradient.colors.length - 1))
      ]
    }
  }

  // Get the CSS z-index property that sets the z-axis order of this drawer. Overlapping
  // drawers with a larger z-index cover those with a smaller one.
  function getZIndex (index: number): number {
    return -1 - index
  }

  return (
    <View style={drawersStyle.drawersScreen}>
      <ScrollView style={drawersStyle.drawerScrollView}>
        {props.drawers.map((drawer, index) => (
          <View
            key={drawer.key}
            style={
              drawerStyle(
                getDrawerColor(index, props.colorGradient),
                getZIndex(index)
              ).drawer
            }
          >
            <View style={drawersStyle.drawerView}>
              <Pressable onPress={() => onUpdateDrawerIsOpen(drawer.key)}>
                <View
                  style={
                    expandableDrawerStyle(drawersState.get(drawer.key) ?? false)
                      .drawerHeaderView
                  }
                >
                  {drawer.title}
                </View>
              </Pressable>
              <View
                style={
                  expandableDrawerStyle(drawersState.get(drawer.key) ?? false)
                    .drawerContent
                }
              >
                {drawer.content}
              </View>
            </View>
          </View>
        ))}
        <View style={drawersStyle.drawerScrollViewExtraPadding} />
      </ScrollView>
    </View>
  )
}
