import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import { buttonStyle, taskStyle } from './task-steps-list.style'
import { Subtask } from './subtask'
import { THEME } from '../../../../constants'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { TaskStepsData, SubtaskData } from '../task-flow-data'
import { SubtaskDetails } from './subtask-details'
import { BackButton } from '../../../common/buttons/back-button'
import { ScrollWithArrow } from '../../common/scroll/scroll-with-arrow'

export function TaskStepsList (props: {
  taskSteps: TaskStepsData
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  const style = taskStyle(THEME)

  function numCompleteTasks (): number {
    let count = 0
    props.taskSteps.subtasks.forEach((t) => {
      if (t.isComplete) {
        count++
      }
    })
    return count
  }

  const useNextButton = numCompleteTasks() === props.taskSteps.subtasks.length

  // If this is defined, render the subtask details view.  If it is undefined, render the task overview.
  const [subtaskDetailsToShow, setSubtaskDetailsToShow] = React.useState<
  SubtaskData | undefined
  >(undefined)

  React.useLayoutEffect(() => {
    // If subtask details are undefined, we're on the task checklist page. There's no back button
    // on this page because the button at the bottom takes folks to the list of all tasks.
    if (subtaskDetailsToShow === undefined) {
      props.navigation.setOptions({
        headerTitle: 'Your task',
        headerTitleAlign: 'center',
        headerLeft: undefined
      })
    } else {
      // If subtask details are defined, we're on the subtask details page. The back button on this page
      // takes the member back to the task steps list page by clearing the subtask details.
      props.navigation.setOptions({
        headerTitle: subtaskDetailsToShow.title,
        headerTitleAlign: 'left',
        headerLeft: () => (
          <BackButton
            handleOnPress={() => {
              setSubtaskDetailsToShow(undefined)
            }}
          />
        )
      })
    }
  }, [props.navigation, subtaskDetailsToShow])

  return subtaskDetailsToShow === undefined ? (
    <View style={style.mainBox}>
      <View style={style.taskBox}>
        <ScrollWithArrow>
          <View style={style.contentBox}>
            <View style={style.subContentBox}>
              <Text style={style.title}>{props.taskSteps.title}</Text>
              <Text style={style.description}>
                {props.taskSteps.description}
              </Text>
            </View>
            {props.taskSteps.subtasks.map((subtask) => (
              <Subtask
                key={subtask.key}
                subtask={subtask}
                onOpenDetails={() => {
                  setSubtaskDetailsToShow(subtask)
                }}
              />
            ))}
          </View>
        </ScrollWithArrow>
      </View>
      <Pressable
        onPress={() => {
          if (useNextButton) {
            props.taskSteps.button.handleOnPressButton({
              navigation: props.navigation
            })
          } else {
            props.navigation.navigate('TaskListScreen')
          }
        }}
      >
        <View style={buttonStyle(THEME, useNextButton).button}>
          <Text style={style.buttonText}>
            {useNextButton ? props.taskSteps.button.buttonText : 'Change tasks'}
          </Text>
        </View>
      </Pressable>
    </View>
  ) : (
    <SubtaskDetails
      subtask={subtaskDetailsToShow}
      navigation={props.navigation}
      onMarkSubtaskDone={() => {
        if (subtaskDetailsToShow.storeCompletionState !== undefined) {
          subtaskDetailsToShow.storeCompletionState(true)
        }
        setSubtaskDetailsToShow(undefined)
      }}
    />
  )
}
