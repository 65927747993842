import { StyleSheet, ViewStyle } from 'react-native'
import { Theme } from '../../../themes/theme'

export const onboardingScreenStyle = (
  theme: Theme
): {
  onboardingScreen: ViewStyle
  questionBox: ViewStyle
  jungleBackground: ViewStyle
} =>
  StyleSheet.create({
    onboardingScreen: {
      backgroundColor: theme.color.background,
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%'
    },
    questionBox: {
      maxHeight: '80%',
      width: '90%',
      marginLeft: theme.spacing.horizontalSpaceMedium,
      marginRight: theme.spacing.horizontalSpaceMedium,
      borderRadius: 20,
      backgroundColor: theme.color.surfaceOne,
      borderColor: theme.color.outline,
      borderWidth: 2
    },
    jungleBackground: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: 0,
      zIndex: -3
    }
  })
