import * as React from 'react'
import { Text } from 'react-native'
import {
  TaskFlowStage,
  TaskFlowData,
  TaskStepsRenderingStyle
} from '../task-flow-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { TaskFlowDataConverter } from './get-task-flow-data'
import { Anchor } from '../../common/links/anchor'
import { NavigationAnchor } from '../../common/links/navigation-anchor'
import { gql } from '@apollo/client'
import { extractBooleanFromJsonMap } from 'global-utils'
import { ContentModule } from '../../click-through-module/click-through-module-screen'
import { contentStyle } from './content.style'
import { getTaskTitle } from './get-task-title'

const firstIntro =
  'This is the end game, folks.\n\nYour tax-advantaged accounts are all maxed out 🤑. Your emergency fund ' +
  'is looking firm 💪🏽. Your debts are manageable and strategically acquired 🧐.\n\nNow, whatever excess money you ' +
  'have should be invested in a standard brokerage account with whatever long-term goals you have in mind 🌄.'

const returningIntro =
  "You thought you completed this, didn't you?  You thought \"man, these people at Walkthrough said this task couldn't " +
  'be completed, but I just checked off the box and completed it no problem!"\n\nNope. Not done yet!'

const steps =
  'Long term wealth is built by just two things:\n\n1) Saving as much as you can.\n\n2) Investing that ' +
  "money.\n\nIf you're on this task, you're already doing a great job. But it's time to dial it up even more."

// See Savings Rate content module for canonical copy on savings rate.
// TODO: consider linking to compound growth module too.
const savingsRateKey = 'SAVINGS_RATE'
const savingsRate = (
  <>
    Your financial freedom really just depends on one number: your savings rate.
    Take the amount you save every year* and divide it by your after-tax income.
    The higher the percentage, the faster you'll build your wealth. Who ever
    said math was hard?{'\n\n'}It's hard to overstate how important this number
    is. It's REAAALLLLLY important.{' '}
    <Text style={contentStyle.boldText}>
      Take a look at your savings rate by clicking{' '}
      <NavigationAnchor
        text="here"
        handlePress={(nav) =>
          nav.navigate('ClickThroughModuleScreen', {
            module: ContentModule.SAVINGS_RATE
          })
        }
      />{' '}
      or heading over to the Jungle page of the app.
    </Text>
    {'\n\n'}
    {/* TODO(COPY_UPDATE): add personalized effective tax rate instead of (20-something percent?) */}
    This is the final task, so you get to decide what your target rate should
    be. We suggest saving just a little more than you think you can. What's life
    without a challenge, right?{'\n\n'}* Technically, you need to pretend all
    your savings were taxed, so you should shrink savings that you put in a
    pre-tax IRA or 401k by your effective tax rate (20-something percent?).
    Okay, math is a little hard.
  </>
)

const learnBrokerageKey = 'LEARN'
const learnBrokerage =
  'Brokerage accounts are like regular bank accounts, except they let you buy and sell ' +
  "investments. Your long-term savings should all be invested, so that's the right place to put money for this " +
  'task.\n\nA good brokerage account is free and charges low (or zero) transaction fees when you buy or sell ' +
  'investments. It\'s an added perk if the account offers "vaults" or "buckets" — remember that the more you ' +
  'separate out your savings, the less likely you are to dip into them.'

const openBrokerageKey = 'OPEN'
const openBrokerage = (
  <>
    Choose a brokerage account where you'll keep your long-term savings. If you
    like the bank you already use, just open another account with them.
    Otherwise, we recommend opening it with{' '}
    <Anchor
      text="M1 Finance"
      href="https://help.m1.com/hc/en-us/articles/221052687-Open-my-account"
    />{' '}
    (
    <NavigationAnchor
      text="no kickbacks"
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.NO_KICKBACKS
        })
      }
    />
    !). Their accounts are free and have zero transaction fees, and we can give
    you a portfolio allocation to mirror with a single click.{'\n\n'}After your
    brokerage account is ready, move on to the next step.
  </>
)

const investmentPlanKey = 'INVESTMENT_PLAN'
const investmentPlan = (
  <>
    Your long-term savings should be invested. If you don't invest, your money
    will be sitting as cash, being eaten away by inflation and missing out on
    compound returns 🧛{'\n\n'}But, before you do that (or anything else with
    your money!!), you should understand what it means.{' '}
    <NavigationAnchor
      text={"Here's"}
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.INVESTMENT_BACKGROUND
        })
      }
    />{' '}
    the basic investing info you need to know.{'\n\n'}
    You also have to decide what investments you are going to buy. We like
    passive, low-cost index funds (basically buying a little bit of the whole
    economy). They aren't fancy, aren't confusing, and get the job done. To make
    your plan,{' '}
    <NavigationAnchor
      text="click here"
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.PORTFOLIO_ALLOCATION
        })
      }
    />
    {'.\n\n'}
    {/* Note: this warning makes this subtask a little long, but we were
     * concerned that people would be frustrated if this were a followup
     * subtask to this one ("I just spent all this time making a plan and
     * now you say there's a big warning that changes everything?!?").
     * There's not a great place to talk about it before this subtask,
     * either.  If members complain it's way too long, we can figure out a different
     * way to break the copy up. */}
    One word of warning: if you have a lot of existing investments in a
    brokerage account that you're planning to buy or sell as part of this plan,
    you may have to pay capital gains taxes, which you may not want to do.
    Before doing any significant rebalancing in existing brokerage accounts,
    please check in with us at{' '}
    <Anchor
      text={'support@walkthrough.co'}
      href={'mailto:support@walkthrough.co'}
    />
    {'\n\n'}
    Once you've decided on your investment plan, move on to the next step.
  </>
)

const startSavingKey = 'START'
const startSaving = (
  <>
    Start funneling money into your account. We recommend going to your
    day-to-day account's website and setting up automatic transfers. If you
    don't see an option, call your bank and ask them. Set up the transfers to
    happen the day after you receive your paycheck — that way the money won't
    sit in your bank account and tempt you.{'\n\n'}
    Every month or two, invest the cash that is building up in your brokerage
    account according to your investment plan. We suggest putting a reminder on
    your calendar right NOW. Even better, use M1's{' '}
    <Anchor
      text="dynamic
      rebalancing"
      href="https://help.m1.com/hc/en-us/articles/4404766862739-Rebalancing-#h_01FC8VVBDJCZ69HE40QK817GWK"
    />{' '}
    to auto-invest according to your plan (
    <NavigationAnchor
      text="no kickbacks"
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.NO_KICKBACKS
        })
      }
    />
    !).
    {'\n\n'}
    If you become aware of additional money building up in your daily account,
    increase your automatic transfers. It's all about pushing that savings rate
    as high as you can.
  </>
)

const dontStopKey = 'DONT_STOP'
const dontStop =
  "Saving money is the path to financial freedom. Don't stop! Keep upping that savings rate!\n\nWe'll help out by making it impossible to " +
  'check off this task in the app. You can try! But you will not succeed. Muah ha ha ha.\n\nOf course, at some point you will ' +
  "have actually saved enough to be financially independent 🏁🏁🏁. That's based on your savings rate, remember?\n\nWhen " +
  "you get there, it will actually be time to check off this task.  But we still won't let you.\n\nIt's a bug, not a feature."

export const investInBrokerageAccountTaskToFlowData: TaskFlowDataConverter<
WalkthroughGraphQL.InvestInBrokerageAccountTask
> =
  (
    taskTypename,
    taskId,
    absoluteDollarsToAllocateToTask,
    currentlyAllocatedDollars,
    client,
    recommendedByWalkthrough
  ) =>
    (task) => {
      if (task === null) {
        return null
      }
      if (task.__typename !== taskTypename) {
        return null
      }
      if (task.owner !== taskId) {
        return null
      }

      // Note that we refer to the JS object (not the JSON string!) when writing this back to the DB. This means that each
      // time the object passes through the FE it is revalidated for JSON parseability (although we still lack enforcement
      // that it conforms to a {[key: string]: boolean} type)
      const subtaskCompletionState: any = JSON.parse(
        task.subtaskCompletion ?? '{}'
      )

      // Generate a storage function to associate with a subtask.
      // We define it as an arrow function so it is interpreted within the control flow (needed for the compiler to infer
      // that task is not null). See https://github.com/microsoft/TypeScript/issues/32339 and 32300 for more.
      const storeCompletionGenerator = (key: string) => {
        return (complete: boolean) => {
          client
            .mutate<
          WalkthroughGraphQL.UpdateInvestInBrokerageAccountTaskMutation,
          WalkthroughGraphQL.UpdateInvestInBrokerageAccountTaskMutationVariables
          >({
            mutation: gql(
              WalkthroughGraphQL.updateInvestInBrokerageAccountTask
            ),
            variables: {
              input: {
                owner: task.owner,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            },
            optimisticResponse: {
              updateInvestInBrokerageAccountTask: {
                ...task,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            }
          })
            .catch(console.log)
        }
      }

      const flowData: TaskFlowData = {
        steps: {
          stepRenderingStyle: TaskStepsRenderingStyle.CHECKLIST,
          title: getTaskTitle(
            task.__typename,
            task.stepsCompletedAtLeastOnce ?? false
          ),
          description: steps,
          subtasks: [
            {
              key: savingsRateKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                savingsRateKey
              ),
              storeCompletionState: storeCompletionGenerator(savingsRateKey),
              title: 'Dial up the savings (rate)',
              description: savingsRate
            },
            {
              key: learnBrokerageKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                learnBrokerageKey
              ),
              storeCompletionState: storeCompletionGenerator(learnBrokerageKey),
              title: 'Refresher on brokerage accounts',
              description: learnBrokerage
            },
            {
              key: openBrokerageKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                openBrokerageKey
              ),
              storeCompletionState: storeCompletionGenerator(openBrokerageKey),
              title: 'Choose or open an account',
              description: openBrokerage
            },
            {
              key: investmentPlanKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                investmentPlanKey
              ),
              storeCompletionState: storeCompletionGenerator(investmentPlanKey),
              title: 'Plan to invest',
              description: investmentPlan
            },
            {
              key: startSavingKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                startSavingKey
              ),
              storeCompletionState: storeCompletionGenerator(startSavingKey),
              title: 'Start Investing',
              description: startSaving
            },
            {
              key: dontStopKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                dontStopKey
              ),
              storeCompletionState: storeCompletionGenerator(dontStopKey),
              title: "Can't stop (Won't stop)",
              description: dontStop
            }
          ],
          button: {
            buttonText: 'Next',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              const response = client.mutate<
              WalkthroughGraphQL.UpdateInvestInBrokerageAccountTaskMutation,
              WalkthroughGraphQL.UpdateInvestInBrokerageAccountTaskMutationVariables
              >({
                mutation: gql(
                  WalkthroughGraphQL.updateInvestInBrokerageAccountTask
                ),
                variables: {
                  input: {
                    owner: task.owner,
                    isComplete: true,
                    stepsCompletedAtLeastOnce: true
                  }
                }
              })
              props.navigation.navigate('TaskScreen', {
                flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL,
                waitForPromise: response
              })
            }
          }
        },
        introData: {
          title: getTaskTitle(
            task.__typename,
            task.stepsCompletedAtLeastOnce ?? false
          ),
          introContent:
          task.stepsCompletedAtLeastOnce === true ? returningIntro : firstIntro,
          button: {
            buttonText: 'Invest in your brokerage account',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
            // Always uncheck the "Don't Stop" task if you're going back to this task
              storeCompletionGenerator(dontStopKey)(false)
              props.navigation.navigate('TaskScreen', {
                taskKey: {
                  taskTypename: task.__typename,
                  taskId: task.owner
                },
                flowStage: TaskFlowStage.STEPS,
                absoluteDollarsToAllocateToTask: absoluteDollarsToAllocateToTask,
                recommendedByWalkthrough: recommendedByWalkthrough
              })
            }
          },
          alternativeButton: {
            buttonText: 'Change tasks',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskListScreen')
            }
          }
        }
      }
      return flowData
    }
