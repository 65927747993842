import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../../themes/theme'

export const questionsStyle = (
  theme: Theme
): {
  onboardingScreen: ViewStyle
  questionBox: ViewStyle
  contentBox: ViewStyle
  questionAndAnswers: ViewStyle
  question: TextStyle
  buttonText: TextStyle
  picker: ViewStyle
  pickerDownArrowView: ViewStyle
  jungleBackground: ViewStyle
  notValidInfoText: TextStyle
  shortInput: ViewStyle
} =>
  StyleSheet.create({
    onboardingScreen: {
      backgroundColor: theme.color.background,
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%'
    },
    questionBox: {
      maxHeight: '80%',
      width: '90%',
      marginLeft: theme.spacing.horizontalSpaceMedium,
      marginRight: theme.spacing.horizontalSpaceMedium,
      borderRadius: 20,
      backgroundColor: theme.color.surfaceOne,
      borderColor: theme.color.outline,
      borderWidth: 2
    },
    contentBox: {
      margin: theme.spacing.horizontalSpaceMedium
    },
    questionAndAnswers: {
      marginBottom: theme.spacing.verticalSpaceSmall
    },
    question: {
      width: '100%',
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeMedium,
      fontFamily: 'PublicSans_400Regular'
    },
    buttonText: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeLarge,
      fontFamily: 'PublicSans_700Bold'
    },
    picker: {
      backgroundColor: 'transparent',
      width: '100%',
      height: theme.buttons.buttonHeight,
      borderWidth: 0,
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeMedium,
      fontFamily: 'PublicSans_700Bold'
    },
    pickerDownArrowView: {
      backgroundColor: theme.color.surfaceTwo,
      width: '20%',
      height: theme.buttons.buttonHeight,
      borderRadius: 20,
      paddingRight: theme.spacing.horizontalSpaceMedium,
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'flex-end',
      position: 'absolute',
      right: 0
    },
    jungleBackground: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: 0,
      zIndex: -3
    },
    notValidInfoText: {
      color: theme.color.superHighlight,
      fontSize: theme.font.fontSizeSmall,
      fontFamily: 'PublicSans_400Regular',
      paddingLeft: theme.spacing.horizontalSpaceMedium,
      paddingRight: theme.spacing.horizontalSpaceMedium
    },
    shortInput: {
      backgroundColor: theme.color.surfaceTwo,
      width: '100%',
      height: theme.buttons.buttonHeight,
      borderRadius: 20,
      marginTop: theme.spacing.verticalSpaceSmall,
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeMedium,
      fontFamily: 'PublicSans_700Bold',
      paddingLeft: theme.spacing.horizontalSpaceMedium,
      paddingRight: theme.spacing.horizontalSpaceMedium,
      alignContent: 'center',
      justifyContent: 'center'
    }
  })

export const nextButtonStyle = (
  theme: Theme,
  buttonDisabled: boolean
): {
  button: ViewStyle
} =>
  StyleSheet.create({
    button: {
      backgroundColor: buttonDisabled
        ? theme.color.disabled
        : theme.color.highlight,
      minWidth: theme.buttons.smallButtonWidth,
      height: theme.buttons.buttonHeight,
      borderRadius: 20,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      marginTop: theme.spacing.verticalSpaceSmall
    }
  })

export const validationStyle = (
  theme: Theme,
  isValid: boolean
): {
  notValidInfoView: ViewStyle
} =>
  StyleSheet.create({
    notValidInfoView: {
      display: isValid ? 'none' : 'flex',
      width: '100%',
      marginTop: theme.spacing.verticalSpaceSmall
    }
  })
