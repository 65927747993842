import { WhyThisTaskHandler } from './get-why-this-task'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { Text } from 'react-native'
import { isNonNullish } from 'global-utils'

export function payCriticalExpensesWhyThisTaskHandler (
  taskTypename: string
): WhyThisTaskHandler<WalkthroughGraphQL.PayCriticalExpensesTask> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      task.__typename === taskTypename
    ) {
      return (
        <Text>
          Paying for your critical expenses comes first. You need the essentials
          (like housing and food) to engage in daily life and have a good
          foundation for the rest of your financial journey.
        </Text>
      )
    }
    return null
  }
}
