import * as React from 'react'
import Carrot from '../../assets/carrot'
import { View, ViewStyle } from 'react-native'
import { boxWithCarrotStyle } from './box-with-carrot.style'

export function BoxWithCarrot (props: {
  children: React.ReactNode
  // Custom style to position the carrot.
  customCarrotViewStyle?: ViewStyle
}): JSX.Element {
  return (
    <View>
      <View
        style={[boxWithCarrotStyle.carrotView, props.customCarrotViewStyle]}
      >
        <Carrot />
      </View>
      <View style={boxWithCarrotStyle.box}>{props.children}</View>
    </View>
  )
}
