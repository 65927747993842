import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish } from 'global-utils'
import {
  getDebtInterestString,
  getInterestInactive
} from '../../../../../util/account-parsing'
import { Text } from 'react-native'
import {
  FadeElement,
  FadeElementGroup
} from '../../determine-next-task/fade-element-groups'
import YuyuCreditCards from '../../../../../assets/yuyu/credit-cards'
import {
  kFirstElementFadeDelay,
  kStandardElementFadeDelay
} from './get-determine-next-task-copy'

export function getDebtInterestElementGroup (
  data: WalkthroughGraphQL.GetMemberQuery
): FadeElementGroup {
  const debtInterestElements: FadeElement[] = []
  const payOffDebtTasks = data.getMember?.payOffDebtTasks

  if (isNonNullish(payOffDebtTasks)) {
    for (const task of payOffDebtTasks.items) {
      if (isNonNullish(task) && !task.isComplete) {
        const debtAccount = task.debtAccount
        if (
          isNonNullish(debtAccount) &&
          !(getInterestInactive(debtAccount) ?? false)
        ) {
          debtInterestElements.push({
            key: `DEBT_INTEREST:${debtAccount.accountId}`,
            element: (
              <Text>{`${debtAccount.accountName ?? 'unknown account'} with ${
                getDebtInterestString(debtAccount) ?? 'unknown'
              } interest`}</Text>
            ),
            preFadeInDelay: kStandardElementFadeDelay
          })
        }
      }
    }
  }
  if (debtInterestElements.length === 0) {
    debtInterestElements.push({
      key: 'NO_DEBT_INTEREST',
      element: (
        <Text>
          According to your account data, you don't have any debt with interest
          being charged!
        </Text>
      ),
      preFadeInDelay: kFirstElementFadeDelay
    })
  } else {
    debtInterestElements[0].preFadeInDelay = kFirstElementFadeDelay
  }

  return {
    key: 'DEBT_INTEREST',
    elements: [
      {
        key: 'DEBT_INTEREST_TITLE',
        element: <Text>Looking at the interest on your debt…</Text>,
        preFadeInDelay: 0
      },
      ...debtInterestElements
    ],
    image: <YuyuCreditCards />
  }
}
