import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import { subtaskDetailsStyle } from './subtask-details.style'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { THEME } from '../../../../constants'
import { SubtaskData } from '../task-flow-data'
import { Questions } from '../../common/questions/questions'
import { ScrollWithArrow } from '../../common/scroll/scroll-with-arrow'

function SubtaskDetails (props: {
  subtask: SubtaskData
  onMarkSubtaskDone: () => void
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  const style = subtaskDetailsStyle(THEME)

  return (
    <View style={style.taskBox}>
      <ScrollWithArrow>
        <View>
          {props.subtask.description !== undefined ? (
            <View style={style.contentBox}>
              <Text style={style.details}>{props.subtask?.description}</Text>
            </View>
          ) : null}
          {props.subtask.questions !== undefined ? (
            <Questions
              questions={props.subtask.questions}
              buttonText="Save"
              onSaveState={props.onMarkSubtaskDone}
            />
          ) : (
            <Pressable onPress={props.onMarkSubtaskDone}>
              <View style={style.button}>
                <Text style={style.buttonText}>Done</Text>
              </View>
            </Pressable>
          )}
        </View>
      </ScrollWithArrow>
    </View>
  )
}

export { SubtaskDetails }
