import * as React from 'react'
import {
  TaskStepsData,
  TaskStepsRenderingStyle
} from '../../tasks/task-flow-data'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { textStyle } from '../../../../themes/global-styles.style'
import { Text } from 'react-native'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { getSavingsRate } from '../../../../util/calculations/savings-rate'
import { getNetWorth } from '../../../../util/calculations/net-worth'
import { isNonNullish } from 'global-utils'
import { getYearsToFinancialIndependence } from '../../../../util/calculations/years-to-financial-independence'
import { getMonthlySpending } from '../../../../util/calculations/monthly-spending'
import { NavigationAnchor } from '../../common/links/navigation-anchor'

export const personalizedSavingsRate: (
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
) => React.ReactNode = (
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
) => {
  const savingsRate = getSavingsRate(memberQuery)
  const netWorth = getNetWorth(memberQuery)
  const monthlySpending =
    memberQuery?.getMember?.estimatedAverageMonthlySpending
  const monthlyAfterTaxIncome =
    memberQuery?.getMember?.estimatedAverageMonthlyAfterTaxIncome

  let yearsToFinancialIndependence: number | null = null
  let yearsToFinancialIndependenceHigherSavingsRate: number | null = null
  let higherSavingsRate: number | null = null
  if (
    isNonNullish(savingsRate) &&
    isNonNullish(monthlySpending) &&
    isNonNullish(monthlyAfterTaxIncome)
  ) {
    yearsToFinancialIndependence = getYearsToFinancialIndependence(
      netWorth,
      monthlySpending,
      monthlyAfterTaxIncome
    )

    higherSavingsRate = savingsRate + 0.1
    if (higherSavingsRate >= 0 && higherSavingsRate <= 1) {
      const lowerMonthlySpending = getMonthlySpending(
        monthlyAfterTaxIncome,
        higherSavingsRate
      )
      if (isNonNullish(lowerMonthlySpending)) {
        yearsToFinancialIndependenceHigherSavingsRate =
          getYearsToFinancialIndependence(
            netWorth,
            lowerMonthlySpending,
            monthlyAfterTaxIncome
          )
      }
    }
  }

  return (
    <>
      {isNonNullish(savingsRate) &&
      isNonNullish(netWorth) &&
      isNonNullish(monthlySpending) &&
      isNonNullish(monthlyAfterTaxIncome) &&
      isNonNullish(yearsToFinancialIndependence) ? (
        <>
          With your savings rate of{' '}
          <Text style={textStyle.boldText}>
            {savingsRate.toLocaleString('en-US', {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </Text>{' '}
          (monthly spending of{' '}
          <Text>
            {monthlySpending.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </Text>{' '}
          and monthly after-tax income of{' '}
          <Text>
            {monthlyAfterTaxIncome.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </Text>
          ) and{' '}
          <Text style={textStyle.boldText}>
            {netWorth.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </Text>{' '}
          net worth,{' '}
          {yearsToFinancialIndependence <= 0 ? (
            <Text style={textStyle.boldText}>
              we think you are already financially independent.
              WOOOOHOOOOOOOOOOOOOO!
            </Text>
          ) : (
            <>
              you'll be financially independent in{' '}
              <Text style={textStyle.boldText}>
                {yearsToFinancialIndependence.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}{' '}
                years.
              </Text>
            </>
          )}
          {isNonNullish(higherSavingsRate) &&
          isNonNullish(yearsToFinancialIndependenceHigherSavingsRate) &&
          yearsToFinancialIndependence > 0 ? (
            <>
              {'\n\n'}If you increase your savings rate to{' '}
              <Text style={textStyle.boldText}>
                {higherSavingsRate.toLocaleString('en-US', {
                  style: 'percent',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Text>
              , you could be financially independent in{' '}
              <Text style={textStyle.boldText}>
                {yearsToFinancialIndependenceHigherSavingsRate.toLocaleString(
                  'en-US',
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }
                )}{' '}
                years
              </Text>{' '}
              instead of{' '}
              {yearsToFinancialIndependence.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}{' '}
              years.
            </>
              ) : null}
        </>
          ) : isNonNullish(savingsRate) &&
        isNonNullish(monthlySpending) &&
        isNonNullish(monthlyAfterTaxIncome) ? (
        // Sometimes we aren't able to calculate yearsToFinancialIndepence, and we get null instead
        // (for instance, if someone is spending down their net worth. If that's the case, use the
        // information we do have.
        <Text>
          With your savings rate of{' '}
          <Text style={textStyle.boldText}>
            {savingsRate.toLocaleString('en-US', {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </Text>{' '}
          (monthly spending of{' '}
          <Text>
            {monthlySpending.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </Text>{' '}
          and monthly after-tax income of{' '}
          <Text>
            {monthlyAfterTaxIncome.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </Text>
          ),{' '}
          {savingsRate > 0 ? (
            <Text>
              you've taken the first critical step by spending less than you
              earn... but there's even more to be done!
            </Text>
          ) : (
            <Text>we think you spend more than you earn.</Text>
          )}{' '}
          Increasing your savings rate can help you become financially
          independent.
        </Text>
              ) : (
        // If we don't know what someone's savings rate is, suggest that they go to the settings page to enter info.
        // We could link to the the overlay questions here, by that requires passing in a hook, which is a bit too
        // much time investment for now - we'd need to consider whether we want the clickthrough module to
        // accept arbitrary hooks or specific hooks etc.
        <Text>
          We don't know what your savings rate is! You can head over to{' '}
          <NavigationAnchor
            handlePress={(navigation: GenericCompositeNavigationProp) => {
              navigation.navigate('Settings')
            }}
            text={'the settings page'}
          />{' '}
          to update your monthly spending and monthly after-tax income. Or click
          next to learn more about savings rate now!
        </Text>
              )}
    </>
  )
}

export const savingsRate: (
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
) => TaskStepsData = (
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
) => {
  // TODO: consider linking to networthify to let users play around- we currently don't offer a slider/
  // interactive experience.
  const content = {
    stepRenderingStyle: TaskStepsRenderingStyle.CLICK_THROUGH_MODULE,
    title: 'Savings rate',
    description: '',
    subtasks: [
      {
        key: 'YEARS_TO_FI',
        isComplete: false,
        storeCompletionState: () => {},
        title: '',
        description: (
          <>
            {personalizedSavingsRate(memberQuery)}
            {'\n\n'}But what is savings rate, you ask? ...
          </>
        )
      },
      {
        key: 'INTRO',
        isComplete: false,
        storeCompletionState: () => {},
        title: '',
        description: (
          <>
            Building wealth is a two part story — saving and investing. On the
            savings side, there's just one big idea to understand: your{' '}
            <Text style={textStyle.boldText}>savings rate</Text>.{'\n\n'}Savings
            rate is the percentage of your after-tax earnings that you save, and
            it tells you almost everything about where your financial situation
            is headed.{'\n\n'}
            Savings rate is so important that it and your net worth are all you
            need to figure out how many years you will need to keep working to
            become{' '}
            <Text style={textStyle.boldText}>financially independent</Text>{' '}
            (with a few assumptions).{'\n\n'}
            The average American saves around{' '}
            <Text style={textStyle.boldText}>6%</Text> of their income every
            year, which means it'll take them about{' '}
            <Text style={textStyle.boldText}>60 years</Text> of working to be
            financially independent, assuming no social security. Thankfully,
            social security does exist, so many folks can still retire (just
            further down the line).
          </>
        )
      },
      {
        key: 'INCREASE_SAVINGS',
        isComplete: false,
        storeCompletionState: () => {},
        title: '',
        description: (
          <>
            So... how do you increase your savings rate?{'\n\n'}
            There are really only two levers we can pull to increase our savings
            rate: <Text style={textStyle.boldText}>make more money</Text>, or{' '}
            <Text style={textStyle.boldText}>spend less</Text>.{'\n\n'}
            Most of us could probably spend a little less, but we don't want to
            be toxic about it. It's still probably a good idea to review your
            spending to see what wiggle room you have.
          </>
        )
      },
      {
        key: 'BIG_EXPENSES',
        isComplete: false,
        storeCompletionState: () => {},
        title: '',
        description: (
          <>
            Take a look at big expenses and see if there's a reasonable way to
            lower them. Most people's biggest three expenses are housing,
            transportation, and food. See if any of them have cheaper
            alternatives that would continue giving you a good quality of life.
            Can you:{'\n\n'}• Find a place with cheaper rent (or maybe find
            housemates?)
            {'\n\n'}• Own an older car instead of a schmancy one (or ride a
            bike, or take the bus)?{'\n\n'}• Cook for yourself a bit more and
            eat out less?
          </>
        )
      },
      {
        key: 'SMALL_EXPENSES',
        isComplete: false,
        storeCompletionState: () => {},
        title: '',
        description: (
          <>
            Then, take a look at your smaller expenses, in particular recurring
            expenses like subscriptions.{'\n\n'}• Which purchases bring you joy?
            Which ones don't?{'\n\n'}• Are you using all of your subscriptions?
            Could you hop on someone else's family plan? If you are the family
            plan, maybe get other folks to chip in for the cost?
            {'\n\n'}See and understand what improvements you can make, but don't
            sweat it too hard — we will all still have expenses to live and
            exist in this world.
          </>
        )
      },
      {
        key: 'MORE_MONEY',
        isComplete: false,
        storeCompletionState: () => {},
        title: '',
        description: (
          <>
            Okay, so that's "Spending Less." Time for "Earn More":{'\n\n'}
            If you're spending a reasonable amount, the best lever to juice your
            savings rate is making more money. This could be in the form of an
            Etsy shop, asking for a raise, switching career paths to one that
            pays more sweet, sweet cheddar, or taking on an extra job.{'\n\n'}
            You might be thinking of "passive income" strategies, but remember!
            The money that you have invested for you in the market{' '}
            <Text style={[textStyle.italicBoldText]}>is</Text> passive income.
            You've got that part covered- we're talking about{' '}
            <Text style={[textStyle.italicBoldText]}>active</Text> income here.
          </>
        )
      },
      {
        key: 'WINDFALLS',
        isComplete: false,
        storeCompletionState: () => {},
        title: '',
        description: (
          <>
            For most people, the easiest way to make more money is getting a
            raise, promotion, or bonus at work. Whenever that cash hits your
            bank account, have a plan for it! It is okay to put some on the side
            to treat yourself — you earned it after all 🥳 — but it's important
            to save a big chunk of it too. Don't increase your spending forever
            after you get a big one-time chunk of money.{'\n\n'}
            <Text style={textStyle.boldText}>
              The easiest way to improve your savings rate is to keep your
              spending levels the same while increasing your income
            </Text>{' '}
            — let that money hit your 401k, Roth IRA, or brokerage account and{' '}
            <Text style={textStyle.boldText}>compound over time!</Text>.
          </>
        )
      }
    ],
    button: {
      buttonText: 'Done',
      handleOnPressButton: (props: {
        navigation: GenericCompositeNavigationProp
      }) => {
        props.navigation.goBack()
      }
    }
  }
  return content
}
