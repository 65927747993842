import { WhyThisTaskHandler } from './get-why-this-task'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { Text } from 'react-native'
import { isNonNullish } from 'global-utils'
import { getTasksAsSentenceFragment } from './get-tasks-as-sentence-fragments'

export function contributeToIraWhyThisTaskHandler (
  data: WalkthroughGraphQL.GetMemberQuery,
  taskTypename: string,
  taskPrimaryKey: string,
  contributionYear: number,
  minDebtInterestRate: number
): WhyThisTaskHandler<WalkthroughGraphQL.ContributeToIraTask> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      task.__typename === taskTypename &&
      task.id === taskPrimaryKey
    ) {
      const alternativeTasksSentenceFragment = getTasksAsSentenceFragment(
        data,
        new Set(['payOffDebtTasks', 'investInBrokerageAccountTask']),
        /* includeIfComplete = */ false,
        /* includeIfIncomplete = */ true,
        contributionYear,
        minDebtInterestRate,
        'or'
      )
      return (
        <Text>
          We think you can still contribute to your IRA for{' '}
          {task.contributionYear}! The tax advantages you get from contributing
          to your IRA build long term wealth much faster
          {isNonNullish(alternativeTasksSentenceFragment) ? (
            <Text> than if you were {alternativeTasksSentenceFragment}</Text>
          ) : null}
          , so we think this is the right task for you.
        </Text>
      )
    }
    return null
  }
}
