import React from 'react'
import { Animated, Easing, View } from 'react-native'
import { textStyle } from '../../../../themes/global-styles.style'
import { fadeElementGroupsStyle } from './fade-element-groups.style'

// One element to fade in.
export interface FadeElement {
  // Key for rendering this element as a react component in a list of react components.
  key: string
  // Expected to be a <Text> element, or a Text-like element (such as <Anchor>) that can be
  // rendered inside <Text><Text>.
  element: JSX.Element
  // Delay in ms between when the previous element in the group finishes animating and when this element
  // starts fading in.
  preFadeInDelay: number
}

// A group of elements to fade in. Each element fades in, and then the whole group fades
// out at once.
export interface FadeElementGroup {
  // Key for rendering this element group as a react component in a list of react components.
  key: string
  elements: FadeElement[]
  // Image to be rendered while all of the elements in this group fade in.
  image: JSX.Element
}

// This component is used to fade in & out one element group. Each element fades in,
// and then the whole group fades out at once.
// Example elementGroup:
// [{key: 'a', element: <Text>Hello</Text>},
//  {key: 'b', element: <Text>First</Text>},
//  {key: 'c', element: <Text>Group</Text>}]
//
// This results in the following animation:
// (fade in) Hello
// (fade in) First
// (fade in) Group
// (fade out all elements)
function FadeInOutOneElementGroup (props: {
  elementGroup: FadeElementGroup
  onFinishAnimation: () => void
}): JSX.Element {
  // Note that durations are currently hardcoded here and tuned for DetermineNextTask flows.
  // We can easily parameterize these durations if we need to use this component elsewhere.
  // Duration for fade in and fade out animations.
  const fadeDuration = 200

  // An array of fade in animations, one per element in the elementGroup.
  const fadeInAnimations: Animated.Value[] = []
  const fadeOutAnimation = React.useRef(new Animated.Value(1)).current

  const animations: Animated.CompositeAnimation[] = []

  // Add one fade in animation per element in the elementGroup
  for (let index = 0; index < props.elementGroup.elements.length; index++) {
    fadeInAnimations.push(React.useRef(new Animated.Value(0)).current)
    animations.push(
      Animated.timing(fadeInAnimations[index], {
        toValue: 1,
        // Fade in the first element immediately so that it renders as the same
        // time as the image.
        duration: index === 0 ? 1 : fadeDuration,
        easing: Easing.linear,
        // No delay for the first element so that it renders as the same
        // time as the image.
        delay: props.elementGroup.elements[index].preFadeInDelay,
        useNativeDriver: false
      })
    )
  }

  // Add a final fade out animation for the whole group.
  animations.push(
    Animated.timing(fadeOutAnimation, {
      toValue: 0,
      duration: fadeDuration,
      easing: Easing.linear,
      delay: 4000,
      useNativeDriver: false
    })
  )

  React.useEffect(() => {
    // Run all animations in sequence. Each element fades in, and then the whole group
    // fades out at once.
    // Note: Running the animations in sequence makes the animation stable and clean.
    // We tried creating a modular component that just owned its own animation, and
    // used the 'delay' field to stagger animations, but sometimes the animations would
    // look strange, stopping and starting at random, and the timing between animations
    // was inconsistent.
    Animated.sequence(animations).start(() => {
      props.onFinishAnimation()
    })
  }, [])

  return (
    <Animated.View
      style={[
        { opacity: fadeOutAnimation },
        fadeElementGroupsStyle.elementGroupBox
      ]}
    >
      <View style={fadeElementGroupsStyle.imageBox}>
        {props.elementGroup.image}
      </View>
      {props.elementGroup.elements.map((element, index) => (
        <Animated.Text
          key={element.key}
          style={[
            textStyle.largeText,
            { opacity: fadeInAnimations[index] },
            fadeElementGroupsStyle.fadeInElementBox
          ]}
        >
          {element.element}
        </Animated.Text>
      ))}
    </Animated.View>
  )
}

// This component is used to fade in & out element groups. Each element group can have multiple
// elements. Each element fades in, and then the whole group fades out at once, and the next group
// fades in.
// Example elementGroups:
// [
//    {key: 'firstGroup', elements: [
//      {key: 'a', element: <Text>Hello</Text>},
//      {key: 'b', element: <Text>First</Text>},
//      {key: 'c', element: <Text>Group</Text>}]
//    },
//    {key: 'secondGroup', elements: [
//      {key: 'd', element: <Text>Hiiii</Text>},
//      {key: 'e', element: <Text>Second</Text>},
//      {key: 'f', element: <Text>Group</Text>}]
//    }
// ]
//
// This results in the following animation:
// (fade in) Hello
// (fade in) First
// (fade in) Group
// (fade out all elements)
// (fade in) Hiiii
// (fade in) Second
// (fade in) Group
// (fade out all elements)
export function FadeInOutElementGroups (props: {
  elementGroups: FadeElementGroup[]
  onAnimationsComplete: () => void
}): JSX.Element {
  const [index, setIndex] = React.useState(0)

  return (
    <View style={fadeElementGroupsStyle.elementGroupBox}>
      {index < props.elementGroups.length ? (
        <FadeInOutOneElementGroup
          key={props.elementGroups[index].key}
          elementGroup={props.elementGroups[index]}
          onFinishAnimation={() => {
            if (index + 1 < props.elementGroups.length) {
              setIndex(index + 1)
            } else {
              props.onAnimationsComplete()
            }
          }}
        />
      ) : null}
    </View>
  )
}
