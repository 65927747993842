// This file both queries the backend for member tasks and translates them into a TaskFlowData shape for rendering.
import { TaskFlowData } from '../task-flow-data'
import {
  getTasksFromContainer,
  processAllFinancialTasksFromGetMemberQuery
} from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish, collapseProcessingResultsIntoArray } from 'global-utils'
import { payCriticalExpensesTaskToFlowData } from './pay-critical-expenses'
import { buildEmergencyFundTaskToFlowData } from './build-emergency-fund'
import { extendEmergencyFundToThreeMonthsTaskToFlowData } from './extend-emergency-fund-to-three-months'
import { investInBrokerageAccountTaskToFlowData } from './invest-in-brokerage-account'
import { contributeTo401kTaskToFlowData } from './contribute-to-401k'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { contributeToIraTaskToFlowData } from './contribute-to-ira'
import { payOffDebtTaskToFlowData } from './pay-off-debt'

// Convenience type for generating handlers.  It is okay for custom
// handlers to diverge from this.
export type TaskFlowDataConverter<T> = (
  taskTypename: string,
  taskId: string,
  absoluteDollarsToAllocateToTask: number | null | undefined,
  currentlyAllocatedDollars: number | null,
  client: ApolloClient<NormalizedCacheObject>,
  recommendedByWalkthrough?: boolean
) => (task: T | null) => TaskFlowData | null

export function getTaskFlowData (
  memberQuery: WalkthroughGraphQL.GetMemberQuery | null | undefined,
  taskTypename: string,
  taskId: string,
  absoluteDollarsToAllocateToTask: number | null | undefined,
  currentlyAllocatedDollars: number | null,
  client: ApolloClient<NormalizedCacheObject>,
  recommendedByWalkthrough?: boolean
): TaskFlowData | undefined {
  const tasks = getTasksFromContainer(memberQuery?.getMember)

  const taskToFlowDataHandlers = {
    payCriticalExpensesTask: payCriticalExpensesTaskToFlowData(
      taskTypename,
      taskId,
      absoluteDollarsToAllocateToTask,
      currentlyAllocatedDollars,
      client,
      recommendedByWalkthrough
    ),
    buildOneMonthEmergencyFundTask: buildEmergencyFundTaskToFlowData(
      taskTypename,
      taskId,
      absoluteDollarsToAllocateToTask,
      currentlyAllocatedDollars,
      client,
      recommendedByWalkthrough
    ),
    extendEmergencyFundToThreeMonthsTask:
      extendEmergencyFundToThreeMonthsTaskToFlowData(
        taskTypename,
        taskId,
        absoluteDollarsToAllocateToTask,
        currentlyAllocatedDollars,
        client,
        recommendedByWalkthrough
      ),
    // The 6 month task is being de-registered for v0; see code change for decision pointers.
    extendEmergencyFundToSixMonthsTask: () => null, // extendEmergencyFundToSixMonthsTaskToFlowData(taskTypename, taskId, client),
    investInBrokerageAccountTask: investInBrokerageAccountTaskToFlowData(
      taskTypename,
      taskId,
      absoluteDollarsToAllocateToTask,
      currentlyAllocatedDollars,
      client,
      recommendedByWalkthrough
    ),
    payOffDebtTasks: payOffDebtTaskToFlowData(
      taskTypename,
      taskId,
      absoluteDollarsToAllocateToTask,
      currentlyAllocatedDollars,
      client,
      recommendedByWalkthrough
    ),
    contributeTo401k2022Tasks: () => null, // Deregistered
    contributeTo401kTasks: contributeTo401kTaskToFlowData(
      taskTypename,
      taskId,
      absoluteDollarsToAllocateToTask,
      currentlyAllocatedDollars,
      client,
      recommendedByWalkthrough
    ),
    contributeToIra2022Task: () => null, // Deregistered
    contributeToIraTasks: contributeToIraTaskToFlowData(
      taskTypename,
      taskId,
      absoluteDollarsToAllocateToTask,
      currentlyAllocatedDollars,
      client,
      recommendedByWalkthrough
    )
  }

  const taskFlowData = collapseProcessingResultsIntoArray(
    processAllFinancialTasksFromGetMemberQuery(tasks, taskToFlowDataHandlers)
  ).filter(isNonNullish)
  if (taskFlowData.length > 1) {
    throw new Error(
      'Registration error in get-task-screen-data.ts, processing should only ever result in zero or ' +
        'one non-null TaskFlowData instance'
    )
  }
  const fd = taskFlowData[0]
  return fd
}
