import * as React from 'react'
import {
  TaskStepsData,
  TaskStepsRenderingStyle
} from '../../tasks/task-flow-data'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { Anchor } from '../../common/links/anchor'

export const mxPrivacy: TaskStepsData = {
  stepRenderingStyle: TaskStepsRenderingStyle.CLICK_THROUGH_MODULE,
  title: 'Account Linking Privacy',
  description: '',
  subtasks: [
    {
      key: 'INTRO',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          For walkthrough to see the full picture, we need you to link in all
          your bank accounts.{'\n\n'}
          To do this, we partner with MX — they help you link your bank accounts
          together and share that data with us.{'\n\n'}
          WALKTHROUGH NEVER SEES OR STORES YOUR BANK ACCOUNT CREDENTIALS, AND WE
          NEVER SELL YOUR DATA!
        </>
      )
    },
    {
      key: 'DEETS',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          If your bank is tech-savvy, MX never stores your bank credentials
          either! (for nerds: this is possible via OAuth2. For nerds who like
          videos: watch{' '}
          <Anchor text="this one" href="https://youtu.be/CPbvxxslDTU?t=49" />
          .){'\n\n'}
          If your bank is a little behind the times, MX may store your bank
          account credentials. This is okay, though — they're pros at this and
          take all sorts of measures to keep your data safe, like hardware
          encryption (and dragons! 🐉 *). We trust them with our data and hope
          you do too.{'\n\n'}* not real dragons. At least, we don't think so.
        </>
      )
    },
    {
      key: 'PARTNERS',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          If your bank is really small or local, MX sometimes gives your
          credentials to a third company which gets your account information for
          them. Those partners have the same security standards as MX (🐉🐉) and
          are not allowed to do anything with your data except give it to MX.
        </>
      )
    },
    {
      key: 'LINKS',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          If you aren't comfy with linking your accounts yet, take a look at
          MX's security docs{' '}
          <Anchor text="here" href="https://www.mx.com/privacy-policy/" /> and{' '}
          <Anchor
            text="here"
            href="https://www.mx.com/whitepapers/security-how-mx-protects-your-data/"
          />
          , or read{' '}
          <Anchor
            text="our security policy"
            href="https://www.walkthrough.co/securityPolicy"
          />
          . Or <Anchor text="email us" href="mailto:support@walkthrough.co" />!
          {'\n\n'}
          Phew! That's a lot of information about banking data! It's important,
          so we're upfront about it... you're going to notice that a lot about
          us.
        </>
      )
    }
  ],
  button: {
    buttonText: 'Done',
    handleOnPressButton: (props: {
      navigation: GenericCompositeNavigationProp
    }) => {
      props.navigation.goBack()
    }
  }
}
