import * as React from 'react'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { THEME } from '../../../constants'
import { getSettingsDrawers } from './get-settings-drawers'
import {
  ApolloClient,
  gql,
  NormalizedCacheObject,
  useApolloClient,
  useLazyQuery
} from '@apollo/client'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { loadToken } from '../../auth/load-token'
import { useIsMounted } from '../../../util/use-is-mounted'
import { Auth } from 'aws-amplify'
import { isNonNullish } from 'global-utils'
import {
  EditProfileSettings,
  EditProfileSettingsQuestionKey
} from './edit-profile-settings'
import { Drawers } from '../common/drawers/drawers'
import { FeedbackButton } from '../feedback/feedback-button'

// TODO: we are currently using a stack to navigate from the settings screen to the edit
// settings screen. It might make more sense for them to be a single screen, and to show
// or hide the edit settings module when someone wants to edit a setting. Do not navigate
// to or push a EditProfileSettingsScreen from any other screen than SettingsScreen (this
// could cause the navigation stack to contain multiple instances of an
// EditProfileSettingsScreen, which could lead to hard-to-debug bugs.)

export function SettingsScreen (props: {
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  const [profileQuestionKeyToEdit, setProfileQuestionKeyToEdit] =
    React.useState<EditProfileSettingsQuestionKey | null>(null)
  const [email, setEmail] = React.useState('')

  const [getMember, { data }] = useLazyQuery<
  WalkthroughGraphQL.GetMemberQuery,
  WalkthroughGraphQL.GetMemberQueryVariables
  >(gql(WalkthroughGraphQL.getMember))

  const isMounted = useIsMounted()

  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>
  React.useEffect(() => {
    // There are known race condititions with async code in useEffect (like what if the component unmounts before the
    // function returns?), but this is still the recommended approach. See
    // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function getOwnerAndQuery (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getMember({ variables: { owner: owner } })
      }
    }

    async function putEmailInState (): Promise<void> {
      const authenticatedUser = await Auth.currentAuthenticatedUser()
      if (isMounted()) {
        setEmail(authenticatedUser.attributes.email)
      }
    }

    getOwnerAndQuery().catch(console.log)
    putEmailInState().catch(console.log)
  }, [])

  const drawersData = getSettingsDrawers(
    email,
    data?.getMember?.state,
    data?.getMember?.zipCode,
    data?.getMember?.birthday,
    data?.getMember?.employmentStatus,
    data?.getMember?.estimatedTaxableIncome,
    data?.getMember?.estimatedAverageMonthlyAfterTaxIncome,
    data?.getMember?.estimatedAverageMonthlySpending,
    data?.getMember?.isAbleToPayBasicExpensesMemberDeclared,
    data?.getMember?.hasOneMonthEmergencyFundMemberDeclared,
    data?.getMember?.taxFilingStatus,
    data?.getMember?.spouseBirthday,
    data?.getMember?.promoCode,
    apolloClient,
    setProfileQuestionKeyToEdit
  )

  return (
    <>
      <Drawers colorGradient={THEME.color.gradientC} drawers={drawersData} />
      <FeedbackButton navigation={props.navigation} />
      {isNonNullish(profileQuestionKeyToEdit) ? (
        // TODO: use QuestionOverlay (in common/questions) here instead of a custom
        // EditProfileSettings overlay.
        <EditProfileSettings
          profileQuestionKey={profileQuestionKeyToEdit}
          setProfileQuestionKeyToEdit={setProfileQuestionKeyToEdit}
        />
      ) : null}
    </>
  )
}
