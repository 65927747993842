import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../themes/theme'

export const welcomeScreenStyle = (
  theme: Theme
): {
  welcomeScreen: ViewStyle
  content: TextStyle
  contentBox: ViewStyle
  nextButton: ViewStyle
  nextButtonText: TextStyle
  illustration: ViewStyle
} =>
  StyleSheet.create({
    welcomeScreen: {
      width: '100%',
      backgroundColor: theme.color.background,
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
    content: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeLarge,
      fontFamily: 'PublicSans_400Regular',
      textAlign: 'left',
      paddingLeft: theme.spacing.horizontalSpaceMedium,
      paddingRight: theme.spacing.horizontalSpaceMedium
    },
    contentBox: {
      backgroundColor: theme.color.background + 'bf',
      width: '100%'
    },
    nextButton: {
      position: 'absolute',
      bottom: theme.spacing.verticalSpaceLarge,
      backgroundColor: theme.color.highlight,
      width: theme.buttons.smallButtonWidth,
      height: theme.buttons.buttonHeight,
      borderRadius: 20,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      marginTop: theme.spacing.verticalSpaceLarge
    },
    nextButtonText: {
      color: theme.color.onSurface,
      fontSize: theme.font.fontSizeLarge,
      fontFamily: 'PublicSans_700Bold',
      paddingLeft: theme.spacing.horizontalSpaceMedium,
      paddingRight: theme.spacing.horizontalSpaceMedium
    },
    illustration: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: -50,
      zIndex: -3
    }
  })
