import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

const ArrowCircleLarge = (props: SvgProps): JSX.Element => (
  <Svg
    width={36}
    height={36}
    fill='none'
    {...props}
  >
    <Circle cx={18} cy={18} r={17} fill='#fff' stroke='#fff' strokeWidth={2} />
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m19.704 11.293 6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.415-1.414l4.657-4.657H10a1 1 0 0 1 0-2h12.946l-4.657-4.657a1 1 0 0 1 1.415-1.414Z'
      fill='#FF7991'
      stroke='#FF7991'
    />
  </Svg>
)

export default ArrowCircleLarge
