import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish, collapseProcessingResultsIntoArray } from 'global-utils'
import { getTaskTitle } from '../get-task-title'
import { Text } from 'react-native'
import { FadeElementGroup } from '../../determine-next-task/fade-element-groups'
import { textStyle } from '../../../../../themes/global-styles.style'
import YuyuBalloons from '../../../../../assets/yuyu/balloons'
import { accountNameWithBalanceFallback } from '../../../../../util/account-parsing'
import {
  getTasksFromContainer,
  processAllFinancialTasksFromGetMemberQuery,
  InputFinancialTaskTypesForGetMemberQuery
} from 'multi-type-processor'
import { getWhyThisTask } from '../why-this-task/get-why-this-task'
import {
  kFirstElementFadeDelay,
  kStandardElementFadeDelay
} from './get-determine-next-task-copy'

type SelectedTaskTitleHandler<T> = (task: T | null) => string | null

function selectedTaskTitleHandler<
  T extends Omit<WalkthroughGraphQL.FinancialTask, '__typename'> & {
    __typename: string
  }
> (taskTypename: string): SelectedTaskTitleHandler<T> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      task.__typename === taskTypename
    ) {
      return getTaskTitle(task.__typename)
    }
    return null
  }
}

function selected401kTaskTitleHandler (
  taskTypename: string,
  taskPrimaryKey: string
): SelectedTaskTitleHandler<WalkthroughGraphQL.ContributeTo401kTask> {
  return (task) => {
    const k401Account = task?.k401Account
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      isNonNullish(k401Account) &&
      task.__typename === taskTypename &&
      task.id === taskPrimaryKey
    ) {
      return getTaskTitle(
        task.__typename,
        task.stepsCompletedAtLeastOnce ?? undefined,
        task.contributionYear,
        accountNameWithBalanceFallback(k401Account) ?? undefined
      )
    }
    return null
  }
}

function selectedDebtTaskTitleHandler (
  taskTypename: string,
  taskPrimaryKey: string
): SelectedTaskTitleHandler<WalkthroughGraphQL.PayOffDebtTask> {
  return (task) => {
    const debtAccount = task?.debtAccount
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      isNonNullish(debtAccount) &&
      task.__typename === taskTypename &&
      task.id === taskPrimaryKey
    ) {
      return getTaskTitle(
        task.__typename,
        undefined,
        undefined,
        accountNameWithBalanceFallback(debtAccount) ?? undefined
      )
    }
    return null
  }
}

function selectedIraTaskTitleHandler (
  taskTypename: string,
  taskPrimaryKey: string
): SelectedTaskTitleHandler<WalkthroughGraphQL.ContributeToIraTask> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      task.__typename === taskTypename &&
      task.id === taskPrimaryKey
    ) {
      return getTaskTitle(task.__typename, undefined, task.contributionYear)
    }
    return null
  }
}

export function getSelectedTaskElementGroup (
  data: WalkthroughGraphQL.GetMemberQuery,
  taskTypename: string,
  taskPrimaryKey: string
): FadeElementGroup {
  const tasks = getTasksFromContainer(data.getMember)
  const handlers = {
    payCriticalExpensesTask: selectedTaskTitleHandler<
    InputFinancialTaskTypesForGetMemberQuery['payCriticalExpensesTask']
    >('PAY_CRITICAL_EXPENSES'),
    buildOneMonthEmergencyFundTask:
      selectedTaskTitleHandler<
      InputFinancialTaskTypesForGetMemberQuery['buildOneMonthEmergencyFundTask']
      >('ONE_MONTH_EFUND'),
    extendEmergencyFundToThreeMonthsTask: selectedTaskTitleHandler<
    InputFinancialTaskTypesForGetMemberQuery['extendEmergencyFundToThreeMonthsTask']
    >('EXTEND_EFUND_THREE_MONTHS'),
    extendEmergencyFundToSixMonthsTask: () => null, // Deregistered,
    investInBrokerageAccountTask: selectedTaskTitleHandler<
    InputFinancialTaskTypesForGetMemberQuery['investInBrokerageAccountTask']
    >('INVEST_IN_BROKERAGE'),
    payOffDebtTasks: selectedDebtTaskTitleHandler(taskTypename, taskPrimaryKey),
    contributeTo401k2022Tasks: () => null, // Deregistered
    contributeTo401kTasks: selected401kTaskTitleHandler(
      taskTypename,
      taskPrimaryKey
    ),
    contributeToIra2022Task: () => null, // Deregistered
    contributeToIraTasks: selectedIraTaskTitleHandler(
      taskTypename,
      taskPrimaryKey
    )
  }

  const selectedTaskTitles = collapseProcessingResultsIntoArray(
    processAllFinancialTasksFromGetMemberQuery(tasks, handlers)
  ).filter(isNonNullish)

  const selectedTaskTitle =
    selectedTaskTitles.length === 1
      ? selectedTaskTitles[0]
      : getTaskTitle(taskTypename)

  return {
    key: 'PERFECT_TASK',
    elements: [
      {
        key: 'PERFECT_TASK_INTRO',
        element: (
          <Text>
            It looks like there’s something that’s{' '}
            <Text style={textStyle.boldText}>just right</Text> for you{' '}
            <Text style={textStyle.boldText}>right now:</Text>
          </Text>
        ),
        preFadeInDelay: 0
      },
      {
        key: 'YOUR_TASK',
        element: <Text>🎉 {selectedTaskTitle} 🎉</Text>,
        // Extra delay for suspense
        preFadeInDelay: kFirstElementFadeDelay + kStandardElementFadeDelay
      },
      {
        key: 'WHY',
        element: (
          <Text>{getWhyThisTask(data, taskTypename, taskPrimaryKey)}</Text>
        ),
        preFadeInDelay: kStandardElementFadeDelay * 3
      },
      // Extra delay at end so people can read the long explanation.
      {
        key: 'FINAL_DELAY',
        element: <></>,
        preFadeInDelay: kStandardElementFadeDelay * 6
      }
    ],
    image: <YuyuBalloons />
  }
}
