import {
  ApolloClient,
  NormalizedCacheObject,
  gql,
  useApolloClient,
  useLazyQuery
} from '@apollo/client'
import { formatISO } from 'date-fns'
import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { Animated, Easing, View } from 'react-native'
import { loadToken } from '../../../auth/load-token'
import {
  PointsToAddAnimation,
  POINTS_TO_ADD_ANIMATION_DURATION_MS
} from '../points'
import { FinalBanner } from './final-banner'
import { savingsRateGameStyle } from './savings-rate-game.style'
import { SpinningWheel } from './spinning-wheel'
import { WelcomeBanner } from './welcome-banner'
import { useIsMounted } from '../../../../util/use-is-mounted'
import { getSavingsRate } from '../../../../util/calculations/savings-rate'
import { isNonNullish } from 'global-utils'

async function storeLastReceivedSavingsRatePoints (
  client: ApolloClient<NormalizedCacheObject>,
  lastReceivedSavingsRatePoints: Date
): Promise<void> {
  await client.mutate<
  WalkthroughGraphQL.UpdateMemberMutation,
  WalkthroughGraphQL.UpdateMemberMutationVariables
  >({
    mutation: gql(WalkthroughGraphQL.updateMember),
    variables: {
      input: {
        owner: await loadToken(),
        lastReceivedSavingsRatePoints: formatISO(lastReceivedSavingsRatePoints)
      }
    }
  })
}

enum SavingsRateGameStage {
  WELCOME_BANNER,
  WHEEL,
  FINAL_BANNER
}

export function SavingsRateGame (props: {
  setSavingsRateQuestionIndex: React.Dispatch<
  React.SetStateAction<number | null>
  >
  setPointsToAddAnimation: React.Dispatch<
  React.SetStateAction<PointsToAddAnimation | null>
  >
}): JSX.Element {
  const [savingsRateGameStage, setSavingsRateGameStage] = React.useState(
    // NOTE: We currently skip straight to the WHEEL state because the WELCOME_BANNER
    // takes up too much space on the screen/is a strange place to land.
    // TODO: Re-evaluate whether we want a welcome banner.
    SavingsRateGameStage.WHEEL
  )

  // This variable is used to signal when the spinning wheel animation is complete so we
  // can fade out the savings rate game afterwards.
  const [spinningWheelAnimationComplete, setSpinningWheelAnimationComplete] =
    React.useState(false)

  // Polling for this query is handled centrally by the app in order to not register repeated
  // polling requests.
  const [getFinancialView, { data }] = useLazyQuery<
  WalkthroughGraphQL.MemberLatestFinancialViewQuery,
  WalkthroughGraphQL.MemberLatestFinancialViewQueryVariables
  >(gql(WalkthroughGraphQL.memberLatestFinancialView))

  const isMounted = useIsMounted()

  React.useEffect(() => {
    // There are known race condititions with async code in useEffect (like what if the component unmounts before the
    // function returns?), but this is still the recommended approach. See
    // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function getOwnerAndQuery (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getFinancialView({ variables: { owner: owner } })
      }
    }
    getOwnerAndQuery().catch(console.log)
  }, [])

  const savingsRate = getSavingsRate(data)

  // Animated variable used to fade out the wheel after the spinning wheel animation
  // is complete.
  const spinningWheelFadeOut = React.useRef(new Animated.Value(1)).current
  // Animated variable used to fade out the final banner after showing it for a short
  // amount of time.
  const finalBannerFadeInOut = React.useRef(new Animated.Value(1)).current

  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>

  // This animation is delayed by POINTS_TO_ADD_ANIMATION_DURATION_MS so the
  // points to add animation can run before the fade out. This is a bit confusing because the
  // animations are split into separate components now, but everything runs in the same order,
  // and the delay belongs here. This component assumes that the spinning wheel starts the
  // points to add animation using setPointsToAddAnimation when the spinning wheel animation
  // finishes.
  React.useEffect(() => {
    if (spinningWheelAnimationComplete) {
      Animated.timing(spinningWheelFadeOut, {
        toValue: 0,
        duration: 300,
        easing: Easing.inOut(Easing.ease),
        useNativeDriver: false,
        // Delay the savings rate game fade out by POINTS_TO_ADD_ANIMATION_DURATION_MS so the
        // points to add animation has time to run before the game fades out.
        delay: POINTS_TO_ADD_ANIMATION_DURATION_MS
      }).start(() => {
        setSavingsRateGameStage(SavingsRateGameStage.FINAL_BANNER)
        Animated.sequence([
          Animated.timing(finalBannerFadeInOut, {
            toValue: 1,
            duration: 300,
            easing: Easing.inOut(Easing.ease),
            useNativeDriver: false
          }),
          Animated.timing(finalBannerFadeInOut, {
            toValue: 0,
            duration: 300,
            easing: Easing.inOut(Easing.ease),
            useNativeDriver: false,
            // Delay the final banner fade out by 10 seconds so there's time to read it.
            delay: 10000
          })
        ]).start(() => {
          // Once the final banner has faded out, we store today as the lastReceivedSavingsRatePoints
          // date. This causes the savings rate game to no longer render via logic in the jungle screen that
          // only shows the game if it hasn't already been played today. It might take a bit of time for
          // that value to be stored here & retrieved by the jungle screen again, but that doesn't matter
          // because the game has already faded out.
          storeLastReceivedSavingsRatePoints(apolloClient, new Date()).catch(
            console.log
          )
        })
      })
    }
  }, [spinningWheelAnimationComplete])

  return (
    <View
      style={savingsRateGameStyle.savingsRateGameContainer}
      pointerEvents="box-none"
    >
      {savingsRateGameStage === SavingsRateGameStage.WELCOME_BANNER ? (
        <WelcomeBanner
          setSavingsRateQuestionIndex={props.setSavingsRateQuestionIndex}
          onPressSpinWheelButton={() =>
            setSavingsRateGameStage(SavingsRateGameStage.WHEEL)
          }
        />
      ) : savingsRateGameStage === SavingsRateGameStage.WHEEL ? (
        <Animated.View
          style={[
            savingsRateGameStyle.savingsRateGameContainer,
            { opacity: spinningWheelFadeOut }
          ]}
          pointerEvents="box-none"
        >
          <SpinningWheel
            // If the savings rate is > 0, the maximum number for the points
            // on a wheel slice is 2x the savings rate. Otherwise, use a seed number
            // of 1, which creates a wheel of 0s and 1s.
            seedNumber={
              isNonNullish(savingsRate) && savingsRate > 0
                ? Math.floor(savingsRate * 2 * 100)
                : 1
            }
            setPointsToAddAnimation={props.setPointsToAddAnimation}
            setSpinningWheelAnimationComplete={
              setSpinningWheelAnimationComplete
            }
          />
        </Animated.View>
      ) : savingsRateGameStage === SavingsRateGameStage.FINAL_BANNER ? (
        <Animated.View
          style={[
            savingsRateGameStyle.savingsRateGameContainer,
            { opacity: finalBannerFadeInOut }
          ]}
          pointerEvents="box-none"
        >
          <FinalBanner
            setSavingsRateQuestionIndex={props.setSavingsRateQuestionIndex}
          />
        </Animated.View>
      ) : null}
    </View>
  )
}
