import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { Pressable, Text, View } from 'react-native'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'
import { jungleScreenStyle } from './jungle-screen.style'
import JungleLeft from '../../../assets/jungle/jungle-left'
import JungleLeftYuyu from '../../../assets/jungle/jungle-left-yuyu'
import {
  textStyle,
  buttonStyle,
  viewStyle
} from '../../../themes/global-styles.style'
import { useLazyQuery, gql } from '@apollo/client'
import { useIsMounted } from '../../../util/use-is-mounted'
import { loadToken } from '../../auth/load-token'
import { getNetWorth } from '../../../util/calculations/net-worth'
import { isNonNullish } from 'global-utils'
import { QuestionOverlay } from '../common/questions/question-overlay'
import {
  monthlySpendingQuestion,
  monthlyTakeHomeIncomeQuestion
} from '../common/questions/content/profile-questions'
import { ContentModule } from '../click-through-module/click-through-module-screen'
import { getSavingsRate } from '../../../util/calculations/savings-rate'
import { QuestionAndAnswers } from '../common/questions/question-data'
import { isToday, parseISO } from 'date-fns'
import { Points, PointsToAddAnimation } from './points'
import { SavingsRateGame } from './savings-rate-game/savings-rate-game'
import { getYearsToFinancialIndependence } from '../../../util/calculations/years-to-financial-independence'
import { TaskFlowStage } from '../tasks/task-flow-data'
import { getTaskTitle } from '../tasks/content-construction/get-task-title'
import { BoxWithCarrot } from '../../common/box-with-carrot'
import { useIsFocused } from '@react-navigation/native'
import { THEME } from '../../../constants'
import QuestionMarkCircle from '../../../assets/question-mark-circle'
import { getWhyThisTask } from '../tasks/content-construction/why-this-task/get-why-this-task'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { NavigationAnchor } from '../common/links/navigation-anchor'

export function getSavingsRateStringToRender (
  savingsRate: number,
  netWorth: number,
  monthlySpending: number,
  monthlyAfterTaxIncome: number
): string {
  // If the member has a positive savings rate, render it, regardless of whether they are FI or not.
  if (savingsRate > 0) {
    return savingsRate.toLocaleString('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  const yearsToFinancialIndependence = getYearsToFinancialIndependence(
    netWorth,
    monthlySpending,
    monthlyAfterTaxIncome
  )
  // The member has a zero or negative savings rate, and they *are* FI.
  if (
    isNonNullish(yearsToFinancialIndependence) &&
    yearsToFinancialIndependence <= 0
  ) {
    return '✓'
  }
  // The member has a zero or negative savings rate, and they are not yet FI (or yearsToFinancialIndependence is null).
  return '!'
}

function JungleScreen (props: {
  navigation: GenericCompositeNavigationProp
}): JSX.Element {
  // *****************************************************************************
  // The jungle screen has components that affect each other's animations & rendering.
  // Value & setter props for these components are saved here, in the common
  // parent element. The component that knows how to calculate the value recieves
  // the hook to set the value as a prop, and the component that consumes the
  // value recieves the hook and value as a prop.

  // When null, no animation runs. When not null, this triggers an animation. The
  // consumer (Points) needs to set this back to null when the animation is complete.
  const [pointsToAddAnimation, setPointsToAddAnimation] =
    React.useState<PointsToAddAnimation | null>(null)

  // *****************************************************************************

  const [showYuyu, setShowYuyu] = React.useState(false)

  const [
    showSavingsRateDropdownInsteadOfTask,
    setShowSavingsRateDropdownInsteadOfTask
  ] = React.useState(false)

  const [showWhyThisTask, setShowWhyThisTask] = React.useState(false)

  const [whyThisTask, setWhyThisTask] = React.useState<JSX.Element | null>(
    null
  )

  const [activeTaskTitle, setActiveTaskTitle] = React.useState<string | null>(
    null
  )

  // The question index determines whether to show the questions in an overlay or not.
  const [savingsRateQuestionIndex, setSavingsRateQuestionIndex] =
    React.useState<number | null>(null)
  // These questions will only be asked if the savings rate can't be calculated (one of the #s is nullish or BOTH
  // numbers are 0). We allow one number to be 0, because a both a 100% savings rate (spending is 0) and a -Infinity
  // savings rate (income is 0) are valid. Ask BOTH questions if savings rate can't be calculated yet, so people know
  // what goes into the calculation (better UX).
  const savingsRateQuestions: QuestionAndAnswers[][] = [
    [monthlyTakeHomeIncomeQuestion],
    [monthlySpendingQuestion]
  ]

  // Polling for this query is handled centrally by the app in order to not register repeated
  // polling requests.
  const [getFinancialView, { data }] = useLazyQuery<
  WalkthroughGraphQL.MemberLatestFinancialViewQuery,
  WalkthroughGraphQL.MemberLatestFinancialViewQueryVariables
  >(gql(WalkthroughGraphQL.memberLatestFinancialView))

  const isMounted = useIsMounted()
  const isFocused = useIsFocused()

  // If we leave the jungle screen or display the question overlay, hide the savings rate
  // dropdown menu before we return
  React.useEffect(() => {
    if (!isFocused || savingsRateQuestionIndex !== null) {
      setShowSavingsRateDropdownInsteadOfTask(false)
    }
  }, [isFocused, savingsRateQuestionIndex])

  React.useEffect(() => {
    // There are known race condititions with async code in useEffect (like what if the component unmounts before the
    // function returns?), but this is still the recommended approach. See
    // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function getOwnerAndQuery (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getFinancialView({ variables: { owner: owner } })
      }
    }
    getOwnerAndQuery().catch(console.log)
  }, [])

  React.useEffect(() => {
    const activeTaskTypename = data?.getMember?.activeTask?.taskTypename
    if (isNonNullish(activeTaskTypename)) {
      setActiveTaskTitle(getTaskTitle(activeTaskTypename))
    }

    const taskTypename = data?.getMember?.activeTask?.taskTypename
    const taskPrimaryKey = data?.getMember?.activeTask?.taskPrimaryKey
    const recommendedByWalkthrough: boolean =
      data?.getMember?.activeTaskWasRecommendedByWalkthrough ?? false

    if (
      isNonNullish(data) &&
      isNonNullish(taskTypename) &&
      isNonNullish(taskPrimaryKey)
    ) {
      if (recommendedByWalkthrough) {
        setWhyThisTask(getWhyThisTask(data, taskTypename, taskPrimaryKey))
      } else {
        setWhyThisTask(
          <Text>
            You chose this task! If you want Walkthrough to recommend the best
            task for you to tackle, click{' '}
            <NavigationAnchor
              text={'here'}
              handlePress={(nav) =>
                nav.navigate('Tasks', {
                  screen: 'TaskScreen',
                  params: {
                    flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL
                  }
                })
              }
            />
            .
          </Text>
        )
      }
    } else {
      setWhyThisTask(null)
    }
  }, [data])

  const netWorth = getNetWorth(data)
  const savingsRate = getSavingsRate(data)

  const monthlySpending = data?.getMember?.estimatedAverageMonthlySpending
  const monthlyAfterTaxIncome =
    data?.getMember?.estimatedAverageMonthlyAfterTaxIncome

  const insets = useSafeAreaInsets()

  return (
    <>
      <View
        style={[
          jungleScreenStyle.jungleScreen,
          {
            // Paddings to handle safe area for the jungle screen.
            // We don't need to use insets.bottom because this screen has tab navigation on the bottom.
            paddingTop: insets.top,
            paddingLeft: insets.left,
            paddingRight: insets.right
          }
        ]}
      >
        <View style={jungleScreenStyle.modulesContainer}>
          {isNonNullish(savingsRate) &&
          isNonNullish(netWorth) &&
          isNonNullish(monthlySpending) &&
          isNonNullish(monthlyAfterTaxIncome) ? (
            <Pressable
              onPress={() =>
                setShowSavingsRateDropdownInsteadOfTask(
                  !showSavingsRateDropdownInsteadOfTask
                )
              }
              style={jungleScreenStyle.module}
            >
              <Text style={[textStyle.extraLargeText, textStyle.boldText]}>
                {getSavingsRateStringToRender(
                  savingsRate,
                  netWorth,
                  monthlySpending,
                  monthlyAfterTaxIncome
                )}
              </Text>
              <Text style={textStyle.regularText}>Savings rate</Text>
            </Pressable>
              ) : (
            // If we don't have savings rate, ask for estimated average monthly take-home income & spending
            // when the module is pressed. The QuestionOverlay is in charge of setting the questionIndex back to
            // null, so we don't need to handle that here.
            <Pressable
              onPress={() => setSavingsRateQuestionIndex(0)}
              style={jungleScreenStyle.module}
            >
              <Text style={[textStyle.extraLargeText, textStyle.boldText]}>
                ?
              </Text>
              <Text style={textStyle.regularText}>Savings rate</Text>
            </Pressable>
              )}
          {isNonNullish(netWorth) ? (
            <Pressable
              onPress={() => props.navigation.navigate('Accounts')}
              style={jungleScreenStyle.module}
            >
              <Text style={[textStyle.extraLargeText, textStyle.boldText]}>
                {netWorth.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
              </Text>
              <Text style={textStyle.regularText}>Net worth</Text>
            </Pressable>
          ) : null}
        </View>
        {showSavingsRateDropdownInsteadOfTask ? (
          <View style={jungleScreenStyle.savingsRateDropdown}>
            <BoxWithCarrot>
              <Pressable
                onPress={() => {
                  setSavingsRateQuestionIndex(0)
                }}
                style={[
                  viewStyle.smallTopMargin,
                  viewStyle.smallBottomMargin,
                  buttonStyle(THEME.color.highlight, THEME.color.outline)
                    .smallButton
                ]}
              >
                <Text style={[textStyle.largeText, textStyle.boldText]}>
                  Update savings rate
                </Text>
              </Pressable>
              <Pressable
                onPress={() =>
                  props.navigation.navigate('ClickThroughModuleScreen', {
                    module: ContentModule.SAVINGS_RATE
                  })
                }
                style={[
                  buttonStyle(THEME.color.superHighlight, THEME.color.outline)
                    .smallButton,
                  viewStyle.smallBottomMargin
                ]}
              >
                <Text style={[textStyle.largeText, textStyle.boldText]}>
                  Check projections
                </Text>
              </Pressable>
            </BoxWithCarrot>
          </View>
        ) : (
          <View style={jungleScreenStyle.taskContainer}>
            <Pressable
              onPress={() => {
                // If the why this task dropdown is currently open, close it instead of navigating to the task screen.
                // The member can click the task again to navigate to the task screen.
                if (showWhyThisTask) {
                  setShowWhyThisTask(false)
                } else if (isNonNullish(data?.getMember?.activeTask)) {
                  props.navigation.navigate('Tasks', {
                    screen: 'TaskScreen',
                    params: {
                      taskKey: {
                        taskTypename: data?.getMember?.activeTask.taskTypename,
                        taskId: data?.getMember?.activeTask.taskPrimaryKey
                      },
                      // Returns undefined unless activeTaskAbsoluteDollarsToAllocate is actually stored as null
                      dollarsToAllocateToTask:
                        data?.getMember?.activeTaskAbsoluteDollarsToAllocate,
                      flowStage: TaskFlowStage.STEPS
                    }
                  })
                } else if (isNonNullish(data)) {
                  // If data is defined but lacks an active task, try to determine one
                  props.navigation.navigate('Tasks', {
                    screen: 'TaskScreen',
                    params: {
                      flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL
                    }
                  })
                }
              }}
              style={jungleScreenStyle.task}
            >
              <View style={jungleScreenStyle.taskTitleContainer}>
                <Text style={textStyle.largeText}>
                  <Text style={textStyle.boldText}>Your task: </Text>
                  {activeTaskTitle ?? 'Click here to determine your next task'}
                </Text>
              </View>
              {isNonNullish(whyThisTask) ? (
                <Pressable
                  onPress={() => setShowWhyThisTask(!showWhyThisTask)}
                  style={jungleScreenStyle.whyThisTaskIconContainer}
                >
                  <QuestionMarkCircle />
                </Pressable>
              ) : null}
            </Pressable>
            {showWhyThisTask && isNonNullish(whyThisTask) ? (
              <BoxWithCarrot
                customCarrotViewStyle={{
                  // Lines up the carrot with the why this task icon. Accounts for the
                  // margin from the right and the width of the why this task icon. 6 is
                  // empirically determined to line up with the center of the why this
                  // task question mark icon.
                  right: THEME.spacing.horizontalSpaceMedium + 6
                }}
              >
                <View style={jungleScreenStyle.whyThisTaskDropdown}>
                  <Text style={[textStyle.largeText, textStyle.boldText]}>
                    Why this task?
                  </Text>
                  <Text style={textStyle.largeText}>{whyThisTask}</Text>
                </View>
              </BoxWithCarrot>
            ) : null}
          </View>
        )}
        {/* Points need to go first so the dropping points render below the savings rate game. */}
        <Points
          pointsToAddAnimation={pointsToAddAnimation}
          setPointsToAddAnimation={setPointsToAddAnimation}
        />
        {isNonNullish(savingsRate) &&
        !isToday(
          parseISO(data?.getMember?.lastReceivedSavingsRatePoints ?? '')
        ) ? (
          <SavingsRateGame
            setSavingsRateQuestionIndex={setSavingsRateQuestionIndex}
            setPointsToAddAnimation={setPointsToAddAnimation}
          />
            ) : null}
        {
          // Transparent overlay that closes savings rate dropdown menu if you click anywhere except
          // that menu. We'd like to include this as part of the dropdown subcomponent above, but it's
          // difficult to size it to fullscreen when it's a deeply nested child component as the screen
          // dimensions are not obviously available.
          showSavingsRateDropdownInsteadOfTask ? (
            <Pressable
              style={jungleScreenStyle.dropDownExitPressable}
              onPress={() => {
                setShowSavingsRateDropdownInsteadOfTask(
                  !showSavingsRateDropdownInsteadOfTask
                )
              }}
            />
          ) : null
        }
        {
          // Transparent overlay that closes the why this task dropdown menu if you click anywhere except
          // that menu. We'd like to include this as part of the dropdown subcomponent above, but it's
          // difficult to size it to fullscreen when it's a deeply nested child component as the screen
          // dimensions are not obviously available.
          showWhyThisTask ? (
            <Pressable
              style={jungleScreenStyle.dropDownExitPressable}
              onPress={() => {
                setShowWhyThisTask(!showWhyThisTask)
              }}
            />
          ) : null
        }
        <Pressable
          onPress={() => setShowYuyu(!showYuyu)}
          style={jungleScreenStyle.jungleBackground}
        >
          {showYuyu ? <JungleLeftYuyu /> : <JungleLeft />}
        </Pressable>
      </View>
      {isNonNullish(savingsRateQuestionIndex) &&
      isNonNullish(savingsRateQuestions) &&
      savingsRateQuestionIndex >= 0 &&
      savingsRateQuestionIndex < savingsRateQuestions?.length ? (
        <QuestionOverlay
          questionIndex={savingsRateQuestionIndex}
          setQuestionIndex={setSavingsRateQuestionIndex}
          questions={savingsRateQuestions}
        />
          ) : null}
    </>
  )
}

export { JungleScreen }
