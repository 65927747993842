"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sumAllEarmarksOfType = exports.sumAllTransactionAggregationsOfType = exports.getCurrentAllocationForTask = void 0;
const account_processor_1 = require("../account-processor");
const task_processor_1 = require("../task-processor");
const global_utils_1 = require("global-utils");
const build_emergency_fund_1 = require("./build-emergency-fund");
const extend_emergency_fund_to_three_months_1 = require("./extend-emergency-fund-to-three-months");
const contribute_to_401k_2022_1 = require("./contribute-to-401k-2022");
const contribute_to_ira_2022_1 = require("./contribute-to-ira-2022");
const contribute_to_401k_1 = require("./contribute-to-401k");
const contribute_to_ira_1 = require("./contribute-to-ira");
const sumReducer = (sum, next) => sum + next;
// Returns the number of dollars CURRENTLY allocated to the specified task within
// the data memberQuery, or null if the task is not included in the memberQuery or
// is otherwise inapplicable.  Notably, some task types are categoriaclly inapplicable
// (like paying off critical expenses).
// The idea of allocating dollars to tasks is shared by both the app (FE/BE) and the Goal Engine.
// Changes that cause their definitions of this idea to diverge may lead to bugs.  As of July 2022,
// goal engine logic for this is https://github.com/eettaa/emb/blob/8f2eec8eb3b1c38924077068f2ea3eb555f27ed4/src/shared/goal-engine/dollars-until-no-longer-best-goal.ts#L393
function getCurrentAllocationForTask(memberQuery, taskKey) {
    const tasks = (0, task_processor_1.getTasksFromContainer)(memberQuery === null || memberQuery === void 0 ? void 0 : memberQuery.getMember);
    const handlers = {
        payCriticalExpensesTask: () => null,
        extendEmergencyFundToSixMonthsTask: () => null,
        investInBrokerageAccountTask: () => null,
        payOffDebtTasks: () => null,
        buildOneMonthEmergencyFundTask: (0, build_emergency_fund_1.buildEmergencyFundTaskCurrentAllocationHandler)(taskKey, memberQuery),
        extendEmergencyFundToThreeMonthsTask: (0, extend_emergency_fund_to_three_months_1.extendEmergencyFundToThreeMonthsTaskCurrentAllocationHandler)(taskKey, memberQuery),
        contributeTo401k2022Tasks: (0, contribute_to_401k_2022_1.contributeTo401k2022TaskCurrentAllocationHandler)(taskKey, memberQuery),
        contributeTo401kTasks: (0, contribute_to_401k_1.contributeTo401kTaskCurrentAllocationHandler)(taskKey, memberQuery),
        contributeToIra2022Task: (0, contribute_to_ira_2022_1.contributeToIra2022TaskCurrentAllocationHandler)(taskKey, memberQuery),
        contributeToIraTasks: (0, contribute_to_ira_1.contributeToIraTaskCurrentAllocationHandler)(taskKey, memberQuery)
    };
    const results = (0, global_utils_1.collapseProcessingResultsIntoArray)((0, task_processor_1.processAllFinancialTasksFromGetMemberQuery)(tasks, handlers)).filter(global_utils_1.isNonNullish);
    if (results.length === 0) {
        return null;
    }
    else {
        return results.reduce(sumReducer, 0);
    }
}
exports.getCurrentAllocationForTask = getCurrentAllocationForTask;
// Sums all transactionAggregations of type transactionAggregationType across ALL ACCOUNTS IN THE QUERY.
function sumAllTransactionAggregationsOfType(memberQuery, transactionAggregationType) {
    if (!(0, global_utils_1.isNonNullish)(memberQuery)) {
        return 0;
    }
    function sumOneAccount(a) {
        var _a, _b;
        if ((0, global_utils_1.isNonNullish)(a) &&
            Object.prototype.hasOwnProperty.call(a, 'transactionAggregations')) {
            return ((_b = (_a = a.transactionAggregations) === null || _a === void 0 ? void 0 : _a.filter(global_utils_1.isNonNullish).filter((t) => t.type === transactionAggregationType).map((t) => { var _a; return (_a = t.amount) !== null && _a !== void 0 ? _a : 0; }).reduce(sumReducer, 0)) !== null && _b !== void 0 ? _b : 0);
        }
        return 0;
    }
    const accounts = (0, account_processor_1.getAccountsFromContainer)(memberQuery === null || memberQuery === void 0 ? void 0 : memberQuery.getMember);
    const handlers = {
        savingsAccounts: sumOneAccount,
        checkingAccounts: sumOneAccount,
        debtAccounts: sumOneAccount,
        otherInvestmentAccounts: sumOneAccount,
        traditionalIraAccounts: sumOneAccount,
        rothIraAccounts: sumOneAccount,
        k401Accounts: sumOneAccount,
        uncategorizedAccounts: sumOneAccount
    };
    return (0, global_utils_1.collapseProcessingResultsIntoArray)((0, account_processor_1.processAllAccountsFromGetMemberQuery)(accounts, handlers)).reduce(sumReducer, 0);
}
exports.sumAllTransactionAggregationsOfType = sumAllTransactionAggregationsOfType;
// Sums all earmarks of type earmarkType across ALL ACCOUNTS IN THE QUERY.
function sumAllEarmarksOfType(memberQuery, earmarkType) {
    if (!(0, global_utils_1.isNonNullish)(memberQuery)) {
        return 0;
    }
    function sumOneAccount(a) {
        var _a, _b;
        if ((0, global_utils_1.isNonNullish)(a) &&
            Object.prototype.hasOwnProperty.call(a, 'earmarks')) {
            return ((_b = (_a = a.earmarks) === null || _a === void 0 ? void 0 : _a.filter(global_utils_1.isNonNullish).filter((e) => e.type === earmarkType).map((e) => { var _a; return (_a = e.amount) !== null && _a !== void 0 ? _a : 0; }).reduce(sumReducer, 0)) !== null && _b !== void 0 ? _b : 0);
        }
        return 0;
    }
    const accounts = (0, account_processor_1.getAccountsFromContainer)(memberQuery === null || memberQuery === void 0 ? void 0 : memberQuery.getMember);
    const handlers = {
        savingsAccounts: sumOneAccount,
        checkingAccounts: sumOneAccount,
        debtAccounts: sumOneAccount,
        otherInvestmentAccounts: sumOneAccount,
        traditionalIraAccounts: sumOneAccount,
        rothIraAccounts: sumOneAccount,
        k401Accounts: sumOneAccount,
        uncategorizedAccounts: sumOneAccount
    };
    return (0, global_utils_1.collapseProcessingResultsIntoArray)((0, account_processor_1.processAllAccountsFromGetMemberQuery)(accounts, handlers)).reduce(sumReducer, 0);
}
exports.sumAllEarmarksOfType = sumAllEarmarksOfType;
