import { WhyThisTaskHandler } from './get-why-this-task'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { Text } from 'react-native'
import { isNonNullish } from 'global-utils'
import { getTasksAsSentenceFragment } from './get-tasks-as-sentence-fragments'

export function investInBrokerageAccountWhyThisTaskHandler (
  data: WalkthroughGraphQL.GetMemberQuery,
  taskTypename: string,
  contributionYear: number,
  minDebtInterestRate: number
): WhyThisTaskHandler<WalkthroughGraphQL.InvestInBrokerageAccountTask> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      task.__typename === taskTypename
    ) {
      const riskMitigationTaskSentenceFragment = getTasksAsSentenceFragment(
        data,
        new Set(['extendEmergencyFundToThreeMonthsTask']),
        /* includeIfComplete = */ true,
        /* includeIfIncomplete = */ false,
        contributionYear,
        minDebtInterestRate,
        'and'
      )
      const opportunityTaskSentenceFragment = getTasksAsSentenceFragment(
        data,
        new Set([
          'payOffDebtTasks',
          'contributeTo401kTasks',
          'contributeToIraTasks'
        ]),
        /* includeIfComplete = */ true,
        /* includeIfIncomplete = */ false,
        contributionYear,
        minDebtInterestRate,
        'and'
      )

      return (
        <Text>
          {isNonNullish(riskMitigationTaskSentenceFragment) ? (
            <Text>
              You've ensured you're protected from risk by{' '}
              {riskMitigationTaskSentenceFragment} and you've
            </Text>
          ) : (
            <Text>You've</Text>
          )}{' '}
          already tackled the best opportunities you have
          {isNonNullish(opportunityTaskSentenceFragment) ? (
            <Text> — {opportunityTaskSentenceFragment} — </Text>
          ) : (
            <Text> </Text>
          )}
          so now you get to start investing money in a brokerage account.
        </Text>
      )
    }
    return null
  }
}
