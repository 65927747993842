// This file checks member accounts and builds appropriate account alerts in a QuestionAndAnswers[][] shape.
import {
  getAccountsFromContainer,
  processAllAccountsFromMemberLatestFinancialViewQuery,
  AccountWithSnapshotShape,
  InputAccountTypesMemberLatestFinancialViewQuery,
  validateAccountForHandler
} from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish, collapseProcessingResultsIntoArray } from 'global-utils'
import {
  AccountLinkingFlowType,
  getAccountLinkingFlowType
} from '../../account-linking/account-linking-flow-type'

export interface AccountRefreshAlertData {
  accountLinkingFlowType: AccountLinkingFlowType
  account: Omit<WalkthroughGraphQL.Account, '__typename'>
}
export type AccountRefreshAlertHandlerType<T> = (
  account: T | null
) => AccountRefreshAlertData | null

export function getAccountRefreshAlerts (
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
): AccountRefreshAlertData[] {
  const accounts = getAccountsFromContainer(memberQuery?.getMember)
  const accountToRefreshAlertHandlers = {
    savingsAccounts:
      accountRefreshAlertsHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['savingsAccounts']
      >(),
    checkingAccounts:
      accountRefreshAlertsHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['checkingAccounts']
      >(),
    debtAccounts:
      accountRefreshAlertsHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
      >(),
    otherInvestmentAccounts:
      accountRefreshAlertsHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['otherInvestmentAccounts']
      >(),
    traditionalIraAccounts:
      accountRefreshAlertsHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['traditionalIraAccounts']
      >(),
    rothIraAccounts:
      accountRefreshAlertsHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['rothIraAccounts']
      >(),
    k401Accounts:
      accountRefreshAlertsHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
      >(),
    uncategorizedAccounts:
      accountRefreshAlertsHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['uncategorizedAccounts']
      >()
  }

  const refreshAlerts = processAllAccountsFromMemberLatestFinancialViewQuery(
    accounts,
    accountToRefreshAlertHandlers
  )
  return collapseProcessingResultsIntoArray(refreshAlerts).filter(isNonNullish)
}

function accountRefreshAlertsHandler<
  T extends Omit<WalkthroughGraphQL.Account, '__typename'> &
  AccountWithSnapshotShape
> (): AccountRefreshAlertHandlerType<T> {
  return (account) => {
    try {
      // This if block allows us to assume that account is nonullable further down
      if (
        !validateAccountForHandler(
          account,
          'accountRefreshAlertsGenericHandler'
        )
      ) {
        return null
      }
    } catch (e) {
      return null
    }
    if (
      isNonNullish(account.snapshots) &&
      account.snapshots?.items?.length > 0 &&
      isNonNullish(account.snapshots.items[0]) &&
      isNonNullish(account.snapshots.items[0]?.dataProviderActionNeededStatus)
    ) {
      const accountLinkingFlowType = getAccountLinkingFlowType(
        account.snapshots.items[0].dataProviderActionNeededStatus
      )
      if (isNonNullish(accountLinkingFlowType)) {
        return {
          accountLinkingFlowType: accountLinkingFlowType,
          account: account
        }
      }
    }
    return null
  }
}
