import { WhyThisTaskHandler } from './get-why-this-task'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { Text } from 'react-native'
import { isNonNullish } from 'global-utils'
import { getEmployerMatchString } from '../../../../../util/account-parsing'
import { getTasksAsSentenceFragment } from './get-tasks-as-sentence-fragments'

export function contributeTo401kWhyThisTaskHandler (
  data: WalkthroughGraphQL.GetMemberQuery,
  taskTypename: string,
  taskPrimaryKey: string,
  contributionYear: number,
  minDebtInterestRate: number
): WhyThisTaskHandler<WalkthroughGraphQL.ContributeTo401kTask> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      task.__typename === taskTypename &&
      task.id === taskPrimaryKey
    ) {
      let k401Account = task.k401Account
      if (!isNonNullish(k401Account)) {
        k401Account = data.getMember?.k401Accounts?.items?.find(
          (account) => account?.accountId === task.k401AccountId
        )
      }
      const matchRules = k401Account?.employerMatchRules

      let firstPart: JSX.Element = <></>
      if (task.stepsCompletedAtLeastOnce === true) {
        // If the user has completed this task before.
        firstPart = (
          <Text>
            You can still contribute more to your 401k this year, which means
            you can still save more on taxes. These tax savings are a big deal
            and give
          </Text>
        )
      } else if (
        isNonNullish(k401Account) &&
        isNonNullish(matchRules) &&
        matchRules.length > 0
      ) {
        // If the user has never completed this task before and *has* an employer match.
        firstPart = (
          <Text>
            You get an employer match of {getEmployerMatchString(k401Account)}!
            This is huge and gives
          </Text>
        )
      } else {
        // If the user has never completed this task before and *does not have* an employer match.
        firstPart = (
          <Text>
            401(k)s are a great way to pay less in taxes on your investments.
            These tax savings are a big deal and give
          </Text>
        )
      }

      const alternativeTasksSentenceFragment = getTasksAsSentenceFragment(
        data,
        new Set([
          'payOffDebtTasks',
          'contributeToIraTasks',
          'investInBrokerageAccountTask'
        ]),
        /* includeIfComplete = */ false,
        /* includeIfIncomplete = */ true,
        contributionYear,
        minDebtInterestRate,
        'and'
      )

      return (
        <Text>
          {firstPart} this task an edge
          {isNonNullish(alternativeTasksSentenceFragment) ? (
            <Text> over {alternativeTasksSentenceFragment}</Text>
          ) : null}
          .
        </Text>
      )
    }
    return null
  }
}
