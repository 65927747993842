"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.memberTasksAndAccounts = void 0;
// Queries only member tasks and accounts (excluding account snapshots).  Only required and owner fields are
// returned.
exports.memberTasksAndAccounts = `
  query MemberTasksAndAccounts($owner: String!) {
    getMember(owner: $owner){
      owner
      createdAt
      updatedAt
      payCriticalExpensesTask {
        owner
        createdAt
        updatedAt
        isComplete
      }
      buildOneMonthEmergencyFundTask {
        owner
        createdAt
        updatedAt
        isComplete
      }
      extendEmergencyFundToThreeMonthsTask {
        owner
        createdAt
        updatedAt
        isComplete
      }
      extendEmergencyFundToSixMonthsTask {
        owner
        createdAt
        updatedAt
        isComplete
      }
      investInBrokerageAccountTask {
        owner
        createdAt
        updatedAt
        isComplete
      }
      payOffDebtTasks {
        items {
          id
          owner
          isComplete
          createdAt
          updatedAt
          debtAccountId
          debtAccount {
            accountId
            owner
            createdAt
            updatedAt
            dataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
          }
        }
      }
      contributeTo401k2022Tasks {
        items {
          id
          owner
          isComplete
          createdAt
          updatedAt
          k401AccountId
          k401Account {
            accountId
            owner
            createdAt
            updatedAt
            dataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
          }
        }
      }
      contributeTo401kTasks {
        items {
          id
          owner
          isComplete
          contributionYear
          createdAt
          updatedAt
          k401AccountId
          k401Account {
            accountId
            owner
            createdAt
            updatedAt
            dataProvider
            dataProviderAccountId
            dataProviderIdForFinancialInstitutionUser
          }
        }
      }
      contributeToIra2022Task {
        owner
        createdAt
        updatedAt
        isComplete
      }
      contributeToIraTasks {
        items {
          id
          owner
          isComplete
          contributionYear
          createdAt
          updatedAt
        }
      }


      savingsAccounts {
        items {
          accountId
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
        }
      }
      checkingAccounts {
        items {
          accountId
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
        }
      }
      debtAccounts {
        items {
          accountId
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
        }
      }
      k401Accounts {
        items {
          accountId
          owner
          createdAt
          updatedAt
          accountId
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
        }
      }
      traditionalIraAccounts {
        items {
          accountId
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
        }
      }
      rothIraAccounts {
        items {
          accountId
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
        }
      }
      otherInvestmentAccounts {
        items {
          accountId
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
        }
      }
      uncategorizedAccounts {
        items {
          accountId
          owner
          createdAt
          updatedAt
          dataProvider
          dataProviderAccountId
          dataProviderIdForFinancialInstitutionUser
        }
      }
    }
  }
`;
