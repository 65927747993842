import * as WalkthroughGraphQL from 'amplify-client-graphql'
import iso3166 from 'iso-3166-2'
import { loadToken } from '../screens/auth/load-token'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'

function getUsaStates (): string[] {
  const subdivisions = iso3166.country('USA')?.sub
  if (subdivisions === undefined) {
    return []
  }
  return Object.entries(subdivisions)
    .map((subdivisionObject) => {
      return subdivisionObject[1].name
    })
    .sort((a, b) => a.localeCompare(b))
}

// Calls API to store the US state where the member is a resident. State must be a ISO 3166-2
// subdivision. Uses iso-3166-2 library for validation: https://www.npmjs.com/package/iso-3166-2
async function storeUsaState (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  state: string
): Promise<void> {
  if (iso3166.subdivision('USA', state) === null) {
    console.log(
      "Provided value ('" +
        state +
        "') is not USA ISO 3166-2 subdivision. Frontend should be validating string before it gets here."
    )
    return
  }

  try {
    await apolloClient.mutate<
    WalkthroughGraphQL.UpdateMemberMutation,
    WalkthroughGraphQL.UpdateMemberMutationVariables
    >({
      mutation: gql(WalkthroughGraphQL.updateMember),
      variables: {
        input: {
          owner: await loadToken(),
          state: state
        }
      }
    })
  } catch (e) {
    console.log(e)
  }
}

export { storeUsaState, getUsaStates }
