import * as React from 'react'
import {
  TaskStepsRenderingStyle,
  TaskFlowData,
  TaskFlowStage
} from '../task-flow-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { TaskFlowDataConverter } from './get-task-flow-data'
import { Anchor } from '../../common/links/anchor'
import { NavigationAnchor } from '../../common/links/navigation-anchor'
import { ContentModule } from '../../click-through-module/click-through-module-screen'
import { gql } from '@apollo/client'
import { extractBooleanFromJsonMap } from 'global-utils'
import { getTaskTitle } from './get-task-title'

const intro =
  "Let's take a minute to make sure you've got all your essential expenses covered. You can probably " +
  "guess most of what's on the list, but can you guess it all?\n\nCheck your knowledge (and check off boxes!):"

const steps =
  "Money basics should probably have been covered in high school, but they weren't, so we're gonna " +
  'walk through them here to make sure all of your critical expenses are covered. You might know a lot of this ' +
  "already 'cause you're an adult adulting in the world, and if so that's great! Just keep swimming 🐠 (aka " +
  'checking off those check boxes).'

const autopayKey = 'AUTOPAY'
const autopay = (
  <>
    Setting up autopay for what bills you can is a great way to make sure you
    never miss a payment. It also means you won't be tempted to spend that money
    on something less important while the bill is still in the mail.{'\n\n'}
    The one gotcha is that you need to make sure you don't overdraw your account
    and get charged fees. Each time you set up autopay, make sure you understand
    what happens if the account doesn't have enough money in it.{'\n\n'}
    We'll point out opportunities to set up autopay as you keep going.
  </>
)

const setupAutopayKey = 'SETUP'
const setupAutopay =
  'To set up autopay, go to the website where you pay your bill and look for an automatic ' +
  "payments option. If you can't find it, give that company a call and try to set it up over the phone.\n\n"

const reachOutKey = 'REACH_OUT'
const reachOut = (
  <>
    We know telling you to buy these things isn't helpful if you don't have the
    money. If you're in that situation, all we can say is do anything you can to
    get these expenses covered. Try not to spend money on anything else, and
    look around for any creative ways to make extra money. No one is saying it's
    going to be easy.{'\n\n'}Depending on your situation, we may be able to give
    guidance on things like budgeting, applying for food stamps/unemployment,
    and even declaring bankruptcy. We want to help. Please reach out to us at{' '}
    <Anchor
      text={'support@walkthrough.co'}
      href={'mailto:support@walkthrough.co'}
    />
    .
  </>
)

const housingKey = 'HOUSING'
const housing =
  "Pay your rent or mortgage.\n\nPay your home or renter's insurance if that's required in your " +
  "situation (this is a good candidate for autopay!).\n\nWhen you're able to pay this every month, move on to the " +
  'next item.'

const foodKey = 'FOOD'
const food =
  "Buy groceries for you and your family.\n\nWhen you're able to do this every month, move on to the " +
  'next item.'

const utilitiesKey = 'UTILITIES'
const utilities =
  'Pay for power, water, heating, and toiletries.\n\nEverything except toiletries is a good candidate ' +
  "for autopay. Set it up if it's right for you, then continue to the next item."

const incomeEarningKey = 'EARNING'
const incomeEarning =
  'Buy things that let you keep earning your income.\n\nPay the bare minimum you can to get to ' +
  'work, keep basic internet working if you need it, etc.\n\nSet up autopay for phone and internet (are you seeing a ' +
  'pattern yet? 😛).'

const healthInsuranceKey = 'HEALTH'
const healthInsurance = (
  <>
    Buy health insurance for you and your family.{'\n\n'}If this is hard on your
    budget, buy a cheap, high-deductible plan. The point is to cover something
    really really bad happening, like you getting in a car crash.{'\n\n'}
    Start at <Anchor
      text="healthcare.gov"
      href="https://www.healthcare.gov"
    />{' '}
    or your employer to explore good coverage options.{'\n\n'}Insurance payments
    are another good candidate for autopay.
  </>
)

const lifeInsuranceKey = 'LIFE'
const lifeInsurance = (
  <>
    {/* TODO(COPY_UPDATE): add link to internal life insurance module once we have one. */}
    If you have children or dependents, you should have some form of life
    insurance.{'\n\n'}You can go to{' '}
    <Anchor href="https://www.policygenius.com" text="policygenius.com" /> (
    <NavigationAnchor
      text="no kickbacks"
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.NO_KICKBACKS
        })
      }
    />
    !) to get started. In general, TERM (not whole) life insurance should be
    enough for the basics.
  </>
)

const minimumDebtKey = 'MIN_DEBT'
const minimumDebt =
  'Pay the minimum payment on all your outstanding debts. This keeps you out of legal trouble and ' +
  'keeps collections companies out of business. Win-win, right?\n\nThese payments are another good candidate for ' +
  'autopay. Look for options to "pay minimum due" or "pay full balance" and choose the right option for you based ' +
  'on what you can afford.'

export const payCriticalExpensesTaskToFlowData: TaskFlowDataConverter<
WalkthroughGraphQL.PayCriticalExpensesTask
> =
  (
    taskTypename,
    taskId,
    absoluteDollarsToAllocateToTask,
    currentlyAllocatedDollars,
    client,
    recommendedByWalkthrough
  ) =>
    (task) => {
      if (task === null) {
        return null
      }
      if (task.__typename !== taskTypename) {
        return null
      }
      if (task.owner !== taskId) {
        return null
      }

      // Note that we refer to the JS object (not the JSON string!) when writing this back to the DB. This means that each
      // time the object passes through the FE it is revalidated for JSON parseability (although we still lack enforcement
      // that it conforms to a {[key: string]: boolean} type)
      const subtaskCompletionState: any = JSON.parse(
        task.subtaskCompletion ?? '{}'
      )

      // Generate a storage function to associate with a subtask.
      // We define it as an arrow function so it is interpreted within the control flow (needed for the compiler to infer
      // that task is not null). See https://github.com/microsoft/TypeScript/issues/32339 and 32300 for more.
      const storeCompletionGenerator = (key: string) => {
        return (complete: boolean) => {
          client
            .mutate<
          WalkthroughGraphQL.UpdatePayCriticalExpensesTaskMutation,
          WalkthroughGraphQL.UpdatePayCriticalExpensesTaskMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updatePayCriticalExpensesTask),
            variables: {
              input: {
                owner: task.owner,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            },
            optimisticResponse: {
              updatePayCriticalExpensesTask: {
                ...task,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            }
          })
            .catch(console.log)
        }
      }

      const payEssentialsFlowData: TaskFlowData = {
        steps: {
          stepRenderingStyle: TaskStepsRenderingStyle.CHECKLIST,
          title: getTaskTitle(task.__typename),
          description: steps,
          subtasks: [
            {
              key: autopayKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                autopayKey
              ),
              storeCompletionState: storeCompletionGenerator(autopayKey),
              title: 'Learn about automatic payments',
              description: autopay
            },
            {
              key: setupAutopayKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                setupAutopayKey
              ),
              storeCompletionState: storeCompletionGenerator(setupAutopayKey),
              title: 'Set it up',
              description: setupAutopay
            },

            {
              key: reachOutKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                reachOutKey
              ),
              storeCompletionState: storeCompletionGenerator(reachOutKey),
              title: 'Before we tell you to buy a bunch of stuff...',
              description: reachOut
            },
            {
              key: housingKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                housingKey
              ),
              storeCompletionState: storeCompletionGenerator(housingKey),
              title: 'Pay housing basics',
              description: housing
            },
            {
              key: foodKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                foodKey
              ),
              storeCompletionState: storeCompletionGenerator(foodKey),
              title: 'Buy groceries',
              description: food
            },
            {
              key: utilitiesKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                utilitiesKey
              ),
              storeCompletionState: storeCompletionGenerator(utilitiesKey),
              title: 'Pay utilities',
              description: utilities
            },
            {
              key: incomeEarningKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                incomeEarningKey
              ),
              storeCompletionState: storeCompletionGenerator(incomeEarningKey),
              title: 'Pay income-earning expenses',
              description: incomeEarning
            },
            {
              key: healthInsuranceKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                healthInsuranceKey
              ),
              storeCompletionState: storeCompletionGenerator(healthInsuranceKey),
              title: 'Pay for health insurance',
              description: healthInsurance
            },
            {
              key: lifeInsuranceKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                lifeInsuranceKey
              ),
              storeCompletionState: storeCompletionGenerator(lifeInsuranceKey),
              title: 'Consider life insurance',
              description: lifeInsurance
            },
            {
              key: minimumDebtKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                minimumDebtKey
              ),
              storeCompletionState: storeCompletionGenerator(minimumDebtKey),
              title: 'Make all minimum debt payments',
              description: minimumDebt
            }
          ],
          button: {
            buttonText: 'Next',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              const response = client.mutate<
              WalkthroughGraphQL.UpdatePayCriticalExpensesTaskMutation,
              WalkthroughGraphQL.UpdatePayCriticalExpensesTaskMutationVariables
              >({
                mutation: gql(WalkthroughGraphQL.updatePayCriticalExpensesTask),
                variables: {
                  input: {
                    owner: task.owner,
                    isComplete: true
                  }
                }
              })
              props.navigation.navigate('TaskScreen', {
                flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL,
                waitForPromise: response
              })
            }
          }
        },
        introData: {
          title: getTaskTitle(task.__typename),
          introContent: intro,
          button: {
            buttonText: 'Pay your essentials',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskScreen', {
                taskKey: {
                  taskTypename: task.__typename,
                  taskId: task.owner
                },
                flowStage: TaskFlowStage.STEPS,
                absoluteDollarsToAllocateToTask: absoluteDollarsToAllocateToTask,
                recommendedByWalkthrough: recommendedByWalkthrough
              })
            }
          },
          alternativeButton: {
            buttonText: 'Change tasks',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskListScreen')
            }
          }
        }
      }
      return payEssentialsFlowData
    }
