/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const PublicSans_100Thin = require('./PublicSans_100Thin.ttf');
export const PublicSans_200ExtraLight = require('./PublicSans_200ExtraLight.ttf');
export const PublicSans_300Light = require('./PublicSans_300Light.ttf');
export const PublicSans_400Regular = require('./PublicSans_400Regular.ttf');
export const PublicSans_500Medium = require('./PublicSans_500Medium.ttf');
export const PublicSans_600SemiBold = require('./PublicSans_600SemiBold.ttf');
export const PublicSans_700Bold = require('./PublicSans_700Bold.ttf');
export const PublicSans_800ExtraBold = require('./PublicSans_800ExtraBold.ttf');
export const PublicSans_900Black = require('./PublicSans_900Black.ttf');
export const PublicSans_100Thin_Italic = require('./PublicSans_100Thin_Italic.ttf');
export const PublicSans_200ExtraLight_Italic = require('./PublicSans_200ExtraLight_Italic.ttf');
export const PublicSans_300Light_Italic = require('./PublicSans_300Light_Italic.ttf');
export const PublicSans_400Regular_Italic = require('./PublicSans_400Regular_Italic.ttf');
export const PublicSans_500Medium_Italic = require('./PublicSans_500Medium_Italic.ttf');
export const PublicSans_600SemiBold_Italic = require('./PublicSans_600SemiBold_Italic.ttf');
export const PublicSans_700Bold_Italic = require('./PublicSans_700Bold_Italic.ttf');
export const PublicSans_800ExtraBold_Italic = require('./PublicSans_800ExtraBold_Italic.ttf');
export const PublicSans_900Black_Italic = require('./PublicSans_900Black_Italic.ttf');
