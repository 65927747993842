import { StyleSheet, ViewStyle } from 'react-native'
import { Theme } from '../../../themes/theme'

export const loadingStyle = (
  theme: Theme
): {
  background: ViewStyle
} =>
  StyleSheet.create({
    background: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.color.background
    }
  })
