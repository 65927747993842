import * as React from 'react'
import { Animated, Easing, Pressable, Text, View } from 'react-native'
import { THEME } from '../../../constants'
import { textStyle } from '../../../themes/global-styles.style'
import { isNonNullish } from 'global-utils'
import { pointsStyle, pointsToAddStyle } from './points.style'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import {
  ApolloClient,
  NormalizedCacheObject,
  gql,
  useApolloClient,
  useLazyQuery
} from '@apollo/client'
import { loadToken } from '../../auth/load-token'
import { Coordinates } from '../../../util/coordinates'
import { useIsMounted } from '../../../util/use-is-mounted'

// How many milleseconds are allotted for the full points to add animation. This
// variable is exported so external animations can assume that they can start
// more of their own animations after this amount of time.
export const POINTS_TO_ADD_ANIMATION_DURATION_MS = 4000

// This animation runs when a different component wants to add points to this module.
// The points being added are in a circle and bounce into the points total circle. To
// trigger that animation, the client sends a PointsToAddAnimation object in the
// props.pointsToAddAnimation field of this component.
export interface PointsToAddAnimation {
  // The number of points to add to the current points total. If this number is
  // negative, it will decrease the points total.
  points: number
  // The color in hex format for the bouncing points to add circle.
  color: string
  // The {x,y} coordinates of the starting position of the points to add circle.
  // Uses the standard screen coordinate system where the top left corner is {0,0}.
  startingCoordinates: Coordinates
}

// NOTE: We don't use optimistic response here because the MemberLatestFinancialViewQuery
// does not have all the fields of the MemberQuery, and it causes errors saying there are
// missing fields. More generally, any time our query shape is a subset of our update shape,
// we will have this problem, although that would be the ideal state management approach
// for query-based state.  If we continue to encounter it, it may be worth investing in a
// generic solution.  One possible idea may be to artificially narrow the declared updateMember
// data type, but we would need to verify that a) the error we encounter is a type error
// (not a runtime error) and b) ApolloCache correctly handled undefined fields (ie merges,
// not erases) Ideally we would use an optimistic response instead of state so that
// the database is the one source of truth for the value of points.
async function storePoints (
  client: ApolloClient<NormalizedCacheObject>,
  points: number
): Promise<void> {
  await client.mutate<
  WalkthroughGraphQL.UpdateMemberMutation,
  WalkthroughGraphQL.UpdateMemberMutationVariables
  >({
    mutation: gql(WalkthroughGraphQL.updateMember),
    variables: {
      input: {
        owner: await loadToken(),
        points: points
      }
    }
  })
}

export function Points (props: {
  pointsToAddAnimation?: PointsToAddAnimation | null
  setPointsToAddAnimation?: React.Dispatch<
  React.SetStateAction<PointsToAddAnimation | null>
  >
}): JSX.Element {
  // Whether to show the points info message.
  const [showPointsInfoMessage, setShowPointsInfoMessage] =
    React.useState(false)

  // Points are initialized to the value stored in the database, or 0 if no value is stored.
  // If the value of points changes, that value is then stored in the database.
  const [points, setPoints] = React.useState<number | null>(null)

  // Whether the points to add animation is eligible to run. Saving this state circumvents an
  // issue where the setPointsToAddAnimation hook (which causes state to change outside this
  // component and prompts a re-render) runs after re-renders within this component. To fix this,
  // eligibleToRunPointsToAddAnimation must be true for the animation to start. This stops an
  // infinite animation loop from occuring. This component is only eligible to run the points
  // animation if the animation has not run yet (set to true initially) or if
  // props.pointsToAddAnimation is reset to null. Otherwise the component cannot re-enter the
  // animation.
  const [
    eligibleToRunPointsToAddAnimation,
    setEligibleToRunPointsToAddAnimation
  ] = React.useState(true)

  // We use the top left of the points circle as the ending coordinates for points to add.
  // We save this value here since we need to use onLayout to get the coordinates on mount &
  // layout changes.
  const [endingCoordinatesPointsToAdd, setEndingCoordinatesPointsToAdd] =
    React.useState<Coordinates | null>(null)

  // Polling for this query is handled centrally by the app in order to not register repeated
  // polling requests.
  const [getFinancialView, { data }] = useLazyQuery<
  WalkthroughGraphQL.MemberLatestFinancialViewQuery,
  WalkthroughGraphQL.MemberLatestFinancialViewQueryVariables
  >(gql(WalkthroughGraphQL.memberLatestFinancialView))

  const isMounted = useIsMounted()

  React.useEffect(() => {
    // There are known race condititions with async code in useEffect (like what if the component unmounts before the
    // function returns?), but this is still the recommended approach. See
    // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function getOwnerAndQuery (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getFinancialView({ variables: { owner: owner } })
      }
    }
    getOwnerAndQuery().catch(console.log)
  }, [])

  // Reset eligibleToRunPointsToAddAnimation to true if the pointsToAddAnimation is reset to null.
  // At this point, a new animation can be triggered by setting the pointsToAddAnimation to be an
  // object.
  React.useEffect(() => {
    // Only set points here if we are initializing state.
    if (props.pointsToAddAnimation === null) {
      setEligibleToRunPointsToAddAnimation(true)
    }
  }, [props.pointsToAddAnimation])

  React.useEffect(() => {
    // Only set points here if we are initializing state.
    if (points === null && isNonNullish(data)) {
      setPoints(data.getMember?.points ?? 0)
    }
  }, [data])

  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>

  React.useEffect(() => {
    // When points update, store that value in the database.
    if (isNonNullish(points) && points !== data?.getMember?.points) {
      storePoints(apolloClient, points).catch(console.log)
    }
  }, [points])

  const addedPointsXTranslation = React.useRef(new Animated.Value(0)).current
  const addedPointsYTranslation = React.useRef(new Animated.Value(0)).current
  const pointsCircleColor = React.useRef(new Animated.Value(0)).current

  // When props.pointsToAddAnimation is not nullish (& the needed things
  // are not nullish) we start the points to add animation.
  React.useEffect(() => {
    if (
      eligibleToRunPointsToAddAnimation &&
      isNonNullish(props.pointsToAddAnimation) &&
      isNonNullish(points) &&
      isNonNullish(endingCoordinatesPointsToAdd)
    ) {
      const xDistanceForAddedPointsToMove =
        endingCoordinatesPointsToAdd.x -
        props.pointsToAddAnimation.startingCoordinates.x
      const yDistanceForAddedPointsToMove =
        endingCoordinatesPointsToAdd.y -
        props.pointsToAddAnimation.startingCoordinates.y
      Animated.sequence([
        // Translate the added points from the starting coordinates to the
        // ending coordinates, using a bounce animation.
        Animated.parallel([
          Animated.timing(addedPointsYTranslation, {
            toValue: yDistanceForAddedPointsToMove,
            // Translation gets 3/5 of the alloted time.
            duration: (POINTS_TO_ADD_ANIMATION_DURATION_MS / 5) * 3,
            easing: Easing.bounce,
            useNativeDriver: false
          }),
          Animated.timing(addedPointsXTranslation, {
            toValue: xDistanceForAddedPointsToMove,
            // Translation gets 3/5 of the alloted time.
            duration: (POINTS_TO_ADD_ANIMATION_DURATION_MS / 5) * 3,
            easing: Easing.linear,
            useNativeDriver: false
          })
        ]),
        // Change the points circle color to the points to add color.
        Animated.timing(pointsCircleColor, {
          toValue: 1,
          // 1/5 of the alloted time.
          duration: POINTS_TO_ADD_ANIMATION_DURATION_MS / 5,
          easing: Easing.inOut(Easing.ease),
          useNativeDriver: false
        })
      ]).start(() => {
        // This callback runs after the previous animations have finished.
        if (isNonNullish(props.pointsToAddAnimation)) {
          // IMPORTANT: After starting this animation in the sequence, this component is no longer
          // eligible to run the points to add animation again, until the pointsToAddAnimation
          // is set back to null.
          //
          // We need to use eligibleToRunPointsToAddAnimation to stop this useEffect logic
          // from running again after the setPoints call right below this. setPoints
          // updates the points value, causing this useEffect to rerun before the
          // pointsToAddAnimation has been set to null.
          setEligibleToRunPointsToAddAnimation(false)
          // Setting the points updates the rendered points & triggers a call for
          // points to be stored.
          setPoints(points + props.pointsToAddAnimation.points)
          // Change the points circle color back to the original dark blue.
          Animated.sequence([
            Animated.timing(pointsCircleColor, {
              toValue: 0,
              // 1/5 of the alloted time.
              duration: POINTS_TO_ADD_ANIMATION_DURATION_MS / 5,
              easing: Easing.inOut(Easing.ease),
              useNativeDriver: false
            })
          ]).start(() => {
            if (isNonNullish(props.setPointsToAddAnimation)) {
              props.setPointsToAddAnimation(null)
              // Reset animated values to zero so they are ready to run again.
              addedPointsXTranslation.setValue(0)
              addedPointsYTranslation.setValue(0)
            }
          })
        }
      })
    }
  }, [
    props.pointsToAddAnimation,
    points,
    endingCoordinatesPointsToAdd,
    eligibleToRunPointsToAddAnimation
  ])

  // Interpolation used to change the points circle color from dark blue to the points
  // to add color, and back again.
  const animatedPointsCircleColor = pointsCircleColor.interpolate({
    inputRange: [0, 1],
    outputRange: [
      THEME.color.rainbow.darkBlue,
      props.pointsToAddAnimation?.color ?? THEME.color.rainbow.darkBlue
    ]
  })

  return (
    <View style={pointsStyle.pointsView} pointerEvents="box-none">
      {isNonNullish(props.pointsToAddAnimation) &&
      isNonNullish(props.pointsToAddAnimation.startingCoordinates) ? (
        <Animated.View
          style={[
            pointsToAddStyle(
              props.pointsToAddAnimation.startingCoordinates.x,
              props.pointsToAddAnimation.startingCoordinates.y
            ).pointsToAdd,
            {
              transform: [
                { translateY: addedPointsYTranslation },
                { translateX: addedPointsXTranslation },
                // Without perspective this Animation will not render on Android.
                // See https://reactnative.dev/docs/animations#bear-in-mind
                { perspective: 1000 }
              ]
            },
            {
              backgroundColor:
                props.pointsToAddAnimation?.color ??
                THEME.color.rainbow.darkBlue
            }
          ]}
        >
          <Text
            style={[
              textStyle.extraLargeText,
              textStyle.boldText,
              textStyle.outlineColorText
            ]}
          >
            {props.pointsToAddAnimation?.points}
          </Text>
        </Animated.View>
          ) : null}
      <Animated.View
        style={[
          pointsStyle.pointsCircle,
          {
            backgroundColor:
              animatedPointsCircleColor ?? THEME.color.rainbow.darkBlue
          }
        ]}
        onLayout={(event) => {
          setEndingCoordinatesPointsToAdd({
            // X coordinate is on the left of the points circle.
            x: event.nativeEvent.layout.x,
            // Y coordinate is on the top of the points circle.
            y: event.nativeEvent.layout.y
          })
        }}
      >
        <Pressable
          style={pointsStyle.pointsPressable}
          onPress={() => setShowPointsInfoMessage(!showPointsInfoMessage)}
        >
          <Text
            style={[
              textStyle.extraLargeText,
              textStyle.boldText,
              textStyle.outlineColorText
            ]}
          >
            {points ?? '—'}
          </Text>
        </Pressable>
      </Animated.View>
      {showPointsInfoMessage ? (
        <View style={pointsStyle.pointsInfoMessage}>
          <Text style={[textStyle.regularText]}>
            Yay you got points! You can't use them quite yet 🥺 (but soon! 😍)
          </Text>
        </View>
      ) : null}
    </View>
  )
}
