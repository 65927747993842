import { StyleSheet, ViewStyle } from 'react-native'
import { Theme } from '../../../themes/theme'

export const taskScreenStyle = (
  theme: Theme
): {
  tasksScreen: ViewStyle
  jungleBackground: ViewStyle
} =>
  StyleSheet.create({
    tasksScreen: {
      backgroundColor: theme.color.background,
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      paddingHorizontal: theme.spacing.horizontalSpaceMedium
    },
    jungleBackground: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: 0,
      zIndex: -3
    }
  })
