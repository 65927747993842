import { StyleSheet } from 'react-native'
import { THEME } from '../../../constants'

export const bannersStyle = StyleSheet.create({
  welcomeBanner: {
    backgroundColor: THEME.color.superHighlight,
    flexDirection: 'row',
    padding: THEME.spacing.horizontalSpaceMedium,
    borderTopWidth: 2,
    borderColor: THEME.color.outline
  },
  authRefreshWarningBox: {
    backgroundColor: THEME.color.superHighlight,
    flexDirection: 'row',
    padding: THEME.spacing.horizontalSpaceMedium
  },
  accountAlertWarningBox: {
    backgroundColor: THEME.color.superHighlight,
    flexDirection: 'row',
    padding: THEME.spacing.horizontalSpaceMedium,
    borderBottomWidth: 2,
    borderColor: THEME.color.outline
  },
  warningText: {
    paddingRight: THEME.spacing.horizontalSpaceMedium,
    color: THEME.color.onSurface,
    fontFamily: 'PublicSans_700Bold',
    fontSize: THEME.font.fontSizeSmall,
    alignSelf: 'center'
  },
  border: {
    backgroundColor: THEME.color.outline,
    height: 2
  }
})
