import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const Close = (props: SvgProps) => (
<Svg
width={18}
height={18}
fill="none"
{...props}
>
<Path
d="m13 5-8 8m0-8 8 8"
stroke="#fff"
strokeWidth={2}
strokeLinecap="round"
/>
</Svg>
)
export default Close
