import * as React from "react"
import Svg, { SvgProps, Rect, Path, Ellipse, Circle } from "react-native-svg"

const YuyuPiggyBankGraph = (props: SvgProps): JSX.Element => (
  <Svg
    width={161}
    height={169}
    fill="none"
    {...props}
  >
    <Rect
      x={1}
      y={1}
      width={115.193}
      height={86.476}
      rx={9}
      fill="#C3EC81"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M12.533 74.16a1.5 1.5 0 0 0 2.227 2.01l-2.227-2.01Zm18.773-18.569.578-1.384-.98-.41-.712.79 1.114 1.004Zm11.237 4.698-.578 1.384.724.303.662-.423-.808-1.264Zm23.278-14.877 1.007-1.111-.849-.77-.966.617.808 1.264ZM77.06 55.591l-1.007 1.112 1.622 1.469.785-2.043-1.4-.538Zm9.632-25.055-.966-1.147-.295.248-.139.36 1.4.539Zm19.154-14.749a1.5 1.5 0 0 0-1.367-1.622l-13.45-1.15a1.5 1.5 0 1 0-.256 2.989l11.956 1.022-1.023 11.957a1.501 1.501 0 0 0 2.989.255l1.151-13.45ZM14.76 76.17l17.66-19.574-2.228-2.01-17.66 19.575 2.228 2.01Zm15.967-19.195 11.238 4.698 1.157-2.768-11.238-4.698-1.157 2.768Zm12.624 4.578 23.278-14.877-1.616-2.528-23.278 14.877 1.616 2.528Zm21.463-15.029 11.238 10.179 2.014-2.224-11.238-10.178-2.014 2.223ZM78.46 56.13l9.632-25.055-2.8-1.076-9.632 25.055 2.8 1.076Zm9.199-24.446 17.659-14.876-1.933-2.295-17.66 14.877 1.934 2.294Z"
      fill="#009898"
    />
    <Path
      d="M59.27 141.631h-.894c-5.068.001-9.849.002-14.213-.331-4.621-.353-8.698-1.077-12.08-2.53-3.36-1.443-6.035-3.604-7.885-6.866-1.862-3.281-2.936-7.755-2.936-13.891 0-12.241 4.27-21.404 11.093-27.516 6.838-6.126 16.335-9.27 26.915-9.27 10.58 0 20.077 3.144 26.916 9.27 6.822 6.112 11.092 15.275 11.092 27.516 0 6.136-1.073 10.61-2.935 13.891-1.85 3.262-4.525 5.423-7.885 6.866-3.382 1.453-7.459 2.177-12.08 2.53-4.365.333-9.145.332-14.213.331h-.895Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={46.65} cy={123.166} rx={2.295} ry={2.29} fill="#505050" />
    <Ellipse cx={73.039} cy={123.166} rx={2.295} ry={2.29} fill="#505050" />
    <Path
      d="M55.83 127.746c.934.864 3.62 2.073 6.883 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M64.433 90.532c-5.507 5.038-10.708 2.1-12.62 0-5.048-4.122 1.338-24.237 5.163-33.779 4.78 9.16 12.964 28.74 7.457 33.779ZM75.276 89.262c-2.632 1.21-4.204-.492-4.66-1.493-1.375-2.12 3.373-8.792 5.919-11.864.677 3.949 1.373 12.148-1.26 13.357ZM41.015 89.262c2.633 1.21 4.204-.492 4.66-1.493 1.375-2.12-3.373-8.792-5.919-11.864-.677 3.949-1.373 12.148 1.26 13.357Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Circle
      cx={44.454}
      cy={123.279}
      r={5.757}
      stroke="#505050"
      strokeWidth={3}
    />
    <Circle
      cx={74.693}
      cy={123.279}
      r={5.757}
      stroke="#505050"
      strokeWidth={3}
    />
    <Path d="M50.804 123.279H68.04" stroke="#505050" strokeWidth={3} />
    <Rect
      x={98.042}
      y={153.651}
      width={7.676}
      height={10.7}
      rx={3.838}
      transform="rotate(32.96 98.042 153.651)"
      fill="#FFF2F8"
    />
    <Rect
      x={98.042}
      y={153.651}
      width={7.676}
      height={10.7}
      rx={3.838}
      transform="rotate(32.96 98.042 153.651)"
      fill="#FFF2F8"
    />
    <Rect
      x={98.042}
      y={153.651}
      width={7.676}
      height={10.7}
      rx={3.838}
      transform="rotate(32.96 98.042 153.651)"
      fill="#FFF2F8"
    />
    <Rect
      x={98.042}
      y={153.651}
      width={7.676}
      height={10.7}
      rx={3.838}
      transform="rotate(32.96 98.042 153.651)"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-0.295}
      y={1.383}
      width={7.676}
      height={10.7}
      rx={3.838}
      transform="scale(-1 1) rotate(32.96 -326.787 -156.196)"
      fill="#FFF2F8"
    />
    <Rect
      x={-0.295}
      y={1.383}
      width={7.676}
      height={10.7}
      rx={3.838}
      transform="scale(-1 1) rotate(32.96 -326.787 -156.196)"
      fill="#FFF2F8"
    />
    <Rect
      x={-0.295}
      y={1.383}
      width={7.676}
      height={10.7}
      rx={3.838}
      transform="scale(-1 1) rotate(32.96 -326.787 -156.196)"
      fill="#FFF2F8"
    />
    <Rect
      x={-0.295}
      y={1.383}
      width={7.676}
      height={10.7}
      rx={3.838}
      transform="scale(-1 1) rotate(32.96 -326.787 -156.196)"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.051 127.317a1.21 1.21 0 0 0-1.57-.679 3.765 3.765 0 0 1-1.66.257c.217-.55.388-1.108.503-1.653.286-1.347.306-2.972-.596-4.099-1.039-1.299-2.752-1.356-4.575-.679-.65.241-1.184.646-1.535 1.212-.345.558-.455 1.182-.423 1.772.064 1.146.664 2.306 1.469 3.243a8.018 8.018 0 0 0 1.682 1.469c-.685.867-1.404 1.359-2.011 1.475-.728.139-1.36-.055-1.983-.543-.657-.515-1.271-1.34-1.828-2.365-.89-1.638-1.498-3.501-1.943-4.867-.118-.36-.224-.685-.321-.962a1.21 1.21 0 1 0-2.283.798c.074.214.163.487.266.804.44 1.354 1.144 3.522 2.156 5.382.629 1.158 1.432 2.308 2.461 3.115 1.064.833 2.389 1.308 3.929 1.014 1.509-.288 2.755-1.396 3.645-2.599a9.29 9.29 0 0 0 .184-.258 6.14 6.14 0 0 0 3.754-.267 1.21 1.21 0 0 0 .679-1.57Zm-5.502-1.256a5.57 5.57 0 0 1-1.05-.947c-.594-.69-.864-1.361-.888-1.8-.011-.202.03-.31.064-.365.029-.047.102-.137.319-.217 1.45-.538 1.786-.152 1.845-.078.197.247.365.926.119 2.086-.091.429-.23.877-.409 1.321Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M86.06 118.138c-.483 4.838 2.016 5.745 4.536 6.048 0 0 2.153-4.763 3.93-7.258 1.794-2.517 5.746-5.745 5.746-5.745-2.318-2.419-4.959-3.266-6.652-3.024-2.117.302-6.955 3.931-7.56 9.979Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
    />
    <Circle
      cx={118.657}
      cy={133.52}
      r={30.448}
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M134.082 156.827c-1.161-1.281-.907-2.261-.665-2.372.238-.055 1.437.31 1.851 1.716.431 1.463 2.671.633 3.318.271 2.001-1.12 3.093-4.467.494-7.997-2.036-2.766-7.175-4.982-12.966-3.62-2.747 4.733-2.501 10.108-.144 13.748 1.263 1.951 2.494 2.856 5.028 2.69 2.244-.146 4.532-2.84 3.084-4.436Z"
      fill="#53D28B"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M113.445 155.11c1.65-.515 2.433.128 2.432.394-.049.238-.885 1.173-2.335.958-1.509-.224-1.697 2.158-1.64 2.896.175 2.287 2.753 4.685 7.049 3.81 3.365-.685 7.536-4.417 8.734-10.244-3.14-4.481-8.12-6.517-12.414-5.909-2.301.326-3.64 1.063-4.555 3.432-.809 2.097.673 5.306 2.729 4.663Z"
      fill="#53D28B"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M113.948 150.319c-1.192 1.252-2.189 1.07-2.317.837-.073-.233.205-1.456 1.577-1.972 1.427-.536.435-2.709.027-3.328-1.263-1.914-4.681-2.759-8.012.091-2.609 2.233-4.444 7.52-2.664 13.196 4.922 2.393 10.264 1.756 13.723-.86 1.853-1.402 2.666-2.696 2.315-5.211-.31-2.227-3.163-4.313-4.649-2.753ZM130.734 141.664c-1.192 1.251-2.188 1.069-2.317.836-.072-.233.205-1.455 1.577-1.971 1.427-.537.435-2.71.027-3.329-1.262-1.914-4.68-2.759-8.011.091-2.61 2.233-4.445 7.52-2.664 13.196 4.921 2.393 10.263 1.756 13.722-.86 1.853-1.402 2.666-2.696 2.316-5.211-.31-2.227-3.164-4.312-4.65-2.752ZM122.267 101.144c-1.192 1.251-2.188 1.069-2.316.836-.073-.233.204-1.455 1.576-1.971 1.427-.537.436-2.71.028-3.329-1.263-1.914-4.681-2.759-8.012.091-2.61 2.233-4.445 7.52-2.664 13.196 4.921 2.393 10.263 1.756 13.722-.86 1.854-1.402 2.666-2.696 2.316-5.211-.31-2.227-3.164-4.312-4.65-2.752Z"
      fill="#53D28B"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M137.699 147.537c-.619-1.614-.027-2.436.239-2.451.241.033 1.227.809 1.103 2.27-.128 1.519 2.261 1.556 2.995 1.453 2.27-.318 4.501-3.042 3.357-7.274-.895-3.315-4.882-7.243-10.773-8.072-4.275 3.417-5.993 8.515-5.115 12.762.47 2.276 1.29 3.565 3.711 4.329 2.145.676 5.253-1.006 4.483-3.017ZM101.024 150.686c-.421-1.677.266-2.422.531-2.405.235.062 1.121.95.824 2.386-.309 1.493 2.057 1.816 2.798 1.802 2.293-.045 4.834-2.482 4.205-6.821-.492-3.398-3.98-7.775-9.73-9.303-4.653 2.88-6.97 7.735-6.606 12.057.194 2.316.854 3.694 3.167 4.743 2.047.928 5.335-.37 4.811-2.459Z"
      fill="#53D28B"
      stroke="#505050"
      strokeWidth={2}
    />
    <Circle
      cx={118.657}
      cy={133.52}
      r={30.448}
      stroke="#505050"
      strokeWidth={2}
    />
    <Circle
      cx={118.657}
      cy={133.52}
      r={30.448}
      fill="#FFF2F8"
      fillOpacity={0.8}
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={104.018} cy={118.272} rx={2.295} ry={2.29} fill="#505050" />
    <Path
      d="M108.918 111.083c1.078-1.403 3.879-2.935 6.326-2.606 1.969.265.741 6.98-.6 7.632-1.877.913-6.963-3.414-5.726-5.026Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
)

export default YuyuPiggyBankGraph
