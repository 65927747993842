import * as React from 'react'
import { Anchor } from '../../common/links/anchor'
import {
  TaskStepsData,
  TaskStepsRenderingStyle
} from '../../tasks/task-flow-data'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'

export const noKickbacks: TaskStepsData = {
  stepRenderingStyle: TaskStepsRenderingStyle.CLICK_THROUGH_MODULE,
  title: 'No Kickbacks',
  description: '',
  subtasks: [
    {
      key: 'STATEMENT',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          Walkthrough never gets paid for recommending a product or financial
          action, and this will never change.{'\n\n'}Anyone who gets paid like
          this is no longer acting solely in your interest, and you shouldn't
          take financial advice from them.
        </>
      )
    },
    {
      key: 'LINKS',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          If you want to understand more about how important unbiased advice is
          to us, look at our mission.{'\n\n'}
          If you think mission statements are a bunch of corporate hooey, look
          at our{' '}
          <Anchor
            text="incentives"
            href="https://www.walkthrough.co/incentives"
          />
          .
        </>
      )
    }
  ],
  button: {
    buttonText: 'Done',
    handleOnPressButton: (props: {
      navigation: GenericCompositeNavigationProp
    }) => {
      props.navigation.goBack()
    }
  }
}
