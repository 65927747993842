"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.provideAiAdvisorFeedback = void 0;
// Updates a record in the AiAdvisorRecord table and returns only fields that are viewable by the client.
// Codegen'ed updateAiAdvisorRecord returns too many fields -> we get unauthorized errors.
exports.provideAiAdvisorFeedback = `
  mutation ProvideAiAdvisorFeedback(
    $input: UpdateAiAdvisorRecordInput!
    $condition: ModelAiAdvisorRecordConditionInput
  ) {
    updateAiAdvisorRecord(input: $input, condition: $condition) {
      id
      userFeedback
    }
  }
`;
