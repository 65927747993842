import * as React from 'react'
import { Text, TextStyle, Platform } from 'react-native'
import * as Linking from 'expo-linking'

// An inline anchor that creates a hyperlink to web pages, email addresses etc. See
// https://docs.expo.dev/guides/linking/ for href formatting.
export function Anchor (props: {
  // Text styles nest, so only specify extra styles you want to add to indicate this is an anchor.  By default,
  // we just underline the text.
  style: TextStyle[]
  href: string
  text: string
}): JSX.Element {
  function handlePress (): void {
    if (Platform.OS === 'web') {
      window.open(props.href, '_blank')
    } else {
      Linking.openURL(props.href).catch(console.log)
    }
  }
  return (
    <Text style={props.style} onPress={handlePress}>
      {props.text}
    </Text>
  )
}

Anchor.defaultProps = {
  style: { textDecorationLine: 'underline' }
}
