import { InputAccountTypesMemberLatestFinancialViewQuery } from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { AnswerType, QuestionAndAnswers } from '../question-data'
import { gql } from '@apollo/client'
import { stringInStringEnum, isNonNullish } from 'global-utils'
import { YesOrNo, yesOrNoToDisplayText } from '../display-values/yes-or-no'
import { accountNameWithBalanceFallback } from '../../../../../util/account-parsing'

export const roth401kEligibilityQuestion: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
) => QuestionAndAnswers = (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
) => {
  return {
    key: 'ROTH_401K_ELIGIBILITY_QUESTION',
    question: {
      text: (
        <>
          Does your employer allow you to make *Roth* contributions to your{' '}
          {accountNameWithBalanceFallback(account)} 401k? If you don't work for
          this employer anymore, answer 'No'.
        </>
      )
    },
    answers: [
      {
        key: 'ROTH_401K_ELIGIBILITY_ANSWER',
        getInitialAnswer: async () => {
          const eligibleForRoth401KContributions =
            account.isEligibleForRoth401KContributions
          if (!isNonNullish(eligibleForRoth401KContributions)) {
            return ''
          }
          return eligibleForRoth401KContributions ? YesOrNo.YES : YesOrNo.NO
        },
        storeValue: async (client, value) => {
          if (!stringInStringEnum(YesOrNo, value)) {
            console.log("Provided value ('" + value + "') is not Yes or No.")
            return
          }
          await client.mutate<
          WalkthroughGraphQL.UpdateK401AccountMutation,
          WalkthroughGraphQL.UpdateK401AccountMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updateK401Account),
            variables: {
              input: {
                accountId: account.accountId,
                isEligibleForRoth401KContributions: value === YesOrNo.YES
              }
            }
          })
        },
        validation: {
          isValid: (answer: string) => stringInStringEnum(YesOrNo, answer),
          notValidInfoText: 'Answer must be Yes or No.'
        },
        answerType: AnswerType.SELECTION,
        selectionAnswer: {
          items: Array.from(yesOrNoToDisplayText).map(([key, value]) => {
            return { enumValue: key, value, displayText: value }
          })
        }
      }
    ]
  }
}
