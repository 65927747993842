import * as React from 'react'
import { View } from 'react-native'
import { THEME } from '../../../constants'
import { loadingStyle } from './loading.style'

// Adding a simple loading screen for now - just a full width/height component with the same
// background color as the app. This makes the loading moment less jarring. Things load
// quickly enough that it's not necessary to add "loading..." text or a loading
// animation.
export function Loading (): JSX.Element {
  const style = loadingStyle(THEME)

  return <View style={style.background} />
}
