import { InputAccountTypesMemberLatestFinancialViewQuery } from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import {
  AnswerType,
  ConditionType,
  QuestionAndAnswers
} from '../question-data'
import { gql } from '@apollo/client'
import {
  getFractionFromPercentString,
  getNumberFromDollarString,
  isWholeDollarString,
  isPercentString
} from '../../../../../util/input-validators'
import { stringInStringEnum, isNonNullish } from 'global-utils'
import { YesOrNo, yesOrNoToDisplayText } from '../display-values/yes-or-no'
import { accountNameWithBalanceFallback } from '../../../../../util/account-parsing'

export const employerMatch401kQuestion: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
) => QuestionAndAnswers[] = (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
) => {
  // Use promises to handle answers from multiple questions and store them all at once.
  let percentResolutionOne: (percent: number) => void
  let percentRejectionOne: (reason: Error) => void
  const percentOne = new Promise<number>((resolve, reject) => {
    percentResolutionOne = resolve
    percentRejectionOne = reject
  })

  let hasSecondMatchRuleResolution: (hasSecondMatchRule: boolean) => void
  let hasSecondMatchRuleRejection: (reason: Error) => void
  const hasSecondMatchRule = new Promise<boolean>((resolve, reject) => {
    hasSecondMatchRuleResolution = resolve
    hasSecondMatchRuleRejection = reject
  })

  let percentResolutionTwo: (percent: number) => void
  let percentRejectionTwo: (reason: Error) => void
  const percentTwo = new Promise<number>((resolve, reject) => {
    percentResolutionTwo = resolve
    percentRejectionTwo = reject
  })

  let matchResolutionTwo: (match: number) => void
  let matchRejectionTwo: (reason: Error) => void
  const matchTwo = new Promise<number>((resolve, reject) => {
    matchResolutionTwo = resolve
    matchRejectionTwo = reject
  })

  return [
    {
      key: 'K401_MATCH_QUESTION_ONE',
      question: {
        text: (
          <>
            What's the employer match for your{' '}
            {accountNameWithBalanceFallback(account)} 401k?{'\n\n'}
            If you no longer work at your employer, or your employer doesn't
            offer a 401k match, enter 0 in both boxes.{'\n\n'}Otherwise, enter
            your employer's match rules below. For example, if your employer
            matches 50% up to $5,000 in total match money ($10,000 of your
            contributions), enter 50 in the % box and 5000 in the $ box. If
            there are two match rules, answer 'Yes' to the question below and
            add another rule.
          </>
        )
      },
      answers: [
        {
          key: 'K401_MATCH_PERCENT_ANSWER',
          getInitialAnswer: async () => {
            const matchRules = account.employerMatchRules
            if (isNonNullish(matchRules) && matchRules.length > 0) {
              return (
                matchRules[0]?.fractionMatch?.toLocaleString('en-US', {
                  style: 'percent'
                }) ?? ''
              )
            }
            return ''
          },
          storeValue: async (client, value) => {
            if (!isPercentString(value)) {
              percentRejectionOne(
                new Error(
                  "Provided value ('" +
                    value +
                    "') is not a number between 0 and 100."
                )
              )
              return
            }
            percentResolutionOne(getFractionFromPercentString(value))
          },
          validation: {
            isValid: (answer: string) => {
              return isPercentString(answer)
            },
            notValidInfoText:
              'Match percent must be a decimal number between 0 and 100.'
          },
          answerType: AnswerType.TEXT,
          textAnswer: {
            placeholderText: 'X.XX%'
          }
        },
        {
          key: 'K401_MATCH_MAX_ANSWER',
          getInitialAnswer: async () => {
            const matchRules = account.employerMatchRules
            if (
              isNonNullish(matchRules) &&
              matchRules.length > 0 &&
              isNonNullish(matchRules[0])
            ) {
              return (
                matchRules[0].maxEmployerMatch
                  ?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                  .replace(/,/g, '') ?? ''
              )
            }
            return ''
          },
          // This store value function awaits the other answers and stores all the values at once. Saving
          // these match rules overwrites all previously saved match rules.
          storeValue: async (client, value) => {
            if (!isWholeDollarString(value)) {
              console.log(
                "Provided value ('" +
                  value +
                  "') is not an amount in dollars > 0."
              )
              return
            }

            let percentOneValue
            // If promise is rejected, return early & do not store match rules.
            try {
              percentOneValue = await percentOne
            } catch (error) {
              console.log(error)
              return
            }
            const matchRulesInput: WalkthroughGraphQL.EmployerMatchRuleInput[] =
              [
                {
                  fractionMatch: percentOneValue,
                  maxEmployerMatch: getNumberFromDollarString(value)
                }
              ]

            // If any of the promises are rejected, return early & do not store match rules.
            try {
              const hasSecondMatchRuleValue = await hasSecondMatchRule
              if (hasSecondMatchRuleValue) {
                const percentTwoValue = await percentTwo
                const matchTwoValue = await matchTwo
                matchRulesInput.push({
                  fractionMatch: percentTwoValue,
                  maxEmployerMatch: matchTwoValue
                })
              }
            } catch (error) {
              console.log(error)
              return
            }

            await client.mutate<
            WalkthroughGraphQL.UpdateK401AccountMutation,
            WalkthroughGraphQL.UpdateK401AccountMutationVariables
            >({
              mutation: gql(WalkthroughGraphQL.updateK401Account),
              variables: {
                input: {
                  accountId: account.accountId,
                  employerMatchRules: matchRulesInput
                }
              }
            })
          },
          validation: {
            isValid: (answer: string) => {
              return isWholeDollarString(answer)
            },
            notValidInfoText: 'Maximum match must be a whole dollar amount > 0.'
          },
          answerType: AnswerType.TEXT,
          textAnswer: {
            placeholderText: '$XXXX'
          }
        }
      ]
    },
    {
      key: 'HAS_SECOND_K401_MATCH_QUESTION',
      question: {
        text: 'Does your employer have another matching rule?'
      },
      answers: [
        {
          key: 'HAS_SECOND_K401_MATCH_ANSWER',
          getInitialAnswer: async () => {
            const employerMatchRulesLength = account.employerMatchRules?.length
            if (!isNonNullish(employerMatchRulesLength)) {
              return ''
            }
            return employerMatchRulesLength > 1 ? YesOrNo.YES : YesOrNo.NO
          },
          storeValue: async (client, value) => {
            if (!stringInStringEnum(YesOrNo, value)) {
              hasSecondMatchRuleRejection(
                new Error("Provided value ('" + value + "') is not Yes or No.")
              )
              return
            }
            hasSecondMatchRuleResolution(value === YesOrNo.YES)
          },
          validation: {
            isValid: (answer: string) => stringInStringEnum(YesOrNo, answer),
            notValidInfoText: 'Answer must be Yes or No.'
          },
          answerType: AnswerType.SELECTION,
          selectionAnswer: {
            items: Array.from(yesOrNoToDisplayText).map(([key, value]) => {
              return { enumValue: key, value, displayText: value }
            })
          }
        }
      ]
    },
    {
      key: 'K401_MATCH_QUESTION_TWO',
      question: {
        text: "Enter your employer's second match rule below."
      },
      answers: [
        {
          key: 'K401_MATCH_PERCENT_ANSWER',
          getInitialAnswer: async () => {
            const matchRules = account.employerMatchRules
            if (isNonNullish(matchRules) && matchRules.length > 1) {
              return (
                matchRules[1]?.fractionMatch?.toLocaleString('en-US', {
                  style: 'percent'
                }) ?? ''
              )
            }
            return ''
          },
          storeValue: async (client, value) => {
            if (!isPercentString(value)) {
              percentRejectionTwo(
                new Error(
                  "Provided value ('" +
                    value +
                    "') is not a number between 0 and 100."
                )
              )
              return
            }
            percentResolutionTwo(getFractionFromPercentString(value))
          },
          validation: {
            isValid: (answer: string) => {
              return isPercentString(answer)
            },
            notValidInfoText:
              'Match percent must be a decimal number between 0 and 100.'
          },
          answerType: AnswerType.TEXT,
          textAnswer: {
            placeholderText: 'X.XX%'
          }
        },
        {
          key: 'K401_MATCH_MAX_ANSWER',
          getInitialAnswer: async () => {
            const matchRules = account.employerMatchRules
            if (
              isNonNullish(matchRules) &&
              matchRules.length > 1 &&
              isNonNullish(matchRules[1])
            ) {
              return (
                matchRules[1].maxEmployerMatch
                  ?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                  .replace(/,/g, '') ?? ''
              )
            }
            return ''
          },
          storeValue: async (client, value) => {
            if (!isWholeDollarString(value)) {
              matchRejectionTwo(
                new Error(
                  "Provided value ('" +
                    value +
                    "') is not an amount in dollars > 0."
                )
              )
              return
            }
            matchResolutionTwo(getNumberFromDollarString(value))
          },
          validation: {
            isValid: (answer: string) => {
              return isWholeDollarString(answer)
            },
            notValidInfoText: 'Maximum match must be a whole dollar amount > 0.'
          },
          answerType: AnswerType.TEXT,
          textAnswer: {
            placeholderText: '$XXXX'
          }
        }
      ],
      condition: {
        conditionType: ConditionType.MATCH_ANSWER,
        matchAnswerCondition: {
          answerKeyToMatch: 'HAS_SECOND_K401_MATCH_ANSWER',
          answersToMatch: [YesOrNo.YES]
        }
      }
    }
  ]
}
