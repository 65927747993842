import React from 'react'
import { Text } from 'react-native'
import EggStart from '../../../assets/line-drawings/egg-start'
import ExponentialGrowth from '../../../assets/line-drawings/exponential-growth'
import Guitar from '../../../assets/line-drawings/guitar'
import Heart from '../../../assets/line-drawings/heart'
import HotAirBalloon from '../../../assets/line-drawings/hot-air-balloon'
import Mountain from '../../../assets/line-drawings/mountain'
import Sailboat from '../../../assets/line-drawings/sailboat'

export interface WelcomeContent {
  // Content for one screen in the form of a react node - so that we can add inline styling
  // and links.
  content: React.ReactNode
  // Illustration to render with this content.
  illustration: React.ReactNode
}

export const welcomeContent: WelcomeContent[] = [
  {
    content: (
      <>
        Welcome to Walkthrough! We're excited to be starting this journey with
        you!{'\n\n'}
        Really though!{'\n\n'}
        Hi.
      </>
    ),
    illustration: <EggStart />
  },
  {
    content: (
      <>
        Walkthrough analyzes your finances and helps you figure out the next
        thing to do to optimize your long-term financial success.{'\n\n'}
        (and we make it fun! 🥳){'\n\n'}
        We teach you everything you need to know, step-by-step, along the way.
        Personal finance is more straightforward than you might think!
      </>
    ),
    illustration: <ExponentialGrowth />
  },
  {
    content: (
      <>
        At Walkthrough, we care about long-term wealth and stability. Really, we
        want to help you achieve{'\n\n'}
        <Text style={{ fontFamily: 'PublicSans_700Bold' }}>
          *financial freedom*
        </Text>
        {'\n\n'}
        (cue inspirational music){'\n\n'}
        This is the point when you no longer have to work to have enough money
        to live the rest of your life. One way or another, almost all financial
        goals boil back down to this.
      </>
    ),
    illustration: <HotAirBalloon />
  },
  {
    content: (
      <>
        Financial freedom doesn't mean retirement. It means freedom to choose
        what's next.{'\n\n'}
        Go travel for a year. Have a second career in something that pays less
        without worrying about the money. Keep your current job because it's
        meaningful and doing good things in the world. Learn to play the guitar.
        Volunteer … we can keep going.{'\n\n'}
        The point is,{' '}
        <Text style={{ fontFamily: 'PublicSans_700Bold' }}>
          you're free to do what you want.
        </Text>
      </>
    ),
    illustration: <Guitar />
  },
  {
    content: (
      <>
        We know that money can be scary. It can carry weighty emotions like
        anxiety and guilt and shame and envy.{'\n\n'}
        This is normal, and it's not your fault. It's the world we're in and
        what we grew up around.{'\n\n'}
        We're here with you on this part of the journey too.
      </>
    ),
    illustration: <Heart />
  },
  {
    content: (
      <>
        To help you work toward your financial goals, we have you tell us some
        basic information about you and have you link your accounts into the
        app. Walkthrough NEVER stores your bank account credentials. We'll tell
        you more about all this when we get there.{'\n\n'}
        Then, we help you identify savvy financial goals to pursue, teach you
        about them, and help you through the whole journey. You'll understand
        what you're doing and why it matters.
      </>
    ),
    illustration: <Mountain />
  },
  {
    content: (
      <>
        This is more than financial advice.{'\n\n'}
        It's empowerment. It's a friend on the journey. It's something new.
        {'\n\n'}
        We believe in you. Let's go 😊
      </>
    ),
    illustration: <Sailboat />
  }
]
