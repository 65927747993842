import {
  validateAccountForHandler,
  InputAccountTypesMemberLatestFinancialViewQuery
} from 'multi-type-processor'
import { isNonNullish } from 'global-utils'
import {
  accountBalanceRow,
  AccountRowConverter
} from './get-account-collections'
import { preTaxTraditional401kEligibilityQuestion } from '../../common/questions/content/pre-tax-traditional-401k-eligibility'
import { roth401kEligibilityQuestion } from '../../common/questions/content/roth-401k-eligibility'
import {
  yesOrNoToDisplayText,
  YesOrNo
} from '../../common/questions/display-values/yes-or-no'
import { afterTaxTraditional401kEligibilityQuestion } from '../../common/questions/content/after-tax-traditional-401k-eligibility'
import {
  getEmployerMatchShortString,
  hasEmployerMatch,
  transactionAggregationTypeToDisplayText
} from '../../../../util/account-parsing'
import { employerMatch401kQuestion } from '../../common/questions/content/employer-match-401k'
import { AccountQuestionHooks } from '../accounts-screen'
import { AccountSettingData, TransactionAgregationData } from '../account-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { k401ContributionQuestions } from '../../common/questions/content/k401-transaction-aggregations'
import { kIncompleteString } from '../../common/questions/display-values/incomplete-message'

// Get const string values for "yes and "no" here so we don't have to use string | undefined
// as the settings value. These strings should never be empty.
const yes = yesOrNoToDisplayText.get(YesOrNo.YES) ?? ''
const no = yesOrNoToDisplayText.get(YesOrNo.NO) ?? ''

function yesOrNoOrIncomplete (
  responseIsYes: boolean | null | undefined
): string {
  if (!isNonNullish(responseIsYes)) {
    return kIncompleteString
  }
  return responseIsYes ? yes : no
}

function contributionYearFromTransactionAggregation (
  aggregationType: WalkthroughGraphQL.TransactionAggregationType
): number {
  // N.B. : there's nothing saying that all transaction aggregation types must be mappable to a year. It so happens
  // this is the case, so we don't add a default statement throwing an error for now.
  // Regardless of the range of TransactionAggregationType's, it seems likely that 401k-associated transaction
  // aggregation types *WILL* forever have a contribution year, so this function should always receive valid input, even
  // if future transaction aggregation types don't have a relevant contribution year.
  switch (aggregationType) {
    case WalkthroughGraphQL.TransactionAggregationType
      .ROTH_401K_CONTRIBUTIONS_2022:
    case WalkthroughGraphQL.TransactionAggregationType
      .ROTH_IRA_CONTRIBUTIONS_2022:
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_401K_POST_TAX_CONTRIBUTIONS_2022:
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2022:
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_IRA_PRETAX_CONTRIBUTIONS_2022:
      return 2022
    case WalkthroughGraphQL.TransactionAggregationType
      .ROTH_401K_CONTRIBUTIONS_2023:
    case WalkthroughGraphQL.TransactionAggregationType
      .ROTH_IRA_CONTRIBUTIONS_2023:
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_401K_POST_TAX_CONTRIBUTIONS_2023:
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2023:
    case WalkthroughGraphQL.TransactionAggregationType
      .TRADITIONAL_IRA_PRETAX_CONTRIBUTIONS_2023:
      return 2023
  }
}

function buildTransactionAggregation (
  transactionAggregationType: WalkthroughGraphQL.TransactionAggregationType,
  title: string,
  balance: number,
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts'],
  accountQuestionHooks: AccountQuestionHooks
): TransactionAgregationData {
  return {
    key: transactionAggregationType,
    title: title,
    balance: balance.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }),
    handleOnPressEdit: () => {
      accountQuestionHooks.setAccountQuestions([
        k401ContributionQuestions(
          account,
          contributionYearFromTransactionAggregation(transactionAggregationType)
        )
      ])
      accountQuestionHooks.setAccountQuestionIndex(0)
    }
  }
}

function get401kTransactionAggregations (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts'],
  accountQuestionHooks: AccountQuestionHooks
): TransactionAgregationData[] {
  if (isNonNullish(account.transactionAggregations)) {
    const transactions: TransactionAgregationData[] = []
    const aggregations = new Map<
    WalkthroughGraphQL.TransactionAggregationType,
    number
    >()
    for (const transaction of account.transactionAggregations) {
      if (isNonNullish(transaction) && isNonNullish(transaction.amount)) {
        aggregations.set(
          transaction.type,
          (transaction.amount ?? 0) + (aggregations.get(transaction.type) ?? 0)
        )
      }
    }
    // Show pre-tax & roth 401k transaction aggregation types, even if the balance is 0.
    // NOTE: Not currently showing the after-tax 401k transaction aggregation type.
    transactions.push(
      buildTransactionAggregation(
        WalkthroughGraphQL.TransactionAggregationType
          .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2023,
        transactionAggregationTypeToDisplayText(
          WalkthroughGraphQL.TransactionAggregationType
            .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2023
        ),
        aggregations.get(
          WalkthroughGraphQL.TransactionAggregationType
            .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2023
        ) ?? 0,
        account,
        accountQuestionHooks
      )
    )
    transactions.push(
      buildTransactionAggregation(
        WalkthroughGraphQL.TransactionAggregationType
          .ROTH_401K_CONTRIBUTIONS_2023,
        transactionAggregationTypeToDisplayText(
          WalkthroughGraphQL.TransactionAggregationType
            .ROTH_401K_CONTRIBUTIONS_2023
        ),
        aggregations.get(
          WalkthroughGraphQL.TransactionAggregationType
            .ROTH_401K_CONTRIBUTIONS_2023
        ) ?? 0,
        account,
        accountQuestionHooks
      )
    )
    transactions.push(
      buildTransactionAggregation(
        WalkthroughGraphQL.TransactionAggregationType
          .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2022,
        transactionAggregationTypeToDisplayText(
          WalkthroughGraphQL.TransactionAggregationType
            .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2022
        ),
        aggregations.get(
          WalkthroughGraphQL.TransactionAggregationType
            .TRADITIONAL_401K_PRE_TAX_CONTRIBUTIONS_2022
        ) ?? 0,
        account,
        accountQuestionHooks
      )
    )
    transactions.push(
      buildTransactionAggregation(
        WalkthroughGraphQL.TransactionAggregationType
          .ROTH_401K_CONTRIBUTIONS_2022,
        transactionAggregationTypeToDisplayText(
          WalkthroughGraphQL.TransactionAggregationType
            .ROTH_401K_CONTRIBUTIONS_2022
        ),
        aggregations.get(
          WalkthroughGraphQL.TransactionAggregationType
            .ROTH_401K_CONTRIBUTIONS_2022
        ) ?? 0,
        account,
        accountQuestionHooks
      )
    )
    return transactions
  }
  return []
}

function get401kAccountSettings (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts'],
  accountQuestionHooks: AccountQuestionHooks
): AccountSettingData[] {
  const settings: AccountSettingData[] = []
  settings.push({
    key: '401K_ACCOUNT_ELIGIBLE_PRE_TAX_TRADITIONAL',
    title: 'Eligible for pre-tax traditional contributions',
    value: yesOrNoOrIncomplete(
      account.isEligibleForPreTaxTraditional401KContributions
    ),
    handleOnPressEdit: () => {
      accountQuestionHooks.setAccountQuestions([
        [preTaxTraditional401kEligibilityQuestion(account)]
      ])
      accountQuestionHooks.setAccountQuestionIndex(0)
    }
  })
  settings.push({
    key: '401K_ACCOUNT_ELIGIBLE_ROTH',
    title: 'Eligible for Roth contributions',
    value: yesOrNoOrIncomplete(account.isEligibleForRoth401KContributions),
    handleOnPressEdit: () => {
      accountQuestionHooks.setAccountQuestions([
        [roth401kEligibilityQuestion(account)]
      ])
      accountQuestionHooks.setAccountQuestionIndex(0)
    }
  })
  settings.push({
    key: '401K_ACCOUNT_ELIGIBLE_AFTER_TAX_TRADITIONAL',
    title: 'Eligible for after-tax contributions to *traditional* 401k',
    value: yesOrNoOrIncomplete(
      account.isEligibleForAfterTaxTraditional401KContributions
    ),
    handleOnPressEdit: () => {
      accountQuestionHooks.setAccountQuestions([
        [afterTaxTraditional401kEligibilityQuestion(account)]
      ])
      accountQuestionHooks.setAccountQuestionIndex(0)
    }
  })
  settings.push({
    key: '401K_ACCOUNT_EMPLOYER_MATCH',
    title: 'Employer match rules',
    value: hasEmployerMatch(account, /* includeZeroMatch= */ true)
      ? getEmployerMatchShortString(account)
      : kIncompleteString,
    handleOnPressEdit: () => {
      accountQuestionHooks.setAccountQuestions([
        employerMatch401kQuestion(account)
      ])
      accountQuestionHooks.setAccountQuestionIndex(0)
    }
  })

  return settings
}

export function k401AccountBalanceRowHandler (
  accountQuestionHooks: AccountQuestionHooks,
  shouldShowWelcomeBanner: boolean,
  memberQuery:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
): AccountRowConverter<
  InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
  > {
  return (account) => {
    try {
      // This if block allows us to assume that account is nonullable further down
      if (!validateAccountForHandler(account, 'k401AccountHandler')) {
        return null
      }
    } catch (e) {
      return null
    }

    const rowAndBalance = accountBalanceRow<
    InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
    >(
      accountQuestionHooks,
      shouldShowWelcomeBanner,
      memberQuery
    )(account)

    if (!isNonNullish(rowAndBalance)) {
      return null
    }
    rowAndBalance.account.accountSettings = get401kAccountSettings(
      account,
      accountQuestionHooks
    )
    rowAndBalance.account.transactionAggregations =
      get401kTransactionAggregations(account, accountQuestionHooks)
    return rowAndBalance
  }
}
