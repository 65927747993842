import * as React from "react"
import Svg, { SvgProps, Circle, Path } from "react-native-svg"

const Flag = (props: SvgProps) => (
<Svg
width={37}
height={37}
fill="none"
{...props}
>
<Circle
cx={18.5}
cy={18.5}
r={17.5}
fill="#FF7991"
stroke="#202232"
strokeWidth={2}
/>
<Path
fillRule="evenodd"
clipRule="evenodd"
d="M16 11.24c0-.685-.448-1.24-1-1.24s-1 .555-1 1.24v15.52c0 .685.448 1.24 1 1.24s1-.555
1-1.24v-5.528l10.453-4.016c.87-.334.85-1.57-.029-1.877L16 11.699v-.46Zm0
2.577v5.272l7.19-2.761L16 13.817Z"
fill="#fff"
/>
</Svg>
)
export default Flag
