import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import { ParamListBase } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { welcomeScreenStyle } from './welcome-screen.style'
// Storing navigation screen state in asynchronous, unencrypted, persistent, key-value storage
// system for React Native: https://github.com/react-native-async-storage/async-storage
// IMPORTANT: DO NOT store personally identifiable info in AsyncStorage.
import AsyncStorage from '@react-native-async-storage/async-storage'
import { welcomeContent } from './welcome-content'
import { APP_SCREEN_STATE_KEY, THEME } from '../../../constants'
import { BackButton } from '../../common/buttons/back-button'

function WelcomeScreen (props: {
  navigation: NativeStackNavigationProp<ParamListBase, string>
}): JSX.Element {
  React.useEffect(() => {
    AsyncStorage.setItem(APP_SCREEN_STATE_KEY, 'Welcome').catch(console.log)
  }, [])

  const content = welcomeContent
  if (content.length <= 0) {
    throw new Error('No welcome content present!')
  }

  const [contentSectionIndex, setContentSectionIndex] = React.useState(0)
  const numContentSections = content.length
  const contentSection = content[contentSectionIndex]

  React.useLayoutEffect(() => {
    // If contentSectionIndex is <= 0, we're on the first screen and there is no back button.
    if (contentSectionIndex <= 0) {
      props.navigation.setOptions({
        headerLeft: () => null
      })
    } else {
      // If contentSectionIndex is greater than 0, we're somewhere in the intro flow. The back button
      // on this page takes the member back to the previous intro content page.
      props.navigation.setOptions({
        headerLeft: () => (
          <BackButton
            handleOnPress={() => {
              setContentSectionIndex(contentSectionIndex - 1)
            }}
          />
        )
      })
    }
  }, [contentSectionIndex])

  const style = welcomeScreenStyle(THEME)

  return (
    <View style={style.welcomeScreen}>
      <View style={style.contentBox}>
        <Text style={style.content}>{contentSection.content}</Text>
      </View>
      <Pressable
        style={style.nextButton}
        onPress={() => {
          if (contentSectionIndex < numContentSections - 1) {
            // We should navigate to the next content section by increasing the contentSectionIndex.
            setContentSectionIndex(contentSectionIndex + 1)
          } else {
            // We're on the last content section so we should navigate to the authenticated app.
            props.navigation.navigate('AuthenticatedApp')
          }
        }}
      >
        <Text style={style.nextButtonText}>Next</Text>
      </Pressable>
      <View style={style.illustration}>{contentSection.illustration}</View>
    </View>
  )
}

export { WelcomeScreen }
