import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { AnswerType, QuestionAndAnswers } from '../question-data'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import {
  stringInStringEnum,
  exhaustiveSwitchGuard,
  isNonNullish
} from 'global-utils'
import {
  stringIsWordCharsOrSpacesAndLessThan31Chars,
  getNumberFromDollarString,
  isWholeDollarString
} from '../../../../../util/input-validators'
import {
  AccountType,
  accountTypeToDisplayValues
} from '../display-values/account-type'
import { v4 as uuidv4 } from 'uuid'
import { loadToken } from '../../../../auth/load-token'
import { storeNewAccountSnapshot } from './update-manual-account-balance'

// Creates the appropriate type of manual account.
async function createNewManualAccount (
  client: ApolloClient<NormalizedCacheObject>,
  accountType: AccountType,
  accountName: string,
  startingBalance: number
): Promise<void> {
  const accountId = uuidv4()
  switch (accountType) {
    case AccountType.SAVINGS: {
      const response = await client.mutate<
      WalkthroughGraphQL.CreateSavingsAccountMutation,
      WalkthroughGraphQL.CreateSavingsAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createSavingsAccount),
        variables: {
          input: {
            // We must explicitly declare this because Amplify doesn't insert it into GSI otherwise
            owner: await loadToken(),
            accountName,
            accountId,
            dataProvider:
              WalkthroughGraphQL.DataProvider.WALKTHROUGH_MANUALLY_DECLARED,
            dataProviderAccountId: accountId,
            dataProviderIdForFinancialInstitutionUser: '',
            manuallyDeclared: true
          }
        }
      })
      const accountResponse = response.data?.createSavingsAccount
      if (isNonNullish(accountResponse)) {
        await storeNewAccountSnapshot(client, accountResponse, startingBalance)
      } else {
        throw new Error(
          'In createNewManualAccount: createSavingsAccount returned null'
        )
      }
      return
    }
    case AccountType.CHECKING: {
      const response = await client.mutate<
      WalkthroughGraphQL.CreateCheckingAccountMutation,
      WalkthroughGraphQL.CreateCheckingAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createCheckingAccount),
        variables: {
          input: {
            // We must explicitly declare this because Amplify doesn't insert it into GSI otherwise
            owner: await loadToken(),
            accountName,
            accountId,
            dataProvider:
              WalkthroughGraphQL.DataProvider.WALKTHROUGH_MANUALLY_DECLARED,
            dataProviderAccountId: accountId,
            dataProviderIdForFinancialInstitutionUser: '',
            manuallyDeclared: true
          }
        }
      })
      const accountResponse = response.data?.createCheckingAccount
      if (isNonNullish(accountResponse)) {
        await storeNewAccountSnapshot(client, accountResponse, startingBalance)
      } else {
        throw new Error(
          'In createNewManualAccount: createCheckingAccount returned null'
        )
      }
      return
    }
    case AccountType.DEBT: {
      const response = await client.mutate<
      WalkthroughGraphQL.CreateDebtAccountMutation,
      WalkthroughGraphQL.CreateDebtAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createDebtAccount),
        variables: {
          input: {
            // We must explicitly declare this because Amplify doesn't insert it into GSI otherwise
            owner: await loadToken(),
            accountName,
            accountId,
            dataProvider:
              WalkthroughGraphQL.DataProvider.WALKTHROUGH_MANUALLY_DECLARED,
            dataProviderAccountId: accountId,
            dataProviderIdForFinancialInstitutionUser: '',
            manuallyDeclared: true
          }
        }
      })
      const accountResponse = response.data?.createDebtAccount
      if (isNonNullish(accountResponse)) {
        await storeNewAccountSnapshot(client, accountResponse, startingBalance)
      } else {
        throw new Error(
          'In createNewManualAccount: createDebtAccount returned null'
        )
      }
      return
    }
    case AccountType.K401_ACCOUNT: {
      const response = await client.mutate<
      WalkthroughGraphQL.CreateK401AccountMutation,
      WalkthroughGraphQL.CreateK401AccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createK401Account),
        variables: {
          input: {
            // We must explicitly declare this because Amplify doesn't insert it into GSI otherwise
            owner: await loadToken(),
            accountName,
            accountId,
            dataProvider:
              WalkthroughGraphQL.DataProvider.WALKTHROUGH_MANUALLY_DECLARED,
            dataProviderAccountId: accountId,
            dataProviderIdForFinancialInstitutionUser: '',
            manuallyDeclared: true
          }
        }
      })
      const accountResponse = response.data?.createK401Account
      if (isNonNullish(accountResponse)) {
        await storeNewAccountSnapshot(client, accountResponse, startingBalance)
      } else {
        throw new Error(
          'In createNewManualAccount: createK401Account returned null'
        )
      }
      return
    }
    case AccountType.TRADITIONAL_IRA: {
      const response = await client.mutate<
      WalkthroughGraphQL.CreateTraditionalIraAccountMutation,
      WalkthroughGraphQL.CreateTraditionalIraAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createTraditionalIraAccount),
        variables: {
          input: {
            // We must explicitly declare this because Amplify doesn't insert it into GSI otherwise
            owner: await loadToken(),
            accountName,
            accountId,
            dataProvider:
              WalkthroughGraphQL.DataProvider.WALKTHROUGH_MANUALLY_DECLARED,
            dataProviderAccountId: accountId,
            dataProviderIdForFinancialInstitutionUser: '',
            manuallyDeclared: true
          }
        }
      })
      const accountResponse = response.data?.createTraditionalIraAccount
      if (isNonNullish(accountResponse)) {
        await storeNewAccountSnapshot(client, accountResponse, startingBalance)
      } else {
        throw new Error(
          'In createNewManualAccount: createTraditionalIraAccount returned null'
        )
      }
      return
    }
    case AccountType.ROTH_IRA: {
      const response = await client.mutate<
      WalkthroughGraphQL.CreateRothIraAccountMutation,
      WalkthroughGraphQL.CreateRothIraAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createRothIraAccount),
        variables: {
          input: {
            // We must explicitly declare this because Amplify doesn't insert it into GSI otherwise
            owner: await loadToken(),
            accountName,
            accountId,
            dataProvider:
              WalkthroughGraphQL.DataProvider.WALKTHROUGH_MANUALLY_DECLARED,
            dataProviderAccountId: accountId,
            dataProviderIdForFinancialInstitutionUser: '',
            manuallyDeclared: true
          }
        }
      })
      const accountResponse = response.data?.createRothIraAccount
      if (isNonNullish(accountResponse)) {
        await storeNewAccountSnapshot(client, accountResponse, startingBalance)
      } else {
        throw new Error(
          'In createNewManualAccount: createRothIraAccount returned null'
        )
      }
      return
    }
    case AccountType.OTHER_INVESTMENT: {
      const response = await client.mutate<
      WalkthroughGraphQL.CreateOtherInvestmentAccountMutation,
      WalkthroughGraphQL.CreateOtherInvestmentAccountMutationVariables
      >({
        mutation: gql(WalkthroughGraphQL.createOtherInvestmentAccount),
        variables: {
          input: {
            // We must explicitly declare this because Amplify doesn't insert it into GSI otherwise
            owner: await loadToken(),
            accountName,
            accountId,
            dataProvider:
              WalkthroughGraphQL.DataProvider.WALKTHROUGH_MANUALLY_DECLARED,
            dataProviderAccountId: accountId,
            dataProviderIdForFinancialInstitutionUser: '',
            manuallyDeclared: true
          }
        }
      })
      const accountResponse = response.data?.createOtherInvestmentAccount
      if (isNonNullish(accountResponse)) {
        await storeNewAccountSnapshot(client, accountResponse, startingBalance)
      } else {
        throw new Error(
          'In createNewManualAccount: createOtherInvestmentAccount returned null'
        )
      }
      return
    }
    default: {
      exhaustiveSwitchGuard(accountType)
    }
  }
}

// Generates a new instance of new manual account questions.  Reports valid account names to the callback.
export const newAccountQuestions: (
  reportAccountName: (name: string) => void
) => QuestionAndAnswers[][] = (reportAccountName) => {
  let accountNameResolve: (value: string) => void
  let accountNameReject: (reason?: any) => void
  const accountName = new Promise<string>((resolve, reject) => {
    accountNameResolve = resolve
    accountNameReject = reject
  })
  let accountTypeResolve: (value: AccountType) => void
  let accountTypeReject: (reason?: any) => void
  const accountType = new Promise<AccountType>((resolve, reject) => {
    accountTypeResolve = resolve
    accountTypeReject = reject
  })
  return [
    [
      {
        key: 'ACCOUNT_NAME_QUESTION',
        question: {
          text: <>What would you like to call this account?</>
        },
        answers: [
          {
            key: 'NAME_ANSWER',
            getInitialAnswer: async () => '',
            storeValue: async (_, value) => {
              if (stringIsWordCharsOrSpacesAndLessThan31Chars(value.trim())) {
                accountNameResolve(value.trim())
                reportAccountName(value.trim())
              } else {
                accountNameReject(
                  new Error(
                    "Provided value ('" +
                      value +
                      "') is not alphanumeric or short enough"
                  )
                )
              }
            },
            validation: {
              isValid: (answer: string) => {
                // Validation here does not inherently protect against XSS attacks because an attacker
                // could simply call the backend directly (via cURL or something).  This field should
                // be treated skeptically in the backend.
                return stringIsWordCharsOrSpacesAndLessThan31Chars(
                  answer.trim()
                )
              },
              notValidInfoText:
                'Account name must be alphanumeric and not super long.'
            },
            answerType: AnswerType.TEXT,
            textAnswer: {
              placeholderText: 'Steve the bank account'
            }
          }
        ]
      }
    ],
    [
      {
        key: 'ACCOUNT_TYPE_QUESTION',
        question: {
          text: <>What type of account is this?</>
        },
        answers: [
          {
            key: 'ACCOUNT_TYPE_ANSWER',
            getInitialAnswer: async () => '',
            validation: {
              isValid: (answer: string) =>
                stringInStringEnum(AccountType, answer),
              notValidInfoText: 'Answer must be a supported account type.'
            },
            answerType: AnswerType.SELECTION,
            selectionAnswer: {
              items: Array.from(accountTypeToDisplayValues).map(
                ([key, value]) => {
                  return { enumValue: key, value, displayText: value }
                }
              )
            },
            storeValue: async (client, value: string) => {
              if (stringInStringEnum(AccountType, value)) {
                accountTypeResolve(value)
              } else {
                accountTypeReject(
                  new Error(
                    "Provided value ('" +
                      value +
                      "') is not a valid account type"
                  )
                )
              }
            }
          }
        ]
      }
    ],
    [
      {
        key: 'ACCOUNT_BALANCE_QUESTION',
        question: {
          text: <>What is the current balance of this account?</>
        },
        answers: [
          {
            key: 'ACCOUNT_BALANCE_ANSWER',
            getInitialAnswer: async () => '',
            validation: {
              isValid: (answer: string) => {
                return isWholeDollarString(answer)
              },
              notValidInfoText: 'Contribution must be a dollar amount > 0.'
            },
            answerType: AnswerType.TEXT,
            textAnswer: {
              placeholderText: '$XXXX'
            },
            storeValue: async (client, value: string) => {
              if (isWholeDollarString(value)) {
                const name = await accountName
                const t = await accountType
                await createNewManualAccount(
                  client,
                  t,
                  name,
                  getNumberFromDollarString(value)
                )
              } else {
                console.log(
                  "Provided value ('" +
                    value +
                    "') is not a valid dollar amount"
                )
              }
            }
          }
        ]
      }
    ]
  ]
}
