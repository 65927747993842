import * as React from 'react'
import { InputAccountTypesMemberLatestFinancialViewQuery } from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { AnswerType, QuestionAndAnswers } from '../question-data'
import { gql } from '@apollo/client'
import {
  getFractionFromPercentString,
  isPercentString
} from '../../../../../util/input-validators'
import { Anchor } from '../../links/anchor'
import {
  accountNameWithBalanceFallback,
  getDebtInterestString
} from '../../../../../util/account-parsing'
import { isNonNullish } from 'global-utils'

export const apyQuestion: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
) => QuestionAndAnswers = (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
) => {
  const autoDetectedAPY = account?.snapshots?.items[0]?.interestAPY
  const autoDetectedInterestImprecise =
    account?.snapshots?.items[0]?.interestImprecise

  const hasAutoDetectedInterest =
    isNonNullish(autoDetectedAPY) ||
    isNonNullish(autoDetectedInterestImprecise)
  const autoDetectedInterestIsImprecise =
    hasAutoDetectedInterest && !isNonNullish(autoDetectedAPY)
  const interestToDisplay = (
    autoDetectedAPY ?? autoDetectedInterestImprecise
  )?.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 })

  return {
    key: 'APY_QUESTION',
    question: {
      text: (
        <>
          We're not sure what the interest APY (annual percentage yield) is for
          your {accountNameWithBalanceFallback(account)} account.
          {hasAutoDetectedInterest
            ? ' Our best guess from your account data is ' +
              (autoDetectedInterestIsImprecise ? 'approximately ' : '') +
              (interestToDisplay ?? '')
            : null}
          {'\n\n'}
          Please look at your account's app or website for the interest
          percentage labeled APY or APR and enter it below. {'\n\n'}For example,
          if the APY is 20.33%, enter 20.33 in the box below.{'\n\n'}
          (side note: We assume it's an APY, so if you have an APR, you can use
          this <Anchor text="calculator" href="https://www.aprtoapy.com/" /> to
          convert an APR to an APY. We'll still be able to give you pretty
          accurate advice if you don't want to bother with calculators, but
          we'll be extra happy if you convert it for us!)
        </>
      )
    },
    answers: [
      {
        key: 'APY_ANSWER',
        getInitialAnswer: async (client) => {
          return getDebtInterestString(account) ?? ''
        },
        storeValue: async (client, value) => {
          if (!isPercentString(value)) {
            console.log(
              "Provided value ('" +
                value +
                "') is not a number between 0 and 100."
            )
          }
          await client.mutate<
          WalkthroughGraphQL.UpdateDebtAccountMutation,
          WalkthroughGraphQL.UpdateDebtAccountMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updateDebtAccount),
            variables: {
              input: {
                accountId: account.accountId,
                memberDeclaredInterestAPY: getFractionFromPercentString(value)
              }
            }
          })
        },
        validation: {
          isValid: (answer: string) => {
            return isPercentString(answer)
          },
          notValidInfoText:
            'Interest APY must be a decimal number between 0 and 100.'
        },
        answerType: AnswerType.TEXT,
        textAnswer: {
          placeholderText: 'X.XX%'
        }
      }
    ]
  }
}
