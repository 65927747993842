import * as React from 'react'
import { Text } from 'react-native'
import { textStyle } from '../../../../themes/global-styles.style'
import {
  TaskStepsData,
  TaskStepsRenderingStyle
} from '../../tasks/task-flow-data'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { Anchor } from '../../common/links/anchor'

export const aiAdvisorPrivacy: TaskStepsData = {
  stepRenderingStyle: TaskStepsRenderingStyle.CLICK_THROUGH_MODULE,
  title: 'AI Advisor Privacy',
  description: '',
  subtasks: [
    {
      key: 'INTRO',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          Walkthrough's AI Advisor is powered by OpenAI. For us to answer your
          question, we share it with them.
        </>
      )
    },
    {
      key: 'DEETS',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          OpenAI{' '}
          <Text style={textStyle.boldText}>
            does not know which questions are yours
          </Text>{' '}
          — they only know that the question came from Walkthrough. However, if
          you include identifying information in your question like "My name is
          Bob," or "I live at 123 Main Street," OpenAI will see it.
          {'\n\n'}Because of this,{' '}
          <Text style={textStyle.boldText}>
            we suggest that you don't include personally identifying information
            in your questions.
          </Text>
        </>
      )
    },
    {
      key: 'LINKS',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          If you'd like to go deeper, take a look at{' '}
          <Anchor
            text="our privacy policy"
            href="https://www.walkthrough.co/privacyPolicy"
          />{' '}
          or OpenAI's{' '}
          <Anchor text="terms of use" href="https://openai.com/terms/" />
          {'.*'}
          {'\n\n'}
          If you still have questions, feel free to{' '}
          <Anchor text="email us" href="mailto:support@walkthrough.co" />!
          {'\n\n'}
          <Text style={textStyle.italicText}>
            (Note: Walkthrough opted out of OpenAI's content improvement to
            further protect your privacy)
          </Text>
          .
        </>
      )
    },
    {
      key: 'THANKS',
      isComplete: false,
      storeCompletionState: () => {},
      title: '',
      description: (
        <>
          That's the whole story — thanks for listening!{'\n\n'}Now... ask away!
        </>
      )
    }
  ],
  button: {
    buttonText: 'Done',
    handleOnPressButton: (props: {
      navigation: GenericCompositeNavigationProp
    }) => {
      props.navigation.goBack()
    }
  }
}
