import { WhyThisTaskHandler } from './get-why-this-task'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { Text } from 'react-native'
import { isNonNullish } from 'global-utils'

export function buildEmergencyFundWhyThisTaskHandler (
  taskTypename: string
): WhyThisTaskHandler<WalkthroughGraphQL.BuildOneMonthEmergencyFundTask> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      task.__typename === taskTypename
    ) {
      return (
        <Text>
          You need a small emergency fund before getting to the real fun stuff,
          so we should deal with that now. This gives you protection and peace
          of mind while you're off doing everything else.
        </Text>
      )
    }
    return null
  }
}
