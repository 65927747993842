import { InputAccountTypesMemberLatestFinancialViewQuery } from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { AnswerType, QuestionAndAnswers } from '../question-data'
import { gql } from '@apollo/client'
import { stringInStringEnum } from 'global-utils'
import { YesOrNo, yesOrNoToDisplayText } from '../display-values/yes-or-no'
import {
  accountNameWithBalanceFallback,
  getInterestBeingCharged
} from '../../../../../util/account-parsing'

// TODO: write real copy/edit this copy for the questions added here.
export const interestInactiveQuestion: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
) => QuestionAndAnswers = (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
) => {
  const autoInactive = account?.snapshots?.items[0]?.interestInactive ?? false
  return {
    key: 'INTEREST_INACTIVE_QUESTION',
    question: {
      text: (
        <>
          Are you being charged interest on your{' '}
          {accountNameWithBalanceFallback(account)} account? Our best guess from
          your account data is you{autoInactive ? ' ARE NOT' : ' ARE'}.{'\n\n'}
          The most common case where you are NOT being charged interest is that
          you live in your credit card's grace period (i.e. you pay off the
          amount due every month). If you missed your credit card payment even
          just once, even if you now pay it off each month, you might no longer
          have the benefit of a grace period, so double check this by looking at
          your account statement and checking for interest charges.{'\n\n'}Are
          you being charged interest?
        </>
      )
    },
    answers: [
      {
        key: 'INTEREST_INACTIVE_ANSWER',
        getInitialAnswer: async () => {
          // This logic autopopulates the answer with our best guess if no previously declared
          // value is present, or the previously declared value if we have one for the member.
          return getInterestBeingCharged(account) ?? ''
        },
        storeValue: async (client, value) => {
          if (!stringInStringEnum(YesOrNo, value)) {
            console.log("Provided value ('" + value + "') is not yes or no.")
            return
          }
          await client.mutate<
          WalkthroughGraphQL.UpdateDebtAccountMutation,
          WalkthroughGraphQL.UpdateDebtAccountMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updateDebtAccount),
            variables: {
              input: {
                accountId: account.accountId,
                memberDeclaredInterestInactive: value === YesOrNo.NO
              }
            }
          })
        },
        validation: {
          isValid: (answer: string) => stringInStringEnum(YesOrNo, answer),
          notValidInfoText: 'Answer must be Yes or No.'
        },
        answerType: AnswerType.SELECTION,
        selectionAnswer: {
          items: Array.from(yesOrNoToDisplayText).map(([key, value]) => {
            return { enumValue: key, value, displayText: value }
          })
        }
      }
    ]
  }
}
