import * as React from 'react'
import { Text, TextStyle } from 'react-native'
import { GenericCompositeNavigationProp } from '../types/generic-composite-navigation-prop'
import { useNavigation } from '@react-navigation/native'

// An inline anchor that surfaces a navigation object with which to navigate to a different place in the app.
export function NavigationAnchor (props: {
  // Text styles nest, so only specify extra styles you want to add to indicate this is an anchor.  By default,
  // we just underline the text.
  style: TextStyle[]
  handlePress: (navigation: GenericCompositeNavigationProp) => void
  text: string
}): JSX.Element {
  const navigation = useNavigation<GenericCompositeNavigationProp>()
  return (
    <Text
      style={props.style}
      onPress={() => {
        props.handlePress(navigation)
      }}
    >
      {props.text}
    </Text>
  )
}

NavigationAnchor.defaultProps = {
  style: { textDecorationLine: 'underline' }
}
