// @ts-expect-error
import { AmplifyTheme } from 'aws-amplify-react-native'
import { Platform } from 'react-native'
import { THEME } from '../../constants'

// AuthTheme overrides some of the css styles in Amplify's theme css:
// https://github.com/aws-amplify/amplify-js/blob/main/packages/aws-amplify-react-native/src/AmplifyTheme.ts
const AuthTheme = Object.assign({}, AmplifyTheme, {
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-around',
    paddingTop: THEME.spacing.verticalSpaceLarge,
    width: '100%',
    maxWidth: 425,
    backgroundColor: THEME.color.background
  },
  linkUnderlay: {
    color: THEME.color.background
  },
  sectionHeaderText: {
    color: THEME.color.onSurface,
    fontSize: THEME.font.fontSizeLarge,
    fontFamily: 'PublicSans_700Bold'
  },
  button: {
    backgroundColor: THEME.color.highlight,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 20,
    height: THEME.buttons.buttonHeight,
    width: '100%'
  },
  buttonDisabled: {
    backgroundColor: THEME.color.disabled,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 20,
    height: THEME.buttons.buttonHeight,
    width: '100%'
  },
  buttonText: {
    color: THEME.color.onSurface,
    fontSize: THEME.font.fontSizeMedium,
    fontFamily: 'PublicSans_700Bold',
    textTransform: 'lowercase'
  },
  input: {
    backgroundColor: THEME.color.surfaceTwo,
    width: '100%',
    height: THEME.buttons.buttonHeight,
    borderRadius: 20,
    color: THEME.color.onSurface,
    fontSize: THEME.font.fontSizeMedium,
    fontFamily: 'PublicSans_700Bold',
    paddingLeft: THEME.spacing.horizontalSpaceMedium,
    paddingRight: THEME.spacing.horizontalSpaceMedium,
    alignContent: 'center',
    justifyContent: 'center'
  },
  phoneInput: {
    flex: 2,
    backgroundColor: THEME.color.surfaceTwo,
    width: '100%',
    height: THEME.buttons.buttonHeight,
    borderRadius: 20,
    color: THEME.color.onSurface,
    fontSize: THEME.font.fontSizeMedium,
    fontFamily: 'PublicSans_700Bold',
    paddingLeft: THEME.spacing.horizontalSpaceMedium,
    paddingRight: THEME.spacing.horizontalSpaceMedium,
    alignContent: 'center',
    justifyContent: 'center'
  },
  inputLabel: {
    display: 'none'
  },
  sectionFooterLink: {
    fontSize: THEME.font.fontSizeSmall,
    color: THEME.color.highlight,
    textDecoration: 'underline',
    alignItems: 'baseline',
    textAlign: 'center'
  },
  sectionFooterLinkDisabled: {
    fontSize: THEME.font.fontSizeSmall,
    color: THEME.color.disabled,
    textDecoration: 'underline',
    alignItems: 'baseline',
    textAlign: 'center'
  },
  picker: {
    flex: 1,
    // ensure that longer text values render without truncation
    // as the selected value of the Picker on Android
    minWidth: Platform.OS === 'android' ? 16 : 0,
    backgroundColor: THEME.color.surfaceTwo,
    height: THEME.buttons.buttonHeight,
    borderWidth: 10,
    borderColor: THEME.color.surfaceTwo,
    borderRadius: 20,
    color: THEME.color.onSurface,
    fontSize: THEME.font.fontSizeMedium,
    fontFamily: 'PublicSans_700Bold',
    marginRight: THEME.spacing.horizontalSpaceMedium
  },
  pickerItem: {
    height: 44
  },
  signedOutMessage: {
    display: 'none'
  }
})

export { AuthTheme }
