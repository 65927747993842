import * as WalkthroughGraphQL from 'amplify-client-graphql'

// Defines an abstraction of DataProviderActionNeededStatus that is particularly useful for account linking pages.
// The flow type indicates whether account linking pages should configure themselves to e.g. link new accounts, edit existing ones, etc.
// Conceptually, the account linking page doesn't need to know the DataProviderActionNeededStatus; it just needs to know
// what page to show.

export enum AccountLinkingFlowType {
  LINK_NEW_ACCOUNTS,
  EDIT_ACCOUNT,
  REFRESH_ACCOUNT,
  VISIT_FINANCIAL_PROVIDER
}

export function getAccountLinkingFlowType (
  dataProviderActionNeededStatus: WalkthroughGraphQL.DataProviderActionNeededStatus
): AccountLinkingFlowType | null {
  switch (dataProviderActionNeededStatus) {
    case WalkthroughGraphQL.DataProviderActionNeededStatus
      .NO_ACTION_REQUIRED_AFFIRMATIVE_SUCCESS:
    case WalkthroughGraphQL.DataProviderActionNeededStatus
      .RECHECK_WITH_DATA_PROVIDER:
    case WalkthroughGraphQL.DataProviderActionNeededStatus
      .ALERT_USER_CONNECTION_HAS_BEEN_DISABLED:
      // TODO: For now we leave this flow unimplemented, although we should eventually surface this to the member so
      // they don't get confused about accounts that are not updating for this reason.
      return null
    case WalkthroughGraphQL.DataProviderActionNeededStatus
      .ERROR_TRIGGER_DATA_PROVIDER_FULL_REPULL:
      // TODO: trigger full re-pull from our data provider via frontend or backend
      return null
    case WalkthroughGraphQL.DataProviderActionNeededStatus
      .REQUEST_USER_GO_TO_FI_SITE:
      return AccountLinkingFlowType.VISIT_FINANCIAL_PROVIDER
    case WalkthroughGraphQL.DataProviderActionNeededStatus
      .REQUEST_USER_EDIT_ACCOUNT_LINK:
      return AccountLinkingFlowType.EDIT_ACCOUNT
    case WalkthroughGraphQL.DataProviderActionNeededStatus
      .REQUEST_USER_PROVIDE_REFRESH_DATA:
      return AccountLinkingFlowType.REFRESH_ACCOUNT
  }
  // DO NOT ADD A DEFAULT RETURN STATEMENT HERE! AS-IS, failure to cover a new DataProviderActionNeededStatus
  // is a compile-time error (which is desired).
}
