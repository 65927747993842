import * as React from 'react'
import { Text } from 'react-native'
import YuyuCalculator from '../../../../../assets/yuyu/calculator'
import { FadeElementGroup } from '../../determine-next-task/fade-element-groups'

export function getRoiOfTasksElementGroup (): FadeElementGroup {
  return {
    key: 'ROI',
    elements: [
      {
        key: 'ROI_LINE',
        element: (
          <Text>
            Calculating the return on investment of eligible tasks and balancing
            it against associated risks.
          </Text>
        ),
        preFadeInDelay: 0
      }
    ],
    image: <YuyuCalculator />
  }
}
