import * as React from 'react'
import { Pressable, Text, TextInput, View } from 'react-native'
import { THEME } from '../../../constants'
import {
  submitButtonStyle,
  feedbackGradientStyle,
  feedbackScreenStyle,
  feedbackThankYouStyle
} from './feedback-screen.style'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { useMutation, gql } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import { BackButton } from '../../common/buttons/back-button'
import { GenericCompositeNavigationProp } from '../common/types/generic-composite-navigation-prop'

function FeedbackScreen (): JSX.Element {
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [feedback, setFeedback] = React.useState('')
  const [showThankYou, setShowThankYou] = React.useState(false)

  const [saveFeedbackMutation, { loading }] = useMutation<
  WalkthroughGraphQL.CreateFeedbackEntryMutation,
  WalkthroughGraphQL.CreateFeedbackEntryMutationVariables
  >(gql(WalkthroughGraphQL.createFeedbackEntry))

  const navigation = useNavigation<GenericCompositeNavigationProp>()

  // Use custom back button so that the back button color is white on iOS (weirdly defaults to black even
  // when headerTintColor is set to white)
  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <BackButton
          handleOnPress={() => {
            navigation.goBack()
          }}
        />
      )
    })
  }, [navigation])

  async function saveFeedback (): Promise<void> {
    try {
      await saveFeedbackMutation({
        variables: {
          input: {
            name: name,
            email: email,
            phone: phoneNumber,
            feedback: feedback
          }
        }
      })
      setFeedback('')
      setShowThankYou(true)
    } catch (e) {
      console.log(e)
    }
  }

  const buttonDisabled = feedback === ''

  return (
    <View style={feedbackGradientStyle(THEME, 6).feedbackScreen}>
      <TextInput
        style={feedbackGradientStyle(THEME, 1).shortInput}
        onChangeText={(name) => {
          setName(name)
          setShowThankYou(false)
        }}
        value={name}
        placeholder="name (optional)"
        placeholderTextColor={THEME.color.lowLight}
      />
      <TextInput
        style={feedbackGradientStyle(THEME, 2).shortInput}
        onChangeText={(email) => {
          setEmail(email)
          setShowThankYou(false)
        }}
        value={email}
        placeholder="email (optional)"
        placeholderTextColor={THEME.color.lowLight}
      />
      <TextInput
        style={feedbackGradientStyle(THEME, 3).shortInput}
        onChangeText={(phoneNumber) => {
          setPhoneNumber(phoneNumber)
          setShowThankYou(false)
        }}
        value={phoneNumber}
        placeholder="phone number (optional)"
        placeholderTextColor={THEME.color.lowLight}
      />
      <TextInput
        style={feedbackGradientStyle(THEME, 4).longInput}
        onChangeText={(feedback) => {
          setFeedback(feedback)
          setShowThankYou(false)
        }}
        value={feedback}
        placeholder="What's going wrong? What's going right? Find a bug? Tell us! We promise you won't hurt
        our feelings."
        placeholderTextColor={THEME.color.lowLight}
        multiline
      />
      <Pressable
        onPress={saveFeedback}
        style={submitButtonStyle(THEME, 5, buttonDisabled).button}
        disabled={buttonDisabled}
      >
        <Text style={feedbackScreenStyle(THEME).text}>Submit</Text>
      </Pressable>
      <View style={feedbackThankYouStyle(THEME, loading).thankYou}>
        <Text style={feedbackScreenStyle(THEME).text}>
          Submitting feedback....
        </Text>
      </View>
      <View style={feedbackThankYouStyle(THEME, showThankYou).thankYou}>
        <Text style={feedbackScreenStyle(THEME).text}>
          Thanks for your feedback! Send moarrrrr! (if you want)
        </Text>
      </View>
    </View>
  )
}

export { FeedbackScreen }
