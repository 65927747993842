import * as React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { APP_SCREEN_STATE_KEY, THEME } from '../../constants'
import { FeedbackScreen } from './feedback/feedback-screen'
import { TabApp } from './tab-app'
// @ts-expect-error
import { withAuthenticator } from 'aws-amplify-react-native'
import { AuthTheme } from '../auth/amplify-theme'
import { signUpConfig } from '../auth/sign-up-config'
import { OnboardingScreen } from './onboarding/onboarding-screen'
import { ClickThroughModuleScreen } from './click-through-module/click-through-module-screen'
import { MxMultiplexerScreen } from './account-linking/mx/mx-multiplexer-screen'
// Storing navigation screen state in asynchronous, unencrypted, persistent, key-value storage
// system for React Native: https://github.com/react-native-async-storage/async-storage
// IMPORTANT: DO NOT store personally identifiable info in AsyncStorage.
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createAndUpdateMember } from '../../api-calls/member'
import {
  gql,
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient,
  useLazyQuery
} from '@apollo/client'
import { Loading } from '../common/loading/loading'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { loadToken } from '../auth/load-token'
import { useIsMounted } from '../../util/use-is-mounted'
import { isNonNullish } from 'global-utils'

// See comments in https://github.com/eettaa/emb/blob/dev/src/app/frontend/screens/authenticated/common/types/generic-composite-navigation-prop.ts
// for justification of this template type.
const AuthenticatedAppStack = createNativeStackNavigator<{
  [x: string]: any
}>()

function AuthenticatedApp (): JSX.Element {
  // Use appolloClient directly to create member in our database.
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>

  const isMounted = useIsMounted()

  // Register MemberLatestFinancialView query with repolling- we want polling to be
  // centralized so that various components do not register redundant polling queries.
  // Components can still register their own non-polling query (which because of this code
  // will almost always result in a cache hit).
  // We don't actually use the data in this component- we just want it loaded/refreshed in the
  // cache.
  const [getFinancialView] = useLazyQuery<
  WalkthroughGraphQL.MemberLatestFinancialViewQuery,
  WalkthroughGraphQL.MemberLatestFinancialViewQueryVariables
  >(gql(WalkthroughGraphQL.memberLatestFinancialView), {
    pollInterval: 5000
  })

  // We access member onboarding data here as it is the narrowest predefined query we have to
  // access needed fields (like onboardingComplete). In principle we could narrow this further (or
  // expand it if this component consumes more data in the future).
  const [getMemberOnboarding, { data }] = useLazyQuery<
  WalkthroughGraphQL.MemberOnboardingDataQuery,
  WalkthroughGraphQL.MemberOnboardingDataQueryVariables
  >(gql(WalkthroughGraphQL.memberOnboardingData))

  // DB initialization: create a member object if it does not exist yet, or update so that the
  // updatedAt timestamp updates at the beginning of every session.
  React.useEffect(() => {
    async function createAndReinitMemberFields (): Promise<void> {
      await createAndUpdateMember(apolloClient)
    }
    createAndReinitMemberFields().catch(console.log)
  }, [])

  // Start lazy query hooks after loading the owner value.
  React.useEffect(() => {
    // There are known race condititions with async state-based code in useEffect (like what if the component unmounts
    // before the function returns?), but this is still the recommended approach. See
    // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function startQueryHooks (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getFinancialView({ variables: { owner: owner } })
        void getMemberOnboarding({ variables: { owner: owner } })
      }
    }
    startQueryHooks().catch(console.log)
  }, [])

  // Store that we got to the Authenticated app, regardless of whether we are in onboarding,
  // TabApp, etc.
  React.useEffect(() => {
    AsyncStorage.setItem(APP_SCREEN_STATE_KEY, 'AuthenticatedApp').catch(
      console.log
    )
  }, [])

  function getInitialRouteName (onboardingComplete: boolean): string {
    return onboardingComplete ? 'TabApp' : 'OnboardingScreen'
  }

  if (!isNonNullish(data)) {
    return <Loading />
  }

  return (
    <AuthenticatedAppStack.Navigator
      initialRouteName={getInitialRouteName(
        data?.getMember?.onboardingComplete ?? false
      )}
    >
      <AuthenticatedAppStack.Screen
        name="OnboardingScreen"
        component={OnboardingScreen}
        initialParams={{
          onboardingQuestionStepIndex: 0
        }}
        options={{
          headerTitle: '',
          headerTitleStyle: {
            fontSize: THEME.font.fontSizeLarge,
            fontFamily: 'PublicSans_700Bold'
          },
          headerShadowVisible: false,
          headerTintColor: THEME.color.onSurface,
          headerStyle: {
            backgroundColor: THEME.color.background
          }
        }}
      />
      <AuthenticatedAppStack.Screen
        name="ClickThroughModuleScreen"
        component={ClickThroughModuleScreen}
        options={{
          headerTitle: '',
          headerTitleStyle: {
            fontSize: THEME.font.fontSizeLarge,
            fontFamily: 'PublicSans_700Bold'
          },
          headerShadowVisible: false,
          headerTintColor: THEME.color.onSurface,
          headerStyle: {
            backgroundColor: THEME.color.background
          }
        }}
      />
      <AuthenticatedAppStack.Screen
        name="MxMultiplexerScreen"
        component={MxMultiplexerScreen}
        options={{
          headerTitle: 'Link your accounts',
          headerTitleStyle: {
            fontSize: THEME.font.fontSizeLarge,
            fontFamily: 'PublicSans_700Bold'
          },
          headerTitleAlign: 'center',
          headerLeft: () => null,
          headerShadowVisible: false,
          headerTintColor: THEME.color.onSurface,
          headerStyle: {
            backgroundColor: THEME.color.background
          }
        }}
      />
      <AuthenticatedAppStack.Screen
        name="FeedbackScreen"
        component={FeedbackScreen}
        initialParams={{
          colorGradient: THEME.color.gradientA
        }}
        options={{
          headerTitle: 'Feedback',
          headerTitleStyle: {
            fontSize: THEME.font.fontSizeLarge,
            fontFamily: 'PublicSans_700Bold'
          },
          headerShadowVisible: false,
          headerTintColor: THEME.color.onSurface,
          headerStyle: {
            backgroundColor:
              THEME.color.gradientA.colors.length > 0
                ? THEME.color.gradientA.colors[0]
                : THEME.color.surfaceOne
          }
        }}
      />
      <AuthenticatedAppStack.Screen
        name="TabApp"
        component={TabApp}
        options={{
          headerShown: false
        }}
      />
    </AuthenticatedAppStack.Navigator>
  )
}

// Note: it appears there are some issues with wrapping React Natigation with this component- see
// https://github.com/eettaa/emb/pull/222#pullrequestreview-832019384.  It appears this works okay for right now- we
// just need this to have any way to interact with the Auth pool- but we will need to sort this out when we put the
// withAuthenticator HOC in its final place (as this will also likely be wrapping navigation).
//
// Note: Known warning that is coming from an error in the amplify codebase - could fix by building our own components,
// but that seems like more time than it's worth at this point: "Warning: A component is changing an uncontrolled input
// of type text to be controlled. Input elements should not switch from uncontrolled to controlled (or vice versa).
// Decide between using a controlled or uncontrolled input element for the lifetime of the component.
// More info: https://fb.me/react-controlled-components". Filed issue with amplify here:
// https://github.com/aws-amplify/amplify-js/issues/9445
//
// Note: resend code on confirm sign up page after sign up is disabled. Filed bug here:
// https://github.com/aws-amplify/amplify-js/issues/9447
export default withAuthenticator(
  AuthenticatedApp,
  {
    includeGreetings: false,
    usernameAttributes: 'email',
    signUpConfig
  },
  [],
  null,
  AuthTheme
)
