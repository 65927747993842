import { StyleSheet } from 'react-native'

export const savingsRateGameStyle = StyleSheet.create({
  // IMPORTANT: The width and height of the savings rate game view need to be 100% so that the animations
  // that occur with the game have the same coordinate system as the points in the jungle screen.
  savingsRateGameContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    // Render the savings rate game at zIndex -1 so that other modules on
    // the jungle screen open on top of it.
    zIndex: -1
  }
})
