import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const RefreshCircle = (props: SvgProps) => (
  <Svg
    width={'100%'}
    height={'100%'}
    viewBox={`0 0 33 33`}
    preserveAspectRatio="xMinYMin meet"
    fill="none"
    {...props}
  >
  <Path
  d="M12.478 16.667a4.19 4.19 0 1 0 4.19-4.19V9.332a7.333 7.333 0 1 1-7.333
  7.334H7.96l2.946-3.209 3.012 3.209h-1.44Z"
  fill="#fff"
  />
  </Svg>
)
export default RefreshCircle
