import { useLazyQuery, gql } from '@apollo/client'
import * as React from 'react'
import { Pressable, Text, View } from 'react-native'
import { THEME } from '../../../../constants'
import {
  buttonStyle,
  textStyle,
  viewStyle
} from '../../../../themes/global-styles.style'
import { useIsMounted } from '../../../../util/use-is-mounted'
import { loadToken } from '../../../auth/load-token'
import { welcomeBannerStyle } from './welcome-banner.style'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { personalizedSavingsRate } from '../../click-through-module/content/savings-rate'
import { isNonNullish } from 'global-utils'
import { getSavingsRate } from '../../../../util/calculations/savings-rate'

export function WelcomeBanner (props: {
  setSavingsRateQuestionIndex: React.Dispatch<
  React.SetStateAction<number | null>
  >
  onPressSpinWheelButton: () => void
}): JSX.Element {
  // Polling for this query is handled centrally by the app in order to not register repeated
  // polling requests.
  const [getFinancialView, { data }] = useLazyQuery<
  WalkthroughGraphQL.MemberLatestFinancialViewQuery,
  WalkthroughGraphQL.MemberLatestFinancialViewQueryVariables
  >(gql(WalkthroughGraphQL.memberLatestFinancialView))

  const isMounted = useIsMounted()

  React.useEffect(() => {
    // There are known race condititions with async code in useEffect (like what if the component unmounts before the
    // function returns?), but this is still the recommended approach. See
    // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    // and https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    async function getOwnerAndQuery (): Promise<void> {
      const owner = await loadToken()
      if (isMounted()) {
        void getFinancialView({ variables: { owner: owner } })
      }
    }
    getOwnerAndQuery().catch(console.log)
  }, [])

  const savingsRate = getSavingsRate(data)

  return (
    <>
      {isNonNullish(savingsRate) ? (
        <View style={welcomeBannerStyle.welcomeBanner}>
          {savingsRate > 0 ? (
            <Text>
              <Text style={[textStyle.regularText, textStyle.boldText]}>
                ✨ Spin the wheel to get your daily points! ✨ {'\n\n'}
              </Text>
              <Text style={textStyle.regularText}>
                {personalizedSavingsRate(data)}
                {'\n\n'}
              </Text>
              <Text style={textStyle.regularText}>
                The higher your savings rate, the more points you can win! Keep
                your monthly income and spending up to date to keep your savings
                rate accurate.
              </Text>
            </Text>
          ) : (
            <Text>
              <Text style={textStyle.regularText}>
                {personalizedSavingsRate(data)}
                {'\n\n'}
              </Text>
              <Text style={[textStyle.regularText, textStyle.boldText]}>
                Since you have a negative savings rate (
                {savingsRate.toLocaleString('en-US', {
                  style: 'percent',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
                ), you don't get many points 🥺.{'\n\n'}
              </Text>
              <Text style={textStyle.regularText}>
                You still get to spin the wheel though (spinning things is
                fun!), and you can use the 'Update savings rate' button below to
                keep your savings rate up to date. The higher your savings rate,
                the more points you can win!
              </Text>
            </Text>
          )}
          <Pressable
            style={[
              buttonStyle(THEME.color.superHighlight, THEME.color.outline)
                .smallButton,
              viewStyle.mediumTopMargin
            ]}
            onPress={props.onPressSpinWheelButton}
          >
            <Text style={[textStyle.regularText, textStyle.boldText]}>
              Spin the wheel
            </Text>
          </Pressable>
          <Pressable
            style={[
              buttonStyle(THEME.color.highlight, THEME.color.outline)
                .smallButton,
              viewStyle.smallTopMargin
            ]}
            onPress={() => props.setSavingsRateQuestionIndex(0)}
          >
            <Text style={[textStyle.regularText, textStyle.boldText]}>
              Update savings rate
            </Text>
          </Pressable>
        </View>
      ) : null}
    </>
  )
}
