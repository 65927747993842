import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../constants'

export const viewStyle = StyleSheet.create({
  smallTopMargin: {
    marginTop: THEME.spacing.verticalSpaceSmall
  },
  mediumTopMargin: {
    marginTop: THEME.spacing.verticalSpaceMedium
  },
  mediumRightMargin: {
    marginRight: THEME.spacing.horizontalSpaceMedium
  },
  mediumLeftMargin: {
    marginLeft: THEME.spacing.horizontalSpaceMedium
  },
  smallBottomMargin: {
    marginBottom: THEME.spacing.verticalSpaceSmall
  }
})

export const textStyle = StyleSheet.create({
  extraLargeText: {
    fontSize: THEME.font.fontSizeExtraLarge,
    fontFamily: 'PublicSans_400Regular',
    color: THEME.color.onSurface
  },
  largeText: {
    fontSize: THEME.font.fontSizeLarge,
    fontFamily: 'PublicSans_400Regular',
    color: THEME.color.onSurface
  },
  regularText: {
    fontSize: THEME.font.fontSizeMedium,
    fontFamily: 'PublicSans_400Regular',
    color: THEME.color.onSurface
  },
  smallText: {
    fontSize: THEME.font.fontSizeSmall,
    fontFamily: 'PublicSans_400Regular',
    color: THEME.color.onSurface
  },
  italicBoldText: {
    fontFamily: 'PublicSans_700Bold_Italic'
  },
  italicText: {
    fontFamily: 'PublicSans_400Regular_Italic'
  },
  boldText: {
    fontFamily: 'PublicSans_700Bold'
  },
  outlineColorText: {
    color: THEME.color.outline
  },
  underline: {
    textDecorationLine: 'underline'
  }
})

export const buttonStyle = (
  color: string,
  outlineColor: string
): {
  inlineButton: ViewStyle
  smallButton: ViewStyle
} =>
  StyleSheet.create({
    inlineButton: {
      backgroundColor: color,
      maxWidth: 'fit-content',
      height: THEME.buttons.buttonHeight,
      borderRadius: 20,
      borderColor: outlineColor,
      borderWidth: 2,
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: THEME.spacing.horizontalSpaceMedium
    },
    smallButton: {
      backgroundColor: color,
      width: THEME.buttons.smallButtonWidth,
      height: THEME.buttons.buttonHeight,
      borderRadius: 20,
      borderColor: outlineColor,
      borderWidth: 2,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center'
    }
  })
