import { isNonNullish } from 'global-utils'
import { format, parseISO } from 'date-fns'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { DrawerWithNodes } from '../common/drawers/drawers'
import { DrawerTitle } from '../common/drawers/drawer-title'
import { RowWithEdit } from '../common/drawers/row-with-edit'
import {
  buttonStyle,
  textStyle,
  viewStyle
} from '../../../themes/global-styles.style'
import { Pressable, Text, View } from 'react-native'
import { THEME } from '../../../constants'
import React from 'react'
import { Card } from '../common/card/card'
import { gql, ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { Auth } from 'aws-amplify'
import {
  YesOrNo,
  yesOrNoToDisplayText
} from '../common/questions/display-values/yes-or-no'
import { EditProfileSettingsQuestionKey } from './edit-profile-settings'
import { kIncompleteString } from '../common/questions/display-values/incomplete-message'
import { taxFilingStatusToDisplayText } from '../../../util/profile-parsing'

function stringOrIncomplete (s: string | null | undefined): string {
  return isNonNullish(s) ? s : kIncompleteString
}

const employmentStatusToDisplayText = new Map<
WalkthroughGraphQL.EmploymentStatus,
string
>([
  [WalkthroughGraphQL.EmploymentStatus.UNEMPLOYED, 'Unemployed'],
  [WalkthroughGraphQL.EmploymentStatus.EMPLOYED, 'Employed'],
  [WalkthroughGraphQL.EmploymentStatus.SELF_EMPLOYED, 'Self employed']
])

// For legacy reasons this is one giant function, but it could easily be split into per-row functions.  We do not anticipate the cost of that refactoring to increase with time, so we'll just leave as-is for now.
export const getSettingsDrawers = (
  email: string | null | undefined,
  state: string | null | undefined,
  zipcode: string | null | undefined,
  birthday: string | null | undefined,
  employmentStatus: WalkthroughGraphQL.EmploymentStatus | null | undefined,
  taxableIncome: number | null | undefined,
  monthlyTakeHomeIncome: number | null | undefined,
  monthlySpending: number | null | undefined,
  ableToPayBasicExpenses: boolean | null | undefined,
  hasOneMonthEmergencyFund: boolean | null | undefined,
  taxFilingStatus: WalkthroughGraphQL.TaxFilingStatus | null | undefined,
  spouseBirthday: string | null | undefined,
  promoCode: string | null | undefined,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  setProfileQuestionKeyToEdit: React.Dispatch<
  React.SetStateAction<EditProfileSettingsQuestionKey | null>
  >
): DrawerWithNodes[] => [
  {
    key: 'PROFILE_SETTING',
    title: DrawerTitle({ title: 'Profile' }),
    content: (
      <>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Email'}
              value={stringOrIncomplete(email)}
              isSubrow={false}
              allowMoreSpaceForValue={true}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Promo Code'}
              value={promoCode ?? 'None'}
              isSubrow={false}
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.PROMO_CODE_QUESTION
                )
              }
              allowMoreSpaceForValue={true}
            />
          </Card>
        </View>
        {/* TODO: add phone setting if we store phone number? Members fill it in on sign up but it's not
      in Auth.currentAuthenticatedUser().attributes.phoneNumber when I try to access it. */}
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'State'}
              value={stringOrIncomplete(state)}
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.STATE_AND_ZIP_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Zipcode'}
              value={stringOrIncomplete(zipcode)}
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.STATE_AND_ZIP_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Birthday'}
              value={
                isNonNullish(birthday)
                  ? format(parseISO(birthday), 'LL/dd/yyyy')
                  : kIncompleteString
              }
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.BIRTHDAY_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Employment status'}
              value={
                isNonNullish(employmentStatus)
                  ? employmentStatusToDisplayText.get(employmentStatus) ?? ''
                  : kIncompleteString
              }
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.EMPLOYMENT_STATUS_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Taxable income this year'}
              value={
                taxableIncome?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }) ?? kIncompleteString
              }
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.TAXABLE_INCOME_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Monthly take-home income'}
              value={
                monthlyTakeHomeIncome?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }) ?? kIncompleteString
              }
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.MONTHLY_TAKE_HOME_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Monthly spending'}
              value={
                monthlySpending?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }) ?? kIncompleteString
              }
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.MONTHLY_SPENDING_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Able to pay basic expenses?'}
              value={
                isNonNullish(ableToPayBasicExpenses)
                  ? ableToPayBasicExpenses
                    ? yesOrNoToDisplayText.get(YesOrNo.YES) ?? ''
                    : yesOrNoToDisplayText.get(YesOrNo.NO) ?? ''
                  : kIncompleteString
              }
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.BASIC_EXPENSES_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'One month emergency fund?'}
              value={
                isNonNullish(hasOneMonthEmergencyFund)
                  ? hasOneMonthEmergencyFund
                    ? yesOrNoToDisplayText.get(YesOrNo.YES) ?? ''
                    : yesOrNoToDisplayText.get(YesOrNo.NO) ?? ''
                  : kIncompleteString
              }
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.ONE_MONTH_EMERGENCY_FUND_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Tax filing status this year'}
              value={
                (isNonNullish(taxFilingStatus)
                  ? taxFilingStatusToDisplayText.get(taxFilingStatus)
                  : kIncompleteString) ?? kIncompleteString
              }
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.TAX_FILING_STATUS_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <View style={viewStyle.smallTopMargin}>
          <Card>
            <RowWithEdit
              title={'Spouse birthday'}
              value={
                isNonNullish(spouseBirthday)
                  ? format(parseISO(spouseBirthday), 'LL/dd/yyyy')
                  : '(optional)'
              }
              handleOnPressEdit={() =>
                setProfileQuestionKeyToEdit(
                  EditProfileSettingsQuestionKey.SPOUSE_BIRTHDAY_QUESTION
                )
              }
              isSubrow={false}
              allowMoreSpaceForValue={false}
            />
          </Card>
        </View>
        <Pressable
          style={[
            buttonStyle(THEME.color.highlight, THEME.color.onSurface)
              .smallButton,
            viewStyle.smallTopMargin
          ]}
          onPress={() => {
            async function signOut (): Promise<void> {
              try {
                await apolloClient.clearStore()
                await Auth.signOut()
              } catch (error) {
                console.log('error signing out: ', error)
              }
            }
            signOut().then(
              () => {},
              () => {}
            )
          }}
        >
          <Text style={[textStyle.regularText, textStyle.boldText]}>
            Sign out
          </Text>
        </Pressable>
      </>
    )
  },
  {
    // As of Feb 2023, this is the only place where the app imports accounts from our data provider.  We do not support
    // a way for the FE to trigger data provider <-> FI pulls (we rely on daily auto refreshes for now).
    key: 'MAGIC_ACCOUNT_SYNC',
    title: DrawerTitle({ title: 'Magical account sync button' }),
    content: (
      <>
        <Text style={textStyle.regularText}>
          Your account balances should update periodically.{'\n\n'}But maybe
          something is going wrong!{'\n\n'}If it is, you can try pushing this
          button too. It might make you feel better :){'\n\n'}
          Click once then go do something else- magic takes a little while to
          get right. If nothing happens in a few minutes, please tell us more in
          the feedback page and we'll get on the case.
        </Text>
        <Pressable
          style={({ pressed }) => {
            return {
              ...(pressed
                ? buttonStyle(
                  THEME.color.highlightPressed,
                  THEME.color.onSurface
                ).smallButton
                : buttonStyle(THEME.color.highlight, THEME.color.onSurface)
                  .smallButton),
              ...viewStyle.mediumTopMargin
            }
          }}
          onPress={() => {
            (async () => {
              return await apolloClient.mutate<WalkthroughGraphQL.ImportAccountsFromMxMutation>(
                {
                  mutation: gql(WalkthroughGraphQL.importAccountsFromMx)
                }
              )
            })().catch(console.log)
          }}
        >
          <Text style={[textStyle.regularText, textStyle.boldText]}>
            Fix things!
          </Text>
        </Pressable>
      </>
    )
  }
]
