import { StyleSheet } from 'react-native'
import { THEME } from '../../../constants'

export const accountDetailsStyle = StyleSheet.create({
  overlayContainer: {
    width: '100%',
    height: '100%',
    zIndex: 2,
    position: 'absolute',
    paddingHorizontal: THEME.spacing.horizontalSpaceMedium,
    paddingVertical: THEME.spacing.verticalSpaceMedium,
    backgroundColor: THEME.color.gradientB.colors[0]
  },
  rowContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  balanceLabel: {
    flex: 2,
    alignContent: 'flex-start'
  },
  balance: {
    flex: 1,
    alignContent: 'flex-end',
    textAlign: 'right'
  },
  updateLabel: {
    flex: 1,
    alignContent: 'flex-start'
  },
  lastUpdate: {
    flex: 2,
    alignContent: 'flex-end',
    textAlign: 'right'
  }
})
