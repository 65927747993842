import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg'

const Sailboat = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 414 896'
    fill='none'
    preserveAspectRatio='xMaxYMax slice'
    {...props}
  >
    <G clipPath='url(#a)' stroke='#fff' strokeWidth={2}>
      <Path
        d='M388 760c0-14.228-179.675.684-184.035-12-5.5-16 154-5.487 154-22.5 0-18.5-82.761-.261-82-17 .761-16.738 70.004-5.935 70.004-21 0-15.064-193.651-3.846-158.764-21.5'
        strokeLinecap='round'
      />
      <Path
        d='M150.5 653c1 20-90-4-90 16s45.5 4 45.5 21-81.5 3.5-80.5 19.5 178-13.001 182 6.999S94 711.5 95 731.5s70.5-3.001 70.5 14.999S-3 753-3 753'
        strokeLinecap='round'
      />
      <Path
        d='M172 653.525c0 5.746-4.506 10.35-10 10.35s-10-4.604-10-10.35c0-2.638.949-6.332 2.56-9.601.976-1.98 2.164-3.743 3.471-4.997 1.311-1.258 2.655-1.927 3.969-1.927 1.569 0 3.182.958 4.708 2.703 1.509 1.727 2.809 4.09 3.747 6.558.992 2.612 1.545 5.253 1.545 7.264ZM251 652.694c0 6.226-4.565 11.106-10 11.106s-10-4.88-10-11.106c0-2.84.956-6.798 2.571-10.294.979-2.119 2.172-4.006 3.483-5.348 1.318-1.35 2.656-2.052 3.946-2.052 1.542 0 3.151 1.008 4.687 2.882 1.515 1.849 2.82 4.378 3.761 7.02.995 2.794 1.552 5.626 1.552 7.792Z'
        fill='#fff'
      />
      <Path
        d='M222.323 645.814c23.667 2.887 55.677-18.85 55.677-18.85s-4.755-38.535-18.305-75.991c-13.651-37.736-36.4-75.053-43.267-101.973 1.479 21.978 10.153 89.7 10.153 123.214 0 23.706-.655 46-4.258 73.6Zm0 0S214.135 653.371 203 656'
        strokeLinecap='round'
      />
      <Path
        d='M142.329 549c12.913-23.823 65.407-78.024 74.671-100-2.526 21.33-6.246 65.667-6.246 98.631 0 29.373 3.343 68.985 5.589 80.342-7.486.462-34.959-5.225-47.996-5.225-16.844 0-32.112 3.656-41.75 5.225-2.807-27.146 4.588-58.414 15.732-78.973Z'
        strokeLinecap='round'
      />
      <Path
        d='M207.385 669c-59.313 0-81.207 0-79.268-20 21.342 5.676 58.758 6.237 79.268 5.946 18.189-.258 36.862 0 72.615-5.946-5.265 9.189-21.063 20-72.615 20Z'
        fill='#343751'
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M0 0h414v896H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default Sailboat
