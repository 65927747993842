import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg'

const HotAirBalloon = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 414 896'
    fill='none'
    preserveAspectRatio='xMaxYMax slice'
    {...props}
  >
    <G clipPath='url(#a)' stroke='#fff' strokeWidth={2}>
      <Path d='M247.828 527.126c33.256 0 60.218 27.099 60.218 60.218 0 16.931-11.279 36.786-24.632 52.502-6.655 7.834-13.776 14.58-20.163 19.358-3.194 2.389-6.188 4.274-8.835 5.556-2.665 1.292-4.896 1.934-6.588 1.934-4.193 0-10.062-2.4-16.603-6.805-6.5-4.377-13.524-10.639-20.001-18.145-12.975-15.036-23.615-34.899-23.615-54.4 0-11.601 3.09-22.242 8.46-31.244a58.935 58.935 0 0 1 16.374-17.775c9.929-7.093 22.154-11.199 35.385-11.199Z' />
      <Path
        d='M240.415 682.641c.988-1.894 2.193-3.582 3.519-4.784 1.329-1.204 2.705-1.857 4.066-1.857 1.625 0 3.272.935 4.815 2.601 1.531 1.654 2.849 3.916 3.799 6.277.515 1.28.915 2.572 1.182 3.789h-19.592c.426-1.943 1.189-4.069 2.211-6.026Z'
        fill='#fff'
      />
      <Path d='m263.898 658.511 6.122 33.67M226.785 691.033l5.357-30.226' />
      <Path
        d='M436.999 714.098c0-36.731-17.126-94.219-44.128-47.934C370.326 704.809 380.501 771 360.363 771c-16.859 0-6.401-44.766-59.775-44.766-23.193 0-74.096 40.175-77.383 14.922-2.965-22.778-49.513 10.331-44.915-14.922 5.114-28.092 51.638 2.481 58.21-17.234 2.035-6.104-4.744-7.798-7.338-8.218-.641-.103-1.282-.209-1.895-.426-1.615-.571-4.822-1.935-5.328-3.895-.759-2.942 1.466-5.809 4.509-5.809h39.544c4.926 0 8.573 4.573 7.469 9.366l-3.171 13.774a7.66 7.66 0 0 1-7.469 5.938h-29.05c-2.766 0-5.806-3.665-6.878-5.082a7.206 7.206 0 0 1-.681-1.096c-4.584-8.807-65.119-120.559-161.212-71.551-61.508 31.369 7.27-80.566 45-103 37-22 54 20.133 31 37C96 609 17 451-3 518'
        strokeLinecap='round'
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M0 0h414v896H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default HotAirBalloon
