import * as React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { TaskScreen } from './task-screen'
import { THEME } from '../../../constants'
import { TaskListScreen } from './task-list-screen'

// See comments in https://github.com/eettaa/emb/blob/dev/src/app/frontend/screens/authenticated/common/types/generic-composite-navigation-prop.ts
// for justification of this template type.
const TaskStack = createNativeStackNavigator<{
  [x: string]: any
}>()

function TaskStackScreen (): JSX.Element {
  const taskListHeaderColor =
    THEME.color.gradientA.colors.length > 0
      ? THEME.color.gradientA.colors[0]
      : THEME.color.surfaceOne

  return (
    <TaskStack.Navigator>
      <TaskStack.Screen
        name="TaskScreen"
        component={TaskScreen}
        initialParams={{}}
        options={{
          headerTitle: '',
          headerTitleStyle: {
            fontSize: THEME.font.fontSizeLarge,
            fontFamily: 'PublicSans_700Bold'
          },
          headerShadowVisible: false,
          headerTintColor: THEME.color.onSurface,
          headerLeft: () => null, // Disable back button
          headerStyle: {
            backgroundColor: THEME.color.background
          }
        }}
      />
      <TaskStack.Screen
        name="TaskListScreen"
        component={TaskListScreen}
        options={{
          headerTitle: 'Tasks',
          headerTitleStyle: {
            fontSize: THEME.font.fontSizeLarge,
            fontFamily: 'PublicSans_700Bold'
          },
          headerShadowVisible: false,
          headerTintColor: THEME.color.onSurface,
          headerLeft: () => null, // Disable back button
          headerStyle: {
            backgroundColor: taskListHeaderColor
          }
        }}
      />
    </TaskStack.Navigator>
  )
}

export { TaskStackScreen }
