import { WhyThisTaskHandler } from './get-why-this-task'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { Text } from 'react-native'
import { isNonNullish } from 'global-utils'
import { getTasksAsSentenceFragment } from './get-tasks-as-sentence-fragments'

export function extendEmergencyFundToThreeMonthsWhyThisTaskHandler (
  data: WalkthroughGraphQL.GetMemberQuery,
  taskTypename: string,
  contributionYear: number,
  minDebtInterestRate: number
): WhyThisTaskHandler<WalkthroughGraphQL.ExtendEmergencyFundToThreeMonthsTask> {
  return (task) => {
    if (
      isNonNullish(task) &&
      isNonNullish(task.__typename) &&
      task.__typename === taskTypename
    ) {
      const alternativeTasksSentenceFragment = getTasksAsSentenceFragment(
        data,
        new Set(['payOffDebtTasks', 'contributeTo401kTasks']),
        /* includeIfComplete = */ true,
        /* includeIfIncomplete = */ false,
        contributionYear,
        minDebtInterestRate,
        'and'
      )
      return (
        <Text>
          Growing your emergency fund is the right balance between risk and
          opportunity. You've already tackled the best opportunities you had
          {isNonNullish(alternativeTasksSentenceFragment) ? (
            <Text> — {alternativeTasksSentenceFragment} — </Text>
          ) : (
            <Text> </Text>
          )}
          so it makes sense to add some safety cushion now.
        </Text>
      )
    }
    return null
  }
}
