import * as React from "react"
import Svg, { SvgProps, Path, Ellipse } from "react-native-svg"

const YuyuBagOfLeaves = (props: SvgProps): JSX.Element => (
  <Svg
    width={130}
    height={110}
    fill="none"
    {...props}
  >
    <Path
      d="M39 88.493h-.894c-5.067.001-9.847.003-14.21-.331-4.62-.354-8.696-1.079-12.078-2.534-3.359-1.445-6.032-3.609-7.883-6.876C2.073 75.466 1 70.985 1 64.84 1 52.58 5.27 43.405 12.09 37.284 18.929 31.148 28.423 28 39 28s20.072 3.148 26.91 9.284C72.73 43.404 77 52.58 77 64.84c0 6.145-1.073 10.626-2.935 13.912-1.85 3.267-4.524 5.431-7.883 6.876-3.382 1.455-7.458 2.18-12.078 2.534-4.363.334-9.143.332-14.21.331H39Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={26.382} cy={69.507} rx={2.294} ry={2.293} fill="#505050" />
    <Ellipse cx={52.765} cy={69.507} rx={2.294} ry={2.293} fill="#505050" />
    <Path
      d="M35.559 74.093c.934.865 3.62 2.077 6.882 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M44.162 36.827c-5.506 5.045-10.706 2.102-12.618 0C26.497 32.699 32.882 12.556 36.706 3c4.78 9.173 12.962 28.781 7.456 33.827ZM55.002 35.555c-2.632 1.21-4.203-.492-4.66-1.495-1.374-2.123 3.373-8.805 5.919-11.88.677 3.954 1.373 12.164-1.259 13.375ZM20.748 35.555c2.632 1.21 4.203-.492 4.66-1.495 1.374-2.123-3.373-8.805-5.919-11.88-.677 3.954-1.373 12.164 1.259 13.375Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M96 59c-11.5-1.643-23-7.5-26.5-3 1.833 2.333 10.8 12.8 12 16 1.5 4 15.5 1 19 1s23.126-12.784 20-14c-9-3.5-13 1.643-24.5 0Z"
      fill="#9B735C"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M87.732 61.627c.333-1.433 1.142-1.73 1.336-1.614.154.139.48 1.154-.303 2.125-.814 1.01.852 2.174 1.418 2.45 1.753.857 4.622 0 5.831-3.528.947-2.764.007-7.431-3.751-10.82-4.64.373-8.28 3.149-9.683 6.56-.751 1.83-.788 3.128.556 4.82 1.19 1.497 4.182 1.792 4.596.007Z"
      fill="#53D28B"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M86.844 57.927c-1.014 1.065-1.862.91-1.97.712-.063-.198.173-1.238 1.34-1.677 1.215-.457.37-2.305.024-2.832-1.074-1.628-3.982-2.346-6.815.078-2.22 1.899-3.781 6.397-2.267 11.225 4.187 2.036 8.73 1.494 11.673-.732 1.577-1.192 2.268-2.293 1.97-4.432-.264-1.895-2.69-3.669-3.955-2.342Z"
      fill="#53D28B"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M93.37 67.311c.49-1.386 1.328-1.59 1.508-1.454.138.155.347 1.201-.54 2.078-.921.913.604 2.256 1.135 2.594 1.645 1.048 4.593.517 6.19-2.852 1.251-2.64.84-7.383-2.514-11.172-4.653-.15-8.58 2.2-10.357 5.433-.953 1.732-1.134 3.019.011 4.85 1.014 1.622 3.955 2.25 4.567.523Z"
      fill="#53D28B"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M104.727 60.978c1.095-.981 1.928-.76 2.021-.553.046.202-.271 1.22-1.469 1.566-1.246.359-.551 2.268-.247 2.82.943 1.708 3.784 2.654 6.8.46 2.363-1.718 4.274-6.078 3.145-11.01-4.013-2.36-8.585-2.179-11.694-.192-1.666 1.064-2.442 2.107-2.314 4.263.114 1.91 2.394 3.87 3.758 2.646Z"
      fill="#53D28B"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M100.4 57.173c.699 1.293.29 2.051.068 2.094-.207-.002-1.124-.548-1.18-1.794-.06-1.295-2.078-1.064-2.686-.897-1.88.519-3.462 3.062-2.032 6.506 1.12 2.698 4.915 5.572 9.975 5.623 3.23-3.352 4.119-7.841 2.911-11.328-.647-1.867-1.48-2.865-3.607-3.242-1.883-.335-4.32 1.426-3.449 3.038Z"
      fill="#53D28B"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="M69.413 92.443C63.508 77.391 69.413 66 76.02 59.5c1.107 1.022 11.076 13.508 20.5 12 12.5-2 20.325-9.478 22-10.5 8.797 8.855 10.588 18.287 5.561 31.443-6.284 16.445-16.966 16.445-25.135 16.445-13.928 0-23.428-.888-29.532-16.445Z"
      fill="#D3A286"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M76.242 57.2C77.35 58.22 86 68.88 96.5 67.2c12.5-2 20.567-7.479 22.242-8.5 2.666 0 6.981 2.5 8.981 4.5 2.5 2.5-.5 4.5-2.5 7.5-1.905 5.712-9.88 10.149-19.432 11.055-.334 1.592-4.676.435-8.232-.11A37.662 37.662 0 0 1 96.5 81.5c-4 2.167-9 2-9-1 0-.583.118-1.03.332-1.36-6.898-2.707-12.353-6.88-13.61-9.94-2.001-3.5-7 .5-8-2s1.518-11 4.518-12c2.4-.8 4.668 1 5.502 2Z"
      fill="#D3A286"
    />
    <Path
      d="M74.223 69.2c-2.002-3.5-7 .5-8-2s1.517-11 4.517-12c2.4-.8 4.668 1 5.502 2C77.35 58.22 86 68.88 96.5 67.2c12.5-2 20.567-7.479 22.242-8.5 2.666 0 6.981 2.5 8.981 4.5 2.5 2.5-.5 4.5-2.5 7.5m-51-1.5c2.002 3.5 4.017 4 8.017 4.5 4 .5 8-3.5 10.5 0s12 5 14.5 3 3.498-7.5 7.5-6.5 8.483 3.5 10.483.5m-51-1.5c1.752 4.266 11.67 10.7 22.277 12.3m28.723-10.8c-2.5 7.5-15.463 12.8-28.723 10.8m0 0c-4-2.833-9-4.8-9-1 0 3 5 3.167 9 1Zm0 0c2.827-2.5 7.192-4.6 9-1 1.808 3.6-4.667 1.5-9 1Zm0 0c-.08 2-.592 6.4-2 8m2-8c1 2.167 3.5 6.5 5.5 6.5"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default YuyuBagOfLeaves
