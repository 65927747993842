import * as WalkthroughGraphQL from 'amplify-client-graphql'
import {
  AnswerType,
  ConditionType,
  QuestionAndAnswers
} from '../question-data'
import { gql } from '@apollo/client'
import { loadToken } from '../../../../auth/load-token'
import { stringInStringEnum, isNonNullish } from 'global-utils'
import { YesOrNo, yesOrNoToDisplayText } from '../display-values/yes-or-no'
import { stringIsCapitalLettersOrNumbersAndLessThan31Chars } from '../../../../../util/input-validators'

export const promoCodeExists: QuestionAndAnswers = {
  key: 'DO_YOU_HAVE_PROMO_CODE_QUESTION',
  question: {
    text: 'Got a promo code for Walkthrough? It probably comes with something good 😇'
  },
  answers: [
    {
      key: 'DO_YOU_HAVE_PROMO_CODE_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        return isNonNullish(response.data.getMember?.promoCode)
          ? YesOrNo.YES
          : YesOrNo.NO
      },
      // Wipe the field if the user answers "No"
      storeValue: async (client, value) => {
        if (stringInStringEnum(YesOrNo, value) && value === YesOrNo.NO) {
          await client.mutate<
          WalkthroughGraphQL.UpdateMemberMutation,
          WalkthroughGraphQL.UpdateMemberMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updateMember),
            variables: {
              input: {
                owner: await loadToken(),
                promoCode: null
              }
            }
          })
        }
      },
      validation: {
        isValid: (answer: string) => stringInStringEnum(YesOrNo, answer),
        notValidInfoText: 'Answer must be Yes or No.'
      },
      answerType: AnswerType.SELECTION,
      selectionAnswer: {
        items: Array.from(yesOrNoToDisplayText).map(([key, value]) => {
          return { enumValue: key, value, displayText: value }
        })
      }
    }
  ]
}

export const promoCodeValue: QuestionAndAnswers = {
  key: 'WHAT_IS_YOUR_PROMO_CODE_QUESTION',
  question: {
    text: "What's the code?"
  },
  answers: [
    {
      key: 'WHAT_IS_YOUR_PROMO_CODE_ANSWER',
      getInitialAnswer: async (client) => {
        const response = await client.query<
        WalkthroughGraphQL.MemberOnboardingDataQuery,
        WalkthroughGraphQL.MemberOnboardingDataQueryVariables
        >({
          query: gql(WalkthroughGraphQL.memberOnboardingData),
          variables: {
            owner: await loadToken()
          }
        })
        return response.data.getMember?.promoCode ?? ''
      },
      storeValue: async (client, value) => {
        if (stringIsCapitalLettersOrNumbersAndLessThan31Chars(value)) {
          await client.mutate<
          WalkthroughGraphQL.UpdateMemberMutation,
          WalkthroughGraphQL.UpdateMemberMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updateMember),
            variables: {
              input: {
                owner: await loadToken(),
                promoCode: value
              }
            }
          })
        } else {
          console.log(
            "Provided value ('" +
              value +
              "') is not capital letters/numbers or is too long"
          )
        }
      },
      validation: {
        isValid: (answer: string) =>
          stringIsCapitalLettersOrNumbersAndLessThan31Chars(answer),
        notValidInfoText: 'Answer must be capital letters and numbers.'
      },
      answerType: AnswerType.TEXT,
      textAnswer: {
        placeholderText: 'SECRETCODE'
      }
    }
  ],
  condition: {
    conditionType: ConditionType.MATCH_ANSWER,
    matchAnswerCondition: {
      answerKeyToMatch: 'DO_YOU_HAVE_PROMO_CODE_ANSWER',
      answersToMatch: [YesOrNo.YES]
    }
  }
}
