import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const rowWithEditStyle = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 'fit-content'
  },
  subrowContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 'fit-content',
    marginTop: THEME.spacing.verticalSpaceSmall
  },
  subrowTitleContainer: {
    flex: 12,
    paddingLeft: THEME.spacing.horizontalSpaceMedium
  },
  titleContainer: {
    flex: 12
  },
  allowLessSpaceForTitle: {
    flex: 7
  },
  valueContainer: {
    flex: 7,
    alignContent: 'flex-end',
    textAlign: 'right'
  },
  allowMoreSpaceForValue: {
    flex: 12,
    alignContent: 'flex-end',
    textAlign: 'right'
  },
  editIconContainer: {
    flex: 1,
    alignItems: 'center',
    paddingLeft: THEME.spacing.horizontalSpaceMedium,
    paddingTop: 3
  }
})
