import * as React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { THEME } from '../../../constants'
import { AccountsScreen } from './accounts-screen'

// See comments in https://github.com/eettaa/emb/blob/dev/src/app/frontend/screens/authenticated/common/types/generic-composite-navigation-prop.ts
// for justification of this template type.
const AccountsStack = createNativeStackNavigator<{
  [x: string]: any
}>()

function AccountsStackScreen (): JSX.Element {
  const accountsHeaderColor =
    THEME.color.gradientB.colors.length > 0
      ? THEME.color.gradientB.colors[0]
      : THEME.color.surfaceOne

  return (
    <AccountsStack.Navigator>
      <AccountsStack.Screen
        name="AccountsScreen"
        component={AccountsScreen}
        initialParams={{
          initiallyShowWelcomeBanner: false
        }}
        options={{
          headerTitle: 'Accounts',
          headerTitleStyle: {
            fontSize: THEME.font.fontSizeLarge,
            fontFamily: 'PublicSans_700Bold'
          },
          headerShadowVisible: false,
          headerTintColor: THEME.color.onSurface,
          headerStyle: {
            backgroundColor: accountsHeaderColor
          }
        }}
      />
    </AccountsStack.Navigator>
  )
}

export { AccountsStackScreen }
