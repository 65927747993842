import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const finalBannerStyle = StyleSheet.create({
  finalBanner: {
    width: '100%',
    padding: THEME.spacing.horizontalSpaceMedium,
    backgroundColor: THEME.color.rainbow.darkBlue,
    borderColor: THEME.color.outline,
    borderBottomWidth: 2,
    borderTopWidth: 2,
    marginTop: THEME.spacing.verticalSpaceLarge * 8
  }
})
