import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish } from 'global-utils'
import { Text } from 'react-native'
import { FadeElementGroup } from '../../determine-next-task/fade-element-groups'
import { taxFilingStatusToDisplayText } from '../../../../../util/profile-parsing'
import YuyuBank from '../../../../../assets/yuyu/bank'

export function getTaxFilingStatusIncomeElementGroup (
  data: WalkthroughGraphQL.GetMemberQuery
): FadeElementGroup {
  const taxFilingStatus = data.getMember?.taxFilingStatus
  const taxableIncome = data.getMember?.estimatedTaxableIncome
  return {
    key: 'TAX_FILING_STATUS_INCOME',
    elements: [
      {
        key: 'TAX_FILING_STATUS_INCOME_LINE',
        element: (
          <Text>
            Determining task eligibility using your{' '}
            {isNonNullish(taxFilingStatus)
              ? taxFilingStatusToDisplayText.get(taxFilingStatus)
              : ''}{' '}
            tax filing status and yearly income of{' '}
            {taxableIncome?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) ?? '$?'}
            ...
          </Text>
        ),
        preFadeInDelay: 0
      }
    ],
    image: <YuyuBank />
  }
}
