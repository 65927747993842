import * as React from 'react'
import { Pressable } from 'react-native'
import Back from '../../../assets/back'
import { THEME } from '../../../constants'
import { backButtonStyle } from './back-button.style'

export function BackButton (props: {
  handleOnPress: () => void
  color?: string
}): JSX.Element {
  const style = backButtonStyle(THEME)

  return (
    <Pressable style={style.backBox} onPress={props.handleOnPress}>
      <Back color={props.color ?? '#fff'} />
    </Pressable>
  )
}
