import * as React from 'react'
import { accountNameWithBalanceFallback } from '../../../../../util/account-parsing'
import { AnswerType, QuestionAndAnswers } from '../question-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { AccountWithSnapshotShape } from 'multi-type-processor'
import { stringInStringEnum } from 'global-utils'
import { YesOrNo, yesOrNoToDisplayText } from '../display-values/yes-or-no'
import {
  purgeWalkthroughManualAccount,
  purgeYodleeAccount
} from '../../../../../api-calls/accounts'

export const kDeleteSingleAccountQuestionKey = 'DELETE_SINGLE_ACCOUNT_QUESTION'

export const deleteSingleAccountQuestion: (
  account: Omit<WalkthroughGraphQL.Account, '__typename'> & {
    __typename: string
  } & AccountWithSnapshotShape
) => QuestionAndAnswers = (account) => {
  return {
    key: kDeleteSingleAccountQuestionKey,
    question: {
      text: (
        <>
          Do you want to fully delete your{' '}
          {accountNameWithBalanceFallback(account)} account?
          {'\n\n'}(Select 'No' or click outside this question to exit without
          deleting).
        </>
      )
    },
    answers: [
      {
        key: 'ONLY_ANSWER',
        answerType: AnswerType.SELECTION,
        getInitialAnswer: async () => {
          return YesOrNo.NO
        }, // No by default
        validation: {
          isValid: (answer: string) => stringInStringEnum(YesOrNo, answer),
          notValidInfoText: 'Answer must be Yes or No.'
        },
        selectionAnswer: {
          items: Array.from(yesOrNoToDisplayText).map(([key, value]) => {
            return { enumValue: key, value, displayText: value }
          })
        },
        storeValue: async (client, value) => {
          if (!stringInStringEnum(YesOrNo, value)) {
            console.log("Provided value ('" + value + "') is not Yes or No.")
            return
          }
          if (value === YesOrNo.YES) {
            if (
              account.dataProvider === WalkthroughGraphQL.DataProvider.YODLEE
            ) {
              await purgeYodleeAccount(client, account)
            } else if (
              account.dataProvider ===
              WalkthroughGraphQL.DataProvider.WALKTHROUGH_MANUALLY_DECLARED
            ) {
              await purgeWalkthroughManualAccount(client, account)
            } else {
              throw new Error(
                'Account had unexpected data provider: ' +
                  account.dataProvider +
                  '. Expected YODLEE or WALKTHROUGH_MANUALLY_DECLARED.'
              )
            }
          }
        }
      }
    ]
  }
}
