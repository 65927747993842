import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const fadeElementGroupsStyle = StyleSheet.create({
  fadeInElementBox: {
    paddingBottom: THEME.spacing.verticalSpaceSmall
  },
  elementGroupBox: {
    width: '100%'
  },
  imageBox: {
    padding: THEME.spacing.verticalSpaceSmall,
    alignItems: 'center'
  }
})
