import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

function ArrowCircleFilled (props: SvgProps): JSX.Element {
  return (
    <Svg
      width={31}
      height={31}
      fill='none'
      {...props}
    >
      <Circle
        cx={15.5}
        cy={15.5}
        r={14.5}
        fill='#22BEAB'
        stroke='#fff'
        strokeWidth={2}
      />
      <Path
        d='M7.44 13.88a1 1 0 100 2v-2zm16.827 1.707a1 1 0 000-1.414l-6.364-6.364a1 1 0 10-1.415 1.414l5.657 5.657-5.657 5.657a1 1 0 001.415 1.414l6.364-6.364zm-16.828.293h16.12v-2H7.44v2z'
        fill='#fff'
      />
    </Svg>
  )
}

export default ArrowCircleFilled
