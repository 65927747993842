import { NIGHT_THEME } from './themes/night-theme'

/// ///////////////////////////////////////////////////////////////////////////////////////////////
// Constants for design theme.
/// ///////////////////////////////////////////////////////////////////////////////////////////////

const THEME = NIGHT_THEME

/// ///////////////////////////////////////////////////////////////////////////////////////////////
// Constants for storing navigation screen state in AsyncStorage. We store the name of the App
// stack screen and the AuthenticatedApp stack screen the member should land on when they return
// to the app. These values must exactly correspond to the screen names as defined in the relevant
// NavigationContainers.
/// ///////////////////////////////////////////////////////////////////////////////////////////////

// Key for saved App screen to land on.
const APP_SCREEN_STATE_KEY = 'APP_SCREEN_STATE'
// Allowed screens for saved App screen. Used to confirm that the saved string is an allowed
// screen name before using it.
const ALLOWED_APP_SCREEN_STATES = new Set<String>([
  'Welcome',
  'AuthenticatedApp'
])

export { THEME, APP_SCREEN_STATE_KEY, ALLOWED_APP_SCREEN_STATES }
