import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const accountRowStyle = StyleSheet.create({
  mainInfoContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 'fit-content'
  },
  dataRowsContainer: {
    flex: 19,
    flexDirection: 'column'
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 'fit-content'
  },
  titleContainer: {
    flex: 12,
    alignContent: 'flex-start'
  },
  valueContainer: {
    flex: 7,
    alignContent: 'flex-end',
    textAlign: 'right'
  },
  iconContainer: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
    paddingLeft: THEME.spacing.horizontalSpaceMedium
  },
  lastUpdateContainer: {
    flex: 1,
    alignContent: 'flex-end',
    textAlign: 'right'
  }
})
