import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const spinningWheelStyle = StyleSheet.create({
  spinningWheel: {
    position: 'relative',
    width: 238, // width of the spinning wheel SVG
    height: 238, // height of the spinning wheel SVG
    alignSelf: 'center',
    marginTop: THEME.spacing.verticalSpaceLarge * 8
  },
  startSpinButton: {
    position: 'absolute',
    top: (238 - 60) / 2, // height of spinning well - height of start spin button
    width: 60,
    height: 60,
    borderRadius: 30,
    borderColor: THEME.color.outline,
    borderWidth: 2,
    backgroundColor: THEME.color.superHighlight,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  spinningWheelComponents: {
    position: 'absolute',
    top: 0
  },
  numberZero: {
    position: 'absolute',
    width: 60,
    top: 20,
    right: 55,
    textAlign: 'center'
  },
  numberOne: {
    position: 'absolute',
    width: 60,
    top: 72,
    right: 8,
    textAlign: 'center'
  },
  numberTwo: {
    position: 'absolute',
    width: 60,
    bottom: 72,
    right: 8,
    textAlign: 'center'
  },
  numberThree: {
    position: 'absolute',
    width: 60,
    bottom: 20,
    right: 55,
    textAlign: 'center'
  },
  numberFour: {
    position: 'absolute',
    width: 60,
    bottom: 20,
    left: 55,
    textAlign: 'center'
  },
  numberFive: {
    position: 'absolute',
    width: 60,
    bottom: 72,
    left: 8,
    textAlign: 'center'
  },
  numberSix: {
    position: 'absolute',
    width: 60,
    top: 72,
    left: 8,
    textAlign: 'center'
  },
  numberSeven: {
    position: 'absolute',
    width: 60,
    top: 20,
    left: 55,
    textAlign: 'center'
  }
})
