import * as React from 'react'
import { Pressable, View } from 'react-native'
import { isNonNullish } from 'global-utils'
import { questionOverlayStyle } from './question-overlay.style'
import { GoalBar } from '../goal-bar/goal-bar'
import { ScrollWithArrow } from '../scroll/scroll-with-arrow'
import { kDeleteSingleAccountQuestionKey } from './content/delete-single-account'
import { QuestionAndAnswers } from './question-data'
import { Questions } from './questions'
import { kDeleteMxFinancialInsitutionUserQuestionKey } from './content/delete-mx-financial-institution-user'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// Shared question overlay element, that can be used to show one question or multiple questions linked
// with "Next" buttons.
export function QuestionOverlay (props: {
  questionIndex: number
  setQuestionIndex: React.Dispatch<React.SetStateAction<number | null>>
  questions: QuestionAndAnswers[][]
  onFinalSaveState?: () => void; // Called after the final question is stored
}): JSX.Element {
  const accountQuestionAlertProgressPercent = isNonNullish(props.questionIndex)
    ? Math.round(((props.questionIndex + 1) / props.questions.length) * 100)
    : 0

  const insets = useSafeAreaInsets()

  return (
    <View
      style={[
        questionOverlayStyle.overlayContainer,
        {
          // Padding to handle safe area for the question overlay.
          // We don't need to use insets.bottom because this component is always used on screens with tab navigation at
          // the bottom.
          // This component is sometimes used on screens without a header, so we need to use insets.top, even though
          // it isn't always needed. Some extra padding at the top looks reasonable in the cases where there is a header
          // and the padding is not needed.
          paddingTop: insets.top,
          paddingLeft: insets.left,
          paddingRight: insets.right
        }
      ]}
    >
      <Pressable
        style={questionOverlayStyle.overlay}
        onPress={() => {
          props.setQuestionIndex(null)
        }}
      />
      <View style={questionOverlayStyle.questionContainer}>
        {props.questions.length > 1 ? (
          <GoalBar goalProgressPercent={accountQuestionAlertProgressPercent} />
        ) : null}
        <View style={questionOverlayStyle.questionBox}>
          <ScrollWithArrow>
            <Questions
              questions={props.questions[props.questionIndex] ?? []}
              buttonText={(() => {
                if (
                  props.questions[props.questionIndex].length === 1 &&
                  (props.questions[props.questionIndex][0].key ===
                    kDeleteSingleAccountQuestionKey ||
                    props.questions[props.questionIndex][0].key ===
                      kDeleteMxFinancialInsitutionUserQuestionKey)
                ) {
                  return 'Confirm'
                } else if (props.questionIndex === props.questions.length - 1) {
                  return 'Done'
                } else {
                  return 'Next'
                }
              })()}
              onSaveState={() => {
                if (props.questionIndex >= props.questions.length - 1) {
                  props.setQuestionIndex(null)
                  props.onFinalSaveState?.()
                } else {
                  props.setQuestionIndex(props.questionIndex + 1)
                }
              }}
            />
          </ScrollWithArrow>
        </View>
      </View>
    </View>
  )
}
