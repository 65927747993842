import {
  validateAccountForHandler,
  InputAccountTypesMemberLatestFinancialViewQuery
} from 'multi-type-processor'
import { isNonNullish } from 'global-utils'
import {
  accountBalanceRow,
  AccountRowConverter
} from './get-account-collections'
import { apyQuestion } from '../../common/questions/content/apy-question'
import { interestInactiveQuestion } from '../../common/questions/content/interest-inactive-question'
import {
  getDebtInterestString,
  getInterestBeingCharged
} from '../../../../util/account-parsing'
import { AccountSettingData } from '../account-data'
import { AccountQuestionHooks } from '../accounts-screen'
import { kIncompleteString } from '../../common/questions/display-values/incomplete-message'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { yesOrNoToDisplayText } from '../../common/questions/display-values/yes-or-no'

function getDebtAccountSettings (
  account: InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts'],
  accountQuestionHooks: AccountQuestionHooks
): AccountSettingData[] {
  const interest = getDebtInterestString(account)
  const interestBeingCharged = getInterestBeingCharged(account)
  let interestBeingChargedDisplayString = kIncompleteString
  if (isNonNullish(interestBeingCharged)) {
    interestBeingChargedDisplayString =
      yesOrNoToDisplayText.get(interestBeingCharged) ?? ''
  }
  const settings: AccountSettingData[] = []
  settings.push({
    key: 'DEBT_ACCOUNT_INTEREST',
    title: 'Interest',
    value: interest ?? kIncompleteString,
    handleOnPressEdit: () => {
      accountQuestionHooks.setAccountQuestions([[apyQuestion(account)]])
      accountQuestionHooks.setAccountQuestionIndex(0)
    }
  })
  settings.push({
    key: 'DEBT_ACCOUNT_INTEREST_INACTIVE',
    title: 'Is interest being charged on this account?',
    value: interestBeingChargedDisplayString,
    handleOnPressEdit: () => {
      accountQuestionHooks.setAccountQuestions([
        [interestInactiveQuestion(account)]
      ])
      accountQuestionHooks.setAccountQuestionIndex(0)
    }
  })
  return settings
}

export function debtAccountBalanceRowHandler (
  accountQuestionHooks: AccountQuestionHooks,
  shouldShowWelcomeBanner: boolean,
  memberQuery:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
): AccountRowConverter<
  InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
  > {
  return (account) => {
    try {
      // This if block allows us to assume that account is nonullable further down
      if (!validateAccountForHandler(account, 'debtAccountHandler')) {
        return null
      }
    } catch (e) {
      return null
    }

    const rowAndBalance = accountBalanceRow<
    InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
    >(
      accountQuestionHooks,
      shouldShowWelcomeBanner,
      memberQuery
    )(account)

    if (!isNonNullish(rowAndBalance)) {
      return null
    }
    rowAndBalance.account.accountSettings = getDebtAccountSettings(
      account,
      accountQuestionHooks
    )
    return rowAndBalance
  }
}
