import { StyleSheet } from 'react-native'
import { THEME } from '../../../../constants'

export const multiplexerStyle = StyleSheet.create({
  background: {
    flex: 1
  },
  instanceCardsContainer: {
    backgroundColor: THEME.color.background,
    flex: 1,
    padding: THEME.spacing.horizontalSpaceMedium
  },
  singleRowContainer: { flexDirection: 'row', alignItems: 'center', flex: 1 },
  cardContents: {
    flex: 1,
    flexDirection: 'row'
  },
  cardContentsBeforeArrow: { flex: 19, flexDirection: 'row' },
  cardIconContainer: {
    justifyContent: 'center',
    height: 33,
    width: 33
  },
  cardTextContainer: {
    marginLeft: THEME.spacing.horizontalSpaceSmall,
    flex: 1
  },
  arrowContainer: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
    paddingLeft: THEME.spacing.horizontalSpaceMedium
  },
  buttonBottomMargin: {
    marginBottom: THEME.spacing.verticalSpaceExtraSmall
  },
  instance: {
    position: 'absolute',
    height: '100%',
    width: '100%'
  },
  cardPressable: { flex: 1 },
  successCardContainer: { flex: 1 },
  footer: {
    justifyContent: 'center',
    alignItems: 'center'
  }
})
