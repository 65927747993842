import * as React from 'react'
import { Text } from 'react-native'
import {
  TaskFlowStage,
  TaskFlowData,
  TaskStepsRenderingStyle
} from '../task-flow-data'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { GenericCompositeNavigationProp } from '../../common/types/generic-composite-navigation-prop'
import { TaskFlowDataConverter } from './get-task-flow-data'
import { gql } from '@apollo/client'
import { extractBooleanFromJsonMap, isNonNullish } from 'global-utils'
import { NavigationAnchor } from '../../common/links/navigation-anchor'
import { ContentModule } from '../../click-through-module/click-through-module-screen'
import { InputAccountTypesMemberLatestFinancialViewQuery } from 'multi-type-processor'
import { Anchor } from '../../common/links/anchor'
import {
  accountNameWithBalanceFallback,
  getEmployerMatchString,
  hasEmployerMatch
} from '../../../../util/account-parsing'
import { contentStyle } from './content.style'
import { k401ContributionQuestions } from '../../common/questions/content/k401-transaction-aggregations'
import { getTaskTitle } from './get-task-title'

const firstIntro: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts'],
  absoluteDollarsToAllocateToTask: number | null | undefined,
  currentlyAllocatedDollars: number | null
) => React.ReactNode = (
  account,
  absoluteDollarsToAllocateToTask,
  currentlyAllocatedDollars
) => {
  return (
    <>
      You're glowing 💖!{'\n\n'}If you can contribute to your{' '}
      {accountNameWithBalanceFallback(account)} 401k through your employer, you
      have the opportunity to shield your investments from taxes.{'\n\n'}
      {hasEmployerMatch(account, /* includeZeroMatch= */ false)
        ? 'Even better, you have an employer match. '
        : 'Even better, you might also have an employer match. '}
      This. is. literally. free. money.{'\n\n'}Would you walk by a five dollar
      bill on the sidewalk? No? So don't pass up thousands here!{'\n\n'}
      {isNonNullish(currentlyAllocatedDollars) ? (
        <>
          <Text style={contentStyle.boldText}>Current contribution: </Text>
          {currentlyAllocatedDollars?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
          {'\n'}
        </>
      ) : null}
      {isNonNullish(absoluteDollarsToAllocateToTask) ? (
        <>
          <Text style={contentStyle.boldText}>Recommended goal: </Text>
          {absoluteDollarsToAllocateToTask?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
        </>
      ) : null}
      {/* TODO(COPY_UPDATE): For now we intentionally do not surface the contribution limit here.  Computing this
       * value in a principled way is nontrivial and really belongs in the goal engine For now, the recommended
       * contribution is sufficient because it will imply the correct limit if it want the member to max out
       * their account */}
    </>
  )
}

const returningIntro: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts'],
  absoluteDollarsToAllocateToTask: number | null | undefined,
  currentlyAllocatedDollars: number | null
) => React.ReactNode = (
  account,
  absoluteDollarsToAllocateToTask,
  currentlyAllocatedDollars
) => {
  return (
    <>
      Tax Shield. Employer Match. Free Money. You know all this already.{'\n\n'}
      Increase contributions to your {accountNameWithBalanceFallback(
      account
    )}{' '}
      401k.{'\n\n'}
      {isNonNullish(currentlyAllocatedDollars) ? (
        <>
          <Text style={contentStyle.boldText}>Current Contribution: </Text>
          {currentlyAllocatedDollars?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
          {'\n'}
        </>
      ) : null}
      {isNonNullish(absoluteDollarsToAllocateToTask) ? (
        <>
          <Text style={contentStyle.boldText}>Recommended Goal: </Text>
          {absoluteDollarsToAllocateToTask?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
        </>
      ) : null}
    </>
  )
}

const steps: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts'],
  absoluteDollarsToAllocateToTask: number | null | undefined,
  currentlyAllocatedDollars: number | null
) => React.ReactNode = (
  account,
  absoluteDollarsToAllocateToTask,
  currentlyAllocatedDollars
) => {
  return (
    <>
      Your {accountNameWithBalanceFallback(account)} 401k effectively lets your
      investments grow without capital gains taxes. But more importantly, MANY
      EMPLOYERS WILL MATCH THE MONEY YOU PUT IN! I will take some free money,
      thank you very much.{'\n\n'}
      {isNonNullish(currentlyAllocatedDollars) ? (
        <>
          <Text style={contentStyle.boldText}>Current contribution: </Text>
          {currentlyAllocatedDollars?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
          {'\n'}
        </>
      ) : null}
      {isNonNullish(absoluteDollarsToAllocateToTask) ? (
        <>
          <Text style={contentStyle.boldText}>Recommended goal: </Text>
          {absoluteDollarsToAllocateToTask?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
        </>
      ) : null}
    </>
  )
}

const learnKey = 'LEARN'
const learn: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
) => React.ReactNode = (account) => {
  // TODO: maybe calculate how much we think the employer match will grow by, and use that number to make this more
  // tangible.
  return (
    <>
      Employer Retirement accounts, such as 401ks, 403bs, and TSPs, are provided
      by your employer. You contribute to them by having your employer take
      money out of your paycheck. They all mostly work the same way, so we're
      just going to talk about 401ks here. Your 401k stays with you when you
      leave your job, but you can't contribute any more.{'\n\n'}There are two
      really good things about 401ks:{'\n\n'}
      1) You effectively never pay capital gains taxes (capital what taxes? keep
      reading).
      {'\n\n'}
      2) Your employer will often match whatever money you put into your 401k.{' '}
      {hasEmployerMatch(account, /* includeZeroMatch= */ false) ? (
        <>
          We think your employer will match {getEmployerMatchString(account)}.
          (You can edit your employer match rules on the accounts page.)
        </>
      ) : (
        <></>
      )}{' '}
      Because of the tax advantages in 1) and the power of compound interest,
      that free money{' '}
      <Text style={contentStyle.boldText}>could easily grow by 10x</Text> by the
      time you withdraw it! Pinch us, we must be dreaming! (not too hard though
      🤏).
    </>
  )
}

// TODO: add capital gains to a glossary somewhere?
const capitalGainsKey = 'CAPITAL_GAINS'
const capitalGains =
  'If you buy stock for $100 and sell it for $150, you\'d normally pay "capital gains" taxes on ' +
  "that $50 growth. Capital gains taxes are usually less than income tax, but they're still not cheap!\n\nWith a " +
  '401k (or IRA, or 403b, etc.), you never pay these taxes, which means nothing gets in the way of your sweet, ' +
  'sweet compound growth.'

const withdrawalKey = 'WITHDRAWAL'
const withdrawal =
  "401ks are pretty excellent, but they're meant for your long-term savings. In exchange for this " +
  "awesome tax break and employer matching (🎉🎉🎉🎉🎉),  you have to keep your money in your 401k until you're " +
  "59.5 years old. We think that's a great tradeoff — it lets compound interest grow your money over time and " +
  'protects the smart decisions present you is making you from the wayward desires of future you 😛. Remember - when ' +
  "it comes to your financial life, you're in it for the long haul."

const investmentPlanKey = 'INVESTMENT_PLAN'
const investmentPlan = (
  <>
    Your 401k is an investment account, so you should plan on investing your
    money. But, before you do that (or anything else!!) with your money, you
    should understand what it means.{' '}
    <NavigationAnchor
      text={"Here's"}
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.INVESTMENT_BACKGROUND
        })
      }
    />{' '}
    the basic investing info you need to know.{'\n\n'}
    You also have to decide what investments you are going to buy. We like
    passive, low-cost index funds (basically buying a little bit of the whole
    economy). They aren't fancy, aren't confusing, and get the job done. To make
    your plan,{' '}
    <NavigationAnchor
      text="click here"
      handlePress={(nav) =>
        nav.navigate('ClickThroughModuleScreen', {
          module: ContentModule.PORTFOLIO_ALLOCATION
        })
      }
    />
    {'.\n\n'}
    Once you've decided on your investment plan, move on to the next step.
  </>
)

const rothTradKey = 'ROTH_TRAD'
const rothTrad =
  'Sometimes you can choose to put money into a Traditional or a Roth 401k. With a Roth 401k, ' +
  'you put post-tax dollars into the account. That means if you earn a dollar and put it into a Roth 401k, it ' +
  "shows up on your taxes this year like normal. When you withdraw it after you're 59.5, " +
  'there are no taxes.\n\nWith a Traditional 401k, you contribute "pre-tax" dollars. This means you don\'t pay ' +
  "income taxes on that money now. BUT, you'll have to pay income tax on ALL the money (principal AND growth), " +
  'when it comes out.  Still no capital gains tax, though.\n\nMathematically, these two options are the same unless your income tax now is different than ' +
  "it will be in the future. If you're sure you'll have less income in the future, or if you think the government " +
  "will lower everyone's taxes, you should defer taxes with a Traditional 401k. But in general, we recommend Roth " +
  '401ks - they make it easier to get your money out early and the tax story is simpler.'

const researchKey = 'RESEARCH'
const research: (
  account: InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
) => React.ReactNode = (account) => {
  return (
    <>
      Time to get going! Start digging through through your employee benefits
      documents. You need to answer two key questions:{'\n\n'}1) What is your
      employer match?{' '}
      {hasEmployerMatch(account, /* includeZeroMatch= */ false) ? (
        <>
          We think your employer will match {getEmployerMatchString(account)}.
        </>
      ) : (
        <>
          Based on the info we have saved for this account, we don't think your
          employer matches your contributions.
        </>
      )}{' '}
      If this isn't right, please update the match rules for your (
      {account.accountName ?? ''}) account on the accounts page.
      {'\n\n'}2) What investment options are available in your 401k? 401ks
      usually have limited investment options, but most of them have a few
      low-cost, broad-based index funds that are pretty similar to the
      investments from the plan you made two steps ago. You're also much more
      likely to find "Target Date" funds, which we also covered in the investing
      module.
      {'\n\n'}Confirm your match and fund options. If they're super different
      from what you expect, or you feel lost, feel free to contact us at{' '}
      <Anchor
        text={'support@walkthrough.co'}
        href={'mailto:support@walkthrough.co'}
      />
      . Otherwise, move on to the next step.
    </>
  )
}

const contributeKey = 'CONTRIBUTE'
const contribute: (
  absoluteDollarsToAllocateToTask: number | null | undefined
) => React.ReactNode = (absoluteDollarsToAllocateToTask) => {
  return (
    <>
      Look at your employer's benefit documents and follow the steps there to
      set up automatic contributions.{' '}
      {isNonNullish(absoluteDollarsToAllocateToTask) ? (
        <>
          Based on your financial situation, we think you should try to
          contribute at least{' '}
          {absoluteDollarsToAllocateToTask?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
          , if you can.{' '}
        </>
      ) : null}
      We know saving isn't easy, but this is really important. There's no better
      way to grow your nest egg than with free money from your boss! 😏😏
      {'\n\n'}
      You also need to make sure your contributions are being invested how you
      want. Depending on your employer documents, you may have to set this up
      once or do it every time new money shows up in your account.
    </>
  )
}

const returningToTaskKey = 'RETURNING_TO_TASK'
const returningToTask =
  "Yay, you're back! We missed you.\n\nContributing more and more to your 401k makes sense " +
  "as you check other financial goals off the list.\n\nReview the info on 401k's if you want.  Otherwise, jump " +
  ' straight to the incomplete tasks!'

const reportContributionsKey = 'REPORT_CONTRIBUTIONS'
const reportContributions =
  "Awwwwwwww, yeah.....  You've done it!  You're the best.\n\nNow you just have to tell us about it 😛"

// TODO: Delete this handler in favor of the year-generic handler.
export const contributeTo401k2022TaskToFlowData: TaskFlowDataConverter<
WalkthroughGraphQL.ContributeTo401k2022Task
> =
  (
    taskTypename,
    taskId,
    absoluteDollarsToAllocateToTask,
    currentlyAllocatedDollars,
    client,
    recommendedByWalkthrough
  ) =>
    (task) => {
      if (task === null) {
        return null
      }
      if (task.__typename !== taskTypename) {
        return null
      }
      if (task.id !== taskId) {
        return null
      }
      if (!isNonNullish(task.k401Account)) {
        return null
      }

      // Note that we refer to the JS object (not the JSON string!) when writing this back to the DB. This means that each
      // time the object passes through the FE it is revalidated for JSON parseability (although we still lack enforcement
      // that it conforms to a {[key: string]: boolean} type)
      const subtaskCompletionState: any = JSON.parse(
        task.subtaskCompletion ?? '{}'
      )

      // Generate a storage function to associate with a subtask.
      // We define it as an arrow function so it is interpreted within the control flow (needed for the compiler to infer
      // that task is not null). See https://github.com/microsoft/TypeScript/issues/32339 and 32300 for more.
      const storeCompletionGenerator = (key: string) => {
        return (complete: boolean) => {
          client
            .mutate<
          WalkthroughGraphQL.UpdateContributeTo401k2022TaskMutation,
          WalkthroughGraphQL.UpdateContributeTo401k2022TaskMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updateContributeTo401k2022Task),
            variables: {
              input: {
                id: task.id,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            },
            optimisticResponse: {
              updateContributeTo401k2022Task: {
                ...task,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            }
          })
            .catch(console.log)
        }
      }

      const flowData: TaskFlowData = {
        steps: {
          stepRenderingStyle: TaskStepsRenderingStyle.CHECKLIST,
          title: getTaskTitle(
            task.__typename,
            task.stepsCompletedAtLeastOnce ?? false,
            2022
          ),
          description: steps(
            task.k401Account,
            absoluteDollarsToAllocateToTask,
            currentlyAllocatedDollars
          ),
          subtasks: [
            ...(task.stepsCompletedAtLeastOnce !== true
              ? []
              : [
                  {
                    key: returningToTaskKey,
                    isComplete: extractBooleanFromJsonMap(
                      subtaskCompletionState,
                      returningToTaskKey
                    ),
                    storeCompletionState:
                    storeCompletionGenerator(returningToTaskKey),
                    title: "You're back, baby!",
                    description: returningToTask
                  }
                ]),
            {
              key: learnKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                learnKey
              ),
              storeCompletionState: storeCompletionGenerator(learnKey),
              title: 'Learn about 401ks',
              description: learn(task.k401Account)
            },
            {
              key: capitalGainsKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                capitalGainsKey
              ),
              storeCompletionState: storeCompletionGenerator(capitalGainsKey),
              title: 'Capital what?',
              description: capitalGains
            },
            {
              key: withdrawalKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                withdrawalKey
              ),
              storeCompletionState: storeCompletionGenerator(withdrawalKey),
              title: 'The catch (sort of)',
              description: withdrawal
            },
            {
              key: investmentPlanKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                investmentPlanKey
              ),
              storeCompletionState: storeCompletionGenerator(investmentPlanKey),
              title: 'Plan to invest',
              description: investmentPlan
            },
            {
              key: rothTradKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                rothTradKey
              ),
              storeCompletionState: storeCompletionGenerator(rothTradKey),
              title: 'Choose: Roth or Traditional',
              description: rothTrad
            },
            {
              key: researchKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                researchKey
              ),
              storeCompletionState: storeCompletionGenerator(researchKey),
              title: 'Enough talk already! Time to act!',
              description: research(task.k401Account)
            },
            {
              key: contributeKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                contributeKey
              ),
              storeCompletionState: storeCompletionGenerator(contributeKey),
              title: 'Secure the bag 💰',
              description: contribute(absoluteDollarsToAllocateToTask)
            },
            {
              key: reportContributionsKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                reportContributionsKey
              ),
              storeCompletionState: storeCompletionGenerator(
                reportContributionsKey
              ),
              title: 'Write home about it!',
              description: reportContributions,
              questions: k401ContributionQuestions(task.k401Account, 2022)
            }
          ],
          button: {
            buttonText: 'Next',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              const response = client.mutate<
              WalkthroughGraphQL.UpdateContributeTo401k2022TaskMutation,
              WalkthroughGraphQL.UpdateContributeTo401k2022TaskMutationVariables
              >({
                mutation: gql(WalkthroughGraphQL.updateContributeTo401k2022Task),
                variables: {
                  input: {
                    id: task.id,
                    isComplete: true,
                    stepsCompletedAtLeastOnce: true
                  }
                }
              })
              props.navigation.navigate('TaskScreen', {
                flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL,
                waitForPromise: response
              })
            }
          }
        },
        introData: {
          title: getTaskTitle(
            task.__typename,
            task.stepsCompletedAtLeastOnce ?? false,
            2022
          ),
          introContent:
          task.stepsCompletedAtLeastOnce === true
            ? returningIntro(
              task.k401Account,
              absoluteDollarsToAllocateToTask,
              currentlyAllocatedDollars
            )
            : firstIntro(
              task.k401Account,
              absoluteDollarsToAllocateToTask,
              currentlyAllocatedDollars
            ),
          button: {
            buttonText: 'Contribute to your 401k',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              client
                .mutate<
              WalkthroughGraphQL.UpdateContributeTo401k2022TaskMutation,
              WalkthroughGraphQL.UpdateContributeTo401k2022TaskMutationVariables
              >({
                mutation: gql(
                  WalkthroughGraphQL.updateContributeTo401k2022Task
                ),
                variables: {
                  input: {
                    id: task.id,
                    subtaskCompletion: JSON.stringify({
                      ...subtaskCompletionState,
                      [returningToTaskKey]: false,
                      [contributeKey]: false,
                      [reportContributionsKey]: false
                    })
                  }
                },
                optimisticResponse: {
                  updateContributeTo401k2022Task: {
                    ...task,
                    subtaskCompletion: JSON.stringify({
                      ...subtaskCompletionState,
                      [returningToTaskKey]: false,
                      [contributeKey]: false,
                      [reportContributionsKey]: false
                    })
                  }
                }
              })
                .catch(console.log)
              props.navigation.navigate('TaskScreen', {
                taskKey: {
                  taskTypename: task.__typename,
                  taskId: task.id
                },
                flowStage: TaskFlowStage.STEPS,
                absoluteDollarsToAllocateToTask: absoluteDollarsToAllocateToTask,
                recommendedByWalkthrough: recommendedByWalkthrough
              })
            }
          },
          alternativeButton: {
            buttonText: 'Change tasks',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskListScreen')
            }
          }
        }
      }
      return flowData
    }

export const contributeTo401kTaskToFlowData: TaskFlowDataConverter<
WalkthroughGraphQL.ContributeTo401kTask
> =
  (
    taskTypename,
    taskId,
    absoluteDollarsToAllocateToTask,
    currentlyAllocatedDollars,
    client,
    recommendedByWalkthrough
  ) =>
    (task) => {
      if (task === null) {
        return null
      }
      if (task.__typename !== taskTypename) {
        return null
      }
      if (task.id !== taskId) {
        return null
      }
      if (!isNonNullish(task.k401Account)) {
        return null
      }

      // Note that we refer to the JS object (not the JSON string!) when writing this back to the DB. This means that each
      // time the object passes through the FE it is revalidated for JSON parseability (although we still lack enforcement
      // that it conforms to a {[key: string]: boolean} type)
      const subtaskCompletionState: any = JSON.parse(
        task.subtaskCompletion ?? '{}'
      )

      // Generate a storage function to associate with a subtask.
      // We define it as an arrow function so it is interpreted within the control flow (needed for the compiler to infer
      // that task is not null). See https://github.com/microsoft/TypeScript/issues/32339 and 32300 for more.
      const storeCompletionGenerator = (key: string) => {
        return (complete: boolean) => {
          client
            .mutate<
          WalkthroughGraphQL.UpdateContributeTo401kTaskMutation,
          WalkthroughGraphQL.UpdateContributeTo401kTaskMutationVariables
          >({
            mutation: gql(WalkthroughGraphQL.updateContributeTo401kTask),
            variables: {
              input: {
                id: task.id,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            },
            optimisticResponse: {
              updateContributeTo401kTask: {
                ...task,
                subtaskCompletion: JSON.stringify({
                  ...subtaskCompletionState,
                  [key]: complete
                })
              }
            }
          })
            .catch(console.log)
        }
      }

      const flowData: TaskFlowData = {
        steps: {
          stepRenderingStyle: TaskStepsRenderingStyle.CHECKLIST,
          title: getTaskTitle(
            task.__typename,
            task.stepsCompletedAtLeastOnce ?? false,
            task.contributionYear
          ),
          description: steps(
            task.k401Account,
            absoluteDollarsToAllocateToTask,
            currentlyAllocatedDollars
          ),
          subtasks: [
            ...(task.stepsCompletedAtLeastOnce !== true
              ? []
              : [
                  {
                    key: returningToTaskKey,
                    isComplete: extractBooleanFromJsonMap(
                      subtaskCompletionState,
                      returningToTaskKey
                    ),
                    storeCompletionState:
                    storeCompletionGenerator(returningToTaskKey),
                    title: "You're back, baby!",
                    description: returningToTask
                  }
                ]),
            {
              key: learnKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                learnKey
              ),
              storeCompletionState: storeCompletionGenerator(learnKey),
              title: 'Learn about 401ks',
              description: learn(task.k401Account)
            },
            {
              key: capitalGainsKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                capitalGainsKey
              ),
              storeCompletionState: storeCompletionGenerator(capitalGainsKey),
              title: 'Capital what?',
              description: capitalGains
            },
            {
              key: withdrawalKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                withdrawalKey
              ),
              storeCompletionState: storeCompletionGenerator(withdrawalKey),
              title: 'The catch (sort of)',
              description: withdrawal
            },
            {
              key: investmentPlanKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                investmentPlanKey
              ),
              storeCompletionState: storeCompletionGenerator(investmentPlanKey),
              title: 'Plan to invest',
              description: investmentPlan
            },
            {
              key: rothTradKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                rothTradKey
              ),
              storeCompletionState: storeCompletionGenerator(rothTradKey),
              title: 'Choose: Roth or Traditional',
              description: rothTrad
            },
            {
              key: researchKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                researchKey
              ),
              storeCompletionState: storeCompletionGenerator(researchKey),
              title: 'Enough talk already! Time to act!',
              description: research(task.k401Account)
            },
            {
              key: contributeKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                contributeKey
              ),
              storeCompletionState: storeCompletionGenerator(contributeKey),
              title: 'Secure the bag 💰',
              description: contribute(absoluteDollarsToAllocateToTask)
            },
            {
              key: reportContributionsKey,
              isComplete: extractBooleanFromJsonMap(
                subtaskCompletionState,
                reportContributionsKey
              ),
              storeCompletionState: storeCompletionGenerator(
                reportContributionsKey
              ),
              title: 'Write home about it!',
              description: reportContributions,
              questions: k401ContributionQuestions(
                task.k401Account,
                task.contributionYear
              )
            }
          ],
          button: {
            buttonText: 'Next',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              const response = client.mutate<
              WalkthroughGraphQL.UpdateContributeTo401kTaskMutation,
              WalkthroughGraphQL.UpdateContributeTo401kTaskMutationVariables
              >({
                mutation: gql(WalkthroughGraphQL.updateContributeTo401kTask),
                variables: {
                  input: {
                    id: task.id,
                    isComplete: true,
                    stepsCompletedAtLeastOnce: true
                  }
                }
              })
              props.navigation.navigate('TaskScreen', {
                flowStage: TaskFlowStage.DETERMINE_NEXT_GOAL,
                waitForPromise: response
              })
            }
          }
        },
        introData: {
          title: getTaskTitle(
            task.__typename,
            task.stepsCompletedAtLeastOnce ?? false,
            task.contributionYear
          ),
          introContent:
          task.stepsCompletedAtLeastOnce === true
            ? returningIntro(
              task.k401Account,
              absoluteDollarsToAllocateToTask,
              currentlyAllocatedDollars
            )
            : firstIntro(
              task.k401Account,
              absoluteDollarsToAllocateToTask,
              currentlyAllocatedDollars
            ),
          button: {
            buttonText: 'Contribute to your 401k',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              client
                .mutate<
              WalkthroughGraphQL.UpdateContributeTo401kTaskMutation,
              WalkthroughGraphQL.UpdateContributeTo401kTaskMutationVariables
              >({
                mutation: gql(WalkthroughGraphQL.updateContributeTo401kTask),
                variables: {
                  input: {
                    id: task.id,
                    subtaskCompletion: JSON.stringify({
                      ...subtaskCompletionState,
                      [returningToTaskKey]: false,
                      [contributeKey]: false,
                      [reportContributionsKey]: false
                    })
                  }
                },
                optimisticResponse: {
                  updateContributeTo401kTask: {
                    ...task,
                    subtaskCompletion: JSON.stringify({
                      ...subtaskCompletionState,
                      [returningToTaskKey]: false,
                      [contributeKey]: false,
                      [reportContributionsKey]: false
                    })
                  }
                }
              })
                .catch(console.log)
              props.navigation.navigate('TaskScreen', {
                taskKey: {
                  taskTypename: task.__typename,
                  taskId: task.id
                },
                flowStage: TaskFlowStage.STEPS,
                absoluteDollarsToAllocateToTask: absoluteDollarsToAllocateToTask,
                recommendedByWalkthrough: recommendedByWalkthrough
              })
            }
          },
          alternativeButton: {
            buttonText: 'Change tasks',
            handleOnPressButton: (props: {
              navigation: GenericCompositeNavigationProp
            }) => {
              props.navigation.navigate('TaskListScreen')
            }
          }
        }
      }
      return flowData
    }
