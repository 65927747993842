import * as React from "react"
import Svg, { SvgProps, Path, Ellipse, Rect } from "react-native-svg"

const YuyuCheckList = (props: SvgProps): JSX.Element => (
  <Svg
    width={129}
    height={116}
    fill="none"
    {...props}
  >
    <Path
      d="M39 88.493h-.894c-5.067.001-9.847.003-14.21-.331-4.62-.354-8.696-1.079-12.078-2.534-3.359-1.445-6.032-3.609-7.883-6.876C2.073 75.466 1 70.985 1 64.84 1 52.58 5.27 43.405 12.09 37.284 18.929 31.148 28.423 28 39 28s20.072 3.148 26.91 9.284C72.73 43.404 77 52.58 77 64.84c0 6.145-1.073 10.626-2.935 13.912-1.85 3.267-4.524 5.431-7.883 6.876-3.382 1.455-7.458 2.18-12.078 2.534-4.363.334-9.143.332-14.21.331H39Z"
      fill="#fff"
      stroke="#505050"
      strokeWidth={2}
    />
    <Ellipse cx={26.382} cy={69.507} rx={2.294} ry={2.293} fill="#505050" />
    <Ellipse cx={52.765} cy={69.507} rx={2.294} ry={2.293} fill="#505050" />
    <Path
      d="M35.559 74.093c.934.865 3.62 2.077 6.882 0"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M44.162 36.827c-5.506 5.045-10.706 2.102-12.618 0C26.497 32.699 32.882 12.556 36.706 3c4.78 9.173 12.962 28.781 7.456 33.827ZM55.002 35.555c-2.632 1.21-4.203-.492-4.66-1.495-1.374-2.123 3.373-8.805 5.919-11.88.677 3.954 1.373 12.164-1.259 13.375ZM20.748 35.555c2.632 1.21 4.203-.492 4.66-1.495 1.374-2.123-3.373-8.805-5.919-11.88-.677 3.954-1.373 12.164 1.259 13.375Z"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Rect
      x={-0.707}
      y={-1.225}
      width={72.88}
      height={55.955}
      rx={9}
      transform="scale(1 -1) rotate(75 127.336 13.348)"
      fill="#FFF2F8"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-0.756}
      y={-1.195}
      width={12}
      height={12}
      rx={6}
      transform="scale(1 -1) rotate(77.335 73.085 28.579)"
      fill="#91E5E5"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-0.707}
      y={-1.225}
      width={12}
      height={29}
      rx={6}
      transform="scale(1 -1) rotate(75 95.548 47.115)"
      fill="#FDFF86"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-0.707}
      y={-1.225}
      width={12}
      height={29}
      rx={6}
      transform="scale(1 -1) rotate(75 104.048 36.038)"
      fill="#FDFF86"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-0.707}
      y={-1.225}
      width={12}
      height={29}
      rx={6}
      transform="scale(1 -1) rotate(75 112.547 24.96)"
      fill="#FDFF86"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-0.707}
      y={-1.225}
      width={12}
      height={29}
      rx={6}
      transform="scale(1 -1) rotate(75 121.048 13.882)"
      fill="#FDFF86"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-0.756}
      y={-1.195}
      width={12}
      height={12}
      rx={6}
      transform="scale(1 -1) rotate(77.335 89.21 6.652)"
      fill="#91E5E5"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-0.756}
      y={-1.195}
      width={12}
      height={12}
      rx={6}
      transform="scale(1 -1) rotate(77.335 97.27 -4.308)"
      fill="#91E5E5"
      stroke="#505050"
      strokeWidth={2}
    />
    <Rect
      x={-0.756}
      y={-1.195}
      width={12}
      height={12}
      rx={6}
      transform="scale(1 -1) rotate(77.335 81.15 17.612)"
      fill="#91E5E5"
      stroke="#505050"
      strokeWidth={2}
    />
    <Path
      d="m78.053 42.672 1.414 2.45s2.484-3.476 4.805-4.408M73.524 59.575l1.414 2.45s2.484-3.476 4.804-4.407"
      stroke="#505050"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default YuyuCheckList
