import * as React from 'react'
import { ColorValue, View } from 'react-native'
import { cardStyle } from './card.style'
import { isNonNullish } from 'global-utils'

// A basic Card component display provided children.  Provides default styling but can be overridden in specific ways.
export function Card (props: {
  backgroundColor?: ColorValue
  borderColor?: ColorValue
  children: React.ReactNode
}): JSX.Element {
  return (
    <View
      style={[
        cardStyle.card,
        {
          backgroundColor: props.backgroundColor,
          borderColor: props.borderColor,
          borderWidth: isNonNullish(props.borderColor) ? 2 : undefined
        }
      ]}
    >
      {props.children}
    </View>
  )
}
