import { StyleSheet } from 'react-native'
import { THEME } from '../../../constants'

export const accountsScreenStyle = StyleSheet.create({
  accountsScreen: {
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    alignContent: 'center'
  },
  iconBox: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: THEME.spacing.verticalSpaceLarge,
    flexDirection: 'row'
  },
  icon: {
    marginHorizontal: THEME.spacing.horizontalSpaceSmall
  }
})
