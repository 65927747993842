import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SpinArrow = (props: SvgProps): JSX.Element => (
  <Svg
    width={242}
    height={242}
    fill="none"
    {...props}
  >
    <Path
      d="M118.5 121a2.5 2.5 0 1 0 5 0h-5Zm4.268-81.768a2.5 2.5 0 0 0-3.536 0l-15.91 15.91a2.5 2.5 0 0 0 3.536 3.536L121 44.536l14.142 14.142a2.5 2.5 0 0 0 3.536-3.536l-15.91-15.91ZM123.5 121V41h-5v80h5Z"
      fill="#202232"
    />
  </Svg>
)

export default SpinArrow