import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const DownArrow = (props: SvgProps): JSX.Element => (
  <Svg
    width={14}
    height={8}
    fill='none'
    {...props}
  >
    <Path
      d='M12.096 1.096 7 6.192 1.904 1.096'
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap='round'
    />
  </Svg>
)

export default DownArrow
