"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processAllFinancialTasksFromMemberTasksAndAccountsQuery = exports.processAllFinancialTasksFromMemberLatestFinancialViewQuery = exports.processAllFinancialTasksFromGetMemberQuery = exports.getTasksFromContainer = void 0;
const global_utils_1 = require("global-utils");
// Extracts all tasks from a containing object and packages them for task processing.
// Null objects in arrays of objects are removed.
// If no Non-null task of a type exists, adds an array with a single null object.  This allows registration of handlers
// that process "task absent" signals.
function getTasksFromContainer(container) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return {
        payCriticalExpensesTask: [(_a = container === null || container === void 0 ? void 0 : container.payCriticalExpensesTask) !== null && _a !== void 0 ? _a : null],
        buildOneMonthEmergencyFundTask: [
            (_b = container === null || container === void 0 ? void 0 : container.buildOneMonthEmergencyFundTask) !== null && _b !== void 0 ? _b : null
        ],
        extendEmergencyFundToThreeMonthsTask: [
            (_c = container === null || container === void 0 ? void 0 : container.extendEmergencyFundToThreeMonthsTask) !== null && _c !== void 0 ? _c : null
        ],
        extendEmergencyFundToSixMonthsTask: [
            (_d = container === null || container === void 0 ? void 0 : container.extendEmergencyFundToSixMonthsTask) !== null && _d !== void 0 ? _d : null
        ],
        investInBrokerageAccountTask: [
            (_e = container === null || container === void 0 ? void 0 : container.investInBrokerageAccountTask) !== null && _e !== void 0 ? _e : null
        ],
        payOffDebtTasks: (0, global_utils_1.filterNullsAndPadEmpty)((_f = container === null || container === void 0 ? void 0 : container.payOffDebtTasks) === null || _f === void 0 ? void 0 : _f.items),
        contributeTo401k2022Tasks: (0, global_utils_1.filterNullsAndPadEmpty)((_g = container === null || container === void 0 ? void 0 : container.contributeTo401k2022Tasks) === null || _g === void 0 ? void 0 : _g.items),
        contributeTo401kTasks: (0, global_utils_1.filterNullsAndPadEmpty)((_h = container === null || container === void 0 ? void 0 : container.contributeTo401kTasks) === null || _h === void 0 ? void 0 : _h.items),
        contributeToIra2022Task: [(_j = container === null || container === void 0 ? void 0 : container.contributeToIra2022Task) !== null && _j !== void 0 ? _j : null],
        contributeToIraTasks: (0, global_utils_1.filterNullsAndPadEmpty)((_k = container === null || container === void 0 ? void 0 : container.contributeToIraTasks) === null || _k === void 0 ? void 0 : _k.items)
    };
}
exports.getTasksFromContainer = getTasksFromContainer;
// Driver for task processing. See generic processor comments for more.
const processAllFinancialTasksFromGetMemberQuery = (input, handlerMap) => {
    const ret = {
        payCriticalExpensesTask: [],
        buildOneMonthEmergencyFundTask: [],
        extendEmergencyFundToThreeMonthsTask: [],
        extendEmergencyFundToSixMonthsTask: [],
        investInBrokerageAccountTask: [],
        payOffDebtTasks: [],
        contributeTo401k2022Tasks: [],
        contributeTo401kTasks: [],
        contributeToIra2022Task: [],
        contributeToIraTasks: []
    };
    {
        const taskType = 'payCriticalExpensesTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'buildOneMonthEmergencyFundTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'extendEmergencyFundToThreeMonthsTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'extendEmergencyFundToSixMonthsTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'investInBrokerageAccountTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'payOffDebtTasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeTo401k2022Tasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeTo401kTasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeToIra2022Task';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeToIraTasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    return ret;
};
exports.processAllFinancialTasksFromGetMemberQuery = processAllFinancialTasksFromGetMemberQuery;
// Driver for task processing. See generic processor comments for more.
const processAllFinancialTasksFromMemberLatestFinancialViewQuery = (input, handlerMap) => {
    const ret = {
        payCriticalExpensesTask: [],
        buildOneMonthEmergencyFundTask: [],
        extendEmergencyFundToThreeMonthsTask: [],
        extendEmergencyFundToSixMonthsTask: [],
        investInBrokerageAccountTask: [],
        payOffDebtTasks: [],
        contributeTo401k2022Tasks: [],
        contributeTo401kTasks: [],
        contributeToIra2022Task: [],
        contributeToIraTasks: []
    };
    {
        const taskType = 'payCriticalExpensesTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'buildOneMonthEmergencyFundTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'extendEmergencyFundToThreeMonthsTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'extendEmergencyFundToSixMonthsTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'investInBrokerageAccountTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'payOffDebtTasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeTo401k2022Tasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeTo401kTasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeToIra2022Task';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeToIraTasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    return ret;
};
exports.processAllFinancialTasksFromMemberLatestFinancialViewQuery = processAllFinancialTasksFromMemberLatestFinancialViewQuery;
// Driver for task processing. See generic processor comments for more.
const processAllFinancialTasksFromMemberTasksAndAccountsQuery = (input, handlerMap) => {
    const ret = {
        payCriticalExpensesTask: [],
        buildOneMonthEmergencyFundTask: [],
        extendEmergencyFundToThreeMonthsTask: [],
        extendEmergencyFundToSixMonthsTask: [],
        investInBrokerageAccountTask: [],
        payOffDebtTasks: [],
        contributeTo401k2022Tasks: [],
        contributeTo401kTasks: [],
        contributeToIra2022Task: [],
        contributeToIraTasks: []
    };
    {
        const taskType = 'payCriticalExpensesTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'buildOneMonthEmergencyFundTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'extendEmergencyFundToThreeMonthsTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'extendEmergencyFundToSixMonthsTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'investInBrokerageAccountTask';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'payOffDebtTasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeTo401k2022Tasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeTo401kTasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeToIra2022Task';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    {
        const taskType = 'contributeToIraTasks';
        const handler = handlerMap[taskType];
        ret[taskType] = input[taskType].map(handler);
    }
    return ret;
};
exports.processAllFinancialTasksFromMemberTasksAndAccountsQuery = processAllFinancialTasksFromMemberTasksAndAccountsQuery;
