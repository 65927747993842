import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const RightArrow = (props: SvgProps): JSX.Element => (
  <Svg
    width={11}
    height={16}
    fill="none"
    {...props}
  >
    <Path
      d="m2 14 6-6-6-6"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </Svg>
)

export default RightArrow
