// This file checks member accounts and builds appropriate account alerts in a QuestionAndAnswers[][] shape.
import {
  getAccountsFromContainer,
  processAllAccountsFromMemberLatestFinancialViewQuery,
  AccountWithSnapshotShape,
  InputAccountTypesMemberLatestFinancialViewQuery,
  validateAccountForHandler
} from 'multi-type-processor'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish, collapseProcessingResultsIntoArray } from 'global-utils'
import { CryptoDigestAlgorithm, digestStringAsync } from 'expo-crypto'

export type AccountLatestSnapshotHandlerType<T> = (
  account: T | null
) => string | null

export async function getAccountLatestSnapshotHash (
  memberQuery?:
  | WalkthroughGraphQL.MemberLatestFinancialViewQuery
  | null
  | undefined
): Promise<string> {
  const accounts = getAccountsFromContainer(memberQuery?.getMember)
  const accountToAccountLatestSnapshotHandlers = {
    savingsAccounts:
      accountLatestSnapshotHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['savingsAccounts']
      >(),
    checkingAccounts:
      accountLatestSnapshotHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['checkingAccounts']
      >(),
    debtAccounts:
      accountLatestSnapshotHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['debtAccounts']
      >(),
    otherInvestmentAccounts:
      accountLatestSnapshotHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['otherInvestmentAccounts']
      >(),
    traditionalIraAccounts:
      accountLatestSnapshotHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['traditionalIraAccounts']
      >(),
    rothIraAccounts:
      accountLatestSnapshotHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['rothIraAccounts']
      >(),
    k401Accounts:
      accountLatestSnapshotHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['k401Accounts']
      >(),
    uncategorizedAccounts:
      accountLatestSnapshotHandler<
      InputAccountTypesMemberLatestFinancialViewQuery['uncategorizedAccounts']
      >()
  }

  const createdAts = processAllAccountsFromMemberLatestFinancialViewQuery(
    accounts,
    accountToAccountLatestSnapshotHandlers
  )
  const ret = await digestStringAsync(
    CryptoDigestAlgorithm.SHA256,
    collapseProcessingResultsIntoArray(createdAts).filter(isNonNullish).join('')
  )
  return ret
}

function accountLatestSnapshotHandler<
  T extends Omit<WalkthroughGraphQL.Account, '__typename'> &
  AccountWithSnapshotShape
> (): AccountLatestSnapshotHandlerType<T> {
  return (account) => {
    try {
      // This if block allows us to assume that account is nonullable further down
      if (
        !validateAccountForHandler(
          account,
          'accountRefreshAlertsGenericHandler'
        )
      ) {
        return null
      }
    } catch (e) {
      return null
    }
    const createdAt = account.snapshots?.items[0]?.createdAt
    return isNonNullish(createdAt) ? createdAt : null
  }
}
