import * as React from 'react'
import * as WalkthroughGraphQL from 'amplify-client-graphql'
import { isNonNullish } from 'global-utils'
import {
  getPretax401kTransactionAggregationTypeByYear,
  getRoth401kTransactionAggregationTypeByYear,
  transactionAggregationTypeToDisplayText
} from '../../../../../util/account-parsing'
import { Text } from 'react-native'
import {
  FadeElement,
  FadeElementGroup
} from '../../determine-next-task/fade-element-groups'
import YuyuPiggyBankGraph from '../../../../../assets/yuyu/piggy-bank-graph'
import {
  kFirstElementFadeDelay,
  kStandardElementFadeDelay
} from './get-determine-next-task-copy'

export function getPrevious401kContributionElementGroup (
  data: WalkthroughGraphQL.GetMemberQuery
): FadeElementGroup {
  const k401ContributionElements: FadeElement[] = []
  const contributeTo401kTasks = data.getMember?.contributeTo401kTasks

  if (isNonNullish(contributeTo401kTasks)) {
    for (const task of contributeTo401kTasks.items) {
      if (isNonNullish(task) && !task.isComplete) {
        const k401Account = task.k401Account
        const transactionAggregations = k401Account?.transactionAggregations
        if (
          isNonNullish(k401Account) &&
          isNonNullish(transactionAggregations)
        ) {
          const relevantAggregations = transactionAggregations
            .filter(isNonNullish)
            .filter((t) => {
              return (
                t.type ===
                  getPretax401kTransactionAggregationTypeByYear(
                    task.contributionYear
                  ) ||
                t.type ===
                  getRoth401kTransactionAggregationTypeByYear(
                    task.contributionYear
                  )
              )
            })
          const amountsByType = new Map<
          WalkthroughGraphQL.TransactionAggregationType,
          number
          >()
          for (const aggregation of relevantAggregations) {
            const currentSum = amountsByType.get(aggregation.type)
            if (isNonNullish(currentSum)) {
              amountsByType.set(
                aggregation.type,
                currentSum + (aggregation.amount ?? 0)
              )
            } else {
              amountsByType.set(aggregation.type, aggregation.amount ?? 0)
            }
          }
          for (const amountByType of amountsByType.entries()) {
            k401ContributionElements.push({
              key: `401K_CONTRIBUTIONS:${k401Account.accountId}:${amountByType[0]}`,
              element: (
                <Text>{`You've already contributed ${
                  amountByType[1].toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }) ?? '$?'
                } in ${transactionAggregationTypeToDisplayText(
                  amountByType[0]
                )} to your ${
                  k401Account.accountName ?? 'unknown'
                } 401(k) account`}</Text>
              ),
              preFadeInDelay: kStandardElementFadeDelay
            })
          }
        }
      }
    }
  }

  if (k401ContributionElements.length === 0) {
    k401ContributionElements.push({
      key: '401K_CONTRIBUTIONS_EMPTY',
      element: (
        <Text>
          We don't see any yet! You can change this in the accounts page.
        </Text>
      ),
      preFadeInDelay: kFirstElementFadeDelay
    })
  } else {
    k401ContributionElements[0].preFadeInDelay = kFirstElementFadeDelay
  }

  return {
    key: '401K_CONTRIBUTIONS',
    elements: [
      {
        key: '401K_CONTRIBUTIONS_TITLE',
        element: <Text>Checking this year's 401(k) contributions…</Text>,
        preFadeInDelay: 0
      },
      ...k401ContributionElements
    ],
    image: <YuyuPiggyBankGraph />
  }
}
