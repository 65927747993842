import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../../../../constants'

export const drawersStyle = StyleSheet.create({
  drawersScreen: {
    backgroundColor: THEME.color.background,
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  drawerScrollView: {
    width: '100%'
  },
  drawerView: {
    zIndex: 1,
    marginLeft: THEME.spacing.horizontalSpaceMedium,
    marginRight: THEME.spacing.horizontalSpaceMedium,
    marginTop: THEME.spacing.verticalSpaceMedium,
    marginBottom: THEME.spacing.verticalSpaceMedium
  },
  drawerScrollViewExtraPadding: {
    marginBottom: 200
  }
})

export const drawerStyle = (
  color: string,
  drawerZIndex: number
): {
  drawer: ViewStyle
} =>
  StyleSheet.create({
    drawer: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      zIndex: drawerZIndex,
      backgroundColor: color
    }
  })

export const expandableDrawerStyle = (
  isOpen: boolean
): {
  drawerHeaderView: ViewStyle
  drawerContent: ViewStyle
} =>
  StyleSheet.create({
    drawerHeaderView: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      minHeight: 'fit-content',
      marginBottom: isOpen ? THEME.spacing.verticalSpaceSmall : 0,
      marginRight: THEME.spacing.horizontalSpaceMedium
    },
    drawerContent: {
      display: isOpen ? 'flex' : 'none'
    }
  })
